import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import {
  fetchResidentResources,
  fetchResidents
} from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

import { useSnackbar } from './useSnackbar';

export interface EmergencyContact {
  address: string;
  email: string;
  id: string;
  name: string;
  phone: string;
  relationship: string;
  resident_id: string;
  organization_id: number;
  is_primary: boolean;
}
export const USE_EMERGENCY_CONTACTS_QUERY_KEY = ['useEmergencyContactsQuery'];

export function useEmergencyContactsQuery() {
  const invalidate = () =>
    queryClient.invalidateQueries(USE_EMERGENCY_CONTACTS_QUERY_KEY);
  return {
    invalidate,

    findAll: (options: UseQueryOptions = {}) => {
      return useQuery(
        USE_EMERGENCY_CONTACTS_QUERY_KEY,
        () => {
          return fetchResidentResources.get<EmergencyContact[]>(
            '/resident-emergency-contacts'
          );
        },
        options as any
      );
    },

    findByResident: (residentId?: string, options: UseQueryOptions = {}) => {
      return useQuery(
        [...USE_EMERGENCY_CONTACTS_QUERY_KEY, residentId],
        () => {
          return fetchResidents.get<EmergencyContact[]>(
            `/residents/${residentId}/emergency-contacts`
          );
        },
        options as any
      );
    },

    mutations: (residentId: string, options: UseMutationOptions = {}) => {
      const { showSnackbar } = useSnackbar();
      return {
        create: useMutation(
          (payload: EmergencyContact[]) => {
            return fetchResidents.put(
              `/residents/${residentId}/emergency-contacts`,
              payload
            );
          },
          {
            onSuccess: () => {
              showSnackbar({
                severity: 'success',
                message: `Created emergency contact`
              });
              invalidate();
            },
            onError: (error) => {
              console.error('useEmergencyContactsQuery create ->', error);
              showSnackbar({
                severity: 'error',
                message: `Error creating emergency contact`
              });
            },
            ...(options as any)
          }
        ),
        update: useMutation(
          (payload: EmergencyContact | EmergencyContact[]) => {
            return fetchResidents.put(
              `/residents/${residentId}/emergency-contacts`,
              payload
            );
          },
          {
            onSuccess: () => {
              showSnackbar({
                severity: 'success',
                message: `Updated emergency contact`
              });
              invalidate();
            },
            onError: (error) => {
              console.error('useEmergencyContactsQuery update ->', error);
              showSnackbar({
                severity: 'error',
                message: `Error updating emergency contact`
              });
            },
            ...(options as any)
          }
        ),
        delete: useMutation(
          (payload: EmergencyContact) => {
            return fetchResidentResources.delete(
              `/resident-emergency-contacts/${payload.id}`
            );
          },
          {
            onSuccess: (data, payload) => {
              showSnackbar({
                severity: 'success',
                message: `Deleted emergency contact: ${payload.name}`
              });
              invalidate();
            },
            onError: (error, payload) => {
              console.error('useEmergencyContactsQuery delete ->', error);
              showSnackbar({
                severity: 'error',
                message: `Error deleting emergency contact: ${payload.name}`
              });
            },
            ...(options as any)
          }
        )
      };
    }
  };
}
