/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceListEntry } from './AudienceListEntry';
import {
    AudienceListEntryFromJSON,
    AudienceListEntryFromJSONTyped,
    AudienceListEntryToJSON,
} from './AudienceListEntry';

/**
 * 
 * @export
 * @interface FindAllAudiences200Response
 */
export interface FindAllAudiences200Response {
    /**
     * 
     * @type {Array<AudienceListEntry>}
     * @memberof FindAllAudiences200Response
     */
    data?: Array<AudienceListEntry>;
}

/**
 * Check if a given object implements the FindAllAudiences200Response interface.
 */
export function instanceOfFindAllAudiences200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllAudiences200ResponseFromJSON(json: any): FindAllAudiences200Response {
    return FindAllAudiences200ResponseFromJSONTyped(json, false);
}

export function FindAllAudiences200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllAudiences200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AudienceListEntryFromJSON)),
    };
}

export function FindAllAudiences200ResponseToJSON(value?: FindAllAudiences200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AudienceListEntryToJSON)),
    };
}

