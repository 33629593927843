import React from 'react';
import { isMobile } from 'react-device-detect';
import { Medication } from '@mui/icons-material';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { MedicationIndicators } from '@/components/MedicationIndicators/MedicationIndicators';
import { DEA_SCHEDULE_CODES } from '@/constants';
import { MedicationTaskInstancePayload } from '@/hooks/useTaskInstancesQuery';
import SlidingScaleDoseColumnCell from '@/pages/ResidentPage/ResidentMedications/components/SlidingScaleDoseColumnCell';
import { RoutePath } from '@/routes/RoutePath';
import { calculateFdbTotalDosage } from '@/utils/calculateTotalDosage';

import { ResidentModel } from './ResidentModel';
import {
  BaseTaskInstanceModel,
  BaseTaskInstanceModelInterface
} from './TaskInstanceModel';
import { UserModel } from './UserModel';

export interface MedicationTaskInstanceModel extends MedicationTaskInstancePayload {
} // prettier-ignore
export class MedicationTaskInstanceModel
  extends BaseTaskInstanceModel
  implements BaseTaskInstanceModelInterface
{
  public image?: string;
  hasVitalRequirements: boolean;

  constructor(
    payload: MedicationTaskInstancePayload,
    resident: ResidentModel,
    image?: string
  ) {
    super(payload);
    Object.assign(this, payload);
    this.resident = resident;
    this.image = image;
    this.hasVitalRequirements = payload.medication_task.vital_reqs.length > 0;
  }

  public getRowId = (): string => `medicationTaskInstanceModel_id=${this.id}`;

  public renderTaskCell = () => {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <div {...bindHover(popupState)}>
              <Stack direction="row" gap={1} flexWrap="wrap">
                <Typography
                  component="span"
                  color="secondary"
                  fontSize="0.9375rem"
                  fontWeight="500">
                  {this.fdb_dispensable_drug?.DrugNameDesc}
                </Typography>
                <MedicationIndicators model={this} abbreviated showTooltip />
              </Stack>
              <Typography color="#667A86" fontSize="14px">
                {`${
                  this.fdb_dispensable_drug?.GenericDispensableDrugDesc ||
                  this.fdb_dispensable_drug?.DispensableDrugDesc ||
                  ''
                }${
                  this.medication_task.amount_in_stock
                    ? ` (stock: ${this.medication_task.amount_in_stock})`
                    : ''
                }`}
              </Typography>
            </div>
            {!isMobile && (
              <HoverPopover
                {...bindPopover(popupState)}
                elevation={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}>
                <ListItem>
                  {this.image && (
                    <ListItemAvatar>
                      <Avatar variant="rounded" src={this.image}>
                        <Medication fontSize="large" />
                      </Avatar>
                    </ListItemAvatar>
                  )}
                  <ListItemText secondary={this.getTaskName()} />
                </ListItem>
              </HoverPopover>
            )}
          </>
        )}
      </PopupState>
    );
  };

  public getTaskName = (): string =>
    this.fdb_dispensable_drug?.DispensableDrugDesc || '';

  public getAssistanceLevel = (): string => 'n/a';

  public getDialogTitle = (): string => 'Medication';

  public getDialogTabName = (): string => 'Medications';

  public getInstructions = (): string =>
    this.medication_task.instructions || 'n/a';

  public getDialogRedirectUrl = (): string =>
    RoutePath.ResidentMedications.replace(':resident_id', this.resident.id);

  public getTotalDosage = (): string | null | React.ReactNode => {
    const medTaskInstanceSchedule =
      this.medication_task.medication_task_instances.find(
        (item) => item.id === this.id
      )?.mts;
    if (
      medTaskInstanceSchedule &&
      medTaskInstanceSchedule.dose_ranges?.length &&
      !this.completed_by_user_id
    ) {
      return (
        <SlidingScaleDoseColumnCell
          slidingScaleDoseRanges={medTaskInstanceSchedule.dose_ranges}
          dispensableDrug={this.fdb_dispensable_drug}
        />
      );
    }

    return calculateFdbTotalDosage(
      this.fdb_dispensable_drug,
      this.dose_range
        ? this.dose_range.number_of_dose_units
        : this.number_of_dose_units
    );
  };

  public getCellClassName = (field: string): string =>
    `tasks-data-grid-medication-task-schedule-id-${this.medication_task_schedule_id}-${field}-cell`;

  public isControlledSubstance = () =>
    this.fdb_dispensable_drug.FederalDEAClassCode &&
    DEA_SCHEDULE_CODES.includes(this.fdb_dispensable_drug.FederalDEAClassCode);

  public shouldShowGiveMedicationDrawer = () =>
    this.status === 'overdue' ||
    this.status === 'upcoming' ||
    this.status === 'due';

  public includesIngredient = (ingredient: string) => {
    if (!this.fdb_dispensable_drug.Ingredients) {
      return false;
    }
    for (const drugIngredient of this.fdb_dispensable_drug.Ingredients) {
      if (
        drugIngredient.IngredientDesc.toLocaleLowerCase().includes(ingredient)
      ) {
        return true;
      }
    }
  };

  public canViewDocumentation = (currentUser: UserModel) =>
    this._canViewDocumentation() && currentUser.hasMinimumUserRole('L3.1');

  public canMarkComplete = (currentUser: UserModel) =>
    this._canMarkComplete() && currentUser.hasMinimumUserRole('L3.1');

  public canEdit = (currentUser: UserModel) =>
    this._canEdit() && currentUser.hasMinimumUserRole('L3.1');

  public canCancel = (currentUser: UserModel) =>
    this._canCancel() && currentUser.hasMinimumUserRole('L3.1');

  public canUncancel = (currentUser: UserModel) =>
    this._canUncancel() && currentUser.hasMinimumUserRole('L3.1');

  public canEditTaskInstances = (currentUser: UserModel) =>
    this._canViewDocumentation() && currentUser.hasMinimumUserRole('L1');

  public hasDiscontinuedPrescription = (): boolean => {
    return (
      this.medication_task?.pharm_prescriptions?.some(
        (prescription) => prescription.has_dc_message
      ) ?? false
    );
  };
}
