/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssessmentCadence = {
    Every2Weeks: 'Every 2 weeks',
    Monthly: 'Monthly',
    Every45Days: 'Every 45 days',
    Quarterly: 'Quarterly',
    BiAnnuallyTwiceAYear: 'Bi-annually (twice a year)',
    Annually: 'Annually',
    EveryTwoYears: 'Every two years'
} as const;
export type AssessmentCadence = typeof AssessmentCadence[keyof typeof AssessmentCadence];


export function AssessmentCadenceFromJSON(json: any): AssessmentCadence {
    return AssessmentCadenceFromJSONTyped(json, false);
}

export function AssessmentCadenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCadence {
    return json as AssessmentCadence;
}

export function AssessmentCadenceToJSON(value?: AssessmentCadence | null): any {
    return value as any;
}

