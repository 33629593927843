import React from 'react';
import { Avatar, Box, Theme, useMediaQuery } from '@mui/material';

interface IncidentFirstNameCellProps {
  firstName: string;
  lastName: string;
  photo?: string;
}

const IncidentFirstNameCell: React.FC<IncidentFirstNameCellProps> = ({
  firstName,
  lastName,
  photo
}) => {
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  return (
    <Box display="flex" alignItems="center" gap="8px">
      {!isMediaQueryDownLg && (
        <Avatar src={photo}>{`${firstName?.charAt(0)}${lastName?.charAt(
          0
        )}`}</Avatar>
      )}
      {firstName}
    </Box>
  );
};

export default IncidentFirstNameCell;
