/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionOrderListEntry } from './PrescriptionOrderListEntry';
import {
    PrescriptionOrderListEntryFromJSON,
    PrescriptionOrderListEntryFromJSONTyped,
    PrescriptionOrderListEntryToJSON,
} from './PrescriptionOrderListEntry';

/**
 * 
 * @export
 * @interface PrescriptionOrderFindAllResponse
 */
export interface PrescriptionOrderFindAllResponse {
    /**
     * 
     * @type {number}
     * @memberof PrescriptionOrderFindAllResponse
     */
    count: number;
    /**
     * 
     * @type {Array<PrescriptionOrderListEntry>}
     * @memberof PrescriptionOrderFindAllResponse
     */
    rows: Array<PrescriptionOrderListEntry>;
}

/**
 * Check if a given object implements the PrescriptionOrderFindAllResponse interface.
 */
export function instanceOfPrescriptionOrderFindAllResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "rows" in value;

    return isInstance;
}

export function PrescriptionOrderFindAllResponseFromJSON(json: any): PrescriptionOrderFindAllResponse {
    return PrescriptionOrderFindAllResponseFromJSONTyped(json, false);
}

export function PrescriptionOrderFindAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionOrderFindAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'rows': ((json['rows'] as Array<any>).map(PrescriptionOrderListEntryFromJSON)),
    };
}

export function PrescriptionOrderFindAllResponseToJSON(value?: PrescriptionOrderFindAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'rows': ((value.rows as Array<any>).map(PrescriptionOrderListEntryToJSON)),
    };
}

