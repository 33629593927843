/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceType } from './AudienceType';
import {
    AudienceTypeFromJSON,
    AudienceTypeFromJSONTyped,
    AudienceTypeToJSON,
} from './AudienceType';

/**
 * 
 * @export
 * @interface AudienceListEntry
 */
export interface AudienceListEntry {
    /**
     * 
     * @type {string}
     * @memberof AudienceListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceListEntry
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceListEntry
     */
    description: string;
    /**
     * 
     * @type {AudienceType}
     * @memberof AudienceListEntry
     */
    type: AudienceType;
    /**
     * 
     * @type {number}
     * @memberof AudienceListEntry
     */
    member_count: number;
}

/**
 * Check if a given object implements the AudienceListEntry interface.
 */
export function instanceOfAudienceListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "member_count" in value;

    return isInstance;
}

export function AudienceListEntryFromJSON(json: any): AudienceListEntry {
    return AudienceListEntryFromJSONTyped(json, false);
}

export function AudienceListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudienceListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'title': json['title'],
        'description': json['description'],
        'type': AudienceTypeFromJSON(json['type']),
        'member_count': json['member_count'],
    };
}

export function AudienceListEntryToJSON(value?: AudienceListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'title': value.title,
        'description': value.description,
        'type': AudienceTypeToJSON(value.type),
        'member_count': value.member_count,
    };
}

