/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NgRepositoryEntry
 */
export interface NgRepositoryEntry {
    /**
     * 
     * @type {string}
     * @memberof NgRepositoryEntry
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NgRepositoryEntry
     */
    care_category_id: string;
    /**
     * 
     * @type {string}
     * @memberof NgRepositoryEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NgRepositoryEntry
     */
    goal: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NgRepositoryEntry
     */
    interventions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NgRepositoryEntry
     */
    cpe_template_id?: string | null;
}

/**
 * Check if a given object implements the NgRepositoryEntry interface.
 */
export function instanceOfNgRepositoryEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "care_category_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "goal" in value;

    return isInstance;
}

export function NgRepositoryEntryFromJSON(json: any): NgRepositoryEntry {
    return NgRepositoryEntryFromJSONTyped(json, false);
}

export function NgRepositoryEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NgRepositoryEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'care_category_id': json['care_category_id'],
        'title': json['title'],
        'goal': json['goal'],
        'interventions': !exists(json, 'interventions') ? undefined : json['interventions'],
        'cpe_template_id': !exists(json, 'cpe_template_id') ? undefined : json['cpe_template_id'],
    };
}

export function NgRepositoryEntryToJSON(value?: NgRepositoryEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'care_category_id': value.care_category_id,
        'title': value.title,
        'goal': value.goal,
        'interventions': value.interventions,
        'cpe_template_id': value.cpe_template_id,
    };
}

