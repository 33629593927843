/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceMember } from './AudienceMember';
import {
    AudienceMemberFromJSON,
    AudienceMemberFromJSONTyped,
    AudienceMemberToJSON,
} from './AudienceMember';

/**
 * 
 * @export
 * @interface AddRemoveAudienceMembersBulkRequest
 */
export interface AddRemoveAudienceMembersBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddRemoveAudienceMembersBulkRequest
     */
    action: AddRemoveAudienceMembersBulkRequestActionEnum;
    /**
     * 
     * @type {Array<AudienceMember>}
     * @memberof AddRemoveAudienceMembersBulkRequest
     */
    members: Array<AudienceMember>;
}


/**
 * @export
 */
export const AddRemoveAudienceMembersBulkRequestActionEnum = {
    Add: 'add',
    Remove: 'remove'
} as const;
export type AddRemoveAudienceMembersBulkRequestActionEnum = typeof AddRemoveAudienceMembersBulkRequestActionEnum[keyof typeof AddRemoveAudienceMembersBulkRequestActionEnum];


/**
 * Check if a given object implements the AddRemoveAudienceMembersBulkRequest interface.
 */
export function instanceOfAddRemoveAudienceMembersBulkRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "members" in value;

    return isInstance;
}

export function AddRemoveAudienceMembersBulkRequestFromJSON(json: any): AddRemoveAudienceMembersBulkRequest {
    return AddRemoveAudienceMembersBulkRequestFromJSONTyped(json, false);
}

export function AddRemoveAudienceMembersBulkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddRemoveAudienceMembersBulkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'members': ((json['members'] as Array<any>).map(AudienceMemberFromJSON)),
    };
}

export function AddRemoveAudienceMembersBulkRequestToJSON(value?: AddRemoveAudienceMembersBulkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'members': ((value.members as Array<any>).map(AudienceMemberToJSON)),
    };
}

