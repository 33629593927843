/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AchDebit } from './AchDebit';
import {
    AchDebitFromJSON,
    AchDebitFromJSONTyped,
    AchDebitToJSON,
} from './AchDebit';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    id: string;
    /**
     * 
     * @type {Card}
     * @memberof PaymentMethod
     */
    card?: Card;
    /**
     * 
     * @type {AchDebit}
     * @memberof PaymentMethod
     */
    ach_debit?: AchDebit;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    chargeable: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    type: string;
}

/**
 * Check if a given object implements the PaymentMethod interface.
 */
export function instanceOfPaymentMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "chargeable" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
        'ach_debit': !exists(json, 'ach_debit') ? undefined : AchDebitFromJSON(json['ach_debit']),
        'chargeable': json['chargeable'],
        'type': json['type'],
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'card': CardToJSON(value.card),
        'ach_debit': AchDebitToJSON(value.ach_debit),
        'chargeable': value.chargeable,
        'type': value.type,
    };
}

