/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllPaymentRecordsResponse } from './FindAllPaymentRecordsResponse';
import {
    FindAllPaymentRecordsResponseFromJSON,
    FindAllPaymentRecordsResponseFromJSONTyped,
    FindAllPaymentRecordsResponseToJSON,
} from './FindAllPaymentRecordsResponse';

/**
 * 
 * @export
 * @interface FindAllPayments200Response
 */
export interface FindAllPayments200Response {
    /**
     * 
     * @type {FindAllPaymentRecordsResponse}
     * @memberof FindAllPayments200Response
     */
    data?: FindAllPaymentRecordsResponse;
}

/**
 * Check if a given object implements the FindAllPayments200Response interface.
 */
export function instanceOfFindAllPayments200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllPayments200ResponseFromJSON(json: any): FindAllPayments200Response {
    return FindAllPayments200ResponseFromJSONTyped(json, false);
}

export function FindAllPayments200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllPayments200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindAllPaymentRecordsResponseFromJSON(json['data']),
    };
}

export function FindAllPayments200ResponseToJSON(value?: FindAllPayments200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindAllPaymentRecordsResponseToJSON(value.data),
    };
}

