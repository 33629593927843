/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimeCharge } from './OneTimeCharge';
import {
    OneTimeChargeFromJSON,
    OneTimeChargeFromJSONTyped,
    OneTimeChargeToJSON,
} from './OneTimeCharge';

/**
 * 
 * @export
 * @interface FindAllOneTimeCharges200Response
 */
export interface FindAllOneTimeCharges200Response {
    /**
     * 
     * @type {Array<OneTimeCharge>}
     * @memberof FindAllOneTimeCharges200Response
     */
    data?: Array<OneTimeCharge>;
}

/**
 * Check if a given object implements the FindAllOneTimeCharges200Response interface.
 */
export function instanceOfFindAllOneTimeCharges200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllOneTimeCharges200ResponseFromJSON(json: any): FindAllOneTimeCharges200Response {
    return FindAllOneTimeCharges200ResponseFromJSONTyped(json, false);
}

export function FindAllOneTimeCharges200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllOneTimeCharges200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(OneTimeChargeFromJSON)),
    };
}

export function FindAllOneTimeCharges200ResponseToJSON(value?: FindAllOneTimeCharges200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(OneTimeChargeToJSON)),
    };
}

