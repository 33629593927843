/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormUpdateRequest
 */
export interface IncidentFormUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IncidentFormUpdateRequest
     */
    s3_key: string;
}

/**
 * Check if a given object implements the IncidentFormUpdateRequest interface.
 */
export function instanceOfIncidentFormUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "s3_key" in value;

    return isInstance;
}

export function IncidentFormUpdateRequestFromJSON(json: any): IncidentFormUpdateRequest {
    return IncidentFormUpdateRequestFromJSONTyped(json, false);
}

export function IncidentFormUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        's3_key': json['s3_key'],
    };
}

export function IncidentFormUpdateRequestToJSON(value?: IncidentFormUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3_key': value.s3_key,
    };
}

