/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentDocumentListEntry } from './IncidentDocumentListEntry';
import {
    IncidentDocumentListEntryFromJSON,
    IncidentDocumentListEntryFromJSONTyped,
    IncidentDocumentListEntryToJSON,
} from './IncidentDocumentListEntry';
import type { IncidentFindOneResponseLoggedBy } from './IncidentFindOneResponseLoggedBy';
import {
    IncidentFindOneResponseLoggedByFromJSON,
    IncidentFindOneResponseLoggedByFromJSONTyped,
    IncidentFindOneResponseLoggedByToJSON,
} from './IncidentFindOneResponseLoggedBy';

/**
 * 
 * @export
 * @interface IncidentFindOneResponse
 */
export interface IncidentFindOneResponse {
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    incident_type: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFindOneResponse
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    env_notes: string;
    /**
     * 
     * @type {IncidentFindOneResponseLoggedBy}
     * @memberof IncidentFindOneResponse
     */
    logged_by?: IncidentFindOneResponseLoggedBy;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFindOneResponse
     */
    injury_area: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    other_injury_area?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFindOneResponse
     */
    injury_type: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    other_injury_type?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    additional_notes?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    pain_level: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponse
     */
    consciousness_level: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFindOneResponse
     */
    orientation: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFindOneResponse
     */
    completed_at?: Date;
    /**
     * 
     * @type {Array<IncidentDocumentListEntry>}
     * @memberof IncidentFindOneResponse
     */
    documents: Array<IncidentDocumentListEntry>;
}

/**
 * Check if a given object implements the IncidentFindOneResponse interface.
 */
export function instanceOfIncidentFindOneResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_id" in value;
    isInstance = isInstance && "incident_type" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "env_notes" in value;
    isInstance = isInstance && "injury_area" in value;
    isInstance = isInstance && "injury_type" in value;
    isInstance = isInstance && "pain_level" in value;
    isInstance = isInstance && "consciousness_level" in value;
    isInstance = isInstance && "orientation" in value;
    isInstance = isInstance && "documents" in value;

    return isInstance;
}

export function IncidentFindOneResponseFromJSON(json: any): IncidentFindOneResponse {
    return IncidentFindOneResponseFromJSONTyped(json, false);
}

export function IncidentFindOneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFindOneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_id': json['incident_id'],
        'incident_type': json['incident_type'],
        'date': (new Date(json['date'])),
        'location': json['location'],
        'description': json['description'],
        'env_notes': json['env_notes'],
        'logged_by': !exists(json, 'logged_by') ? undefined : IncidentFindOneResponseLoggedByFromJSON(json['logged_by']),
        'injury_area': json['injury_area'],
        'other_injury_area': !exists(json, 'other_injury_area') ? undefined : json['other_injury_area'],
        'injury_type': json['injury_type'],
        'other_injury_type': !exists(json, 'other_injury_type') ? undefined : json['other_injury_type'],
        'additional_notes': !exists(json, 'additional_notes') ? undefined : json['additional_notes'],
        'pain_level': json['pain_level'],
        'consciousness_level': json['consciousness_level'],
        'orientation': json['orientation'],
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'documents': ((json['documents'] as Array<any>).map(IncidentDocumentListEntryFromJSON)),
    };
}

export function IncidentFindOneResponseToJSON(value?: IncidentFindOneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_type': value.incident_type,
        'date': (value.date.toISOString()),
        'location': value.location,
        'description': value.description,
        'env_notes': value.env_notes,
        'logged_by': IncidentFindOneResponseLoggedByToJSON(value.logged_by),
        'injury_area': value.injury_area,
        'other_injury_area': value.other_injury_area,
        'injury_type': value.injury_type,
        'other_injury_type': value.other_injury_type,
        'additional_notes': value.additional_notes,
        'pain_level': value.pain_level,
        'consciousness_level': value.consciousness_level,
        'orientation': value.orientation,
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'documents': ((value.documents as Array<any>).map(IncidentDocumentListEntryToJSON)),
    };
}

