/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentCommentsListEntry
 */
export interface IncidentCommentsListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    created_by_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentsListEntry
     */
    created_at: string;
}

/**
 * Check if a given object implements the IncidentCommentsListEntry interface.
 */
export function instanceOfIncidentCommentsListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created_by_id" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function IncidentCommentsListEntryFromJSON(json: any): IncidentCommentsListEntry {
    return IncidentCommentsListEntryFromJSONTyped(json, false);
}

export function IncidentCommentsListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCommentsListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_by_id': json['created_by_id'],
        'created_by': json['created_by'],
        'role': json['role'],
        'comment': json['comment'],
        'created_at': json['created_at'],
    };
}

export function IncidentCommentsListEntryToJSON(value?: IncidentCommentsListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.created_by_id,
        'created_by': value.created_by,
        'role': value.role,
        'comment': value.comment,
        'created_at': value.created_at,
    };
}

