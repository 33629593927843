/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Deposit } from './Deposit';
import {
    DepositFromJSON,
    DepositFromJSONTyped,
    DepositToJSON,
} from './Deposit';

/**
 * 
 * @export
 * @interface CreateDeposit201Response
 */
export interface CreateDeposit201Response {
    /**
     * 
     * @type {Deposit}
     * @memberof CreateDeposit201Response
     */
    data?: Deposit;
}

/**
 * Check if a given object implements the CreateDeposit201Response interface.
 */
export function instanceOfCreateDeposit201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDeposit201ResponseFromJSON(json: any): CreateDeposit201Response {
    return CreateDeposit201ResponseFromJSONTyped(json, false);
}

export function CreateDeposit201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeposit201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : DepositFromJSON(json['data']),
    };
}

export function CreateDeposit201ResponseToJSON(value?: CreateDeposit201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DepositToJSON(value.data),
    };
}

