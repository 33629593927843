import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { residentsClient } from '@/adapters/schemaClients';
import { ResidentModel } from '@/models/ResidentModel';
import {
  FindAllResidentsRequest,
  FindAllResidentsWithExistingStatementRequest,
  FindAllResidentsWithNoBillsRequest,
  ResidentsFindAllResponse
} from '@/types/residents';

import { ResidentPayload } from '../useResidentFacesheetsQuery';

export const FIND_ALL_RESIDENTS_QUERY_KEY_V2 = 'findAllResidentsKeyV2';
export const FIND_ALL_RESIDENTS_INFINITE_QUERY_KEY_V2 =
  'findAllResidentsInfiniteKeyV2';
export const FIND_ALL_RESIDENTS_NO_BILLS_QUERY_KEY =
  'findAllResidentsNoBillsKey';
export const FIND_ALL_RESIDENTS_WITH_STATEMENT_QUERY_KEY =
  'findAllResidentsWithStatementKey';

export const FIND_ALL_RESIDENTS_DEFAULT_LIMIT = 20;

export const invalidateResidentsQueriesV2 = () => {
  queryClient.invalidateQueries([FIND_ALL_RESIDENTS_QUERY_KEY_V2]);
  queryClient.invalidateQueries([FIND_ALL_RESIDENTS_INFINITE_QUERY_KEY_V2]);
  queryClient.invalidateQueries([FIND_ALL_RESIDENTS_NO_BILLS_QUERY_KEY]);
  queryClient.invalidateQueries([FIND_ALL_RESIDENTS_WITH_STATEMENT_QUERY_KEY]);
};

interface FindAllResidentsPage {
  data: Array<ResidentModel>;
  total: number;
  page: number;
  limit: number;
  has_next: boolean;
}

function useResidentsQueryV2() {
  return {
    invalidateResidentsQueriesV2,
    findAll: (
      params: FindAllResidentsRequest,
      options: UseQueryOptions<ResidentsFindAllResponse, HTTPError> = {}
    ) => {
      return useQuery<ResidentsFindAllResponse, HTTPError>(
        [FIND_ALL_RESIDENTS_QUERY_KEY_V2, params],
        async () => {
          const response = await residentsClient.findAllResidents(params);
          return response.data!;
        },
        {
          ...options
        }
      );
    },
    findAllWithNoBills: (
      params: FindAllResidentsWithNoBillsRequest,
      options: UseQueryOptions<
        {
          canGenerateBills?: boolean;
          residentsWithoutBills?: ResidentModel[];
        },
        HTTPError
      > = {}
    ) => {
      return useQuery<
        {
          canGenerateBills?: boolean;
          residentsWithoutBills?: ResidentModel[];
        },
        HTTPError
      >(
        [FIND_ALL_RESIDENTS_NO_BILLS_QUERY_KEY, params],
        async () => {
          const response = await residentsClient.findAllResidentsWithNoBills(
            params
          );
          return {
            canGenerateBills: response.data?.can_generate_bills,
            residentsWithoutBills: response.data?.without_bills.map(
              (item) => new ResidentModel(item as ResidentPayload)
            )
          };
        },
        {
          enabled: !!params.facilityId,
          ...options
        }
      );
    },
    findAllInfinite: (
      params: FindAllResidentsRequest,
      options: UseInfiniteQueryOptions<FindAllResidentsPage> = {}
    ) =>
      useInfiniteQuery<FindAllResidentsPage>(
        [FIND_ALL_RESIDENTS_INFINITE_QUERY_KEY_V2, params],
        async ({ pageParam }) => {
          const searchParams = {
            ...params,
            page: pageParam ? parseInt(pageParam.page) + 1 : 1
          };
          const response = await residentsClient.findAllResidents(searchParams);
          const transformedResponse: FindAllResidentsPage = {
            ...response.data!,
            data: response.data
              ? response.data?.data.map(
                  (item) => new ResidentModel(item as ResidentPayload)
                )
              : []
          };
          return transformedResponse;
        },
        {
          getNextPageParam: (lastPage) => {
            if (!lastPage?.has_next) return false;
            return lastPage;
          },
          ...options
        }
      ),
    findAllWithStatement: (
      params: FindAllResidentsWithExistingStatementRequest,
      options: UseQueryOptions<ResidentModel[], HTTPError> = {}
    ) => {
      return useQuery<ResidentModel[], HTTPError>(
        [FIND_ALL_RESIDENTS_WITH_STATEMENT_QUERY_KEY, params],
        async () => {
          const response =
            await residentsClient.findAllResidentsWithExistingStatement(params);
          return response.data!.map(
            (resident) => new ResidentModel(resident as ResidentPayload)
          );
        },
        {
          enabled: !!params.facilityId,
          ...options
        }
      );
    }
  };
}

export default useResidentsQueryV2;
