/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatientAllergy
 */
export interface PccPatientAllergy {
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    category: PccPatientAllergyCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    clinical_status: PccPatientAllergyClinicalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    type: PccPatientAllergyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    display: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    reaction_note?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    severity?: PccPatientAllergySeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    resolved_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientAllergy
     */
    pcc_historical_allergy_intolerance_id?: string;
}


/**
 * @export
 */
export const PccPatientAllergyCategoryEnum = {
    Drug: 'Drug',
    Food: 'Food',
    Environmental: 'Environmental',
    Substance: 'Substance',
    Other: 'Other'
} as const;
export type PccPatientAllergyCategoryEnum = typeof PccPatientAllergyCategoryEnum[keyof typeof PccPatientAllergyCategoryEnum];

/**
 * @export
 */
export const PccPatientAllergyClinicalStatusEnum = {
    Active: 'active',
    PriorHistory: 'priorHistory',
    Resolved: 'resolved'
} as const;
export type PccPatientAllergyClinicalStatusEnum = typeof PccPatientAllergyClinicalStatusEnum[keyof typeof PccPatientAllergyClinicalStatusEnum];

/**
 * @export
 */
export const PccPatientAllergyTypeEnum = {
    Allergy: 'Allergy',
    Intolerance: 'Intolerance',
    PropensityToAdverseReactions: 'Propensity to Adverse Reactions',
    Unknown: 'Unknown'
} as const;
export type PccPatientAllergyTypeEnum = typeof PccPatientAllergyTypeEnum[keyof typeof PccPatientAllergyTypeEnum];

/**
 * @export
 */
export const PccPatientAllergySeverityEnum = {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
    Unknown: 'Unknown'
} as const;
export type PccPatientAllergySeverityEnum = typeof PccPatientAllergySeverityEnum[keyof typeof PccPatientAllergySeverityEnum];


/**
 * Check if a given object implements the PccPatientAllergy interface.
 */
export function instanceOfPccPatientAllergy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "clinical_status" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "display" in value;

    return isInstance;
}

export function PccPatientAllergyFromJSON(json: any): PccPatientAllergy {
    return PccPatientAllergyFromJSONTyped(json, false);
}

export function PccPatientAllergyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientAllergy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': json['category'],
        'clinical_status': json['clinical_status'],
        'type': json['type'],
        'display': json['display'],
        'reaction_note': !exists(json, 'reaction_note') ? undefined : json['reaction_note'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'resolved_date': !exists(json, 'resolved_date') ? undefined : json['resolved_date'],
        'pcc_historical_allergy_intolerance_id': !exists(json, 'pcc_historical_allergy_intolerance_id') ? undefined : json['pcc_historical_allergy_intolerance_id'],
    };
}

export function PccPatientAllergyToJSON(value?: PccPatientAllergy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': value.category,
        'clinical_status': value.clinical_status,
        'type': value.type,
        'display': value.display,
        'reaction_note': value.reaction_note,
        'severity': value.severity,
        'resolved_date': value.resolved_date,
        'pcc_historical_allergy_intolerance_id': value.pcc_historical_allergy_intolerance_id,
    };
}

