import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { CreateShiftBlockForm, EditShiftBlockForm } from './ShiftBlockModel';

export enum ShiftToggleType {
  datetime = 'Shift End Date/Time',
  hours = 'Shift Hours'
}

interface ShiftToggleDateTimeHoursProps {
  formMethods: UseFormReturn<CreateShiftBlockForm | EditShiftBlockForm>;
}

export const ShiftToggleDateTimeHours: React.FC<
  ShiftToggleDateTimeHoursProps
> = ({ formMethods }) => {
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    toggleType: ShiftToggleType | null
  ) => {
    if (toggleType !== null) {
      formMethods.setValue('shiftToggleType', toggleType);
      // When shift hours is selected, make sure these fields
      // are not pre-filled
      if (toggleType === ShiftToggleType.hours) {
        formMethods.setValue('shiftHours', null);
        formMethods.setValue('shiftMinutes', null);
      }
    }
  };
  const toggleType = formMethods.watch('shiftToggleType');

  return (
    <ToggleButtonGroup
      value={toggleType}
      exclusive
      onChange={handleAlignment}
      aria-label="Shift Total Payable Hours Calculation">
      <ToggleButton
        data-testid="shift-end-date-time-toggle"
        value={ShiftToggleType.datetime}
        aria-label="Shift End Date/Time">
        {ShiftToggleType.datetime}
      </ToggleButton>
      <ToggleButton
        data-testid="shift-hours-toggle"
        value={ShiftToggleType.hours}
        aria-label="Shift Hours">
        {ShiftToggleType.hours}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
