/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmMedicalResponse
 */
export interface ApfmMedicalResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmMedicalResponse
     */
    profile_medical_history?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmMedicalResponse
     */
    profile_medical_diagnosis?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmMedicalResponse
     */
    profile_diabetic_care_needs?: string;
}

/**
 * Check if a given object implements the ApfmMedicalResponse interface.
 */
export function instanceOfApfmMedicalResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmMedicalResponseFromJSON(json: any): ApfmMedicalResponse {
    return ApfmMedicalResponseFromJSONTyped(json, false);
}

export function ApfmMedicalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmMedicalResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile_medical_history': !exists(json, 'profile_medical_history') ? undefined : json['profile_medical_history'],
        'profile_medical_diagnosis': !exists(json, 'profile_medical_diagnosis') ? undefined : json['profile_medical_diagnosis'],
        'profile_diabetic_care_needs': !exists(json, 'profile_diabetic_care_needs') ? undefined : json['profile_diabetic_care_needs'],
    };
}

export function ApfmMedicalResponseToJSON(value?: ApfmMedicalResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_medical_history': value.profile_medical_history,
        'profile_medical_diagnosis': value.profile_medical_diagnosis,
        'profile_diabetic_care_needs': value.profile_diabetic_care_needs,
    };
}

