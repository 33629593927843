/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';

/**
 * 
 * @export
 * @interface FindAllDiscounts200Response
 */
export interface FindAllDiscounts200Response {
    /**
     * 
     * @type {Array<Discount>}
     * @memberof FindAllDiscounts200Response
     */
    data?: Array<Discount>;
}

/**
 * Check if a given object implements the FindAllDiscounts200Response interface.
 */
export function instanceOfFindAllDiscounts200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllDiscounts200ResponseFromJSON(json: any): FindAllDiscounts200Response {
    return FindAllDiscounts200ResponseFromJSONTyped(json, false);
}

export function FindAllDiscounts200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllDiscounts200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(DiscountFromJSON)),
    };
}

export function FindAllDiscounts200ResponseToJSON(value?: FindAllDiscounts200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(DiscountToJSON)),
    };
}

