/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LeadCommentReferenceType = {
    LeadMedication: 'LEAD_MEDICATION',
    AnalysisResult: 'ANALYSIS_RESULT',
    SOffenderRecord: 'S_OFFENDER_RECORD',
    PdpmEstimatorField: 'PDPM_ESTIMATOR_FIELD',
    PdpmEstimatorComponent: 'PDPM_ESTIMATOR_COMPONENT'
} as const;
export type LeadCommentReferenceType = typeof LeadCommentReferenceType[keyof typeof LeadCommentReferenceType];


export function LeadCommentReferenceTypeFromJSON(json: any): LeadCommentReferenceType {
    return LeadCommentReferenceTypeFromJSONTyped(json, false);
}

export function LeadCommentReferenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadCommentReferenceType {
    return json as LeadCommentReferenceType;
}

export function LeadCommentReferenceTypeToJSON(value?: LeadCommentReferenceType | null): any {
    return value as any;
}

