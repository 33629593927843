import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { CustomMedicationPayload } from '@/pages/CRM/models/CustomMedicationModel';

const hasPercentageRegex = new RegExp('%');
const hasNonNumericalRegex = new RegExp('[^0-9.]');
const hasSlashRegex = new RegExp('/');

export function calculateTotalDosage(
  doses: number | null | undefined,
  strength: string | number | null | undefined,
  units: string | null | undefined,
  excludeMedStrength = false
) {
  if (!doses || !strength) {
    return 'N/A';
  }

  if (!units) {
    return `${doses} Units`;
  }

  if (
    // A med strength unit may contain a percent ie benzoyl peroxide 2.5 % topical gel
    hasPercentageRegex.test(units) ||
    // med strength may contain non numericals ie Tylenol PM Extra Strength 25-500mg
    hasNonNumericalRegex.test(strength.toString()) ||
    // med strength may be concentration ie sertraline 20mg/mL
    hasSlashRegex.test(units)
  ) {
    return `${doses} x ${strength} ${units}`;
  }

  try {
    const medStrengthNumber = Number(strength);
    const multiplied = Math.fround(medStrengthNumber * doses);

    if (isNaN(multiplied)) {
      return 'N/A';
    }

    const roundedWithoutTrailing0s = Number(multiplied.toFixed(2));

    return `${doses} x${
      excludeMedStrength ? '' : ` ${strength} ${units}`
    } = ${roundedWithoutTrailing0s} ${units}`;
  } catch (e) {
    return 'N/A';
  }
}

export function calculateCustomMedicationTotalDosage(
  customMedication: CustomMedicationPayload | null,
  numberOfDoseUnits?: number | null,
  excludeMedStrength = false
) {
  return calculateTotalDosage(
    numberOfDoseUnits,
    customMedication?.dosage,
    customMedication?.dosage_unit,
    excludeMedStrength
  );
}

export function calculateFdbTotalDosage(
  dispensable: MedicationsPayload | null,
  numberOfDoseUnits?: number | null,
  excludeMedStrength = false
) {
  return calculateTotalDosage(
    numberOfDoseUnits,
    dispensable?.MedStrength,
    dispensable?.MedStrengthUnit,
    excludeMedStrength
  );
}
