/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentCommentUpdateRequest
 */
export interface IncidentCommentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentUpdateRequest
     */
    comment: string;
}

/**
 * Check if a given object implements the IncidentCommentUpdateRequest interface.
 */
export function instanceOfIncidentCommentUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function IncidentCommentUpdateRequestFromJSON(json: any): IncidentCommentUpdateRequest {
    return IncidentCommentUpdateRequestFromJSONTyped(json, false);
}

export function IncidentCommentUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCommentUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
    };
}

export function IncidentCommentUpdateRequestToJSON(value?: IncidentCommentUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
    };
}

