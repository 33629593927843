/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllUserDynamicPermissions200Response,
  FindAllUserFacilityAccess200Response,
  FindAllUsersV2200Response,
  SetUserDynamicPermissionsRequest,
  UpdateUserFacilityAccessRequest,
  UpdateUserPin,
  UserCrmNotificationStatus,
} from '../models/index';
import {
    FindAllUserDynamicPermissions200ResponseFromJSON,
    FindAllUserDynamicPermissions200ResponseToJSON,
    FindAllUserFacilityAccess200ResponseFromJSON,
    FindAllUserFacilityAccess200ResponseToJSON,
    FindAllUsersV2200ResponseFromJSON,
    FindAllUsersV2200ResponseToJSON,
    SetUserDynamicPermissionsRequestFromJSON,
    SetUserDynamicPermissionsRequestToJSON,
    UpdateUserFacilityAccessRequestFromJSON,
    UpdateUserFacilityAccessRequestToJSON,
    UpdateUserPinFromJSON,
    UpdateUserPinToJSON,
    UserCrmNotificationStatusFromJSON,
    UserCrmNotificationStatusToJSON,
} from '../models/index';

export interface DeleteUserRequest {
    id: string;
    pin: string;
    newTaskUserId?: string;
    newProspectUserId?: string;
}

export interface FindAllUserDynamicPermissionsRequest {
    id: string;
}

export interface FindAllUserFacilityAccessRequest {
    id: string;
}

export interface FindAllUsersV2Request {
    page?: number;
    limit?: number;
    facilityIds?: string;
    userTypeIds?: string;
    name?: string;
}

export interface SetUserDynamicPermissionsOperationRequest {
    id: string;
    setUserDynamicPermissionsRequest: SetUserDynamicPermissionsRequest;
}

export interface UpdateUserCrmNotificationStatusRequest {
    id: string;
    userCrmNotificationStatus: UserCrmNotificationStatus;
}

export interface UpdateUserFacilityAccessOperationRequest {
    id: string;
    updateUserFacilityAccessRequest: UpdateUserFacilityAccessRequest;
}

export interface UpdateUserPinRequest {
    id: string;
    updateUserPin: UpdateUserPin;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Delete a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.pin !== undefined) {
            queryParameters['pin'] = requestParameters.pin;
        }

        if (requestParameters.newTaskUserId !== undefined) {
            queryParameters['new_task_user_id'] = requestParameters.newTaskUserId;
        }

        if (requestParameters.newProspectUserId !== undefined) {
            queryParameters['new_prospect_user_id'] = requestParameters.newProspectUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * List all dynamic permissions for a user
     */
    async findAllUserDynamicPermissionsRaw(requestParameters: FindAllUserDynamicPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllUserDynamicPermissions200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllUserDynamicPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}/dynamic-permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllUserDynamicPermissions200ResponseFromJSON(jsonValue));
    }

    /**
     * List all dynamic permissions for a user
     */
    async findAllUserDynamicPermissions(requestParameters: FindAllUserDynamicPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllUserDynamicPermissions200Response> {
        const response = await this.findAllUserDynamicPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all user facility access
     */
    async findAllUserFacilityAccessRaw(requestParameters: FindAllUserFacilityAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllUserFacilityAccess200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllUserFacilityAccess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}/facility-access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllUserFacilityAccess200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all user facility access
     */
    async findAllUserFacilityAccess(requestParameters: FindAllUserFacilityAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllUserFacilityAccess200Response> {
        const response = await this.findAllUserFacilityAccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All Users
     */
    async findAllUsersV2Raw(requestParameters: FindAllUsersV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllUsersV2200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.facilityIds !== undefined) {
            queryParameters['facility_ids'] = requestParameters.facilityIds;
        }

        if (requestParameters.userTypeIds !== undefined) {
            queryParameters['user_type_ids'] = requestParameters.userTypeIds;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v2/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllUsersV2200ResponseFromJSON(jsonValue));
    }

    /**
     * Get All Users
     */
    async findAllUsersV2(requestParameters: FindAllUsersV2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllUsersV2200Response> {
        const response = await this.findAllUsersV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set users dynamic permissions
     */
    async setUserDynamicPermissionsRaw(requestParameters: SetUserDynamicPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setUserDynamicPermissions.');
        }

        if (requestParameters.setUserDynamicPermissionsRequest === null || requestParameters.setUserDynamicPermissionsRequest === undefined) {
            throw new runtime.RequiredError('setUserDynamicPermissionsRequest','Required parameter requestParameters.setUserDynamicPermissionsRequest was null or undefined when calling setUserDynamicPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}/dynamic-permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetUserDynamicPermissionsRequestToJSON(requestParameters.setUserDynamicPermissionsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set users dynamic permissions
     */
    async setUserDynamicPermissions(requestParameters: SetUserDynamicPermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setUserDynamicPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a users crm notification status
     */
    async updateUserCrmNotificationStatusRaw(requestParameters: UpdateUserCrmNotificationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserCrmNotificationStatus.');
        }

        if (requestParameters.userCrmNotificationStatus === null || requestParameters.userCrmNotificationStatus === undefined) {
            throw new runtime.RequiredError('userCrmNotificationStatus','Required parameter requestParameters.userCrmNotificationStatus was null or undefined when calling updateUserCrmNotificationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}/crm-notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserCrmNotificationStatusToJSON(requestParameters.userCrmNotificationStatus),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a users crm notification status
     */
    async updateUserCrmNotificationStatus(requestParameters: UpdateUserCrmNotificationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserCrmNotificationStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Grant or revoke user access to facilities
     */
    async updateUserFacilityAccessRaw(requestParameters: UpdateUserFacilityAccessOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserFacilityAccess.');
        }

        if (requestParameters.updateUserFacilityAccessRequest === null || requestParameters.updateUserFacilityAccessRequest === undefined) {
            throw new runtime.RequiredError('updateUserFacilityAccessRequest','Required parameter requestParameters.updateUserFacilityAccessRequest was null or undefined when calling updateUserFacilityAccess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}/facility-access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserFacilityAccessRequestToJSON(requestParameters.updateUserFacilityAccessRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Grant or revoke user access to facilities
     */
    async updateUserFacilityAccess(requestParameters: UpdateUserFacilityAccessOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserFacilityAccessRaw(requestParameters, initOverrides);
    }

    /**
     * Update a users PIN
     */
    async updateUserPinRaw(requestParameters: UpdateUserPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserPin.');
        }

        if (requestParameters.updateUserPin === null || requestParameters.updateUserPin === undefined) {
            throw new runtime.RequiredError('updateUserPin','Required parameter requestParameters.updateUserPin was null or undefined when calling updateUserPin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{id}/user-pin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPinToJSON(requestParameters.updateUserPin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a users PIN
     */
    async updateUserPin(requestParameters: UpdateUserPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserPinRaw(requestParameters, initOverrides);
    }

}
