/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentFollowupListEntry } from './IncidentFollowupListEntry';
import {
    IncidentFollowupListEntryFromJSON,
    IncidentFollowupListEntryFromJSONTyped,
    IncidentFollowupListEntryToJSON,
} from './IncidentFollowupListEntry';
import type { IncidentProtocolResponsiblePartyListEntry } from './IncidentProtocolResponsiblePartyListEntry';
import {
    IncidentProtocolResponsiblePartyListEntryFromJSON,
    IncidentProtocolResponsiblePartyListEntryFromJSONTyped,
    IncidentProtocolResponsiblePartyListEntryToJSON,
} from './IncidentProtocolResponsiblePartyListEntry';

/**
 * 
 * @export
 * @interface IncidentProtocolFormListEntry
 */
export interface IncidentProtocolFormListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    deadline?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    last_uploaded_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolFormListEntry
     */
    last_uploaded_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    s3_key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentProtocolFormListEntry
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormListEntry
     */
    incomplete_followup_time?: string;
    /**
     * 
     * @type {Array<IncidentProtocolResponsiblePartyListEntry>}
     * @memberof IncidentProtocolFormListEntry
     */
    responsible_parties: Array<IncidentProtocolResponsiblePartyListEntry>;
    /**
     * 
     * @type {Array<IncidentFollowupListEntry>}
     * @memberof IncidentProtocolFormListEntry
     */
    followups?: Array<IncidentFollowupListEntry>;
    /**
     * 
     * @type {any}
     * @memberof IncidentProtocolFormListEntry
     */
    complete_followups?: any | null;
}

/**
 * Check if a given object implements the IncidentProtocolFormListEntry interface.
 */
export function instanceOfIncidentProtocolFormListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_protocol_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_optional" in value;
    isInstance = isInstance && "responsible_parties" in value;

    return isInstance;
}

export function IncidentProtocolFormListEntryFromJSON(json: any): IncidentProtocolFormListEntry {
    return IncidentProtocolFormListEntryFromJSONTyped(json, false);
}

export function IncidentProtocolFormListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolFormListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_protocol_id': json['incident_protocol_id'],
        'name': json['name'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        'last_uploaded_by': !exists(json, 'last_uploaded_by') ? undefined : json['last_uploaded_by'],
        'last_uploaded_date': !exists(json, 'last_uploaded_date') ? undefined : (new Date(json['last_uploaded_date'])),
        's3_key': !exists(json, 's3_key') ? undefined : json['s3_key'],
        'is_optional': json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
        'responsible_parties': ((json['responsible_parties'] as Array<any>).map(IncidentProtocolResponsiblePartyListEntryFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentFollowupListEntryFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
    };
}

export function IncidentProtocolFormListEntryToJSON(value?: IncidentProtocolFormListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_protocol_id': value.incident_protocol_id,
        'name': value.name,
        'deadline': value.deadline,
        'last_uploaded_by': value.last_uploaded_by,
        'last_uploaded_date': value.last_uploaded_date === undefined ? undefined : (value.last_uploaded_date.toISOString()),
        's3_key': value.s3_key,
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
        'responsible_parties': ((value.responsible_parties as Array<any>).map(IncidentProtocolResponsiblePartyListEntryToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentFollowupListEntryToJSON)),
        'complete_followups': value.complete_followups,
    };
}

