import React, { useContext, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FileCopy } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ControlledSelect } from '@/components/ControlledSelect';
import { FormHeader } from '@/components/FormHeader';
import { useResidentDoctorsQuery } from '@/hooks/useDoctorsQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';

import { PrescriptionFiles } from './PrescriptionFiles';

const prescriptionTypeOptions = [
  'Fax',
  'Email',
  'Handwritten',
  'Called-in',
  'Scanned'
];

interface MedicationPrescriptionProps {
  formMethods: UseFormReturn<ResidentMedicationForm>;
  handleBack: VoidFunction;
  handleNext: VoidFunction;
  isSaving?: boolean;
  type: MedicationDrawerProps['type'];
  residentId: string;
  disableEdit?: boolean;
}

export const MedicationPrescription: React.FC<MedicationPrescriptionProps> = ({
  formMethods,
  handleBack,
  handleNext,
  isSaving,
  residentId,
  type,
  disableEdit = false
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const [doctorId] = formMethods.watch(['doctor_id']);

  const { data: doctors = [], isLoading } = useResidentDoctorsQuery(residentId);

  useEffect(() => {
    if (!doctors || isLoading) return;
    const doctor = doctors.find((v) => v.id == doctorId);
    formMethods.setValue('doctor_id', doctor?.id as string, {
      shouldDirty: true
    });
  }, [doctors]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormHeader Icon={FileCopy} text="Prescription Review" mb={1} />
        </Grid>
        <Grid xs={12} md={6}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              disabled={isResidentArchived || disableEdit}
              autoHighlight
              value={doctors.find((v) => v.id == doctorId)}
              options={doctors}
              loading={isLoading}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                formMethods.setValue('doctor_id', value?.id as string);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Prescriber" />
              )}
            />
          )}
        </Grid>
        <Grid xs={12} md={6}>
          <ControlledSelect
            disabled={isResidentArchived || disableEdit}
            control={formMethods.control}
            name="prescription_type"
            label="Prescription Type"
            options={prescriptionTypeOptions}
          />
        </Grid>
        <PrescriptionFiles
          disableEdit={disableEdit}
          formMethods={formMethods}
          isResidentArchived={isResidentArchived}
        />
      </Grid>
      {(type === 'Add' || type === 'Readonly') && (
        <DrawerFooter>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleNext}
            loading={isSaving}>
            Next
          </LoadingButton>
        </DrawerFooter>
      )}
    </>
  );
};
