/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssessmentQuestionType = {
    FreeText: 'free_text',
    SingleSelect: 'single_select',
    MultiSelect: 'multi_select'
} as const;
export type AssessmentQuestionType = typeof AssessmentQuestionType[keyof typeof AssessmentQuestionType];


export function AssessmentQuestionTypeFromJSON(json: any): AssessmentQuestionType {
    return AssessmentQuestionTypeFromJSONTyped(json, false);
}

export function AssessmentQuestionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentQuestionType {
    return json as AssessmentQuestionType;
}

export function AssessmentQuestionTypeToJSON(value?: AssessmentQuestionType | null): any {
    return value as any;
}

