import { format } from 'date-fns';
import { RRule } from 'rrule';

// Mainly used to display the Frequency column value in Datagrids
// Example rruleString: DTSTART;TZID=America/Adak:20221110T000000\nRRULE:FREQ=DAILY;INTERVAL=1
export const transformRRuleStringToHumanText = (
  rruleString?: string,
  endTime = ''
): string => {
  if (!rruleString) {
    return '';
  }

  const rrule = RRule.fromString(rruleString);
  // There are one off tasks that'll need additional parsing because they
  // are converted to "Everyday for 1 time", which isn't helpful. We need
  // to get the dtstart instead to display exactly when this will occur.
  if (rrule.options.count === 1) {
    // Add the offset to the UTC date so that the correct date appears to the
    // user. The date would otherwise appear as the day before. For example
    // a RRule dtstart date parsed in a NY browser shows the date as
    // Thu Sep 08 2022 20:00:00 GMT-0400 (Eastern Daylight Time). When set
    // as the value in the datepicker it is shown for that day, which is
    // incorrect. We must add 4 hours to get the correct UTC date of
    // Fri Sep 09 2022 00:00:00 GMT-400
    const userTimezoneOffset =
      rrule.options.dtstart!.getTimezoneOffset() * 60000;
    const dtstart = new Date(
      rrule.options.dtstart!.getTime() + userTimezoneOffset
    );
    return `Once on ${format(dtstart, 'MMMM do')}` as string;
  }

  if (rrule.options.count === 0 && rrule.options.interval === 0) {
    return 'PRN';
  }

  let rruleText = rrule.toText();
  rruleText = rruleText.charAt(0).toUpperCase() + rruleText.substring(1);
  // Having the tzid on the RRule appends "(~ approximate)" to the human
  // readable text, remove it
  const formattedRRuleText = rruleText.replace(' (~ approximate)', '');
  return `${formattedRRuleText}${endTime && `; ${endTime}`}`;
};
