import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { MedicationTaskInstancePayload } from '@/hooks/useMedicationTaskInstancesQuery';
import {
  MedicationTaskPayload,
  MedicationTaskSchedulePayload
} from '@/hooks/useResidentMedications';
import { convertMinutesToHours } from '@/utils/convertMinutesToHours';

export class PrnLimitationsModel
  implements Partial<MedicationTaskSchedulePayload>
{
  public readonly number_of_dose_units: number | null;
  public readonly prn_min_interval_minutes: number | null;
  public readonly prn_max_daily_number_of_dose_units: number | null;
  public readonly prn_available_dosage: number | null;
  public readonly prn_refresh_time: string | null;
  public readonly prn_constraint_reason:
    | 'max_daily_dose'
    | 'min_interval'
    | null;

  public readonly formattedLastAdministeredDatetime: string;
  public readonly lastCompletedMedTaskInstance: MedicationTaskInstancePayload | null;

  constructor(medicationTaskPayload: MedicationTaskPayload) {
    Object.assign(
      this,
      medicationTaskPayload.medication_task_schedules?.[0] ?? {}
    );
    this.lastCompletedMedTaskInstance =
      medicationTaskPayload.medication_task_instances?.slice().sort((a, b) => {
        return dayjs(b.date_completed).diff(dayjs(a.date_completed));
      })?.[0] ?? null;
    this.formattedLastAdministeredDatetime = this.lastCompletedMedTaskInstance
      ?.date_completed
      ? dayjs(this.lastCompletedMedTaskInstance?.date_completed).format(
          'h:mma [on] dddd, MMMM D, YYYY'
        )
      : '';
  }

  public validateDosesToAdminister(value: any): boolean | string {
    const doses = Number(value);

    if (!isFinite(doses)) {
      return 'Please enter a valid number';
    }

    if (doses < 0) {
      return 'Please enter a positive number';
    }

    if (
      !this.prn_min_interval_minutes &&
      !this.prn_max_daily_number_of_dose_units
    ) {
      return true;
    }

    if (
      (typeof this.prn_min_interval_minutes === 'number' ||
        typeof this.prn_max_daily_number_of_dose_units === 'number') &&
      typeof this.prn_available_dosage === 'number' &&
      doses > this.prn_available_dosage
    ) {
      return `You cannot administer more than ${
        this.prn_available_dosage
      } ${pluralize('dose', this.prn_available_dosage)}${
        this.prn_refresh_time
          ? ` until ${this.formattedPrnRefreshDatetime}`
          : ''
      }.`;
    }

    return true;
  }

  public get formattedPrnRefreshDatetime(): string {
    return this.prn_refresh_time
      ? dayjs(this.prn_refresh_time).format('h:mma [on] dddd, MMMM D, YYYY')
      : '';
  }

  public get canOnlyAdministerPartialDose(): boolean {
    return (
      Number(this.prn_available_dosage) > 0 &&
      this.defaultDoseToAdminister < Number(this.number_of_dose_units)
    );
  }

  public get partialDoseMessage(): string | null {
    if (!this.canOnlyAdministerPartialDose || !this.prn_constraint_reason) {
      return null;
    }

    const constraintMessage =
      this.prn_constraint_reason === 'min_interval'
        ? `The minimum interval of time between administrations for this PRN is ${
            this.prn_min_interval_minutes
          } minutes (${convertMinutesToHours(this.prn_min_interval_minutes)}).`
        : this.prn_constraint_reason === 'max_daily_dose'
        ? `The maximum daily dose units for this PRN is ${this.prn_max_daily_number_of_dose_units}.`
        : '';

    return this.canOnlyAdministerPartialDose
      ? `A partial dose (${
          this.lastCompletedMedTaskInstance?.number_of_dose_units
        }) was administered at ${
          this.formattedLastAdministeredDatetime
        }.${constraintMessage}\n\nDo not administer more than ${
          this.defaultDoseToAdminister
        } ${pluralize(
          'dose',
          this.defaultDoseToAdminister
        )} of this PRN medication until ${this.formattedPrnRefreshDatetime}.`
      : '';
  }

  public get hasNoMoreAvailableDoses(): boolean {
    return this.prn_available_dosage === 0;
  }

  public get noMoreAvailableDosesMessage(): {
    title: string;
    body: string;
  } | null {
    if (!this.hasNoMoreAvailableDoses || !this.prn_constraint_reason) {
      return null;
    }

    if (this.prn_constraint_reason === 'max_daily_dose') {
      return {
        title: 'Cannot Administer; Maximum Daily Dosage Reached',
        body: `The maximum daily doses of this PRN (${this.prn_max_daily_number_of_dose_units}) has been reached. Do not administer this PRN medication again until ${this.formattedPrnRefreshDatetime}.`
      };
    }

    if (this.prn_constraint_reason === 'min_interval') {
      return {
        title: 'Cannot Administer; Minimum Interval Has Not Elapsed',
        body: `The minimum interval of time between administrations for this PRN is ${
          this.prn_min_interval_minutes
        } minutes (${convertMinutesToHours(
          this.prn_min_interval_minutes
        )}). Do not administer this PRN medication again until ${
          this.formattedPrnRefreshDatetime
        }.`
      };
    }

    return null;
  }

  public get defaultDoseToAdminister(): number {
    if (Number(this.prn_available_dosage) < Number(this.number_of_dose_units)) {
      return this.prn_available_dosage as number;
    }
    return this.number_of_dose_units as number;
  }
}
