import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

let theme = createTheme();
theme = createTheme({
  palette: {
    primary: {
      main: '#1DB8F2',
      dark: '#009BD0',
      light: '#E6F7FE',
      contrastText: '#FCFEFF'
    },
    secondary: {
      main: '#0A1E28',
      light: '#1DB8F2'
    },
    success: {
      main: '#569E59',
      contrastText: '#FCFEFF',
      dark: '#569E59',
      light: '#67CC71'
    },
    info: {
      main: '#E7EEF4',
      dark: 'rgba(0, 0, 0, 0.08)'
    },
    error: {
      main: '#DD3730',
      dark: '#BA1B1B',
      contrastText: '#FCFEFF'
    },
    warning: {
      main: '#F38D2F',
      contrastText: '#FCFEFF'
    }
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#9AAEBB',
          '& .MuiTypography-root': {
            fontSize: '16px'
          }
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: '#9AAEBB'
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundColor: 'inherit'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& .MuiChip-label': {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '13px'
          },
          '&.MuiChip-colorError, &.MuiChip-colorSuccess': {
            color: '#FCFEFF'
          }
        },
        sizeSmall: {
          '& .MuiChip-label': {
            paddingLeft: '8px',
            paddingRight: '8px'
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
        disableElevation: true
      },
      styleOverrides: {
        sizeSmall: {
          height: 30,
          padding: '4px 10px',
          lineHeight: 2.75
        },
        sizeMedium: {
          height: 36,
          padding: '6px 16px'
        },
        sizeLarge: {
          height: 42,
          padding: '8px 22px'
        },
        root: {
          minWidth: 'max-content',
          letterSpacing: 'unset',
          textTransform: 'none',
          borderRadius: '100px'
        },
        // TODO: Normalize button theme pseudoclasses after chatting with design
        outlinedPrimary: {
          color: '#1DB8F2',
          backgroundColor: 'unset',
          borderColor: '#1DB8F2',
          '&:hover': {
            color: '#1DB8F2',
            backgroundColor: 'none',
            borderColor: '#1DB8F2'
          }
        },
        outlinedSecondary: {
          color: '#36495',
          borderColor: '#364955',
          background: 'none',
          '&:hover': {
            color: '#36495',
            borderColor: '#364955',
            background: 'none'
          }
        },
        containedPrimary: {
          color: '#FCFEFF',
          background: '#1DB8F2',
          '&:hover': {
            color: '#FCFEFF',
            background: '#1DB8F2'
          }
        },
        containedSecondary: {
          color: '#364955',
          backgroundColor: '#E7EEF4',
          '&:hover': {
            color: '#364955',
            backgroundColor: '#D3E5F4'
          }
        },
        textPrimary: {
          width: 'max-content',
          background: 'unset',
          color: '#1DB8F2'
        },
        textSecondary: {
          background: 'none'
        }
      }
    },
    MuiMobileDatePicker: {
      defaultProps: {
        slotProps: {
          dialog: {
            sx: {
              '& .MuiPaper-root': {
                width: '100dvw',
                height: '100dvh',
                maxHeight: 'unset',
                borderRadius: 0,
                margin: 0
              }
            }
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E7EEF4',
          '&::before, &::after': {
            borderTopColor: '#E7EEF4'
          }
        }
      }
    },
    MuiDrawer: {
      defaultProps: {
        anchor: 'right'
      },
      styleOverrides: {
        paper: {
          width: 560,
          overflowX: 'hidden',
          overflowY: 'auto',
          [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100dvh'
          }
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: 'max-content',
          background: '#E7EEF4',
          borderRadius: '360px'
        }
      }
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'large'
      },
      styleOverrides: {
        sizeLarge: {
          height: 42,
          fontSize: '15px',
          padding: '0 22px'
        },
        sizeMedium: {
          height: 36,
          fontSize: '14px',
          padding: '0 16px'
        },
        root: {
          width: 'max-content',
          transition: 'background 200ms ease-in-out',
          fontWeight: 500,
          background: '#E7EEF4',
          borderRadius: '360px',
          textTransform: 'none',
          border: 'none',
          letterSpacing: 'unset',
          color: '#364955',
          '&:hover': {
            background: '#E7EEF4'
          },
          '&.Mui-selected': {
            background: '#D3E5F4',
            color: '#0A1E28',
            '&:hover': {
              background: '#D3E5F4'
            }
          },
          '&.Mui-disabled': {
            border: 'none'
          }
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: '#364955'
        },
        arrow: {
          '&:before': {
            color: '#364955'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.radio-group,&.checkbox-group': {
            '& .MuiFormLabel-root': {
              color: '#0A1E28',
              fontSize: '16px',
              fontWeight: 500,
              '&.Mui-error': {
                color: '#DD3730'
              }
            }
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          color: '#0A1E28'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: theme.palette.error.main
        }
      }
    },
    MuiDataGrid: {
      defaultProps: {
        disableVirtualization: true,
        hideFooterSelectedRowCount: true,
        pagination: true,
        disableRowSelectionOnClick: true,
        disableColumnMenu: true,
        hideFooter: false,
        getRowHeight: () => 'auto',
        getDetailPanelHeight: () => 'auto',
        slotProps: {
          panel: {
            placement: 'bottom-end'
          }
        }
      },
      styleOverrides: {
        root: {
          border: 'none',
          background: '#FBFCFF',
          borderRadius: '16px',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0
          }
        },
        cell: {
          borderColor: '#E7EEF4',
          paddingTop: '15px',
          paddingBottom: '15px',
          [theme.breakpoints.up('lg')]: {
            ':not(last-of-type)': {
              paddingLeft: '24px'
            },
            ':last-of-type': {
              paddingRight: '24px'
            }
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
            ':not(.ai-analysis-cell)': {
              minWidth: 'unset !important',
              maxWidth: 'unset !important',
              width: '100vw'
            },
            minHeight: 'unset !important',
            maxHeight: 'unset !important',
            padding: 0
          }
        },
        columnHeaders: {
          background:
            'linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05)), #FBFCFF',
          borderRadius: 0,
          borderBottom: 'unset',
          [theme.breakpoints.up('lg')]: {
            ':not(last-of-type)': {
              paddingLeft: '14px'
            },
            ':last-of-type': {
              paddingRight: '14px'
            }
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.875rem'
          },
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        },
        columnHeaderTitle: {
          textOverflow: 'clip',
          whiteSpace: 'break-spaces',
          lineHeight: 1.1
        },
        columnHeaderTitleContainer: {
          textOverflow: 'clip',
          whiteSpace: 'break-spaces',
          lineHeight: 1.1
        },
        detailPanelToggleCell: {
          '&.Mui-disabled': {
            display: 'none'
          }
        },
        menu: {
          '& .MuiPaper-root': {
            background: '#FBFCFF',
            boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
            borderRadius: '8px'
          },
          '& .MuiMenuItem-root': {
            height: 48,
            '&:hover': {
              background: '#DDEAF1'
            }
          }
        },
        paper: {
          minWidth: 'unset',
          overflow: 'hidden',
          boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)'
        },
        row: {
          '&:hover': {
            background:
              'linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05)), #FBFCFF'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#0A1E28'
        },
        secondary: {
          fontSize: '14px',
          color: '#667A86'
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          marginRight: '12px'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#364955'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          background: 'unset'
        },
        list: {
          boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
          borderRadius: '8px'
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
        scrollButtons: 'auto'
      },
      styleOverrides: {
        root: {
          width: '100%',
          zIndex: '2 !important',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: 57, // The height must be at least 1px large than the height
          // of .MuiButtonBase-root in order to not show the scroll arrows
          // by default
          backgroundColor: '#FCFEFF',
          borderBottom: '1px #D3E5F4 solid',
          padding: '0 24px',
          '& .MuiTab-root': {
            minHeight: 56 // See above comment
          },
          [theme.breakpoints.down('lg')]: {
            padding: 0
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            minHeight: 49,
            '& .MuiTab-root': {
              minHeight: 48
            }
          }
        },
        indicator: {
          backgroundColor: '#27A8F0'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: '0.1px',
          '&.Mui-selected': {
            color: '#27A8F0'
          }
        },
        labelIcon: {
          color: '#364955',
          marginRight: 8
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        sizeSmall: {
          height: 24,
          borderRadius: 8,
          fontSize: 13,
          '& .MuiSelect-select': {
            paddingLeft: 9,
            paddingTop: 5,
            paddingRight: '24px !important'
          },
          '& .MuiSvgIcon-root': {
            right: 1
          }
        },
        root: {
          height: 42,
          backgroundColor: '#E7EEF4',
          borderRadius: 360,
          color: '#364955',
          '&::before, &::after': {
            display: 'none'
          },
          '&:hover': {
            backgroundColor: '#D3E5F4'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#667A86'
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '& tbody > tr:last-of-type > td': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: '1px solid #E7EEF4',
          borderRadius: '8px'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05)), #FBFCFF',
          '& .MuiTableCell-root': {
            borderBottom: '1px solid #D3E5F4'
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          color: '#0A1E28',
          padding: '24px !important',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflowX: 'hidden',
          [theme.breakpoints.down('sm')]: {
            padding: '16px !important'
          }
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          padding: 'unset',
          fontSize: '16px',
          color: '#0A1E28'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontWeight: 500,
          color: '#0A1E28',
          display: 'flex',
          padding: '24px 24px 0 24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            padding: '16px 16px 0 16px'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 5px 10px 0px rgba(117, 136, 153, 0.10)',
          borderRadius: '16px',
          background: '#FFFFFF',
          minWidth: 480,
          width: 'max-content',
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%'
          }
        },
        paperFullScreen: {
          borderRadius: 0
        },
        paperWidthXs: {
          maxWidth: '480px'
        },
        paperWidthSm: {
          maxWidth: '480px'
        },
        paperWidthMd: {
          maxWidth: '620px'
        },
        paperWidthLg: {
          maxWidth: '820px'
        },
        paperWidthXl: {
          maxWidth: '820px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 24px 24px 24px',
          gap: '8px',
          '& > :not(:first-of-type)': {
            marginLeft: 0
          },
          [theme.breakpoints.down('sm')]: {
            padding: '0 16px 16px 16px'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: '#667A86',
          fontSize: '1.125rem'
        },
        body2: {
          color: '#667A86'
        },
        h2: {
          fontSize: '1.75rem',
          fontWeight: 500
        },
        h3: {
          fontWeight: 500,
          fontSize: '1.375rem'
        },
        h6: {
          fontSize: '1.2rem',
          color: '#0A1E28'
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontSize: '1rem'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px'
        },
        outlinedInfo: {
          color: `#0A1E28 !important`,
          border: '1px solid #1DB8F2 !important',
          '& .MuiAlert-icon': {
            color: '#1DB8F2 !important'
          }
        },
        standardInfo: {
          color: 'rgba(0, 0, 0, 0.87)',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
          '& .MuiAlert-icon': {
            color: '#0288D1 !important'
          }
        },
        standardError: {
          '& .MuiAlert-icon': {
            color: '#D32F2F !important'
          }
        },
        standardSuccess: {
          '& .MuiAlert-icon': {
            color: '#2E7D32 !important'
          }
        },
        standardWarning: {
          '& .MuiAlert-icon': {
            color: '#ED6C02 !important'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    }
  }
});

export default theme;

import { VictoryTheme } from 'victory';

const chart = { ...VictoryTheme.material } as typeof VictoryTheme.material;
if (chart.group?.colorScale && Array.isArray(chart.group.colorScale)) {
  chart.group.colorScale = [];
}

const themes = { ...VictoryTheme, exacare: chart };
export { themes as VictoryTheme };
