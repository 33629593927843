/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentFollowupListEntry } from './IncidentFollowupListEntry';
import {
    IncidentFollowupListEntryFromJSON,
    IncidentFollowupListEntryFromJSONTyped,
    IncidentFollowupListEntryToJSON,
} from './IncidentFollowupListEntry';
import type { IncidentProtocolResponsiblePartyListEntry } from './IncidentProtocolResponsiblePartyListEntry';
import {
    IncidentProtocolResponsiblePartyListEntryFromJSON,
    IncidentProtocolResponsiblePartyListEntryFromJSONTyped,
    IncidentProtocolResponsiblePartyListEntryToJSON,
} from './IncidentProtocolResponsiblePartyListEntry';

/**
 * 
 * @export
 * @interface IncidentProtocolForm
 */
export interface IncidentProtocolForm {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    last_uploaded_by_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolForm
     */
    last_uploaded_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    deadline?: string;
    /**
     * 
     * @type {Array<IncidentProtocolResponsiblePartyListEntry>}
     * @memberof IncidentProtocolForm
     */
    responsible_parties?: Array<IncidentProtocolResponsiblePartyListEntry>;
    /**
     * 
     * @type {Array<IncidentFollowupListEntry>}
     * @memberof IncidentProtocolForm
     */
    followups?: Array<IncidentFollowupListEntry>;
    /**
     * 
     * @type {any}
     * @memberof IncidentProtocolForm
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentProtocolForm
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolForm
     */
    incomplete_followup_time?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolForm
     */
    date_created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolForm
     */
    date_updated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolForm
     */
    date_deleted?: Date;
}

/**
 * Check if a given object implements the IncidentProtocolForm interface.
 */
export function instanceOfIncidentProtocolForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_protocol_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_optional" in value;

    return isInstance;
}

export function IncidentProtocolFormFromJSON(json: any): IncidentProtocolForm {
    return IncidentProtocolFormFromJSONTyped(json, false);
}

export function IncidentProtocolFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_protocol_id': json['incident_protocol_id'],
        'last_uploaded_by_id': !exists(json, 'last_uploaded_by_id') ? undefined : json['last_uploaded_by_id'],
        'last_uploaded_date': !exists(json, 'last_uploaded_date') ? undefined : (new Date(json['last_uploaded_date'])),
        'name': json['name'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        'responsible_parties': !exists(json, 'responsible_parties') ? undefined : ((json['responsible_parties'] as Array<any>).map(IncidentProtocolResponsiblePartyListEntryFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentFollowupListEntryFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'is_optional': json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
        'date_created': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'date_updated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'date_deleted': !exists(json, 'date_deleted') ? undefined : (new Date(json['date_deleted'])),
    };
}

export function IncidentProtocolFormToJSON(value?: IncidentProtocolForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_protocol_id': value.incident_protocol_id,
        'last_uploaded_by_id': value.last_uploaded_by_id,
        'last_uploaded_date': value.last_uploaded_date === undefined ? undefined : (value.last_uploaded_date.toISOString()),
        'name': value.name,
        'deadline': value.deadline,
        'responsible_parties': value.responsible_parties === undefined ? undefined : ((value.responsible_parties as Array<any>).map(IncidentProtocolResponsiblePartyListEntryToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentFollowupListEntryToJSON)),
        'complete_followups': value.complete_followups,
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
        'date_created': value.date_created === undefined ? undefined : (value.date_created.toISOString()),
        'date_updated': value.date_updated === undefined ? undefined : (value.date_updated.toISOString()),
        'date_deleted': value.date_deleted === undefined ? undefined : (value.date_deleted.toISOString()),
    };
}

