import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { fetchIncidents } from '@/adapters/fetchExaCare';

export type IncidentOption = { name: string; value: string };

export type BloodPressure = {
  systolic: number;
  diastolic: number;
};

export interface AddIncident {
  pin: string;
  residentId: number | null;
  status: number | null;
  typeId: number | null;
  location: number | null;
  locationOther: string | null;
  time: string | null;
  injuryArea?: string | null;
  otherInjuryAreas?: string | null;
  orientation?: string | null;
  injuryType?: string | null;
  otherInjuryTypes?: string | null;
  painLevel?: number | null;
  levelOfConsiousness: number | null;
  bloodPressure?: string | null;
  heartRate?: string | null;
  oxygen?: string | null;
  temperature?: string | null;
  respiration?: string | null;
  actionsFollowUp?: string | null;
  otherActionsFollowUp?: string | null;
  familyPowerOfAttorney?: string | null;
  actionPlanToPrevent?: string | null;
  occurenceDate?: string | null;
  incidentTypeOther?: string | null;
  injuryOther?: string | null;
  residentDescription?: string | null;
  witnessDescription?: string | null;
  nursesDescription?: string | null;
  notesOnPhysicalEnvironment?: string | null;
  notesOnPhysiologicalCircunstances?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  contactName?: string | null;
  contactDate?: string | null;
}

export interface SignOpenIncident {
  pin: string;
}

export const addDefaultIncidentPostBody: AddIncident = {
  pin: '',
  residentId: null,
  status: null,
  typeId: null,
  location: null,
  locationOther: null,
  time: dayjs().toISOString(),
  injuryArea: null,
  otherInjuryAreas: null,
  orientation: null,
  injuryType: null,
  otherInjuryTypes: null,
  painLevel: null,
  levelOfConsiousness: null,
  bloodPressure: null,
  heartRate: null,
  oxygen: null,
  temperature: null,
  respiration: null,
  actionsFollowUp: null,
  otherActionsFollowUp: null,
  familyPowerOfAttorney: null,
  actionPlanToPrevent: null,
  occurenceDate: dayjs().toISOString(),
  injuryOther: null,
  witnessDescription: null,
  residentDescription: null,
  nursesDescription: null,
  notesOnPhysicalEnvironment: null,
  notesOnPhysiologicalCircunstances: null,
  contactEmail: null,
  contactPhone: null,
  contactName: null,
  contactDate: dayjs().toISOString()
};

export type InjuryArea = {
  name: string;
  value: string;
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
  position: {
    [key: string]: string;
  };
};

interface IncidentBodyPayload {
  resident_id: number | null;
  status: number | null;
  type_id: number | null;
  occurence_date?: string | null;
  location: number | null;
  location_other?: string | null;
  resident_description?: string | null;
  witness_description?: string | null;
  nurses_description?: string | null;
  notes_on_physical_environment?: string | null;
  notes_on_psychological_state?: string | null;
  injury_area?: string | null;
  injury_type?: string | null;
  pain_level?: number | null;
  level_of_consiousness?: number | null;
  blood_pressure?: string | null;
  heart_rate?: string | null;
  oxygen?: string | null;
  temperature?: string | null;
  respiration?: string | null;
  orientation?: string | null;
  actions_follow_up?: string | null;
  family_power_of_attorney?: string | null;
  action_plan_to_prevent?: string | null;
  contact_email?: string | null;
  contact_phone?: string | null;
  contact_name?: string | null;
  contact_date?: string | null;
  pin: string;
  incident_type_other?: string | null;
}

function extractEmptyResponse(payload: string | null | undefined) {
  if (payload === '[]') return null;
  return payload;
}

function mergePayloadWithOtherValues(
  payloadStr: string | null | undefined,
  otherValue: string | null | undefined
) {
  if (payloadStr == '[]' && !otherValue) return null;
  const mergedPayload = JSON.stringify([
    ...JSON.parse(payloadStr!),
    { key: 'other', value: otherValue }
  ]);
  return extractEmptyResponse(mergedPayload);
}

export function transformToPostIncidentBody(
  payload: AddIncident
): IncidentBodyPayload {
  return {
    resident_id: payload.residentId,
    status: payload.status,
    type_id: payload.typeId,
    occurence_date: payload.occurenceDate,
    location: payload.location,
    location_other: payload.locationOther,
    resident_description: payload.residentDescription,
    witness_description: payload.witnessDescription,
    nurses_description: payload.nursesDescription,
    notes_on_physical_environment: payload.notesOnPhysicalEnvironment,
    notes_on_psychological_state: payload.notesOnPhysiologicalCircunstances,
    injury_area: mergePayloadWithOtherValues(
      payload.injuryArea,
      payload.otherInjuryAreas
    ),
    injury_type: mergePayloadWithOtherValues(
      payload.injuryType,
      payload.otherInjuryTypes
    ),
    pain_level: payload.painLevel,
    level_of_consiousness: payload.levelOfConsiousness,
    blood_pressure: payload.bloodPressure,
    heart_rate: payload.heartRate,
    oxygen: payload.oxygen,
    temperature: payload.temperature,
    respiration: payload.respiration,
    orientation: extractEmptyResponse(payload.orientation),
    actions_follow_up: mergePayloadWithOtherValues(
      payload.actionsFollowUp,
      payload.otherActionsFollowUp
    ),
    family_power_of_attorney: extractEmptyResponse(
      payload.familyPowerOfAttorney
    ),
    action_plan_to_prevent: payload.actionPlanToPrevent,
    contact_email: payload.contactEmail,
    contact_phone: payload.contactPhone,
    contact_name: payload.contactName,
    contact_date: payload.contactDate,
    pin: payload.pin,
    incident_type_other: payload.incidentTypeOther
  };
}

export const useMutateIncident = (id?: number) => {
  return {
    create: useMutation({
      mutationFn: (payload: IncidentBodyPayload): Promise<any> =>
        fetchIncidents.post(`/incidents`, {
          ...payload
        })
    }),
    close: useMutation({
      mutationFn: (pin: Pick<IncidentBodyPayload, 'pin'>): Promise<any> =>
        fetchIncidents.put(`/incidents/close/${id}`, {
          ...pin
        })
    })
  };
};
