/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillCredit
 */
export interface BillCredit {
    /**
     * 
     * @type {string}
     * @memberof BillCredit
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BillCredit
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof BillCredit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillCredit
     */
    gl_code?: string;
}

/**
 * Check if a given object implements the BillCredit interface.
 */
export function instanceOfBillCredit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function BillCreditFromJSON(json: any): BillCredit {
    return BillCreditFromJSONTyped(json, false);
}

export function BillCreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillCredit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'name': json['name'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
    };
}

export function BillCreditToJSON(value?: BillCredit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'name': value.name,
        'gl_code': value.gl_code,
    };
}

