/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PdpmEstimatorComponentReferenceMeta } from './PdpmEstimatorComponentReferenceMeta';
import {
    PdpmEstimatorComponentReferenceMetaFromJSON,
    PdpmEstimatorComponentReferenceMetaFromJSONTyped,
    PdpmEstimatorComponentReferenceMetaToJSON,
} from './PdpmEstimatorComponentReferenceMeta';
import type { PdpmEstimatorFieldReferenceMeta } from './PdpmEstimatorFieldReferenceMeta';
import {
    PdpmEstimatorFieldReferenceMetaFromJSON,
    PdpmEstimatorFieldReferenceMetaFromJSONTyped,
    PdpmEstimatorFieldReferenceMetaToJSON,
} from './PdpmEstimatorFieldReferenceMeta';

/**
 * 
 * @export
 * @interface LeadCommentReferenceMeta
 */
export interface LeadCommentReferenceMeta {
    /**
     * 
     * @type {PdpmEstimatorFieldReferenceMeta}
     * @memberof LeadCommentReferenceMeta
     */
    pdpm_estimator_field?: PdpmEstimatorFieldReferenceMeta;
    /**
     * 
     * @type {PdpmEstimatorComponentReferenceMeta}
     * @memberof LeadCommentReferenceMeta
     */
    pdpm_estimator_component?: PdpmEstimatorComponentReferenceMeta;
}

/**
 * Check if a given object implements the LeadCommentReferenceMeta interface.
 */
export function instanceOfLeadCommentReferenceMeta(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadCommentReferenceMetaFromJSON(json: any): LeadCommentReferenceMeta {
    return LeadCommentReferenceMetaFromJSONTyped(json, false);
}

export function LeadCommentReferenceMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadCommentReferenceMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pdpm_estimator_field': !exists(json, 'pdpm_estimator_field') ? undefined : PdpmEstimatorFieldReferenceMetaFromJSON(json['pdpm_estimator_field']),
        'pdpm_estimator_component': !exists(json, 'pdpm_estimator_component') ? undefined : PdpmEstimatorComponentReferenceMetaFromJSON(json['pdpm_estimator_component']),
    };
}

export function LeadCommentReferenceMetaToJSON(value?: LeadCommentReferenceMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pdpm_estimator_field': PdpmEstimatorFieldReferenceMetaToJSON(value.pdpm_estimator_field),
        'pdpm_estimator_component': PdpmEstimatorComponentReferenceMetaToJSON(value.pdpm_estimator_component),
    };
}

