import React, { forwardRef } from 'react';
import { FilterAlt } from '@mui/icons-material';
import { Badge, Box, Button, ButtonProps, Stack, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

interface GridToolbarFilterButtonProps extends ButtonProps {
  setFilterButtonEl?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null>
  >;
}

export const GridToolbarFilterButton = forwardRef<
  HTMLButtonElement,
  GridToolbarFilterButtonProps
>(function ({ sx, ...props }, ref) {
  const { setFilterButtonEl } = props;
  const gridApiRef = useGridApiContext();

  const toggleFilterPanel = () =>
    gridApiRef.current.state.preferencePanel.open
      ? gridApiRef.current.hideFilterPanel()
      : gridApiRef.current.showFilterPanel();

  return (
    <Tooltip
      title={
        gridApiRef.current.state.preferencePanel.open
          ? 'Hide filters'
          : 'Show filters'
      }
      enterDelay={1000}>
      <Badge
        color="primary"
        badgeContent={gridApiRef.current.state.filter.filterModel.items.length}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        sx={{ '& .MuiBadge-badge': { left: 39, top: 9 } }}>
        <Stack ref={setFilterButtonEl}>
          <Button
            ref={ref}
            variant="contained"
            color="secondary"
            startIcon={<FilterAlt />}
            onClick={toggleFilterPanel}
            sx={{
              px: { xs: '11px', xl: '22px' },
              '& .MuiButton-startIcon': {
                margin: { xs: '0', xl: '0 8px 0 -4px' }
              },
              ...sx
            }}
            {...props}>
            <Box
              component="span"
              sx={{ display: { xs: 'none', xl: 'inline-block' } }}>
              Filters
            </Box>
          </Button>
        </Stack>
      </Badge>
    </Tooltip>
  );
});

GridToolbarFilterButton.displayName = 'GridToolbarFilterButton';
