/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CriteriaQualifier = {
    Informational: 'Informational',
    DealBreaker: 'Deal Breaker',
    Warning: 'Warning'
} as const;
export type CriteriaQualifier = typeof CriteriaQualifier[keyof typeof CriteriaQualifier];


export function CriteriaQualifierFromJSON(json: any): CriteriaQualifier {
    return CriteriaQualifierFromJSONTyped(json, false);
}

export function CriteriaQualifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaQualifier {
    return json as CriteriaQualifier;
}

export function CriteriaQualifierToJSON(value?: CriteriaQualifier | null): any {
    return value as any;
}

