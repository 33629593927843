import { InjuryArea } from '@/hooks/useCreateIncident';

export const injuryOptions: Array<InjuryArea> = [
  { name: 'Head', value: 'head', labelPlacement: 'top', position: { left: '44%', top: '0%' } }, // prettier-ignore
  { name: 'Left Ear', value: 'left_ear', labelPlacement: 'start', position: { left: '27.2%', top: '6%' }}, // prettier-ignore
  { name: 'Left Eye', value: 'left_eye', labelPlacement: 'start', position: { left: '27%', top: '10%' }}, // prettier-ignore
  { name: 'Nose', value: 'nose', labelPlacement: 'start', position: { left: '30.5%', top: '14%' }}, // prettier-ignore
  { name: 'Left Shoulder', value: 'left_shoulder', labelPlacement: 'start', position: { left: '13%', top: '21%' }}, // prettier-ignore
  { name: 'Left Arm', value: 'left_arm', labelPlacement: 'start', position: { left: '20%', top: '29%' }}, // prettier-ignore
  { name: 'Left Hand', value: 'left_hand', labelPlacement: 'start', position: { left: '19%', top: '46%' }}, // prettier-ignore
  { name: 'Left Hip', value: 'left_hip', labelPlacement: 'start', position: { left: '26%', top: '50%' }}, // prettier-ignore
  { name: 'Left Leg', value: 'left_leg', labelPlacement: 'start', position: { left: '29%', top: '54%' }}, // prettier-ignore
  { name: 'Left Knee', value: 'left_knee', labelPlacement: 'start', position: { left: '27%', top: '60%' }}, // prettier-ignore
  { name: 'Left Ankle', value: 'left_ankle', labelPlacement: 'start', position: { left: '25%', top: '77%' }}, // prettier-ignore
  { name: 'Left Foot', value: 'left_foot', labelPlacement: 'start', position: { left: '18%', top: '85%' }}, // prettier-ignore
  { name: 'Right Ear', value: 'right_ear', labelPlacement: 'end', position: { right: '27%', top: '6%' }}, // prettier-ignore
  { name: 'Right Eye', value: 'right_eye', labelPlacement: 'end', position: { right: '26.7%', top: '10%' }}, // prettier-ignore
  { name: 'Mouth', value: 'mouth', labelPlacement: 'end', position: { right: '30.1%', top: '14%' }}, // prettier-ignore
  { name: 'Neck', value: 'neck', labelPlacement: 'end', position: { right: '38%', top: '18%' }}, // prettier-ignore
  { name: 'Right Shoulder', value: 'right_shoulder', labelPlacement: 'end', position: { right: '12%', top: '21%' }}, // prettier-ignore
  { name: 'Chest', value: 'chest', labelPlacement: 'end', position: { right: '42%', top: '25%' }}, // prettier-ignore
  { name: 'Back/Spine', value: 'back_spine', labelPlacement: 'end', position: { right: '34.8%', top: '29%' }}, // prettier-ignore
  { name: 'Trunk', value: 'trunk', labelPlacement: 'end', position: { right: '34.8%', top: '34%' }}, // prettier-ignore
  { name: 'Right Arm', value: 'right_arm', labelPlacement: 'end', position: { right: '16%', top: '29%' }}, // prettier-ignore
  { name: 'Right Hand', value: 'right_hand', labelPlacement: 'end', position: { right: '18%', top: '46%' }}, // prettier-ignore
  { name: 'Buttocks', value: 'buttocks', labelPlacement: 'end', position: { right: '37.8%', top: '38%' }}, // prettier-ignore
  { name: 'Genitals', value: 'genitals', labelPlacement: 'end', position: { right: '38.6%', top: '42%' }}, // prettier-ignore
  { name: 'Right Hip', value: 'right_hip', labelPlacement: 'end', position: { right: '26%', top: '50%' }}, // prettier-ignore
  { name: 'Right Leg', value: 'right_leg', labelPlacement: 'end', position: { right: '29%', top: '54%' }}, // prettier-ignore
  { name: 'Right Knee', value: 'right_knee', labelPlacement: 'end', position: { right: '25%', top: '60%' }}, // prettier-ignore
  { name: 'Right Ankle', value: 'right_ankle', labelPlacement: 'end', position: { right: '25%', top: '77%' }}, // prettier-ignore
  { name: 'Right Foot', value: 'right_foot', labelPlacement: 'end', position: { right: '15%', top: '85%' }} // prettier-ignore
];

export const painLevelOptions = [
  {
    name: '0 - (no pain)',
    value: 0
  },
  {
    name: '1 - 2 (discomfort)',
    value: 1
  },
  {
    name: '3 - 4 (mild pain)',
    value: 2
  },
  {
    name: '5 - 6 (moderate pain)',
    value: 3
  },
  {
    name: '7 - 8 (intense pain)',
    value: 4
  },
  {
    name: '9 - 10 (excruciating pain)',
    value: 5
  }
];

export const orientations = [
  'Oriented To Person',
  'Oriented To Time',
  'Oriented To Place',
  'Oriented To Situation'
];

export const proxyNotificationOptions = [
  'Left Message',
  'Spoke With',
  'Email Sent',
  'Unable To Leave Message Or Connect'
];

export const locationOptions = [
  {
    key: "Resident's Room",
    value: 0
  },
  {
    key: "Resident's Bathroom",
    value: 1
  },
  {
    key: 'Hallway',
    value: 2
  },
  {
    key: 'Dining Room',
    value: 3
  },
  {
    key: 'Activities Room',
    value: 4
  },
  {
    key: 'Therapy',
    value: 5
  },
  {
    key: 'Beauty Salon',
    value: 6
  },
  {
    key: 'Shower',
    value: 7
  },
  {
    key: 'Nursing Station',
    value: 8
  },
  {
    key: 'Outside (On Premise)',
    value: 9
  },
  {
    key: 'Off Premise',
    value: 10
  },
  {
    key: 'Other',
    value: 11
  }
];
