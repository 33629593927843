import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import SelectCommunityLayout from '@/components/Layout/SelectCommunityLayout';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom
} from '@/hooks/useFacilitiesQuery';

import { MedicationInventoryHistoryDataGrid } from './MedicationInventoryHistoryDataGrid';
import { MedicationInventoryHistoryHeader } from './MedicationInventoryHistoryHeader';

const MedicationInventoryHistory: React.FC = () => {
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom)!;

  if (selectedFacilityId === ALL_FACILITIES) {
    return <SelectCommunityLayout />;
  }

  return (
    <>
      <MedicationInventoryHistoryHeader />
      <MedicationInventoryHistoryDataGrid />
      <Outlet />
    </>
  );
};

export default MedicationInventoryHistory;
