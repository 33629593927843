/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IncidentInjuryArea = {
    Head: 'head',
    RightEar: 'right_ear',
    LeftEar: 'left_ear',
    RightEye: 'right_eye',
    LeftEye: 'left_eye',
    RightShoulder: 'right_shoulder',
    LeftShoulder: 'left_shoulder',
    Neck: 'neck',
    Chest: 'chest',
    BackSpine: 'back_spine',
    Trunk: 'trunk',
    Buttocks: 'buttocks',
    Genitals: 'genitals',
    RightArm: 'right_arm',
    LeftArm: 'left_arm',
    RightHand: 'right_hand',
    LeftHand: 'left_hand',
    RightHip: 'right_hip',
    LeftHip: 'left_hip',
    RightLeg: 'right_leg',
    LeftLeg: 'left_leg',
    RightKnee: 'right_knee',
    LeftKnee: 'left_knee',
    RightAnkle: 'right_ankle',
    LeftAnkle: 'left_ankle',
    RightFoot: 'right_foot',
    LeftFoot: 'left_foot'
} as const;
export type IncidentInjuryArea = typeof IncidentInjuryArea[keyof typeof IncidentInjuryArea];


export function IncidentInjuryAreaFromJSON(json: any): IncidentInjuryArea {
    return IncidentInjuryAreaFromJSONTyped(json, false);
}

export function IncidentInjuryAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentInjuryArea {
    return json as IncidentInjuryArea;
}

export function IncidentInjuryAreaToJSON(value?: IncidentInjuryArea | null): any {
    return value as any;
}

