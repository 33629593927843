/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllAvailableRooms200Response,
  FindAllTaskExtensionTags200Response,
  FindAllTaskExtensionTemplates200Response,
  ManualDuplicationCheck200Response,
  TaskExtensionType,
} from '../models/index';
import {
    FindAllAvailableRooms200ResponseFromJSON,
    FindAllAvailableRooms200ResponseToJSON,
    FindAllTaskExtensionTags200ResponseFromJSON,
    FindAllTaskExtensionTags200ResponseToJSON,
    FindAllTaskExtensionTemplates200ResponseFromJSON,
    FindAllTaskExtensionTemplates200ResponseToJSON,
    ManualDuplicationCheck200ResponseFromJSON,
    ManualDuplicationCheck200ResponseToJSON,
    TaskExtensionTypeFromJSON,
    TaskExtensionTypeToJSON,
} from '../models/index';

export interface FindAllAvailableRoomsRequest {
    facilityId: string;
}

export interface FindAllTaskExtensionTagsRequest {
    facilityId: string;
    extensionType: TaskExtensionType;
}

export interface FindAllTaskExtensionTemplatesRequest {
    facilityId: string;
    extensionType: TaskExtensionType;
}

export interface ManualDuplicationCheckRequest {
    facilityId?: number;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get available rooms for a facility
     */
    async findAllAvailableRoomsRaw(requestParameters: FindAllAvailableRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllAvailableRooms200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllAvailableRooms.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rooms/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllAvailableRooms200ResponseFromJSON(jsonValue));
    }

    /**
     * Get available rooms for a facility
     */
    async findAllAvailableRooms(requestParameters: FindAllAvailableRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllAvailableRooms200Response> {
        const response = await this.findAllAvailableRoomsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find task extension tags for a facility
     */
    async findAllTaskExtensionTagsRaw(requestParameters: FindAllTaskExtensionTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaskExtensionTags200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllTaskExtensionTags.');
        }

        if (requestParameters.extensionType === null || requestParameters.extensionType === undefined) {
            throw new runtime.RequiredError('extensionType','Required parameter requestParameters.extensionType was null or undefined when calling findAllTaskExtensionTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.extensionType !== undefined) {
            queryParameters['extension_type'] = requestParameters.extensionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-extension-tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaskExtensionTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Find task extension tags for a facility
     */
    async findAllTaskExtensionTags(requestParameters: FindAllTaskExtensionTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaskExtensionTags200Response> {
        const response = await this.findAllTaskExtensionTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find task extension templates for a facility
     */
    async findAllTaskExtensionTemplatesRaw(requestParameters: FindAllTaskExtensionTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaskExtensionTemplates200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllTaskExtensionTemplates.');
        }

        if (requestParameters.extensionType === null || requestParameters.extensionType === undefined) {
            throw new runtime.RequiredError('extensionType','Required parameter requestParameters.extensionType was null or undefined when calling findAllTaskExtensionTemplates.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.extensionType !== undefined) {
            queryParameters['extension_type'] = requestParameters.extensionType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-extension-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaskExtensionTemplates200ResponseFromJSON(jsonValue));
    }

    /**
     * Find task extension templates for a facility
     */
    async findAllTaskExtensionTemplates(requestParameters: FindAllTaskExtensionTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaskExtensionTemplates200Response> {
        const response = await this.findAllTaskExtensionTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manual trigger for Resident Duplications
     */
    async manualDuplicationCheckRaw(requestParameters: ManualDuplicationCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualDuplicationCheck200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.firstName !== undefined) {
            queryParameters['first_name'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['last_name'] = requestParameters.lastName;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/duplication-check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualDuplicationCheck200ResponseFromJSON(jsonValue));
    }

    /**
     * Manual trigger for Resident Duplications
     */
    async manualDuplicationCheck(requestParameters: ManualDuplicationCheckRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualDuplicationCheck200Response> {
        const response = await this.manualDuplicationCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
