/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDoctorPayload
 */
export interface CreateDoctorPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorPayload
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorPayload
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorPayload
     */
    dea_number?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorPayload
     */
    npi_number?: string;
}

/**
 * Check if a given object implements the CreateDoctorPayload interface.
 */
export function instanceOfCreateDoctorPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreateDoctorPayloadFromJSON(json: any): CreateDoctorPayload {
    return CreateDoctorPayloadFromJSONTyped(json, false);
}

export function CreateDoctorPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDoctorPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'dea_number': !exists(json, 'dea_number') ? undefined : json['dea_number'],
        'npi_number': !exists(json, 'npi_number') ? undefined : json['npi_number'],
    };
}

export function CreateDoctorPayloadToJSON(value?: CreateDoctorPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'dea_number': value.dea_number,
        'npi_number': value.npi_number,
    };
}

