/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PtOtClinicalCategoryToCaseMixGroupMapping } from './PtOtClinicalCategoryToCaseMixGroupMapping';
import {
    PtOtClinicalCategoryToCaseMixGroupMappingFromJSON,
    PtOtClinicalCategoryToCaseMixGroupMappingFromJSONTyped,
    PtOtClinicalCategoryToCaseMixGroupMappingToJSON,
} from './PtOtClinicalCategoryToCaseMixGroupMapping';
import type { PtOtDiagnosisToClinicalCategoryMapping } from './PtOtDiagnosisToClinicalCategoryMapping';
import {
    PtOtDiagnosisToClinicalCategoryMappingFromJSON,
    PtOtDiagnosisToClinicalCategoryMappingFromJSONTyped,
    PtOtDiagnosisToClinicalCategoryMappingToJSON,
} from './PtOtDiagnosisToClinicalCategoryMapping';

/**
 * 
 * @export
 * @interface MedicarePtOtMappings
 */
export interface MedicarePtOtMappings {
    /**
     * 
     * @type {Array<PtOtDiagnosisToClinicalCategoryMapping>}
     * @memberof MedicarePtOtMappings
     */
    diagnosis_to_clinical_category_mappings?: Array<PtOtDiagnosisToClinicalCategoryMapping>;
    /**
     * 
     * @type {Array<PtOtClinicalCategoryToCaseMixGroupMapping>}
     * @memberof MedicarePtOtMappings
     */
    clinical_category_to_case_mix_group_mappings?: Array<PtOtClinicalCategoryToCaseMixGroupMapping>;
}

/**
 * Check if a given object implements the MedicarePtOtMappings interface.
 */
export function instanceOfMedicarePtOtMappings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicarePtOtMappingsFromJSON(json: any): MedicarePtOtMappings {
    return MedicarePtOtMappingsFromJSONTyped(json, false);
}

export function MedicarePtOtMappingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicarePtOtMappings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diagnosis_to_clinical_category_mappings': !exists(json, 'diagnosis_to_clinical_category_mappings') ? undefined : ((json['diagnosis_to_clinical_category_mappings'] as Array<any>).map(PtOtDiagnosisToClinicalCategoryMappingFromJSON)),
        'clinical_category_to_case_mix_group_mappings': !exists(json, 'clinical_category_to_case_mix_group_mappings') ? undefined : ((json['clinical_category_to_case_mix_group_mappings'] as Array<any>).map(PtOtClinicalCategoryToCaseMixGroupMappingFromJSON)),
    };
}

export function MedicarePtOtMappingsToJSON(value?: MedicarePtOtMappings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diagnosis_to_clinical_category_mappings': value.diagnosis_to_clinical_category_mappings === undefined ? undefined : ((value.diagnosis_to_clinical_category_mappings as Array<any>).map(PtOtDiagnosisToClinicalCategoryMappingToJSON)),
        'clinical_category_to_case_mix_group_mappings': value.clinical_category_to_case_mix_group_mappings === undefined ? undefined : ((value.clinical_category_to_case_mix_group_mappings as Array<any>).map(PtOtClinicalCategoryToCaseMixGroupMappingToJSON)),
    };
}

