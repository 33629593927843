/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DiscountType = {
    Percentage: 'PERCENTAGE',
    Fixed: 'FIXED'
} as const;
export type DiscountType = typeof DiscountType[keyof typeof DiscountType];


export function DiscountTypeFromJSON(json: any): DiscountType {
    return DiscountTypeFromJSONTyped(json, false);
}

export function DiscountTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountType {
    return json as DiscountType;
}

export function DiscountTypeToJSON(value?: DiscountType | null): any {
    return value as any;
}

