import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { UserModel } from '@/models/UserModel';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const AdminHubPricesProducts = loadable(() => import("@/pages/AdminHub/PricesProducts")); // prettier-ignore
const AdminHubPricesProductsCarePackages = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory/CarePackages")); // prettier-ignore
const AdminHubPricesProductsDiscounts = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory/Discounts")); // prettier-ignore
const AdminHubPricesProductsEditCareSubscription = loadable(() => import("@/pages/AdminHub/PricesProducts/EditCareSubscription")); // prettier-ignore
const AdminHubPricesProductsInventory = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory")); // prettier-ignore
const AdminHubPricesProductsOneTimeCharges = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory/OneTimeCharges")); // prettier-ignore
const AdminHubPricesProductsRoomRates = loadable(() => import("@/pages/AdminHub/PricesProducts/RoomRates")); // prettier-ignore
const AdminHubPricesProductsRooms = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory/Rooms")); // prettier-ignore
const AdminHubPricesProductsSummary = loadable(() => import("@/pages/AdminHub/PricesProducts/Summary")); // prettier-ignore
const AdminHubPricesProductsTaxes = loadable(() => import("@/pages/AdminHub/PricesProducts/Inventory/Taxes")); // prettier-ignore
const AdminHubPricesProductsCareSubscriptions = loadable(() => import("@/pages/AdminHub/PricesProducts/CareSubscriptions")); // prettier-ignore

export enum AdminHubPricesProductsRoutes {
  AdminHubPricesProducts = '/:domain/admin-hub/prices-products',
  AdminHubPricesProductsInventory = '/:domain/admin-hub/prices-products/inventory',
  AdminHubPricesProductsInventoryCarePackages = '/:domain/admin-hub/prices-products/inventory/care-packages',
  AdminHubPricesProductsInventoryRooms = '/:domain/admin-hub/prices-products/inventory/rooms',
  AdminHubPricesProductsInventoryTaxes = '/:domain/admin-hub/prices-products/inventory/taxes',
  AdminHubPricesProductsInventoryOneTimeCharges = '/:domain/admin-hub/prices-products/inventory/one-time-charges',
  AdminHubPricesProductsInventoryDiscounts = '/:domain/admin-hub/prices-products/inventory/discounts',
  AdminHubPricesProductsSummary = '/:domain/admin-hub/prices-products/summary',
  AdminHubPricesProductsRoomRates = '/:domain/admin-hub/prices-products/room-rates',
  AdminHubPricesProductsCareSubscriptions = '/:domain/admin-hub/prices-products/care-subscriptions',
  AdminHubPricesProductsEditCareSubscription = '/:domain/admin-hub/prices-products/edit-care-subscription/resident/:resident_id'
}

export const getAdminHubPricesProductRoutes = (
  currentUser: UserModel
): RouteObject[] => {
  return [
    ...(currentUser.isBillingEnabledForUser() &&
    FeatureFlagService.isEnabled(ExacareFeature.BILLING_V2)
      ? [
          {
            path: AdminHubPricesProductsRoutes.AdminHubPricesProducts,
            element: <AdminHubPricesProducts />,
            children: [
              {
                path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventory,
                element: <AdminHubPricesProductsInventory />,
                children: [
                  ...(currentUser.isBillingEnabledForUserAndOrg()
                    ? [
                        {
                          path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventoryTaxes,
                          element: <AdminHubPricesProductsTaxes />
                        },
                        {
                          path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventoryOneTimeCharges,
                          element: <AdminHubPricesProductsOneTimeCharges />
                        },
                        {
                          path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventoryDiscounts,
                          element: <AdminHubPricesProductsDiscounts />
                        }
                      ]
                    : []),
                  ...(currentUser.isBillingEnabledForUser()
                    ? [
                        {
                          path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventoryCarePackages,
                          element: <AdminHubPricesProductsCarePackages />
                        },
                        {
                          path: AdminHubPricesProductsRoutes.AdminHubPricesProductsInventoryRooms,
                          element: <AdminHubPricesProductsRooms />
                        }
                      ]
                    : [])
                ]
              },
              ...(FeatureFlagService.isEnabled(ExacareFeature.BILLING_V2)
                ? [
                    ...(currentUser.isBillingEnabledForUserAndOrg()
                      ? [
                          {
                            path: AdminHubPricesProductsRoutes.AdminHubPricesProductsSummary,
                            element: <AdminHubPricesProductsSummary />
                          },

                          {
                            path: AdminHubPricesProductsRoutes.AdminHubPricesProductsCareSubscriptions,
                            element: <AdminHubPricesProductsCareSubscriptions />
                          }
                        ]
                      : []),
                    ...(currentUser.isBillingEnabledForUser()
                      ? [
                          {
                            path: AdminHubPricesProductsRoutes.AdminHubPricesProductsRoomRates,
                            element: <AdminHubPricesProductsRoomRates />
                          }
                        ]
                      : [])
                  ]
                : [])
            ]
          }
        ]
      : []),
    ...(currentUser.isBillingEnabledForUser() &&
    FeatureFlagService.isEnabled(ExacareFeature.BILLING_V2)
      ? [
          {
            path: AdminHubPricesProductsRoutes.AdminHubPricesProductsEditCareSubscription,
            element: <AdminHubPricesProductsEditCareSubscription />
          }
        ]
      : [])
  ];
};
