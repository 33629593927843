/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentAction } from './IncidentAction';
import {
    IncidentActionFromJSON,
    IncidentActionFromJSONTyped,
    IncidentActionToJSON,
} from './IncidentAction';

/**
 * 
 * @export
 * @interface CreateIncidentAction200Response
 */
export interface CreateIncidentAction200Response {
    /**
     * 
     * @type {IncidentAction}
     * @memberof CreateIncidentAction200Response
     */
    data?: IncidentAction;
}

/**
 * Check if a given object implements the CreateIncidentAction200Response interface.
 */
export function instanceOfCreateIncidentAction200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIncidentAction200ResponseFromJSON(json: any): CreateIncidentAction200Response {
    return CreateIncidentAction200ResponseFromJSONTyped(json, false);
}

export function CreateIncidentAction200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncidentAction200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentActionFromJSON(json['data']),
    };
}

export function CreateIncidentAction200ResponseToJSON(value?: CreateIncidentAction200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentActionToJSON(value.data),
    };
}

