/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CampaignStepType } from './CampaignStepType';
import {
    CampaignStepTypeFromJSON,
    CampaignStepTypeFromJSONTyped,
    CampaignStepTypeToJSON,
} from './CampaignStepType';
import type { EmailStep } from './EmailStep';
import {
    EmailStepFromJSON,
    EmailStepFromJSONTyped,
    EmailStepToJSON,
} from './EmailStep';
import type { TaskStep } from './TaskStep';
import {
    TaskStepFromJSON,
    TaskStepFromJSONTyped,
    TaskStepToJSON,
} from './TaskStep';
import type { TextStep } from './TextStep';
import {
    TextStepFromJSON,
    TextStepFromJSONTyped,
    TextStepToJSON,
} from './TextStep';

/**
 * 
 * @export
 * @interface CampaignStep
 */
export interface CampaignStep {
    /**
     * 
     * @type {string}
     * @memberof CampaignStep
     */
    id?: string;
    /**
     * 
     * @type {CampaignStepType}
     * @memberof CampaignStep
     */
    type: CampaignStepType;
    /**
     * 
     * @type {number}
     * @memberof CampaignStep
     */
    days_interval: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignStep
     */
    title: string;
    /**
     * 
     * @type {EmailStep}
     * @memberof CampaignStep
     */
    email_step?: EmailStep;
    /**
     * 
     * @type {TextStep}
     * @memberof CampaignStep
     */
    text_step?: TextStep;
    /**
     * 
     * @type {TaskStep}
     * @memberof CampaignStep
     */
    task_step?: TaskStep;
}

/**
 * Check if a given object implements the CampaignStep interface.
 */
export function instanceOfCampaignStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "days_interval" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function CampaignStepFromJSON(json: any): CampaignStep {
    return CampaignStepFromJSONTyped(json, false);
}

export function CampaignStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': CampaignStepTypeFromJSON(json['type']),
        'days_interval': json['days_interval'],
        'title': json['title'],
        'email_step': !exists(json, 'email_step') ? undefined : EmailStepFromJSON(json['email_step']),
        'text_step': !exists(json, 'text_step') ? undefined : TextStepFromJSON(json['text_step']),
        'task_step': !exists(json, 'task_step') ? undefined : TaskStepFromJSON(json['task_step']),
    };
}

export function CampaignStepToJSON(value?: CampaignStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CampaignStepTypeToJSON(value.type),
        'days_interval': value.days_interval,
        'title': value.title,
        'email_step': EmailStepToJSON(value.email_step),
        'text_step': TextStepToJSON(value.text_step),
        'task_step': TaskStepToJSON(value.task_step),
    };
}

