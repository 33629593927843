import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchMedications } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

type Update = {
  rrule: string;
  instance_generation_start_date: string;
  instance_generation_end_date: string;
  execution_window_end_time: string;
  number_of_dose_units: number;
};

export interface MedicationAuditPayload {
  medication_task_id: string;
  medication_task_audit_updates: {
    s3_prescription_key: any;
    before: string;
    after: string;
    date_modified: string;
    modified_by: {
      id: string;
      first_name: string;
      last_name: string;
    };
    field: string;
  }[];
  schedule_audit_updates: {
    before: Update[];
    after: Update[];
    modified_by: {
      id: string;
      first_name: string;
      last_name: string;
    };
    date_modified: string | null;
  }[];
}

export function useMedicationTaskAudit(
  queryString: { medication_task_ids?: string } | { resident_ids?: string }
) {
  const QUERY_KEY = ['useMedicationTaskAudit', queryString];
  const invalidate = () => {
    queryClient.invalidateQueries(QUERY_KEY);
  };

  return {
    invalidate,
    findAll: (options: UseQueryOptions<MedicationAuditPayload[]> = {}) => {
      return useQuery(['useMedicationTaskAudit', queryString], () => {
        return fetchMedications.get<MedicationAuditPayload[]>(
          '/medication-tasks/audit',
          {
            searchParams: { ...queryString },
            ...(options as any)
          }
        );
      });
    }
  };
}
