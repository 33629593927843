import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

import { RoutePath } from '@/routes/RoutePath';

interface AddIncidentButtonProps {
  residentId?: string;
}

const AddIncidentButton: React.FC<AddIncidentButtonProps> = ({
  residentId = ''
}) => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<Add />}
      onClick={() => {
        if (residentId) {
          navigate(
            RoutePath.AddResidentIncident.replace(':resident_id', residentId)
          );
        } else {
          navigate(RoutePath.AddIncident);
        }
      }}
      variant="contained"
      color="primary">
      Add Incident
    </Button>
  );
};

export default AddIncidentButton;
