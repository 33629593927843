import React, { useEffect, useRef, useState } from 'react';
import ReactDropzone, { DropzoneOptions, ErrorCode } from 'react-dropzone';
import { AttachFile, Cancel, PhotoCamera } from '@mui/icons-material';
import FileUpload from '@mui/icons-material/FileUpload';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import prettyBytes from 'pretty-bytes';

import { useSnackbar } from '@/hooks/useSnackbar';

import { UploadCircularProgress } from './CircularProgress';

type DropzoneProps = {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  progressPercentage?: number;
  handleOnFileUpload?: (acceptedFiles: File[]) => Promise<void>;
  showTakeAPhotoButton?: boolean;
} & DropzoneOptions;

let stream: MediaStream;

export const Dropzone: React.FC<DropzoneProps> = ({
  maxSize = 33554432, // 32MB
  inputProps = {},
  progressPercentage,
  showTakeAPhotoButton = false,
  handleOnFileUpload,
  disabled,
  ...props
}) => {
  const { showSnackbar } = useSnackbar();
  const [capturedImage, setCapturedImage] = useState<File | null>(null);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [isLoadingCapture, setIsCapturingImage] = useState<boolean>(false);
  const [isLoadingVideoPreview, setIsLoadingVideoPreview] =
    useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleCaptureImage = async () => {
    setIsCapturingImage(true);
    try {
      if (videoRef.current) {
        const mediaStreamTrack = (
          videoRef.current.srcObject as MediaStream
        ).getVideoTracks()[0];
        const imageCapture = new ImageCapture(mediaStreamTrack);

        const blob = await imageCapture.takePhoto();
        const file = new File([blob], 'captured-image.jpg', {
          type: blob.type
        });

        setCapturedImage(file);
        handleStopPreview();
      }
    } catch (error) {
      console.error('Error capturing image:', error);
    } finally {
      setIsCapturingImage(false);
    }
  };

  const handleUpload = async () => {
    try {
      setIsCapturingImage(true);
      await handleOnFileUpload?.([capturedImage as File]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCapturingImage(false);
    }
  };

  const handleStartPreview = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoadingVideoPreview(true);
    e.stopPropagation();
    try {
      setIsPreviewing(true);
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error starting camera preview:', error);
    } finally {
      setIsLoadingVideoPreview(false);
    }
  };

  const handleStopPreview = () => {
    if (videoRef.current) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setIsPreviewing(false);
    }
  };

  const handleOnDropRejected: DropzoneOptions['onDropRejected'] = (
    fileRejections
  ) => {
    fileRejections.forEach(({ errors, file }) => {
      if (errors.find(({ code }) => code === ErrorCode.FileTooLarge)) {
        showSnackbar({
          message: `File "${file.name}" is over ${prettyBytes(
            maxSize
          )} upload limit`,
          severity: 'error'
        });
      }
    });
  };

  useEffect(() => {
    return () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [videoRef]);

  const renderVideoPreview = () => {
    // Important: The video element must be rendered in the DOM for the ref to be assigned. Use CSS trick to hide the loading state of the video
    return isPreviewing ? (
      <Stack position="relative" gap={2} mt={2}>
        <Stack
          display={isLoadingVideoPreview ? 'flex' : 'none'}
          height="100%"
          direction="row"
          zIndex={9}
          top={0}
          position="absolute"
          bgcolor="#FFFFFF"
          justifyContent="center"
          width="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
        <Stack border="2px dashed #9AAEBB">
          <video
            ref={videoRef}
            autoPlay
            style={{ width: '100%', height: '300px' }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="center">
          <Button
            disabled={disabled}
            variant="contained"
            color="secondary"
            onClick={handleStopPreview}>
            Stop Preview
          </Button>
          <LoadingButton
            disabled={disabled}
            loading={isLoadingCapture}
            variant="contained"
            color="primary"
            onClick={handleCaptureImage}>
            Capture Image
          </LoadingButton>
        </Stack>
      </Stack>
    ) : (
      <></>
    );
  };

  const renderCapturedImage = () => {
    if (capturedImage) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          gap={2}
          mt={2}
          position="relative">
          <img
            src={URL.createObjectURL(capturedImage)}
            alt="Captured"
            style={{ width: '430px', height: 'auto' }}
          />
          <Stack direction="row" gap={2} justifyContent="end">
            <IconButton
              disabled={disabled}
              sx={{ position: 'absolute', top: 0, left: 0 }}
              onClick={() => {
                setCapturedImage(null);
              }}>
              <Cancel />
            </IconButton>
            <LoadingButton
              disabled={disabled}
              variant="contained"
              color="primary"
              loading={isLoadingCapture}
              onClick={handleUpload}>
              Confirm Upload
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }
  };

  useEffect(() => {
    if (showTakeAPhotoButton && handleOnFileUpload === undefined) {
      console.warn(
        'handleOnFileUpload is required when showTakeAPhotoButton is true'
      );
    }
  }, []);

  return (
    <ReactDropzone
      disabled={disabled}
      maxSize={maxSize}
      onDropRejected={handleOnDropRejected}
      {...props}>
      {({ getRootProps, getInputProps }) => {
        if (progressPercentage && progressPercentage < 100) {
          return (
            <Stack
              gap={2}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="400px"
              border="2px dashed #9AAEBB">
              <UploadCircularProgress value={progressPercentage} />
              <Typography
                variant="subtitle1"
                fontWeight={500}
                textAlign="center"
                lineHeight="20px">
                Your files are being uploaded
              </Typography>
            </Stack>
          );
        }
        return (
          <>
            {isPreviewing ? (
              renderVideoPreview()
            ) : (
              <Stack
                {...getRootProps()}
                alignItems="center"
                gap={2}
                padding={4}
                border="2px dashed #9AAEBB"
                width="100%"
                borderRadius="4px">
                <input {...getInputProps()} {...inputProps} />
                <FileUpload fontSize="large" />
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  textAlign="center"
                  lineHeight="20px">
                  Drag file(s) here or click the button below
                  <br />
                  to select them on your computer
                </Typography>
                <Stack direction="row" gap={2}>
                  <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    startIcon={<AttachFile />}>
                    Attach Files
                  </Button>
                  {showTakeAPhotoButton && (
                    <Button
                      disabled={disabled}
                      startIcon={<PhotoCamera />}
                      variant="contained"
                      onClick={handleStartPreview}
                      color="secondary">
                      Take Photo
                    </Button>
                  )}
                </Stack>
              </Stack>
            )}
            {renderCapturedImage()}
          </>
        );
      }}
    </ReactDropzone>
  );
};
