/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOneTimeBillRequest
 */
export interface CreateOneTimeBillRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOneTimeBillRequest
     */
    resident_id: string;
}

/**
 * Check if a given object implements the CreateOneTimeBillRequest interface.
 */
export function instanceOfCreateOneTimeBillRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;

    return isInstance;
}

export function CreateOneTimeBillRequestFromJSON(json: any): CreateOneTimeBillRequest {
    return CreateOneTimeBillRequestFromJSONTyped(json, false);
}

export function CreateOneTimeBillRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOneTimeBillRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
    };
}

export function CreateOneTimeBillRequestToJSON(value?: CreateOneTimeBillRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
    };
}

