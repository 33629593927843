import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Stack,
  styled
} from '@mui/material';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { FormHeader, FormHeaderProps } from './FormHeader';

interface MuiAccordionProps {
  isMobileViewport?: boolean;
}

/**
 * @deprecated Use the Accordion component from '@/components/ui/Accordion/Accordion' instead.
 */
export const StyledAccordion = styled(MuiAccordion, {
  shouldForwardProp: (prop) => prop !== 'isMobileViewport'
})<MuiAccordionProps>(({ isMobileViewport }) => ({
  border: '1px solid #D4DEE7',
  boxShadow: 'unset',
  borderRadius: '12px !important',
  background: '#FCFEFF',
  margin: '0 !important',
  '&::before': {
    display: 'none'
  },
  '& h3': {
    fontSize: isMobileViewport ? '16px' : '18px'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .Mui-expanded': {
    background:
      'linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05)), #FBFCFF',
    borderBottomLeftRadius: 'unset !important',
    borderBottomRightRadius: 'unset !important',
    marginBottom: isMobileViewport ? '16px' : '0'
  }
}));

export const StyledAccordionSummary = styled(MuiAccordionSummary)({
  borderRadius: '12px'
});

export const StyledAccordionDetails = styled(MuiAccordionDetails)(
  ({ padding }: { padding?: string }) => ({
    padding: padding ?? '16px',
    background: '#FCFEFF',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  })
);

type AccordionSummaryProps = {
  children: React.ReactNode;
  initiallyExpanded?: boolean;
  padding?: string;
  chip?: React.ReactNode;
} & FormHeaderProps;

/**
 *
 * @deprecated import { Accordion, AccordionDetails, AccordionSummary } from '@/components/ui/Accordion/Accordion';
 */
export const Accordion: React.FC<AccordionSummaryProps> = ({
  children,
  Icon,
  text,
  initiallyExpanded = true,
  padding,
  chip
}) => {
  const [expanded, setExpanded] = React.useState(initiallyExpanded);
  const isMobileViewport = useIsMobileViewport();
  return (
    <StyledAccordion expanded={expanded} isMobileViewport={isMobileViewport}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        onClick={() => setExpanded((state) => !state)}>
        <Box width={1} display="flex" justifyContent="space-between">
          <FormHeader Icon={Icon} text={text} />
          {chip && chip}
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails padding={padding}>
        <Stack spacing={2}>{children}</Stack>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
