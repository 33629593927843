import { useAuth0, User } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchUsers } from '@/adapters/fetchExaCare';
import { UserPayload } from '@/hooks/useUserQuery';
import { UserModel } from '@/models/UserModel';

export interface ExaCareAuth0User extends User {
  organization_id?: string;
  role?: string;
}

function toExaCareAuth0User(user: User = {}) {
  return Object.fromEntries(
    Object.entries(user).map(([key, value]) => {
      const last = key.lastIndexOf('/');
      if (last >= 0) {
        return [key.slice(last + 1), value];
      }
      return [key, value];
    })
  ) as ExaCareAuth0User;
}

export const USE_CURRENT_USER_QUERY_KEY = 'useCurrentUser';

export const useCurrentUser = (options: UseQueryOptions<UserModel> = {}) => {
  const { isAuthenticated, user: auth0User } = useAuth0();

  return useQuery<UserModel>(
    [USE_CURRENT_USER_QUERY_KEY],
    async () => {
      const user = await fetchUsers.get<UserPayload>('/users/current');
      const userModel = new UserModel(
        Object.assign(user, toExaCareAuth0User(auth0User))
      );

      if (import.meta.env.MODE === 'production') {
        window.Upscope('updateConnection', {
          // Set the user ID below. If you don't have one, set to undefined.
          uniqueId: user.id,

          // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
          identities: [user.first_name, user.last_name, user.email, user.phone]
        });
      }

      if (import.meta.env.PROD) {
        Sentry.setUser({
          email: user.email,
          id: user.id,
          username: user.username ?? undefined
        });
      }

      return userModel;
    },
    {
      enabled: isAuthenticated,
      refetchOnMount: false,
      ...options
    }
  );
};
