import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InfoOutlined, Report } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormControlLabelProps,
  Stack,
  Tooltip,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { FormHeader } from '@/components/FormHeader';
import { TotalDosage } from '@/components/TotalDosage';
import { useCustomMedicationsQuery } from '@/hooks/useCustomMedicationsQuery';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';
import { convertMinutesToHours } from '@/utils/convertMinutesToHours';

type UseFormReturnPrnFields = UseFormReturn<
  Pick<
    ResidentMedicationForm,
    | 'prn_max_daily_number_of_dose_units'
    | 'prn_min_interval_minutes'
    | 'DispensableDrug'
    | 'medication_id'
    | 'prescription_interval'
    | 'may_have_max_daily_dosage'
    | 'is_editable'
  >
>;

interface PrnLimitationsFormProps {
  formMethods: UseFormReturnPrnFields;
  numberOfDoseUnits: string;
}

enum PrnPrescriptionWarningState {
  NONE,
  UNACKNOWLEDGED,
  ACKNOWLEDGED
}

function initializePrescriptionWarningState(
  formMethods: UseFormReturnPrnFields,
  prescriptionLimitationFieldName: keyof Pick<
    ResidentMedicationForm,
    'prescription_interval' | 'may_have_max_daily_dosage'
  >,
  prnLimitationFieldName: keyof Pick<
    ResidentMedicationForm,
    'prn_min_interval_minutes' | 'prn_max_daily_number_of_dose_units'
  >
): PrnPrescriptionWarningState {
  const prescriptionLimitationValue = formMethods.getValues(
    prescriptionLimitationFieldName
  );
  const prnLimitationValue = formMethods.getValues(prnLimitationFieldName);

  // No need to show the warning if the prn limitation is already set
  if (!prescriptionLimitationValue || prnLimitationValue) {
    return PrnPrescriptionWarningState.NONE;
  }

  return PrnPrescriptionWarningState.UNACKNOWLEDGED;
}

function validateMaxDailyDosage(maxDailyDosage: any, numberOfDoseUnits: any) {
  if (maxDailyDosage && numberOfDoseUnits) {
    return parseFloat(maxDailyDosage) < parseFloat(numberOfDoseUnits)
      ? `Must be equal to or greater than default doses (${numberOfDoseUnits})`
      : true;
  }

  return true;
}

export const PrnLimitationsForm: React.FC<PrnLimitationsFormProps> = ({
  formMethods,
  numberOfDoseUnits
}) => {
  const [prescriptionIntervalWarning, setPrescriptionIntervalWarning] =
    React.useState<PrnPrescriptionWarningState>(
      initializePrescriptionWarningState(
        formMethods,
        'prescription_interval',
        'prn_min_interval_minutes'
      )
    );
  const [prescriptionMaxDosageWarning, setPrescriptionMaxDosageWarning] =
    React.useState<PrnPrescriptionWarningState>(
      initializePrescriptionWarningState(
        formMethods,
        'may_have_max_daily_dosage',
        'prn_max_daily_number_of_dose_units'
      )
    );
  const [hasMinimumInterval, setHasMinimumInterval] = React.useState(
    !!formMethods.getValues('prn_min_interval_minutes') ||
      prescriptionIntervalWarning === PrnPrescriptionWarningState.UNACKNOWLEDGED
  );
  const [hasMaxDailyDosage, setHasMaxDailyDosage] = React.useState(
    !!formMethods.getValues('prn_max_daily_number_of_dose_units') ||
      prescriptionMaxDosageWarning ===
        PrnPrescriptionWarningState.UNACKNOWLEDGED
  );

  const [
    prnMaxDailyDoses,
    prnMinIntervalMinutes,
    dispensableDrug,
    customMedicationId,
    isEditable
  ] = formMethods.watch([
    'prn_max_daily_number_of_dose_units',
    'prn_min_interval_minutes',
    'DispensableDrug',
    'medication_id',
    'is_editable'
  ]);

  const { data: customMedication } =
    useCustomMedicationsQuery().findOne(customMedicationId);

  const handleToggleMinimumInterval: FormControlLabelProps['onChange'] = (
    e,
    checked
  ) => {
    setHasMinimumInterval(checked);
    if (!checked) {
      acknowledgePrescriptionWarning(setPrescriptionIntervalWarning);
      formMethods.setValue('prn_min_interval_minutes', null, {
        shouldDirty: true
      });
    }
  };

  const handleToggleMaxDailyDosage: FormControlLabelProps['onChange'] = (
    e,
    checked
  ) => {
    setHasMaxDailyDosage(checked);
    if (!checked) {
      acknowledgePrescriptionWarning(setPrescriptionMaxDosageWarning);
      formMethods.setValue('prn_max_daily_number_of_dose_units', null, {
        shouldDirty: true
      });
    }
  };

  const acknowledgePrescriptionWarning = (
    setPrescriptionWarning: React.Dispatch<
      React.SetStateAction<PrnPrescriptionWarningState>
    >
  ) => {
    setPrescriptionWarning(PrnPrescriptionWarningState.ACKNOWLEDGED);
  };

  return (
    <Stack>
      <FormHeader
        text="PRN Limitations"
        fontSize="18px"
        Icon={Report}
        sx={{ mb: 1 }}
      />
      <Collapse
        in={
          prescriptionIntervalWarning ===
          PrnPrescriptionWarningState.UNACKNOWLEDGED
        }
        unmountOnExit>
        <Alert severity="error">
          <AlertTitle>Careful!</AlertTitle>
          It looks like this prescription might set out a minimum interval
          between doses for this PRN medication. Either dismiss this warning or
          enter the minimum interval to proceed.
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  acknowledgePrescriptionWarning(setPrescriptionIntervalWarning)
                }
              />
            }
            label="Dismiss this warning"
          />
        </Alert>
      </Collapse>
      <FormControlLabel
        checked={hasMinimumInterval}
        onChange={handleToggleMinimumInterval}
        control={<Checkbox />}
        disabled={!isEditable}
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            Set Minimum PRN Interval?
            <Tooltip title="Users will be unable to pass this PRN med if the minimum interval has not elapsed.">
              <InfoOutlined fontSize="small" htmlColor="#667A86" />
            </Tooltip>
          </Stack>
        }
      />
      <Collapse in={hasMinimumInterval} unmountOnExit>
        <Grid container spacing={1} my={1}>
          <Grid xs={12}>
            <ControlledTextField
              autoComplete="off"
              control={formMethods.control}
              name={'prn_min_interval_minutes'}
              rules={{ required: true }}
              label="Min PRN Interval Minutes"
              type="number"
              format="integer"
              onChange={() =>
                acknowledgePrescriptionWarning(setPrescriptionIntervalWarning)
              }
              inputProps={{
                min: 1,
                step: 15
              }}
              disabled={!isEditable}
              helperText={convertMinutesToHours(
                prnMinIntervalMinutes?.toString()
              )}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse
        in={
          prescriptionMaxDosageWarning ===
          PrnPrescriptionWarningState.UNACKNOWLEDGED
        }
        unmountOnExit>
        <Alert severity="error">
          <AlertTitle>Careful!</AlertTitle>
          It looks like this prescription might set out a maximum daily dosage
          for this PRN medication. Either dismiss this warning or enter the
          maximum daily dosage to proceed.
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  acknowledgePrescriptionWarning(
                    setPrescriptionMaxDosageWarning
                  )
                }
              />
            }
            label="Dismiss this warning"
          />
        </Alert>
      </Collapse>
      <FormControlLabel
        checked={hasMaxDailyDosage}
        onChange={handleToggleMaxDailyDosage}
        control={<Checkbox />}
        disabled={!isEditable}
        label={
          <Stack direction="row" alignItems="center" gap={1}>
            Set Maximum PRN Daily Dosage?
            <Tooltip title="Users will be unable to pass this PRN med if the maximum daily dosage has been reached.">
              <InfoOutlined fontSize="small" htmlColor="#667A86" />
            </Tooltip>
          </Stack>
        }
      />
      <Collapse in={hasMaxDailyDosage} unmountOnExit>
        <Grid container spacing={1} mt={1}>
          <Grid xs={12}>
            <ControlledTextField
              sx={{
                '& .MuiFormHelperText-root': {
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  zIndex: 1
                }
              }}
              type="number"
              maxDecimalPlaces={2}
              autoComplete="off"
              control={formMethods.control}
              name={'prn_max_daily_number_of_dose_units'}
              rules={{
                required: true,
                validate: (value) =>
                  validateMaxDailyDosage(value, numberOfDoseUnits)
              }}
              label="Max Daily Doses"
              inputProps={{
                min: 0.01,
                step: 0.01
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={() =>
                acknowledgePrescriptionWarning(setPrescriptionMaxDosageWarning)
              }
              disabled={!isEditable}
            />
          </Grid>
          <Grid xs={12}>
            <TotalDosage
              body="Max Daily Dosage:"
              dispensable={dispensableDrug}
              numberOfDoseUnits={prnMaxDailyDoses}
              customMedication={customMedication}
              severity="warning"
              sx={{ flex: 1, top: 3, position: 'relative' }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" gap={1} mt={1} alignItems="flex-start"></Stack>
      </Collapse>
    </Stack>
  );
};
