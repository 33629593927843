/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Room } from './Room';
import {
    RoomFromJSON,
    RoomFromJSONTyped,
    RoomToJSON,
} from './Room';

/**
 * 
 * @export
 * @interface RoomType
 */
export interface RoomType {
    /**
     * 
     * @type {string}
     * @memberof RoomType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomType
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof RoomType
     */
    name: string;
    /**
     * 
     * @type {Array<Room>}
     * @memberof RoomType
     */
    rooms?: Array<Room>;
}

/**
 * Check if a given object implements the RoomType interface.
 */
export function instanceOfRoomType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function RoomTypeFromJSON(json: any): RoomType {
    return RoomTypeFromJSONTyped(json, false);
}

export function RoomTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'rooms': !exists(json, 'rooms') ? undefined : ((json['rooms'] as Array<any>).map(RoomFromJSON)),
    };
}

export function RoomTypeToJSON(value?: RoomType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'rooms': value.rooms === undefined ? undefined : ((value.rooms as Array<any>).map(RoomToJSON)),
    };
}

