/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolFormListEntry } from './IncidentProtocolFormListEntry';
import {
    IncidentProtocolFormListEntryFromJSON,
    IncidentProtocolFormListEntryFromJSONTyped,
    IncidentProtocolFormListEntryToJSON,
} from './IncidentProtocolFormListEntry';

/**
 * 
 * @export
 * @interface FindProtocolForm200Response
 */
export interface FindProtocolForm200Response {
    /**
     * 
     * @type {IncidentProtocolFormListEntry}
     * @memberof FindProtocolForm200Response
     */
    data?: IncidentProtocolFormListEntry;
}

/**
 * Check if a given object implements the FindProtocolForm200Response interface.
 */
export function instanceOfFindProtocolForm200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindProtocolForm200ResponseFromJSON(json: any): FindProtocolForm200Response {
    return FindProtocolForm200ResponseFromJSONTyped(json, false);
}

export function FindProtocolForm200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindProtocolForm200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentProtocolFormListEntryFromJSON(json['data']),
    };
}

export function FindProtocolForm200ResponseToJSON(value?: FindProtocolForm200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentProtocolFormListEntryToJSON(value.data),
    };
}

