/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountInformationalCPE200ResponseData
 */
export interface CountInformationalCPE200ResponseData {
    /**
     * 
     * @type {number}
     * @memberof CountInformationalCPE200ResponseData
     */
    count: number;
}

/**
 * Check if a given object implements the CountInformationalCPE200ResponseData interface.
 */
export function instanceOfCountInformationalCPE200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function CountInformationalCPE200ResponseDataFromJSON(json: any): CountInformationalCPE200ResponseData {
    return CountInformationalCPE200ResponseDataFromJSONTyped(json, false);
}

export function CountInformationalCPE200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountInformationalCPE200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
    };
}

export function CountInformationalCPE200ResponseDataToJSON(value?: CountInformationalCPE200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
    };
}

