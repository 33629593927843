/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HospitalMessage } from './HospitalMessage';
import {
    HospitalMessageFromJSON,
    HospitalMessageFromJSONTyped,
    HospitalMessageToJSON,
} from './HospitalMessage';

/**
 * 
 * @export
 * @interface CreateHospitalMessage200Response
 */
export interface CreateHospitalMessage200Response {
    /**
     * 
     * @type {HospitalMessage}
     * @memberof CreateHospitalMessage200Response
     */
    data?: HospitalMessage;
}

/**
 * Check if a given object implements the CreateHospitalMessage200Response interface.
 */
export function instanceOfCreateHospitalMessage200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateHospitalMessage200ResponseFromJSON(json: any): CreateHospitalMessage200Response {
    return CreateHospitalMessage200ResponseFromJSONTyped(json, false);
}

export function CreateHospitalMessage200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHospitalMessage200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : HospitalMessageFromJSON(json['data']),
    };
}

export function CreateHospitalMessage200ResponseToJSON(value?: CreateHospitalMessage200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': HospitalMessageToJSON(value.data),
    };
}

