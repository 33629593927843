import React from 'react';
import { DoneAll, Place } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled
} from '@mui/material';

import { ALL_FACILITIES } from '@/hooks/useFacilitiesQuery';
import { UserModel } from '@/models/UserModel';

const StyledListItemButton = styled(ListItemButton)({
  p: '12px 16px',
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #D4DEE7'
  },
  '& .MuiListItemText-primary': {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#0A1E28'
  },
  '& .MuiListItemText-secondary': {
    display: 'flex',
    alignItems: 'center',
    color: '#667A86',
    position: 'relative',
    right: '2px'
  },
  '& .MuiSvgIcon-root': {
    color: '#9AAEBB'
  },
  '& .MuiListItemIcon-root': {
    minWidth: 32
  }
});

interface FacilitySelectorPopoverProps {
  selectedFacilityId: string | null;
  anchorEl: HTMLDivElement;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserModel;
  onSelectFacility: (facilityId: string) => void;
  canSelectAllFacilities: boolean;
  isCRM: boolean;
}

const FacilitySelectorPopover: React.FC<FacilitySelectorPopoverProps> = ({
  selectedFacilityId,
  onSelectFacility,
  isOpen,
  user,
  setIsOpen,
  anchorEl,
  canSelectAllFacilities,
  isCRM
}) => {
  return (
    <Popover
      PaperProps={{
        sx: {
          maxHeight: 400,
          boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
          borderRadius: '8px'
        }
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <List sx={{ p: 0 }}>
        {!isCRM && canSelectAllFacilities && (
          <StyledListItemButton
            selected={selectedFacilityId === ALL_FACILITIES}
            onClick={() => {
              onSelectFacility(ALL_FACILITIES);
              setIsOpen(false);
            }}>
            <ListItemIcon>
              <DoneAll />
            </ListItemIcon>
            <ListItemText>Select All</ListItemText>
          </StyledListItemButton>
        )}
        {user.user_facility_accesses!.map((item) => (
          <StyledListItemButton
            id={`community-${item.facility_id}`}
            onClick={() => onSelectFacility(item.facility_id)}
            selected={item.facility_id === selectedFacilityId}
            key={item.id}>
            <ListItemText
              secondary={
                <>
                  <Place />
                  {item.facility.address}
                </>
              }>
              {item.facility.name}
            </ListItemText>
          </StyledListItemButton>
        ))}
      </List>
    </Popover>
  );
};

export default FacilitySelectorPopover;
