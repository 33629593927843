/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationStockSignOff } from './MedicationStockSignOff';
import {
    MedicationStockSignOffFromJSON,
    MedicationStockSignOffFromJSONTyped,
    MedicationStockSignOffToJSON,
} from './MedicationStockSignOff';

/**
 * 
 * @export
 * @interface CreateMedicationStockSignOff201Response
 */
export interface CreateMedicationStockSignOff201Response {
    /**
     * 
     * @type {MedicationStockSignOff}
     * @memberof CreateMedicationStockSignOff201Response
     */
    data?: MedicationStockSignOff;
}

/**
 * Check if a given object implements the CreateMedicationStockSignOff201Response interface.
 */
export function instanceOfCreateMedicationStockSignOff201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateMedicationStockSignOff201ResponseFromJSON(json: any): CreateMedicationStockSignOff201Response {
    return CreateMedicationStockSignOff201ResponseFromJSONTyped(json, false);
}

export function CreateMedicationStockSignOff201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMedicationStockSignOff201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : MedicationStockSignOffFromJSON(json['data']),
    };
}

export function CreateMedicationStockSignOff201ResponseToJSON(value?: CreateMedicationStockSignOff201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': MedicationStockSignOffToJSON(value.data),
    };
}

