import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SendIcon from '@mui/icons-material/Send';
import {
  Avatar,
  Box,
  createFilterOptions,
  Icon,
  Typography
} from '@mui/material';

import { AvatarS3PhotoKey } from '@/components/ui/Avatar/AvatarS3PhotoKey';
import { ProspectResidentPayload } from '@/models/ProspectResidentModel';
import { ReferralPayload } from '@/models/ReferralModel';
import { ResidentCloseConnectionPayload } from '@/models/ResidentCloseConnectionModel';
import theme from '@/theme';
import { DealTemperature } from '@/types/crm-campaigns';

import { CrmRelationType } from './CrmTaskModel';

export const phoneBookAutocompleteFilterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: PhoneBookItemModel) =>
    `${option.first_name}${option.last_name}${option.phone}${option.type}`
});

export enum PhoneBookItemType {
  // 'All' value was added to represent all facilities in the FE only
  All = 'All',
  Resident = 'Resident',
  Staff = 'Staff',
  ReferralSource = 'Referral Source',
  CloseConnection = 'Resident Close Connection',
  Doctor = 'Doctor',
  Hospital = 'Hospital',
  Pharmacy = 'Pharmacy',
  FuneralHome = 'Funeral Home',
  Provider = 'Provider',
  ProspectResident = 'Prospect Resident',
  ReferralSourceCompany = 'Referral Source Company',
  ScreenerSourceContact = 'Screener Source Contact'
}

export interface PhoneBookItemPage {
  page: number;
  limit: number;
  hasNextPage: boolean;
  total: number;
  data: PhoneBookItemModel[];
}

export class PhoneBookItemPayload {
  facility_id: string;
  resource_id: string;
  first_name: string;
  last_name: string | null;
  email: string | null;
  phone: string;
  s3_photo_key?: string | null;
  type: PhoneBookItemType;
  phone_type: string;
  crm_lead_temperature: DealTemperature | null;
  crmRelation?:
    | ResidentCloseConnectionPayload
    | ProspectResidentPayload
    | ReferralPayload
    | null;

  constructor(payload: PhoneBookItemPayload) {
    Object.assign(this, payload);
  }
  get initials(): string {
    return `${this.first_name?.charAt(0) || ''}${
      this.last_name?.charAt(0) || ''
    }`;
  }
  get fullName(): string {
    if (!this.first_name) return 'Unknown';
    return `${this.first_name} ${this.last_name || ''}`;
  }
}

interface RenderDetailsCellParams {
  key?: React.Key;
  showCallButton?: boolean;
  onClickContactCall?: (phoneBookItem: PhoneBookItemModel) => void;
  contactMode?: 'call' | 'text';
}

export class PhoneBookItemModel extends PhoneBookItemPayload {
  constructor(payload: PhoneBookItemPayload) {
    super(payload);
  }

  public renderDetailsCell = ({
    key,
    showCallButton,
    onClickContactCall,
    contactMode
  }: RenderDetailsCellParams = {}) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        key={key}
        justifyContent="space-between"
        gap={2}>
        <Box gap="8px" display="flex" alignItems="center">
          <AvatarS3PhotoKey s3PhotoKey={this.s3_photo_key}>
            {this.initials}
          </AvatarS3PhotoKey>
          <Box display="column">
            <Box display="flex" alignItems="center" gap="8px">
              {this.fullName}
              <Typography variant="body2">{this.type}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={'5px'}>
              {this.phone_type && (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={theme.palette.primary.main}>
                  {`${this.phone_type}:`}
                </Typography>
              )}
              <Typography variant="body2" color={theme.palette.primary.main}>
                {this.phone}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showCallButton && onClickContactCall && (
          <Avatar
            onClick={() => onClickContactCall(this)}
            sx={() => ({
              color: '#364955',
              background: '#E7EEF4',
              width: 32,
              height: 32,
              ':hover': {
                cursor: 'pointer'
              }
            })}>
            <Icon
              component={contactMode === 'text' ? SendIcon : LocalPhoneIcon}
            />
          </Avatar>
        )}
      </Box>
    );
  };

  public getCrmRelationType(): CrmRelationType | undefined {
    switch (this.type) {
      case PhoneBookItemType.Staff:
        return 'USER';
      case PhoneBookItemType.ProspectResident:
        return 'PROSPECT_RESIDENT';
      case PhoneBookItemType.CloseConnection:
        return 'RESIDENT_CLOSE_CONNECTION';
      case PhoneBookItemType.ReferralSource:
        return 'REFERRAL';
      default:
        // Not a CRM relation
        return undefined;
    }
  }
}
