/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionDetailsDetails } from './BidirectionalActionDetailsDetails';
import {
    BidirectionalActionDetailsDetailsFromJSON,
    BidirectionalActionDetailsDetailsFromJSONTyped,
    BidirectionalActionDetailsDetailsToJSON,
} from './BidirectionalActionDetailsDetails';

/**
 * 
 * @export
 * @interface BidirectionalActionDetails
 */
export interface BidirectionalActionDetails {
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetails
     */
    action: BidirectionalActionDetailsActionEnum;
    /**
     * 
     * @type {BidirectionalActionDetailsDetails}
     * @memberof BidirectionalActionDetails
     */
    details?: BidirectionalActionDetailsDetails;
}


/**
 * @export
 */
export const BidirectionalActionDetailsActionEnum = {
    Accept: 'Accept',
    Decline: 'Decline',
    RequestInformation: 'RequestInformation'
} as const;
export type BidirectionalActionDetailsActionEnum = typeof BidirectionalActionDetailsActionEnum[keyof typeof BidirectionalActionDetailsActionEnum];


/**
 * Check if a given object implements the BidirectionalActionDetails interface.
 */
export function instanceOfBidirectionalActionDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function BidirectionalActionDetailsFromJSON(json: any): BidirectionalActionDetails {
    return BidirectionalActionDetailsFromJSONTyped(json, false);
}

export function BidirectionalActionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'details': !exists(json, 'details') ? undefined : BidirectionalActionDetailsDetailsFromJSON(json['details']),
    };
}

export function BidirectionalActionDetailsToJSON(value?: BidirectionalActionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'details': BidirectionalActionDetailsDetailsToJSON(value.details),
    };
}

