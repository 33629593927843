import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Box, BoxProps, Typography } from '@mui/material';

export interface FormHeaderProps extends BoxProps {
  Icon?: SvgIconComponent;
  text: string;
  headerVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontSize?: string;
}

export const FormHeader: React.FC<FormHeaderProps> = ({
  Icon,
  text,
  headerVariant = 'h3',
  fontSize = '18px',
  ...props
}) => (
  <Box display="flex" alignItems="center" {...props} className="form-header">
    <>
      {Icon && <Icon htmlColor="#667A86" sx={{ mr: '10px' }} />}
      <Typography variant={headerVariant} fontSize={fontSize} color="secondary">
        {text}
      </Typography>
    </>
  </Box>
);
