import React, { useEffect, useState } from 'react';
import { IReactToPrintProps, useReactToPrint } from 'react-to-print';

export function usePrint({
  onAfterPrint,
  ...props
}: Omit<IReactToPrintProps, 'content' | 'onBeforeGetContent'> = {}) {
  const [printView, setPrintView] = useState(false);
  const printRef = React.useRef<any>(null);
  const printRefPromise = React.useRef<any>(null);

  const getPageMargins = () => {
    return `@page { margin: 24px !important; }`;
  };

  const handlePrintAsPdf = useReactToPrint({
    content: () => printRef?.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        printRefPromise.current = resolve;
        setPrintView(true);
      });
    },
    onAfterPrint: () => {
      printRefPromise.current = null;
      setPrintView(false);
      onAfterPrint?.();
    },
    ...props
  });

  useEffect(() => {
    if (printView && printRefPromise.current) {
      printRefPromise.current();
    }
  }, [printView]);

  return {
    printView,
    setPrintView,
    handlePrintAsPdf,
    printRef,
    getPageMargins
  };
}
