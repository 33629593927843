import {
  DoctorSearchResult,
  DoctorSearchResultSource,
  SearchDoctorsResponse
} from '@/types/residents';

export class DoctorSearchResultModel implements DoctorSearchResult {
  id?: string;
  name?: string;
  type?: string;
  levenshtein_distance?: number;
  doctor_source?: DoctorSearchResultSource;
  source: string;

  constructor(payload: DoctorSearchResult) {
    Object.assign(this, payload);

    switch (payload.doctor_source) {
      case 'RESIDENT':
        this.source = 'Resident Doctors';
        break;
      case 'FACILITY':
        this.source = 'Community Doctors';
        break;
      case 'ORGANIZATION':
        this.source = 'Organization Doctors';
        break;
    }
  }
}

export class ResidentDoctorModel implements SearchDoctorsResponse {
  exact_match?: DoctorSearchResult;
  resident_doctors: DoctorSearchResult[];
  facility_doctors: DoctorSearchResult[];
  organization_doctors: DoctorSearchResult[];

  providerName?: string;
  allDoctors: DoctorSearchResultModel[];
  closestMatch?: DoctorSearchResult;

  constructor(payload: SearchDoctorsResponse, providerName?: string) {
    this.exact_match = payload.exact_match;
    this.resident_doctors = payload.resident_doctors ?? [];
    this.facility_doctors = payload.facility_doctors ?? [];
    this.organization_doctors = payload.organization_doctors ?? [];
    this.providerName = providerName;
    this.allDoctors = this.allDoctorsSortedByLevenshteinDistance();
    this.closestMatch = this.allDoctors[0];
  }

  private allDoctorsSortedByLevenshteinDistance(): DoctorSearchResultModel[] {
    return [
      ...this.resident_doctors,
      ...this.facility_doctors,
      ...this.organization_doctors
    ]
      .map((doctor) => new DoctorSearchResultModel(doctor))
      .sort(this.doctorSortFunction);
  }

  public get alertMessage(): string | undefined {
    if (this.exact_match?.doctor_source === 'FACILITY') {
      return `${this.exact_match?.name} prescribed this medication, but they are not listed as a doctor for this resident. We'll automatically add them to the resident's doctor list.`;
    }

    if (
      this.exact_match?.doctor_source === 'ORGANIZATION' ||
      !this.allDoctors.length
    ) {
      return `${
        this.exact_match?.name || this.providerName
      } prescribed this medication, but they are not listed as a doctor for this community. We'll automatically add them to both the resident's doctor list, as well as the community’s doctor list.`;
    }
  }

  private doctorSortFunction(a: DoctorSearchResult, b: DoctorSearchResult) {
    return (a.levenshtein_distance || 0) - (b.levenshtein_distance || 0);
  }
}
