/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolAction } from './IncidentProtocolAction';
import {
    IncidentProtocolActionFromJSON,
    IncidentProtocolActionFromJSONTyped,
    IncidentProtocolActionToJSON,
} from './IncidentProtocolAction';

/**
 * 
 * @export
 * @interface CreateProtocolAction200Response
 */
export interface CreateProtocolAction200Response {
    /**
     * 
     * @type {IncidentProtocolAction}
     * @memberof CreateProtocolAction200Response
     */
    data?: IncidentProtocolAction;
}

/**
 * Check if a given object implements the CreateProtocolAction200Response interface.
 */
export function instanceOfCreateProtocolAction200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateProtocolAction200ResponseFromJSON(json: any): CreateProtocolAction200Response {
    return CreateProtocolAction200ResponseFromJSONTyped(json, false);
}

export function CreateProtocolAction200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProtocolAction200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentProtocolActionFromJSON(json['data']),
    };
}

export function CreateProtocolAction200ResponseToJSON(value?: CreateProtocolAction200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentProtocolActionToJSON(value.data),
    };
}

