/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocol } from './IncidentProtocol';
import {
    IncidentProtocolFromJSON,
    IncidentProtocolFromJSONTyped,
    IncidentProtocolToJSON,
} from './IncidentProtocol';

/**
 * 
 * @export
 * @interface CreateProtocol200Response
 */
export interface CreateProtocol200Response {
    /**
     * 
     * @type {IncidentProtocol}
     * @memberof CreateProtocol200Response
     */
    data?: IncidentProtocol;
}

/**
 * Check if a given object implements the CreateProtocol200Response interface.
 */
export function instanceOfCreateProtocol200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateProtocol200ResponseFromJSON(json: any): CreateProtocol200Response {
    return CreateProtocol200ResponseFromJSONTyped(json, false);
}

export function CreateProtocol200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProtocol200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentProtocolFromJSON(json['data']),
    };
}

export function CreateProtocol200ResponseToJSON(value?: CreateProtocol200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentProtocolToJSON(value.data),
    };
}

