/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmPermissionsResponse
 */
export interface ApfmPermissionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApfmPermissionsResponse
     */
    do_not_call?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmPermissionsResponse
     */
    primary_contact_allow_contact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmPermissionsResponse
     */
    resident_contact_allow_contact?: boolean;
}

/**
 * Check if a given object implements the ApfmPermissionsResponse interface.
 */
export function instanceOfApfmPermissionsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmPermissionsResponseFromJSON(json: any): ApfmPermissionsResponse {
    return ApfmPermissionsResponseFromJSONTyped(json, false);
}

export function ApfmPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'do_not_call': !exists(json, 'do_not_call') ? undefined : json['do_not_call'],
        'primary_contact_allow_contact': !exists(json, 'primary_contact_allow_contact') ? undefined : json['primary_contact_allow_contact'],
        'resident_contact_allow_contact': !exists(json, 'resident_contact_allow_contact') ? undefined : json['resident_contact_allow_contact'],
    };
}

export function ApfmPermissionsResponseToJSON(value?: ApfmPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'do_not_call': value.do_not_call,
        'primary_contact_allow_contact': value.primary_contact_allow_contact,
        'resident_contact_allow_contact': value.resident_contact_allow_contact,
    };
}

