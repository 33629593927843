/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IncidentOrientation = {
    Person: 'oriented_to_person',
    Place: 'oriented_to_place',
    Time: 'oriented_to_time',
    Situation: 'oriented_to_situation'
} as const;
export type IncidentOrientation = typeof IncidentOrientation[keyof typeof IncidentOrientation];


export function IncidentOrientationFromJSON(json: any): IncidentOrientation {
    return IncidentOrientationFromJSONTyped(json, false);
}

export function IncidentOrientationFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentOrientation {
    return json as IncidentOrientation;
}

export function IncidentOrientationToJSON(value?: IncidentOrientation | null): any {
    return value as any;
}

