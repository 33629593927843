/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilityBillingInfo
 */
export interface FacilityBillingInfo {
    /**
     * 
     * @type {string}
     * @memberof FacilityBillingInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityBillingInfo
     */
    facility_id: string;
    /**
     * 
     * @type {number}
     * @memberof FacilityBillingInfo
     */
    auto_bill_day_of_month: number;
}

/**
 * Check if a given object implements the FacilityBillingInfo interface.
 */
export function instanceOfFacilityBillingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "auto_bill_day_of_month" in value;

    return isInstance;
}

export function FacilityBillingInfoFromJSON(json: any): FacilityBillingInfo {
    return FacilityBillingInfoFromJSONTyped(json, false);
}

export function FacilityBillingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityBillingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'auto_bill_day_of_month': json['auto_bill_day_of_month'],
    };
}

export function FacilityBillingInfoToJSON(value?: FacilityBillingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'auto_bill_day_of_month': value.auto_bill_day_of_month,
    };
}

