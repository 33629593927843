/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatientOrganizationDetails
 */
export interface PccPatientOrganizationDetails {
    /**
     * 
     * @type {string}
     * @memberof PccPatientOrganizationDetails
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientOrganizationDetails
     */
    display: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientOrganizationDetails
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientOrganizationDetails
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientOrganizationDetails
     */
    code?: string;
}

/**
 * Check if a given object implements the PccPatientOrganizationDetails interface.
 */
export function instanceOfPccPatientOrganizationDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "display" in value;

    return isInstance;
}

export function PccPatientOrganizationDetailsFromJSON(json: any): PccPatientOrganizationDetails {
    return PccPatientOrganizationDetailsFromJSONTyped(json, false);
}

export function PccPatientOrganizationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientOrganizationDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'display': json['display'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function PccPatientOrganizationDetailsToJSON(value?: PccPatientOrganizationDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'display': value.display,
        'phone': value.phone,
        'address': value.address,
        'code': value.code,
    };
}

