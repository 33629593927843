/* tslint:disable */
/* eslint-disable */
/**
 * data-center
 * data-center
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardListEntry } from './DashboardListEntry';
import {
    DashboardListEntryFromJSON,
    DashboardListEntryFromJSONTyped,
    DashboardListEntryToJSON,
} from './DashboardListEntry';

/**
 * 
 * @export
 * @interface FindAllDashboards200Response
 */
export interface FindAllDashboards200Response {
    /**
     * 
     * @type {Array<DashboardListEntry>}
     * @memberof FindAllDashboards200Response
     */
    data?: Array<DashboardListEntry>;
}

/**
 * Check if a given object implements the FindAllDashboards200Response interface.
 */
export function instanceOfFindAllDashboards200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllDashboards200ResponseFromJSON(json: any): FindAllDashboards200Response {
    return FindAllDashboards200ResponseFromJSONTyped(json, false);
}

export function FindAllDashboards200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllDashboards200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(DashboardListEntryFromJSON)),
    };
}

export function FindAllDashboards200ResponseToJSON(value?: FindAllDashboards200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(DashboardListEntryToJSON)),
    };
}

