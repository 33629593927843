/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetLeadAssessmentForm200Response,
  GetMedicareAssessmentFormOptions200Response,
  GetMedicareEstimates200Response,
  GetMedicarePtOtMappings200Response,
  MedicareAssessmentForm,
} from '../models/index';
import {
    GetLeadAssessmentForm200ResponseFromJSON,
    GetLeadAssessmentForm200ResponseToJSON,
    GetMedicareAssessmentFormOptions200ResponseFromJSON,
    GetMedicareAssessmentFormOptions200ResponseToJSON,
    GetMedicareEstimates200ResponseFromJSON,
    GetMedicareEstimates200ResponseToJSON,
    GetMedicarePtOtMappings200ResponseFromJSON,
    GetMedicarePtOtMappings200ResponseToJSON,
    MedicareAssessmentFormFromJSON,
    MedicareAssessmentFormToJSON,
} from '../models/index';

export interface GetLeadAssessmentFormRequest {
    leadId: string;
}

export interface GetMedicareEstimatesRequest {
    leadId: string;
}

export interface UpdateLeadAssessmentFormRequest {
    leadId: string;
    medicareAssessmentForm: MedicareAssessmentForm;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Medicare Assessment Form for a lead
     */
    async getLeadAssessmentFormRaw(requestParameters: GetLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadAssessmentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getLeadAssessmentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/assessment`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadAssessmentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Assessment Form for a lead
     */
    async getLeadAssessmentForm(requestParameters: GetLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadAssessmentForm200Response> {
        const response = await this.getLeadAssessmentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Assessment Form Options
     */
    async getMedicareAssessmentFormOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicareAssessmentFormOptions200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicare/assessment-form-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicareAssessmentFormOptions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Assessment Form Options
     */
    async getMedicareAssessmentFormOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicareAssessmentFormOptions200Response> {
        const response = await this.getMedicareAssessmentFormOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimatesRaw(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicareEstimates200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getMedicareEstimates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicareEstimates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimates(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicareEstimates200Response> {
        const response = await this.getMedicareEstimatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicarePtOtMappings200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicare/pt-ot-mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicarePtOtMappings200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicarePtOtMappings200Response> {
        const response = await this.getMedicarePtOtMappingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Medicare Assessment Form for a lead
     */
    async updateLeadAssessmentFormRaw(requestParameters: UpdateLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeadAssessmentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateLeadAssessmentForm.');
        }

        if (requestParameters.medicareAssessmentForm === null || requestParameters.medicareAssessmentForm === undefined) {
            throw new runtime.RequiredError('medicareAssessmentForm','Required parameter requestParameters.medicareAssessmentForm was null or undefined when calling updateLeadAssessmentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/assessment`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicareAssessmentFormToJSON(requestParameters.medicareAssessmentForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeadAssessmentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Update Medicare Assessment Form for a lead
     */
    async updateLeadAssessmentForm(requestParameters: UpdateLeadAssessmentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeadAssessmentForm200Response> {
        const response = await this.updateLeadAssessmentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
