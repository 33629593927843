import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAtomValue } from 'jotai/utils';
import omit from 'lodash/omit';

import { fetchCRM } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom
} from '@/hooks/useFacilitiesQuery';

import {
  CrmTaskCountModel,
  CrmTaskCountPayload
} from '../models/CrmTaskCountModel';
import {
  CrmTaskInstanceModel,
  CrmTaskInstancePayload
} from '../models/CrmTaskInstanceModel';
import { CrmRelationType, CrmTaskType } from '../models/CrmTaskModel';

import { EVENT_LOG_FIND_ALL_QUERY_KEY } from './useCrmEventLogQuery';
import { CRM_TASKS_FIND_ONE_QUERY_KEY } from './useCrmTasksQuery';

interface FindAllTaskInstancesSearchQuery {
  from_date: string;
  to_date: string;
  facility_id?: string;
  task_type?: CrmTaskType;
  relation_ids?: string;
  relation_type?: CrmRelationType;
}

export interface UpdateTaskInstancePayload {
  id: string;
  scheduled_date: string;
  is_cancelled: boolean;
  cancelled_by_user_id: string;
  is_completed: boolean;
  completed_time: string;
  completed_by_user_id: string;
  length_mins: number | null;
}

type GetTaskCountsSearchQuery = Pick<
  FindAllTaskInstancesSearchQuery,
  'from_date' | 'to_date' | 'facility_id' | 'relation_type'
>;

export const CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY =
  'useCrmTaskInstancesFindAllQuery';
const CRM_TASK_INSTANCES_FIND_ONE_QUERY_KEY = 'useCrmTaskInstancesFindOneQuery';
const CRM_TASK_INSTANCES_COMPLETE_MUTATION_KEY =
  'useCrmTaskInstancesCompleteMutation';
const CRM_TASK_INSTANCES_DELETE_MUTATION_KEY =
  'useCrmTaskInstancesDeleteMutation';
export const CRM_TASK_INSTANCES_COUNT_QUERY_KEY =
  'useCrmTaskInstancesCountQuery';

export const useCrmTaskInstancesQuery = () => {
  const currentUser = useCurrentUser().data!;
  const facilityId = useAtomValue(selectedFacilityIdAtom)!;
  // Invalidate all queries by this prefix for now
  const invalidate = () => {
    queryClient.invalidateQueries([CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY]);
    queryClient.invalidateQueries([CRM_TASKS_FIND_ONE_QUERY_KEY]);
    queryClient.invalidateQueries([CRM_TASK_INSTANCES_COUNT_QUERY_KEY]);
    queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
    queryClient.invalidateQueries([CRM_TASK_INSTANCES_FIND_ONE_QUERY_KEY]);
  };

  return {
    invalidate,
    findOne: (
      id?: string,
      options: UseQueryOptions<CrmTaskInstanceModel> = {}
    ) => {
      return useQuery(
        [CRM_TASK_INSTANCES_FIND_ONE_QUERY_KEY, { id }],
        async () => {
          const response = await fetchCRM.get<CrmTaskInstancePayload | null>(
            `/task-instances/${id}`
          );
          if (response) {
            return new CrmTaskInstanceModel(response);
          }
          return response;
        },
        {
          enabled: !!id && facilityId !== ALL_FACILITIES,
          ...(options as any)
        }
      );
    },
    findAll: (
      searchParams: FindAllTaskInstancesSearchQuery,
      options: UseQueryOptions<CrmTaskInstanceModel[]> = {}
    ) => {
      const searchParamsWithFacilityId: FindAllTaskInstancesSearchQuery = {
        facility_id: facilityId,
        ...searchParams
      };
      return useQuery(
        [CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY, searchParamsWithFacilityId],
        async () => {
          const response = await fetchCRM.get<CrmTaskInstancePayload[]>(
            `/task-instances`,
            {
              searchParams: searchParamsWithFacilityId
            }
          );
          const models = response.map(
            (taskInstance) => new CrmTaskInstanceModel(taskInstance)
          );
          return models;
        },
        {
          enabled:
            facilityId !== ALL_FACILITIES &&
            !!searchParams.from_date &&
            !!searchParams.to_date &&
            (searchParams.relation_type ? !!searchParams.relation_ids : true),
          ...(options as any)
        }
      );
    },

    count: (
      searchParams: Pick<
        FindAllTaskInstancesSearchQuery,
        'from_date' | 'to_date' | 'facility_id' | 'relation_type'
      >,
      options: UseQueryOptions<CrmTaskCountModel> = {}
    ) => {
      const searchParamsWithFacilityId: GetTaskCountsSearchQuery = {
        facility_id: facilityId,
        ...searchParams
      };
      return useQuery<CrmTaskCountModel>(
        [CRM_TASK_INSTANCES_COUNT_QUERY_KEY, searchParamsWithFacilityId],
        async () => {
          const response = await fetchCRM.get<CrmTaskCountPayload>(
            `/task-instances/count`,
            {
              searchParams: searchParamsWithFacilityId
            }
          );
          return new CrmTaskCountModel(response);
        },
        {
          enabled:
            !!searchParamsWithFacilityId.relation_type &&
            facilityId !== ALL_FACILITIES,
          ...(options as any)
        }
      );
    },

    mutations: {
      update: useMutation(
        async (params: Partial<UpdateTaskInstancePayload>) => {
          return await fetchCRM.put(
            `/task-instances/${params.id}`,
            omit(params, 'id')
          );
        },
        {
          onSuccess: invalidate
        }
      ),

      markComplete: useMutation(
        async ({ id, notifyAPFM }: { id: string; notifyAPFM?: boolean }) => {
          return await fetchCRM.put(`/task-instances/${id}`, {
            is_completed: true,
            completed_time: new Date().toISOString(),
            completed_by_user_id: currentUser.id,
            notify_apfm: notifyAPFM
          });
        },
        {
          onSuccess: invalidate,
          mutationKey: [CRM_TASK_INSTANCES_COMPLETE_MUTATION_KEY]
        }
      ),

      markIncomplete: useMutation(
        async (id: string) => {
          return await fetchCRM.put(`/task-instances/${id}`, {
            is_completed: false,
            completed_time: null,
            completed_by_user_id: null
          });
        },
        {
          onSuccess: invalidate
        }
      ),

      delete: useMutation(
        async (id: string) => fetchCRM.delete(`/task-instances/${id}`),
        {
          onSuccess: invalidate,
          mutationKey: [CRM_TASK_INSTANCES_DELETE_MUTATION_KEY]
        }
      ),

      bulkComplete: useMutation(
        async (instanceIds: string[]) =>
          fetchCRM.put(`/task-instances/complete/bulk`, {
            instance_ids: instanceIds.join(',')
          }),
        {
          onSuccess: invalidate
        }
      ),

      bulkDelete: useMutation(
        async (instanceIds: string[]) =>
          fetchCRM.delete(`/task-instances/bulk`, {
            instance_ids: instanceIds.join(',')
          }),
        {
          onSuccess: invalidate
        }
      )
    }
  };
};
