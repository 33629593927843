/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionListEntry } from './PrescriptionListEntry';
import {
    PrescriptionListEntryFromJSON,
    PrescriptionListEntryFromJSONTyped,
    PrescriptionListEntryToJSON,
} from './PrescriptionListEntry';

/**
 * 
 * @export
 * @interface PrescriptionsFindAllResponse
 */
export interface PrescriptionsFindAllResponse {
    /**
     * 
     * @type {number}
     * @memberof PrescriptionsFindAllResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<PrescriptionListEntry>}
     * @memberof PrescriptionsFindAllResponse
     */
    rows?: Array<PrescriptionListEntry>;
}

/**
 * Check if a given object implements the PrescriptionsFindAllResponse interface.
 */
export function instanceOfPrescriptionsFindAllResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrescriptionsFindAllResponseFromJSON(json: any): PrescriptionsFindAllResponse {
    return PrescriptionsFindAllResponseFromJSONTyped(json, false);
}

export function PrescriptionsFindAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionsFindAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'rows': !exists(json, 'rows') ? undefined : ((json['rows'] as Array<any>).map(PrescriptionListEntryFromJSON)),
    };
}

export function PrescriptionsFindAllResponseToJSON(value?: PrescriptionsFindAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'rows': value.rows === undefined ? undefined : ((value.rows as Array<any>).map(PrescriptionListEntryToJSON)),
    };
}

