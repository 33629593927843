/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceContact } from './SourceContact';
import {
    SourceContactFromJSON,
    SourceContactFromJSONTyped,
    SourceContactToJSON,
} from './SourceContact';

/**
 * 
 * @export
 * @interface SourceContactTag
 */
export interface SourceContactTag {
    /**
     * 
     * @type {string}
     * @memberof SourceContactTag
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContactTag
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContactTag
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SourceContactTag
     */
    type: number;
    /**
     * 
     * @type {Array<SourceContact>}
     * @memberof SourceContactTag
     */
    source_contacts?: Array<SourceContact>;
}

/**
 * Check if a given object implements the SourceContactTag interface.
 */
export function instanceOfSourceContactTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function SourceContactTagFromJSON(json: any): SourceContactTag {
    return SourceContactTagFromJSONTyped(json, false);
}

export function SourceContactTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceContactTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'type': json['type'],
        'source_contacts': !exists(json, 'source_contacts') ? undefined : ((json['source_contacts'] as Array<any>).map(SourceContactFromJSON)),
    };
}

export function SourceContactTagToJSON(value?: SourceContactTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'type': value.type,
        'source_contacts': value.source_contacts === undefined ? undefined : ((value.source_contacts as Array<any>).map(SourceContactToJSON)),
    };
}

