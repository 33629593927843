/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillReceiptSettingsIncludeFacilityEntry } from './BillReceiptSettingsIncludeFacilityEntry';
import {
    BillReceiptSettingsIncludeFacilityEntryFromJSON,
    BillReceiptSettingsIncludeFacilityEntryFromJSONTyped,
    BillReceiptSettingsIncludeFacilityEntryToJSON,
} from './BillReceiptSettingsIncludeFacilityEntry';
import type { BillReceiptSettingsIncludeFooterEntry } from './BillReceiptSettingsIncludeFooterEntry';
import {
    BillReceiptSettingsIncludeFooterEntryFromJSON,
    BillReceiptSettingsIncludeFooterEntryFromJSONTyped,
    BillReceiptSettingsIncludeFooterEntryToJSON,
} from './BillReceiptSettingsIncludeFooterEntry';
import type { BillReceiptSettingsIncludePOAEntry } from './BillReceiptSettingsIncludePOAEntry';
import {
    BillReceiptSettingsIncludePOAEntryFromJSON,
    BillReceiptSettingsIncludePOAEntryFromJSONTyped,
    BillReceiptSettingsIncludePOAEntryToJSON,
} from './BillReceiptSettingsIncludePOAEntry';
import type { BillReceiptSettingsIncludeResidentEntry } from './BillReceiptSettingsIncludeResidentEntry';
import {
    BillReceiptSettingsIncludeResidentEntryFromJSON,
    BillReceiptSettingsIncludeResidentEntryFromJSONTyped,
    BillReceiptSettingsIncludeResidentEntryToJSON,
} from './BillReceiptSettingsIncludeResidentEntry';

/**
 * 
 * @export
 * @interface FacilityBillReceiptSettings
 */
export interface FacilityBillReceiptSettings {
    /**
     * 
     * @type {string}
     * @memberof FacilityBillReceiptSettings
     */
    facility_logo_s3_key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacilityBillReceiptSettings
     */
    facility_logo_url?: string;
    /**
     * 
     * @type {Array<BillReceiptSettingsIncludeFacilityEntry>}
     * @memberof FacilityBillReceiptSettings
     */
    include_facility_info?: Array<BillReceiptSettingsIncludeFacilityEntry>;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityBillReceiptSettings
     */
    include_item_gl_code?: boolean;
    /**
     * 
     * @type {Array<BillReceiptSettingsIncludeResidentEntry>}
     * @memberof FacilityBillReceiptSettings
     */
    include_resident_info?: Array<BillReceiptSettingsIncludeResidentEntry>;
    /**
     * 
     * @type {Array<BillReceiptSettingsIncludePOAEntry>}
     * @memberof FacilityBillReceiptSettings
     */
    include_poa_info?: Array<BillReceiptSettingsIncludePOAEntry>;
    /**
     * 
     * @type {Array<BillReceiptSettingsIncludeFooterEntry>}
     * @memberof FacilityBillReceiptSettings
     */
    include_footer_info?: Array<BillReceiptSettingsIncludeFooterEntry>;
}

/**
 * Check if a given object implements the FacilityBillReceiptSettings interface.
 */
export function instanceOfFacilityBillReceiptSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FacilityBillReceiptSettingsFromJSON(json: any): FacilityBillReceiptSettings {
    return FacilityBillReceiptSettingsFromJSONTyped(json, false);
}

export function FacilityBillReceiptSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityBillReceiptSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_logo_s3_key': !exists(json, 'facility_logo_s3_key') ? undefined : json['facility_logo_s3_key'],
        'facility_logo_url': !exists(json, 'facility_logo_url') ? undefined : json['facility_logo_url'],
        'include_facility_info': !exists(json, 'include_facility_info') ? undefined : ((json['include_facility_info'] as Array<any>).map(BillReceiptSettingsIncludeFacilityEntryFromJSON)),
        'include_item_gl_code': !exists(json, 'include_item_gl_code') ? undefined : json['include_item_gl_code'],
        'include_resident_info': !exists(json, 'include_resident_info') ? undefined : ((json['include_resident_info'] as Array<any>).map(BillReceiptSettingsIncludeResidentEntryFromJSON)),
        'include_poa_info': !exists(json, 'include_poa_info') ? undefined : ((json['include_poa_info'] as Array<any>).map(BillReceiptSettingsIncludePOAEntryFromJSON)),
        'include_footer_info': !exists(json, 'include_footer_info') ? undefined : ((json['include_footer_info'] as Array<any>).map(BillReceiptSettingsIncludeFooterEntryFromJSON)),
    };
}

export function FacilityBillReceiptSettingsToJSON(value?: FacilityBillReceiptSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_logo_s3_key': value.facility_logo_s3_key,
        'facility_logo_url': value.facility_logo_url,
        'include_facility_info': value.include_facility_info === undefined ? undefined : ((value.include_facility_info as Array<any>).map(BillReceiptSettingsIncludeFacilityEntryToJSON)),
        'include_item_gl_code': value.include_item_gl_code,
        'include_resident_info': value.include_resident_info === undefined ? undefined : ((value.include_resident_info as Array<any>).map(BillReceiptSettingsIncludeResidentEntryToJSON)),
        'include_poa_info': value.include_poa_info === undefined ? undefined : ((value.include_poa_info as Array<any>).map(BillReceiptSettingsIncludePOAEntryToJSON)),
        'include_footer_info': value.include_footer_info === undefined ? undefined : ((value.include_footer_info as Array<any>).map(BillReceiptSettingsIncludeFooterEntryToJSON)),
    };
}

