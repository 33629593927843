import React, { ReactNode } from 'react';
import { CheckCircleOutline, Close } from '@mui/icons-material';
import {
  Alert,
  AlertColor,
  AlertProps,
  AlertTitle,
  IconButton,
  styled
} from '@mui/material';
import {
  OptionsObject,
  SnackbarKey,
  useSnackbar as useNotiSnackbar
} from 'notistack';

const StyledAlert = styled(Alert)({
  boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
  borderRadius: '12px',
  letterSpacing: '0.15px',
  fontSize: '1rem',
  minWidth: '320px',
  '& .MuiAlert-icon': {
    fontSize: '1.5625rem'
  }
});

interface QueueSnackbarParams {
  message: ReactNode;
  title?: ReactNode;
  severity?: AlertColor;
  AlertProps?: AlertProps;
  NotistackOptions?: OptionsObject;
}

export interface UseSnackbarReturn {
  showSnackbar: ({
    message,
    title,
    severity,
    AlertProps,
    NotistackOptions
  }: QueueSnackbarParams) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey | undefined) => void;
}

export const useSnackbar = (): UseSnackbarReturn => {
  const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar();

  const queueSnackbar = ({
    message,
    title = '',
    severity = 'success',
    AlertProps = {},
    NotistackOptions = {}
  }: QueueSnackbarParams) => {
    if (severity === 'success') {
      AlertProps.icon = <CheckCircleOutline className="MuiAlert-icon" />;
    }

    return enqueueSnackbar(message, {
      content: (key) => (
        <StyledAlert
          severity={severity}
          {...AlertProps}
          action={
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close />
            </IconButton>
          }>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </StyledAlert>
      ),
      ...NotistackOptions
    });
  };

  return {
    showSnackbar: queueSnackbar,
    closeSnackbar
  };
};
