import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ResidentLoader } from '../middleware/ResidentLoader';
import { ProtectedRoute } from '../ProtectedRoute';
import { RoutePath } from '../RoutePath';

const ResidentPage = loadable(() => import("@/pages/ResidentPage")); // prettier-ignore
const ResidentTasksPage = loadable(() => import("@/pages/ResidentPage/ResidentTasksPage")); // prettier-ignore
const ResidentAssessmentsPage = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentsPage/ResidentAssessmentsPage")); // prettier-ignore
const ResidentCarePlanPage = loadable(() => import("@/pages/ResidentPage/ResidentCarePlanPage")); // prettier-ignore
const ResidentFacesheet = loadable(() => import("@/components/ResidentFacesheet")); // prettier-ignore
const ResidentIncidentsV2Page = loadable(() => import("@/pages/ResidentPage/ResidentIncidentsV2Page")); // prettier-ignore
const ResidentMedicalHistory = loadable(() => import("@/pages/ResidentPage/ResidentMedicalHistory")); // prettier-ignore
const ResidentMedicationsList = loadable(() => import("@/pages/ResidentPage/ResidentMedications/MedicationsList")); // prettier-ignore
const ResidentMedicationsMAR = loadable(() => import("@/pages/ResidentPage/ResidentMedications/MedicationsMAR/MedicationsMAR")); // prettier-ignore
const ResidentNotesPage = loadable(() => import("@/pages/ResidentPage/ResidentNotesPage")); // prettier-ignore
const ResidentRecordsPage = loadable(() => import("@/pages/ResidentPage/ResidentRecordsPage")); // prettier-ignore
const ResidentVaccinesPage = loadable(() => import("@/pages/ResidentPage/ResidentVaccinesPage")); // prettier-ignore

export enum ArchivedResidentRoutes {
  ArchivedResident = '/archived-resident/:resident_id',
  ArchivedResidentTasks = '/archived-resident/:resident_id/tasks',
  ArchivedResidentCarePlan = '/archived-resident/:resident_id/care-plan',
  ArchivedResidentMedications = '/archived-resident/:resident_id/medications',
  ArchivedResidentMedicationsMAR = '/archived-resident/:resident_id/medications/MAR',
  ArchivedResidentMedicalHistory = '/archived-resident/:resident_id/vitals',
  ArchivedResidentFacesheet = '/archived-resident/:resident_id/facesheet',
  ArchivedResidentAssessments = '/archived-resident/:resident_id/assessments',
  ArchivedResidentRecords = '/archived-resident/:resident_id/records',
  ArchivedResidentNotes = '/archived-resident/:resident_id/notes',
  ArchivedResidentIncidents = '/archived-resident/:resident_id/incidents',
  ArchivedResidentVaccines = '/archived-resident/:resident_id/vaccines',
  ArchivedResidentBilling = '/archived-resident/:resident_id/billing'
}

export const getEhrArchivedResidentRoutes = (): RouteObject[] => {
  if (!FeatureFlagService.isEnabled(ExacareFeature.ARCHIVED_RESIDENTS)) {
    return [];
  }

  return [
    {
      path: RoutePath.ArchivedResident,
      element: (
        <ResidentLoader>
          <ResidentPage />
        </ResidentLoader>
      ),
      children: [
        {
          index: true,
          path: RoutePath.ArchivedResidentTasks,
          element: <ProtectedRoute role="L4" component={ResidentTasksPage} />
        },
        {
          path: RoutePath.ArchivedResidentCarePlan,
          element: <ResidentCarePlanPage />
        },
        {
          path: RoutePath.ArchivedResidentMedications,
          element: (
            <ProtectedRoute role="L5" component={ResidentMedicationsList} />
          )
        },
        {
          path: RoutePath.ArchivedResidentMedicationsMAR,
          element: (
            <ProtectedRoute role="L3" component={ResidentMedicationsMAR} />
          )
        },
        {
          path: RoutePath.ArchivedResidentMedicalHistory,
          element: (
            <ProtectedRoute role="L5" component={ResidentMedicalHistory} />
          )
        },
        {
          path: RoutePath.ArchivedResidentFacesheet,
          element: <ResidentFacesheet />
        },
        {
          path: RoutePath.ArchivedResidentAssessments,
          element: (
            <ProtectedRoute
              role="L2"
              component={() => <ResidentAssessmentsPage isArchived />}
            />
          )
        },
        {
          path: RoutePath.ArchivedResidentVaccines,
          element: <ProtectedRoute role="L4" component={ResidentVaccinesPage} />
        },
        {
          path: RoutePath.ArchivedResidentRecords,
          element: <ResidentRecordsPage />
        },
        {
          path: RoutePath.ArchivedResidentNotes,
          element: <ResidentNotesPage />
        },
        {
          path: RoutePath.ArchivedResidentIncidents,
          element: (
            <ProtectedRoute role="L4" component={ResidentIncidentsV2Page} />
          )
        }
      ]
    }
  ];
};
