/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreatePccAdvancedPatientDetailsResponse } from './CreatePccAdvancedPatientDetailsResponse';
import {
    CreatePccAdvancedPatientDetailsResponseFromJSON,
    CreatePccAdvancedPatientDetailsResponseFromJSONTyped,
    CreatePccAdvancedPatientDetailsResponseToJSON,
} from './CreatePccAdvancedPatientDetailsResponse';

/**
 * 
 * @export
 * @interface UploadPccAdvancedPatientDetails200Response
 */
export interface UploadPccAdvancedPatientDetails200Response {
    /**
     * 
     * @type {CreatePccAdvancedPatientDetailsResponse}
     * @memberof UploadPccAdvancedPatientDetails200Response
     */
    data?: CreatePccAdvancedPatientDetailsResponse;
}

/**
 * Check if a given object implements the UploadPccAdvancedPatientDetails200Response interface.
 */
export function instanceOfUploadPccAdvancedPatientDetails200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadPccAdvancedPatientDetails200ResponseFromJSON(json: any): UploadPccAdvancedPatientDetails200Response {
    return UploadPccAdvancedPatientDetails200ResponseFromJSONTyped(json, false);
}

export function UploadPccAdvancedPatientDetails200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadPccAdvancedPatientDetails200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CreatePccAdvancedPatientDetailsResponseFromJSON(json['data']),
    };
}

export function UploadPccAdvancedPatientDetails200ResponseToJSON(value?: UploadPccAdvancedPatientDetails200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreatePccAdvancedPatientDetailsResponseToJSON(value.data),
    };
}

