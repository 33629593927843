/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationType } from './MedicationType';
import {
    MedicationTypeFromJSON,
    MedicationTypeFromJSONTyped,
    MedicationTypeToJSON,
} from './MedicationType';

/**
 * 
 * @export
 * @interface CreateMedicationStockSignOffRequest
 */
export interface CreateMedicationStockSignOffRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationStockSignOffRequest
     */
    facility_id: string;
    /**
     * 
     * @type {MedicationType}
     * @memberof CreateMedicationStockSignOffRequest
     */
    type: MedicationType;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationStockSignOffRequest
     */
    signed_at: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMedicationStockSignOffRequest
     */
    pin: string;
}

/**
 * Check if a given object implements the CreateMedicationStockSignOffRequest interface.
 */
export function instanceOfCreateMedicationStockSignOffRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "signed_at" in value;
    isInstance = isInstance && "pin" in value;

    return isInstance;
}

export function CreateMedicationStockSignOffRequestFromJSON(json: any): CreateMedicationStockSignOffRequest {
    return CreateMedicationStockSignOffRequestFromJSONTyped(json, false);
}

export function CreateMedicationStockSignOffRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMedicationStockSignOffRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'type': MedicationTypeFromJSON(json['type']),
        'signed_at': json['signed_at'],
        'pin': json['pin'],
    };
}

export function CreateMedicationStockSignOffRequestToJSON(value?: CreateMedicationStockSignOffRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'type': MedicationTypeToJSON(value.type),
        'signed_at': value.signed_at,
        'pin': value.pin,
    };
}

