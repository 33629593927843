/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CRMStatus = {
    MovedIn: 'MOVED_IN',
    Prospect: 'PROSPECT'
} as const;
export type CRMStatus = typeof CRMStatus[keyof typeof CRMStatus];


export function CRMStatusFromJSON(json: any): CRMStatus {
    return CRMStatusFromJSONTyped(json, false);
}

export function CRMStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRMStatus {
    return json as CRMStatus;
}

export function CRMStatusToJSON(value?: CRMStatus | null): any {
    return value as any;
}

