import {
  CrmProspectListEntry,
  DealStage,
  DealTemperature,
  RelationType
} from '@/types/crm-campaigns';

export const temperatureColors: Partial<Record<DealTemperature, string>> = {
  [DealTemperature.Cold]: '#1DB8F2',
  [DealTemperature.Warm]: '#569E59',
  [DealTemperature.Hot]: '#F38D2F'
};

export interface CrmProspectModelsPage {
  prospects: Array<CrmProspectListEntryModel>;
  total_count: number;
}

export class CrmProspectListEntryModel implements CrmProspectListEntry {
  public readonly id: string;
  public readonly avatar_url: string | null;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly deal_stage: DealStage | null;
  public readonly deal_temperature: DealTemperature | null;
  public readonly relation_type: RelationType;
  public readonly relation_id: string;
  public readonly is_added_to_audience?: boolean | undefined;
  public readonly fullName: string;

  constructor(payload: CrmProspectListEntry) {
    this.id = payload.id;
    this.avatar_url = payload.avatar_url;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.deal_stage = payload.deal_stage;
    this.deal_temperature = payload.deal_temperature;
    this.relation_type = payload.relation_type;
    this.relation_id = payload.relation_id;
    this.is_added_to_audience = payload.is_added_to_audience;
    this.fullName =
      payload.first_name || payload.last_name
        ? `${payload.first_name ?? ''} ${payload.last_name ?? ''}`
        : 'Unknown';
  }

  public getFullName(): string {
    return this.fullName;
  }

  public getInitials = (): string =>
    `${this.first_name?.charAt(0) || ''}${this.last_name?.charAt(0) || ''}`;
}
