import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import { fetchCRMReferrals } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { ReferralSourceType } from '@/models/ReferralModel';

export const USE_CRM_REFERRAL_SOURCE_TYPES_QUERY_KEY = [
  'useCrmReferralSourceTypesQuery'
];
const USE_REFERRAL_SOURCE_TYPES_CREATE_MUTATION_KEY = [
  'useCrmReferralSourceTypesCreateMutation'
];

export function useCrmReferralSourceTypesQuery() {
  const invalidate = () => {
    queryClient.invalidateQueries(USE_CRM_REFERRAL_SOURCE_TYPES_QUERY_KEY);
  };

  return {
    invalidate,
    findAll: (
      facilityId?: string | null,
      options: UseQueryOptions<ReferralSourceType[]> = {}
    ) => {
      return useQuery(
        [...USE_CRM_REFERRAL_SOURCE_TYPES_QUERY_KEY, facilityId],
        () => {
          return fetchCRMReferrals.get<ReferralSourceType[]>(
            '/referral-sources/source-types',
            {
              searchParams: {
                facility_id: facilityId
              }
            }
          );
        },
        {
          enabled: !!facilityId,
          ...(options as any)
        }
      );
    },
    mutations: (options: UseMutationOptions<ReferralSourceType> = {}) => ({
      create: useMutation(
        (payload: Partial<ReferralSourceType>) => {
          return fetchCRMReferrals.post<ReferralSourceType>(
            '/referral-sources/source-types',
            payload
          );
        },
        {
          onSuccess: invalidate,
          mutationKey: USE_REFERRAL_SOURCE_TYPES_CREATE_MUTATION_KEY,
          ...(options as any)
        }
      )
    })
  };
}
