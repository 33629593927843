/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRoomRequest
 */
export interface CreateRoomRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    organization_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomRequest
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomRequest
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomRequest
     */
    room_type_id?: string;
}

/**
 * Check if a given object implements the CreateRoomRequest interface.
 */
export function instanceOfCreateRoomRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization_id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function CreateRoomRequestFromJSON(json: any): CreateRoomRequest {
    return CreateRoomRequestFromJSONTyped(json, false);
}

export function CreateRoomRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoomRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization_id': json['organization_id'],
        'facility_id': json['facility_id'],
        'number': json['number'],
        'price': json['price'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'room_type_id': !exists(json, 'room_type_id') ? undefined : json['room_type_id'],
    };
}

export function CreateRoomRequestToJSON(value?: CreateRoomRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_id': value.organization_id,
        'facility_id': value.facility_id,
        'number': value.number,
        'price': value.price,
        'description': value.description,
        'capacity': value.capacity,
        'gl_code': value.gl_code,
        'room_type_id': value.room_type_id,
    };
}

