/* tslint:disable */
/* eslint-disable */
/**
 * notifications
 * notifications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationChannelUpdateListEntry } from './NotificationChannelUpdateListEntry';
import {
    NotificationChannelUpdateListEntryFromJSON,
    NotificationChannelUpdateListEntryFromJSONTyped,
    NotificationChannelUpdateListEntryToJSON,
} from './NotificationChannelUpdateListEntry';

/**
 * 
 * @export
 * @interface NotificationPreferencesUpdateRequest
 */
export interface NotificationPreferencesUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferencesUpdateRequest
     */
    user_type_id: string;
    /**
     * 
     * @type {Array<NotificationChannelUpdateListEntry>}
     * @memberof NotificationPreferencesUpdateRequest
     */
    preferences?: Array<NotificationChannelUpdateListEntry>;
}

/**
 * Check if a given object implements the NotificationPreferencesUpdateRequest interface.
 */
export function instanceOfNotificationPreferencesUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user_type_id" in value;

    return isInstance;
}

export function NotificationPreferencesUpdateRequestFromJSON(json: any): NotificationPreferencesUpdateRequest {
    return NotificationPreferencesUpdateRequestFromJSONTyped(json, false);
}

export function NotificationPreferencesUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPreferencesUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_type_id': json['user_type_id'],
        'preferences': !exists(json, 'preferences') ? undefined : ((json['preferences'] as Array<any>).map(NotificationChannelUpdateListEntryFromJSON)),
    };
}

export function NotificationPreferencesUpdateRequestToJSON(value?: NotificationPreferencesUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_type_id': value.user_type_id,
        'preferences': value.preferences === undefined ? undefined : ((value.preferences as Array<any>).map(NotificationChannelUpdateListEntryToJSON)),
    };
}

