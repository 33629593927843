/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';
import type { Totals } from './Totals';
import {
    TotalsFromJSON,
    TotalsFromJSONTyped,
    TotalsToJSON,
} from './Totals';

/**
 * 
 * @export
 * @interface FindAllRecurringPurchasesRentResponse
 */
export interface FindAllRecurringPurchasesRentResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    room_product_id: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    room_name: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    room_type: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    resident_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    facility_id: string;
    /**
     * 
     * @type {number}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    market_rate: number;
    /**
     * 
     * @type {Subscription}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    rent: Subscription;
    /**
     * 
     * @type {Totals}
     * @memberof FindAllRecurringPurchasesRentResponse
     */
    totals: Totals;
}

/**
 * Check if a given object implements the FindAllRecurringPurchasesRentResponse interface.
 */
export function instanceOfFindAllRecurringPurchasesRentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "room_product_id" in value;
    isInstance = isInstance && "room_name" in value;
    isInstance = isInstance && "room_type" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "market_rate" in value;
    isInstance = isInstance && "rent" in value;
    isInstance = isInstance && "totals" in value;

    return isInstance;
}

export function FindAllRecurringPurchasesRentResponseFromJSON(json: any): FindAllRecurringPurchasesRentResponse {
    return FindAllRecurringPurchasesRentResponseFromJSONTyped(json, false);
}

export function FindAllRecurringPurchasesRentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllRecurringPurchasesRentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room_product_id': json['room_product_id'],
        'room_name': json['room_name'],
        'room_type': json['room_type'],
        'resident_id': !exists(json, 'resident_id') ? undefined : json['resident_id'],
        'resident_name': json['resident_name'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'facility_id': json['facility_id'],
        'market_rate': json['market_rate'],
        'rent': SubscriptionFromJSON(json['rent']),
        'totals': TotalsFromJSON(json['totals']),
    };
}

export function FindAllRecurringPurchasesRentResponseToJSON(value?: FindAllRecurringPurchasesRentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_product_id': value.room_product_id,
        'room_name': value.room_name,
        'room_type': value.room_type,
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'resident_photo_url': value.resident_photo_url,
        'facility_id': value.facility_id,
        'market_rate': value.market_rate,
        'rent': SubscriptionToJSON(value.rent),
        'totals': TotalsToJSON(value.totals),
    };
}

