/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmProspectsPage } from './CrmProspectsPage';
import {
    CrmProspectsPageFromJSON,
    CrmProspectsPageFromJSONTyped,
    CrmProspectsPageToJSON,
} from './CrmProspectsPage';

/**
 * 
 * @export
 * @interface FindAllCrmContactsProspects200Response
 */
export interface FindAllCrmContactsProspects200Response {
    /**
     * 
     * @type {CrmProspectsPage}
     * @memberof FindAllCrmContactsProspects200Response
     */
    data?: CrmProspectsPage;
}

/**
 * Check if a given object implements the FindAllCrmContactsProspects200Response interface.
 */
export function instanceOfFindAllCrmContactsProspects200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCrmContactsProspects200ResponseFromJSON(json: any): FindAllCrmContactsProspects200Response {
    return FindAllCrmContactsProspects200ResponseFromJSONTyped(json, false);
}

export function FindAllCrmContactsProspects200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCrmContactsProspects200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CrmProspectsPageFromJSON(json['data']),
    };
}

export function FindAllCrmContactsProspects200ResponseToJSON(value?: FindAllCrmContactsProspects200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CrmProspectsPageToJSON(value.data),
    };
}

