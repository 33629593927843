import get from 'lodash/get';

import { DEA_SCHEDULE_CODES } from '@/constants';
import { Doctor } from '@/hooks/useDoctorsQuery';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';

import { MARScheduleModel } from './MARScheduleModel';
import { MARTaskInstanceModel } from './MARTaskInstanceModel';

export class MARMedicationModel {
  as_needed: boolean;
  cancellation_reason: string | null;
  createdAt: string;
  created_by_user_id: string;
  diagnosis: string;
  doctor: Doctor | null;
  doctor_id: string | null;
  end_date: string | null;
  equivalent_to: string | null;
  fdb_dispensable_drug: MedicationsPayload;
  fdb_dispensable_drug_id: string;
  id: string;
  instructions: string | null;
  is_deleted: boolean;
  is_discontinued: boolean;
  is_informational: boolean;
  is_new: boolean;
  is_prn: boolean;
  length_mins: number;
  number_of_assistants: number;
  prescription_types: string;
  resident_id: string;
  responsible_party: string | null;
  s3_prescription_key: string | null;
  schedules: MARScheduleModel[];
  start_date: string;
  updatedAt: string | null;
  non_community_dose?: number | null;
  responsible_party_instance?: ResponsibleParty;

  exceptions: Array<MARTaskInstanceModel>;
  has_exception = false;
  searchableTerms: string;

  constructor(payload: MARMedicationModel) {
    Object.assign(this, payload);
    this.schedules =
      payload.schedules?.map((schedule) => new MARScheduleModel(schedule)) ??
      [];
    this.exceptions = this.gatherExceptions();
    this.searchableTerms = this.constructSearchableTerms();
  }

  private gatherExceptions = () => {
    const exceptions: MARTaskInstanceModel[] = [];

    if (!this.schedules?.length) {
      return exceptions;
    }

    this.schedules.forEach((schedule) => {
      schedule.instances.forEach((instance) => {
        if (
          instance.status === 'overdue' ||
          instance.status === 'cancelled' ||
          instance.is_modified
        ) {
          this.has_exception = true;
          exceptions.push(new MARTaskInstanceModel(instance));
        }
      });
    });
    return exceptions.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };

  public isControlledSubstance = () =>
    this.fdb_dispensable_drug.FederalDEAClassCode &&
    DEA_SCHEDULE_CODES.includes(this.fdb_dispensable_drug.FederalDEAClassCode);

  private constructSearchableTerms(): string {
    return [
      'fdb_dispensable_drug.DrugNameDesc',
      'fdb_dispensable_drug.GenericDispensableDrugDesc',
      'instructions',
      'diagnosis',
      'equivalent_to',
      'doctor.name',
      'fdb_dispensable_drug.MedStrength',
      'fdb_dispensable_drug.MedStrengthUnit',
      'fdb_dispensable_drug.RouteDesc'
    ].reduce((acc, key) => {
      const value = get(this, key);
      if (value) {
        acc += `${value.toLowerCase()} `;
      }
      return acc;
    }, '');
  }
}
