/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Assessment } from './Assessment';
import {
    AssessmentFromJSON,
    AssessmentFromJSONTyped,
    AssessmentToJSON,
} from './Assessment';

/**
 * 
 * @export
 * @interface CreateAssessment201Response
 */
export interface CreateAssessment201Response {
    /**
     * 
     * @type {Assessment}
     * @memberof CreateAssessment201Response
     */
    data?: Assessment;
}

/**
 * Check if a given object implements the CreateAssessment201Response interface.
 */
export function instanceOfCreateAssessment201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAssessment201ResponseFromJSON(json: any): CreateAssessment201Response {
    return CreateAssessment201ResponseFromJSONTyped(json, false);
}

export function CreateAssessment201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssessment201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : AssessmentFromJSON(json['data']),
    };
}

export function CreateAssessment201ResponseToJSON(value?: CreateAssessment201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AssessmentToJSON(value.data),
    };
}

