import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { RegularBreakpoints, Unstable_Grid2 as Grid } from '@mui/material';
import dayjs from 'dayjs';

import { createDateFromTimeString } from '@/utils/date';

import { ControlledTimePicker } from '../ControlledTimePicker';

import { ExactTimeOption } from './options';

interface PeriodTimeInputProps {
  formMethods: UseFormReturn<any>;
  gridBreakpoints?: {
    from: RegularBreakpoints['md'];
    to: RegularBreakpoints['md'];
  };
  fromFieldDisabled?: boolean;
  toFieldDisabled?: boolean;
  toFieldName: string;
  fromFieldName: string;
  handleOnChange?: VoidFunction;
  fromFieldLabel: string;
  toFieldLabel: string;
}

export default function PeriodTimeInput(props: PeriodTimeInputProps) {
  const {
    formMethods,
    gridBreakpoints = {
      from: true,
      to: true
    },
    toFieldName,
    fromFieldName,
    handleOnChange = () => {},
    fromFieldLabel,
    fromFieldDisabled,
    toFieldLabel,
    toFieldDisabled
  } = props;

  const startTime = useWatch({
    control: formMethods.control,
    name: fromFieldName
  }) as ExactTimeOption['value'];

  const [isEndTimeBeforeStartTime, setIsEndTimeBeforeStartTime] =
    useState(false);

  const handleSelectStartTime = () => {
    // Selecting a start time should wipe the end time just in case they
    // pick the same time. Or, if they select an exact time it wipes end time
    // as well
    formMethods.setValue(toFieldName, null);
  };

  const handleNewStartTimePeriodValue = (newValue: Date | null) => {
    if (dayjs(newValue).isValid()) {
      formMethods.setValue(fromFieldName, dayjs(newValue).format('h:mm A'), {
        shouldDirty: true
      });
      handleSelectStartTime();
      handleOnChange();
    } else {
      formMethods.setValue(fromFieldName, null);
    }
  };

  const handleNewEndTimePeriodValue = (newValue: Date | null) => {
    if (dayjs(newValue).isValid()) {
      if (
        startTime &&
        dayjs(newValue).isBefore(createDateFromTimeString(startTime))
      ) {
        setIsEndTimeBeforeStartTime(true);
      } else {
        formMethods.setValue(toFieldName, dayjs(newValue).format('h:mm A'), {
          shouldDirty: true
        });
        setIsEndTimeBeforeStartTime(false);
        handleOnChange();
      }
    } else {
      formMethods.setValue(toFieldName, null);
    }
  };

  return (
    <>
      <Grid xs={6} md={gridBreakpoints.from}>
        <ControlledTimePicker
          control={formMethods.control}
          label={fromFieldLabel}
          name={fromFieldName}
          disabled={fromFieldDisabled}
          onChange={(newValue: Date | null) => {
            handleNewStartTimePeriodValue(newValue);
          }}
          rules={{ required: true }}
        />
      </Grid>
      <Grid xs={6} md={gridBreakpoints.to}>
        <ControlledTimePicker
          error={isEndTimeBeforeStartTime}
          helperText={
            isEndTimeBeforeStartTime
              ? 'End time cannot be earlier than the start time'
              : undefined
          }
          minTime={createDateFromTimeString(startTime)}
          control={formMethods.control}
          label={toFieldLabel}
          name={toFieldName}
          disabled={toFieldDisabled}
          onChange={(newValue: Date | null) => {
            handleNewEndTimePeriodValue(newValue);
          }}
          rules={{
            required: true,
            validate: () => {
              return !isEndTimeBeforeStartTime;
            }
          }}
        />
      </Grid>
    </>
  );
}
