/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentPreArrivalListEntry
 */
export interface ResidentPreArrivalListEntry {
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    preferred_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    target_move_in_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    integration?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    profile_picture?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentPreArrivalListEntry
     */
    dob?: string;
}

/**
 * Check if a given object implements the ResidentPreArrivalListEntry interface.
 */
export function instanceOfResidentPreArrivalListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;

    return isInstance;
}

export function ResidentPreArrivalListEntryFromJSON(json: any): ResidentPreArrivalListEntry {
    return ResidentPreArrivalListEntryFromJSONTyped(json, false);
}

export function ResidentPreArrivalListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentPreArrivalListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'preferred_name': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'target_move_in_date': !exists(json, 'target_move_in_date') ? undefined : json['target_move_in_date'],
        'integration': !exists(json, 'integration') ? undefined : json['integration'],
        'profile_picture': !exists(json, 'profile_picture') ? undefined : json['profile_picture'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
    };
}

export function ResidentPreArrivalListEntryToJSON(value?: ResidentPreArrivalListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'preferred_name': value.preferred_name,
        'target_move_in_date': value.target_move_in_date,
        'integration': value.integration,
        'profile_picture': value.profile_picture,
        'dob': value.dob,
    };
}

