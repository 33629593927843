import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { medicationsClient } from '@/adapters/schemaClients';
import {
  MedicationInventorySignOffModel,
  MedicationStockSignOffModelsPage
} from '@/pages/CRM/models/MedicationInventorySignOffModel';
import {
  CreateMedicationStockSignOffOperationRequest,
  FindAllMedicationStockSignOffsRequest
} from '@/types/medications';

export interface MedicationSignOffDeletePayload {
  id: string;
}

export const FIND_ALL_MEDICATION_SIGN_OFF_DEFAULT_LIMIT = 200;
const USE_MEDICATION_SIGN_OFF_QUERY_KEY = 'useMedicationSignOffQueryKey';

export const useMedicationSignOffQuery = () => {
  const invalidate = () => {
    queryClient.invalidateQueries([USE_MEDICATION_SIGN_OFF_QUERY_KEY]);
  };
  return {
    invalidate,
    findAll: (
      params: FindAllMedicationStockSignOffsRequest,
      options: UseQueryOptions<MedicationStockSignOffModelsPage, HTTPError> = {}
    ) => {
      return useQuery<MedicationStockSignOffModelsPage, HTTPError>(
        [USE_MEDICATION_SIGN_OFF_QUERY_KEY, params],
        async () => {
          const response =
            await medicationsClient.findAllMedicationStockSignOffs(params);
          const medicationStockSignOffs =
            response.data?.rows?.map(
              (medicationSignOff) =>
                new MedicationInventorySignOffModel(medicationSignOff)
            ) || [];
          const total_count = response.data?.count || 0;

          return { medicationStockSignOffs, total_count };
        },
        {
          enabled: !!params.facilityId,
          ...options
        }
      );
    },
    mutations: () => ({
      createMutation: useMutation(
        async (payload: CreateMedicationStockSignOffOperationRequest) => {
          const response = await medicationsClient.createMedicationStockSignOff(
            payload
          );
          return response.data;
        },
        {
          onSuccess: invalidate
        }
      ),
      deleteMutation: useMutation<void, HTTPError, string>(
        async (id: string) => {
          await medicationsClient.deleteMedicationStockSignOff({ id });
        },
        {
          onSuccess: invalidate
        }
      )
    })
  };
};
