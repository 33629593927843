/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceContact } from './SourceContact';
import {
    SourceContactFromJSON,
    SourceContactFromJSONTyped,
    SourceContactToJSON,
} from './SourceContact';

/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    location?: string | null;
    /**
     * 
     * @type {Array<SourceContact>}
     * @memberof Source
     */
    contacts?: Array<SourceContact>;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    npi_number?: string | null;
}

/**
 * Check if a given object implements the Source interface.
 */
export function instanceOfSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SourceFromJSON(json: any): Source {
    return SourceFromJSONTyped(json, false);
}

export function SourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Source {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(SourceContactFromJSON)),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'npi_number': !exists(json, 'npi_number') ? undefined : json['npi_number'],
    };
}

export function SourceToJSON(value?: Source | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'location': value.location,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(SourceContactToJSON)),
        'phone': value.phone,
        'npi_number': value.npi_number,
    };
}

