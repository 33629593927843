/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefundPaymentRecordRequest
 */
export interface RefundPaymentRecordRequest {
    /**
     * 
     * @type {number}
     * @memberof RefundPaymentRecordRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof RefundPaymentRecordRequest
     */
    reason?: string;
}

/**
 * Check if a given object implements the RefundPaymentRecordRequest interface.
 */
export function instanceOfRefundPaymentRecordRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function RefundPaymentRecordRequestFromJSON(json: any): RefundPaymentRecordRequest {
    return RefundPaymentRecordRequestFromJSONTyped(json, false);
}

export function RefundPaymentRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundPaymentRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function RefundPaymentRecordRequestToJSON(value?: RefundPaymentRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'reason': value.reason,
    };
}

