/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentSettingCreateResponse } from './IncidentSettingCreateResponse';
import {
    IncidentSettingCreateResponseFromJSON,
    IncidentSettingCreateResponseFromJSONTyped,
    IncidentSettingCreateResponseToJSON,
} from './IncidentSettingCreateResponse';

/**
 * 
 * @export
 * @interface CreateLocations200Response
 */
export interface CreateLocations200Response {
    /**
     * 
     * @type {IncidentSettingCreateResponse}
     * @memberof CreateLocations200Response
     */
    data?: IncidentSettingCreateResponse;
}

/**
 * Check if a given object implements the CreateLocations200Response interface.
 */
export function instanceOfCreateLocations200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateLocations200ResponseFromJSON(json: any): CreateLocations200Response {
    return CreateLocations200ResponseFromJSONTyped(json, false);
}

export function CreateLocations200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLocations200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentSettingCreateResponseFromJSON(json['data']),
    };
}

export function CreateLocations200ResponseToJSON(value?: CreateLocations200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentSettingCreateResponseToJSON(value.data),
    };
}

