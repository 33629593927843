/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UploadPccPatientDetailsResponse } from './UploadPccPatientDetailsResponse';
import {
    UploadPccPatientDetailsResponseFromJSON,
    UploadPccPatientDetailsResponseFromJSONTyped,
    UploadPccPatientDetailsResponseToJSON,
} from './UploadPccPatientDetailsResponse';

/**
 * 
 * @export
 * @interface UploadPccPatientDetails200Response
 */
export interface UploadPccPatientDetails200Response {
    /**
     * 
     * @type {UploadPccPatientDetailsResponse}
     * @memberof UploadPccPatientDetails200Response
     */
    data?: UploadPccPatientDetailsResponse;
}

/**
 * Check if a given object implements the UploadPccPatientDetails200Response interface.
 */
export function instanceOfUploadPccPatientDetails200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadPccPatientDetails200ResponseFromJSON(json: any): UploadPccPatientDetails200Response {
    return UploadPccPatientDetails200ResponseFromJSONTyped(json, false);
}

export function UploadPccPatientDetails200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadPccPatientDetails200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : UploadPccPatientDetailsResponseFromJSON(json['data']),
    };
}

export function UploadPccPatientDetails200ResponseToJSON(value?: UploadPccPatientDetails200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UploadPccPatientDetailsResponseToJSON(value.data),
    };
}

