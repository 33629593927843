import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import { Box, CircularProgress, Stack } from '@mui/material';
import { debounce } from 'lodash';

import useResidentsQueryV2, {
  FIND_ALL_RESIDENTS_DEFAULT_LIMIT
} from '@/hooks/residentsv2/useResidentsQuery';
import {
  ALL_FACILITIES,
  useSelectedFacilityId
} from '@/hooks/useFacilitiesQuery';
import {
  PROSPECTS_PAGE_SIZE,
  useProspectResidents
} from '@/hooks/useProspectResidents';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ResidentModel } from '@/models/ResidentModel';
import { Placeholder } from '@/pages/CRM/components/Placeholder';
import { RoutePath } from '@/routes/RoutePath';

import { ResidentTabsOptions } from './ResidentsTabs';

interface ResidentsPanelProps {
  value: number;
  index: number;
  tabId: ResidentTabsOptions;
  searchQuery: string;
  onResolve: VoidFunction;
  isCRM: boolean;
}

export default function ResidentsPanel(props: ResidentsPanelProps) {
  const { value, index, tabId, searchQuery, onResolve, isCRM } = props;
  const selectedFacilityId = useSelectedFacilityId();
  const { showSnackbar } = useSnackbar();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [searchParam, setSearchParam] = useState<string>('');
  const navigate = useNavigate();

  const {
    data: residents,
    isFetchingNextPage: isFetchingNextResidentsPage,
    hasNextPage: hasNextResidentsPage,
    isLoading: isLoadingResidents,
    isFetching: isFetchingResidents,
    fetchNextPage: fetchNextResidentsPage
  } = useResidentsQueryV2().findAllInfinite(
    {
      limit: FIND_ALL_RESIDENTS_DEFAULT_LIMIT,
      search: searchParam,
      ...(tabId === ResidentTabsOptions.ALL_RESIDENTS && {
        facilityId:
          selectedFacilityId === ALL_FACILITIES
            ? undefined
            : selectedFacilityId!
      }),
      ...(tabId === ResidentTabsOptions.RECENTLY_VIEWED && {
        recentlyViewed: true
      })
    },
    {
      ...(tabId === ResidentTabsOptions.RECENTLY_VIEWED && {
        refetchOnMount: 'always'
      }),
      enabled:
        !isCRM &&
        value === index &&
        (tabId === ResidentTabsOptions.ALL_RESIDENTS ||
          tabId === ResidentTabsOptions.RECENTLY_VIEWED),
      onError: () => {
        showSnackbar({
          message: 'Error loading residents',
          severity: 'error'
        });
      }
    }
  );

  const {
    data: prospects,
    isFetching: isFetchingProspects,
    isFetchingNextPage: isFetchingNextProspectsPage,
    hasNextPage: hasNextProspectsPage,
    isLoading: isLoadingProspects,
    fetchNextPage: fetchNextProspectsPage
  } = useProspectResidents().findAllInfinite(
    {
      ...(tabId === ResidentTabsOptions.PROSPECTIVE_RESIDENTS &&
        !isCRM && {
          facilityId:
            selectedFacilityId === ALL_FACILITIES
              ? undefined
              : selectedFacilityId!
        }),
      recentlyViewed: tabId === ResidentTabsOptions.RECENTLY_VIEWED,
      limit: PROSPECTS_PAGE_SIZE,
      search: searchParam
    },
    {
      ...(tabId === ResidentTabsOptions.RECENTLY_VIEWED && {
        refetchOnMount: 'always'
      }),
      enabled:
        value === index &&
        (tabId === ResidentTabsOptions.PROSPECTIVE_RESIDENTS ||
          (tabId === ResidentTabsOptions.RECENTLY_VIEWED && isCRM)),
      onError: () => {
        showSnackbar({
          message: 'Error loading prospects',
          severity: 'error'
        });
      }
    }
  );

  useEffect(() => {
    onFilterChange(searchQuery);
  }, [searchQuery]);

  const onFilterChange = useCallback((searchValue: string) => {
    debouncedSetFilterOptions(searchValue);
  }, []);

  const debouncedSetFilterOptions = useCallback(
    debounce((searchValue) => {
      setSearchParam(searchValue);
    }, 1000),
    []
  );

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    if (
      ((isFetchingNextResidentsPage || !hasNextResidentsPage) &&
        (tabId === ResidentTabsOptions.RECENTLY_VIEWED ||
          tabId === ResidentTabsOptions.ALL_RESIDENTS)) ||
      ((isFetchingNextProspectsPage || !hasNextProspectsPage) &&
        (tabId === ResidentTabsOptions.PROSPECTIVE_RESIDENTS ||
          (tabId === ResidentTabsOptions.RECENTLY_VIEWED && isCRM)))
    ) {
      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 25) {
      tabId === ResidentTabsOptions.ALL_RESIDENTS
        ? fetchNextResidentsPage()
        : fetchNextProspectsPage();
    }
  };

  useEffect(() => {
    if (isFetchingNextResidentsPage || isFetchingNextProspectsPage) {
      containerRef.current?.scrollTo(0, containerRef.current.scrollHeight);
    }
  }, [isFetchingNextResidentsPage, isFetchingNextProspectsPage]);

  return (
    <Box
      ref={containerRef}
      onScroll={handleScroll}
      sx={{
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'auto'
      }}
      role="tabpanel"
      hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {(isLoadingResidents &&
            !isCRM &&
            (tabId === ResidentTabsOptions.ALL_RESIDENTS ||
              tabId === ResidentTabsOptions.RECENTLY_VIEWED)) ||
          (isLoadingProspects &&
            (tabId === ResidentTabsOptions.PROSPECTIVE_RESIDENTS ||
              (tabId === ResidentTabsOptions.RECENTLY_VIEWED && isCRM))) ||
          ((isFetchingProspects || isFetchingResidents) &&
            tabId === ResidentTabsOptions.RECENTLY_VIEWED) ? (
            <Box justifyContent="center" display="flex">
              <CircularProgress />
            </Box>
          ) : (
            <Stack gap={2} maxHeight={260}>
              {((tabId === ResidentTabsOptions.ALL_RESIDENTS ||
                tabId === ResidentTabsOptions.RECENTLY_VIEWED) &&
              !isCRM
                ? residents
                : tabId === ResidentTabsOptions.PROSPECTIVE_RESIDENTS ||
                  (tabId === ResidentTabsOptions.RECENTLY_VIEWED && isCRM)
                ? prospects
                : undefined
              )?.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.length === 0 && i === 0 ? (
                    searchParam.trim().length !== 0 ? (
                      <Placeholder
                        sx={{ py: '32px' }}
                        header={`No residents found for "${searchParam}"`}
                        Icon={Person}
                      />
                    ) : (
                      <Placeholder
                        sx={{ py: '32px' }}
                        header="Search for residents"
                        Icon={Person}
                      />
                    )
                  ) : (
                    group?.data.map((model, index) => {
                      let facesheetUrl: string;
                      if (model instanceof ResidentModel) {
                        facesheetUrl = generatePath(
                          RoutePath.ResidentFacesheet,
                          {
                            resident_id: model.id
                          }
                        );
                      } else {
                        facesheetUrl = generatePath(
                          RoutePath.CRMResidentFacesheet,
                          {
                            resident_id: model.resident.id
                          }
                        );
                      }
                      return (
                        <Box
                          className="cursor-pointer"
                          key={index}
                          onClick={() => {
                            navigate(facesheetUrl);
                            onResolve();
                          }}>
                          {model.renderDetailsCell()}
                        </Box>
                      );
                    })
                  )}
                </React.Fragment>
              ))}
              {(isFetchingNextResidentsPage || isFetchingNextProspectsPage) && (
                <Stack justifyContent="center" direction="row">
                  <CircularProgress disableShrink />
                </Stack>
              )}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}
