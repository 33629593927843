import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';

import { createDateFromTimeString, isValidDate } from '@/utils/date';

type ControlledTimePickerProps<TFields extends FieldValues> =
  React.PropsWithChildren<
    UseControllerProps<TFields> &
      TimePickerProps<any> & {
        label: string;
        disabled?: boolean;
        onChange: (newValue: Date | null) => void;
        minTime?: Date;
        error?: boolean;
        helperText?: string;
      }
  >;

const calculateValue = (value: string | Date | unknown): Date | null => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    return createDateFromTimeString(value) || null;
  }

  if (value instanceof Date && isValidDate(value)) {
    return value;
  }

  return null;
};

export function ControlledTimePicker<TFields extends FieldValues>({
  label,
  name,
  control,
  rules = {},
  disabled = false,
  onChange,
  minTime,
  error,
  helperText,
  disableFuture
}: ControlledTimePickerProps<TFields>) {
  const {
    field: { ref, value, onBlur },
    fieldState: { error: fieldError }
  } = useController({
    name,
    control,
    rules: {
      ...rules
    }
  });

  const renderLabel = () => {
    if (rules?.required && label) {
      return (
        <span>
          {label}
          <span style={{ color: '#DD3730' }}>*</span>
        </span>
      );
    } else if (label) {
      return label;
    } else {
      return '';
    }
  };

  return (
    <TimePicker
      minTime={minTime}
      disableFuture={disableFuture}
      disabled={disabled}
      label={renderLabel()}
      onChange={(newValue) => onChange(newValue)}
      value={calculateValue(value)}
      inputRef={ref}
      slotProps={{
        textField: {
          fullWidth: true,
          error: !!fieldError || error,
          helperText: fieldError?.message || helperText,
          onBlur
        }
      }}
    />
  );
}
