import React from 'react';
import MedicationIcon from '@mui/icons-material/Medication';
import {
  Avatar,
  createFilterOptions,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import { ResidentPayload } from '@/hooks/useResidentFacesheetsQuery';

export class CustomMedicationPayload {
  constructor(payload: CustomMedicationPayload) {
    Object.assign(this, payload);
  }

  id: number;
  facility_id: string;
  organization_id: number;
  name: string;
  brand: string;
  route: string;
  form: string;
  dosage: number | null;
  dosage_unit: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  active_for?: Partial<ResidentPayload>[];
  inactive_for?: Partial<ResidentPayload>[];
}
export const customMedicationsAutocompleteFilterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: CustomMedicationModel) =>
    `${option.name}${option.brand}${option.route}${option.form}`
});
export class CustomMedicationModel extends CustomMedicationPayload {
  constructor(payload: CustomMedicationPayload) {
    super(payload);
  }

  public renderListItem = (key?: number | string): JSX.Element => {
    return (
      <ListItem key={key} disableGutters disablePadding dense>
        <ListItemAvatar>
          <Avatar sx={{ width: '32px', height: '32px' }}>
            <MedicationIcon htmlColor="#FFFFFF" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={this.name}
          secondary={`${this.brand} ${this.dosage || ''} ${
            this.dosage_unit || ''
          } ${this.form} ${this.route}`}
          primaryTypographyProps={{ fontWeight: 500 }}
          secondaryTypographyProps={{ color: '#009BD0!important' }}
        />
      </ListItem>
    );
  };
}
