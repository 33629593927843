import dayjs from 'dayjs';

export function isValidDate(date?: Date | null) {
  return date instanceof Date && date.getTime() == date.getTime();
}

/**
 *
 * @param timeString string time following the format 'h:mm A'
 * @returns valid Date object with the time set to the input timeString
 */
export function createDateFromTimeString(timeString: string) {
  if (!timeString) return;
  const currentDate = dayjs();
  const [time, ampm] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  const timeDetails = {
    hour: (hours % 12) + (ampm.toUpperCase() === 'PM' ? 12 : 0),
    minute: minutes
  };
  const newDate = currentDate
    .set('hour', timeDetails.hour)
    .set('minute', timeDetails.minute);
  return newDate.toDate();
}
