import React from 'react';
import { useRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import SecretFeatureFlagPage from '@/pages/SecretFeatureFlagPage';

import { getEhrRoutes } from './getEhrRoutes/getEhrRoutes';
import { getSharedRoutes } from './getSharedRoutes/getSharedRoutes';
import { getCrmRoutes } from './getCrmRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { RoutePath } from './RoutePath';

const RouteConfig = () => {
  const { data: currentUser, isLoading } = useCurrentUser();

  if (isLoading || !currentUser) {
    return null;
  }

  const routes = [
    ...getCrmRoutes(currentUser),
    ...getEhrRoutes(),
    ...getSharedRoutes(currentUser),
    ...(currentUser.email.endsWith('@exacare.com')
      ? [
          {
            path: RoutePath.SecretFeatureFlagService,
            element: (
              <ProtectedRoute role="L0" component={SecretFeatureFlagPage} />
            )
          }
        ]
      : [])
  ];

  return useRoutes(routes);
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(RouteConfig);
export { SentryRoutes as RouteConfig };
