import { AudienceMemberListEntry, RelationType } from '@/types/crm-campaigns';

export const relationTypeDisplay: Record<RelationType, string> = {
  USER: 'User',
  PROSPECT_RESIDENT: 'Prospects',
  REFERRAL: 'Referral Sources',
  RESIDENT: 'Residents',
  RESIDENT_CLOSE_CONNECTION: 'Family & Friends'
};

export interface AudienceMembersModelsPage {
  members: Array<AudienceMemberListEntryModel>;
  total_count: number;
}

export class AudienceMemberListEntryModel implements AudienceMemberListEntry {
  public readonly id: string;
  public readonly relation_type: RelationType;
  public readonly relation_id: string;
  public readonly avatar_url: string | null;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly date_added: string;
  public readonly fullName: string;

  constructor(payload: AudienceMemberListEntry) {
    this.id = payload.id;
    this.relation_type = payload.relation_type;
    this.relation_id = payload.relation_id;
    this.avatar_url = payload.avatar_url;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.date_added = payload.date_added;
    this.fullName = `${payload.first_name} ${payload.last_name}`;
  }

  public getRelationTypeToDisplay(): string {
    return relationTypeDisplay[this.relation_type];
  }

  public getFullName(): string {
    return this.fullName;
  }
}
