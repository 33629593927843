/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillItem } from './BillItem';
import {
    BillItemFromJSON,
    BillItemFromJSONTyped,
    BillItemToJSON,
} from './BillItem';

/**
 * 
 * @export
 * @interface GroupedBillItems
 */
export interface GroupedBillItems {
    /**
     * 
     * @type {Array<BillItem>}
     * @memberof GroupedBillItems
     */
    rent: Array<BillItem>;
    /**
     * 
     * @type {any}
     * @memberof GroupedBillItems
     */
    base_care: any | null;
    /**
     * 
     * @type {any}
     * @memberof GroupedBillItems
     */
    care_packages: any | null;
    /**
     * 
     * @type {any}
     * @memberof GroupedBillItems
     */
    other_recurring: any | null;
    /**
     * 
     * @type {any}
     * @memberof GroupedBillItems
     */
    one_time: any | null;
}

/**
 * Check if a given object implements the GroupedBillItems interface.
 */
export function instanceOfGroupedBillItems(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rent" in value;
    isInstance = isInstance && "base_care" in value;
    isInstance = isInstance && "care_packages" in value;
    isInstance = isInstance && "other_recurring" in value;
    isInstance = isInstance && "one_time" in value;

    return isInstance;
}

export function GroupedBillItemsFromJSON(json: any): GroupedBillItems {
    return GroupedBillItemsFromJSONTyped(json, false);
}

export function GroupedBillItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedBillItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rent': ((json['rent'] as Array<any>).map(BillItemFromJSON)),
        'base_care': json['base_care'],
        'care_packages': json['care_packages'],
        'other_recurring': json['other_recurring'],
        'one_time': json['one_time'],
    };
}

export function GroupedBillItemsToJSON(value?: GroupedBillItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rent': ((value.rent as Array<any>).map(BillItemToJSON)),
        'base_care': value.base_care,
        'care_packages': value.care_packages,
        'other_recurring': value.other_recurring,
        'one_time': value.one_time,
    };
}

