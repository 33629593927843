import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface DuplicateMedicationAlertProps {
  drugName: string;
  residentName?: string;
}

export const DuplicateMedicationAlert: React.FC<
  DuplicateMedicationAlertProps
> = ({ drugName, residentName }) => {
  const name = residentName ?? 'resident';

  return (
    <Alert severity="warning">
      <AlertTitle>Potential Duplicate</AlertTitle>
      Looks like {drugName} is already on {name}&#39;s medication list. Adding
      this might create a duplicate.
    </Alert>
  );
};
