/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentProduct
 */
export interface ResidentProduct {
    /**
     * 
     * @type {string}
     * @memberof ResidentProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentProduct
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentProduct
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentProduct
     */
    service_start_date?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidentProduct
     */
    service_end_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResidentProduct
     */
    single_service_date?: Date;
}

/**
 * Check if a given object implements the ResidentProduct interface.
 */
export function instanceOfResidentProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "resident_id" in value;

    return isInstance;
}

export function ResidentProductFromJSON(json: any): ResidentProduct {
    return ResidentProductFromJSONTyped(json, false);
}

export function ResidentProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product_id': json['product_id'],
        'resident_id': json['resident_id'],
        'service_start_date': !exists(json, 'service_start_date') ? undefined : json['service_start_date'],
        'service_end_date': !exists(json, 'service_end_date') ? undefined : (new Date(json['service_end_date'])),
        'single_service_date': !exists(json, 'single_service_date') ? undefined : (new Date(json['single_service_date'])),
    };
}

export function ResidentProductToJSON(value?: ResidentProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_id': value.product_id,
        'resident_id': value.resident_id,
        'service_start_date': value.service_start_date,
        'service_end_date': value.service_end_date === undefined ? undefined : (value.service_end_date.toISOString().substring(0,10)),
        'single_service_date': value.single_service_date === undefined ? undefined : (value.single_service_date.toISOString()),
    };
}

