/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskTemplateListEntry } from './TaskTemplateListEntry';
import {
    TaskTemplateListEntryFromJSON,
    TaskTemplateListEntryFromJSONTyped,
    TaskTemplateListEntryToJSON,
} from './TaskTemplateListEntry';

/**
 * 
 * @export
 * @interface FindAllTaskTemplates200Response
 */
export interface FindAllTaskTemplates200Response {
    /**
     * 
     * @type {Array<TaskTemplateListEntry>}
     * @memberof FindAllTaskTemplates200Response
     */
    data?: Array<TaskTemplateListEntry>;
}

/**
 * Check if a given object implements the FindAllTaskTemplates200Response interface.
 */
export function instanceOfFindAllTaskTemplates200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllTaskTemplates200ResponseFromJSON(json: any): FindAllTaskTemplates200Response {
    return FindAllTaskTemplates200ResponseFromJSONTyped(json, false);
}

export function FindAllTaskTemplates200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllTaskTemplates200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(TaskTemplateListEntryFromJSON)),
    };
}

export function FindAllTaskTemplates200ResponseToJSON(value?: FindAllTaskTemplates200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(TaskTemplateListEntryToJSON)),
    };
}

