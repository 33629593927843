/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloseConnection } from './CloseConnection';
import {
    CloseConnectionFromJSON,
    CloseConnectionFromJSONTyped,
    CloseConnectionToJSON,
} from './CloseConnection';

/**
 * 
 * @export
 * @interface CarePlanEsignRecipient
 */
export interface CarePlanEsignRecipient {
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsignRecipient
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsignRecipient
     */
    close_connection_id: string;
    /**
     * 
     * @type {CloseConnection}
     * @memberof CarePlanEsignRecipient
     */
    close_connection?: CloseConnection;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsignRecipient
     */
    type: CarePlanEsignRecipientTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CarePlanEsignRecipient
     */
    signed?: boolean;
}


/**
 * @export
 */
export const CarePlanEsignRecipientTypeEnum = {
    Signer: 'signer',
    Cc: 'cc'
} as const;
export type CarePlanEsignRecipientTypeEnum = typeof CarePlanEsignRecipientTypeEnum[keyof typeof CarePlanEsignRecipientTypeEnum];


/**
 * Check if a given object implements the CarePlanEsignRecipient interface.
 */
export function instanceOfCarePlanEsignRecipient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "close_connection_id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CarePlanEsignRecipientFromJSON(json: any): CarePlanEsignRecipient {
    return CarePlanEsignRecipientFromJSONTyped(json, false);
}

export function CarePlanEsignRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanEsignRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'close_connection_id': json['close_connection_id'],
        'close_connection': !exists(json, 'close_connection') ? undefined : CloseConnectionFromJSON(json['close_connection']),
        'type': json['type'],
        'signed': !exists(json, 'signed') ? undefined : json['signed'],
    };
}

export function CarePlanEsignRecipientToJSON(value?: CarePlanEsignRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'close_connection_id': value.close_connection_id,
        'close_connection': CloseConnectionToJSON(value.close_connection),
        'type': value.type,
        'signed': value.signed,
    };
}

