/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentProduct } from './ResidentProduct';
import {
    ResidentProductFromJSON,
    ResidentProductFromJSONTyped,
    ResidentProductToJSON,
} from './ResidentProduct';

/**
 * 
 * @export
 * @interface CarePackageWithoutPrice
 */
export interface CarePackageWithoutPrice {
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    gl_code: string;
    /**
     * 
     * @type {string}
     * @memberof CarePackageWithoutPrice
     */
    billing_product_id?: string;
    /**
     * 
     * @type {Array<ResidentProduct>}
     * @memberof CarePackageWithoutPrice
     */
    resident_products?: Array<ResidentProduct>;
}

/**
 * Check if a given object implements the CarePackageWithoutPrice interface.
 */
export function instanceOfCarePackageWithoutPrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "gl_code" in value;

    return isInstance;
}

export function CarePackageWithoutPriceFromJSON(json: any): CarePackageWithoutPrice {
    return CarePackageWithoutPriceFromJSONTyped(json, false);
}

export function CarePackageWithoutPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePackageWithoutPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'title': json['title'],
        'description': json['description'],
        'gl_code': json['gl_code'],
        'billing_product_id': !exists(json, 'billing_product_id') ? undefined : json['billing_product_id'],
        'resident_products': !exists(json, 'resident_products') ? undefined : ((json['resident_products'] as Array<any>).map(ResidentProductFromJSON)),
    };
}

export function CarePackageWithoutPriceToJSON(value?: CarePackageWithoutPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category_id': value.category_id,
        'title': value.title,
        'description': value.description,
        'gl_code': value.gl_code,
        'billing_product_id': value.billing_product_id,
        'resident_products': value.resident_products === undefined ? undefined : ((value.resident_products as Array<any>).map(ResidentProductToJSON)),
    };
}

