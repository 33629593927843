/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareConditionsServices = {
    HivAids: 'hiv_aids',
    ParenteralIvFeeding7days51Calories: 'parenteral_iv_feeding_7days_51_calories',
    IvMedications14days: 'iv_medications_14days',
    VentilatorRespirator14days: 'ventilator_respirator_14days',
    ParenteralIvFeeding7days2650Calories: 'parenteral_iv_feeding_7days_26_50_calories',
    LungTransplant: 'lung_transplant',
    Transfusions14days: 'transfusions_14days',
    MajorOrganTransplantExceptLung: 'major_organ_transplant_except_lung',
    MultipleSclerosis: 'multiple_sclerosis',
    OpportunisticInfections: 'opportunistic_infections',
    AsthmaCopdChronicLungDisease7days: 'asthma_copd_chronic_lung_disease_7days',
    BoneJointMuscleInfectionsExceptAseptic: 'bone_joint_muscle_infections_except_aseptic',
    ChronicMyeloidLeukemia: 'chronic_myeloid_leukemia',
    WoundInfectionNonFoot7days: 'wound_infection_non_foot_7days',
    DiabetesMellitus: 'diabetes_mellitus',
    Endocarditis: 'endocarditis',
    ImmuneDisorder: 'immune_disorder',
    EndStageLiverDisease: 'end_stage_liver_disease',
    DiabeticFootUlcer: 'diabetic_foot_ulcer',
    NarcolepsyCataplexy: 'narcolepsy_cataplexy',
    CysticFibrosis: 'cystic_fibrosis',
    TracheostomyCare14days: 'tracheostomy_care_14days',
    MultidrugResistantOrganism7days: 'multidrug_resistant_organism_7days',
    QuarantinedInfectiousDisease14days: 'quarantined_infectious_disease_14days',
    HereditaryMetabolicImmuneDisorder: 'hereditary_metabolic_immune_disorder',
    MorbidObesity: 'morbid_obesity',
    Radiation14days: 'radiation_14days',
    Stage4PressureUlcer: 'stage_4_pressure_ulcer',
    PsoriaticArthropathySystemicSclerosis: 'psoriatic_arthropathy_systemic_sclerosis',
    ChronicPancreatitis: 'chronic_pancreatitis',
    ProliferativeDiabeticRetinopathyVitreousHemorrhage: 'proliferative_diabetic_retinopathy_vitreous_hemorrhage',
    FootInfectionOpenLesionExcludeDiabeticUlcer: 'foot_infection_open_lesion_exclude_diabetic_ulcer',
    ComplicationsImplantedDeviceGraft: 'complications_implanted_device_graft',
    IntermittentCatheterizations: 'intermittent_catheterizations',
    UlcerativeColitisCrohnsIbd7days: 'ulcerative_colitis_crohns_ibd_7days',
    AsepticNecrosisBone: 'aseptic_necrosis_bone',
    Suctioning14days: 'suctioning_14days',
    CardioRespiratoryFailureShock: 'cardio_respiratory_failure_shock',
    MyelodysplasticSyndromesMyelofibrosis: 'myelodysplastic_syndromes_myelofibrosis',
    SystemicLupusConnectiveTissueInflammatorySpondylopathy: 'systemic_lupus_connective_tissue_inflammatory_spondylopathy',
    DiabeticRetinopathyExcludeProliferative: 'diabetic_retinopathy_exclude_proliferative',
    FeedingTube7days: 'feeding_tube_7days',
    SevereSkinBurnCondition: 'severe_skin_burn_condition',
    IntractableEpilepsy: 'intractable_epilepsy',
    Malnutrition7days: 'malnutrition_7days',
    ImmuneDisorderExcludeRxcc97: 'immune_disorder_exclude_rxcc97',
    CirrhosisLiver: 'cirrhosis_liver',
    Ostomy: 'ostomy',
    RespiratoryArrest: 'respiratory_arrest',
    PulmonaryFibrosisChronicLungDisorder: 'pulmonary_fibrosis_chronic_lung_disorder'
} as const;
export type MedicareConditionsServices = typeof MedicareConditionsServices[keyof typeof MedicareConditionsServices];


export function MedicareConditionsServicesFromJSON(json: any): MedicareConditionsServices {
    return MedicareConditionsServicesFromJSONTyped(json, false);
}

export function MedicareConditionsServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareConditionsServices {
    return json as MedicareConditionsServices;
}

export function MedicareConditionsServicesToJSON(value?: MedicareConditionsServices | null): any {
    return value as any;
}

