import {
  CrmReferralSourceListEntry,
  LifecycleStep,
  RelationType
} from '@/types/crm-campaigns';

export interface CrmReferralSourceModelsPage {
  referral_sources: Array<CrmReferralSourceListEntryModel>;
  total_count: number;
}

export class CrmReferralSourceListEntryModel
  implements CrmReferralSourceListEntry
{
  public readonly id: string;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly source_type: string;
  public readonly company_name: string;
  public readonly is_paid: boolean;
  public readonly lifecycle_step: LifecycleStep;
  public readonly last_referral_date: string | null;
  public readonly relation_type: RelationType;
  public readonly relation_id: string;
  public readonly is_added_to_audience?: boolean | undefined;
  public readonly fullName: string;

  constructor(payload: CrmReferralSourceListEntry) {
    this.id = payload.id;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.source_type = payload.source_type;
    this.company_name = payload.company_name;
    this.is_paid = payload.is_paid;
    this.lifecycle_step = payload.lifecycle_step;
    this.last_referral_date = payload.last_referral_date;
    this.relation_type = payload.relation_type;
    this.relation_id = payload.relation_id;
    this.is_added_to_audience = payload.is_added_to_audience;
    this.fullName = `${payload.first_name} ${payload.last_name}`;
  }

  public getFullName(): string {
    return this.fullName;
  }

  public getInitials = (): string =>
    `${this.first_name?.charAt(0) || ''}${this.last_name?.charAt(0) || ''}`;
}
