import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

// TODO: Discover why this isn't wrapped in a loadable component
import MedicationInventoryHistory from '@/pages/Pharmacy/MedicationInventoryHistoryPage';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ProtectedRoute } from '../ProtectedRoute';

const MedicationStockPage = loadable(() => import("@/pages/Pharmacy/MedicationStock")); // prettier-ignore
const PharmacyMedicationStock = loadable(() => import("@/pages/Pharmacy/MedicationStock")); // prettier-ignore
const PharmacyMedicationView = loadable(() => import("@/pages/Pharmacy/PrescriptionsViewPage/PrescriptionView")); // prettier-ignore
const PharmacyMessages = loadable(() => import("@/pages/Pharmacy/PharmacyProfile/Messages/PharmacyMessages")); // prettier-ignore
const PharmacyPrescriptions = loadable(() => import("@/pages/Pharmacy/PrescriptionsV2Page")); // prettier-ignore
const PharmacyPrescriptionsOrdersPage = loadable(() => import("@/pages/Pharmacy/PrescriptionOrdersPage")); // prettier-ignore
const PharmacyProfile = loadable(() => import("@/pages/Pharmacy/PharmacyProfile/index")); // prettier-ignore
const PharmacyProfilePage = loadable(() => import("@/pages/Pharmacy/PharmacyProfile/Settings/PharmacyProfilePage")); // prettier-ignore
const PharmacySettings = loadable(() => import("@/pages/Pharmacy/PharmacySettings")); // prettier-ignore
const PharmacyViewPage = loadable(() => import("@/pages/Pharmacy/PharmacyView/PharmacyViewPage")); // prettier-ignore
const PrescriptionDetails = loadable(() => import("@/pages/Pharmacy/PharmacyProfile/Messages/PrescriptionDetails/PrescriptionDetails")); // prettier-ignore

export enum PharmacyRoutes {
  MedicationStock = '/medication-stock',
  PharmacyMedicationStock = '/pharmacy/medication-stock',
  PharmacyMedicationView = '/pharmacy/prescription-view',
  PharmacyMedicationViewV2 = '/pharmacy/view-prescriptions',
  PharmacyPrescriptionOrders = '/pharmacy/prescription-orders',
  PrescriptionDetails = '/pharmacy/messages/prescription-details/:prescription_id',
  Pharmacy = '/pharmacy',
  PharmacyProfile = '/pharmacy/profile',
  PharmacyViewPage = '/pharmacy/pharmacies',
  PharmacyMessages = '/pharmacy/:pharmacy_id/messages',
  PharmacySetting = '/pharmacy/:pharmacy_id/settings',
  PharmacyMedicationInventoryHistory = '/pharmacy/medication-inventory-history'
}

export const getEhrPharmacyRoutes = (): RouteObject[] => {
  return [
    {
      path: PharmacyRoutes.PrescriptionDetails,
      element: <ProtectedRoute component={PrescriptionDetails} role="L1" />
    },
    {
      path: PharmacyRoutes.MedicationStock,
      element: <MedicationStockPage />
    },
    {
      path: PharmacyRoutes.PharmacyMedicationInventoryHistory,
      element: <MedicationInventoryHistory />
    },
    {
      path: PharmacyRoutes.Pharmacy,
      element: <PharmacyProfile />,
      children: [
        {
          path: PharmacyRoutes.PharmacyMessages,
          element: <ProtectedRoute role="L1" component={PharmacyMessages} />
        },
        {
          path: PharmacyRoutes.PharmacySetting,
          element: <ProtectedRoute role="L1" component={PharmacyProfilePage} />
        }
      ]
    },
    ...(FeatureFlagService.isEnabled(ExacareFeature.PHARMACY)
      ? [
          {
            path: PharmacyRoutes.Pharmacy,
            element: <ProtectedRoute component={PharmacySettings} role="L4" />,
            children: [
              {
                path: PharmacyRoutes.PharmacyMedicationStock,
                element: <PharmacyMedicationStock />
              },
              ...(FeatureFlagService.isEnabled(ExacareFeature.PHARMACY_V2)
                ? [
                    {
                      path: PharmacyRoutes.PharmacyMedicationViewV2,
                      element: <PharmacyPrescriptions />
                    }
                  ]
                : []),
              {
                path: PharmacyRoutes.PharmacyMedicationView,
                element: <PharmacyMedicationView />
              },
              {
                path: PharmacyRoutes.PharmacyPrescriptionOrders,
                element: <PharmacyPrescriptionsOrdersPage />
              },
              {
                path: PharmacyRoutes.PharmacyViewPage,
                element: (
                  <ProtectedRoute component={PharmacyViewPage} role="L2" />
                )
              }
            ]
          }
        ]
      : [])
  ];
};
