import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { HTTPError } from 'ky/distribution/errors/HTTPError';

import { useMutateIncident } from '@/hooks/useCreateIncident';
import { SignOpenIncident } from '@/hooks/useCreateIncident';
import { useIncidentsQuery } from '@/hooks/useIncidentsQuery';
import { useSnackbar } from '@/hooks/useSnackbar';

interface AddIncidentSignButtonProps {
  formMethods: UseFormReturn<SignOpenIncident, any>;
  closeModal: (value?: any) => void;
  incidentId: number;
}

const AddIncidentSignButton: React.FC<AddIncidentSignButtonProps> = ({
  formMethods,
  closeModal,
  incidentId
}) => {
  const { getValues } = formMethods;
  const incidents = useIncidentsQuery();
  const mutateIncident = useMutateIncident(incidentId);
  const { showSnackbar } = useSnackbar();

  const handleIncidentSubmit = () => {
    const pin = getValues('pin');

    mutateIncident.close.mutate(
      { pin },
      {
        onSuccess: () => {
          closeModal();
          incidents.invalidate();
          showSnackbar({
            message: 'Incident signed successfully',
            severity: 'success'
          });
        },
        onError: async (error) => {
          const errorMessage = await (error as HTTPError)?.response.text();
          closeModal();
          showSnackbar({
            message: errorMessage,
            severity: 'error'
          });
        }
      }
    );
  };

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleIncidentSubmit}
      id="incident-sign-button"
      loading={mutateIncident.close.isLoading}>
      Sign and Complete
    </LoadingButton>
  );
};

export default AddIncidentSignButton;
