/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OneTimeChargeWithoutPrice
 */
export interface OneTimeChargeWithoutPrice {
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeChargeWithoutPrice
     */
    billing_product_id?: string;
}

/**
 * Check if a given object implements the OneTimeChargeWithoutPrice interface.
 */
export function instanceOfOneTimeChargeWithoutPrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function OneTimeChargeWithoutPriceFromJSON(json: any): OneTimeChargeWithoutPrice {
    return OneTimeChargeWithoutPriceFromJSONTyped(json, false);
}

export function OneTimeChargeWithoutPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OneTimeChargeWithoutPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'billing_product_id': !exists(json, 'billing_product_id') ? undefined : json['billing_product_id'],
    };
}

export function OneTimeChargeWithoutPriceToJSON(value?: OneTimeChargeWithoutPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'category_id': value.category_id,
        'gl_code': value.gl_code,
        'billing_product_id': value.billing_product_id,
    };
}

