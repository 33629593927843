/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmResidentCountResponse } from './CrmResidentCountResponse';
import {
    CrmResidentCountResponseFromJSON,
    CrmResidentCountResponseFromJSONTyped,
    CrmResidentCountResponseToJSON,
} from './CrmResidentCountResponse';

/**
 * 
 * @export
 * @interface CountCRMResidents200Response
 */
export interface CountCRMResidents200Response {
    /**
     * 
     * @type {CrmResidentCountResponse}
     * @memberof CountCRMResidents200Response
     */
    data?: CrmResidentCountResponse;
}

/**
 * Check if a given object implements the CountCRMResidents200Response interface.
 */
export function instanceOfCountCRMResidents200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CountCRMResidents200ResponseFromJSON(json: any): CountCRMResidents200Response {
    return CountCRMResidents200ResponseFromJSONTyped(json, false);
}

export function CountCRMResidents200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountCRMResidents200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CrmResidentCountResponseFromJSON(json['data']),
    };
}

export function CountCRMResidents200ResponseToJSON(value?: CountCRMResidents200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CrmResidentCountResponseToJSON(value.data),
    };
}

