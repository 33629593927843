/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExclusionTermCreatedByUser } from './ExclusionTermCreatedByUser';
import {
    ExclusionTermCreatedByUserFromJSON,
    ExclusionTermCreatedByUserFromJSONTyped,
    ExclusionTermCreatedByUserToJSON,
} from './ExclusionTermCreatedByUser';

/**
 * 
 * @export
 * @interface ExclusionTerm
 */
export interface ExclusionTerm {
    /**
     * Unique identifier for the exclusion term
     * @type {string}
     * @memberof ExclusionTerm
     */
    id: string;
    /**
     * Facility ID this term belongs to
     * @type {string}
     * @memberof ExclusionTerm
     */
    facility_id: string;
    /**
     * The exclusion term text
     * @type {string}
     * @memberof ExclusionTerm
     */
    term: string;
    /**
     * ID of the user who created this term
     * @type {string}
     * @memberof ExclusionTerm
     */
    created_by_user_id: string;
    /**
     * Timestamp when the term was created
     * @type {string}
     * @memberof ExclusionTerm
     */
    createdAt: string;
    /**
     * Timestamp when the term was last modified
     * @type {string}
     * @memberof ExclusionTerm
     */
    updatedAt: string;
    /**
     * Timestamp when the term was deleted
     * @type {string}
     * @memberof ExclusionTerm
     */
    deletedAt?: string;
    /**
     * 
     * @type {ExclusionTermCreatedByUser}
     * @memberof ExclusionTerm
     */
    created_by_user: ExclusionTermCreatedByUser;
}

/**
 * Check if a given object implements the ExclusionTerm interface.
 */
export function instanceOfExclusionTerm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "term" in value;
    isInstance = isInstance && "created_by_user_id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "created_by_user" in value;

    return isInstance;
}

export function ExclusionTermFromJSON(json: any): ExclusionTerm {
    return ExclusionTermFromJSONTyped(json, false);
}

export function ExclusionTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExclusionTerm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'term': json['term'],
        'created_by_user_id': json['created_by_user_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
        'created_by_user': ExclusionTermCreatedByUserFromJSON(json['created_by_user']),
    };
}

export function ExclusionTermToJSON(value?: ExclusionTerm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'term': value.term,
        'created_by_user_id': value.created_by_user_id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
        'created_by_user': ExclusionTermCreatedByUserToJSON(value.created_by_user),
    };
}

