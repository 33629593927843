/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentProtocolListEntry
 */
export interface IncidentProtocolListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolListEntry
     */
    last_modified_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolListEntry
     */
    last_modified_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolListEntry
     */
    created_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolListEntry
     */
    publish_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolListEntry
     */
    status?: string;
}

/**
 * Check if a given object implements the IncidentProtocolListEntry interface.
 */
export function instanceOfIncidentProtocolListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IncidentProtocolListEntryFromJSON(json: any): IncidentProtocolListEntry {
    return IncidentProtocolListEntryFromJSONTyped(json, false);
}

export function IncidentProtocolListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'last_modified_by': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'last_modified_date': !exists(json, 'last_modified_date') ? undefined : (new Date(json['last_modified_date'])),
        'created_by': !exists(json, 'created_by') ? undefined : json['created_by'],
        'publish_date': !exists(json, 'publish_date') ? undefined : (new Date(json['publish_date'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function IncidentProtocolListEntryToJSON(value?: IncidentProtocolListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'last_modified_by': value.last_modified_by,
        'last_modified_date': value.last_modified_date === undefined ? undefined : (value.last_modified_date.toISOString()),
        'created_by': value.created_by,
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date.toISOString()),
        'status': value.status,
    };
}

