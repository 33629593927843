import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { incidentsv2Client } from '@/adapters/schemaClients';
import {
  CreateProtocolRequest,
  DeleteProtocolRequest,
  DuplicateProtocolRequest,
  FindAllProtocols200Response,
  FindAllProtocolsRequest,
  PublishProtocolRequest,
  UnPublishProtocolRequest,
  UpdateProtocolRequest
} from '@/types/incidentsv2';

const FIND_ALL_PROTOCOLS_QUERY_KEY = 'findAllProtocols';

function useProtocolsQuery() {
  const invalidate = () =>
    queryClient.invalidateQueries([FIND_ALL_PROTOCOLS_QUERY_KEY]);
  return {
    invalidate,
    findAll: (
      facility_id: FindAllProtocolsRequest,
      options: UseQueryOptions<
        FindAllProtocols200Response['data'],
        HTTPError
      > = {}
    ) => {
      return useQuery<FindAllProtocols200Response['data'], HTTPError>(
        [FIND_ALL_PROTOCOLS_QUERY_KEY, facility_id],
        async () => {
          const response = await incidentsv2Client.findAllProtocols(
            facility_id
          );
          return response.data;
        },
        {
          ...options
        }
      );
    },
    publish: useMutation(
      (params: PublishProtocolRequest) => {
        return incidentsv2Client.publishProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    ),
    duplicate: useMutation(
      (params: DuplicateProtocolRequest) => {
        return incidentsv2Client.duplicateProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    ),
    rename: useMutation(
      (params: UpdateProtocolRequest) => {
        return incidentsv2Client.updateProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    ),
    deactivate: useMutation(
      (params: UnPublishProtocolRequest) => {
        return incidentsv2Client.unPublishProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    ),
    remove: useMutation(
      (params: DeleteProtocolRequest) => {
        return incidentsv2Client.deleteProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    ),
    create: useMutation(
      (params: CreateProtocolRequest) => {
        return incidentsv2Client.createProtocol(params);
      },
      {
        onSuccess: () => {
          invalidate();
        }
      }
    )
  };
}

export default useProtocolsQuery;
