/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MakesSelfUnderstoodOption = {
    Na: 'na',
    UsuallyUnderstood: 'usually_understood',
    SometimesUnderstood: 'sometimes_understood',
    RarelyUnderstood: 'rarely_understood'
} as const;
export type MakesSelfUnderstoodOption = typeof MakesSelfUnderstoodOption[keyof typeof MakesSelfUnderstoodOption];


export function MakesSelfUnderstoodOptionFromJSON(json: any): MakesSelfUnderstoodOption {
    return MakesSelfUnderstoodOptionFromJSONTyped(json, false);
}

export function MakesSelfUnderstoodOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakesSelfUnderstoodOption {
    return json as MakesSelfUnderstoodOption;
}

export function MakesSelfUnderstoodOptionToJSON(value?: MakesSelfUnderstoodOption | null): any {
    return value as any;
}

