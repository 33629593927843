import React, { SyntheticEvent } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import ResidentsPanel from './ResidentsPanel';

export enum ResidentTabsOptions {
  RECENTLY_VIEWED = 'RECENTLY_VIEWED',
  ALL_RESIDENTS = 'ALL_RESIDENTS',
  PROSPECTIVE_RESIDENTS = 'PROSPECTIVE_RESIDENTS'
}

export default function ResidentsTabs({
  searchQuery,
  currentTab,
  handleTabChange,
  onResolve,
  isCRM
}: {
  searchQuery: string;
  currentTab: number;
  handleTabChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => void;
  onResolve: VoidFunction;
  isCRM: boolean;
}) {
  const currentUser = useCurrentUser().data!;
  const isMobileViewport = useIsMobileViewport();

  const tabs = [
    {
      id: ResidentTabsOptions.RECENTLY_VIEWED,
      label: 'Recently Viewed'
    },
    ...(isCRM
      ? []
      : [
          {
            id: ResidentTabsOptions.ALL_RESIDENTS,
            label: 'All Residents'
          }
        ]),
    ...(currentUser.isCrmEnabled()
      ? [
          {
            id: ResidentTabsOptions.PROSPECTIVE_RESIDENTS,
            label: 'Prospective Residents'
          }
        ]
      : [])
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        allowScrollButtonsMobile={isMobileViewport}
        sx={{ padding: '0 !important' }}
        value={currentTab}
        onChange={handleTabChange}>
        {tabs.map(({ label, id }, i) => {
          return <Tab id={id} key={i} label={label} iconPosition="start" />;
        })}
      </Tabs>
      {tabs.map((tab, i) => (
        <ResidentsPanel
          isCRM={isCRM}
          onResolve={onResolve}
          searchQuery={searchQuery}
          key={i}
          value={currentTab}
          index={i}
          tabId={tab.id}
        />
      ))}
    </Box>
  );
}
