/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentMoveInRequest
 */
export interface ResidentMoveInRequest {
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    resident_id: string;
    /**
     * billing_product_id from the room object
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    room_product_id?: string;
    /**
     * Date of move in
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    service_start_date: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveInRequest
     */
    facility_id?: string;
}

/**
 * Check if a given object implements the ResidentMoveInRequest interface.
 */
export function instanceOfResidentMoveInRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "dob" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "service_start_date" in value;

    return isInstance;
}

export function ResidentMoveInRequestFromJSON(json: any): ResidentMoveInRequest {
    return ResidentMoveInRequestFromJSONTyped(json, false);
}

export function ResidentMoveInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentMoveInRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'dob': json['dob'],
        'resident_id': json['resident_id'],
        'room_product_id': !exists(json, 'room_product_id') ? undefined : json['room_product_id'],
        'service_start_date': json['service_start_date'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
    };
}

export function ResidentMoveInRequestToJSON(value?: ResidentMoveInRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'dob': value.dob,
        'resident_id': value.resident_id,
        'room_product_id': value.room_product_id,
        'service_start_date': value.service_start_date,
        'facility_id': value.facility_id,
    };
}

