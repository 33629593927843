import React from 'react';
import { Close } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

interface NotificationToastProps {
  title: string;
  message: string;
  actions: React.ReactNode;
  dismissToast: () => void;
}

const NotificationToast: React.FC<NotificationToastProps> = ({
  title,
  message,
  actions,
  dismissToast
}) => {
  const isMobileViewport = useIsMobileViewport();

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '8px',
        padding: '16px',
        minWidth: isMobileViewport ? '100%' : '380px'
      }}>
      <Stack bgcolor="#FFFFFF" direction="column">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="row" gap={0} alignItems="center">
            <Typography fontSize="16px" color="#0A1E28" fontWeight={500}>
              {title}
            </Typography>
          </Stack>
          <Close
            onClick={dismissToast}
            sx={{ cursor: 'pointer' }}
            htmlColor="#9AAEBB"
          />
        </Stack>
        <Stack>
          <Typography
            color="#364955"
            fontSize="12px"
            fontWeight={400}
            marginTop="8px">
            {message}
          </Typography>
        </Stack>
        {actions && actions}
      </Stack>
    </Paper>
  );
};

export default NotificationToast;
