import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import { fetchResidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { USE_FACILITIES_QUERY_KEY } from '@/hooks/useFacilitiesQuery';
import { USE_CRM_FACILITIES_QUERY_KEY } from '@/pages/AdminHub/CommunitySettings/useCRMFacilitiesQuery';

import {
  FIND_ALL_RESIDENTS_QUERY_KEY,
  FIND_ONE_RESIDENT_QUERY_KEY
} from './useResidentQuery';

interface PartialResidentGroupResident {
  first_name: string;
  last_name: string;
}
export interface ResidentGroup {
  id?: string;
  facility_id?: string;
  name: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  residents?: PartialResidentGroupResident[];
}

export const USE_RESIDENT_GROUPS_QUERY_KEY = ['useResidentGroupsQuery'];

export function useResidentGroupsQuery() {
  const invalidate = () => {
    queryClient.invalidateQueries(USE_CRM_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_RESIDENT_GROUPS_QUERY_KEY);
    queryClient.invalidateQueries([FIND_ONE_RESIDENT_QUERY_KEY]);
    queryClient.invalidateQueries([FIND_ALL_RESIDENTS_QUERY_KEY]);
  };

  return {
    invalidate,

    findAll: (
      includeResidents: boolean,
      facilityId?: string | null,
      options: UseQueryOptions = {}
    ) => {
      return useQuery(
        [...USE_RESIDENT_GROUPS_QUERY_KEY, includeResidents, facilityId],
        () => {
          return fetchResidents.get<ResidentGroup[]>('/resident-groups', {
            searchParams: {
              facility: facilityId,
              residents: includeResidents
            }
          });
        },
        options as any
      );
    },

    mutations: (options: UseMutationOptions = {}) => ({
      delete: useMutation(
        (resident_group_id: string) => {
          return fetchResidents.delete(`/resident-groups/${resident_group_id}`);
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}
