/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillCredit } from './BillCredit';
import {
    BillCreditFromJSON,
    BillCreditFromJSONTyped,
    BillCreditToJSON,
} from './BillCredit';

/**
 * 
 * @export
 * @interface OneTimeBillItem
 */
export interface OneTimeBillItem {
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    bill_id: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OneTimeBillItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeBillItem
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeBillItem
     */
    tax?: number;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    discount_gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    service_start_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    service_end_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OneTimeBillItem
     */
    single_service_date?: string | null;
    /**
     * 
     * @type {Array<BillCredit>}
     * @memberof OneTimeBillItem
     */
    correction_credits: Array<BillCredit>;
}

/**
 * Check if a given object implements the OneTimeBillItem interface.
 */
export function instanceOfOneTimeBillItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bill_id" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "correction_credits" in value;

    return isInstance;
}

export function OneTimeBillItemFromJSON(json: any): OneTimeBillItem {
    return OneTimeBillItemFromJSONTyped(json, false);
}

export function OneTimeBillItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OneTimeBillItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bill_id': json['bill_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'price': json['price'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'discount_gl_code': !exists(json, 'discount_gl_code') ? undefined : json['discount_gl_code'],
        'service_start_date': !exists(json, 'service_start_date') ? undefined : json['service_start_date'],
        'service_end_date': !exists(json, 'service_end_date') ? undefined : json['service_end_date'],
        'single_service_date': !exists(json, 'single_service_date') ? undefined : json['single_service_date'],
        'correction_credits': ((json['correction_credits'] as Array<any>).map(BillCreditFromJSON)),
    };
}

export function OneTimeBillItemToJSON(value?: OneTimeBillItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bill_id': value.bill_id,
        'name': value.name,
        'price': value.price,
        'discount': value.discount,
        'tax': value.tax,
        'gl_code': value.gl_code,
        'discount_gl_code': value.discount_gl_code,
        'service_start_date': value.service_start_date,
        'service_end_date': value.service_end_date,
        'single_service_date': value.single_service_date,
        'correction_credits': ((value.correction_credits as Array<any>).map(BillCreditToJSON)),
    };
}

