import { generatePath } from 'react-router-dom';

type GeneratePathParams = Parameters<typeof generatePath>;

/**
 * Generates a path with the domain parameter based on the current location.
 */
export function generatePathWithDomain(
  path: GeneratePathParams[0],
  params?: GeneratePathParams[1]
) {
  const domain = location.pathname.startsWith('/crm') ? 'crm' : 'ehr';
  return generatePath(path, { domain, ...params });
}
