import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { Box, Icon, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { UserPayload } from '@/hooks/useUserQuery';
import { ProspectResidentPayload } from '@/models/ProspectResidentModel';
import { ReferralPayload } from '@/models/ReferralModel';
import { ResidentCloseConnectionPayload } from '@/models/ResidentCloseConnectionModel';
import { UserModel } from '@/models/UserModel';
import { GenericEnumOption } from '@/stores/residentFacesheetsAtom';
import theme from '@/theme';
import { formatPhoneNumber } from '@/utils/phoneNumber';

import { CallCardProps } from '../InboxPage/CallInbox/components/CallCard';

import { CrmCallNotePayload } from './CrmCallNoteModel';
import { CrmRelationType } from './CrmTaskModel';
import { PhoneBookItemModel, PhoneBookItemPayload } from './PhoneBookItemModel';

export enum CallStatus {
  Queued = 'queued',
  Ringing = 'ringing',
  InProgress = 'in-progress',
  Completed = 'completed',
  Busy = 'busy',
  Failed = 'failed',
  NoAnswer = 'no-answer',
  Canceled = 'canceled'
}

export const callFailedEndStatuses: string[] = [
  CallStatus.Failed,
  CallStatus.NoAnswer,
  CallStatus.Canceled
];

export const MANUAL_LOG_CALL_SID = 'MANUAL_LOG';

export const callTypeOptions: GenericEnumOption[] = [
  {
    label: 'Inbound',
    value: 'INBOUND'
  },
  {
    label: 'Outbound',
    value: 'OUTBOUND'
  }
];

export enum CallOutcomeValues {
  INITIATED = 'INITIATED',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BUSY = 'BUSY',
  FAILED = 'FAILED'
}

export const callOutcomeOptions: GenericEnumOption[] = [
  {
    label: 'Initiated',
    value: CallOutcomeValues.INITIATED
  },
  {
    label: 'Ringing',
    value: CallOutcomeValues.RINGING
  },
  {
    label: 'In Progress',
    value: CallOutcomeValues.IN_PROGRESS
  },
  {
    label: 'Completed',
    value: CallOutcomeValues.COMPLETED
  },
  {
    label: 'Busy',
    value: CallOutcomeValues.BUSY
  },
  {
    label: 'Failed',
    value: CallOutcomeValues.FAILED
  }
];

export type CallType = 'OUTBOUND' | 'INBOUND';

export class CrmCallPayload {
  id: string;
  facility_id: string;
  user_id: string;
  from_number: string;
  to_number: string;
  call_sid: string;
  status: string;
  end_time: string;
  recording_sid: string;
  transcript_sid: string;
  type: CallType;
  relation_id: string | null;
  relation_type: CrmRelationType | null;
  ai_summary: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  crmRelation?:
    | ResidentCloseConnectionPayload
    | ProspectResidentPayload
    | ReferralPayload
    | null;
  phoneBookItem?: PhoneBookItemPayload;
  note?: CrmCallNotePayload | null;
  user?: UserPayload | null;
  constructor(payload: CrmCallPayload) {
    Object.assign(this, payload);
  }
}

export class CrmCallModel extends CrmCallPayload {
  declare user?: UserModel;
  declare phoneBookItem: PhoneBookItemModel;

  constructor(payload: CrmCallPayload) {
    super(payload);
    if (payload.user) {
      this.user = new UserModel(payload.user);
    }
    if (payload.phoneBookItem) {
      this.phoneBookItem = new PhoneBookItemModel(payload.phoneBookItem);
    }
  }

  public capitalize(str: string) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }

  public getType() {
    return this.capitalize(this.type);
  }

  public getStatus() {
    return this.capitalize(this.status).replace(/[_-]/g, ' ');
  }

  public getCallMaker() {
    return this.type === 'INBOUND'
      ? this.getFullNameOrPhone()
      : this.user?.fullName;
  }

  public getCallReceiver() {
    return this.type === 'OUTBOUND'
      ? this.getFullNameOrPhone()
      : this.user?.fullName;
  }

  public getCallMakerInitials() {
    return this.type === 'INBOUND'
      ? this.phoneBookItem?.initials
        ? this.phoneBookItem?.initials
        : undefined
      : this.user?.initials;
  }

  public getCallReceiverInitials() {
    return this.type === 'OUTBOUND'
      ? this.phoneBookItem?.initials
        ? this.phoneBookItem?.initials
        : undefined
      : this.user?.initials;
  }

  public renderDirectionAndOutcomeCell = (): React.ReactNode => {
    return (
      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
        <Icon
          component={
            this.type === 'INBOUND' ? PhoneCallbackIcon : PhoneInTalkIcon
          }
        />
        <Typography variant="inherit" display="inline" fontWeight={500}>
          {`${this.getType()} Call `}
        </Typography>
        {`${
          this.type === 'INBOUND'
            ? `${
                this.relation_type === 'RESIDENT_CLOSE_CONNECTION'
                  ? 'from close connection'
                  : 'from'
              }`
            : 'by'
        } `}
        <Typography variant="inherit" color={theme.palette.primary.dark}>
          {`${
            this.type === 'INBOUND'
              ? this.getFullNameOrPhone()
              : this.user?.fullName
          } `}
        </Typography>
        {this.relation_type === 'RESIDENT_CLOSE_CONNECTION' &&
          this.type === 'OUTBOUND' && (
            <>
              to{' '}
              <Typography variant="inherit" color={theme.palette.primary.dark}>
                {this.getFullNameOrPhone()}
              </Typography>
            </>
          )}
        <ArrowRightAltIcon />
        {this.getStatus()}
      </Box>
    );
  };

  public getFullNameOrPhone = () => {
    return this.phoneBookItem?.fullName
      ? this.phoneBookItem?.fullName
      : this.getNotUserPhone();
  };

  public getNotUserPhone = () => {
    return this.type === 'INBOUND'
      ? formatPhoneNumber(this.from_number)
      : formatPhoneNumber(this.to_number);
  };

  public toCallCardProps = (id?: string): Partial<CallCardProps> => {
    const role = this.phoneBookItem?.type
      ? this.phoneBookItem?.type
      : 'Unknown';
    return {
      isSelected: this.id === id,
      initials: this.phoneBookItem?.initials,
      role: role,
      name: this.getFullNameOrPhone(),
      timestamp: dayjs(this.createdAt).fromNow(),
      s3PhotoKey: this.phoneBookItem?.s3_photo_key,
      callType: (
        <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
          <Icon
            sx={{
              color: callFailedEndStatuses.includes(this.status)
                ? '#DD3730'
                : '#667A86'
            }}
            component={
              this.type === 'INBOUND' ? PhoneCallbackIcon : PhoneInTalkIcon
            }
          />
          <Typography
            variant="inherit"
            display="inline"
            color={
              callFailedEndStatuses.includes(this.status)
                ? '#DD3730'
                : '#364955'
            }>
            {this.getStatus()}
          </Typography>
        </Box>
      )
    };
  };
}
