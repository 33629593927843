/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealStage = {
    Opportunity: 'Opportunity',
    Connected: 'Connected',
    PreTour: 'Pre-Tour',
    PostTour: 'Post-Tour',
    PreArrival: 'Pre-Arrival',
    Deposit: 'Deposit',
    MoveIn: 'Move In',
    Resident: 'Resident',
    ArchivedDeal: 'Archived Deal',
    Null: 'null'
} as const;
export type DealStage = typeof DealStage[keyof typeof DealStage];


export function DealStageFromJSON(json: any): DealStage {
    return DealStageFromJSONTyped(json, false);
}

export function DealStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealStage {
    return json as DealStage;
}

export function DealStageToJSON(value?: DealStage | null): any {
    return value as any;
}

