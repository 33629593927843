import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { fetchResidentResources } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { USE_EMERGENCY_CONTACTS_QUERY_KEY } from '@/hooks/useEmergencyContactsQuery';
import { invalidateProspectResidentsQueries } from '@/hooks/useProspectResidents';
import {
  ResidentCloseConnectionModel,
  ResidentCloseConnectionPayload
} from '@/models/ResidentCloseConnectionModel';
import { invalidateAudiencesQueries } from '@/pages/CRM/hooks/useAudienceQuery';
import { invalidateCallsQueries } from '@/pages/CRM/hooks/useCrmCallsQuery';
import { invalidateConversationQueries } from '@/pages/CRM/hooks/useCrmConversationsQuery';
import { invalidatePhoneBookQuery } from '@/pages/CRM/hooks/useCrmPhoneBookQuery';
import { invalidateEmailAddressBookQuery } from '@/pages/CRM/hooks/useEmailAddressBookQuery';

const updateCloseConnection = async (
  payload: Partial<ResidentCloseConnectionPayload>
): Promise<ResidentCloseConnectionModel> => {
  const data = await fetchResidentResources.put<ResidentCloseConnectionPayload>(
    `/close-connections/${payload.id}`,
    payload
  );
  return new ResidentCloseConnectionModel(data);
};

const createCloseConnection = async (
  payload: Partial<ResidentCloseConnectionPayload>
): Promise<ResidentCloseConnectionModel> => {
  const data =
    await fetchResidentResources.post<ResidentCloseConnectionPayload>(
      '/close-connections',
      payload
    );
  return new ResidentCloseConnectionModel(data);
};

interface FindAllResidentCloseConnectionsQueryParams<T> {
  residentId?: string;
  facilityId?: string;
  options?: UseQueryOptions<ResidentCloseConnectionModel[], HTTPError, T>;
  includeProspectResident?: boolean;
  includeResident?: boolean;
}

const USE_RESIDENT_CLOSE_CONNECTIONS_FIND_ALL_QUERY_KEY = [
  'useResidentCloseConnectionsFindAll'
];
export const USE_RESIDENT_CLOSE_CONNECTIONS_CREATE_MUTATION_KEY = [
  'useResidentCloseConnectionsCreate'
];

export const invalidateCloseConnectionsQuery = (residentId?: string) => {
  queryClient.invalidateQueries([
    ...USE_EMERGENCY_CONTACTS_QUERY_KEY,
    residentId
  ]);
  queryClient.invalidateQueries(
    USE_RESIDENT_CLOSE_CONNECTIONS_FIND_ALL_QUERY_KEY
  );
};

export const useResidentCloseConnectionsQuery = () => {
  return {
    invalidateCloseConnectionsQuery,
    findAll: <T = ResidentCloseConnectionModel[]>({
      residentId,
      facilityId,
      includeProspectResident = false,
      includeResident = false,
      options = {}
    }: FindAllResidentCloseConnectionsQueryParams<T>) =>
      useQuery<ResidentCloseConnectionModel[], HTTPError, T>(
        [
          ...USE_RESIDENT_CLOSE_CONNECTIONS_FIND_ALL_QUERY_KEY,
          {
            residentId,
            facilityId,
            includeProspectResident,
            includeResident
          }
        ],
        async () => {
          const payload = await fetchResidentResources.get<
            ResidentCloseConnectionPayload[]
          >(`/close-connections`, {
            searchParams: {
              resident_id: residentId,
              facility_id: facilityId,
              prospect_resident: includeProspectResident,
              resident: includeResident
            }
          });
          return payload.map(
            (payload) => new ResidentCloseConnectionModel(payload)
          );
        },
        options
      ),
    mutations: {
      save: useMutation(
        async (payload: Partial<ResidentCloseConnectionPayload>) => {
          if (payload.id) {
            return updateCloseConnection(payload);
          } else {
            return createCloseConnection(payload);
          }
        },
        {
          mutationKey: USE_RESIDENT_CLOSE_CONNECTIONS_CREATE_MUTATION_KEY,
          onSuccess: (data) => {
            invalidateCloseConnectionsQuery(data.resident_id);
            invalidateCallsQueries();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
            invalidateEmailAddressBookQuery();
            invalidateProspectResidentsQueries();
          }
        }
      ),
      delete: useMutation(
        async (id: string) => {
          return await fetchResidentResources.delete<void>(
            `/close-connections/${id}`
          );
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              USE_RESIDENT_CLOSE_CONNECTIONS_FIND_ALL_QUERY_KEY
            );
            invalidateAudiencesQueries();
            invalidateProspectResidentsQueries();
          }
        }
      )
    }
  };
};
