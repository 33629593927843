/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentCreateResponse } from './IncidentCreateResponse';
import {
    IncidentCreateResponseFromJSON,
    IncidentCreateResponseFromJSONTyped,
    IncidentCreateResponseToJSON,
} from './IncidentCreateResponse';

/**
 * 
 * @export
 * @interface CreateIncident200Response
 */
export interface CreateIncident200Response {
    /**
     * 
     * @type {IncidentCreateResponse}
     * @memberof CreateIncident200Response
     */
    data?: IncidentCreateResponse;
}

/**
 * Check if a given object implements the CreateIncident200Response interface.
 */
export function instanceOfCreateIncident200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIncident200ResponseFromJSON(json: any): CreateIncident200Response {
    return CreateIncident200ResponseFromJSONTyped(json, false);
}

export function CreateIncident200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncident200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentCreateResponseFromJSON(json['data']),
    };
}

export function CreateIncident200ResponseToJSON(value?: CreateIncident200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentCreateResponseToJSON(value.data),
    };
}

