/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolFollowup } from './IncidentProtocolFollowup';
import {
    IncidentProtocolFollowupFromJSON,
    IncidentProtocolFollowupFromJSONTyped,
    IncidentProtocolFollowupToJSON,
} from './IncidentProtocolFollowup';
import type { IncidentProtocolParty } from './IncidentProtocolParty';
import {
    IncidentProtocolPartyFromJSON,
    IncidentProtocolPartyFromJSONTyped,
    IncidentProtocolPartyToJSON,
} from './IncidentProtocolParty';

/**
 * 
 * @export
 * @interface IncidentActionCreateRequest
 */
export interface IncidentActionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    incident_setting_action_type_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentActionCreateRequest
     */
    deadline: Date;
    /**
     * 
     * @type {Array<IncidentProtocolParty>}
     * @memberof IncidentActionCreateRequest
     */
    responsible_parties: Array<IncidentProtocolParty>;
    /**
     * 
     * @type {Array<IncidentProtocolFollowup>}
     * @memberof IncidentActionCreateRequest
     */
    followups?: Array<IncidentProtocolFollowup>;
    /**
     * 
     * @type {any}
     * @memberof IncidentActionCreateRequest
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    completion_note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentActionCreateRequest
     */
    is_completed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentActionCreateRequest
     */
    is_optional?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionCreateRequest
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the IncidentActionCreateRequest interface.
 */
export function instanceOfIncidentActionCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "incident_id" in value;
    isInstance = isInstance && "deadline" in value;
    isInstance = isInstance && "responsible_parties" in value;
    isInstance = isInstance && "is_completed" in value;

    return isInstance;
}

export function IncidentActionCreateRequestFromJSON(json: any): IncidentActionCreateRequest {
    return IncidentActionCreateRequestFromJSONTyped(json, false);
}

export function IncidentActionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentActionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'incident_setting_action_type_id': !exists(json, 'incident_setting_action_type_id') ? undefined : json['incident_setting_action_type_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deadline': (new Date(json['deadline'])),
        'responsible_parties': ((json['responsible_parties'] as Array<any>).map(IncidentProtocolPartyFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentProtocolFollowupFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'completion_note': !exists(json, 'completion_note') ? undefined : json['completion_note'],
        'is_completed': json['is_completed'],
        'is_optional': !exists(json, 'is_optional') ? undefined : json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function IncidentActionCreateRequestToJSON(value?: IncidentActionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'incident_setting_action_type_id': value.incident_setting_action_type_id,
        'name': value.name,
        'description': value.description,
        'deadline': (value.deadline.toISOString()),
        'responsible_parties': ((value.responsible_parties as Array<any>).map(IncidentProtocolPartyToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentProtocolFollowupToJSON)),
        'complete_followups': value.complete_followups,
        'completion_note': value.completion_note,
        'is_completed': value.is_completed,
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

