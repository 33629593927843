/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoctorSearchResultSource } from './DoctorSearchResultSource';
import {
    DoctorSearchResultSourceFromJSON,
    DoctorSearchResultSourceFromJSONTyped,
    DoctorSearchResultSourceToJSON,
} from './DoctorSearchResultSource';

/**
 * 
 * @export
 * @interface DoctorSearchResult
 */
export interface DoctorSearchResult {
    /**
     * 
     * @type {string}
     * @memberof DoctorSearchResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSearchResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSearchResult
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSearchResult
     */
    dea_number?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSearchResult
     */
    npi_number?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorSearchResult
     */
    levenshtein_distance?: number;
    /**
     * 
     * @type {DoctorSearchResultSource}
     * @memberof DoctorSearchResult
     */
    doctor_source?: DoctorSearchResultSource;
}

/**
 * Check if a given object implements the DoctorSearchResult interface.
 */
export function instanceOfDoctorSearchResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoctorSearchResultFromJSON(json: any): DoctorSearchResult {
    return DoctorSearchResultFromJSONTyped(json, false);
}

export function DoctorSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'dea_number': !exists(json, 'dea_number') ? undefined : json['dea_number'],
        'npi_number': !exists(json, 'npi_number') ? undefined : json['npi_number'],
        'levenshtein_distance': !exists(json, 'levenshtein_distance') ? undefined : json['levenshtein_distance'],
        'doctor_source': !exists(json, 'doctor_source') ? undefined : DoctorSearchResultSourceFromJSON(json['doctor_source']),
    };
}

export function DoctorSearchResultToJSON(value?: DoctorSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'dea_number': value.dea_number,
        'npi_number': value.npi_number,
        'levenshtein_distance': value.levenshtein_distance,
        'doctor_source': DoctorSearchResultSourceToJSON(value.doctor_source),
    };
}

