/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecurringPurchaseDiscount } from './RecurringPurchaseDiscount';
import {
    RecurringPurchaseDiscountFromJSON,
    RecurringPurchaseDiscountFromJSONTyped,
    RecurringPurchaseDiscountToJSON,
} from './RecurringPurchaseDiscount';

/**
 * 
 * @export
 * @interface UpdateRecurringPurchaseRequest
 */
export interface UpdateRecurringPurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRecurringPurchaseRequest
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRecurringPurchaseRequest
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRecurringPurchaseRequest
     */
    service_start_date: string;
    /**
     * 
     * @type {Array<RecurringPurchaseDiscount>}
     * @memberof UpdateRecurringPurchaseRequest
     */
    discounts: Array<RecurringPurchaseDiscount>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRecurringPurchaseRequest
     */
    tax_ids: Array<string>;
}

/**
 * Check if a given object implements the UpdateRecurringPurchaseRequest interface.
 */
export function instanceOfUpdateRecurringPurchaseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "service_start_date" in value;
    isInstance = isInstance && "discounts" in value;
    isInstance = isInstance && "tax_ids" in value;

    return isInstance;
}

export function UpdateRecurringPurchaseRequestFromJSON(json: any): UpdateRecurringPurchaseRequest {
    return UpdateRecurringPurchaseRequestFromJSONTyped(json, false);
}

export function UpdateRecurringPurchaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRecurringPurchaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_id': json['product_id'],
        'resident_id': json['resident_id'],
        'service_start_date': json['service_start_date'],
        'discounts': ((json['discounts'] as Array<any>).map(RecurringPurchaseDiscountFromJSON)),
        'tax_ids': json['tax_ids'],
    };
}

export function UpdateRecurringPurchaseRequestToJSON(value?: UpdateRecurringPurchaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.product_id,
        'resident_id': value.resident_id,
        'service_start_date': value.service_start_date,
        'discounts': ((value.discounts as Array<any>).map(RecurringPurchaseDiscountToJSON)),
        'tax_ids': value.tax_ids,
    };
}

