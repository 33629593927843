/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DoctorSearchResultSource = {
    Resident: 'RESIDENT',
    Facility: 'FACILITY',
    Organization: 'ORGANIZATION'
} as const;
export type DoctorSearchResultSource = typeof DoctorSearchResultSource[keyof typeof DoctorSearchResultSource];


export function DoctorSearchResultSourceFromJSON(json: any): DoctorSearchResultSource {
    return DoctorSearchResultSourceFromJSONTyped(json, false);
}

export function DoctorSearchResultSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorSearchResultSource {
    return json as DoctorSearchResultSource;
}

export function DoctorSearchResultSourceToJSON(value?: DoctorSearchResultSource | null): any {
    return value as any;
}

