import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { UserLoader } from '../middleware/UserLoader';
import { ProtectedRoute } from '../ProtectedRoute';

const StaffFacesheet = loadable(() => import("@/pages/AdminHub/Staff/StaffFacesheet")); // prettier-ignore
const StaffSystemPermissions = loadable(() => import("@/pages/AdminHub/Staff/StaffSystemPermissions")); // prettier-ignore
const StaffUserSettings = loadable(() => import("@/pages/AdminHub/Staff/StaffUserSettings.tsx")); // prettier-ignore
const StaffPage = loadable(() => import('@/pages/AdminHub/Staff'));
const StaffAccess = loadable(() => import("@/pages/AdminHub/Staff/StaffAccess")); // prettier-ignore
const StaffAccessResidents = loadable(() => import("@/pages/AdminHub/Staff/StaffAccess/StaffAccessResidents")); // prettier-ignore
const StaffAccessCommunity = loadable(() => import("@/pages/AdminHub/Staff/StaffAccess/StaffAccessCommunity")); // prettier-ignore
const StaffRecords = loadable(() => import("@/pages/AdminHub/Staff/StaffRecords")); // prettier-ignore

export enum StaffRoutes {
  Staff = '/:domain/admin-hub/staff',
  StaffFacesheet = '/:domain/admin-hub/staff/:staff_id/facesheet',
  StaffRecords = '/:domain/admin-hub/staff/:staff_id/records',
  StaffAccess = '/:domain/admin-hub/staff/:staff_id/access',
  StaffAccessResidents = '/:domain/admin-hub/staff/:staff_id/access/residents',
  StaffAccessCommunity = '/:domain/admin-hub/staff/:staff_id/access/community',
  StaffSystemPermissions = '/:domain/admin-hub/staff/:staff_id/system-permissions',
  StaffUserSettingsPage = '/:domain/admin-hub/staff/:staff_id/user-settings'
}

export const getStaffRoutes = (): RouteObject[] => {
  return [
    {
      path: StaffRoutes.Staff,
      element: (
        <UserLoader>
          <ProtectedRoute component={StaffPage} role="L1" />
        </UserLoader>
      ),
      children: [
        {
          path: StaffRoutes.StaffFacesheet,
          element: <StaffFacesheet />
        },
        {
          path: StaffRoutes.StaffRecords,
          element: <StaffRecords />
        },
        {
          path: StaffRoutes.StaffAccess,
          element: <StaffAccess />,
          children: [
            {
              path: StaffRoutes.StaffAccessCommunity,
              element: <StaffAccessCommunity />
            },
            {
              path: StaffRoutes.StaffAccessResidents,
              element: <StaffAccessResidents />
            }
          ]
        },
        {
          path: StaffRoutes.StaffSystemPermissions,
          element: <StaffSystemPermissions />
        },
        ...(FeatureFlagService.isEnabled(
          ExacareFeature.VITE_FEATURE_FLAG_USER_LANDING_PREFERENCES
        )
          ? [
              {
                path: StaffRoutes.StaffUserSettingsPage,
                element: <StaffUserSettings />
              }
            ]
          : [])
      ]
    }
  ];
};
