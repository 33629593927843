/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentRecord } from './PaymentRecord';
import {
    PaymentRecordFromJSON,
    PaymentRecordFromJSONTyped,
    PaymentRecordToJSON,
} from './PaymentRecord';

/**
 * 
 * @export
 * @interface FindAllPaymentRecordsResponse
 */
export interface FindAllPaymentRecordsResponse {
    /**
     * 
     * @type {Array<PaymentRecord>}
     * @memberof FindAllPaymentRecordsResponse
     */
    data: Array<PaymentRecord>;
    /**
     * 
     * @type {number}
     * @memberof FindAllPaymentRecordsResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPaymentRecordsResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllPaymentRecordsResponse
     */
    limit: number;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPaymentRecordsResponse
     */
    has_next: boolean;
}

/**
 * Check if a given object implements the FindAllPaymentRecordsResponse interface.
 */
export function instanceOfFindAllPaymentRecordsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "has_next" in value;

    return isInstance;
}

export function FindAllPaymentRecordsResponseFromJSON(json: any): FindAllPaymentRecordsResponse {
    return FindAllPaymentRecordsResponseFromJSONTyped(json, false);
}

export function FindAllPaymentRecordsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllPaymentRecordsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PaymentRecordFromJSON)),
        'total': json['total'],
        'page': json['page'],
        'limit': json['limit'],
        'has_next': json['has_next'],
    };
}

export function FindAllPaymentRecordsResponseToJSON(value?: FindAllPaymentRecordsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(PaymentRecordToJSON)),
        'total': value.total,
        'page': value.page,
        'limit': value.limit,
        'has_next': value.has_next,
    };
}

