import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { onHide, onShow } from '@intercom/messenger-js-sdk';
import { ContactSupport, Menu, MenuOpen } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CardMedia,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import CurrentUser from '@/components/CurrentUser';
import FacilitySelector from '@/components/FacilitySelector';
import { TimeClockSidebarButton } from '@/components/Timeclock/TimeclockSidebarButton';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useSidebarExpandedAtom } from '@/hooks/useSidebarExpandedAtom';
import exacareIcon from '@/images/exacare_icon.png';
import exacareLogo from '@/images/exacare_logo.png';
import InboxModal from '@/pages/AdminHub/Notifications/components/InboxModal';
import { RoutePath } from '@/routes/RoutePath';

import { showResidentGlobalSearchDialog } from '../ResidentGlobalSearchDialog';

import { CrmEhrModuleButton } from './CrmEhrModuleButton';
import { getSidebarCRMItems } from './getSidebarCRMItems';
import { getSidebarEHRItems, SidebarItem } from './getSidebarEHRItems';
import { SidebarItemBadge } from './SidebarItemBadge';

export const StyledNavButton = styled(ListItemButton)({
  minHeight: 58,
  height: 58,
  borderRadius: '100px',
  '&.Mui-disabled': {
    opacity: 1
  },
  '&.Mui-selected': {
    '&:hover': {
      background: '#D3E5F4'
    },
    background: '#D3E5F4'
  }
});

export const StyledNavButtonOutlined = styled(StyledNavButton)({
  border: '1px solid #D4DEE7',
  '& .MuiListItemAvatar-root': {
    margin: '0 4px 0 -8px'
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'unset',
    marginRight: '12px',
    '& .MuiSvgIcon-root': {
      color: '#364955'
    }
  },
  '& .MuiListItemText-primary': {
    fontSize: '14px',
    fontWeight: 500,
    color: '#364955'
  },
  '& .MuiListItemText-secondary': {
    fontSize: '12px!important',
    color: '#667A86'
  },
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export const isCurrentPath = (item: SidebarItem, pathname: string) => {
  const isDomainPath =
    pathname.startsWith('/crm') || pathname.startsWith('/ehr');
  const pathbase = (
    pathname.split('/').filter(Boolean)[isDomainPath ? 1 : 0] ?? pathname
  ).toLowerCase();
  const pathtext = (
    item.text.split(' ').filter(Boolean)[0] ?? ''
  ).toLowerCase();
  return pathbase.includes(pathtext) || pathname === item.path;
};

const Sidebar = () => {
  const currentUser = useCurrentUser().data!;
  const [showIntercom, setShowIntercom] = React.useState(false);
  const pathname = useLocation().pathname;
  const isCrmPath = pathname.startsWith('/crm');
  const ehrSidebarItems = getSidebarEHRItems();
  const crmSidebarItems = getSidebarCRMItems();
  const sidebarItems = isCrmPath ? crmSidebarItems : ehrSidebarItems;
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  const isMediaQueryDownMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [expanded, setExpanded] = useSidebarExpandedAtom();
  React.useEffect(() => {
    setExpanded(isMediaQueryDownLg || isMediaQueryDownMd ? false : true);
  }, [isMediaQueryDownLg, isMediaQueryDownMd]);

  useEffect(() => {
    if (showIntercom) {
      window?.Intercom?.('show');
    } else {
      window?.Intercom?.('hide');
    }
    onShow(() => {
      setShowIntercom(true);
    });
    onHide(() => {
      setShowIntercom(false);
    });
  }, [showIntercom]);

  return (
    <Stack
      component="nav"
      sx={{
        width: expanded ? '280px' : '90px',
        transition: 'width 200ms ease-in-out'
      }}
      direction="column">
      <List dense>
        <Stack
          pt={2}
          pr={2}
          pl={2}
          direction={isMediaQueryDownMd || !expanded ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          {isMediaQueryDownMd ? (
            <CardMedia
              component="img"
              src={exacareIcon}
              sx={{ width: 36, height: 36 }}
            />
          ) : (
            <IconButton
              sx={{
                ...(!expanded && {
                  paddingBottom: 2
                })
              }}
              size="large"
              onClick={() => setExpanded(!expanded)}>
              {expanded ? <MenuOpen sx={{ paddingLeft: '3px' }} /> : <Menu />}
            </IconButton>
          )}
          {expanded && (
            <Box sx={{ marginLeft: '0 !important' }}>
              <CardMedia
                sx={{ maxWidth: '140px' }}
                component="img"
                src={exacareLogo}
              />
            </Box>
          )}
          <IconButton
            onClick={() => {
              const newState = !showIntercom;
              setShowIntercom(newState);
              if (newState) {
                window?.Intercom?.('show');
              } else {
                window?.Intercom?.('hide');
              }
            }}
            sx={{
              backgroundColor: '#11A4D1',
              color: '#FFFFFF',
              width: '26px',
              height: '26px',
              '& svg': {
                fontSize: '16px'
              },
              '&:hover': {
                backgroundColor: '#0E83A7'
              },
              display: 'flex'
            }}>
            <ContactSupport />
          </IconButton>
          <Stack
            sx={{
              '& div': {
                margin: 0
              },
              '&.MuiListItemButton-root:hover': {
                backgroundColor: 'transparent !important'
              }
            }}>
            <InboxModal
              userId={currentUser.id}
              subscriberId={currentUser.subscriber_id}
            />
          </Stack>
        </Stack>
      </List>
      <Stack
        overflow="auto"
        height={1}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none'
        }}>
        <List dense>
          {!expanded && (
            <SidebarItemBadge expanded={false}>
              <ListItem>
                <StyledNavButton
                  onClick={() => {
                    showResidentGlobalSearchDialog();
                  }}
                  sx={{
                    height: '58px'
                  }}>
                  <ListItemIcon sx={{ minWidth: 'unset' }}>
                    {<SearchIcon />}
                  </ListItemIcon>
                </StyledNavButton>
              </ListItem>
            </SidebarItemBadge>
          )}
          {sidebarItems.map((item, index) => {
            const { icon, text, path, state, badgeProps } = item;
            return (
              <SidebarItemBadge expanded={expanded} key={index} {...badgeProps}>
                <ListItem>
                  <StyledNavButton
                    sx={{
                      height: '58px'
                    }}
                    // @ts-ignore
                    component={Link}
                    to={path}
                    {...(state && { state })}
                    selected={isCurrentPath(item, pathname)}>
                    <ListItemIcon sx={{ minWidth: 'unset' }}>
                      {icon}
                    </ListItemIcon>
                    {expanded && (
                      <>
                        <ListItemText
                          sx={{ paddingLeft: 2 }}
                          primaryTypographyProps={{
                            whiteSpace: 'nowrap',
                            fontSize: '14px',
                            fontWeight: isCurrentPath(item, pathname)
                              ? 700
                              : 500,
                            color: '#364955'
                          }}
                          primary={text}
                        />
                        {(path === RoutePath.ResidentOverview ||
                          path === RoutePath.CRMProspectsResidents) && (
                          <ListItemIcon
                            sx={{
                              minWidth: 'unset'
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              showResidentGlobalSearchDialog();
                            }}>
                            <SearchIcon sx={{ color: '#9AAEBB !important' }} />
                          </ListItemIcon>
                        )}
                      </>
                    )}
                  </StyledNavButton>
                </ListItem>
              </SidebarItemBadge>
            );
          })}
        </List>
        <List dense>
          <CrmEhrModuleButton {...{ expanded }} />
          {!isCrmPath && (
            <ListItem>
              <TimeClockSidebarButton {...{ expanded }} />
            </ListItem>
          )}
          <ListItem>
            <FacilitySelector {...{ expanded }} />
          </ListItem>
          <ListItem>
            <CurrentUser {...{ expanded }} />
          </ListItem>
        </List>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
