/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicareNursingClinicallyComplex } from './MedicareNursingClinicallyComplex';
import {
    MedicareNursingClinicallyComplexFromJSON,
    MedicareNursingClinicallyComplexFromJSONTyped,
    MedicareNursingClinicallyComplexToJSON,
} from './MedicareNursingClinicallyComplex';
import type { MedicareNursingExtensiveServices } from './MedicareNursingExtensiveServices';
import {
    MedicareNursingExtensiveServicesFromJSON,
    MedicareNursingExtensiveServicesFromJSONTyped,
    MedicareNursingExtensiveServicesToJSON,
} from './MedicareNursingExtensiveServices';
import type { MedicareNursingOtherSymptoms } from './MedicareNursingOtherSymptoms';
import {
    MedicareNursingOtherSymptomsFromJSON,
    MedicareNursingOtherSymptomsFromJSONTyped,
    MedicareNursingOtherSymptomsToJSON,
} from './MedicareNursingOtherSymptoms';
import type { MedicareNursingRequiredServices } from './MedicareNursingRequiredServices';
import {
    MedicareNursingRequiredServicesFromJSON,
    MedicareNursingRequiredServicesFromJSONTyped,
    MedicareNursingRequiredServicesToJSON,
} from './MedicareNursingRequiredServices';
import type { MedicareNursingSpecialCareHigh } from './MedicareNursingSpecialCareHigh';
import {
    MedicareNursingSpecialCareHighFromJSON,
    MedicareNursingSpecialCareHighFromJSONTyped,
    MedicareNursingSpecialCareHighToJSON,
} from './MedicareNursingSpecialCareHigh';
import type { MedicareNursingSpecialCareLow } from './MedicareNursingSpecialCareLow';
import {
    MedicareNursingSpecialCareLowFromJSON,
    MedicareNursingSpecialCareLowFromJSONTyped,
    MedicareNursingSpecialCareLowToJSON,
} from './MedicareNursingSpecialCareLow';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface NursingAssessmentForm
 */
export interface NursingAssessmentForm {
    /**
     * 
     * @type {Array<MedicareNursingExtensiveServices>}
     * @memberof NursingAssessmentForm
     */
    extensive_services?: Array<MedicareNursingExtensiveServices>;
    /**
     * 
     * @type {Array<MedicareNursingExtensiveServices>}
     * @memberof NursingAssessmentForm
     */
    predicted_extensive_services?: Array<MedicareNursingExtensiveServices>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_extensive_services_reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof NursingAssessmentForm
     */
    predicted_extensive_services_visual_references?: Array<VisualReference>;
    /**
     * 
     * @type {Array<MedicareNursingSpecialCareHigh>}
     * @memberof NursingAssessmentForm
     */
    special_care_high?: Array<MedicareNursingSpecialCareHigh>;
    /**
     * 
     * @type {Array<MedicareNursingSpecialCareHigh>}
     * @memberof NursingAssessmentForm
     */
    predicted_special_care_high?: Array<MedicareNursingSpecialCareHigh>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_special_care_high_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_special_care_high_visual_references?: any | null;
    /**
     * 
     * @type {Array<MedicareNursingSpecialCareLow>}
     * @memberof NursingAssessmentForm
     */
    special_care_low?: Array<MedicareNursingSpecialCareLow>;
    /**
     * 
     * @type {Array<MedicareNursingSpecialCareLow>}
     * @memberof NursingAssessmentForm
     */
    predicted_special_care_low?: Array<MedicareNursingSpecialCareLow>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_special_care_low_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_special_care_low_visual_references?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof NursingAssessmentForm
     */
    is_depressed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_is_depressed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_is_depressed_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_is_depressed_visual_references?: any | null;
    /**
     * 
     * @type {Array<MedicareNursingClinicallyComplex>}
     * @memberof NursingAssessmentForm
     */
    clinically_complex?: Array<MedicareNursingClinicallyComplex>;
    /**
     * 
     * @type {Array<MedicareNursingClinicallyComplex>}
     * @memberof NursingAssessmentForm
     */
    predicted_clinically_complex?: Array<MedicareNursingClinicallyComplex>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_clinically_complex_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_clinically_complex_visual_references?: any | null;
    /**
     * 
     * @type {Array<MedicareNursingOtherSymptoms>}
     * @memberof NursingAssessmentForm
     */
    other_symptoms?: Array<MedicareNursingOtherSymptoms>;
    /**
     * 
     * @type {Array<MedicareNursingOtherSymptoms>}
     * @memberof NursingAssessmentForm
     */
    predicted_other_symptoms?: Array<MedicareNursingOtherSymptoms>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_other_symptoms_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_other_symptoms_visual_references?: any | null;
    /**
     * 
     * @type {Array<MedicareNursingRequiredServices>}
     * @memberof NursingAssessmentForm
     */
    services_required?: Array<MedicareNursingRequiredServices>;
    /**
     * 
     * @type {Array<MedicareNursingRequiredServices>}
     * @memberof NursingAssessmentForm
     */
    predicted_services_required?: Array<MedicareNursingRequiredServices>;
    /**
     * 
     * @type {string}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_services_required_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof NursingAssessmentForm
     */
    readonly predicted_services_required_visual_references?: any | null;
}

/**
 * Check if a given object implements the NursingAssessmentForm interface.
 */
export function instanceOfNursingAssessmentForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NursingAssessmentFormFromJSON(json: any): NursingAssessmentForm {
    return NursingAssessmentFormFromJSONTyped(json, false);
}

export function NursingAssessmentFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): NursingAssessmentForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extensive_services': !exists(json, 'extensive_services') ? undefined : ((json['extensive_services'] as Array<any>).map(MedicareNursingExtensiveServicesFromJSON)),
        'predicted_extensive_services': !exists(json, 'predicted_extensive_services') ? undefined : ((json['predicted_extensive_services'] as Array<any>).map(MedicareNursingExtensiveServicesFromJSON)),
        'predicted_extensive_services_reasoning': !exists(json, 'predicted_extensive_services_reasoning') ? undefined : json['predicted_extensive_services_reasoning'],
        'predicted_extensive_services_visual_references': !exists(json, 'predicted_extensive_services_visual_references') ? undefined : ((json['predicted_extensive_services_visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
        'special_care_high': !exists(json, 'special_care_high') ? undefined : ((json['special_care_high'] as Array<any>).map(MedicareNursingSpecialCareHighFromJSON)),
        'predicted_special_care_high': !exists(json, 'predicted_special_care_high') ? undefined : ((json['predicted_special_care_high'] as Array<any>).map(MedicareNursingSpecialCareHighFromJSON)),
        'predicted_special_care_high_reasoning': !exists(json, 'predicted_special_care_high_reasoning') ? undefined : json['predicted_special_care_high_reasoning'],
        'predicted_special_care_high_visual_references': !exists(json, 'predicted_special_care_high_visual_references') ? undefined : json['predicted_special_care_high_visual_references'],
        'special_care_low': !exists(json, 'special_care_low') ? undefined : ((json['special_care_low'] as Array<any>).map(MedicareNursingSpecialCareLowFromJSON)),
        'predicted_special_care_low': !exists(json, 'predicted_special_care_low') ? undefined : ((json['predicted_special_care_low'] as Array<any>).map(MedicareNursingSpecialCareLowFromJSON)),
        'predicted_special_care_low_reasoning': !exists(json, 'predicted_special_care_low_reasoning') ? undefined : json['predicted_special_care_low_reasoning'],
        'predicted_special_care_low_visual_references': !exists(json, 'predicted_special_care_low_visual_references') ? undefined : json['predicted_special_care_low_visual_references'],
        'is_depressed': !exists(json, 'is_depressed') ? undefined : json['is_depressed'],
        'predicted_is_depressed': !exists(json, 'predicted_is_depressed') ? undefined : json['predicted_is_depressed'],
        'predicted_is_depressed_reasoning': !exists(json, 'predicted_is_depressed_reasoning') ? undefined : json['predicted_is_depressed_reasoning'],
        'predicted_is_depressed_visual_references': !exists(json, 'predicted_is_depressed_visual_references') ? undefined : json['predicted_is_depressed_visual_references'],
        'clinically_complex': !exists(json, 'clinically_complex') ? undefined : ((json['clinically_complex'] as Array<any>).map(MedicareNursingClinicallyComplexFromJSON)),
        'predicted_clinically_complex': !exists(json, 'predicted_clinically_complex') ? undefined : ((json['predicted_clinically_complex'] as Array<any>).map(MedicareNursingClinicallyComplexFromJSON)),
        'predicted_clinically_complex_reasoning': !exists(json, 'predicted_clinically_complex_reasoning') ? undefined : json['predicted_clinically_complex_reasoning'],
        'predicted_clinically_complex_visual_references': !exists(json, 'predicted_clinically_complex_visual_references') ? undefined : json['predicted_clinically_complex_visual_references'],
        'other_symptoms': !exists(json, 'other_symptoms') ? undefined : ((json['other_symptoms'] as Array<any>).map(MedicareNursingOtherSymptomsFromJSON)),
        'predicted_other_symptoms': !exists(json, 'predicted_other_symptoms') ? undefined : ((json['predicted_other_symptoms'] as Array<any>).map(MedicareNursingOtherSymptomsFromJSON)),
        'predicted_other_symptoms_reasoning': !exists(json, 'predicted_other_symptoms_reasoning') ? undefined : json['predicted_other_symptoms_reasoning'],
        'predicted_other_symptoms_visual_references': !exists(json, 'predicted_other_symptoms_visual_references') ? undefined : json['predicted_other_symptoms_visual_references'],
        'services_required': !exists(json, 'services_required') ? undefined : ((json['services_required'] as Array<any>).map(MedicareNursingRequiredServicesFromJSON)),
        'predicted_services_required': !exists(json, 'predicted_services_required') ? undefined : ((json['predicted_services_required'] as Array<any>).map(MedicareNursingRequiredServicesFromJSON)),
        'predicted_services_required_reasoning': !exists(json, 'predicted_services_required_reasoning') ? undefined : json['predicted_services_required_reasoning'],
        'predicted_services_required_visual_references': !exists(json, 'predicted_services_required_visual_references') ? undefined : json['predicted_services_required_visual_references'],
    };
}

export function NursingAssessmentFormToJSON(value?: NursingAssessmentForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extensive_services': value.extensive_services === undefined ? undefined : ((value.extensive_services as Array<any>).map(MedicareNursingExtensiveServicesToJSON)),
        'predicted_extensive_services': value.predicted_extensive_services === undefined ? undefined : ((value.predicted_extensive_services as Array<any>).map(MedicareNursingExtensiveServicesToJSON)),
        'predicted_extensive_services_visual_references': value.predicted_extensive_services_visual_references === undefined ? undefined : ((value.predicted_extensive_services_visual_references as Array<any>).map(VisualReferenceToJSON)),
        'special_care_high': value.special_care_high === undefined ? undefined : ((value.special_care_high as Array<any>).map(MedicareNursingSpecialCareHighToJSON)),
        'predicted_special_care_high': value.predicted_special_care_high === undefined ? undefined : ((value.predicted_special_care_high as Array<any>).map(MedicareNursingSpecialCareHighToJSON)),
        'special_care_low': value.special_care_low === undefined ? undefined : ((value.special_care_low as Array<any>).map(MedicareNursingSpecialCareLowToJSON)),
        'predicted_special_care_low': value.predicted_special_care_low === undefined ? undefined : ((value.predicted_special_care_low as Array<any>).map(MedicareNursingSpecialCareLowToJSON)),
        'is_depressed': value.is_depressed,
        'clinically_complex': value.clinically_complex === undefined ? undefined : ((value.clinically_complex as Array<any>).map(MedicareNursingClinicallyComplexToJSON)),
        'predicted_clinically_complex': value.predicted_clinically_complex === undefined ? undefined : ((value.predicted_clinically_complex as Array<any>).map(MedicareNursingClinicallyComplexToJSON)),
        'other_symptoms': value.other_symptoms === undefined ? undefined : ((value.other_symptoms as Array<any>).map(MedicareNursingOtherSymptomsToJSON)),
        'predicted_other_symptoms': value.predicted_other_symptoms === undefined ? undefined : ((value.predicted_other_symptoms as Array<any>).map(MedicareNursingOtherSymptomsToJSON)),
        'services_required': value.services_required === undefined ? undefined : ((value.services_required as Array<any>).map(MedicareNursingRequiredServicesToJSON)),
        'predicted_services_required': value.predicted_services_required === undefined ? undefined : ((value.predicted_services_required as Array<any>).map(MedicareNursingRequiredServicesToJSON)),
    };
}

