/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
} from './Credit';

/**
 * 
 * @export
 * @interface CreateCredit201Response
 */
export interface CreateCredit201Response {
    /**
     * 
     * @type {Credit}
     * @memberof CreateCredit201Response
     */
    data?: Credit;
}

/**
 * Check if a given object implements the CreateCredit201Response interface.
 */
export function instanceOfCreateCredit201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCredit201ResponseFromJSON(json: any): CreateCredit201Response {
    return CreateCredit201ResponseFromJSONTyped(json, false);
}

export function CreateCredit201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCredit201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CreditFromJSON(json['data']),
    };
}

export function CreateCredit201ResponseToJSON(value?: CreateCredit201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreditToJSON(value.data),
    };
}

