/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanEsignRecipient } from './CarePlanEsignRecipient';
import {
    CarePlanEsignRecipientFromJSON,
    CarePlanEsignRecipientFromJSONTyped,
    CarePlanEsignRecipientToJSON,
} from './CarePlanEsignRecipient';

/**
 * 
 * @export
 * @interface CarePlanEsign
 */
export interface CarePlanEsign {
    /**
     * 
     * @type {Array<CarePlanEsignRecipient>}
     * @memberof CarePlanEsign
     */
    recipients: Array<CarePlanEsignRecipient>;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    document_status: CarePlanEsignDocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    document_s3_key?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    sign_status: CarePlanEsignSignStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    signed_document_s3_key?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    email_subject?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEsign
     */
    email_body?: string;
}


/**
 * @export
 */
export const CarePlanEsignDocumentStatusEnum = {
    Empty: 'empty',
    Generating: 'generating',
    Ready: 'ready',
    Error: 'error'
} as const;
export type CarePlanEsignDocumentStatusEnum = typeof CarePlanEsignDocumentStatusEnum[keyof typeof CarePlanEsignDocumentStatusEnum];

/**
 * @export
 */
export const CarePlanEsignSignStatusEnum = {
    Pending: 'pending',
    Sending: 'sending',
    Sent: 'sent',
    Signed: 'signed',
    Error: 'error'
} as const;
export type CarePlanEsignSignStatusEnum = typeof CarePlanEsignSignStatusEnum[keyof typeof CarePlanEsignSignStatusEnum];


/**
 * Check if a given object implements the CarePlanEsign interface.
 */
export function instanceOfCarePlanEsign(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipients" in value;
    isInstance = isInstance && "document_status" in value;
    isInstance = isInstance && "sign_status" in value;

    return isInstance;
}

export function CarePlanEsignFromJSON(json: any): CarePlanEsign {
    return CarePlanEsignFromJSONTyped(json, false);
}

export function CarePlanEsignFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanEsign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipients': ((json['recipients'] as Array<any>).map(CarePlanEsignRecipientFromJSON)),
        'document_status': json['document_status'],
        'document_s3_key': !exists(json, 'document_s3_key') ? undefined : json['document_s3_key'],
        'sign_status': json['sign_status'],
        'signed_document_s3_key': !exists(json, 'signed_document_s3_key') ? undefined : json['signed_document_s3_key'],
        'email_subject': !exists(json, 'email_subject') ? undefined : json['email_subject'],
        'email_body': !exists(json, 'email_body') ? undefined : json['email_body'],
    };
}

export function CarePlanEsignToJSON(value?: CarePlanEsign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipients': ((value.recipients as Array<any>).map(CarePlanEsignRecipientToJSON)),
        'document_status': value.document_status,
        'document_s3_key': value.document_s3_key,
        'sign_status': value.sign_status,
        'signed_document_s3_key': value.signed_document_s3_key,
        'email_subject': value.email_subject,
        'email_body': value.email_body,
    };
}

