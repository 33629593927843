/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompletionTimeRequest
 */
export interface UpdateCompletionTimeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompletionTimeRequest
     */
    completion_time: string;
}

/**
 * Check if a given object implements the UpdateCompletionTimeRequest interface.
 */
export function instanceOfUpdateCompletionTimeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "completion_time" in value;

    return isInstance;
}

export function UpdateCompletionTimeRequestFromJSON(json: any): UpdateCompletionTimeRequest {
    return UpdateCompletionTimeRequestFromJSONTyped(json, false);
}

export function UpdateCompletionTimeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompletionTimeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completion_time': json['completion_time'],
    };
}

export function UpdateCompletionTimeRequestToJSON(value?: UpdateCompletionTimeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completion_time': value.completion_time,
    };
}

