/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentListEntry } from './IncidentListEntry';
import {
    IncidentListEntryFromJSON,
    IncidentListEntryFromJSONTyped,
    IncidentListEntryToJSON,
} from './IncidentListEntry';

/**
 * 
 * @export
 * @interface IncidentsFindAllResponse
 */
export interface IncidentsFindAllResponse {
    /**
     * 
     * @type {Array<IncidentListEntry>}
     * @memberof IncidentsFindAllResponse
     */
    data: Array<IncidentListEntry>;
    /**
     * 
     * @type {number}
     * @memberof IncidentsFindAllResponse
     */
    total: number;
}

/**
 * Check if a given object implements the IncidentsFindAllResponse interface.
 */
export function instanceOfIncidentsFindAllResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function IncidentsFindAllResponseFromJSON(json: any): IncidentsFindAllResponse {
    return IncidentsFindAllResponseFromJSONTyped(json, false);
}

export function IncidentsFindAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsFindAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(IncidentListEntryFromJSON)),
        'total': json['total'],
    };
}

export function IncidentsFindAllResponseToJSON(value?: IncidentsFindAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(IncidentListEntryToJSON)),
        'total': value.total,
    };
}

