/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmReferralSourceListEntry } from './CrmReferralSourceListEntry';
import {
    CrmReferralSourceListEntryFromJSON,
    CrmReferralSourceListEntryFromJSONTyped,
    CrmReferralSourceListEntryToJSON,
} from './CrmReferralSourceListEntry';

/**
 * 
 * @export
 * @interface CrmReferralSourcesPage
 */
export interface CrmReferralSourcesPage {
    /**
     * 
     * @type {Array<CrmReferralSourceListEntry>}
     * @memberof CrmReferralSourcesPage
     */
    referral_sources: Array<CrmReferralSourceListEntry>;
    /**
     * 
     * @type {number}
     * @memberof CrmReferralSourcesPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the CrmReferralSourcesPage interface.
 */
export function instanceOfCrmReferralSourcesPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "referral_sources" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function CrmReferralSourcesPageFromJSON(json: any): CrmReferralSourcesPage {
    return CrmReferralSourcesPageFromJSONTyped(json, false);
}

export function CrmReferralSourcesPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmReferralSourcesPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referral_sources': ((json['referral_sources'] as Array<any>).map(CrmReferralSourceListEntryFromJSON)),
        'total_count': json['total_count'],
    };
}

export function CrmReferralSourcesPageToJSON(value?: CrmReferralSourcesPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_sources': ((value.referral_sources as Array<any>).map(CrmReferralSourceListEntryToJSON)),
        'total_count': value.total_count,
    };
}

