/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CombineLeadsRequest
 */
export interface CombineLeadsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CombineLeadsRequest
     */
    lead_ids: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CombineLeadsRequest
     */
    exclude_document_ids: Array<string>;
    /**
     * Facility ID
     * @type {string}
     * @memberof CombineLeadsRequest
     */
    facility_id: string;
}

/**
 * Check if a given object implements the CombineLeadsRequest interface.
 */
export function instanceOfCombineLeadsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lead_ids" in value;
    isInstance = isInstance && "exclude_document_ids" in value;
    isInstance = isInstance && "facility_id" in value;

    return isInstance;
}

export function CombineLeadsRequestFromJSON(json: any): CombineLeadsRequest {
    return CombineLeadsRequestFromJSONTyped(json, false);
}

export function CombineLeadsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombineLeadsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lead_ids': json['lead_ids'],
        'exclude_document_ids': json['exclude_document_ids'],
        'facility_id': json['facility_id'],
    };
}

export function CombineLeadsRequestToJSON(value?: CombineLeadsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lead_ids': value.lead_ids,
        'exclude_document_ids': value.exclude_document_ids,
        'facility_id': value.facility_id,
    };
}

