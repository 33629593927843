/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareTotals } from './CareTotals';
import {
    CareTotalsFromJSON,
    CareTotalsFromJSONTyped,
    CareTotalsToJSON,
} from './CareTotals';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface FindAllRecurringPurchasesCareResponse
 */
export interface FindAllRecurringPurchasesCareResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    facility_id: string;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    purchases: Array<Subscription>;
    /**
     * 
     * @type {CareTotals}
     * @memberof FindAllRecurringPurchasesCareResponse
     */
    totals: CareTotals;
}

/**
 * Check if a given object implements the FindAllRecurringPurchasesCareResponse interface.
 */
export function instanceOfFindAllRecurringPurchasesCareResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "purchases" in value;
    isInstance = isInstance && "totals" in value;

    return isInstance;
}

export function FindAllRecurringPurchasesCareResponseFromJSON(json: any): FindAllRecurringPurchasesCareResponse {
    return FindAllRecurringPurchasesCareResponseFromJSONTyped(json, false);
}

export function FindAllRecurringPurchasesCareResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllRecurringPurchasesCareResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'resident_name': json['resident_name'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'facility_id': json['facility_id'],
        'purchases': ((json['purchases'] as Array<any>).map(SubscriptionFromJSON)),
        'totals': CareTotalsFromJSON(json['totals']),
    };
}

export function FindAllRecurringPurchasesCareResponseToJSON(value?: FindAllRecurringPurchasesCareResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'resident_photo_url': value.resident_photo_url,
        'facility_id': value.facility_id,
        'purchases': ((value.purchases as Array<any>).map(SubscriptionToJSON)),
        'totals': CareTotalsToJSON(value.totals),
    };
}

