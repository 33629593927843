/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimeChargeWithoutPrice } from './OneTimeChargeWithoutPrice';
import {
    OneTimeChargeWithoutPriceFromJSON,
    OneTimeChargeWithoutPriceFromJSONTyped,
    OneTimeChargeWithoutPriceToJSON,
} from './OneTimeChargeWithoutPrice';

/**
 * 
 * @export
 * @interface FindAllOneTimeChargesWithoutPrice200Response
 */
export interface FindAllOneTimeChargesWithoutPrice200Response {
    /**
     * 
     * @type {Array<OneTimeChargeWithoutPrice>}
     * @memberof FindAllOneTimeChargesWithoutPrice200Response
     */
    data?: Array<OneTimeChargeWithoutPrice>;
}

/**
 * Check if a given object implements the FindAllOneTimeChargesWithoutPrice200Response interface.
 */
export function instanceOfFindAllOneTimeChargesWithoutPrice200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllOneTimeChargesWithoutPrice200ResponseFromJSON(json: any): FindAllOneTimeChargesWithoutPrice200Response {
    return FindAllOneTimeChargesWithoutPrice200ResponseFromJSONTyped(json, false);
}

export function FindAllOneTimeChargesWithoutPrice200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllOneTimeChargesWithoutPrice200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(OneTimeChargeWithoutPriceFromJSON)),
    };
}

export function FindAllOneTimeChargesWithoutPrice200ResponseToJSON(value?: FindAllOneTimeChargesWithoutPrice200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(OneTimeChargeWithoutPriceToJSON)),
    };
}

