/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicareConditionsServices } from './MedicareConditionsServices';
import {
    MedicareConditionsServicesFromJSON,
    MedicareConditionsServicesFromJSONTyped,
    MedicareConditionsServicesToJSON,
} from './MedicareConditionsServices';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface AssessmentFormConditionsServices
 */
export interface AssessmentFormConditionsServices {
    /**
     * 
     * @type {Array<MedicareConditionsServices>}
     * @memberof AssessmentFormConditionsServices
     */
    conditions_services: Array<MedicareConditionsServices>;
    /**
     * 
     * @type {Array<MedicareConditionsServices>}
     * @memberof AssessmentFormConditionsServices
     */
    predicted_conditions_services?: Array<MedicareConditionsServices>;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFormConditionsServices
     */
    readonly predicted_conditions_services_reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof AssessmentFormConditionsServices
     */
    predicted_conditions_services_visual_references?: Array<VisualReference>;
}

/**
 * Check if a given object implements the AssessmentFormConditionsServices interface.
 */
export function instanceOfAssessmentFormConditionsServices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conditions_services" in value;

    return isInstance;
}

export function AssessmentFormConditionsServicesFromJSON(json: any): AssessmentFormConditionsServices {
    return AssessmentFormConditionsServicesFromJSONTyped(json, false);
}

export function AssessmentFormConditionsServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentFormConditionsServices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions_services': ((json['conditions_services'] as Array<any>).map(MedicareConditionsServicesFromJSON)),
        'predicted_conditions_services': !exists(json, 'predicted_conditions_services') ? undefined : ((json['predicted_conditions_services'] as Array<any>).map(MedicareConditionsServicesFromJSON)),
        'predicted_conditions_services_reasoning': !exists(json, 'predicted_conditions_services_reasoning') ? undefined : json['predicted_conditions_services_reasoning'],
        'predicted_conditions_services_visual_references': !exists(json, 'predicted_conditions_services_visual_references') ? undefined : ((json['predicted_conditions_services_visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
    };
}

export function AssessmentFormConditionsServicesToJSON(value?: AssessmentFormConditionsServices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions_services': ((value.conditions_services as Array<any>).map(MedicareConditionsServicesToJSON)),
        'predicted_conditions_services': value.predicted_conditions_services === undefined ? undefined : ((value.predicted_conditions_services as Array<any>).map(MedicareConditionsServicesToJSON)),
        'predicted_conditions_services_visual_references': value.predicted_conditions_services_visual_references === undefined ? undefined : ((value.predicted_conditions_services_visual_references as Array<any>).map(VisualReferenceToJSON)),
    };
}

