/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PccAdvancedPatientDetails } from './PccAdvancedPatientDetails';
import {
    PccAdvancedPatientDetailsFromJSON,
    PccAdvancedPatientDetailsFromJSONTyped,
    PccAdvancedPatientDetailsToJSON,
} from './PccAdvancedPatientDetails';
import type { PccPatientDataUploadError } from './PccPatientDataUploadError';
import {
    PccPatientDataUploadErrorFromJSON,
    PccPatientDataUploadErrorFromJSONTyped,
    PccPatientDataUploadErrorToJSON,
} from './PccPatientDataUploadError';

/**
 * 
 * @export
 * @interface CreatePccAdvancedPatientDetailsResponse
 */
export interface CreatePccAdvancedPatientDetailsResponse {
    /**
     * 
     * @type {PccAdvancedPatientDetails}
     * @memberof CreatePccAdvancedPatientDetailsResponse
     */
    advanced_details?: PccAdvancedPatientDetails;
    /**
     * 
     * @type {Array<PccPatientDataUploadError>}
     * @memberof CreatePccAdvancedPatientDetailsResponse
     */
    errors?: Array<PccPatientDataUploadError>;
}

/**
 * Check if a given object implements the CreatePccAdvancedPatientDetailsResponse interface.
 */
export function instanceOfCreatePccAdvancedPatientDetailsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreatePccAdvancedPatientDetailsResponseFromJSON(json: any): CreatePccAdvancedPatientDetailsResponse {
    return CreatePccAdvancedPatientDetailsResponseFromJSONTyped(json, false);
}

export function CreatePccAdvancedPatientDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePccAdvancedPatientDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advanced_details': !exists(json, 'advanced_details') ? undefined : PccAdvancedPatientDetailsFromJSON(json['advanced_details']),
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(PccPatientDataUploadErrorFromJSON)),
    };
}

export function CreatePccAdvancedPatientDetailsResponseToJSON(value?: CreatePccAdvancedPatientDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advanced_details': PccAdvancedPatientDetailsToJSON(value.advanced_details),
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(PccPatientDataUploadErrorToJSON)),
    };
}

