/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanEsign } from './CarePlanEsign';
import {
    CarePlanEsignFromJSON,
    CarePlanEsignFromJSONTyped,
    CarePlanEsignToJSON,
} from './CarePlanEsign';

/**
 * 
 * @export
 * @interface GetCarePlanEsign200Response
 */
export interface GetCarePlanEsign200Response {
    /**
     * 
     * @type {CarePlanEsign}
     * @memberof GetCarePlanEsign200Response
     */
    data?: CarePlanEsign;
}

/**
 * Check if a given object implements the GetCarePlanEsign200Response interface.
 */
export function instanceOfGetCarePlanEsign200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCarePlanEsign200ResponseFromJSON(json: any): GetCarePlanEsign200Response {
    return GetCarePlanEsign200ResponseFromJSONTyped(json, false);
}

export function GetCarePlanEsign200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCarePlanEsign200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CarePlanEsignFromJSON(json['data']),
    };
}

export function GetCarePlanEsign200ResponseToJSON(value?: GetCarePlanEsign200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CarePlanEsignToJSON(value.data),
    };
}

