import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { Cancel } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack
} from '@mui/material';
import dayjs from 'dayjs';

import { MedicationTaskInstancePayload } from '@/hooks/useMedicationTaskInstancesQuery';

interface ConfirmSchedulesUpdateDialogProps extends InstanceProps<unknown> {
  taskInstance: MedicationTaskInstancePayload;
  medicationName?: string;
  onConfirm: () => void;
  onApplyImmediately: () => void;
}

const ConfirmSchedulesUpdateDialog: React.FC<
  ConfirmSchedulesUpdateDialogProps
> = ({
  isOpen,
  onResolve,
  onReject,
  taskInstance,
  medicationName,
  onConfirm,
  onApplyImmediately
}) => {
  const taskInstanceDate = dayjs(taskInstance.date).startOf('day');
  const effectiveDate = taskInstanceDate.add(1, 'day');
  const effectiveDateString = dayjs()
    .add(1, 'day')
    .startOf('day')
    .isSame(effectiveDate)
    ? 'tomorrow'
    : effectiveDate.format('MMMM D, YYYY');
  const daysDiff = taskInstanceDate.diff(dayjs().startOf('day'), 'day');
  const taskInstanceStatus = taskInstance.date_completed
    ? 'completed'
    : 'canceled';

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle sx={{ paddingBottom: 0, textTransform: 'capitalize' }}>
        Update Takes Effect Starting {effectiveDateString}
        <IconButton onClick={onReject}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '16px !important', paddingBottom: 0 }}>
        <DialogContentText>
          As a safeguard against overdosing, ExaCare checked the history of all
          completed and canceled instances of this medication{' '}
          {medicationName && <span className="bold">({medicationName})</span>}.
          ExaCare found that a med pass scheduled{' '}
          {daysDiff > 0 ? (
            <>
              <span className="bold">
                {daysDiff} {daysDiff > 1 ? 'days' : 'day'}{' '}
              </span>
              from now
            </>
          ) : (
            <>
              for{' '}
              <span className="bold">
                {taskInstance.execution_window_start_time}
              </span>{' '}
              today
            </>
          )}{' '}
          <span className="bold">
            ({taskInstanceDate.format('MMMM D, YYYY')})
          </span>{' '}
          was marked {taskInstanceStatus}. Accordingly, this update will take
          effect starting {effectiveDateString}. Would you like to proceed?
        </DialogContentText>
        <Alert severity="info">
          <AlertTitle>Need Immediate Update?</AlertTitle>
          <Stack spacing={1}>
            <Box>
              To apply this update now, click the button below. Then, review
              scheduled med passes from today onwards and check them against
              completed or canceled ones to avoid overdosing.
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  onApplyImmediately();
                  onResolve();
                }}>
                Update Immediately
              </Button>
            </Box>
          </Stack>
        </Alert>
        <DialogActions sx={{ paddingTop: '8px' }}>
          <Stack direction="row" spacing={0.8}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                onConfirm();
                onResolve();
              }}>
              Proceed
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export const showSchedulesUpdateDialog =
  create<ConfirmSchedulesUpdateDialogProps>(ConfirmSchedulesUpdateDialog);
