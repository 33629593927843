/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApproveAssessmentCompletionRequest
 */
export interface ApproveAssessmentCompletionRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ApproveAssessmentCompletionRequest
     */
    notice_required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApproveAssessmentCompletionRequest
     */
    update_care_plan_now: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApproveAssessmentCompletionRequest
     */
    care_plan_update_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveAssessmentCompletionRequest
     */
    care_plan_update_delay_reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApproveAssessmentCompletionRequest
     */
    update_care_subscription_now?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApproveAssessmentCompletionRequest
     */
    care_subscription_update_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveAssessmentCompletionRequest
     */
    care_subscription_update_delay_reason?: string;
}

/**
 * Check if a given object implements the ApproveAssessmentCompletionRequest interface.
 */
export function instanceOfApproveAssessmentCompletionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "update_care_plan_now" in value;

    return isInstance;
}

export function ApproveAssessmentCompletionRequestFromJSON(json: any): ApproveAssessmentCompletionRequest {
    return ApproveAssessmentCompletionRequestFromJSONTyped(json, false);
}

export function ApproveAssessmentCompletionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveAssessmentCompletionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notice_required': !exists(json, 'notice_required') ? undefined : json['notice_required'],
        'update_care_plan_now': json['update_care_plan_now'],
        'care_plan_update_date': !exists(json, 'care_plan_update_date') ? undefined : json['care_plan_update_date'],
        'care_plan_update_delay_reason': !exists(json, 'care_plan_update_delay_reason') ? undefined : json['care_plan_update_delay_reason'],
        'update_care_subscription_now': !exists(json, 'update_care_subscription_now') ? undefined : json['update_care_subscription_now'],
        'care_subscription_update_date': !exists(json, 'care_subscription_update_date') ? undefined : json['care_subscription_update_date'],
        'care_subscription_update_delay_reason': !exists(json, 'care_subscription_update_delay_reason') ? undefined : json['care_subscription_update_delay_reason'],
    };
}

export function ApproveAssessmentCompletionRequestToJSON(value?: ApproveAssessmentCompletionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notice_required': value.notice_required,
        'update_care_plan_now': value.update_care_plan_now,
        'care_plan_update_date': value.care_plan_update_date,
        'care_plan_update_delay_reason': value.care_plan_update_delay_reason,
        'update_care_subscription_now': value.update_care_subscription_now,
        'care_subscription_update_date': value.care_subscription_update_date,
        'care_subscription_update_delay_reason': value.care_subscription_update_delay_reason,
    };
}

