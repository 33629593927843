import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import {
  TaskExtensionPayload,
  TaskExtensionQuestionType,
  TaskExtensionType
} from '@/models/TaskExtensionModel';

import TaskExtensionCommonFieldsForm from './TaskExtensionCommonFieldsForm';
import TaskExtensionMultiChoiceForm from './TaskExtensionMultiChoiceForm';
import TaskExtensionPreviewForm from './TaskExtensionPreviewForm';
import TaskExtensionTagForm from './TaskExtensionTagForm';

// Typing as a generic object that has a task extension
export interface InstanceWithTaskExtensionFormType {
  task_extension: TaskExtensionPayload;
  [key: string]: any;
}

interface TaskExtensionFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  taskExtensionType: TaskExtensionType;
  disabled?: boolean;
  // If task extension is used in an org wide level, we use this field to disable
  // tag selection manually or when it gets set by selecting a template
  isOrgWide?: boolean;
}

export default function TaskExtensionForm(props: TaskExtensionFormProps) {
  const {
    formMethods,
    taskExtensionType,
    disabled = false,
    isOrgWide = false
  } = props;

  const questionType = formMethods.watch('task_extension.question_type');

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('N/A');

  const cleanTemplateSelect = () => {
    setSelectedTemplateId('N/A');
  };

  const resetFirstDefaultAnswer = () => {
    formMethods.setValue('task_extension.default_answers.0.value', '');
  };

  return (
    <Stack gap={2}>
      <TaskExtensionCommonFieldsForm
        disabled={disabled}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        cleanTemplateSelect={cleanTemplateSelect}
        formMethods={formMethods}
        taskExtensionType={taskExtensionType}
        resetFirstDefaultAnswer={resetFirstDefaultAnswer}
        isOrgWide={isOrgWide}
      />
      {(questionType === TaskExtensionQuestionType.FreeText ||
        questionType === TaskExtensionQuestionType.Number ||
        questionType === TaskExtensionQuestionType.Percentage) && (
        <ControlledTextField
          disabled={disabled}
          control={formMethods.control}
          name="task_extension.default_answers.0.value"
          label="Default Answer"
          InputProps={{
            endAdornment: (
              <Typography color="#9AAEBB" fontSize="22px">
                {questionType === TaskExtensionQuestionType.Percentage
                  ? '%'
                  : ''}
              </Typography>
            )
          }}
          rules={{
            validate: {
              isNotANumber: (value) => {
                if (
                  (formMethods.getValues('task_extension.question_type') ===
                    TaskExtensionQuestionType.Number ||
                    formMethods.getValues('task_extension.question_type') ===
                      TaskExtensionQuestionType.Percentage) &&
                  value &&
                  value.trim() !== '' &&
                  isNaN(parseFloat(value as string))
                ) {
                  return 'Enter a valid number';
                } else {
                  return true;
                }
              }
            }
          }}
          onChange={() => cleanTemplateSelect()}
        />
      )}
      {(questionType === TaskExtensionQuestionType.MultipleSelect ||
        questionType === TaskExtensionQuestionType.SingleSelect) && (
        <TaskExtensionMultiChoiceForm
          disabled={disabled}
          formMethods={formMethods}
          cleanTemplateSelect={cleanTemplateSelect}
          questionType={questionType}
          resetFirstDefaultAnswer={resetFirstDefaultAnswer}
        />
      )}
      <TaskExtensionTagForm
        disabled={disabled}
        isOrgWide={isOrgWide}
        cleanTemplateSelect={cleanTemplateSelect}
        formMethods={formMethods}
        taskExtensionType={taskExtensionType}
      />
      <TaskExtensionPreviewForm
        disabled={disabled}
        formMethods={formMethods}
        questionType={questionType}
      />
    </Stack>
  );
}
