/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateIncidentComment200ResponseData } from './CreateIncidentComment200ResponseData';
import {
    CreateIncidentComment200ResponseDataFromJSON,
    CreateIncidentComment200ResponseDataFromJSONTyped,
    CreateIncidentComment200ResponseDataToJSON,
} from './CreateIncidentComment200ResponseData';

/**
 * 
 * @export
 * @interface CreateIncidentComment200Response
 */
export interface CreateIncidentComment200Response {
    /**
     * 
     * @type {CreateIncidentComment200ResponseData}
     * @memberof CreateIncidentComment200Response
     */
    data?: CreateIncidentComment200ResponseData;
}

/**
 * Check if a given object implements the CreateIncidentComment200Response interface.
 */
export function instanceOfCreateIncidentComment200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIncidentComment200ResponseFromJSON(json: any): CreateIncidentComment200Response {
    return CreateIncidentComment200ResponseFromJSONTyped(json, false);
}

export function CreateIncidentComment200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncidentComment200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CreateIncidentComment200ResponseDataFromJSON(json['data']),
    };
}

export function CreateIncidentComment200ResponseToJSON(value?: CreateIncidentComment200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateIncidentComment200ResponseDataToJSON(value.data),
    };
}

