import { atomWithStorage } from 'jotai/utils';

import {
  ExacareFeature,
  LOCAL_STORAGE_FEATURE_FLAGS_KEY
} from '@/utils/featureFlagService';

export const localStorageFeatureFlagsAtom = atomWithStorage<
  Partial<Record<ExacareFeature, boolean>>
>(LOCAL_STORAGE_FEATURE_FLAGS_KEY, {});
