/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SlpRelatedComorbidities = {
    Aphasia: 'aphasia',
    CvaTiaStroke: 'cva_tia_stroke',
    HemiplegiaHemiparesis: 'hemiplegia_hemiparesis',
    BrainInjury: 'brain_injury',
    LaryngealCancer: 'laryngeal_cancer',
    Apraxia: 'apraxia',
    Dysphagia: 'dysphagia',
    Als: 'als',
    OralCancer: 'oral_cancer',
    SpeechLanguageDeficit: 'speech_language_deficit',
    TracheostomyCare: 'tracheostomy_care',
    VentilatorRespirator: 'ventilator_respirator'
} as const;
export type SlpRelatedComorbidities = typeof SlpRelatedComorbidities[keyof typeof SlpRelatedComorbidities];


export function SlpRelatedComorbiditiesFromJSON(json: any): SlpRelatedComorbidities {
    return SlpRelatedComorbiditiesFromJSONTyped(json, false);
}

export function SlpRelatedComorbiditiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlpRelatedComorbidities {
    return json as SlpRelatedComorbidities;
}

export function SlpRelatedComorbiditiesToJSON(value?: SlpRelatedComorbidities | null): any {
    return value as any;
}

