/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolActionListEntry } from './IncidentProtocolActionListEntry';
import {
    IncidentProtocolActionListEntryFromJSON,
    IncidentProtocolActionListEntryFromJSONTyped,
    IncidentProtocolActionListEntryToJSON,
} from './IncidentProtocolActionListEntry';

/**
 * 
 * @export
 * @interface FindProtocolAction200Response
 */
export interface FindProtocolAction200Response {
    /**
     * 
     * @type {IncidentProtocolActionListEntry}
     * @memberof FindProtocolAction200Response
     */
    data?: IncidentProtocolActionListEntry;
}

/**
 * Check if a given object implements the FindProtocolAction200Response interface.
 */
export function instanceOfFindProtocolAction200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindProtocolAction200ResponseFromJSON(json: any): FindProtocolAction200Response {
    return FindProtocolAction200ResponseFromJSONTyped(json, false);
}

export function FindProtocolAction200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindProtocolAction200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentProtocolActionListEntryFromJSON(json['data']),
    };
}

export function FindProtocolAction200ResponseToJSON(value?: FindProtocolAction200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentProtocolActionListEntryToJSON(value.data),
    };
}

