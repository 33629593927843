import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { TextField, TextFieldProps, Tooltip } from '@mui/material';

import { formatFaxOrPhoneNumber } from '@/utils/formatters';

export type ControlledTextFieldProps<TFields extends FieldValues> =
  React.PropsWithChildren<
    UseControllerProps<TFields> &
      TextFieldProps & {
        format?:
          | 'ssn'
          | 'phoneNumber'
          | 'pin'
          | 'number'
          | 'vital'
          | 'bloodPressure'
          | 'currency'
          | 'integer'
          | 'dose'
          | 'medicare'
          | 'medicaid';
        onChange?: (e: any) => void;
        onBlur?: (e: any) => void;
        tooltip?: string;
        maxDecimalPlaces?: number;
      }
  >;

export function ControlledTextField<TFields extends FieldValues>({
  label,
  name,
  format,
  control,
  rules,
  InputProps,
  onChange,
  onBlur,
  tooltip,
  maxDecimalPlaces,
  helperText,
  ...props
}: ControlledTextFieldProps<TFields>) {
  const {
    field: {
      ref,
      onBlur: onBlurField,
      onChange: onChangeField,
      value,
      ...fields
    },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleOnBlur: React.FocusEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    if (format === 'currency' && !maxDecimalPlaces) {
      const value = e.target.value.replaceAll('-', '');
      const numberWithDecimals = Number(value).toFixed(2);
      onChangeField(numberWithDecimals);
    }
    onBlurField();
    setOpenTooltip(false);
    onBlur?.(e);
  };

  const formatSSN = (value: string) => value.replace(/\D/g, '').substring(0, 9);

  const formatPhoneNumber = (value: string) => formatFaxOrPhoneNumber(value);

  const formatPIN = (value: string) => value.replace(/\D/g, '').substring(0, 4);

  const formatNumber = (value: string) => value.replace(/[^\d.]/g, '');

  const formatBloodPressure = (value: string) => value.replace(/[^0-9/]/g, '');

  const formatInteger = (value: string) => value.replace(/[^\d]/g, '');

  const formatVital = (value: string) => {
    let values = value.replace(/[^\d. ]/g, '').split(' ');
    values = values.map((v) => {
      if (!v) return v;
      const float = Number.parseFloat(v).toString();
      if (float === 'NaN') return v;
      if (v.endsWith('.') && v.indexOf('.') === v.length - 1) {
        return `${float}.`;
      }
      return float;
    });
    return values.join(' ');
  };

  const formatMedicaidOrMedicare = (value: string) => {
    return value.replace(/[^a-zA-Z0-9 .-]/g, '').substring(0, 30);
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    let newValue = value;

    switch (format) {
      case 'ssn':
        newValue = formatSSN(value);
        break;
      case 'phoneNumber':
        newValue = formatPhoneNumber(value);
        break;
      case 'pin':
        newValue = formatPIN(value);
        break;
      case 'number':
        newValue = formatNumber(value);
        break;
      case 'bloodPressure':
        newValue = formatBloodPressure(value);
        break;
      case 'integer':
        newValue = formatInteger(value);
        break;
      case 'vital':
        newValue = formatVital(value);
        break;
      case 'currency':
        if (!maxDecimalPlaces) {
          const parts = value.split('.');
          if (parts.length === 2 && parts[1].length > 2) {
            return;
          }
        }
        break;
      case 'medicaid':
      case 'medicare':
        newValue = formatMedicaidOrMedicare(value);
        break;
      default:
        if (maxDecimalPlaces) {
          const parts = value.split('.');
          if (parts.length === 2 && parts[1].length > maxDecimalPlaces) {
            return;
          }
        }
        break;
    }
    e.target.value = newValue;
    onChangeField(e);
    onChange?.(e);
  };

  // https://stackoverflow.com/a/69497807
  const numberInputOnWheelPreventChange = (e: any) => {
    if (props.type === 'number') {
      // Prevent the input value change
      e.target.blur();

      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus();
      }, 0);
    }
  };

  const renderLabel = () => {
    if (rules?.required && label) {
      return (
        <span>
          {label}
          <span style={{ color: '#DD3730' }}>*</span>
        </span>
      );
    } else if (label) {
      return label;
    } else {
      return '';
    }
  };

  return (
    <Tooltip
      open={!!tooltip && openTooltip}
      arrow
      title={tooltip}
      placement="top-start">
      <TextField
        fullWidth
        label={renderLabel()}
        value={value ?? ''}
        variant="outlined"
        inputRef={ref}
        onFocus={() => setOpenTooltip(true)}
        helperText={helperText || error?.message}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...props.inputProps,
            maxLength: format === 'phoneNumber' ? 17 : undefined
          },
          onChange: handleOnChange,
          onBlur: handleOnBlur,
          onWheel: numberInputOnWheelPreventChange,
          sx: {
            ...props.sx,
            '& .MuiInputBase-input': {
              flex: 1 // Share the space with the adornment, taking up the whole
              // width without making the adornment wrap
            }
          }
        }}
        id={`mui-component-textfield-${name}`}
        error={!!error}
        {...fields}
        {...props}
      />
    </Tooltip>
  );
}
