/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmOtherResponse
 */
export interface ApfmOtherResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_activity_preferences?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_other_accommodations?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_sleeping_habits?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_other_preferences?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_other_living_situation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    profile_living_situation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmOtherResponse
     */
    secondary_resident_name?: string;
}

/**
 * Check if a given object implements the ApfmOtherResponse interface.
 */
export function instanceOfApfmOtherResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmOtherResponseFromJSON(json: any): ApfmOtherResponse {
    return ApfmOtherResponseFromJSONTyped(json, false);
}

export function ApfmOtherResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmOtherResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile_activity_preferences': !exists(json, 'profile_activity_preferences') ? undefined : json['profile_activity_preferences'],
        'profile_other_accommodations': !exists(json, 'profile_other_accommodations') ? undefined : json['profile_other_accommodations'],
        'profile_sleeping_habits': !exists(json, 'profile_sleeping_habits') ? undefined : json['profile_sleeping_habits'],
        'profile_other_preferences': !exists(json, 'profile_other_preferences') ? undefined : json['profile_other_preferences'],
        'profile_other_living_situation': !exists(json, 'profile_other_living_situation') ? undefined : json['profile_other_living_situation'],
        'profile_living_situation': !exists(json, 'profile_living_situation') ? undefined : json['profile_living_situation'],
        'secondary_resident_name': !exists(json, 'secondary_resident_name') ? undefined : json['secondary_resident_name'],
    };
}

export function ApfmOtherResponseToJSON(value?: ApfmOtherResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_activity_preferences': value.profile_activity_preferences,
        'profile_other_accommodations': value.profile_other_accommodations,
        'profile_sleeping_habits': value.profile_sleeping_habits,
        'profile_other_preferences': value.profile_other_preferences,
        'profile_other_living_situation': value.profile_other_living_situation,
        'profile_living_situation': value.profile_living_situation,
        'secondary_resident_name': value.secondary_resident_name,
    };
}

