/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpdateV2RequestData
 */
export interface BulkUpdateV2RequestData {
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateV2RequestData
     */
    lead_owner_id?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateV2RequestData
     */
    lifecycle_step?: BulkUpdateV2RequestDataLifecycleStepEnum;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateV2RequestData
     */
    temperature?: BulkUpdateV2RequestDataTemperatureEnum;
}


/**
 * @export
 */
export const BulkUpdateV2RequestDataLifecycleStepEnum = {
    Opportunity: 'Opportunity',
    Connected: 'Connected',
    PreTour: 'Pre-Tour',
    PostTour: 'Post-Tour',
    Deposit: 'Deposit',
    PreArrival: 'Pre-Arrival'
} as const;
export type BulkUpdateV2RequestDataLifecycleStepEnum = typeof BulkUpdateV2RequestDataLifecycleStepEnum[keyof typeof BulkUpdateV2RequestDataLifecycleStepEnum];

/**
 * @export
 */
export const BulkUpdateV2RequestDataTemperatureEnum = {
    Cold: 'Cold',
    Warm: 'Warm',
    Hot: 'Hot'
} as const;
export type BulkUpdateV2RequestDataTemperatureEnum = typeof BulkUpdateV2RequestDataTemperatureEnum[keyof typeof BulkUpdateV2RequestDataTemperatureEnum];


/**
 * Check if a given object implements the BulkUpdateV2RequestData interface.
 */
export function instanceOfBulkUpdateV2RequestData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BulkUpdateV2RequestDataFromJSON(json: any): BulkUpdateV2RequestData {
    return BulkUpdateV2RequestDataFromJSONTyped(json, false);
}

export function BulkUpdateV2RequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateV2RequestData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lead_owner_id': !exists(json, 'lead_owner_id') ? undefined : json['lead_owner_id'],
        'lifecycle_step': !exists(json, 'lifecycle_step') ? undefined : json['lifecycle_step'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
    };
}

export function BulkUpdateV2RequestDataToJSON(value?: BulkUpdateV2RequestData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lead_owner_id': value.lead_owner_id,
        'lifecycle_step': value.lifecycle_step,
        'temperature': value.temperature,
    };
}

