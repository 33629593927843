/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bill } from './Bill';
import {
    BillFromJSON,
    BillFromJSONTyped,
    BillToJSON,
} from './Bill';
import type { SkippedResident } from './SkippedResident';
import {
    SkippedResidentFromJSON,
    SkippedResidentFromJSONTyped,
    SkippedResidentToJSON,
} from './SkippedResident';

/**
 * 
 * @export
 * @interface GenerateBillResponse
 */
export interface GenerateBillResponse {
    /**
     * 
     * @type {Array<Bill>}
     * @memberof GenerateBillResponse
     */
    bills: Array<Bill>;
    /**
     * 
     * @type {Array<SkippedResident>}
     * @memberof GenerateBillResponse
     */
    skippedResidents: Array<SkippedResident>;
}

/**
 * Check if a given object implements the GenerateBillResponse interface.
 */
export function instanceOfGenerateBillResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bills" in value;
    isInstance = isInstance && "skippedResidents" in value;

    return isInstance;
}

export function GenerateBillResponseFromJSON(json: any): GenerateBillResponse {
    return GenerateBillResponseFromJSONTyped(json, false);
}

export function GenerateBillResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateBillResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bills': ((json['bills'] as Array<any>).map(BillFromJSON)),
        'skippedResidents': ((json['skippedResidents'] as Array<any>).map(SkippedResidentFromJSON)),
    };
}

export function GenerateBillResponseToJSON(value?: GenerateBillResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bills': ((value.bills as Array<any>).map(BillToJSON)),
        'skippedResidents': ((value.skippedResidents as Array<any>).map(SkippedResidentToJSON)),
    };
}

