import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { HTTPError } from 'ky';

import { useProspectResidents } from '@/hooks/useProspectResidents';
import ErrorPage from '@/pages/ErrorPage';

export const CrmProspectResidentLoader: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const residentId = useParams().resident_id!;

  const { error, isLoading } = useProspectResidents().findOne(residentId, {
    retry: false
  });

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (error instanceof HTTPError && error.response.status === 404) {
    return (
      <ErrorPage
        title="Requested Prospect Resident Not Found"
        message="This prospect resident does not exist. Please check the URL and try again."
      />
    );
  }

  return <>{children}</>;
};
