import React from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Fade, ListItemAvatar, ListItemText, Tooltip } from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';

import { StyledNavButtonOutlined } from '../Layout/Sidebar';
import { AvatarS3PhotoKey } from '../ui/Avatar/AvatarS3PhotoKey';

import { CurrentUserPopover } from './CurrentUserPopover';

interface CurrentUserProps {
  expanded: boolean;
  handleNavigate?: VoidFunction;
}

const CurrentUser: React.FC<CurrentUserProps> = ({
  expanded,
  handleNavigate
}) => {
  const currentUser = useCurrentUser().data!;
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();

  return (
    <>
      {/* @ts-ignore */}
      <StyledNavButtonOutlined {...{ ref }} onClick={() => setIsOpen(true)}>
        <ListItemAvatar>
          <AvatarS3PhotoKey
            s3PhotoKey={currentUser.s3_photo_key}
            sx={{ backgroundColor: '#1DB8F2' }}>
            {currentUser.getInitials()}
          </AvatarS3PhotoKey>
        </ListItemAvatar>
        <Fade in={expanded} easing="cubic-bezier(0.95, 0.05, 0.795, 0.035)">
          <Tooltip title={currentUser.getFullName()}>
            <ListItemText secondary={currentUser.getPosition()}>
              {currentUser.getFullName()}
            </ListItemText>
          </Tooltip>
        </Fade>
        {expanded && <>{isOpen ? <ArrowDropUp /> : <ArrowDropDown />}</>}
      </StyledNavButtonOutlined>
      <CurrentUserPopover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorEl={ref.current!}
        handleNavigate={handleNavigate}
      />
    </>
  );
};

export default CurrentUser;
