import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';
import { useTaskExtensionTemplatesQuery } from '@/hooks/useTaskExtensionTemplatesQuery';
import {
  TaskExtensionModel,
  TaskExtensionQuestionType,
  TaskExtensionType
} from '@/models/TaskExtensionModel';
import { TaskExtensionTemplate } from '@/types/resident-resources';

import { InstanceWithTaskExtensionFormType } from '.';

interface TaskExtensionTemplateFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  taskExtensionType: TaskExtensionType;
  cleanTemplateSelect: VoidFunction;
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  isOrgWide?: boolean;
}

export default function TaskExtensionTemplateForm(
  props: TaskExtensionTemplateFormProps
) {
  const {
    formMethods,
    taskExtensionType,
    cleanTemplateSelect,
    selectedTemplateId,
    setSelectedTemplateId,
    disabled = false,
    isOrgWide = false
  } = props;
  const currentUser = useCurrentUser().data!;
  const selectedFacilityId = useSelectedFacilityId();

  const {
    data: taskExtensionTemplates = [],
    isLoading: isLoadingTaskExtensionTemplates
  } = useTaskExtensionTemplatesQuery().findAll({
    facilityId:
      selectedFacilityId === 'ALL_FACILITIES'
        ? currentUser.facility_id
        : selectedFacilityId!,
    extensionType: taskExtensionType
  });

  const handleSetTemplate = (template: TaskExtensionTemplate) => {
    formMethods.setValue('task_extension.title', template.title, {
      shouldDirty: true
    });
    formMethods.setValue(
      'task_extension.description',
      template.description || null
    );
    formMethods.setValue(
      'task_extension.question_type',
      template.question_type as TaskExtensionQuestionType
    );
    formMethods.setValue(
      'task_extension.default_answer_options',
      template.default_answer_options || [{ value: '' }]
    );
    formMethods.setValue(
      'task_extension.default_answers',
      TaskExtensionModel.toTaskExtensionDefaultAnswersForm(
        template.default_answer_options || null,
        template.default_answers || null,
        template.question_type as TaskExtensionQuestionType
      )
    );
    if (!isOrgWide) {
      formMethods.setValue('task_extension.tag_id', template.tag_id || '');
    } else {
      formMethods.setValue('task_extension.tag_id', null);
    }
  };

  const cleanForm = () => {
    formMethods.setValue('task_extension.title', '');
    formMethods.setValue('task_extension.description', null);
    formMethods.setValue(
      'task_extension.question_type',
      TaskExtensionQuestionType.FreeText
    );
    formMethods.setValue('task_extension.default_answer_options', [
      { value: '' }
    ]);
    formMethods.setValue('task_extension.default_answers', [{ value: '' }]);
    formMethods.setValue('task_extension.tag_id', null);
  };

  useEffect(() => {
    if (selectedTemplateId && selectedTemplateId !== 'N/A') {
      const template = taskExtensionTemplates.find(
        (template) => template.id === selectedTemplateId
      );
      handleSetTemplate(template!);
    }
  }, [selectedTemplateId]);

  return (
    <FormControl fullWidth>
      <InputLabel id="template-select-label">
        {isLoadingTaskExtensionTemplates ? (
          <CircularProgress size="20px" />
        ) : (
          'Select Template'
        )}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="template-select-label"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
        value={selectedTemplateId}
        label={
          isLoadingTaskExtensionTemplates ? (
            <CircularProgress size="20px" />
          ) : (
            'Select Template'
          )
        }
        onChange={(event) => setSelectedTemplateId(event.target.value)}>
        {taskExtensionTemplates.map((item) => {
          return (
            <MenuItem value={item.id} key={item.id}>
              {item.title}
            </MenuItem>
          );
        })}
        <MenuItem
          value={'N/A'}
          onClick={() => {
            cleanForm();
            cleanTemplateSelect();
          }}>
          N/A
        </MenuItem>
      </Select>
    </FormControl>
  );
}
