/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarePlanEntryTemplateTaskExtension
 */
export interface CarePlanEntryTemplateTaskExtension {
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    care_plan_entry_template_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    question_type: string;
    /**
     * 
     * @type {any}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    default_answer_options?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    default_answers?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CarePlanEntryTemplateTaskExtension
     */
    tag_id?: any | null;
}

/**
 * Check if a given object implements the CarePlanEntryTemplateTaskExtension interface.
 */
export function instanceOfCarePlanEntryTemplateTaskExtension(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "question_type" in value;

    return isInstance;
}

export function CarePlanEntryTemplateTaskExtensionFromJSON(json: any): CarePlanEntryTemplateTaskExtension {
    return CarePlanEntryTemplateTaskExtensionFromJSONTyped(json, false);
}

export function CarePlanEntryTemplateTaskExtensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanEntryTemplateTaskExtension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'care_plan_entry_template_id': !exists(json, 'care_plan_entry_template_id') ? undefined : json['care_plan_entry_template_id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'question_type': json['question_type'],
        'default_answer_options': !exists(json, 'default_answer_options') ? undefined : json['default_answer_options'],
        'default_answers': !exists(json, 'default_answers') ? undefined : json['default_answers'],
        'tag_id': !exists(json, 'tag_id') ? undefined : json['tag_id'],
    };
}

export function CarePlanEntryTemplateTaskExtensionToJSON(value?: CarePlanEntryTemplateTaskExtension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'care_plan_entry_template_id': value.care_plan_entry_template_id,
        'title': value.title,
        'description': value.description,
        'question_type': value.question_type,
        'default_answer_options': value.default_answer_options,
        'default_answers': value.default_answers,
        'tag_id': value.tag_id,
    };
}

