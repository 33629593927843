/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentVitalListEntry
 */
export interface ResidentVitalListEntry {
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalListEntry
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalListEntry
     */
    resident_id: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalListEntry
     */
    vital_type_id: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalListEntry
     */
    created_by_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalListEntry
     */
    updated_by_user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    recorded_at: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentVitalListEntry
     */
    deletedAt?: string;
}

/**
 * Check if a given object implements the ResidentVitalListEntry interface.
 */
export function instanceOfResidentVitalListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "vital_type_id" in value;
    isInstance = isInstance && "created_by_user_id" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "recorded_at" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ResidentVitalListEntryFromJSON(json: any): ResidentVitalListEntry {
    return ResidentVitalListEntryFromJSONTyped(json, false);
}

export function ResidentVitalListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentVitalListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'vital_type_id': json['vital_type_id'],
        'created_by_user_id': json['created_by_user_id'],
        'updated_by_user_id': !exists(json, 'updated_by_user_id') ? undefined : json['updated_by_user_id'],
        'value': json['value'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'recorded_at': json['recorded_at'],
        'createdAt': json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
    };
}

export function ResidentVitalListEntryToJSON(value?: ResidentVitalListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'vital_type_id': value.vital_type_id,
        'created_by_user_id': value.created_by_user_id,
        'updated_by_user_id': value.updated_by_user_id,
        'value': value.value,
        'notes': value.notes,
        'recorded_at': value.recorded_at,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
    };
}

