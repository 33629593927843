import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import fetchExaCare from '@/adapters/fetchExaCare';

export interface CareCategory {
  description: string;
  id: string;
  title: string;
}

export function useFindAllCareCategoriesQuery<T = CareCategory[]>(
  options: UseQueryOptions<CareCategory[], HTTPError, T> = {}
) {
  return useQuery<CareCategory[], HTTPError, T>(
    ['useFindAllCareCategoriesQuery'],
    () => fetchExaCare.get<CareCategory[]>(`/care-categories`),
    options
  );
}
