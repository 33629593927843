import React, { useEffect, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormReturn
} from 'react-hook-form';

import { ControlledGroupSelect } from '@/components/ControlledGroupSelect';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';

import {
  GroupedPartyOptions,
  isStaffResponsibilityV2,
  PartyCategory
} from './options';

interface ResponsiblePartySelectableProps<TFields extends FieldValues> {
  // formMethods are required to handle deprecated responsible party
  formMethods: UseFormReturn<any>;
  name: FieldPath<TFields>;
  responsibleParties?: ResponsibleParty[];
  onChange?: (value: any) => void;
  disabled?: boolean;
  isEditableParty: boolean;
  rules?: UseControllerProps<TFields>['rules'];
}

const ResponsiblePartySelectable = <TFields extends FieldValues>({
  formMethods,
  name,
  onChange,
  disabled = false,
  responsibleParties,
  isEditableParty,
  rules
}: ResponsiblePartySelectableProps<TFields>) => {
  const [responsiblePartiesGroups, setResponsiblePartiesGroup] = useState<
    GroupedPartyOptions[]
  >([]);

  const responsible_party = formMethods.watch('responsible_party');
  useEffect(() => {
    if (responsibleParties) {
      const groups = responsibleParties.reduce(
        (acc: GroupedPartyOptions[], responsibleParty) => {
          acc[responsibleParty.is_informational ? 1 : 0].subItems.push({
            value: responsibleParty.id,
            label: responsibleParty.name
          });
          return acc;
        },
        [
          {
            group: PartyCategory.staff,
            subItems: []
          },
          {
            group: PartyCategory.nonStaff,
            subItems: []
          }
        ]
      );

      // Handle old responsible party.
      // We should remove it when we fully migrate to the new responsible party and no old tasks are left
      if (responsible_party && isNaN(parseInt(responsible_party))) {
        // Try to find the responsible party by name
        const responsibleParty = responsibleParties.find(
          (responsibleParty) => responsibleParty.name === responsible_party
        );
        // If non-editable, we should not try to find a new responsible party
        if (isEditableParty && responsibleParty) {
          formMethods.setValue('responsible_party', responsibleParty.id, {
            shouldDirty: true
          });
        } else {
          // If the responsible party is not found, add an option to the list
          isStaffResponsibilityV2(responsible_party, responsibleParties)
            ? groups[0].subItems.push({
                value: responsible_party,
                label: responsible_party
              })
            : groups[1].subItems.push({
                value: responsible_party,
                label: responsible_party
              });
        }
      }
      setResponsiblePartiesGroup(groups);
    }
  }, [responsibleParties]);

  return (
    <ControlledGroupSelect
      id="mui-component-select-responsibleParties"
      name={name}
      optionsGrouped={responsiblePartiesGroups}
      onChange={onChange}
      control={formMethods.control}
      label="Responsible Party"
      disabled={disabled}
      MenuProps={{
        sx: {
          '& .MuiListSubheader-root': {
            color: 'primary.main'
          }
        }
      }}
      rules={rules}
    />
  );
};

export default ResponsiblePartySelectable;
