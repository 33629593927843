/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DuplicateResidents } from './DuplicateResidents';
import {
    DuplicateResidentsFromJSON,
    DuplicateResidentsFromJSONTyped,
    DuplicateResidentsToJSON,
} from './DuplicateResidents';

/**
 * 
 * @export
 * @interface ManualDuplicationCheck200Response
 */
export interface ManualDuplicationCheck200Response {
    /**
     * 
     * @type {DuplicateResidents}
     * @memberof ManualDuplicationCheck200Response
     */
    data?: DuplicateResidents;
}

/**
 * Check if a given object implements the ManualDuplicationCheck200Response interface.
 */
export function instanceOfManualDuplicationCheck200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManualDuplicationCheck200ResponseFromJSON(json: any): ManualDuplicationCheck200Response {
    return ManualDuplicationCheck200ResponseFromJSONTyped(json, false);
}

export function ManualDuplicationCheck200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualDuplicationCheck200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : DuplicateResidentsFromJSON(json['data']),
    };
}

export function ManualDuplicationCheck200ResponseToJSON(value?: ManualDuplicationCheck200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DuplicateResidentsToJSON(value.data),
    };
}

