/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Month = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
} as const;
export type Month = typeof Month[keyof typeof Month];


export function MonthFromJSON(json: any): Month {
    return MonthFromJSONTyped(json, false);
}

export function MonthFromJSONTyped(json: any, ignoreDiscriminator: boolean): Month {
    return json as Month;
}

export function MonthToJSON(value?: Month | null): any {
    return value as any;
}

