/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Credit
 */
export interface Credit {
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    gl_code?: string;
}

/**
 * Check if a given object implements the Credit interface.
 */
export function instanceOfCredit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function CreditFromJSON(json: any): Credit {
    return CreditFromJSONTyped(json, false);
}

export function CreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'name': json['name'],
        'created_at': json['created_at'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
    };
}

export function CreditToJSON(value?: Credit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'amount': value.amount,
        'name': value.name,
        'created_at': value.created_at,
        'gl_code': value.gl_code,
    };
}

