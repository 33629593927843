/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextStep
 */
export interface TextStep {
    /**
     * 
     * @type {string}
     * @memberof TextStep
     */
    template_body: string;
    /**
     * 
     * @type {string}
     * @memberof TextStep
     */
    send_time: string | null;
}

/**
 * Check if a given object implements the TextStep interface.
 */
export function instanceOfTextStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "template_body" in value;
    isInstance = isInstance && "send_time" in value;

    return isInstance;
}

export function TextStepFromJSON(json: any): TextStep {
    return TextStepFromJSONTyped(json, false);
}

export function TextStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template_body': json['template_body'],
        'send_time': json['send_time'],
    };
}

export function TextStepToJSON(value?: TextStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_body': value.template_body,
        'send_time': value.send_time,
    };
}

