/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmCognitiveResponse
 */
export interface ApfmCognitiveResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmCognitiveResponse
     */
    profile_disability?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCognitiveResponse
     */
    profile_memory_loss?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApfmCognitiveResponse
     */
    profile_memory_diagnosis?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmCognitiveResponse
     */
    profile_memory_combative?: string;
}

/**
 * Check if a given object implements the ApfmCognitiveResponse interface.
 */
export function instanceOfApfmCognitiveResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmCognitiveResponseFromJSON(json: any): ApfmCognitiveResponse {
    return ApfmCognitiveResponseFromJSONTyped(json, false);
}

export function ApfmCognitiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmCognitiveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile_disability': !exists(json, 'profile_disability') ? undefined : json['profile_disability'],
        'profile_memory_loss': !exists(json, 'profile_memory_loss') ? undefined : json['profile_memory_loss'],
        'profile_memory_diagnosis': !exists(json, 'profile_memory_diagnosis') ? undefined : json['profile_memory_diagnosis'],
        'profile_memory_combative': !exists(json, 'profile_memory_combative') ? undefined : json['profile_memory_combative'],
    };
}

export function ApfmCognitiveResponseToJSON(value?: ApfmCognitiveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_disability': value.profile_disability,
        'profile_memory_loss': value.profile_memory_loss,
        'profile_memory_diagnosis': value.profile_memory_diagnosis,
        'profile_memory_combative': value.profile_memory_combative,
    };
}

