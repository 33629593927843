import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';

export function ControlledGroupSelect<TFields extends FieldValues, Option>({
  name,
  control,
  optionIdKey,
  label,
  onChange,
  optionsGrouped,
  rules,
  ...props
}: React.PropsWithChildren<
  UseControllerProps<TFields> &
    Partial<SelectProps> & {
      optionsGrouped: {
        group: string;
        subItems: Array<{ label: string; value: string }>;
      }[];
      optionIdKey?: keyof Option;
      onChange?: (option: Option) => void;
      rules?: UseControllerProps<TFields>['rules'];
    }
>) {
  const {
    field: { onChange: onChangeField, value, ...fields },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const selectValue = String(
    (optionIdKey ? (value as any)?.[optionIdKey] : value) ?? ''
  );

  let labelWithAsterisk = label;
  if (rules?.required) {
    labelWithAsterisk = (
      <span>
        {label}
        <span style={{ color: '#DD3730' }}>*</span>
      </span>
    );
  }

  return (
    <FormControl error={!!error} fullWidth>
      <InputLabel id={name}>{labelWithAsterisk}</InputLabel>
      <Select
        labelId={name}
        label={labelWithAsterisk}
        value={selectValue}
        defaultValue=""
        onChange={(e) => {
          const id = e.target.value;
          let selectedOption;
          for (const { subItems } of optionsGrouped) {
            for (const item of subItems) {
              if (item.value === id) {
                selectedOption = item;
              }
            }
          }
          onChangeField(selectedOption?.value ?? '');
          onChange?.(selectedOption?.value as Option);
        }}
        {...fields}
        {...props}>
        {optionsGrouped.map((category, key) => [
          <ListSubheader key={key}>{category.group}</ListSubheader>,
          category.subItems.map((subItem, subItemKey) => (
            <MenuItem
              key={subItemKey}
              value={subItem.value}
              data-testid={`responsible-party-id-${subItem.value}`}>
              {subItem.label}
            </MenuItem>
          ))
        ])}
      </Select>
    </FormControl>
  );
}
