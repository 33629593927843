import { ConversationState } from '@twilio/conversations';

import { UserPayload } from '@/hooks/useUserQuery';
import { UserModel } from '@/models/UserModel';

export const CONVERSATIONS_LIMIT = 1000;
export const DELETE_LAST_CONVERSATIONS_LIMIT = 10;

// These payloads don't come from the Twilio package on the client side. These are payloads that can come
// from the BE when it is not possible to get all the conversations and their information in the FE and requests to the
// BE are necessary to be done

export class CrmConversationPayload {
  accountSid: string;
  chatServiceSid: string;
  messagingServiceSid: string;
  sid: string;
  friendlyName: string;
  uniqueName: string;
  attributes: string;
  state: ConversationState;
  dateCreated: Date;
  dateUpdated: Date;
  timers: any;
  url: string;
  links: Record<string, string>;
  bindings: any;
  lastMessage?: CrmMessagePayload;
  constructor(payload: CrmConversationPayload) {
    Object.assign(this, payload);
  }
}

export class CrmMessagePayload {
  accountSid: string;
  conversationSid: string;
  sid: string;
  index: number;
  author: string;
  body: string;
  media: any[];
  attributes: string;
  participantSid: string;
  dateCreated: Date;
  dateUpdated: Date;
  url: string;
  delivery: any;
  links: Record<string, string>;
  contentSid: string;
  authorUser?: UserPayload;
}

export class CrmMessagesPagePayload {
  prevPageToken: string | null;
  prevPageNumber: string | null;
  messages: CrmMessagePayload[];
}

export class CrmConversationModel extends CrmConversationPayload {
  declare lastMessageAuthorUser?: UserModel;

  constructor(payload: CrmConversationPayload) {
    super(payload);
    if (payload.lastMessage?.authorUser) {
      this.lastMessageAuthorUser = new UserModel(
        payload.lastMessage.authorUser
      );
    }
  }
}
