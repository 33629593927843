/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceMembersPage } from './AudienceMembersPage';
import {
    AudienceMembersPageFromJSON,
    AudienceMembersPageFromJSONTyped,
    AudienceMembersPageToJSON,
} from './AudienceMembersPage';

/**
 * 
 * @export
 * @interface FindAllAudienceMembers200Response
 */
export interface FindAllAudienceMembers200Response {
    /**
     * 
     * @type {AudienceMembersPage}
     * @memberof FindAllAudienceMembers200Response
     */
    data?: AudienceMembersPage;
}

/**
 * Check if a given object implements the FindAllAudienceMembers200Response interface.
 */
export function instanceOfFindAllAudienceMembers200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllAudienceMembers200ResponseFromJSON(json: any): FindAllAudienceMembers200Response {
    return FindAllAudienceMembers200ResponseFromJSONTyped(json, false);
}

export function FindAllAudienceMembers200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllAudienceMembers200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : AudienceMembersPageFromJSON(json['data']),
    };
}

export function FindAllAudienceMembers200ResponseToJSON(value?: FindAllAudienceMembers200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AudienceMembersPageToJSON(value.data),
    };
}

