/* tslint:disable */
/* eslint-disable */
/**
 * notifications
 * notifications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationPreferenceListEntry } from './NotificationPreferenceListEntry';
import {
    NotificationPreferenceListEntryFromJSON,
    NotificationPreferenceListEntryFromJSONTyped,
    NotificationPreferenceListEntryToJSON,
} from './NotificationPreferenceListEntry';

/**
 * 
 * @export
 * @interface NotificationPreferenceCategoryListEntry
 */
export interface NotificationPreferenceCategoryListEntry {
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceCategoryListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceCategoryListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceCategoryListEntry
     */
    user_type_id?: string;
    /**
     * 
     * @type {Array<NotificationPreferenceListEntry>}
     * @memberof NotificationPreferenceCategoryListEntry
     */
    preferences?: Array<NotificationPreferenceListEntry>;
}

/**
 * Check if a given object implements the NotificationPreferenceCategoryListEntry interface.
 */
export function instanceOfNotificationPreferenceCategoryListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function NotificationPreferenceCategoryListEntryFromJSON(json: any): NotificationPreferenceCategoryListEntry {
    return NotificationPreferenceCategoryListEntryFromJSONTyped(json, false);
}

export function NotificationPreferenceCategoryListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPreferenceCategoryListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'user_type_id': !exists(json, 'user_type_id') ? undefined : json['user_type_id'],
        'preferences': !exists(json, 'preferences') ? undefined : ((json['preferences'] as Array<any>).map(NotificationPreferenceListEntryFromJSON)),
    };
}

export function NotificationPreferenceCategoryListEntryToJSON(value?: NotificationPreferenceCategoryListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'user_type_id': value.user_type_id,
        'preferences': value.preferences === undefined ? undefined : ((value.preferences as Array<any>).map(NotificationPreferenceListEntryToJSON)),
    };
}

