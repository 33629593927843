/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';
import type { PaymentRecordType } from './PaymentRecordType';
import {
    PaymentRecordTypeFromJSON,
    PaymentRecordTypeFromJSONTyped,
    PaymentRecordTypeToJSON,
} from './PaymentRecordType';
import type { PaymentStatus } from './PaymentStatus';
import {
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';
import type { Refund } from './Refund';
import {
    RefundFromJSON,
    RefundFromJSONTyped,
    RefundToJSON,
} from './Refund';

/**
 * 
 * @export
 * @interface PaymentRecord
 */
export interface PaymentRecord {
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRecord
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentRecord
     */
    refunded_amount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentRecord
     */
    pending_refund_amount: number;
    /**
     * 
     * @type {Date}
     * @memberof PaymentRecord
     */
    date: Date;
    /**
     * 
     * @type {PaymentRecordType}
     * @memberof PaymentRecord
     */
    type: PaymentRecordType;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    applied_to_bill_id?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRecord
     */
    fees?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    resident_name?: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PaymentRecord
     */
    payment_method?: PaymentMethod;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PaymentRecord
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {Array<Refund>}
     * @memberof PaymentRecord
     */
    refunds: Array<Refund>;
    /**
     * 
     * @type {string}
     * @memberof PaymentRecord
     */
    failure_reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRecord
     */
    is_settlement_payment: boolean;
}

/**
 * Check if a given object implements the PaymentRecord interface.
 */
export function instanceOfPaymentRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "refunded_amount" in value;
    isInstance = isInstance && "pending_refund_amount" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "refunds" in value;
    isInstance = isInstance && "is_settlement_payment" in value;

    return isInstance;
}

export function PaymentRecordFromJSON(json: any): PaymentRecord {
    return PaymentRecordFromJSONTyped(json, false);
}

export function PaymentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'refunded_amount': json['refunded_amount'],
        'pending_refund_amount': json['pending_refund_amount'],
        'date': (new Date(json['date'])),
        'type': PaymentRecordTypeFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applied_to_bill_id': !exists(json, 'applied_to_bill_id') ? undefined : json['applied_to_bill_id'],
        'fees': !exists(json, 'fees') ? undefined : json['fees'],
        'resident_name': !exists(json, 'resident_name') ? undefined : json['resident_name'],
        'payment_method': !exists(json, 'payment_method') ? undefined : PaymentMethodFromJSON(json['payment_method']),
        'status': !exists(json, 'status') ? undefined : PaymentStatusFromJSON(json['status']),
        'refunds': ((json['refunds'] as Array<any>).map(RefundFromJSON)),
        'failure_reason': !exists(json, 'failure_reason') ? undefined : json['failure_reason'],
        'is_settlement_payment': json['is_settlement_payment'],
    };
}

export function PaymentRecordToJSON(value?: PaymentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'amount': value.amount,
        'refunded_amount': value.refunded_amount,
        'pending_refund_amount': value.pending_refund_amount,
        'date': (value.date.toISOString().substring(0,10)),
        'type': PaymentRecordTypeToJSON(value.type),
        'description': value.description,
        'applied_to_bill_id': value.applied_to_bill_id,
        'fees': value.fees,
        'resident_name': value.resident_name,
        'payment_method': PaymentMethodToJSON(value.payment_method),
        'status': PaymentStatusToJSON(value.status),
        'refunds': ((value.refunds as Array<any>).map(RefundToJSON)),
        'failure_reason': value.failure_reason,
        'is_settlement_payment': value.is_settlement_payment,
    };
}

