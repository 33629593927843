import { useQuery } from '@tanstack/react-query';

import { fetchIncidents } from '@/adapters/fetchExaCare';

type ID =
  | 'INCIDENT_TYPE'
  | 'INCIDENT_INJURY_TYPE'
  | 'INJURY_AREA'
  | 'ACTIONS_FOLLOW_UP'
  | 'CONSCIOUSNESS_LEVEL'
  | 'INCIDENT_FOLLOW_UP';

export function useLookup(id: ID) {
  return useQuery(['useLookup', id], () => {
    return fetchIncidents.get<any>(`/lookup/${id}`);
  });
}
