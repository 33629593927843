import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Medication } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  CircularProgress,
  Fade,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ControlledSelect } from '@/components/ControlledSelect';
import { ControlledTextField } from '@/components/ControlledTextField';
import { FormHeader } from '@/components/FormHeader';
import ResponsiblePartySelectable from '@/components/ResponsiblePartySelectable';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import {
  DrugInteraction,
  DrugInteractionAlertType,
  useMedicationInteractionsQuery
} from '@/hooks/useMedicationInteractionsQuery';
import {
  MedicationsPayload,
  useMedicationDetailsQuery,
  useMedicationImagesQuery
} from '@/hooks/useMedicationsQuery';
import { MedicationTaskPayload } from '@/hooks/useResidentMedications';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import MedicationMatcherAlert from '@/pages/Pharmacy/PharmacyProfile/components/MedicationMatcherAlert';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';
import { NumberOfDoseUnits } from '../NumberOfDoseUnits';

import { CustomMedicationDetails } from './CustomMedicationDetails/CustomMedicationDetails';
import DrugInteractionAlert from './DrugInteractionAlert';
import { DuplicateMedicationAlert } from './DuplicateMedicationAlert';
import { getMedicationOptions } from './getMedicationOptions';
import { SelectMedicationInput } from './SelectMedicationInput';

interface MedicationDetailsProps {
  disableEdit: boolean;
  formMethods: UseFormReturn<ResidentMedicationForm>;
  handleNext: VoidFunction;
  handleClose: VoidFunction;
  type: MedicationDrawerProps['type'];
  onResponsiblePartyChanged: (hasChanged: boolean) => void;
  responsibleParties?: ResponsibleParty[];
  residentId?: string;
  residentName?: string;
  preFilledMedication?: string;
  activeMedications?: MedicationTaskPayload[];
}

export const MedicationDetails: React.FC<MedicationDetailsProps> = ({
  disableEdit,
  formMethods,
  handleClose,
  handleNext,
  type,
  onResponsiblePartyChanged,
  responsibleParties,
  residentId,
  residentName,
  preFilledMedication,
  activeMedications
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const [isWarningConfirmed, setIsWarningConfirmed] = useState<boolean | null>(
    null
  );
  const [
    medicationId,
    medicationGeneric,
    medicationRoute,
    medicationForm,
    dose,
    numberOfDoseUnits,
    freq,
    responsibleParty,
    nonCommunityDose,
    medication_id,
    drugName
  ] = formMethods.watch([
    'medication.id',
    'medicationGeneric',
    'medicationRoute',
    'medicationForm',
    'dose',
    'frequency.number_of_dose_units',
    'frequency.freq',
    'responsible_party',
    'non_community_dose',
    'medication_id',
    'medication.drug_name'
  ]);

  const [isFdbDrug, setIsFdbDrug] = useState<boolean>(
    (type === 'Add' && !medication_id) ||
      ((type === 'Edit' || type === 'Readonly') && !medication_id)
  );

  const [drugInteractionAlerts, setDrugInteractionAlerts] = useState<
    DrugInteractionAlertType[]
  >([]);

  const [acceptedDrugInteractions, setAcceptedDrugInteractions] = useState<
    string[]
  >([]);

  const isStaffResponsibility = useCallback(isStaffResponsibilityV2, [
    responsibleParty,
    responsibleParties
  ]);

  useEffect(() => {
    if (type == 'Edit' && formMethods.formState.isDirty) {
      const isStaff = isStaffResponsibility(
        responsibleParty,
        responsibleParties
      );
      onResponsiblePartyChanged(isStaff === null ? false : isStaff);
    } else {
      onResponsiblePartyChanged(false);
    }
    if (
      !isStaffResponsibility(responsibleParty, responsibleParties) &&
      !nonCommunityDose &&
      numberOfDoseUnits
    ) {
      formMethods.setValue('non_community_dose', numberOfDoseUnits);
    }
  }, [responsibleParty]);

  const { data: medications = [], isFetching } = useMedicationDetailsQuery(
    medicationId,
    {
      onSuccess(medications) {
        handleMedicationSuccess(medications);
      }
    }
  );

  const handleMedicationSuccess = (medications: MedicationsPayload[]) => {
    const options = getMedicationOptions({
      medicationGeneric,
      medicationForm,
      medicationRoute,
      medications
    });
    if (options.generic.length === 1) {
      formMethods.setValue('medicationGeneric', options.generic[0]);
      if (options.route.length === 1) {
        formMethods.setValue('medicationRoute', options.route[0]);
        if (options.form.length === 1) {
          formMethods.setValue('medicationForm', options.form[0]);
          if (options.dose.length === 1) {
            formMethods.setValue('dose', options.dose[0]);
            const dispensable = getDispensableDrug(
              options.filtered,
              options.dose[0].id
            );
            formMethods.setValue('DispensableDrug', null);
            formMethods.setValue(
              'DispensableDrugID',
              dispensable?.DispensableDrugID ?? null
            );
            formMethods.setValue(
              'frequency.number_of_dose_units',
              numberOfDoseUnits || 1
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!medications || medications.length === 0) return;
    handleMedicationSuccess(medications);
  }, [medications]);

  const options = getMedicationOptions({
    medicationRoute,
    medicationForm,
    medicationGeneric,
    medications
  });

  function getDispensableDrug(filtered: MedicationsPayload[], doseId?: string) {
    return filtered.find((v) => v.MedStrength === doseId) ?? null;
  }

  const dispensable = getDispensableDrug(options.filtered, dose?.id);
  const drugId = dispensable?.DispensableDrugID;
  const { data: images } = useMedicationImagesQuery(drugId ? [drugId] : [], {
    enabled: !!drugId
  });

  const image = useMemo(() => {
    if (!drugId) {
      return;
    }
    if (images && images.get(drugId)) {
      const imageData = images.get(drugId);
      if (imageData?.urls.length && imageData.urls.length > 0) {
        return imageData.urls[0];
      }
    }
  }, [images]);

  const handleFdbMedicationOnChange = useCallback(() => {
    setIsFdbDrug(true);
    formMethods.setValue('medicationRoute', null);
    formMethods.setValue('medicationForm', null);
    formMethods.setValue('dose', null);
    formMethods.setValue('DispensableDrug', null);
    formMethods.setValue('DispensableDrugID', null);
    formMethods.setValue('frequency.number_of_dose_units', null);
    formMethods.setValue('medication_id', null);
    formMethods.reset(
      {
        medicationGeneric: null,
        medicationRoute: null,
        medicationForm: null,
        dose: null,
        DispensableDrug: null,
        DispensableDrugID: null,
        frequency: { number_of_dose_units: null },
        non_community_dose: null,
        medication_id: null
      },
      { keepValues: true }
    );
    setIsWarningConfirmed(false);
    setDrugInteractionAlerts([]);
    setAcceptedDrugInteractions([]);
  }, [
    setIsFdbDrug,
    formMethods,
    setIsWarningConfirmed,
    setDrugInteractionAlerts,
    setAcceptedDrugInteractions
  ]);

  const handleCustomMedicationOnChange = useCallback(
    (newMedicationId?: string) => {
      setIsFdbDrug(false);
      if (newMedicationId) {
        formMethods.setValue('medication_id', newMedicationId, {
          shouldValidate: true
        });
      }
      formMethods.setValue('medication.id', '');
      formMethods.setValue('medication.drug_name', '');
      formMethods.setValue('medicationGeneric.id', '');
      formMethods.setValue('medicationGeneric.name', '');
      formMethods.setValue('medicationRoute', null);
      formMethods.setValue('medicationForm', null);
      formMethods.setValue('dose', null);
      formMethods.setValue('DispensableDrug', null);
      formMethods.setValue('DispensableDrugID', null);
      formMethods.setValue('frequency.number_of_dose_units', null);
      formMethods.reset(
        {
          medication: null,
          medicationGeneric: null,
          medicationRoute: null,
          medicationForm: null,
          dose: null,
          DispensableDrug: null,
          DispensableDrugID: null,
          frequency: { number_of_dose_units: null },
          non_community_dose: null,
          medication_id: null
        },
        { keepValues: true }
      );

      setIsWarningConfirmed(false);
      setDrugInteractionAlerts([]);
      setAcceptedDrugInteractions([]);
    },
    [
      formMethods,
      setIsFdbDrug,
      setIsWarningConfirmed,
      setDrugInteractionAlerts,
      setAcceptedDrugInteractions
    ]
  );

  React.useEffect(() => {
    if (options.dose.length == 1) {
      formMethods.setValue('dose', options.dose[0]);
      const dispensable = getDispensableDrug(
        options.filtered,
        options.dose[0].id
      );
      formMethods.setValue('DispensableDrug', dispensable);
      formMethods.setValue(
        'DispensableDrugID',
        dispensable?.DispensableDrugID ?? null
      );
    }
  }, [medicationForm]);

  const {
    data: drugInteractionsData = [],
    status: drugInteractionsStatus,
    isFetching: isFetchingDrugInteractions
  } = useMedicationInteractionsQuery(residentId!, drugId, {
    enabled: !!residentId && !!drugId
  });

  useEffect(() => {
    if (drugInteractionsStatus === 'success') {
      const interactionAlerts =
        transformDrugInteractionAlerts(drugInteractionsData);
      setDrugInteractionAlerts(interactionAlerts);
    }
  }, [drugInteractionsData]);

  const transformDrugInteractionAlerts = (
    drugInteractions: DrugInteraction[]
  ) => {
    // The next array is necessary to order by severity
    // From FDB doc, the severity is managed like this:
    // 1 - Contraindicated Drug Combination
    // 2 - Severe Interaction
    // 3 - Moderate Interaction
    // 9 - Undetermined Severity–Alternative Therapy Interaction
    const severityOrder = ['9', '2', '3', '1'];
    const interactionAlerts = drugInteractions
      .map((interaction) => {
        return {
          id: generateId(),
          accept: false,
          Severity: interaction.Severity,
          ScreenMessage: interaction.ScreenMessage,
          ClinicalEffectsNarrative: interaction.ClinicalEffectsNarrative,
          SeverityDesc: interaction.SeverityDesc
        };
      })
      .sort((a, b) => {
        return (
          severityOrder.indexOf(a.Severity) - severityOrder.indexOf(b.Severity)
        );
      });
    return interactionAlerts;
  };

  const handleDrugInteractionAccept = (checked: boolean, id: string) => {
    const newInteractions = drugInteractionAlerts.map((interaction) => {
      if (id !== interaction.id) return interaction;
      return {
        ...interaction,
        accept: checked
      };
    });
    setDrugInteractionAlerts(newInteractions);
    if (checked) {
      setAcceptedDrugInteractions((prevVal) => [...prevVal, id]);
    } else {
      setAcceptedDrugInteractions((ids) =>
        ids.filter((currentId) => currentId !== id)
      );
    }
  };

  const generateId = () =>
    new Date().getTime().toString(36) + Math.random().toString(36).slice(2);

  const preventNextButton =
    formMethods.watch('DispensableDrug')?.DrugNameID == null;

  const [
    displayDuplicateMedicationWarning,
    setDisplayDuplicateMedicationWarning
  ] = useState<boolean>(false);

  useEffect(() => {
    if (type !== 'Add' || activeMedications === undefined) {
      return;
    }

    let shouldDisplayWarning = false;

    if (isFdbDrug && medicationId) {
      shouldDisplayWarning =
        activeMedications?.some(
          (medication) =>
            medication.fdb_dispensable_drug?.DrugNameID === medicationId
        ) ?? false;
    }
    if (!isFdbDrug && medication_id) {
      shouldDisplayWarning =
        activeMedications?.some(
          (medication) =>
            medication.fdb_dispensable_drug?.medication_id === medication_id
        ) ?? false;
    }

    setDisplayDuplicateMedicationWarning(shouldDisplayWarning);
  }, [medicationId, medication_id, isFdbDrug]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormHeader Icon={Medication} text="Medication" />
        </Grid>
        {isFdbDrug && (
          <>
            <Grid xs={12}>
              <SelectMedicationInput
                label="Medication"
                control={formMethods.control}
                name="medication"
                optionLabelKey="drug_name"
                optionIdKey="id"
                rules={{
                  required: isFdbDrug
                }}
                onChange={handleFdbMedicationOnChange}
                disabled={disableEdit || isResidentArchived}
              />
            </Grid>
            {displayDuplicateMedicationWarning && (
              <Grid xs={12}>
                <DuplicateMedicationAlert
                  drugName={drugName}
                  residentName={residentName}
                />
              </Grid>
            )}
          </>
        )}
        {!isResidentArchived && (
          <Grid xs={12}>
            <Typography
              onClick={() => {
                setIsFdbDrug(!isFdbDrug);
              }}
              sx={{
                color: '#009BD0',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                cursor: 'pointer'
              }}>
              {isFdbDrug
                ? `Can't find a medication?`
                : `Do you want to add a standard medication?`}
            </Typography>
          </Grid>
        )}
        {!isFdbDrug && (
          <>
            <CustomMedicationDetails
              formMethods={formMethods}
              onChange={handleCustomMedicationOnChange}
              disabled={disableEdit}
              residentId={residentId}
            />
            {displayDuplicateMedicationWarning && (
              <Grid xs={12}>
                <DuplicateMedicationAlert
                  drugName={drugName || 'medication'}
                  residentName={residentName}
                />
              </Grid>
            )}
          </>
        )}
        {preFilledMedication != null && (
          <Grid xs={12}>
            <MedicationMatcherAlert
              medicationFrom={preFilledMedication ?? ''}
              medicationTo={dispensable as MedicationsPayload}
              handleCheckBoxChange={(e) => setIsWarningConfirmed(e)}
            />
          </Grid>
        )}
        {isFdbDrug && (
          <>
            <Grid xs={12} md={6}>
              <ControlledSelect
                control={formMethods.control}
                onChange={() => {
                  formMethods.setValue('medicationRoute', null);
                  formMethods.setValue('medicationForm', null);
                  formMethods.setValue('dose', null);
                  formMethods.setValue('DispensableDrug', null);
                  formMethods.setValue('DispensableDrugID', null);
                  formMethods.setValue('frequency.number_of_dose_units', null);
                  formMethods.setValue('non_community_dose', null);
                }}
                isLoading={isFetching}
                disabled={!medicationId || disableEdit || isResidentArchived}
                label="Branded"
                name="medicationGeneric"
                optionLabelKey="name"
                optionIdKey="id"
                options={options.generic}
                rules={{ required: isFdbDrug }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ControlledSelect
                control={formMethods.control}
                onChange={() => {
                  formMethods.setValue('medicationForm', null);
                  formMethods.setValue('dose', null);
                  formMethods.setValue('DispensableDrug', null);
                  formMethods.setValue('DispensableDrugID', null);
                  formMethods.setValue('frequency.number_of_dose_units', null);
                  formMethods.setValue('non_community_dose', null);
                }}
                isLoading={isFetching}
                disabled={
                  !medicationGeneric?.id || disableEdit || isResidentArchived
                }
                label="Route"
                name="medicationRoute"
                optionLabelKey="name"
                optionIdKey="id"
                options={options.route}
                rules={{ required: isFdbDrug }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ControlledSelect
                control={formMethods.control}
                onChange={() => {
                  formMethods.setValue('dose', null);
                  formMethods.setValue('DispensableDrug', null);
                  formMethods.setValue('DispensableDrugID', null);
                  formMethods.setValue('frequency.number_of_dose_units', null);
                  formMethods.setValue('non_community_dose', null);
                }}
                isLoading={isFetching}
                disabled={
                  !(medicationGeneric?.id && medicationRoute?.id) ||
                  disableEdit ||
                  isResidentArchived
                }
                label="Form"
                name="medicationForm"
                optionLabelKey="name"
                optionIdKey="id"
                options={options.form}
                rules={{ required: isFdbDrug }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ControlledSelect
                hideNA
                control={formMethods.control}
                onChange={(dose) => {
                  if (!dose && options.dose.length == 1) {
                    dose = options.dose[0];
                  }
                  const dispensable = getDispensableDrug(
                    options.filtered,
                    (dose as any).id as string
                  );
                  formMethods.setValue('DispensableDrug', dispensable);
                  formMethods.setValue(
                    'DispensableDrugID',
                    dispensable?.DispensableDrugID ?? null
                  );
                  formMethods.setValue('frequency.number_of_dose_units', 1);
                  formMethods.setValue('non_community_dose', 1);
                }}
                isLoading={isFetching}
                disabled={
                  !(
                    medicationGeneric?.id &&
                    medicationRoute?.id &&
                    medicationForm?.id
                  ) ||
                  dose?.id === null ||
                  disableEdit ||
                  isResidentArchived
                }
                label="Strength"
                name="dose"
                optionLabelKey="strength"
                optionIdKey="id"
                options={options.dose}
                rules={{ required: isFdbDrug }}
              />
            </Grid>
          </>
        )}
        {!!dispensable?.GenericDispensableDrugDesc && (
          <>
            <Grid xs={image ? 9 : 12}>
              <Alert
                sx={{ height: '100%', alignItems: 'center' }}
                severity="success">
                {dispensable?.GenericDispensableDrugDesc || ''}
              </Alert>
            </Grid>
            {image && (
              <Fade in={true}>
                <Grid xs={3}>
                  <Avatar
                    sx={{ width: '100%', height: '100%' }}
                    variant="rounded"
                    src={image}>
                    <Medication fontSize="large" />
                  </Avatar>
                </Grid>
              </Fade>
            )}
          </>
        )}
        {!isStaffResponsibility(responsibleParty, responsibleParties) && (
          <>
            <Grid xs={12}>
              <Alert
                severity="info"
                data-testid="non-staff-party-alert-medication">
                <AlertTitle>Non-Staff Responsible party selected</AlertTitle>
                Medication tasks are not generated for non-staff responsible
                parties.
              </Alert>
            </Grid>
            {isFdbDrug ? (
              dose?.id && (
                <NumberOfDoseUnits
                  formMethods={formMethods}
                  name="non_community_dose"
                />
              )
            ) : (
              <NumberOfDoseUnits
                formMethods={formMethods}
                name="non_community_dose"
              />
            )}
          </>
        )}
        {isStaffResponsibility(responsibleParty, responsibleParties) &&
          freq !== 'custom' &&
          freq !== 'twice-a-day' &&
          freq !== 'three-times-a-day' &&
          freq !== 'four-times-a-day' &&
          freq !== 'five-times-a-day' &&
          (dose?.id || medication_id) &&
          !FeatureFlagService.isEnabled(
            ExacareFeature.MEDICATION_TASK_REGIMENS
          ) && (
            <NumberOfDoseUnits
              disableEdit={type === 'Readonly'}
              formMethods={formMethods}
              name="frequency.number_of_dose_units"
            />
          )}
        <Grid xs={12}>
          <ResponsiblePartySelectable
            formMethods={formMethods}
            name="responsible_party"
            disabled={isResidentArchived || disableEdit}
            responsibleParties={responsibleParties}
            isEditableParty={!isResidentArchived && !disableEdit}
          />
        </Grid>
        <Grid xs={12}>
          <ControlledTextField
            control={formMethods.control}
            disabled={isResidentArchived || type === 'Readonly'}
            name="equivalent_to"
            label="Equivalent to"
            placeholder="Glucophage"
          />
        </Grid>
        <Grid xs={12}>
          <ControlledTextField
            control={formMethods.control}
            disabled={isResidentArchived || type === 'Readonly'}
            name="amount_in_stock"
            label="Medication Total Quantity"
            type={'number'}
            tooltip="Use this field to track medication stock and remind you when resident needs a refill"
            rules={{
              min: 0
            }}
          />
        </Grid>
      </Grid>

      {(type === 'Add' || type === 'Readonly') && (
        <>
          <Grid container gap={2}>
            {isFetchingDrugInteractions && (
              <Grid xs={12} display="flex" alignItems="center" gap={1}>
                <CircularProgress size={12} />
                <Typography fontSize="14px">
                  Checking for drug interactions…
                </Typography>
              </Grid>
            )}
            {drugInteractionAlerts.map((interaction, i) => {
              return (
                <Grid xs={12} key={i}>
                  <DrugInteractionAlert
                    drugInteraction={interaction}
                    accept={interaction.accept}
                    onAccept={(checked, id) =>
                      handleDrugInteractionAccept(checked, id)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <DrawerFooter>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={
                medication_id == null &&
                (drugInteractionAlerts.length !==
                  acceptedDrugInteractions.length ||
                  isFetchingDrugInteractions ||
                  preventNextButton ||
                  (isWarningConfirmed === false && preFilledMedication != null))
              }
              variant="contained"
              color="primary"
              onClick={handleNext}>
              Next
            </Button>
          </DrawerFooter>
        </>
      )}
    </>
  );
};
