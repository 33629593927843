import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  styled
} from '@mui/material';

import { DrugInteractionAlertType } from '@/hooks/useMedicationInteractionsQuery';

type SeverityType = 'mild' | 'moderate' | 'severe' | 'undetermined';

// The next mapping keys come from the FDB documentation
const severityMapping: { [key: string]: SeverityType } = {
  '1': 'mild', // 1 - Contraindicated Drug Combination
  '2': 'severe', // 2 - Severe Interaction
  '3': 'moderate', // 3 - Moderate Interaction
  '9': 'undetermined' // 9 - Undetermined Severity–Alternative Therapy Interaction
};

interface StyledAlertItemProps {
  severity: SeverityType;
  variant: 'light' | 'primary';
}

const alertItemColorMapping = {
  mild: {
    checkboxColor: '#726951',
    iconColor: '#5f2b01',
    light: '#faeed1',
    primary: '#f6e3b2'
  },
  moderate: {
    checkboxColor: '#73685e',
    iconColor: '#5f2b01',
    light: '#fdede1',
    primary: '#fbe2cb'
  },
  severe: {
    checkboxColor: '#756361',
    iconColor: '#602b01',
    light: '#ffe9e5',
    primary: '#ffdbd5'
  },
  undetermined: {
    checkboxColor: '#756361',
    iconColor: '#602b01',
    light: '#ffe9e5',
    primary: '#ffdbd5'
  }
};

const StyledAlertItem = styled(Box)(
  ({ severity, variant }: StyledAlertItemProps) => ({
    backgroundColor: alertItemColorMapping[severity][variant],
    padding: '10px 15px',
    '&.footer': {
      borderRadius: '0 0 10px 10px',
      fontStyle: 'italic',
      fontWeight: 500
    }
  })
);

/**
 * @deprecated Use the Accordion component from '@/components/ui/Accordion/Accordion' instead.
 */
const StyledAccordion = styled(Accordion)({
  marginTop: '10px',
  boxShadow: 'none',
  border: 'none',
  '& .MuiAccordionSummary-root:hover.MuiButtonBase-root:hover': {
    cursor: 'default'
  }
});

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ severity }: { severity: SeverityType }) => ({
    backgroundColor: alertItemColorMapping[severity].primary,
    borderRadius: '10px',
    fontWeight: 500,
    '&.Mui-expanded': {
      borderRadius: '10px 10px 0 0'
    }
  })
);

const StyledCheckbox = styled(Checkbox)(
  ({ severity }: { severity: SeverityType }) => ({
    color: alertItemColorMapping[severity].checkboxColor,
    '&.Mui-checked': {
      color: alertItemColorMapping[severity].checkboxColor
    },
    input: {
      cursor: 'pointer'
    }
  })
);

export default function DrugInteractionAlert({
  drugInteraction,
  onAccept
}: {
  drugInteraction: DrugInteractionAlertType;
  accept: boolean;
  onAccept: (accept: boolean, drugInteractionId: string) => void;
}) {
  const {
    Severity,
    ScreenMessage,
    ClinicalEffectsNarrative,
    SeverityDesc,
    id,
    accept
  } = drugInteraction;
  const mappedSeverity = severityMapping[Severity];

  return (
    <StyledAccordion expanded={!accept} disableGutters>
      <StyledAccordionSummary severity={mappedSeverity}>
        <Box
          display="flex"
          width={1}
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" alignItems="center">
            <WarningAmberIcon
              sx={{
                mr: 1,
                color: alertItemColorMapping[mappedSeverity].iconColor
              }}
            />
            <Box>
              Drug Interaction:{' '}
              <Box sx={{ textTransform: 'capitalize' }} component="span">
                {mappedSeverity}
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>Accept & Collapse</Box>
            <Box>
              <StyledCheckbox
                disableRipple
                severity={mappedSeverity}
                checked={accept}
                onChange={(e) =>
                  onAccept(e.target.checked, id)
                }></StyledCheckbox>
            </Box>
          </Box>
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: 0, margin: 0 }}>
        <StyledAlertItem severity={mappedSeverity} variant="light">
          {ScreenMessage}
        </StyledAlertItem>
        <StyledAlertItem severity={mappedSeverity} variant="primary">
          {ClinicalEffectsNarrative}
        </StyledAlertItem>
        <StyledAlertItem
          severity={mappedSeverity}
          variant="light"
          className="footer">
          {SeverityDesc}
        </StyledAlertItem>
      </AccordionDetails>
    </StyledAccordion>
  );
}
