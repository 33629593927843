/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PdpmEstimatorField } from './PdpmEstimatorField';
import {
    PdpmEstimatorFieldFromJSON,
    PdpmEstimatorFieldFromJSONTyped,
    PdpmEstimatorFieldToJSON,
} from './PdpmEstimatorField';
import type { PdpmEstimatorFieldReferenceMetaValue } from './PdpmEstimatorFieldReferenceMetaValue';
import {
    PdpmEstimatorFieldReferenceMetaValueFromJSON,
    PdpmEstimatorFieldReferenceMetaValueFromJSONTyped,
    PdpmEstimatorFieldReferenceMetaValueToJSON,
} from './PdpmEstimatorFieldReferenceMetaValue';

/**
 * 
 * @export
 * @interface PdpmEstimatorFieldReferenceMeta
 */
export interface PdpmEstimatorFieldReferenceMeta {
    /**
     * 
     * @type {PdpmEstimatorField}
     * @memberof PdpmEstimatorFieldReferenceMeta
     */
    field: PdpmEstimatorField;
    /**
     * 
     * @type {PdpmEstimatorFieldReferenceMetaValue}
     * @memberof PdpmEstimatorFieldReferenceMeta
     */
    value: PdpmEstimatorFieldReferenceMetaValue | null;
}

/**
 * Check if a given object implements the PdpmEstimatorFieldReferenceMeta interface.
 */
export function instanceOfPdpmEstimatorFieldReferenceMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "field" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function PdpmEstimatorFieldReferenceMetaFromJSON(json: any): PdpmEstimatorFieldReferenceMeta {
    return PdpmEstimatorFieldReferenceMetaFromJSONTyped(json, false);
}

export function PdpmEstimatorFieldReferenceMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdpmEstimatorFieldReferenceMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': PdpmEstimatorFieldFromJSON(json['field']),
        'value': PdpmEstimatorFieldReferenceMetaValueFromJSON(json['value']),
    };
}

export function PdpmEstimatorFieldReferenceMetaToJSON(value?: PdpmEstimatorFieldReferenceMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': PdpmEstimatorFieldToJSON(value.field),
        'value': PdpmEstimatorFieldReferenceMetaValueToJSON(value.value),
    };
}

