/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoctorSearchResult } from './DoctorSearchResult';
import {
    DoctorSearchResultFromJSON,
    DoctorSearchResultFromJSONTyped,
    DoctorSearchResultToJSON,
} from './DoctorSearchResult';

/**
 * 
 * @export
 * @interface SearchDoctorsResponse
 */
export interface SearchDoctorsResponse {
    /**
     * 
     * @type {DoctorSearchResult}
     * @memberof SearchDoctorsResponse
     */
    exact_match?: DoctorSearchResult;
    /**
     * 
     * @type {Array<DoctorSearchResult>}
     * @memberof SearchDoctorsResponse
     */
    resident_doctors?: Array<DoctorSearchResult>;
    /**
     * 
     * @type {any}
     * @memberof SearchDoctorsResponse
     */
    facility_doctors?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SearchDoctorsResponse
     */
    organization_doctors?: any | null;
}

/**
 * Check if a given object implements the SearchDoctorsResponse interface.
 */
export function instanceOfSearchDoctorsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchDoctorsResponseFromJSON(json: any): SearchDoctorsResponse {
    return SearchDoctorsResponseFromJSONTyped(json, false);
}

export function SearchDoctorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDoctorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exact_match': !exists(json, 'exact_match') ? undefined : DoctorSearchResultFromJSON(json['exact_match']),
        'resident_doctors': !exists(json, 'resident_doctors') ? undefined : ((json['resident_doctors'] as Array<any>).map(DoctorSearchResultFromJSON)),
        'facility_doctors': !exists(json, 'facility_doctors') ? undefined : json['facility_doctors'],
        'organization_doctors': !exists(json, 'organization_doctors') ? undefined : json['organization_doctors'],
    };
}

export function SearchDoctorsResponseToJSON(value?: SearchDoctorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exact_match': DoctorSearchResultToJSON(value.exact_match),
        'resident_doctors': value.resident_doctors === undefined ? undefined : ((value.resident_doctors as Array<any>).map(DoctorSearchResultToJSON)),
        'facility_doctors': value.facility_doctors,
        'organization_doctors': value.organization_doctors,
    };
}

