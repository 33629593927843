/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountType } from './DiscountType';
import {
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
} from './DiscountType';

/**
 * 
 * @export
 * @interface CreateDiscountRequest
 */
export interface CreateDiscountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountRequest
     */
    value: number;
    /**
     * 
     * @type {DiscountType}
     * @memberof CreateDiscountRequest
     */
    type: DiscountType;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountRequest
     */
    category_id?: string;
}

/**
 * Check if a given object implements the CreateDiscountRequest interface.
 */
export function instanceOfCreateDiscountRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreateDiscountRequestFromJSON(json: any): CreateDiscountRequest {
    return CreateDiscountRequestFromJSONTyped(json, false);
}

export function CreateDiscountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDiscountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
        'type': DiscountTypeFromJSON(json['type']),
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
    };
}

export function CreateDiscountRequestToJSON(value?: CreateDiscountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'type': DiscountTypeToJSON(value.type),
        'gl_code': value.gl_code,
        'description': value.description,
        'category_id': value.category_id,
    };
}

