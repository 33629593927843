import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { PostAccessUrlPayload } from '@/api/postAccessUrl';
import { OrganizationEmploymentTypePayload } from '@/hooks/useOrganizationEmploymentTypesQuery';

import { ShiftToggleType } from './ShiftToggleDateTimeHours';

export class ShiftBlockPayload {
  constructor(payload: ShiftBlockPayload) {
    Object.assign(this, payload);
  }

  name: string;
  position: string;
  employmentType: 'N/A' | OrganizationEmploymentTypePayload;
  facilityName: string;
  startDate: string;
  clockedInTime: string;
  breakTime: string;
  totalTime: string;
  payableTimeOverride: string | null;
  startTimeOverride: string | null;
  endTimeOverride: string | null;
  comment: string | null;
  createdByUserId: string | null;
  photos: {
    clockIn: string | null;
    clockOut: string | null;
  };
}

export interface EditShiftBlockForm {
  startDate: string;
  endDate: string | null;
  shiftHours: string | null;
  shiftMinutes: string | null;
  comment: string | null;
  shiftToggleType: ShiftToggleType;
}

export type CreateShiftBlockForm = {
  userId: string;
} & EditShiftBlockForm;

export class ShiftBlockModel extends ShiftBlockPayload {
  clockInPhotoUrl?: string;
  clockOutPhotoUrl?: string;

  constructor(payload: ShiftBlockPayload, signedUrls?: PostAccessUrlPayload[]) {
    super(payload);
    this.clockInPhotoUrl = signedUrls?.find(
      (signedUrl) => signedUrl.key === this.photos.clockIn
    )?.url;
    this.clockOutPhotoUrl = signedUrls?.find(
      (signedUrl) => signedUrl.key === this.photos.clockOut
    )?.url;
  }

  public getHoursAndMinutes = (
    field: keyof Pick<
      ShiftBlockPayload,
      'clockedInTime' | 'breakTime' | 'totalTime' | 'payableTimeOverride'
    >
  ): { hours: string; minutes: string } => {
    if (this[field] === '—') {
      return { hours: '', minutes: '' };
    }
    const split = this[field]?.split(':') ?? ['', ''];
    // Remove leading 0's ie 05 -> 5 as the server returns hh:mm
    split[0] = parseInt(split[0]).toString();
    split[1] = parseInt(split[1]).toString();
    return {
      hours: split[0],
      minutes: split[1]
    };
  };

  public getHoursAndMinutesDisplay = (
    field: keyof Pick<
      ShiftBlockPayload,
      'clockedInTime' | 'breakTime' | 'totalTime' | 'payableTimeOverride'
    >
  ): string => {
    const { hours, minutes } = this.getHoursAndMinutes(field);
    return !hours && !minutes ? '—' : `${hours} hrs ${minutes} mins`;
  };

  // Either the clockedInTime or payableTimeOverride will be used to initialize
  // the form. Both values can't be valid at the same time. One field will
  // always return the placeholder '—' and the other will return a valid value.
  public toForm = (): EditShiftBlockForm => {
    let shiftHours: string, shiftMinutes: string;
    if (this.payableTimeOverride) {
      const { hours, minutes } = this.getHoursAndMinutes('payableTimeOverride');
      shiftHours = hours;
      shiftMinutes = minutes;
    } else if (this.clockedInTime !== '—') {
      const { hours, minutes } = this.getHoursAndMinutes('clockedInTime');
      shiftHours = hours;
      shiftMinutes = minutes;
    } else {
      const { hours, minutes } = this.getHoursAndMinutes('totalTime');
      shiftHours = hours;
      shiftMinutes = minutes;
    }

    let shiftToggleType: ShiftToggleType;
    if (this.startTimeOverride && this.endTimeOverride) {
      shiftToggleType = ShiftToggleType.datetime;
    } else {
      shiftToggleType = ShiftToggleType.hours;
    }

    return {
      startDate: this.deserializeISOStringToDatetimeLocal(
        this.startTimeOverride || this.startDate
      ),
      endDate: this.endTimeOverride
        ? this.deserializeISOStringToDatetimeLocal(this.endTimeOverride)
        : null,
      comment: this.comment,
      shiftHours,
      shiftMinutes,
      shiftToggleType
    };
  };

  private deserializeISOStringToDatetimeLocal = (date: string): string => {
    // input type="datetime-local" requires a value in the format "YYYY-MM-DDTHH:mm"
    return dayjs(date).format('YYYY-MM-DDTHH:mm');
  };

  public hasManualOverride = () => {
    // This signals the shift block was manually created by a user so we should
    // treat it as a standard shift block. It should be null if it the shift
    // block was created through a user clocking in & out.
    if (this.createdByUserId) {
      return false;
    }
    // Otherwise, if one of these fields aren't null then its a manual override
    return (
      !!this.payableTimeOverride ||
      !!this.startTimeOverride ||
      !!this.endTimeOverride
    );
  };
}
