/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicationType = {
    All: 'ALL',
    ControlledSubstance: 'CONTROLLED_SUBSTANCE'
} as const;
export type MedicationType = typeof MedicationType[keyof typeof MedicationType];


export function MedicationTypeFromJSON(json: any): MedicationType {
    return MedicationTypeFromJSONTyped(json, false);
}

export function MedicationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationType {
    return json as MedicationType;
}

export function MedicationTypeToJSON(value?: MedicationType | null): any {
    return value as any;
}

