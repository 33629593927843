/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FunctionScoreAction = {
    Eating: 'eating',
    OralHygiene: 'oral_hygiene',
    Toileting: 'toileting',
    SitToLying: 'sit_to_lying',
    LyingToSit: 'lying_to_sit',
    SitToStand: 'sit_to_stand',
    BedToChair: 'bed_to_chair',
    Toilet: 'toilet',
    Walking50Feet: 'walking_50_feet',
    Walking150Feet: 'walking_150_feet'
} as const;
export type FunctionScoreAction = typeof FunctionScoreAction[keyof typeof FunctionScoreAction];


export function FunctionScoreActionFromJSON(json: any): FunctionScoreAction {
    return FunctionScoreActionFromJSONTyped(json, false);
}

export function FunctionScoreActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionScoreAction {
    return json as FunctionScoreAction;
}

export function FunctionScoreActionToJSON(value?: FunctionScoreAction | null): any {
    return value as any;
}

