/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutCarePlanEsignRequest
 */
export interface PutCarePlanEsignRequest {
    /**
     * 
     * @type {string}
     * @memberof PutCarePlanEsignRequest
     */
    email_subject: string;
    /**
     * 
     * @type {string}
     * @memberof PutCarePlanEsignRequest
     */
    email_body: string;
}

/**
 * Check if a given object implements the PutCarePlanEsignRequest interface.
 */
export function instanceOfPutCarePlanEsignRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email_subject" in value;
    isInstance = isInstance && "email_body" in value;

    return isInstance;
}

export function PutCarePlanEsignRequestFromJSON(json: any): PutCarePlanEsignRequest {
    return PutCarePlanEsignRequestFromJSONTyped(json, false);
}

export function PutCarePlanEsignRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCarePlanEsignRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_subject': json['email_subject'],
        'email_body': json['email_body'],
    };
}

export function PutCarePlanEsignRequestToJSON(value?: PutCarePlanEsignRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_subject': value.email_subject,
        'email_body': value.email_body,
    };
}

