import React from 'react';
import { styled, Typography } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarContainerProps
} from '@mui/x-data-grid-pro';

interface GridToolbarProps extends GridToolbarContainerProps {}
/**
 * This generic tool bar is used as the components.Toolbar prop for Mui's DataGrid.
 * It appears above the grid as the container for the search, filter button, etc
 * components.
 * */
const GridToolbar: React.FC<GridToolbarProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <StyledGridToolbarContainer {...props}>
      {title && (
        <Typography variant="h5" color="#0A1E28">
          {title}
        </Typography>
      )}
      {children}
    </StyledGridToolbarContainer>
  );
};

const StyledGridToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FBFCFF',
    minHeight: 74,
    padding: '16px',
    borderRadius: 0,
    [theme.breakpoints.down('lg')]: {
      padding: '8px',
      minHeight: 72
    }
  })
);

export default GridToolbar;
