/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentSettingListEntry } from './IncidentSettingListEntry';
import {
    IncidentSettingListEntryFromJSON,
    IncidentSettingListEntryFromJSONTyped,
    IncidentSettingListEntryToJSON,
} from './IncidentSettingListEntry';

/**
 * 
 * @export
 * @interface IncidentSettingFindAllResponse
 */
export interface IncidentSettingFindAllResponse {
    /**
     * 
     * @type {string}
     * @memberof IncidentSettingFindAllResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentSettingFindAllResponse
     */
    last_modified_by: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentSettingFindAllResponse
     */
    last_modified_date: Date;
    /**
     * 
     * @type {Array<IncidentSettingListEntry>}
     * @memberof IncidentSettingFindAllResponse
     */
    data: Array<IncidentSettingListEntry>;
}

/**
 * Check if a given object implements the IncidentSettingFindAllResponse interface.
 */
export function instanceOfIncidentSettingFindAllResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "last_modified_by" in value;
    isInstance = isInstance && "last_modified_date" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IncidentSettingFindAllResponseFromJSON(json: any): IncidentSettingFindAllResponse {
    return IncidentSettingFindAllResponseFromJSONTyped(json, false);
}

export function IncidentSettingFindAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentSettingFindAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'last_modified_by': json['last_modified_by'],
        'last_modified_date': (new Date(json['last_modified_date'])),
        'data': ((json['data'] as Array<any>).map(IncidentSettingListEntryFromJSON)),
    };
}

export function IncidentSettingFindAllResponseToJSON(value?: IncidentSettingFindAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'last_modified_by': value.last_modified_by,
        'last_modified_date': (value.last_modified_date.toISOString()),
        'data': ((value.data as Array<any>).map(IncidentSettingListEntryToJSON)),
    };
}

