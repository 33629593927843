/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentBillingInfo } from './ResidentBillingInfo';
import {
    ResidentBillingInfoFromJSON,
    ResidentBillingInfoFromJSONTyped,
    ResidentBillingInfoToJSON,
} from './ResidentBillingInfo';

/**
 * 
 * @export
 * @interface FindOneResidentBillingInfo200Response
 */
export interface FindOneResidentBillingInfo200Response {
    /**
     * 
     * @type {ResidentBillingInfo}
     * @memberof FindOneResidentBillingInfo200Response
     */
    data?: ResidentBillingInfo;
}

/**
 * Check if a given object implements the FindOneResidentBillingInfo200Response interface.
 */
export function instanceOfFindOneResidentBillingInfo200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOneResidentBillingInfo200ResponseFromJSON(json: any): FindOneResidentBillingInfo200Response {
    return FindOneResidentBillingInfo200ResponseFromJSONTyped(json, false);
}

export function FindOneResidentBillingInfo200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneResidentBillingInfo200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ResidentBillingInfoFromJSON(json['data']),
    };
}

export function FindOneResidentBillingInfo200ResponseToJSON(value?: FindOneResidentBillingInfo200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ResidentBillingInfoToJSON(value.data),
    };
}

