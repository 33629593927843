/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentAnswersEntry } from './AssessmentAnswersEntry';
import {
    AssessmentAnswersEntryFromJSON,
    AssessmentAnswersEntryFromJSONTyped,
    AssessmentAnswersEntryToJSON,
} from './AssessmentAnswersEntry';

/**
 * 
 * @export
 * @interface AssessmentAnswersCategory
 */
export interface AssessmentAnswersCategory {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswersCategory
     */
    name?: string;
    /**
     * 
     * @type {Array<AssessmentAnswersEntry>}
     * @memberof AssessmentAnswersCategory
     */
    entries?: Array<AssessmentAnswersEntry>;
}

/**
 * Check if a given object implements the AssessmentAnswersCategory interface.
 */
export function instanceOfAssessmentAnswersCategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssessmentAnswersCategoryFromJSON(json: any): AssessmentAnswersCategory {
    return AssessmentAnswersCategoryFromJSONTyped(json, false);
}

export function AssessmentAnswersCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentAnswersCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(AssessmentAnswersEntryFromJSON)),
    };
}

export function AssessmentAnswersCategoryToJSON(value?: AssessmentAnswersCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'entries': value.entries === undefined ? undefined : ((value.entries as Array<any>).map(AssessmentAnswersEntryToJSON)),
    };
}

