/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CampaignStepType = {
    Email: 'email',
    Text: 'text',
    Task: 'task'
} as const;
export type CampaignStepType = typeof CampaignStepType[keyof typeof CampaignStepType];


export function CampaignStepTypeFromJSON(json: any): CampaignStepType {
    return CampaignStepTypeFromJSONTyped(json, false);
}

export function CampaignStepTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStepType {
    return json as CampaignStepType;
}

export function CampaignStepTypeToJSON(value?: CampaignStepType | null): any {
    return value as any;
}

