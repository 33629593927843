/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BillEmailStatus = {
    Success: 'Success',
    Failed: 'Failed',
    NotReadyYet: 'NotReadyYet'
} as const;
export type BillEmailStatus = typeof BillEmailStatus[keyof typeof BillEmailStatus];


export function BillEmailStatusFromJSON(json: any): BillEmailStatus {
    return BillEmailStatusFromJSONTyped(json, false);
}

export function BillEmailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillEmailStatus {
    return json as BillEmailStatus;
}

export function BillEmailStatusToJSON(value?: BillEmailStatus | null): any {
    return value as any;
}

