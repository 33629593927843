import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Icon, SxProps, Tooltip } from '@mui/material';

import { AvatarS3PhotoKey } from '@/components/ui/Avatar/AvatarS3PhotoKey';
import { useProspectResidents } from '@/hooks/useProspectResidents';
import { useSnackbar } from '@/hooks/useSnackbar';
import { DealTemperature } from '@/types/crm-campaigns';

import { TempColdLarge } from './TempColdLarge';
import { TempColdSmall } from './TempColdSmall';
import { TempHotLarge } from './TempHotLarge';
import { TempHotSmall } from './TempHotSmall';
import { TempWarmLarge } from './TempWarmLarge';
import { TempWarmSmall } from './TempWarmSmall';

import './styles.css';

type ProspectResidentAvatarTemperatureSize = 'chip' | 'small' | 'large';
// Medium size are on cards that we haven't implemented yet vs list view

interface ProspectResidentAvatarTemperatureProps {
  residentId?: string;
  temperature?: DealTemperature | null;
  s3PhotoKey?: string | null;
  initials?: string;
  size?: ProspectResidentAvatarTemperatureSize;
  disabled?: boolean;
  onSuccess?: (newTemperature: DealTemperature) => void;
  sx?: SxProps;
}

function getSvgByAttributes(
  size: ProspectResidentAvatarTemperatureSize,
  temperature?: DealTemperature | null
): React.ReactNode {
  if (!size || !temperature) {
    return null;
  }

  if (size === 'small' || size === 'chip') {
    switch (temperature) {
      case DealTemperature.Hot:
        return <TempHotSmall />;
      case DealTemperature.Warm:
        return <TempWarmSmall />;
      case DealTemperature.Cold:
        return <TempColdSmall />;
    }
  }

  if (size === 'large') {
    switch (temperature) {
      case DealTemperature.Hot:
        return <TempHotLarge />;
      case DealTemperature.Warm:
        return <TempWarmLarge />;
      case DealTemperature.Cold:
        return <TempColdLarge />;
    }
  }

  console.warn(
    `Invalid size ${size}|${temperature} passed to getSvgByAttributes`
  );
}

export const ProspectResidentAvatarTemperature: React.FC<
  ProspectResidentAvatarTemperatureProps
> = ({
  residentId,
  temperature,
  s3PhotoKey,
  initials,
  size = 'small',
  disabled = false,
  onSuccess,
  sx = {}
}) => {
  const mutate = useProspectResidents().mutations.update;
  const { showSnackbar } = useSnackbar();

  const handleTemperatureChange = (newTemperature: DealTemperature) => {
    if (newTemperature === temperature || disabled || !residentId) {
      return;
    }

    mutate.mutate(
      {
        id: residentId,
        temperature: newTemperature
      },
      {
        onSuccess: () => {
          showSnackbar({
            severity: 'success',
            message: `Successfully set prospect temperature to ${newTemperature}`
          });
          onSuccess?.(newTemperature);
        },
        onError: () => {
          showSnackbar({
            severity: 'error',
            message: 'Failed to set prospect temperature, please try again'
          });
        }
      }
    );
  };

  const mobileMixins = {
    '> svg:first-child ': {
      position: 'absolute !important',
      top: '-6px',
      left: '-6px'
    },
    '& .MuiAvatar-root': {
      width: '32px',
      height: '32px',
      fontSize: '18px'
    },
    ...sx
  };

  return (
    <Box
      className={`prospect-resident-avatar-temperature ${size}`}
      sx={size === 'small' ? mobileMixins : sx}>
      {getSvgByAttributes(size, temperature)}
      <Tooltip arrow placement="left" title="Hot">
        <div
          aria-disabled={temperature === DealTemperature.Hot}
          className="mark-temperature hot"
          onClick={() => handleTemperatureChange(DealTemperature.Hot)}
        />
      </Tooltip>
      <Tooltip arrow placement="left" title="Warm">
        <div
          aria-disabled={temperature === DealTemperature.Warm}
          className="mark-temperature warm"
          onClick={() => handleTemperatureChange(DealTemperature.Warm)}
        />
      </Tooltip>
      <Tooltip arrow placement="left" title="Cold">
        <div
          aria-disabled={temperature === DealTemperature.Cold}
          className="mark-temperature cold"
          onClick={() => handleTemperatureChange(DealTemperature.Cold)}
        />
      </Tooltip>
      <AvatarS3PhotoKey s3PhotoKey={s3PhotoKey}>
        {initials ? initials : <Icon component={PersonIcon} />}
      </AvatarS3PhotoKey>
    </Box>
  );
};
