/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePackageCategory } from './CarePackageCategory';
import {
    CarePackageCategoryFromJSON,
    CarePackageCategoryFromJSONTyped,
    CarePackageCategoryToJSON,
} from './CarePackageCategory';

/**
 * 
 * @export
 * @interface FindAllCarePackageCategories200Response
 */
export interface FindAllCarePackageCategories200Response {
    /**
     * 
     * @type {Array<CarePackageCategory>}
     * @memberof FindAllCarePackageCategories200Response
     */
    data?: Array<CarePackageCategory>;
}

/**
 * Check if a given object implements the FindAllCarePackageCategories200Response interface.
 */
export function instanceOfFindAllCarePackageCategories200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCarePackageCategories200ResponseFromJSON(json: any): FindAllCarePackageCategories200Response {
    return FindAllCarePackageCategories200ResponseFromJSONTyped(json, false);
}

export function FindAllCarePackageCategories200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCarePackageCategories200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(CarePackageCategoryFromJSON)),
    };
}

export function FindAllCarePackageCategories200ResponseToJSON(value?: FindAllCarePackageCategories200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(CarePackageCategoryToJSON)),
    };
}

