/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Resident } from './Resident';
import {
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './Resident';

/**
 * 
 * @export
 * @interface FindAllResidentsWithNoBills200ResponseData
 */
export interface FindAllResidentsWithNoBills200ResponseData {
    /**
     * 
     * @type {boolean}
     * @memberof FindAllResidentsWithNoBills200ResponseData
     */
    can_generate_bills: boolean;
    /**
     * 
     * @type {Array<Resident>}
     * @memberof FindAllResidentsWithNoBills200ResponseData
     */
    without_bills: Array<Resident>;
}

/**
 * Check if a given object implements the FindAllResidentsWithNoBills200ResponseData interface.
 */
export function instanceOfFindAllResidentsWithNoBills200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "can_generate_bills" in value;
    isInstance = isInstance && "without_bills" in value;

    return isInstance;
}

export function FindAllResidentsWithNoBills200ResponseDataFromJSON(json: any): FindAllResidentsWithNoBills200ResponseData {
    return FindAllResidentsWithNoBills200ResponseDataFromJSONTyped(json, false);
}

export function FindAllResidentsWithNoBills200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentsWithNoBills200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'can_generate_bills': json['can_generate_bills'],
        'without_bills': ((json['without_bills'] as Array<any>).map(ResidentFromJSON)),
    };
}

export function FindAllResidentsWithNoBills200ResponseDataToJSON(value?: FindAllResidentsWithNoBills200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_generate_bills': value.can_generate_bills,
        'without_bills': ((value.without_bills as Array<any>).map(ResidentToJSON)),
    };
}

