import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';

import { ResidentLoader } from '../middleware/ResidentLoader';
import { ProtectedRoute } from '../ProtectedRoute';

const ResidentAssessmentCompletion = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentCompletion")); // prettier-ignore
const ResidentAssessmentsPage = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentsPage/ResidentAssessmentsPage")); // prettier-ignore
const ResidentAssessmentsCompletedPage = loadable(() => import("@/pages/ResidentPage/ResidentAssessmentsPage/ResidentAssessmentsCompletedGrid")); // prettier-ignore
const ResidentCarePlanPage = loadable(() => import("@/pages/ResidentPage/ResidentCarePlanPage")); // prettier-ignore
const ResidentFacesheet = loadable(() => import("@/components/ResidentFacesheet")); // prettier-ignore
const ResidentMedicalHistory = loadable(() => import("@/pages/ResidentPage/ResidentMedicalHistory")); // prettier-ignore
const ResidentMedicationHistory = loadable(() => import("@/pages/ResidentPage/ResidentMedications/MedicationHistory")); // prettier-ignore
const ResidentMedicationsList = loadable(() => import("@/pages/ResidentPage/ResidentMedications/MedicationsList")); // prettier-ignore
const ResidentMedicationsMAR = loadable(() => import("@/pages/ResidentPage/ResidentMedications/MedicationsMAR/MedicationsMAR")); // prettier-ignore
const ResidentNotesPage = loadable(() => import("@/pages/ResidentPage/ResidentNotesPage")); // prettier-ignore
const ResidentPage = loadable(() => import("@/pages/ResidentPage")); // prettier-ignore
const ResidentRecordsPage = loadable(() => import("@/pages/ResidentPage/ResidentRecordsPage")); // prettier-ignore
const ResidentTasksPage = loadable(() => import("@/pages/ResidentPage/ResidentTasksPage")); // prettier-ignore
const ResidentVaccinesPage = loadable(() => import("@/pages/ResidentPage/ResidentVaccinesPage")); // prettier-ignore
const ResidentBillingPage = loadable(() => import("@/pages/ResidentPage/ResidentBillingPage")); // prettier-ignore
const ResidentIncidentsV2Page = loadable(() => import("@/pages/ResidentPage/ResidentIncidentsV2Page")); // prettier-ignore

export enum ResidentRoutes {
  Resident = '/resident/:resident_id',
  ResidentBilling = '/resident/:resident_id/billing',
  ResidentTasks = '/resident/:resident_id/tasks',
  ResidentCarePlan = '/resident/:resident_id/care-plan',
  ResidentMedications = '/resident/:resident_id/medications',
  ResidentMedicationsMAR = '/resident/:resident_id/medications/MAR',
  ResidentMedicationHistory = '/medication-task/:medication_id/history/:medication',
  ResidentMedicalHistory = '/resident/:resident_id/vitals',
  ResidentFacesheet = '/resident/:resident_id/facesheet',
  ResidentAssessments = '/resident/:resident_id/assessments',
  ResidentAssessmentsCompleted = '/resident/:resident_id/assessments/completed',
  ResidentAssessmentCompletion = '/resident/:resident_id/assessment/:assessment_id/resident-assessment/:resident_assessment_id/completion/:completion_id',
  ResidentRecords = '/resident/:resident_id/records',
  ResidentNotes = '/resident/:resident_id/notes',
  ResidentIncidents = '/resident/:resident_id/incidents',
  ResidentVaccines = '/resident/:resident_id/vaccines'
}

export const getEhrResidentRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  return [
    {
      path: ResidentRoutes.ResidentMedicationHistory,
      element: (
        <ProtectedRoute component={ResidentMedicationHistory} role="L5" />
      )
    },
    {
      path: ResidentRoutes.Resident,
      element: (
        <ResidentLoader>
          <ResidentPage />
        </ResidentLoader>
      ),
      children: [
        {
          index: true,
          path: ResidentRoutes.ResidentTasks,
          element: <ProtectedRoute role="L4" component={ResidentTasksPage} />
        },
        {
          path: ResidentRoutes.ResidentCarePlan,
          element: <ResidentCarePlanPage />
        },
        {
          path: ResidentRoutes.ResidentMedications,
          element: (
            <ProtectedRoute role="L5" component={ResidentMedicationsList} />
          )
        },
        {
          path: ResidentRoutes.ResidentMedicationsMAR,
          element: (
            <ProtectedRoute role="L5" component={ResidentMedicationsMAR} />
          )
        },
        {
          path: ResidentRoutes.ResidentMedicalHistory,
          element: (
            <ProtectedRoute role="L5" component={ResidentMedicalHistory} />
          )
        },
        {
          path: ResidentRoutes.ResidentFacesheet,
          element: <ResidentFacesheet />
        },
        {
          path: ResidentRoutes.ResidentAssessments,
          element: (
            <ProtectedRoute role="L4" component={ResidentAssessmentsPage} />
          )
        },
        {
          path: ResidentRoutes.ResidentAssessmentsCompleted,
          element: (
            <ProtectedRoute
              role="L4"
              component={ResidentAssessmentsCompletedPage}
            />
          )
        },
        {
          path: ResidentRoutes.ResidentVaccines,
          element: <ProtectedRoute role="L4" component={ResidentVaccinesPage} />
        },
        ...(currentUser.isBillingEnabledForUser()
          ? [
              {
                path: ResidentRoutes.ResidentBilling,
                element: (
                  <ProtectedRoute role="L4" component={ResidentBillingPage} />
                )
              }
            ]
          : []),
        {
          path: ResidentRoutes.ResidentRecords,
          element: <ResidentRecordsPage />
        },
        {
          path: ResidentRoutes.ResidentNotes,
          element: <ResidentNotesPage />
        },
        {
          path: ResidentRoutes.ResidentIncidents,
          element: (
            <ProtectedRoute role="L4" component={ResidentIncidentsV2Page} />
          )
        }
      ]
    },
    {
      path: ResidentRoutes.ResidentAssessmentCompletion,
      element: (
        <ProtectedRoute component={ResidentAssessmentCompletion} role="L4" />
      )
    }
  ];
};
