/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionDetailsDetailsSelectionsInner } from './BidirectionalActionDetailsDetailsSelectionsInner';
import {
    BidirectionalActionDetailsDetailsSelectionsInnerFromJSON,
    BidirectionalActionDetailsDetailsSelectionsInnerFromJSONTyped,
    BidirectionalActionDetailsDetailsSelectionsInnerToJSON,
} from './BidirectionalActionDetailsDetailsSelectionsInner';

/**
 * 
 * @export
 * @interface BidirectionalActionDetailsDetails
 */
export interface BidirectionalActionDetailsDetails {
    /**
     * 
     * @type {Array<BidirectionalActionDetailsDetailsSelectionsInner>}
     * @memberof BidirectionalActionDetailsDetails
     */
    selections: Array<BidirectionalActionDetailsDetailsSelectionsInner>;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    message?: string;
}

/**
 * Check if a given object implements the BidirectionalActionDetailsDetails interface.
 */
export function instanceOfBidirectionalActionDetailsDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "selections" in value;

    return isInstance;
}

export function BidirectionalActionDetailsDetailsFromJSON(json: any): BidirectionalActionDetailsDetails {
    return BidirectionalActionDetailsDetailsFromJSONTyped(json, false);
}

export function BidirectionalActionDetailsDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionDetailsDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selections': ((json['selections'] as Array<any>).map(BidirectionalActionDetailsDetailsSelectionsInnerFromJSON)),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function BidirectionalActionDetailsDetailsToJSON(value?: BidirectionalActionDetailsDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selections': ((value.selections as Array<any>).map(BidirectionalActionDetailsDetailsSelectionsInnerToJSON)),
        'date': value.date,
        'time': value.time,
        'message': value.message,
    };
}

