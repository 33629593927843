/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DynamicAudienceSettings
 */
export interface DynamicAudienceSettings {
    /**
     * 
     * @type {boolean}
     * @memberof DynamicAudienceSettings
     */
    apfm_prospects: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DynamicAudienceSettings
     */
    apfm_close_connections: boolean;
}

/**
 * Check if a given object implements the DynamicAudienceSettings interface.
 */
export function instanceOfDynamicAudienceSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apfm_prospects" in value;
    isInstance = isInstance && "apfm_close_connections" in value;

    return isInstance;
}

export function DynamicAudienceSettingsFromJSON(json: any): DynamicAudienceSettings {
    return DynamicAudienceSettingsFromJSONTyped(json, false);
}

export function DynamicAudienceSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicAudienceSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apfm_prospects': json['apfm_prospects'],
        'apfm_close_connections': json['apfm_close_connections'],
    };
}

export function DynamicAudienceSettingsToJSON(value?: DynamicAudienceSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apfm_prospects': value.apfm_prospects,
        'apfm_close_connections': value.apfm_close_connections,
    };
}

