/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LifecycleStep } from './LifecycleStep';
import {
    LifecycleStepFromJSON,
    LifecycleStepFromJSONTyped,
    LifecycleStepToJSON,
} from './LifecycleStep';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface CrmReferralSourceListEntry
 */
export interface CrmReferralSourceListEntry {
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    source_type: string;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    company_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrmReferralSourceListEntry
     */
    is_paid: boolean;
    /**
     * 
     * @type {LifecycleStep}
     * @memberof CrmReferralSourceListEntry
     */
    lifecycle_step: LifecycleStep;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    last_referral_date: string | null;
    /**
     * 
     * @type {RelationType}
     * @memberof CrmReferralSourceListEntry
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof CrmReferralSourceListEntry
     */
    relation_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrmReferralSourceListEntry
     */
    is_added_to_audience?: boolean;
}

/**
 * Check if a given object implements the CrmReferralSourceListEntry interface.
 */
export function instanceOfCrmReferralSourceListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "source_type" in value;
    isInstance = isInstance && "company_name" in value;
    isInstance = isInstance && "is_paid" in value;
    isInstance = isInstance && "lifecycle_step" in value;
    isInstance = isInstance && "last_referral_date" in value;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;

    return isInstance;
}

export function CrmReferralSourceListEntryFromJSON(json: any): CrmReferralSourceListEntry {
    return CrmReferralSourceListEntryFromJSONTyped(json, false);
}

export function CrmReferralSourceListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmReferralSourceListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'source_type': json['source_type'],
        'company_name': json['company_name'],
        'is_paid': json['is_paid'],
        'lifecycle_step': LifecycleStepFromJSON(json['lifecycle_step']),
        'last_referral_date': json['last_referral_date'],
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
        'is_added_to_audience': !exists(json, 'is_added_to_audience') ? undefined : json['is_added_to_audience'],
    };
}

export function CrmReferralSourceListEntryToJSON(value?: CrmReferralSourceListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'source_type': value.source_type,
        'company_name': value.company_name,
        'is_paid': value.is_paid,
        'lifecycle_step': LifecycleStepToJSON(value.lifecycle_step),
        'last_referral_date': value.last_referral_date,
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
        'is_added_to_audience': value.is_added_to_audience,
    };
}

