/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmProspectResponse
 */
export interface ApfmProspectResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    profile_age?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    profile_marital_status?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_contact_second_resident_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmProspectResponse
     */
    profile_veteran?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    desired_zip?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    tour_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    profile_timeframe?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_home_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_work_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_cell_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmProspectResponse
     */
    resident_fax_phone?: string;
}

/**
 * Check if a given object implements the ApfmProspectResponse interface.
 */
export function instanceOfApfmProspectResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmProspectResponseFromJSON(json: any): ApfmProspectResponse {
    return ApfmProspectResponseFromJSONTyped(json, false);
}

export function ApfmProspectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmProspectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_first_name': !exists(json, 'resident_first_name') ? undefined : json['resident_first_name'],
        'resident_last_name': !exists(json, 'resident_last_name') ? undefined : json['resident_last_name'],
        'profile_age': !exists(json, 'profile_age') ? undefined : json['profile_age'],
        'profile_marital_status': !exists(json, 'profile_marital_status') ? undefined : json['profile_marital_status'],
        'resident_contact_second_resident_name': !exists(json, 'resident_contact_second_resident_name') ? undefined : json['resident_contact_second_resident_name'],
        'profile_veteran': !exists(json, 'profile_veteran') ? undefined : json['profile_veteran'],
        'desired_zip': !exists(json, 'desired_zip') ? undefined : json['desired_zip'],
        'tour_date': !exists(json, 'tour_date') ? undefined : json['tour_date'],
        'profile_timeframe': !exists(json, 'profile_timeframe') ? undefined : json['profile_timeframe'],
        'resident_home_phone': !exists(json, 'resident_home_phone') ? undefined : json['resident_home_phone'],
        'resident_work_phone': !exists(json, 'resident_work_phone') ? undefined : json['resident_work_phone'],
        'resident_cell_phone': !exists(json, 'resident_cell_phone') ? undefined : json['resident_cell_phone'],
        'resident_fax_phone': !exists(json, 'resident_fax_phone') ? undefined : json['resident_fax_phone'],
    };
}

export function ApfmProspectResponseToJSON(value?: ApfmProspectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_first_name': value.resident_first_name,
        'resident_last_name': value.resident_last_name,
        'profile_age': value.profile_age,
        'profile_marital_status': value.profile_marital_status,
        'resident_contact_second_resident_name': value.resident_contact_second_resident_name,
        'profile_veteran': value.profile_veteran,
        'desired_zip': value.desired_zip,
        'tour_date': value.tour_date,
        'profile_timeframe': value.profile_timeframe,
        'resident_home_phone': value.resident_home_phone,
        'resident_work_phone': value.resident_work_phone,
        'resident_cell_phone': value.resident_cell_phone,
        'resident_fax_phone': value.resident_fax_phone,
    };
}

