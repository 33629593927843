/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillEmailStatus } from './BillEmailStatus';
import {
    BillEmailStatusFromJSON,
    BillEmailStatusFromJSONTyped,
    BillEmailStatusToJSON,
} from './BillEmailStatus';

/**
 * 
 * @export
 * @interface SendBillEmailResponse
 */
export interface SendBillEmailResponse {
    /**
     * 
     * @type {BillEmailStatus}
     * @memberof SendBillEmailResponse
     */
    status: BillEmailStatus;
}

/**
 * Check if a given object implements the SendBillEmailResponse interface.
 */
export function instanceOfSendBillEmailResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SendBillEmailResponseFromJSON(json: any): SendBillEmailResponse {
    return SendBillEmailResponseFromJSONTyped(json, false);
}

export function SendBillEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendBillEmailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': BillEmailStatusFromJSON(json['status']),
    };
}

export function SendBillEmailResponseToJSON(value?: SendBillEmailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': BillEmailStatusToJSON(value.status),
    };
}

