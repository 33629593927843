import React from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { HTTPError } from 'ky';

import { fetchCRM } from '@/adapters/fetchExaCare';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom
} from '@/hooks/useFacilitiesQuery';

export interface FacilityAuthenticatedEmail {
  id: string;
  email: string;
  reauthenticate: boolean;
}

export const FACILITY_AUTHENTICATED_EMAILS_QUERY_KEY =
  'facilityAuthenticatedEmails';

export const useFacilityAuthenticatedEmailQuery = ({
  enabled = true,
  ...options
}: UseQueryOptions<
  FacilityAuthenticatedEmail[],
  HTTPError,
  FacilityAuthenticatedEmail
> = {}) => {
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom);
  const currentUserFacilityId = useCurrentUser().data?.facility_id;

  const facilityId = React.useMemo(() => {
    if (selectedFacilityId === ALL_FACILITIES) {
      return currentUserFacilityId;
    }

    return selectedFacilityId;
  }, [selectedFacilityId, currentUserFacilityId]);

  return useQuery<
    FacilityAuthenticatedEmail[],
    HTTPError,
    FacilityAuthenticatedEmail
  >(
    [FACILITY_AUTHENTICATED_EMAILS_QUERY_KEY, facilityId],
    () =>
      fetchCRM.get<FacilityAuthenticatedEmail[]>(
        `/facilities/${facilityId}/authenticated-emails`
      ),
    {
      select: (data) =>
        data?.filter((item) => !item.reauthenticate)[0] ?? data?.[0],
      enabled: !!facilityId && enabled,
      ...options
    }
  );
};
