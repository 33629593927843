import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import fetchExaCare, { fetchResidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { SEARCH_RESIDENT_DOCTORS_QUERY_KEY } from '@/pages/Pharmacy/components/useResidentDoctorsQuery';

import { FacilityPayload } from './useFacilitiesQuery';

export interface Doctor {
  address?: string;
  fax?: string;
  id: string;
  is_primary?: boolean;
  name: string;
  primary_phone: string | null;
  secondary_phone?: string;
  email?: string;
  npi_number?: string;
  dea_number?: string;
  type: string;
  item_id?: string;
  facility_ids?: string[] | null;
  facilities?: FacilityPayload[];
}

export const FIND_ALL_DOCTORS_QUERY_KEY = 'findAllDoctors';
export const FIND_ALL_RESIDENT_DOCTORS_QUERY_KEY = 'findAllResidentDoctors';

export function useDoctorsQuery() {
  const invalidate = () => {
    queryClient.invalidateQueries([FIND_ALL_DOCTORS_QUERY_KEY]);
    queryClient.invalidateQueries([FIND_ALL_RESIDENT_DOCTORS_QUERY_KEY]);
    queryClient.invalidateQueries([SEARCH_RESIDENT_DOCTORS_QUERY_KEY]);
  };
  return {
    invalidate,

    findAll: (options: UseQueryOptions = {}) => {
      return useQuery(
        [FIND_ALL_DOCTORS_QUERY_KEY],
        () => {
          return fetchExaCare.get<Doctor[]>('/doctors');
        },
        options as any
      );
    },

    mutations: (options: UseMutationOptions = {}) => ({
      create: useMutation(
        (payload: Omit<Doctor, 'id'>) => {
          return fetchExaCare.post('/doctors', payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      ),
      update: useMutation(
        (payload: Doctor) => {
          return fetchExaCare.put(`/doctors/${payload.id}`, payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      ),
      delete: useMutation(
        (payload: Pick<Doctor, 'id'>) => {
          return fetchExaCare.delete(`/doctors/${payload.id}`);
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}

export function useResidentDoctorsQuery(
  residentId: string,
  options: UseQueryOptions = {}
) {
  const QUERY_KEY = [FIND_ALL_RESIDENT_DOCTORS_QUERY_KEY, residentId];
  const invalidate = () => queryClient.invalidateQueries(QUERY_KEY);
  return Object.assign(
    useQuery(
      QUERY_KEY,
      () => fetchResidents.get<Doctor[]>(`/residents/${residentId}/doctors`),
      options as any
    ),
    {
      invalidate,
      mutations: {
        update: useMutation(
          (doctors: Doctor[]) => {
            return fetchResidents.put(
              `/residents/${residentId}/doctors`,
              doctors.map((v) => ({
                doctor_id: v.item_id,
                is_primary: v.is_primary
              }))
            );
          },
          { onSuccess: invalidate }
        )
      }
    }
  );
}
