import React from 'react';

import { CarePlanTaskInstanceModel } from '@/models/CarePlanTaskInstanceModel';
import { ResidentCarePlanModel } from '@/pages/ResidentPage/ResidentCarePlanPage/ResidentCarePlanModel';

import TaskExtensionIndicator from '../MedicationIndicators/TaskExtensionIndicator';

interface CarePlanIndicatorsProps {
  model: ResidentCarePlanModel | CarePlanTaskInstanceModel;
}

export default function CarePlanIndicators(props: CarePlanIndicatorsProps) {
  const { model } = props;
  return <TaskExtensionIndicator model={model} />;
}
