/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BulkUpdateV2RequestData } from './BulkUpdateV2RequestData';
import {
    BulkUpdateV2RequestDataFromJSON,
    BulkUpdateV2RequestDataFromJSONTyped,
    BulkUpdateV2RequestDataToJSON,
} from './BulkUpdateV2RequestData';

/**
 * 
 * @export
 * @interface BulkUpdateV2Request
 */
export interface BulkUpdateV2Request {
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateV2Request
     */
    type: BulkUpdateV2RequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateV2Request
     */
    prospect_ids: Array<string>;
    /**
     * 
     * @type {BulkUpdateV2RequestData}
     * @memberof BulkUpdateV2Request
     */
    data?: BulkUpdateV2RequestData;
}


/**
 * @export
 */
export const BulkUpdateV2RequestTypeEnum = {
    Archive: 'ARCHIVE',
    Lifecycle: 'LIFECYCLE',
    LeadOwner: 'LEAD_OWNER',
    Temperature: 'TEMPERATURE'
} as const;
export type BulkUpdateV2RequestTypeEnum = typeof BulkUpdateV2RequestTypeEnum[keyof typeof BulkUpdateV2RequestTypeEnum];


/**
 * Check if a given object implements the BulkUpdateV2Request interface.
 */
export function instanceOfBulkUpdateV2Request(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "prospect_ids" in value;

    return isInstance;
}

export function BulkUpdateV2RequestFromJSON(json: any): BulkUpdateV2Request {
    return BulkUpdateV2RequestFromJSONTyped(json, false);
}

export function BulkUpdateV2RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateV2Request {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'prospect_ids': json['prospect_ids'],
        'data': !exists(json, 'data') ? undefined : BulkUpdateV2RequestDataFromJSON(json['data']),
    };
}

export function BulkUpdateV2RequestToJSON(value?: BulkUpdateV2Request | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'prospect_ids': value.prospect_ids,
        'data': BulkUpdateV2RequestDataToJSON(value.data),
    };
}

