import React, { MouseEventHandler } from 'react';
import { FieldArrayPath, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Add, Delete, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from '../Accordion';
import { FormHeader } from '../FormHeader';
import { NumberOfDoseUnits } from '../MedicationDrawer/NumberOfDoseUnits';

import { ScheduleFormData } from './FrequencyTimeForm';
import { TypeAndTimeInput } from './TypeAndTimeInput';

export const getScheduleFieldNamesMap = (name: string, index: number) => {
  return {
    startTime: `${name}.${index}.execution_window_start_time`,
    endTime: `${name}.${index}.execution_window_end_time`,
    type: `${name}.${index}.type`,
    partOfDay: `${name}.${index}.partOfDay`,
    numberOfDoseUnits: `${name}.${index}.number_of_dose_units`,
    slidingScaleDoseRanges: `${name}.${index}.dose_ranges`
  };
};

interface CustomFrequencyDayFormProps {
  day: string;
  fieldArrayName: FieldArrayPath<{ schedule: ScheduleFormData }>;
  formMethods: UseFormReturn;
  scheduleHasSameDoseUnits: boolean;
  numberOfDoseUnits: number | null;
  isMedicationsDrawer: boolean;
  disabled?: boolean;
}

export const CustomFrequencyDayForm = ({
  day,
  fieldArrayName,
  formMethods,
  scheduleHasSameDoseUnits,
  numberOfDoseUnits,
  isMedicationsDrawer = false,
  disabled = false
}: CustomFrequencyDayFormProps) => {
  const { fields, append, remove, update, replace } = useFieldArray({
    control: formMethods.control,
    name: fieldArrayName,
    keyName: '_id'
  });

  // Handles the logic for setting the individual FrequencyTimeFormData to the
  // "master" freq.number_of_dose_units
  React.useEffect(() => {
    if (scheduleHasSameDoseUnits) {
      const fieldsWithSameDosage = fields.map((item) => ({
        ...item,
        number_of_dose_units: numberOfDoseUnits
      }));
      replace(fieldsWithSameDosage);
    }
  }, [scheduleHasSameDoseUnits, numberOfDoseUnits]);

  const handleOnAddTimeClick: React.MouseEventHandler<
    HTMLButtonElement
  > = (): void => {
    append({ number_of_dose_units: numberOfDoseUnits } as any);
  };

  const handleOnDeleteTimeClick = (index: number): void => {
    remove(index);
  };

  return (
    <StyledWrapper
      isMedicationsDrawer={isMedicationsDrawer}
      day={day}
      handleOnAddTimeClick={handleOnAddTimeClick}
      disabled={disabled}>
      {fields.map((field, index) => {
        const fieldNames = getScheduleFieldNamesMap(fieldArrayName, index);
        return (
          <React.Fragment key={field._id}>
            <Stack direction="row" gap={1} alignItems="flex-start">
              <Grid container columnSpacing={1} rowSpacing={2} width="100%">
                <TypeAndTimeInput
                  fieldNames={fieldNames}
                  formMethods={formMethods}
                  // @ts-ignore #HACK: Force fields re-render. If this didn't
                  // exist, edits to these fields will be lost when updating
                  // number_of_dose_units because its copying the old fields
                  // item
                  handleOnChange={() => update()}
                  disabled={disabled}
                />
                {!scheduleHasSameDoseUnits && (
                  <NumberOfDoseUnits
                    formMethods={formMethods}
                    name={fieldNames.numberOfDoseUnits}
                    onChange={(e) => {
                      update(index, {
                        ...field,
                        number_of_dose_units: e.currentTarget
                          .value as unknown as number
                      });
                    }}
                  />
                )}
              </Grid>
              {fields.length > 1 && !disabled && (
                // Prevent user from saving an empty schedule day
                <IconButton
                  edge="end"
                  sx={{ top: 7 }}
                  onClick={() => {
                    handleOnDeleteTimeClick(index);
                  }}>
                  <Delete />
                </IconButton>
              )}
            </Stack>
            {index !== fields.length - 1 && (
              <Divider sx={{ borderBottomWidth: '1.5px', my: 2 }} />
            )}
          </React.Fragment>
        );
      })}
    </StyledWrapper>
  );
};

type StyledWrapperProps = React.PropsWithChildren<{
  isMedicationsDrawer: boolean;
  day: string;
  handleOnAddTimeClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}>;

const StyledWrapper: React.FC<StyledWrapperProps> = ({
  isMedicationsDrawer,
  handleOnAddTimeClick,
  day,
  children,
  disabled = false
}) => {
  const [expanded, setExpanded] = React.useState(true);

  if (
    FeatureFlagService.isEnabled(ExacareFeature.MEDICATION_TASK_REGIMENS) &&
    isMedicationsDrawer
  ) {
    return (
      <>
        <Divider orientation="horizontal" flexItem />
        <Stack
          p="8px 0 16px 0"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography fontWeight={500} fontSize="16px" color="secondary">
            {day}
          </Typography>
          {!disabled && (
            <Button
              size="medium"
              variant="text"
              onClick={handleOnAddTimeClick}
              startIcon={<Add />}>
              Add Time
            </Button>
          )}
        </Stack>
        {children}
      </>
    );
  }

  return (
    <StyledAccordion expanded={expanded}>
      <StyledAccordionSummary
        sx={{
          height: 62,
          '& .MuiAccordionSummary-expandIconWrapper': {
            order: -1
          }
        }}
        expandIcon={
          <IconButton onClick={() => setExpanded((state) => !state)} edge="end">
            <ExpandMore />
          </IconButton>
        }>
        <Box display="flex" justifyContent="space-between" width="100%">
          <FormHeader text={day} sx={{ paddingLeft: '12px' }} />
          {expanded && !disabled && (
            <Button
              variant="text"
              color="primary"
              startIcon={<Add />}
              onClick={handleOnAddTimeClick}>
              Add time
            </Button>
          )}
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
