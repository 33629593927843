import { Frequency, Options, RRule } from 'rrule';

import { FrequencyTimeFormData } from '../components/FrequencyTimeForm/FrequencyTimeForm';

export const transformFrequencyTimeFormDataToRRule = (
  frequency: FrequencyTimeFormData | null | undefined,
  options: Partial<Options> = {}
): RRule | null => {
  // Safeguard against transmuting custom frequencies into an RRule as this
  // is specific to our application. See interface FrequencyOption in
  // src/components/FrequencyTimeForm/options.tsx
  if (
    (!frequency?.freq ||
      frequency?.freq === 'custom' ||
      frequency?.freq === 'informational') &&
    !options?.freq
  ) {
    return null;
  }

  if (frequency?.freq === 'one-time-task') {
    options.count = 1;
    options.freq = Frequency.DAILY;
    delete options.until;
  }

  if (frequency?.freq === 'prn') {
    options.count = 0;
    options.interval = 0;
    options.freq = Frequency.DAILY;
  }

  const defaultOptions: Partial<Options> = {
    freq: frequency?.freq as Frequency,
    ...options
  };

  if (frequency?.freq === Frequency.DAILY) {
    defaultOptions.interval = frequency?.interval;
  } else if (frequency?.freq === Frequency.WEEKLY) {
    defaultOptions.interval = frequency?.interval;
    defaultOptions.byweekday = frequency?.byweekday;
  } else if (frequency?.freq === Frequency.MONTHLY) {
    defaultOptions.bymonthday = frequency?.bymonthday;
  }

  return new RRule(defaultOptions);
};
