/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrescriptionDrugInfo
 */
export interface PrescriptionDrugInfo {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    DrugNameDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    MedStrength?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    MedStrengthUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    DoseFormDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    RouteDesc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionDrugInfo
     */
    ControlledSubstanceNarcoticsInd?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    DispensableDrugID?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrugInfo
     */
    DispensableDrugDesc?: string;
}

/**
 * Check if a given object implements the PrescriptionDrugInfo interface.
 */
export function instanceOfPrescriptionDrugInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrescriptionDrugInfoFromJSON(json: any): PrescriptionDrugInfo {
    return PrescriptionDrugInfoFromJSONTyped(json, false);
}

export function PrescriptionDrugInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionDrugInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DrugNameDesc': !exists(json, 'DrugNameDesc') ? undefined : json['DrugNameDesc'],
        'MedStrength': !exists(json, 'MedStrength') ? undefined : json['MedStrength'],
        'MedStrengthUnit': !exists(json, 'MedStrengthUnit') ? undefined : json['MedStrengthUnit'],
        'DoseFormDesc': !exists(json, 'DoseFormDesc') ? undefined : json['DoseFormDesc'],
        'RouteDesc': !exists(json, 'RouteDesc') ? undefined : json['RouteDesc'],
        'ControlledSubstanceNarcoticsInd': !exists(json, 'ControlledSubstanceNarcoticsInd') ? undefined : json['ControlledSubstanceNarcoticsInd'],
        'DispensableDrugID': !exists(json, 'DispensableDrugID') ? undefined : json['DispensableDrugID'],
        'DispensableDrugDesc': !exists(json, 'DispensableDrugDesc') ? undefined : json['DispensableDrugDesc'],
    };
}

export function PrescriptionDrugInfoToJSON(value?: PrescriptionDrugInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DrugNameDesc': value.DrugNameDesc,
        'MedStrength': value.MedStrength,
        'MedStrengthUnit': value.MedStrengthUnit,
        'DoseFormDesc': value.DoseFormDesc,
        'RouteDesc': value.RouteDesc,
        'ControlledSubstanceNarcoticsInd': value.ControlledSubstanceNarcoticsInd,
        'DispensableDrugID': value.DispensableDrugID,
        'DispensableDrugDesc': value.DispensableDrugDesc,
    };
}

