/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecutionsHistory } from './ExecutionsHistory';
import {
    ExecutionsHistoryFromJSON,
    ExecutionsHistoryFromJSONTyped,
    ExecutionsHistoryToJSON,
} from './ExecutionsHistory';

/**
 * 
 * @export
 * @interface ExecutionsAvailable
 */
export interface ExecutionsAvailable {
    /**
     * 
     * @type {string}
     * @memberof ExecutionsAvailable
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionsAvailable
     */
    facility_id: string;
    /**
     * 
     * @type {number}
     * @memberof ExecutionsAvailable
     */
    executions_available: number;
    /**
     * 
     * @type {string}
     * @memberof ExecutionsAvailable
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionsAvailable
     */
    updatedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionsAvailable
     */
    reset_enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExecutionsAvailable
     */
    last_reset_at: string;
    /**
     * 
     * @type {number}
     * @memberof ExecutionsAvailable
     */
    periodic_executions_limit: number;
    /**
     * 
     * @type {Array<ExecutionsHistory>}
     * @memberof ExecutionsAvailable
     */
    executions_history?: Array<ExecutionsHistory>;
}

/**
 * Check if a given object implements the ExecutionsAvailable interface.
 */
export function instanceOfExecutionsAvailable(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "executions_available" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "reset_enabled" in value;
    isInstance = isInstance && "last_reset_at" in value;
    isInstance = isInstance && "periodic_executions_limit" in value;

    return isInstance;
}

export function ExecutionsAvailableFromJSON(json: any): ExecutionsAvailable {
    return ExecutionsAvailableFromJSONTyped(json, false);
}

export function ExecutionsAvailableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionsAvailable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'executions_available': json['executions_available'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'reset_enabled': json['reset_enabled'],
        'last_reset_at': json['last_reset_at'],
        'periodic_executions_limit': json['periodic_executions_limit'],
        'executions_history': !exists(json, 'executions_history') ? undefined : ((json['executions_history'] as Array<any>).map(ExecutionsHistoryFromJSON)),
    };
}

export function ExecutionsAvailableToJSON(value?: ExecutionsAvailable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'executions_available': value.executions_available,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'reset_enabled': value.reset_enabled,
        'last_reset_at': value.last_reset_at,
        'periodic_executions_limit': value.periodic_executions_limit,
        'executions_history': value.executions_history === undefined ? undefined : ((value.executions_history as Array<any>).map(ExecutionsHistoryToJSON)),
    };
}

