/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingClinicallyComplex = {
    Pneumonia: 'pneumonia',
    HemiplegiaHemiparesis: 'hemiplegia_hemiparesis',
    OpenLesionsSurgicalWounds: 'open_lesions_surgical_wounds',
    Burns: 'burns',
    Chemotherapy14days: 'chemotherapy_14days',
    OxygenTherapy14days: 'oxygen_therapy_14days',
    IvMedication14days: 'iv_medication_14days',
    Transfusions14days: 'transfusions_14days'
} as const;
export type MedicareNursingClinicallyComplex = typeof MedicareNursingClinicallyComplex[keyof typeof MedicareNursingClinicallyComplex];


export function MedicareNursingClinicallyComplexFromJSON(json: any): MedicareNursingClinicallyComplex {
    return MedicareNursingClinicallyComplexFromJSONTyped(json, false);
}

export function MedicareNursingClinicallyComplexFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingClinicallyComplex {
    return json as MedicareNursingClinicallyComplex;
}

export function MedicareNursingClinicallyComplexToJSON(value?: MedicareNursingClinicallyComplex | null): any {
    return value as any;
}

