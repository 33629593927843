import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover
} from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { UserModel } from '@/models/UserModel';

import { useCachedPin } from '../TaskModals/useCachedPin';
import { AvatarS3PhotoKey } from '../ui/Avatar/AvatarS3PhotoKey';

interface RecentUsersPopoverProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLDivElement;
  recentUsers: UserModel[];
  isLoading: boolean;
}

export const RecentUsersPopover: React.FC<RecentUsersPopoverProps> = ({
  isOpen,
  setIsOpen,
  anchorEl,
  recentUsers,
  isLoading
}) => {
  const currentUser = useCurrentUser().data!;
  const { logout } = useAuth0();
  const recentUsersWithoutCurrentUser = recentUsers.filter(
    (user) => user.id !== currentUser.id
  );
  const { clearCachedPin } = useCachedPin();
  const handleClickRecentUser = (user: UserModel) => {
    clearCachedPin();
    // See Layout component for consumption of this local storage variable
    localStorage.setItem('login_hint', user.email);
    logout({
      returnTo: window.location.origin
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      PaperProps={{
        onMouseLeave: () => setIsOpen(false),
        sx: {
          boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
          borderRadius: '8px'
        }
      }}
      onClose={() => setIsOpen(false)}>
      <List>
        {recentUsersWithoutCurrentUser.length === 0 ? (
          <ListItem sx={{ pt: '2px', pb: '2px' }}>
            <ListItemText primary="No recent users" />
          </ListItem>
        ) : (
          recentUsersWithoutCurrentUser.map((user) => (
            <ListItemButton
              key={user.id}
              onClick={() => handleClickRecentUser(user)}>
              <ListItemAvatar>
                <AvatarS3PhotoKey s3PhotoKey={user.s3_photo_key}>
                  {user.getInitials()}
                </AvatarS3PhotoKey>
              </ListItemAvatar>
              <ListItemText
                primary={user.getFullName()}
                secondary={user.getPosition()}
              />
            </ListItemButton>
          ))
        )}
      </List>
    </Popover>
  );
};
