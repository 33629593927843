import React from 'react';
import { GridApi } from '@mui/x-data-grid-pro';

import {
  scopeHandleFilterOnChange,
  scopeIsFilterChecked
} from '@/utils/dataGridFilterItem';

interface FilterItem {
  label: string;
  value: string;
}

export const medicationInventoryHistoryTypeFilters: FilterItem[] = [
  {
    label: 'All Meds',
    value: 'ALL'
  },
  {
    label: 'Controlled Substances Only',
    value: 'CONTROLLED_SUBSTANCE'
  }
];

export const useMedicationInventoryHistoryListFilters = (
  gridApiRef: React.MutableRefObject<GridApi>
) => {
  return {
    handleFilterOnChange: scopeHandleFilterOnChange(gridApiRef),
    isFilterChecked: scopeIsFilterChecked(gridApiRef)
  };
};
