/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CriteriaQualifier } from './CriteriaQualifier';
import {
    CriteriaQualifierFromJSON,
    CriteriaQualifierFromJSONTyped,
    CriteriaQualifierToJSON,
} from './CriteriaQualifier';

/**
 * 
 * @export
 * @interface ScreenerSpecialCriteria
 */
export interface ScreenerSpecialCriteria {
    /**
     * 
     * @type {string}
     * @memberof ScreenerSpecialCriteria
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerSpecialCriteria
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerSpecialCriteria
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ScreenerSpecialCriteria
     */
    identifier: ScreenerSpecialCriteriaIdentifierEnum;
    /**
     * 
     * @type {CriteriaQualifier}
     * @memberof ScreenerSpecialCriteria
     */
    qualifier?: CriteriaQualifier;
    /**
     * 
     * @type {boolean}
     * @memberof ScreenerSpecialCriteria
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScreenerSpecialCriteria
     */
    requires_payment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScreenerSpecialCriteria
     */
    is_paid?: boolean;
}


/**
 * @export
 */
export const ScreenerSpecialCriteriaIdentifierEnum = {
    MedicationPricing: 'MEDICATION_PRICING',
    SexOffenderCheck: 'SEX_OFFENDER_CHECK',
    FollowupAppointments: 'FOLLOWUP_APPOINTMENTS'
} as const;
export type ScreenerSpecialCriteriaIdentifierEnum = typeof ScreenerSpecialCriteriaIdentifierEnum[keyof typeof ScreenerSpecialCriteriaIdentifierEnum];


/**
 * Check if a given object implements the ScreenerSpecialCriteria interface.
 */
export function instanceOfScreenerSpecialCriteria(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "display_name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function ScreenerSpecialCriteriaFromJSON(json: any): ScreenerSpecialCriteria {
    return ScreenerSpecialCriteriaFromJSONTyped(json, false);
}

export function ScreenerSpecialCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerSpecialCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'display_name': json['display_name'],
        'description': json['description'],
        'identifier': json['identifier'],
        'qualifier': !exists(json, 'qualifier') ? undefined : CriteriaQualifierFromJSON(json['qualifier']),
        'enabled': json['enabled'],
        'requires_payment': !exists(json, 'requires_payment') ? undefined : json['requires_payment'],
        'is_paid': !exists(json, 'is_paid') ? undefined : json['is_paid'],
    };
}

export function ScreenerSpecialCriteriaToJSON(value?: ScreenerSpecialCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'display_name': value.display_name,
        'description': value.description,
        'identifier': value.identifier,
        'qualifier': CriteriaQualifierToJSON(value.qualifier),
        'enabled': value.enabled,
        'requires_payment': value.requires_payment,
        'is_paid': value.is_paid,
    };
}

