/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PtOtClinicalCategory = {
    JointReplacementOrSpinalSurgery: 'joint_replacement_or_spinal_surgery',
    OtherOrthopedic: 'other_orthopedic',
    MedicalManagement: 'medical_management',
    NonOrthopedicSurgeryAcuteNeurologic: 'non_orthopedic_surgery_acute_neurologic'
} as const;
export type PtOtClinicalCategory = typeof PtOtClinicalCategory[keyof typeof PtOtClinicalCategory];


export function PtOtClinicalCategoryFromJSON(json: any): PtOtClinicalCategory {
    return PtOtClinicalCategoryFromJSONTyped(json, false);
}

export function PtOtClinicalCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtClinicalCategory {
    return json as PtOtClinicalCategory;
}

export function PtOtClinicalCategoryToJSON(value?: PtOtClinicalCategory | null): any {
    return value as any;
}

