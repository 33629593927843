/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CareLevel
 */
export interface CareLevel {
    /**
     * 
     * @type {string}
     * @memberof CareLevel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CareLevel
     */
    care_scheme_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CareLevel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CareLevel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CareLevel
     */
    market_price: number;
    /**
     * 
     * @type {number}
     * @memberof CareLevel
     */
    care_units_min: number;
    /**
     * 
     * @type {number}
     * @memberof CareLevel
     */
    care_units_max: number;
    /**
     * 
     * @type {string}
     * @memberof CareLevel
     */
    billing_product_id?: string;
}

/**
 * Check if a given object implements the CareLevel interface.
 */
export function instanceOfCareLevel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "market_price" in value;
    isInstance = isInstance && "care_units_min" in value;
    isInstance = isInstance && "care_units_max" in value;

    return isInstance;
}

export function CareLevelFromJSON(json: any): CareLevel {
    return CareLevelFromJSONTyped(json, false);
}

export function CareLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'care_scheme_id': !exists(json, 'care_scheme_id') ? undefined : json['care_scheme_id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'market_price': json['market_price'],
        'care_units_min': json['care_units_min'],
        'care_units_max': json['care_units_max'],
        'billing_product_id': !exists(json, 'billing_product_id') ? undefined : json['billing_product_id'],
    };
}

export function CareLevelToJSON(value?: CareLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'care_scheme_id': value.care_scheme_id,
        'title': value.title,
        'description': value.description,
        'market_price': value.market_price,
        'care_units_min': value.care_units_min,
        'care_units_max': value.care_units_max,
        'billing_product_id': value.billing_product_id,
    };
}

