/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmReferredToResponse
 */
export interface ApfmReferredToResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmReferredToResponse
     */
    property_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferredToResponse
     */
    property_postal_region?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferredToResponse
     */
    partner_property_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferredToResponse
     */
    apfm_property_id?: string;
}

/**
 * Check if a given object implements the ApfmReferredToResponse interface.
 */
export function instanceOfApfmReferredToResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmReferredToResponseFromJSON(json: any): ApfmReferredToResponse {
    return ApfmReferredToResponseFromJSONTyped(json, false);
}

export function ApfmReferredToResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmReferredToResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property_name': !exists(json, 'property_name') ? undefined : json['property_name'],
        'property_postal_region': !exists(json, 'property_postal_region') ? undefined : json['property_postal_region'],
        'partner_property_id': !exists(json, 'partner_property_id') ? undefined : json['partner_property_id'],
        'apfm_property_id': !exists(json, 'apfm_property_id') ? undefined : json['apfm_property_id'],
    };
}

export function ApfmReferredToResponseToJSON(value?: ApfmReferredToResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property_name': value.property_name,
        'property_postal_region': value.property_postal_region,
        'partner_property_id': value.partner_property_id,
        'apfm_property_id': value.apfm_property_id,
    };
}

