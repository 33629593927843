import { CrmResidentListEntry, RelationType } from '@/types/crm-campaigns';

export interface CrmResidentModelsPage {
  residents: Array<CrmResidentListEntryModel>;
  total_count: number;
}

export class CrmResidentListEntryModel implements CrmResidentListEntry {
  public readonly id: string;
  public readonly avatar_url: string | null;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly resident_group: string | null;
  public readonly move_in_date: string;
  public readonly relation_type: RelationType;
  public readonly relation_id: string;
  public readonly is_added_to_audience?: boolean | undefined;
  public readonly fullName: string;

  constructor(payload: CrmResidentListEntry) {
    this.id = payload.id;
    this.avatar_url = payload.avatar_url;
    this.first_name = payload.first_name;
    this.last_name = payload.last_name;
    this.resident_group = payload.resident_group;
    this.move_in_date = payload.move_in_date;
    this.relation_type = payload.relation_type;
    this.relation_id = payload.relation_id;
    this.is_added_to_audience = payload.is_added_to_audience;
    this.fullName =
      payload.first_name || payload.last_name
        ? `${payload.first_name ?? ''} ${payload.last_name ?? ''}`
        : 'Unknown';
  }

  public getFullName(): string {
    return this.fullName;
  }

  public getInitials = (): string =>
    `${this.first_name?.charAt(0) || ''}${this.last_name?.charAt(0) || ''}`;
}
