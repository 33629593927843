/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface CrmResidentListEntry
 */
export interface CrmResidentListEntry {
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    avatar_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    resident_group: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    move_in_date: string;
    /**
     * 
     * @type {RelationType}
     * @memberof CrmResidentListEntry
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof CrmResidentListEntry
     */
    relation_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrmResidentListEntry
     */
    is_added_to_audience?: boolean;
}

/**
 * Check if a given object implements the CrmResidentListEntry interface.
 */
export function instanceOfCrmResidentListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "avatar_url" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "resident_group" in value;
    isInstance = isInstance && "move_in_date" in value;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;

    return isInstance;
}

export function CrmResidentListEntryFromJSON(json: any): CrmResidentListEntry {
    return CrmResidentListEntryFromJSONTyped(json, false);
}

export function CrmResidentListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmResidentListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'avatar_url': json['avatar_url'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'resident_group': json['resident_group'],
        'move_in_date': json['move_in_date'],
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
        'is_added_to_audience': !exists(json, 'is_added_to_audience') ? undefined : json['is_added_to_audience'],
    };
}

export function CrmResidentListEntryToJSON(value?: CrmResidentListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avatar_url': value.avatar_url,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'resident_group': value.resident_group,
        'move_in_date': value.move_in_date,
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
        'is_added_to_audience': value.is_added_to_audience,
    };
}

