/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationCredentials
 */
export interface IntegrationCredentials {
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    readonly facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationCredentials
     */
    integration_type: IntegrationCredentialsIntegrationTypeEnum;
}


/**
 * @export
 */
export const IntegrationCredentialsIntegrationTypeEnum = {
    WellskyIntake: 'wellsky_intake',
    WellskyReferralManagement: 'wellsky_referral_management',
    Ensocare: 'ensocare',
    Aidin: 'aidin',
    Aida: 'aida'
} as const;
export type IntegrationCredentialsIntegrationTypeEnum = typeof IntegrationCredentialsIntegrationTypeEnum[keyof typeof IntegrationCredentialsIntegrationTypeEnum];


/**
 * Check if a given object implements the IntegrationCredentials interface.
 */
export function instanceOfIntegrationCredentials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "integration_type" in value;

    return isInstance;
}

export function IntegrationCredentialsFromJSON(json: any): IntegrationCredentials {
    return IntegrationCredentialsFromJSONTyped(json, false);
}

export function IntegrationCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'username': json['username'],
        'password': json['password'],
        'integration_type': json['integration_type'],
    };
}

export function IntegrationCredentialsToJSON(value?: IntegrationCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'integration_type': value.integration_type,
    };
}

