/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CampaignEmailStats } from './CampaignEmailStats';
import {
    CampaignEmailStatsFromJSON,
    CampaignEmailStatsFromJSONTyped,
    CampaignEmailStatsToJSON,
} from './CampaignEmailStats';
import type { CampaignTaskStats } from './CampaignTaskStats';
import {
    CampaignTaskStatsFromJSON,
    CampaignTaskStatsFromJSONTyped,
    CampaignTaskStatsToJSON,
} from './CampaignTaskStats';
import type { CampaignTextStats } from './CampaignTextStats';
import {
    CampaignTextStatsFromJSON,
    CampaignTextStatsFromJSONTyped,
    CampaignTextStatsToJSON,
} from './CampaignTextStats';

/**
 * 
 * @export
 * @interface CampaignStats
 */
export interface CampaignStats {
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    total_subscribers_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    active_subscribers_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    completed_subscribers_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    completed_subscribers_today_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    paused_subscribers_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    paused_subscribers_today_count: number;
    /**
     * 
     * @type {CampaignEmailStats}
     * @memberof CampaignStats
     */
    email_stats?: CampaignEmailStats;
    /**
     * 
     * @type {CampaignTextStats}
     * @memberof CampaignStats
     */
    text_stats?: CampaignTextStats;
    /**
     * 
     * @type {CampaignTaskStats}
     * @memberof CampaignStats
     */
    task_stats?: CampaignTaskStats;
}

/**
 * Check if a given object implements the CampaignStats interface.
 */
export function instanceOfCampaignStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total_subscribers_count" in value;
    isInstance = isInstance && "active_subscribers_count" in value;
    isInstance = isInstance && "completed_subscribers_count" in value;
    isInstance = isInstance && "completed_subscribers_today_count" in value;
    isInstance = isInstance && "paused_subscribers_count" in value;
    isInstance = isInstance && "paused_subscribers_today_count" in value;

    return isInstance;
}

export function CampaignStatsFromJSON(json: any): CampaignStats {
    return CampaignStatsFromJSONTyped(json, false);
}

export function CampaignStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_subscribers_count': json['total_subscribers_count'],
        'active_subscribers_count': json['active_subscribers_count'],
        'completed_subscribers_count': json['completed_subscribers_count'],
        'completed_subscribers_today_count': json['completed_subscribers_today_count'],
        'paused_subscribers_count': json['paused_subscribers_count'],
        'paused_subscribers_today_count': json['paused_subscribers_today_count'],
        'email_stats': !exists(json, 'email_stats') ? undefined : CampaignEmailStatsFromJSON(json['email_stats']),
        'text_stats': !exists(json, 'text_stats') ? undefined : CampaignTextStatsFromJSON(json['text_stats']),
        'task_stats': !exists(json, 'task_stats') ? undefined : CampaignTaskStatsFromJSON(json['task_stats']),
    };
}

export function CampaignStatsToJSON(value?: CampaignStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_subscribers_count': value.total_subscribers_count,
        'active_subscribers_count': value.active_subscribers_count,
        'completed_subscribers_count': value.completed_subscribers_count,
        'completed_subscribers_today_count': value.completed_subscribers_today_count,
        'paused_subscribers_count': value.paused_subscribers_count,
        'paused_subscribers_today_count': value.paused_subscribers_today_count,
        'email_stats': CampaignEmailStatsToJSON(value.email_stats),
        'text_stats': CampaignTextStatsToJSON(value.text_stats),
        'task_stats': CampaignTaskStatsToJSON(value.task_stats),
    };
}

