/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TextTemplate } from './TextTemplate';
import {
    TextTemplateFromJSON,
    TextTemplateFromJSONTyped,
    TextTemplateToJSON,
} from './TextTemplate';

/**
 * 
 * @export
 * @interface CreateTextTemplate201Response
 */
export interface CreateTextTemplate201Response {
    /**
     * 
     * @type {TextTemplate}
     * @memberof CreateTextTemplate201Response
     */
    data?: TextTemplate;
}

/**
 * Check if a given object implements the CreateTextTemplate201Response interface.
 */
export function instanceOfCreateTextTemplate201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateTextTemplate201ResponseFromJSON(json: any): CreateTextTemplate201Response {
    return CreateTextTemplate201ResponseFromJSONTyped(json, false);
}

export function CreateTextTemplate201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTextTemplate201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : TextTemplateFromJSON(json['data']),
    };
}

export function CreateTextTemplate201ResponseToJSON(value?: CreateTextTemplate201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TextTemplateToJSON(value.data),
    };
}

