/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskExtensionTemplateDefaultAnswerOptionsInner } from './TaskExtensionTemplateDefaultAnswerOptionsInner';
import {
    TaskExtensionTemplateDefaultAnswerOptionsInnerFromJSON,
    TaskExtensionTemplateDefaultAnswerOptionsInnerFromJSONTyped,
    TaskExtensionTemplateDefaultAnswerOptionsInnerToJSON,
} from './TaskExtensionTemplateDefaultAnswerOptionsInner';
import type { TaskExtensionType } from './TaskExtensionType';
import {
    TaskExtensionTypeFromJSON,
    TaskExtensionTypeFromJSONTyped,
    TaskExtensionTypeToJSON,
} from './TaskExtensionType';

/**
 * 
 * @export
 * @interface TaskExtensionTemplate
 */
export interface TaskExtensionTemplate {
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    facility_id: string;
    /**
     * 
     * @type {TaskExtensionType}
     * @memberof TaskExtensionTemplate
     */
    extension_type: TaskExtensionType;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    question_type: TaskExtensionTemplateQuestionTypeEnum;
    /**
     * 
     * @type {Array<TaskExtensionTemplateDefaultAnswerOptionsInner>}
     * @memberof TaskExtensionTemplate
     */
    default_answer_options?: Array<TaskExtensionTemplateDefaultAnswerOptionsInner>;
    /**
     * 
     * @type {Array<TaskExtensionTemplateDefaultAnswerOptionsInner>}
     * @memberof TaskExtensionTemplate
     */
    default_answers?: Array<TaskExtensionTemplateDefaultAnswerOptionsInner>;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTemplate
     */
    tag_id?: string;
}


/**
 * @export
 */
export const TaskExtensionTemplateQuestionTypeEnum = {
    FreeText: 'free_text',
    Number: 'number',
    Percentage: 'percentage',
    SingleSelect: 'single_select',
    MultipleSelect: 'multiple_select'
} as const;
export type TaskExtensionTemplateQuestionTypeEnum = typeof TaskExtensionTemplateQuestionTypeEnum[keyof typeof TaskExtensionTemplateQuestionTypeEnum];


/**
 * Check if a given object implements the TaskExtensionTemplate interface.
 */
export function instanceOfTaskExtensionTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "extension_type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "question_type" in value;

    return isInstance;
}

export function TaskExtensionTemplateFromJSON(json: any): TaskExtensionTemplate {
    return TaskExtensionTemplateFromJSONTyped(json, false);
}

export function TaskExtensionTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskExtensionTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'extension_type': TaskExtensionTypeFromJSON(json['extension_type']),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'question_type': json['question_type'],
        'default_answer_options': !exists(json, 'default_answer_options') ? undefined : ((json['default_answer_options'] as Array<any>).map(TaskExtensionTemplateDefaultAnswerOptionsInnerFromJSON)),
        'default_answers': !exists(json, 'default_answers') ? undefined : ((json['default_answers'] as Array<any>).map(TaskExtensionTemplateDefaultAnswerOptionsInnerFromJSON)),
        'tag_id': !exists(json, 'tag_id') ? undefined : json['tag_id'],
    };
}

export function TaskExtensionTemplateToJSON(value?: TaskExtensionTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'extension_type': TaskExtensionTypeToJSON(value.extension_type),
        'title': value.title,
        'description': value.description,
        'question_type': value.question_type,
        'default_answer_options': value.default_answer_options === undefined ? undefined : ((value.default_answer_options as Array<any>).map(TaskExtensionTemplateDefaultAnswerOptionsInnerToJSON)),
        'default_answers': value.default_answers === undefined ? undefined : ((value.default_answers as Array<any>).map(TaskExtensionTemplateDefaultAnswerOptionsInnerToJSON)),
        'tag_id': value.tag_id,
    };
}

