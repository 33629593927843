/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UnscheduledCareTaskInstance } from './UnscheduledCareTaskInstance';
import {
    UnscheduledCareTaskInstanceFromJSON,
    UnscheduledCareTaskInstanceFromJSONTyped,
    UnscheduledCareTaskInstanceToJSON,
} from './UnscheduledCareTaskInstance';

/**
 * 
 * @export
 * @interface UnscheduledCareEntry
 */
export interface UnscheduledCareEntry {
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    care_category_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    responsible_party_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    created_by_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    care_plan_assistance_level_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareEntry
     */
    instructions?: string;
    /**
     * 
     * @type {number}
     * @memberof UnscheduledCareEntry
     */
    number_of_assistants?: number;
    /**
     * 
     * @type {number}
     * @memberof UnscheduledCareEntry
     */
    length_mins?: number;
    /**
     * 
     * @type {Array<UnscheduledCareTaskInstance>}
     * @memberof UnscheduledCareEntry
     */
    task_instances: Array<UnscheduledCareTaskInstance>;
}

/**
 * Check if a given object implements the UnscheduledCareEntry interface.
 */
export function instanceOfUnscheduledCareEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "care_category_id" in value;
    isInstance = isInstance && "responsible_party_id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "created_by_user_id" in value;
    isInstance = isInstance && "care_plan_assistance_level_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "task_instances" in value;

    return isInstance;
}

export function UnscheduledCareEntryFromJSON(json: any): UnscheduledCareEntry {
    return UnscheduledCareEntryFromJSONTyped(json, false);
}

export function UnscheduledCareEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnscheduledCareEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'care_category_id': json['care_category_id'],
        'responsible_party_id': json['responsible_party_id'],
        'resident_id': json['resident_id'],
        'created_by_user_id': json['created_by_user_id'],
        'care_plan_assistance_level_id': json['care_plan_assistance_level_id'],
        'title': json['title'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'number_of_assistants': !exists(json, 'number_of_assistants') ? undefined : json['number_of_assistants'],
        'length_mins': !exists(json, 'length_mins') ? undefined : json['length_mins'],
        'task_instances': ((json['task_instances'] as Array<any>).map(UnscheduledCareTaskInstanceFromJSON)),
    };
}

export function UnscheduledCareEntryToJSON(value?: UnscheduledCareEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'care_category_id': value.care_category_id,
        'responsible_party_id': value.responsible_party_id,
        'resident_id': value.resident_id,
        'created_by_user_id': value.created_by_user_id,
        'care_plan_assistance_level_id': value.care_plan_assistance_level_id,
        'title': value.title,
        'instructions': value.instructions,
        'number_of_assistants': value.number_of_assistants,
        'length_mins': value.length_mins,
        'task_instances': ((value.task_instances as Array<any>).map(UnscheduledCareTaskInstanceToJSON)),
    };
}

