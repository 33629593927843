import pluralize from 'pluralize';

export function convertMinutesToHours(minutes: any): string {
  // Convert minutes to a number
  const minutesNumber = parseFloat(minutes ?? '');

  if (isNaN(minutesNumber)) {
    return '';
  }

  // Convert minutes to hours and round to two decimal places
  let hours = (minutesNumber / 60).toFixed(2);

  // Remove trailing 0's
  hours = hours.replace(/\.?0+$/, '');

  return hours.length ? `${hours} ${pluralize('hour', parseFloat(hours))}` : '';
}
