import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Tooltip
} from '@mui/material';

export function hasNA(
  options = [] as (Record<any, any> | string | symbol | number)[]
) {
  if (!Array.isArray(options)) {
    return false;
  }
  return options
    .map((v) => (typeof v == 'object' ? Object.values(v) : [v]))
    .flat()
    .some((v) => typeof v == 'string' && v.toLowerCase() == 'n/a');
}

/**
 *
 * @deprecated use ControlledSelectPrimitive instead
 */
export function ControlledSelect<
  TFields extends FieldValues,
  Option extends Record<any, any> | string | symbol | number
>({
  name,
  control,
  rules,
  options,
  optionLabelKey,
  optionIdKey,
  label,
  onChange,
  isLoading,
  tooltip = '',
  hideNA = false,
  ...props
}: React.PropsWithChildren<
  UseControllerProps<TFields> &
    Partial<SelectProps> & {
      options?: Option[];
      optionLabelKey?: keyof Option;
      optionIdKey?: keyof Option;
      onChange?: (option: Option) => void;
      isLoading?: boolean;
      tooltip?: string;
      hideNA?: boolean;
    }
>) {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const {
    field: { ref, onChange: onChangeField, value, onBlur, ...fields },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  isLoading = isLoading ?? (!options || options?.length == 0);
  const selectvalue = String(
    (optionIdKey ? (value as any)?.[optionIdKey] : value) ?? null
  );

  return (
    <Tooltip
      open={!!tooltip && openTooltip}
      arrow
      title={tooltip}
      placement="left-start">
      <FormControl error={!!error} fullWidth>
        <InputLabel id={name}>{label}</InputLabel>

        {isLoading && (
          <Select
            label={label}
            value={' '}
            inputRef={ref}
            {...fields}
            {...props}
            disabled
            renderValue={() => (
              <CircularProgress
                sx={{ mb: -0.5, ml: 0.5 }}
                size="1rem"
                thickness={5}
              />
            )}
          />
        )}

        {!isLoading && (
          <Select
            labelId={name}
            label={label}
            value={selectvalue}
            onChange={(e) => {
              const id = e.target.value;
              const selectedOption =
                options?.find((option) =>
                  optionIdKey ? option?.[optionIdKey] == id : option == id
                ) ?? '';
              onChangeField(selectedOption);
              onChange?.(selectedOption as Option);
            }}
            inputRef={ref}
            onFocus={() => {
              setOpenTooltip(true);
            }}
            onBlur={() => {
              setOpenTooltip(false);
              onBlur();
            }}
            {...fields}
            {...props}>
            {!rules?.required && !hasNA(options) && !hideNA && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {options?.map((option) => {
              const key = option?.[optionIdKey] ?? option;
              const value = option?.[optionIdKey] ?? option;
              const label = option?.[optionLabelKey] ?? option;
              return (
                <MenuItem
                  key={key as string}
                  value={value as string}
                  data-testid={`mui-component-select-${String(name)}-option`}>
                  {label as string}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Tooltip>
  );
}
