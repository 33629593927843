import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import omitBy from 'lodash/omitBy';

import { fetchCRMReferrals } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';
import { invalidateReferralSources } from '@/hooks/useReferrals';

import {
  ReferralSourceCompanyModel,
  ReferralSourceCompanyPayload
} from '../models/ReferralSourceCompanyModel';

const USE_REFERRAL_SOURCE_COMPANIES_FIND_ALL_QUERY_KEY = [
  'useReferralSourceCompaniesQueryFindAll'
];
const USE_REFERRAL_SOURCE_COMPANIES_FIND_ONE_QUERY_KEY = [
  'useReferralSourceCompaniesQueryFindOne'
];
const USE_REFERRAL_SOURCE_COMPANIES_DASHBOARD_QUERY_KEY = [
  'useReferralSourceCompaniesQueryDashboard'
];

type SerializePayload = ReferralSourceCompanyPayload & {
  referral_source_ids: string[];
};

const serialize = (
  payload: Partial<ReferralSourceCompanyPayload>
): Partial<SerializePayload> => {
  return {
    ...payload,
    referral_source_ids:
      payload.crm_referral_sources?.map((referral) => referral.id) ?? []
  };
};

export const invalidateReferralSourceCompanies = () => {
  queryClient.invalidateQueries(
    USE_REFERRAL_SOURCE_COMPANIES_FIND_ALL_QUERY_KEY
  );
  queryClient.invalidateQueries(
    USE_REFERRAL_SOURCE_COMPANIES_FIND_ONE_QUERY_KEY
  );
  queryClient.invalidateQueries(
    USE_REFERRAL_SOURCE_COMPANIES_DASHBOARD_QUERY_KEY
  );
};

export const useReferralSourceCompaniesQuery = () => {
  const facilityId = useSelectedFacilityId();

  return {
    dashboard: (
      facilityId: string,
      options: UseQueryOptions<ReferralSourceCompanyModel[]> = {}
    ) =>
      useQuery(
        [...USE_REFERRAL_SOURCE_COMPANIES_DASHBOARD_QUERY_KEY, facilityId],
        async () => {
          const payloads = await fetchCRMReferrals.get<
            ReferralSourceCompanyPayload[]
          >(`/referral-source-companies/dashboard`, {
            searchParams: {
              facility_id: facilityId
            }
          });
          return payloads.map(
            (payload) => new ReferralSourceCompanyModel(payload)
          );
        },
        options as any
      ),

    findAll: (options: UseQueryOptions<ReferralSourceCompanyModel[]> = {}) =>
      useQuery(
        [...USE_REFERRAL_SOURCE_COMPANIES_FIND_ALL_QUERY_KEY, facilityId],
        async () => {
          const payloads = await fetchCRMReferrals.get<
            ReferralSourceCompanyPayload[]
          >('/referral-source-companies', {
            searchParams: {
              facility_id: facilityId
            }
          });
          return payloads.map(
            (payload) => new ReferralSourceCompanyModel(payload)
          );
        },
        options as any
      ),

    findOne: (
      id?: string,
      options: UseQueryOptions<ReferralSourceCompanyModel> = {}
    ) =>
      useQuery(
        [...USE_REFERRAL_SOURCE_COMPANIES_FIND_ONE_QUERY_KEY, id],
        async () => {
          const payload =
            await fetchCRMReferrals.get<ReferralSourceCompanyPayload>(
              `/referral-source-companies/${id}`
            );
          return new ReferralSourceCompanyModel(payload);
        },
        {
          enabled: !!id,
          ...(options as any)
        }
      ),

    mutations: () => ({
      create: useMutation(
        (payload: Partial<ReferralSourceCompanyPayload>) => {
          const serializedPayload = serialize(payload);
          return fetchCRMReferrals.post<ReferralSourceCompanyPayload>(
            '/referral-source-companies',
            serializedPayload
          );
        },
        {
          onSuccess: () => {
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
          }
        }
      ),

      update: useMutation(
        (payload: Partial<ReferralSourceCompanyPayload>) => {
          const serializedPayload = serialize(payload);
          return fetchCRMReferrals.put<ReferralSourceCompanyPayload>(
            `/referral-source-companies/${payload.id}`,
            omitBy(serializedPayload, 'id')
          );
        },
        {
          onSuccess: () => {
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
          }
        }
      ),

      delete: useMutation(
        (id: string) => {
          return fetchCRMReferrals.delete(`/referral-source-companies/${id}`);
        },
        {
          onSuccess: () => {
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
          }
        }
      )
    })
  };
};
