/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareLevel } from './CareLevel';
import {
    CareLevelFromJSON,
    CareLevelFromJSONTyped,
    CareLevelToJSON,
} from './CareLevel';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface CareScheme
 */
export interface CareScheme {
    /**
     * 
     * @type {string}
     * @memberof CareScheme
     */
    scheme_type: CareSchemeSchemeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CareScheme
     */
    care_levels_count?: number;
    /**
     * 
     * @type {Array<CareLevel>}
     * @memberof CareScheme
     */
    care_levels?: Array<CareLevel>;
    /**
     * 
     * @type {UserName}
     * @memberof CareScheme
     */
    created_by?: UserName;
    /**
     * 
     * @type {UserName}
     * @memberof CareScheme
     */
    modified_by?: UserName;
    /**
     * 
     * @type {string}
     * @memberof CareScheme
     */
    date_created?: string;
    /**
     * 
     * @type {string}
     * @memberof CareScheme
     */
    date_modified?: string;
}


/**
 * @export
 */
export const CareSchemeSchemeTypeEnum = {
    Points: 'points',
    Levels: 'levels'
} as const;
export type CareSchemeSchemeTypeEnum = typeof CareSchemeSchemeTypeEnum[keyof typeof CareSchemeSchemeTypeEnum];


/**
 * Check if a given object implements the CareScheme interface.
 */
export function instanceOfCareScheme(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scheme_type" in value;

    return isInstance;
}

export function CareSchemeFromJSON(json: any): CareScheme {
    return CareSchemeFromJSONTyped(json, false);
}

export function CareSchemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareScheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheme_type': json['scheme_type'],
        'care_levels_count': !exists(json, 'care_levels_count') ? undefined : json['care_levels_count'],
        'care_levels': !exists(json, 'care_levels') ? undefined : ((json['care_levels'] as Array<any>).map(CareLevelFromJSON)),
        'created_by': !exists(json, 'created_by') ? undefined : UserNameFromJSON(json['created_by']),
        'modified_by': !exists(json, 'modified_by') ? undefined : UserNameFromJSON(json['modified_by']),
        'date_created': !exists(json, 'date_created') ? undefined : json['date_created'],
        'date_modified': !exists(json, 'date_modified') ? undefined : json['date_modified'],
    };
}

export function CareSchemeToJSON(value?: CareScheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheme_type': value.scheme_type,
        'care_levels_count': value.care_levels_count,
        'care_levels': value.care_levels === undefined ? undefined : ((value.care_levels as Array<any>).map(CareLevelToJSON)),
        'created_by': UserNameToJSON(value.created_by),
        'modified_by': UserNameToJSON(value.modified_by),
        'date_created': value.date_created,
        'date_modified': value.date_modified,
    };
}

