import React from 'react';
import { Box, Grid, Stack } from '@mui/material';

import { SlidingScaleDoseRange } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { BLOOD_PRESSURE_VITAL_ID, VITAL_ID_TO_UNIT_MAP } from '@/constants';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { useVitalTypesQuery } from '@/hooks/useVitalsQuery';
import { calculateFdbTotalDosage } from '@/utils/calculateTotalDosage';

interface SlidingScaleDoseColumnCellProps {
  slidingScaleDoseRanges: SlidingScaleDoseRange[];
  dispensableDrug?: MedicationsPayload;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

export default function SlidingScaleDoseColumnCell(
  props: SlidingScaleDoseColumnCellProps
) {
  const {
    slidingScaleDoseRanges,
    dispensableDrug,
    direction = 'column'
  } = props;

  const vitalTypeId = slidingScaleDoseRanges.find(
    (range) => range.vital_type_id
  )?.vital_type_id;

  const { data: vitals } = useVitalTypesQuery().findAll({
    enabled: !!vitalTypeId
  });

  return (
    <Stack gap={1}>
      <Box textAlign="left" width={1}>
        <Box fontWeight={500} mr="5px" component="span">
          {vitals?.find((vital) => vital.id === vitalTypeId)?.name}
        </Box>
        {vitals?.find((vital) => vital.id === vitalTypeId)
          ? `(${
              vitalTypeId === BLOOD_PRESSURE_VITAL_ID
                ? slidingScaleDoseRanges?.[0]?.blood_pressure_type + ' '
                : ''
            }${
              VITAL_ID_TO_UNIT_MAP[
                vitals?.find((vital) => vital.id === vitalTypeId)?.id as string
              ]
            })`
          : ''}{' '}
        {dispensableDrug?.MedStrength} {dispensableDrug?.MedStrengthUnit}
      </Box>
      <Stack direction={direction} gap={1} width={1}>
        {slidingScaleDoseRanges
          .sort((a, b) => parseInt(a.from) - parseInt(b.from))
          .map((range, index) => {
            return (
              <React.Fragment key={index}>
                <Grid container spacing={1} key={index}>
                  <Grid item fontWeight={500}>
                    {range.from} - {range.to}
                  </Grid>
                  <Grid item>
                    {dispensableDrug &&
                      calculateFdbTotalDosage(
                        dispensableDrug,
                        range.number_of_dose_units,
                        true
                      )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
      </Stack>
    </Stack>
  );
}
