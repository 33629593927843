/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BillStatus = {
    Generating: 'Generating',
    Pending: 'Pending',
    PaymentScheduled: 'Payment Scheduled',
    Unpaid: 'Unpaid',
    Paid: 'Paid',
    PartiallyPaid: 'Partially Paid',
    PaymentFailed: 'Payment Failed',
    PaymentInProcess: 'Payment In Process'
} as const;
export type BillStatus = typeof BillStatus[keyof typeof BillStatus];


export function BillStatusFromJSON(json: any): BillStatus {
    return BillStatusFromJSONTyped(json, false);
}

export function BillStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillStatus {
    return json as BillStatus;
}

export function BillStatusToJSON(value?: BillStatus | null): any {
    return value as any;
}

