/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApfmCareResponse } from './ApfmCareResponse';
import {
    ApfmCareResponseFromJSON,
    ApfmCareResponseFromJSONTyped,
    ApfmCareResponseToJSON,
} from './ApfmCareResponse';
import type { ApfmCognitiveResponse } from './ApfmCognitiveResponse';
import {
    ApfmCognitiveResponseFromJSON,
    ApfmCognitiveResponseFromJSONTyped,
    ApfmCognitiveResponseToJSON,
} from './ApfmCognitiveResponse';
import type { ApfmFinancialResponse } from './ApfmFinancialResponse';
import {
    ApfmFinancialResponseFromJSON,
    ApfmFinancialResponseFromJSONTyped,
    ApfmFinancialResponseToJSON,
} from './ApfmFinancialResponse';
import type { ApfmInquiryResponse } from './ApfmInquiryResponse';
import {
    ApfmInquiryResponseFromJSON,
    ApfmInquiryResponseFromJSONTyped,
    ApfmInquiryResponseToJSON,
} from './ApfmInquiryResponse';
import type { ApfmMedicalResponse } from './ApfmMedicalResponse';
import {
    ApfmMedicalResponseFromJSON,
    ApfmMedicalResponseFromJSONTyped,
    ApfmMedicalResponseToJSON,
} from './ApfmMedicalResponse';
import type { ApfmMedicationResponse } from './ApfmMedicationResponse';
import {
    ApfmMedicationResponseFromJSON,
    ApfmMedicationResponseFromJSONTyped,
    ApfmMedicationResponseToJSON,
} from './ApfmMedicationResponse';
import type { ApfmMobilityResponse } from './ApfmMobilityResponse';
import {
    ApfmMobilityResponseFromJSON,
    ApfmMobilityResponseFromJSONTyped,
    ApfmMobilityResponseToJSON,
} from './ApfmMobilityResponse';
import type { ApfmOtherResponse } from './ApfmOtherResponse';
import {
    ApfmOtherResponseFromJSON,
    ApfmOtherResponseFromJSONTyped,
    ApfmOtherResponseToJSON,
} from './ApfmOtherResponse';
import type { ApfmPermissionsResponse } from './ApfmPermissionsResponse';
import {
    ApfmPermissionsResponseFromJSON,
    ApfmPermissionsResponseFromJSONTyped,
    ApfmPermissionsResponseToJSON,
} from './ApfmPermissionsResponse';
import type { ApfmPrimaryContactResponse } from './ApfmPrimaryContactResponse';
import {
    ApfmPrimaryContactResponseFromJSON,
    ApfmPrimaryContactResponseFromJSONTyped,
    ApfmPrimaryContactResponseToJSON,
} from './ApfmPrimaryContactResponse';
import type { ApfmProspectResponse } from './ApfmProspectResponse';
import {
    ApfmProspectResponseFromJSON,
    ApfmProspectResponseFromJSONTyped,
    ApfmProspectResponseToJSON,
} from './ApfmProspectResponse';
import type { ApfmReferralDetailsResponse } from './ApfmReferralDetailsResponse';
import {
    ApfmReferralDetailsResponseFromJSON,
    ApfmReferralDetailsResponseFromJSONTyped,
    ApfmReferralDetailsResponseToJSON,
} from './ApfmReferralDetailsResponse';
import type { ApfmReferredToResponse } from './ApfmReferredToResponse';
import {
    ApfmReferredToResponseFromJSON,
    ApfmReferredToResponseFromJSONTyped,
    ApfmReferredToResponseToJSON,
} from './ApfmReferredToResponse';

/**
 * 
 * @export
 * @interface LatestAPFMUpdatesResponse
 */
export interface LatestAPFMUpdatesResponse {
    /**
     * 
     * @type {ApfmProspectResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    prospect?: ApfmProspectResponse;
    /**
     * 
     * @type {ApfmReferredToResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    referred_to?: ApfmReferredToResponse;
    /**
     * 
     * @type {ApfmReferralDetailsResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    referral_details?: ApfmReferralDetailsResponse;
    /**
     * 
     * @type {ApfmPrimaryContactResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    primary_contact?: ApfmPrimaryContactResponse;
    /**
     * 
     * @type {ApfmInquiryResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    inquiry?: ApfmInquiryResponse;
    /**
     * 
     * @type {ApfmCareResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    care?: ApfmCareResponse;
    /**
     * 
     * @type {ApfmMedicationResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    medication?: ApfmMedicationResponse;
    /**
     * 
     * @type {ApfmMedicalResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    medical?: ApfmMedicalResponse;
    /**
     * 
     * @type {ApfmMobilityResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    mobility?: ApfmMobilityResponse;
    /**
     * 
     * @type {ApfmCognitiveResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    cognitive?: ApfmCognitiveResponse;
    /**
     * 
     * @type {ApfmOtherResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    other?: ApfmOtherResponse;
    /**
     * 
     * @type {ApfmFinancialResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    financial?: ApfmFinancialResponse;
    /**
     * 
     * @type {ApfmPermissionsResponse}
     * @memberof LatestAPFMUpdatesResponse
     */
    permission?: ApfmPermissionsResponse;
}

/**
 * Check if a given object implements the LatestAPFMUpdatesResponse interface.
 */
export function instanceOfLatestAPFMUpdatesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LatestAPFMUpdatesResponseFromJSON(json: any): LatestAPFMUpdatesResponse {
    return LatestAPFMUpdatesResponseFromJSONTyped(json, false);
}

export function LatestAPFMUpdatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatestAPFMUpdatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prospect': !exists(json, 'prospect') ? undefined : ApfmProspectResponseFromJSON(json['prospect']),
        'referred_to': !exists(json, 'referred_to') ? undefined : ApfmReferredToResponseFromJSON(json['referred_to']),
        'referral_details': !exists(json, 'referral_details') ? undefined : ApfmReferralDetailsResponseFromJSON(json['referral_details']),
        'primary_contact': !exists(json, 'primary_contact') ? undefined : ApfmPrimaryContactResponseFromJSON(json['primary_contact']),
        'inquiry': !exists(json, 'inquiry') ? undefined : ApfmInquiryResponseFromJSON(json['inquiry']),
        'care': !exists(json, 'care') ? undefined : ApfmCareResponseFromJSON(json['care']),
        'medication': !exists(json, 'medication') ? undefined : ApfmMedicationResponseFromJSON(json['medication']),
        'medical': !exists(json, 'medical') ? undefined : ApfmMedicalResponseFromJSON(json['medical']),
        'mobility': !exists(json, 'mobility') ? undefined : ApfmMobilityResponseFromJSON(json['mobility']),
        'cognitive': !exists(json, 'cognitive') ? undefined : ApfmCognitiveResponseFromJSON(json['cognitive']),
        'other': !exists(json, 'other') ? undefined : ApfmOtherResponseFromJSON(json['other']),
        'financial': !exists(json, 'financial') ? undefined : ApfmFinancialResponseFromJSON(json['financial']),
        'permission': !exists(json, 'permission') ? undefined : ApfmPermissionsResponseFromJSON(json['permission']),
    };
}

export function LatestAPFMUpdatesResponseToJSON(value?: LatestAPFMUpdatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prospect': ApfmProspectResponseToJSON(value.prospect),
        'referred_to': ApfmReferredToResponseToJSON(value.referred_to),
        'referral_details': ApfmReferralDetailsResponseToJSON(value.referral_details),
        'primary_contact': ApfmPrimaryContactResponseToJSON(value.primary_contact),
        'inquiry': ApfmInquiryResponseToJSON(value.inquiry),
        'care': ApfmCareResponseToJSON(value.care),
        'medication': ApfmMedicationResponseToJSON(value.medication),
        'medical': ApfmMedicalResponseToJSON(value.medical),
        'mobility': ApfmMobilityResponseToJSON(value.mobility),
        'cognitive': ApfmCognitiveResponseToJSON(value.cognitive),
        'other': ApfmOtherResponseToJSON(value.other),
        'financial': ApfmFinancialResponseToJSON(value.financial),
        'permission': ApfmPermissionsResponseToJSON(value.permission),
    };
}

