import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ProtectedRoute } from '@/routes/ProtectedRoute';
import { RoutePath } from '@/routes/RoutePath';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { getEhrArchivedResidentRoutes } from './getEhrArchivedResidentRoutes';
import { getEhrIncidentRoutes } from './getEhrIncidentRoutes';
import { getEhrPharmacyRoutes } from './getEhrPharmacyRoutes';
import { getEhrResidentOverviewRoutes } from './getEhrResidentOverviewRoutes';
import { getEhrResidentRoutes } from './getEhrResidentRoutes';

const ProfilePage = loadable(() => import("@/pages/ProfilePage")); // prettier-ignore
const ProfilePageGeneralInfo = loadable(() => import("@/pages/ProfilePage/ProfilePageGeneralInfo")); // prettier-ignore
const ProfilePageShifts = loadable(() => import("@/pages/ProfilePage/ProfilePageShifts")); // prettier-ignore

const TasksPageByResident = loadable(() => import("@/pages/TasksPage/TasksPageByResident")); // prettier-ignore
const TasksPageChronological = loadable(() => import("@/pages/TasksPage/TasksPageChronological")); // prettier-ignore
const TasksPageV2 = loadable(() => import("@/pages/TasksPageV2/TasksPageV2")); // prettier-ignore

export const getEhrRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;
  const userRedirectPath =
    currentUser.login_url_preference ||
    (currentUser.isScreenerCustomer()
      ? RoutePath.CRMScreenerAllLeads
      : '/profile');

  return [
    {
      path: RoutePath.TasksChronological,
      element: <ProtectedRoute role="L4" component={TasksPageChronological} />
    },
    {
      path: RoutePath.TasksByResident,
      element: (
        <ProtectedRoute
          role="L4"
          component={
            FeatureFlagService.isEnabled(ExacareFeature.TASKS_PAGE_V2)
              ? TasksPageV2
              : TasksPageByResident
          }
        />
      )
    },
    {
      path: RoutePath.Profile,
      element: <ProfilePage />,
      children: [
        {
          path: RoutePath.Profile,
          element: <ProfilePageGeneralInfo />
        },
        {
          path: RoutePath.ProfileShifts,
          element: <ProfilePageShifts />
        }
      ]
    },

    ...getEhrArchivedResidentRoutes(),
    ...getEhrIncidentRoutes(),
    ...getEhrPharmacyRoutes(),
    ...getEhrResidentOverviewRoutes(),
    ...getEhrResidentRoutes(),

    {
      path: '*',
      element: <Navigate to={userRedirectPath} replace />
    }
  ];
};
