import dayjs from 'dayjs';

import {
  MedicationStockSignOff,
  MedicationStockSignOffSignedBy,
  MedicationType
} from '@/types/medications';

export interface MedicationStockSignOffModelsPage {
  medicationStockSignOffs: Array<MedicationInventorySignOffModel>;
  total_count: number;
}

export class MedicationInventorySignOffModel implements MedicationStockSignOff {
  public readonly id: string;
  public readonly facility_id: string;
  public readonly signed_at: string | undefined;
  public readonly user_id: string;
  public readonly type: MedicationType;
  public readonly snapshot_s3_url: string;
  public readonly createdAt: string;
  public readonly updatedAt: string | undefined;
  public readonly deletedAt: string | undefined;
  public readonly signed_by?: MedicationStockSignOffSignedBy;

  constructor(payload: MedicationStockSignOff) {
    this.id = payload.id;
    this.facility_id = payload.facility_id;
    this.signed_at = payload.signed_at;
    this.user_id = payload.user_id;
    this.type = payload.type;
    this.snapshot_s3_url = payload.snapshot_s3_url;
    this.createdAt = payload.createdAt;
    this.updatedAt = payload.updatedAt;
    this.deletedAt = payload.deletedAt;
    this.signed_by = payload.signed_by;
  }

  public getSignedAtDateOnly(): string {
    return dayjs(this.signed_at).format('MMM D, YYYY');
  }

  public getSignedAtTimeOnly(): string {
    return dayjs(this.signed_at).format('hh:mm A');
  }

  public getFullDateDisplay(): string {
    return `${dayjs(this.signed_at).format('hh:mm a')} on ${dayjs(
      this.signed_at
    ).format('MMM D, YYYY')}`;
  }

  public getSignedByFullName(): string {
    if (!this.signed_by) {
      return '';
    }

    return `${this.signed_by.first_name} ${this.signed_by.last_name}`;
  }

  public getTypeDisplay(): string {
    if (this.type === MedicationType.All) {
      return 'All Meds';
    }

    return 'Controlled Substances Only';
  }
}
