/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentFollowupListEntry } from './IncidentFollowupListEntry';
import {
    IncidentFollowupListEntryFromJSON,
    IncidentFollowupListEntryFromJSONTyped,
    IncidentFollowupListEntryToJSON,
} from './IncidentFollowupListEntry';
import type { PostIncidentCommentEntry } from './PostIncidentCommentEntry';
import {
    PostIncidentCommentEntryFromJSON,
    PostIncidentCommentEntryFromJSONTyped,
    PostIncidentCommentEntryToJSON,
} from './PostIncidentCommentEntry';

/**
 * 
 * @export
 * @interface IncidentActionFindOneResponse
 */
export interface IncidentActionFindOneResponse {
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentActionFindOneResponse
     */
    assigned_to?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof IncidentActionFindOneResponse
     */
    deadline?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    action_type: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    created_by?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    completed_by?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    cancelled_by?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    completed_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentActionFindOneResponse
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    cancellation_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    completion_note?: string;
    /**
     * 
     * @type {Array<PostIncidentCommentEntry>}
     * @memberof IncidentActionFindOneResponse
     */
    comments: Array<PostIncidentCommentEntry>;
    /**
     * 
     * @type {string}
     * @memberof IncidentActionFindOneResponse
     */
    incomplete_followup_time?: string;
    /**
     * 
     * @type {Array<IncidentFollowupListEntry>}
     * @memberof IncidentActionFindOneResponse
     */
    followups: Array<IncidentFollowupListEntry>;
    /**
     * 
     * @type {any}
     * @memberof IncidentActionFindOneResponse
     */
    complete_followups: any | null;
}

/**
 * Check if a given object implements the IncidentActionFindOneResponse interface.
 */
export function instanceOfIncidentActionFindOneResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "action_type" in value;
    isInstance = isInstance && "is_optional" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "followups" in value;
    isInstance = isInstance && "complete_followups" in value;

    return isInstance;
}

export function IncidentActionFindOneResponseFromJSON(json: any): IncidentActionFindOneResponse {
    return IncidentActionFindOneResponseFromJSONTyped(json, false);
}

export function IncidentActionFindOneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentActionFindOneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'assigned_to': !exists(json, 'assigned_to') ? undefined : json['assigned_to'],
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'action_type': json['action_type'],
        'created_by': !exists(json, 'created_by') ? undefined : json['created_by'],
        'completed_by': !exists(json, 'completed_by') ? undefined : json['completed_by'],
        'cancelled_by': !exists(json, 'cancelled_by') ? undefined : json['cancelled_by'],
        'completed_date': !exists(json, 'completed_date') ? undefined : json['completed_date'],
        'is_optional': json['is_optional'],
        'cancellation_reason': !exists(json, 'cancellation_reason') ? undefined : json['cancellation_reason'],
        'completion_note': !exists(json, 'completion_note') ? undefined : json['completion_note'],
        'comments': ((json['comments'] as Array<any>).map(PostIncidentCommentEntryFromJSON)),
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
        'followups': ((json['followups'] as Array<any>).map(IncidentFollowupListEntryFromJSON)),
        'complete_followups': json['complete_followups'],
    };
}

export function IncidentActionFindOneResponseToJSON(value?: IncidentActionFindOneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'assigned_to': value.assigned_to,
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'action_type': value.action_type,
        'created_by': value.created_by,
        'completed_by': value.completed_by,
        'cancelled_by': value.cancelled_by,
        'completed_date': value.completed_date,
        'is_optional': value.is_optional,
        'cancellation_reason': value.cancellation_reason,
        'completion_note': value.completion_note,
        'comments': ((value.comments as Array<any>).map(PostIncidentCommentEntryToJSON)),
        'incomplete_followup_time': value.incomplete_followup_time,
        'followups': ((value.followups as Array<any>).map(IncidentFollowupListEntryToJSON)),
        'complete_followups': value.complete_followups,
    };
}

