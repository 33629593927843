/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentActionFindOneResponse } from './IncidentActionFindOneResponse';
import {
    IncidentActionFindOneResponseFromJSON,
    IncidentActionFindOneResponseFromJSONTyped,
    IncidentActionFindOneResponseToJSON,
} from './IncidentActionFindOneResponse';

/**
 * 
 * @export
 * @interface FindIncidentAction200Response
 */
export interface FindIncidentAction200Response {
    /**
     * 
     * @type {IncidentActionFindOneResponse}
     * @memberof FindIncidentAction200Response
     */
    data?: IncidentActionFindOneResponse;
}

/**
 * Check if a given object implements the FindIncidentAction200Response interface.
 */
export function instanceOfFindIncidentAction200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindIncidentAction200ResponseFromJSON(json: any): FindIncidentAction200Response {
    return FindIncidentAction200ResponseFromJSONTyped(json, false);
}

export function FindIncidentAction200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindIncidentAction200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentActionFindOneResponseFromJSON(json['data']),
    };
}

export function FindIncidentAction200ResponseToJSON(value?: FindIncidentAction200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentActionFindOneResponseToJSON(value.data),
    };
}

