import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

export function ControlledSingleSelect<
  TFields extends FieldValues,
  Option extends Record<any, any>
>({
  name,
  control,
  rules,
  options = [],
  optionIdKey,
  optionLabelKey,
  label,
  placeholder,
  disabled,
  onChange
}: React.PropsWithChildren<
  UseControllerProps<TFields> & {
    label?: string;
    options: Option[];
    optionLabelKey: keyof Option;
    optionIdKey: keyof Option;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (event?: any) => void;
  }
>) {
  const {
    field: { value, onChange: onChangeField, ...fields },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const handleAutocompleteOnChange: (
    event: React.SyntheticEvent,
    value: Option,
    reason: 'createOption' | 'selectOption' | 'removeOption' | 'blur' | 'clear',
    details?: string
  ) => void = (event, value) => {
    // Handles Options as custom Models
    if (typeof value !== 'string' && value != null) {
      onChangeField(value[optionIdKey]);
      onChange?.(value);
      return;
    }
    onChangeField(value);
    onChange?.(event);
  };

  const filterOptions = createFilterOptions<Option>({
    stringify: (option) => option[optionLabelKey]
  });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      disabled={disabled}
      filterSelectedOptions
      options={options ?? []}
      getOptionLabel={(option) => option[optionLabelKey]}
      value={options.find((option) => option[optionIdKey] === value) || null}
      renderInput={(params) => {
        return (
          <TextField
            key={params?.id}
            label={
              <>
                {label ? label : ''}
                <span style={{ color: '#DD3730' }}>
                  {rules?.required && label ? '*' : ''}
                </span>
              </>
            }
            placeholder={placeholder}
            type="text"
            variant="outlined"
            helperText={error?.message}
            error={!!error}
            {...params}
            {...fields}
            fullWidth
          />
        );
      }}
      // @ts-expect-error Typing this onChange handler is a nightmare so just skip it
      onChange={handleAutocompleteOnChange}
    />
  );
}
