import { useEffect, useState } from 'react';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { omit } from 'lodash';

import { fetchCRMCalls } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { selectedFacilityIdAtom } from '@/hooks/useFacilitiesQuery';
import { PhoneBookItemType } from '@/pages/CRM/models/PhoneBookItemModel';

import {
  callFailedEndStatuses,
  CallStatus,
  CallType,
  CrmCallModel,
  CrmCallPayload,
  MANUAL_LOG_CALL_SID
} from '../models/CrmCallModel';
import { CrmRelationType } from '../models/CrmTaskModel';

import { EVENT_LOG_FIND_ALL_QUERY_KEY } from './useCrmEventLogQuery';

export const CRM_CALLS_FIND_ALL_QUERY_KEY = 'crmCallsFindAll';
export const CRM_CALLS_FIND_ONE_QUERY_KEY = 'crmCallsFindOne';

export const invalidateCallsQueries = () => {
  queryClient.invalidateQueries([CRM_CALLS_FIND_ALL_QUERY_KEY]);
  queryClient.invalidateQueries([CRM_CALLS_FIND_ONE_QUERY_KEY]);
};

interface FindAllCallsRequest {
  facility_id: string;
  relation_type?: CrmRelationType;
  relation_id?: number;
  phonebook_type?: PhoneBookItemType;
  limit?: number;
  before_date?: string;
  after_date?: string;
  type?: CallType;
  search_text?: string;
  status?: string;
}

interface FindAllQueryFilterParams {
  search?: string;
  callType?: CallType | 'All';
  phoneBookItemType?: PhoneBookItemType;
  relationId?: string;
  relationType?: CrmRelationType;
  afterDate?: string;
  beforeDate?: string;
  callStatus?: CallStatus | 'All';
}

export const useCrmCallsQuery = () => {
  const facilityId = useAtomValue(selectedFacilityIdAtom);
  return {
    invalidateCallsQueries,
    findAllNewest: (
      filterParams: FindAllQueryFilterParams,
      afterDate?: string,
      options: UseQueryOptions<CrmCallModel[]> = {}
    ) =>
      useQuery<CrmCallModel[]>(
        [CRM_CALLS_FIND_ALL_QUERY_KEY, { facilityId, afterDate, filterParams }],
        async () => {
          const searchParams = getFindAllCallsSearchParams(
            { ...filterParams, ...(afterDate && { afterDate: afterDate }) },
            facilityId,
            10
          );
          const payloads = await fetchCRMCalls.get<CrmCallPayload[]>(`/calls`, {
            searchParams: searchParams
          });
          return payloads.map((payload) => new CrmCallModel(payload));
        },
        {
          enabled: !!facilityId,
          refetchInterval: 20 * 1000, // Need to poll for new calls
          ...options
        }
      ),
    findAllForAllUsers: (
      relationId: string,
      relationType: CrmRelationType,
      closeConnections = false,
      options: UseQueryOptions<CrmCallModel[]> = {}
    ) =>
      useQuery<CrmCallModel[]>(
        [
          CRM_CALLS_FIND_ALL_QUERY_KEY,
          { facilityId, relationId, relationType }
        ],
        async () => {
          const payloads = await fetchCRMCalls.get<CrmCallPayload[]>(`/calls`, {
            searchParams: {
              facility_id: facilityId,
              ...(relationId && { relation_id: relationId }),
              ...(relationType && { relation_type: relationType }),
              close_connections: closeConnections,
              all_users: true
            } as FindAllCallsRequest
          });
          return payloads.map((payload) => new CrmCallModel(payload));
        },
        {
          enabled: !!facilityId,
          ...options
        }
      ),
    findAllInfinite: (
      filterParams: FindAllQueryFilterParams,
      options: UseInfiniteQueryOptions<CrmCallModel[]> = {}
    ) =>
      useInfiniteQuery<CrmCallModel[]>(
        [CRM_CALLS_FIND_ALL_QUERY_KEY, { facilityId, filterParams }],
        async ({ pageParam }) => {
          const searchParams = getFindAllCallsSearchParams(
            { ...filterParams, ...(pageParam && { beforeDate: pageParam }) },
            facilityId,
            20
          );
          const payloads = await fetchCRMCalls.get<CrmCallPayload[]>(`/calls`, {
            searchParams: searchParams
          });
          return payloads.map((payload) => new CrmCallModel(payload));
        },
        {
          getNextPageParam: (lastPage) => {
            const lastCall = lastPage[lastPage.length - 1];
            if (!lastCall) {
              return false;
            }
            return lastCall.createdAt;
          },
          enabled: !!facilityId,
          ...options
        }
      ),
    findOne: (callId?: string, options: UseQueryOptions<CrmCallModel> = {}) =>
      useQuery<CrmCallModel>(
        [CRM_CALLS_FIND_ONE_QUERY_KEY, { callId }],
        async () => {
          const payload = await fetchCRMCalls.get<CrmCallPayload>(
            `/calls/${callId}`
          );
          return new CrmCallModel(payload);
        },
        {
          enabled: !!callId,
          ...options
        }
      ),

    mutations: {
      create: useMutation(
        async (params: Partial<CrmCallPayload>) => {
          const paramsWithFacilityId = {
            ...params,
            facility_id: facilityId
          };
          return await fetchCRMCalls.post<CrmCallPayload>(
            '/calls',
            paramsWithFacilityId
          );
        },
        {
          onSuccess: () => {
            invalidateCallsQueries();
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
          }
        }
      ),
      update: useMutation(
        async (payload: Partial<CrmCallPayload>) => {
          return await fetchCRMCalls.put<CrmCallPayload>(
            `/calls/${payload.id}`,
            omit(payload, ['crmRelation', 'note', 'phoneBookItem', 'user'])
          );
        },
        {
          onSuccess: invalidateCallsQueries
        }
      ),
      delete: useMutation(
        (id: string) => fetchCRMCalls.delete(`/calls/${id}`),
        {
          onSuccess: invalidateCallsQueries
        }
      )
    }
  };
};

export const useRefetchCallUntilCompleted = (callId: string) => {
  // If the call is not manual, and it was created recently, the state and transcript_sid might get updated.
  // We will refetch the call every 5 seconds and the last piece of data we need to stop refetching is the transcript_sid
  const [refetchCall, setRefetchCall] = useState(true);
  const { data: crmCall, isLoading: isLoadingCall } =
    useCrmCallsQuery().findOne(callId, {
      onSuccess: (data) => {
        shouldStopRefetching(data);
      },
      ...(refetchCall && { refetchInterval: 5 * 1000 })
    });

  useEffect(() => {
    if (crmCall) {
      shouldStopRefetching(crmCall);
    }
  }, []);

  const shouldStopRefetching = (call: CrmCallModel) => {
    if (
      call.call_sid === MANUAL_LOG_CALL_SID ||
      call?.ai_summary ||
      callFailedEndStatuses.includes(call.status)
    ) {
      setRefetchCall(false);
    }
  };

  return { crmCall, isLoadingCall };
};

const getFindAllCallsSearchParams = (
  filterParams: FindAllQueryFilterParams,
  facilityId: string | null,
  limit: number
): FindAllCallsRequest => {
  return {
    facility_id: facilityId,
    limit: limit,
    ...(filterParams.relationId && {
      relation_id: filterParams.relationId
    }),
    ...(filterParams.relationType && {
      relation_type: filterParams.relationType
    }),
    ...(filterParams.search && { search_text: filterParams.search }),
    ...(filterParams.callType &&
      filterParams.callType !== 'All' && {
        type: filterParams.callType
      }),
    ...(filterParams.phoneBookItemType &&
      filterParams.phoneBookItemType !== 'All' && {
        phonebook_type: filterParams.phoneBookItemType
      }),
    ...(filterParams.afterDate && { after_date: filterParams.afterDate }),
    ...(filterParams.beforeDate && { before_date: filterParams.beforeDate }),
    ...(filterParams.callStatus &&
      filterParams.callStatus !== 'All' && {
        status: filterParams.callStatus
      })
  } as FindAllCallsRequest;
};
