import startCase from 'lodash/startCase';
import uniqBy from 'lodash/uniqBy';

import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { GenericSqlRowOption } from '@/stores/residentFacesheetsAtom';
import { MedicationDoseSqlRow } from '@/stores/residentMedicationsAtom';

interface GetMedicationOptionsParams {
  medicationGeneric: GenericSqlRowOption | null;
  medicationRoute: GenericSqlRowOption | null;
  medicationForm: GenericSqlRowOption | null;
  medications: MedicationsPayload[];
}

interface GetMedicationOptionsReturn {
  filtered: MedicationsPayload[];
  generic: GenericSqlRowOption[];
  route: GenericSqlRowOption[];
  form: GenericSqlRowOption[];
  dose: MedicationDoseSqlRow[];
}

/**
 * @param medicationGeneric - selected option for field "Branded" ie "Generic Name"
 * @param medicationRoute - selected option for field "Route" ie "Oral"
 * @param medicationForm - selected option for field "Form" ie "Tablet"
 * @param medicationDose - selected option for field "Dose" ie "5 mg"
 * @param medications - all medications from the API for field "Medication" ie "Prozac"
 * @returns filtered medications and options for each field (generic, route,
 * form, dose) based on the selected options. For example, if the user selects
 * "Tablet" for Route, the options for Form will be filtered to only show forms
 * that are available in tablet form.
 */
export function getMedicationOptions({
  medicationGeneric,
  medicationRoute,
  medicationForm,
  medications
}: GetMedicationOptionsParams): GetMedicationOptionsReturn {
  let filteredRoutes: MedicationsPayload[] = [];
  let filteredForms: MedicationsPayload[] = [];
  let filteredDoses: MedicationsPayload[] = [];

  if (medicationGeneric?.id) {
    filteredRoutes = medications?.filter((v) => {
      return v.NameTypeCode == medicationGeneric.id;
    });
  }

  if (medicationRoute?.id) {
    filteredForms = filteredRoutes?.filter((v) => {
      return v.RouteID == medicationRoute.id;
    });
  }

  if (medicationForm?.id) {
    filteredDoses = filteredForms?.filter((v) => {
      return v.DoseFormID == medicationForm.id;
    });
  }

  return {
    filtered: filteredDoses,
    generic: uniqBy(
      medications
        .filter((v) => v.NameTypeCode)
        .map((v) => ({
          id: v.NameTypeCode!,
          name: v.NameTypeCodeDesc
        })),
      'id'
    ),
    route: uniqBy(
      filteredRoutes
        .filter((v) => v.RouteID)
        .map((v) => ({
          id: v.RouteID!,
          name: startCase(v.RouteDesc)
        })),
      'id'
    ),
    form: uniqBy(
      filteredForms
        .filter((v) => v.DoseFormID)
        .map((v) => ({
          id: v.DoseFormID!,
          name: startCase(v.DoseFormDesc)
        })),
      'id'
    ),
    dose: uniqBy(
      filteredDoses.map((v) => ({
        id: v.MedStrength,
        strength: `${v.MedStrength} ${v.MedStrengthUnit}`
      })),
      'id'
    ).sort((a, b) => parseInt(a.strength) - parseInt(b.strength))
  };
}
