import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { RRule } from 'rrule';
dayjs.extend(localizedFormat);

import {
  FrequencyTimeFormData,
  generateScheduleFormDataDefaultValues
} from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  customFrequencyOption,
  fiveTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  threeTimesADayFrequencyOption,
  twiceADayFrequencyOption
} from '@/components/FrequencyTimeForm/options';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { MedicationTaskSchedulePayload } from '@/hooks/useResidentMedications';
import { MedicationRegimenForm } from '@/stores/residentMedicationsAtom';
import { calculateFdbTotalDosage } from '@/utils/calculateTotalDosage';
import { getExecutionWindowTimeValue } from '@/utils/getExecutionWindowTimeValue';
import { transformPatternedRecurrenceToHumanText } from '@/utils/patternedRecurrenceFormatters';
import { transformRRuleStringToHumanText } from '@/utils/transformRRuleStringToHumanText';
import { transformSchedulePayloadToFrequencyTimeFormData } from '@/utils/transformSchedulePayloadToFrequencyTimeFormData';

import { PatternedRecurrenceEhr } from './PatternedRecurrenceEhr';

interface GetTotalDosageParams {
  nonCommunityDose: number | null;
  fdbDispensableDrug: MedicationsPayload;
  schedules: MedicationTaskSchedulePayload[];
}

interface MedicationRegimenModelConstructorParams {
  nonCommunityDose: number | null;
  fdbDispensableDrug: MedicationsPayload;
  schedules: MedicationTaskSchedulePayload[];
}

export class MedicationRegimenModel {
  id: string;
  fdbDispensableDrug: MedicationsPayload;
  schedules: MedicationTaskSchedulePayload[];
  date: string;
  dateStart: string;
  frequency: string;
  time: string;
  isCustom: boolean;
  isMultipleTimesADay: boolean;
  howManyTimesADay: number | false;
  totalDosage: string;

  constructor({
    nonCommunityDose,
    fdbDispensableDrug,
    schedules
  }: MedicationRegimenModelConstructorParams) {
    this.id = schedules[0].id;
    this.schedules = schedules;
    this.dateStart = schedules[0].instance_generation_start_date;
    this.date = this.getDate();
    this.isCustom = MedicationRegimenModel.areSchedulesCustom(schedules);
    // TODO: Figure out how to pass the shouldAddEndTime param from the print component
    const howManyTimesADay =
      MedicationRegimenModel.areSchedulesMultipleTimesADay(schedules);
    this.isMultipleTimesADay = !!howManyTimesADay;
    this.howManyTimesADay = howManyTimesADay;
    this.totalDosage = MedicationRegimenModel.calculateTotalDosage({
      nonCommunityDose,
      fdbDispensableDrug,
      schedules
    });
    this.frequency = MedicationRegimenModel.calculateFrequencyValue(schedules);
    this.time = MedicationRegimenModel.calculateTime(schedules).display;
  }

  getDate() {
    const startDate = this.schedules[0].instance_generation_start_date;

    if (this.schedules[0].rrule) {
      return MedicationRegimenModel.formatDate({
        startDate: startDate,
        recurrence: this.schedules[0].rrule,
        isRRule: true
      });
    } else if (this.schedules[0].patterned_recurrence) {
      return MedicationRegimenModel.formatDate({
        startDate: startDate,
        recurrence: this.schedules[0].patterned_recurrence,
        isRRule: false
      });
    }
    return '';
  }

  /**
   * Given properties of the resident's medication task from the API, this
   * method will return an array of MedicationRegimenModel instances. The
   * regimens are sorted by the instance_generation_start_date of the schedule.
   * @param nonCommunityDose MedicationTaskModel.non_community_dose
   * @param fdbDispensableDrug MedicationTaskModel.fdb_dispensable_drug
   * @param schedules MedicationTaskModel.schedules
   * @returns An array of MedicationRegimenModel instances
   */
  static constructFromSchedules = ({
    nonCommunityDose,
    fdbDispensableDrug,
    schedules
  }: MedicationRegimenModelConstructorParams): MedicationRegimenModel[] => {
    const schedulesByStartDate =
      MedicationRegimenModel.mapSchedulesByInstanceGenerationStartDate(
        schedules
      );
    return Array?.from(schedulesByStartDate?.keys() ?? [])
      .sort()
      .map((startDate) => {
        const schedules = schedulesByStartDate.get(startDate)!;
        return new MedicationRegimenModel({
          nonCommunityDose,
          fdbDispensableDrug,
          schedules
        });
      });
  };

  /**
   * Given an array of schedules from the API, this method maps the schedules
   * according to their instance_generation_start_date. The medication form
   * should not allow any regimens to have the same start date or else the
   * partition won't work correctly.
   */
  static mapSchedulesByInstanceGenerationStartDate = (
    schedules: MedicationTaskSchedulePayload[]
  ): Map<string, MedicationTaskSchedulePayload[]> =>
    schedules?.reduce((accum, schedule) => {
      if (accum.has(schedule.instance_generation_start_date)) {
        accum.get(schedule.instance_generation_start_date)?.push?.(schedule);
      } else {
        accum.set(schedule.instance_generation_start_date, [schedule]);
      }
      return accum;
    }, new Map<string, MedicationTaskSchedulePayload[]>());

  static areSchedulesMultipleTimesADay = (
    schedules: MedicationTaskSchedulePayload[]
  ): number | false => {
    if (
      schedules?.every((schedule) => {
        if (!schedule.rrule) return false;
        const rruleOptions = RRule.parseString(schedule.rrule);
        // Custom and weekly schedules can only have a byweekday rrule option
        return !rruleOptions.byweekday && rruleOptions.interval === 1;
      })
    ) {
      return schedules.length > 1 ? schedules.length : false;
    }
    return false;
  };

  static areSchedulesCustom = (
    schedules: MedicationTaskSchedulePayload[]
  ): boolean =>
    schedules.every((schedule) => {
      if (!schedule.rrule) return false;
      const rruleOptions = RRule.parseString(schedule.rrule);
      return rruleOptions.byweekday;
    }) && schedules.length > 1;

  static calculateTotalDosage = ({
    nonCommunityDose,
    fdbDispensableDrug,
    schedules
  }: GetTotalDosageParams): string => {
    if (nonCommunityDose && !schedules.length) {
      return calculateFdbTotalDosage(
        fdbDispensableDrug,
        nonCommunityDose
      ) as string;
    }
    if (schedules.length > 1) {
      return 'Custom';
    }
    return calculateFdbTotalDosage(
      fdbDispensableDrug,
      schedules[0].number_of_dose_units
    ) as string;
  };

  static calculateFrequencyValue = (
    schedules: MedicationTaskSchedulePayload[],
    shouldAddEndTime = false
  ): string => {
    if (!schedules.length) {
      return 'Informational';
    }

    const howManyTimesADay =
      MedicationRegimenModel.areSchedulesMultipleTimesADay(schedules);

    if (howManyTimesADay === 2) {
      return 'Twice a day';
    }

    if (howManyTimesADay === 3) {
      return 'Three times a day';
    }

    if (howManyTimesADay === 4) {
      return 'Four times a day';
    }

    if (howManyTimesADay === 5) {
      return 'Five times a day';
    }

    if (schedules.length > 1) {
      return 'Custom';
    }

    const endTime =
      shouldAddEndTime && schedules[0].execution_window_end_time
        ? schedules[0].execution_window_end_time
        : '';

    if (schedules[0].rrule) {
      return transformRRuleStringToHumanText(schedules[0].rrule, endTime);
    } else if (schedules[0].patterned_recurrence) {
      return transformPatternedRecurrenceToHumanText(
        schedules[0].patterned_recurrence
      );
    }
    return '';
  };

  // TODO: Figure out how to pass the last completed date for PRN meds
  static calculateTime = (
    schedules: MedicationTaskSchedulePayload[]
  ): { value: number; display: string } => {
    if (!schedules.length) {
      return {
        value: 0,
        display: 'N/A'
      };
    }
    if (schedules.length > 1) {
      return {
        value: 0,
        display: 'Custom'
      };
    }
    return getExecutionWindowTimeValue({
      format: 'LLLL',
      // date_completed: this.prnMedTaskInstance?.date_completed ?? null,
      execution_window_start_time: schedules[0].execution_window_start_time,
      execution_window_end_time: schedules[0].execution_window_end_time
    });
  };

  static formatDate = ({
    startDate,
    recurrence,
    isRRule
  }: {
    startDate: string;
    recurrence: string | PatternedRecurrenceEhr;
    isRRule: boolean;
  }) => {
    const startDayjs = dayjs(startDate);
    let until: string | undefined | Date | null;
    if (isRRule) {
      const { until: rruleUntil } = RRule.parseString(recurrence as string);
      until = rruleUntil;
    } else {
      until = (recurrence as PatternedRecurrenceEhr).range.endDate;
    }
    const endDayjs = until ? dayjs(until) : null;
    const startFormat = startDayjs.format('MMM D');
    const endFormat = endDayjs?.format('MMM D');

    return endFormat ? `${startFormat} - ${endFormat}` : startFormat;
  };

  public toForm = (): MedicationRegimenForm => {
    const regimen: MedicationRegimenForm = {
      frequency: {} as FrequencyTimeFormData,
      schedule: generateScheduleFormDataDefaultValues()
    };

    if (this.schedules.length > 1) {
      regimen.frequency!.freq =
        this.howManyTimesADay === 2
          ? twiceADayFrequencyOption.value
          : this.howManyTimesADay === 3
          ? threeTimesADayFrequencyOption.value
          : this.howManyTimesADay === 4
          ? fourTimesADayFrequencyOption.value
          : this.howManyTimesADay == 5
          ? fiveTimesADayFrequencyOption.value
          : customFrequencyOption.value;

      this.schedules.forEach((schedule) => {
        const { dtstart, until } =
          transformSchedulePayloadToFrequencyTimeFormData(
            schedule,
            regimen.schedule!
          );
        // HACK: Set the top level frequency's dtstart and until to one of the schedule rrule's dtstart and until.
        // Each schedule should have the same dtstart and until, so it doesn't matter which one we pick.
        regimen.frequency!.dtstart = dtstart;
        regimen.frequency!.until = until;
      });
    } else if (this.schedules?.length === 1) {
      // For single schedules, the frequency property should be fully set
      regimen.frequency = transformSchedulePayloadToFrequencyTimeFormData(
        this.schedules[0]
      );
    }

    return regimen;
  };
}
