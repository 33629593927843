import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

const AddIncidentPage = loadable(() => import("@/pages/Incidents/AddIncidentPage")); // prettier-ignore
const AddResidentIncidentPage = loadable(() => import("@/pages/Incidents/AddIncidentPage")); // prettier-ignore
const LogResidentIncidentPage = loadable(() => import("@/pages/ResidentPage/ResidentIncidentsV2Page/LogIncidentPage")); // prettier-ignore
const ResidentPostIncidentPage = loadable(() => import("@/pages/ResidentPage/ResidentIncidentsV2Page/PostIncidentPage")); // prettier-ignore
const IncidentsPage = loadable(() => import("@/pages/Incidents")); // prettier-ignore
const ViewIncidentPage = loadable(() => import("@/pages/Incidents/ViewIncidentSummary")); // prettier-ignore

export enum IncidentRoutes {
  Incidents = '/incidents',
  AddIncident = '/incidents/add-incident/',
  AddResidentIncident = '/resident/:resident_id/incidents/add-incident',
  LogResidentIncident = '/resident/:resident_id/incidents/log-incident',
  ResidentPostIncident = '/resident/:resident_id/incidents/post-incident/:incident_id',
  ViewIncident = '/incidents/view/:incident_id'
}

export const getEhrIncidentRoutes = (): RouteObject[] => {
  return [
    {
      path: IncidentRoutes.AddResidentIncident,
      element: <AddResidentIncidentPage />
    },
    {
      path: IncidentRoutes.LogResidentIncident,
      element: <LogResidentIncidentPage />
    },
    {
      path: IncidentRoutes.ResidentPostIncident,
      element: <ResidentPostIncidentPage />
    },
    {
      path: IncidentRoutes.Incidents,
      element: <ProtectedRoute component={IncidentsPage} role="L0.1" />
    },
    {
      path: IncidentRoutes.AddIncident,
      element: <ProtectedRoute role="L4" component={AddIncidentPage} />
    },
    {
      path: IncidentRoutes.ViewIncident,
      element: <ProtectedRoute role="L4" component={ViewIncidentPage} />
    }
  ];
};
