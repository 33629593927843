/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CriteriaQualifier } from './CriteriaQualifier';
import {
    CriteriaQualifierFromJSON,
    CriteriaQualifierFromJSONTyped,
    CriteriaQualifierToJSON,
} from './CriteriaQualifier';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface AnalysisResult
 */
export interface AnalysisResult {
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    lead_id: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    query: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    criteria_result: AnalysisResultCriteriaResultEnum;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    reasoning: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    date: string;
    /**
     * 
     * @type {CriteriaQualifier}
     * @memberof AnalysisResult
     */
    qualifier: CriteriaQualifier;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof AnalysisResult
     */
    visual_references?: Array<VisualReference>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    deleted_at?: string;
}


/**
 * @export
 */
export const AnalysisResultCriteriaResultEnum = {
    Pass: 'Pass',
    Fail: 'Fail',
    Unknown: 'Unknown',
    NA: 'N/A'
} as const;
export type AnalysisResultCriteriaResultEnum = typeof AnalysisResultCriteriaResultEnum[keyof typeof AnalysisResultCriteriaResultEnum];


/**
 * Check if a given object implements the AnalysisResult interface.
 */
export function instanceOfAnalysisResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "lead_id" in value;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "criteria_result" in value;
    isInstance = isInstance && "reasoning" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "qualifier" in value;

    return isInstance;
}

export function AnalysisResultFromJSON(json: any): AnalysisResult {
    return AnalysisResultFromJSONTyped(json, false);
}

export function AnalysisResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lead_id': json['lead_id'],
        'query': json['query'],
        'criteria_result': json['criteria_result'],
        'reasoning': json['reasoning'],
        'date': json['date'],
        'qualifier': CriteriaQualifierFromJSON(json['qualifier']),
        'visual_references': !exists(json, 'visual_references') ? undefined : ((json['visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : json['deleted_at'],
    };
}

export function AnalysisResultToJSON(value?: AnalysisResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'query': value.query,
        'criteria_result': value.criteria_result,
        'reasoning': value.reasoning,
        'date': value.date,
        'qualifier': CriteriaQualifierToJSON(value.qualifier),
        'visual_references': value.visual_references === undefined ? undefined : ((value.visual_references as Array<any>).map(VisualReferenceToJSON)),
        'deleted_at': value.deleted_at,
    };
}

