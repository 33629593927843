import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { residentsResourcesClient } from '@/adapters/schemaClients';
import {
  FindAllTaskExtensionTagsRequest,
  TaskExtensionTag
} from '@/types/resident-resources';

const FIND_ALL_TASK_EXTENSION_TAGS_QUERY_KEY = 'findAllTaskExtensionTags';

export const useTaskExtensionTagsQuery = () => {
  return {
    findAll: (
      params: FindAllTaskExtensionTagsRequest,
      options: UseQueryOptions<TaskExtensionTag[], HTTPError> = {}
    ) =>
      useQuery<TaskExtensionTag[], HTTPError>(
        [FIND_ALL_TASK_EXTENSION_TAGS_QUERY_KEY, params],
        async () => {
          const response =
            await residentsResourcesClient.findAllTaskExtensionTags(params);
          return response.data!;
        },
        {
          ...options,
          enabled: !!params.facilityId
        }
      )
  };
};
