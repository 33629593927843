/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillReceiptSettingsIncludeFacilityEntry
 */
export interface BillReceiptSettingsIncludeFacilityEntry {
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludeFacilityEntry
     */
    field?: BillReceiptSettingsIncludeFacilityEntryFieldEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BillReceiptSettingsIncludeFacilityEntry
     */
    value?: boolean;
}


/**
 * @export
 */
export const BillReceiptSettingsIncludeFacilityEntryFieldEnum = {
    Name: 'include_facility_name',
    Address: 'include_facility_address',
    Phone: 'include_facility_phone'
} as const;
export type BillReceiptSettingsIncludeFacilityEntryFieldEnum = typeof BillReceiptSettingsIncludeFacilityEntryFieldEnum[keyof typeof BillReceiptSettingsIncludeFacilityEntryFieldEnum];


/**
 * Check if a given object implements the BillReceiptSettingsIncludeFacilityEntry interface.
 */
export function instanceOfBillReceiptSettingsIncludeFacilityEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillReceiptSettingsIncludeFacilityEntryFromJSON(json: any): BillReceiptSettingsIncludeFacilityEntry {
    return BillReceiptSettingsIncludeFacilityEntryFromJSONTyped(json, false);
}

export function BillReceiptSettingsIncludeFacilityEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillReceiptSettingsIncludeFacilityEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function BillReceiptSettingsIncludeFacilityEntryToJSON(value?: BillReceiptSettingsIncludeFacilityEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
    };
}

