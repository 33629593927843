import React from 'react';
import { Timer } from '@mui/icons-material';
import { Fade, ListItemIcon, ListItemText } from '@mui/material';
import dayjs from 'dayjs';

import { StyledNavButton } from '@/components/Layout/Sidebar';
import { useTimeClock } from '@/hooks/useTimeClock';

import { showClockInDialog } from './ClockInDialog';

interface TimeClockSidebarButtonProps {
  expanded: boolean;
}

export const TimeClockSidebarButton: React.FC<TimeClockSidebarButtonProps> = ({
  expanded
}) => {
  const { isLoading, data } = useTimeClock().queryClockStatus();

  if (isLoading) {
    return null;
  }

  if (data?.date) {
    return <ClockedIn expanded={expanded} date={data.date} />;
  }

  return <ClockedOut expanded={expanded} />;
};

type ClockedInProps = TimeClockSidebarButtonProps & {
  date: string;
};

export const hoursAndMinutesFromDate = (date: string | Date | dayjs.Dayjs) => {
  const clockInTimestamp = dayjs(date);
  let now = dayjs();
  const hours = now.diff(clockInTimestamp, 'hours');
  if (hours >= 1) {
    now = now.subtract(hours, 'hours');
  }
  const minutes = now
    .diff(clockInTimestamp, 'minutes')
    .toString()
    .padStart(2, '0');
  return { hours, minutes };
};

const ClockedIn: React.FC<ClockedInProps> = ({ expanded, date }) => {
  const [counter, setCounter] = React.useState('');

  // Update the hours and minutes from the date every 10 seconds
  React.useEffect(() => {
    const { hours, minutes } = hoursAndMinutesFromDate(date);
    setCounter(`Clock Out \u2013 ${hours}hrs${minutes}min`);
    const interval = setInterval(() => {
      const { hours, minutes } = hoursAndMinutesFromDate(date);
      setCounter(`Clock Out \u2013 ${hours}hrs${minutes}min`);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledNavButton
      id="time-clock-out-button"
      sx={{ backgroundColor: '#FCFEFF' }}
      onClick={async () => {
        const stream = await showClockInDialog({ action: 'out' });
        stream?.getTracks().forEach((track) => track.stop());
      }}>
      <ListItemIcon sx={{ minWidth: 'unset' }}>
        <Timer sx={{ color: '#364955' }} />
      </ListItemIcon>
      {expanded && (
        <Fade in={expanded} easing="cubic-bezier(0.95, 0.05, 0.795, 0.035)">
          <ListItemText
            sx={{ paddingLeft: 1.5 }}
            primary={counter}
            primaryTypographyProps={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 500,
              color: '#364955'
            }}
          />
        </Fade>
      )}
    </StyledNavButton>
  );
};

const ClockedOut: React.FC<TimeClockSidebarButtonProps> = ({ expanded }) => {
  return (
    <StyledNavButton
      id="time-clock-in-button"
      onClick={async () => {
        const stream = await showClockInDialog({ action: 'in' });
        stream?.getTracks().forEach((track) => track.stop());
      }}
      sx={{
        backgroundColor: '#364955',
        border: '1px solid #D4DEE7',
        justifyContent: 'center',
        '& .MuiListItemText-root': {
          flex: 'unset'
        },
        ':hover': {
          backgroundColor: '#364955'
        }
      }}>
      <ListItemIcon sx={{ minWidth: 'unset' }}>
        <Timer sx={{ color: '#FCFEFF !important' }} />
      </ListItemIcon>
      {expanded && (
        <Fade in={expanded} easing="cubic-bezier(0.95, 0.05, 0.795, 0.035)">
          <ListItemText
            sx={{ pl: 1 }}
            primary="Clock in"
            primaryTypographyProps={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#FCFEFF !important'
            }}
          />
        </Fade>
      )}
    </StyledNavButton>
  );
};
