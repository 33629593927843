/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentRecordType = {
    Cash: 'Cash',
    Check: 'Check',
    Other: 'Other',
    Card: 'Card',
    BankAccount: 'Bank Account',
    PaymentLink: 'Payment Link'
} as const;
export type PaymentRecordType = typeof PaymentRecordType[keyof typeof PaymentRecordType];


export function PaymentRecordTypeFromJSON(json: any): PaymentRecordType {
    return PaymentRecordTypeFromJSONTyped(json, false);
}

export function PaymentRecordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentRecordType {
    return json as PaymentRecordType;
}

export function PaymentRecordTypeToJSON(value?: PaymentRecordType | null): any {
    return value as any;
}

