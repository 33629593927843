/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GgFunctionScore } from './GgFunctionScore';
import {
    GgFunctionScoreFromJSON,
    GgFunctionScoreFromJSONTyped,
    GgFunctionScoreToJSON,
} from './GgFunctionScore';

/**
 * 
 * @export
 * @interface MedicareGgScores
 */
export interface MedicareGgScores {
    /**
     * 
     * @type {Array<GgFunctionScore>}
     * @memberof MedicareGgScores
     */
    eating?: Array<GgFunctionScore>;
    /**
     * 
     * @type {any}
     * @memberof MedicareGgScores
     */
    oral_hygiene?: any | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareGgScores
     */
    toileting?: any | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareGgScores
     */
    bed_mobility?: any | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareGgScores
     */
    transfer?: any | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareGgScores
     */
    walking?: any | null;
}

/**
 * Check if a given object implements the MedicareGgScores interface.
 */
export function instanceOfMedicareGgScores(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicareGgScoresFromJSON(json: any): MedicareGgScores {
    return MedicareGgScoresFromJSONTyped(json, false);
}

export function MedicareGgScoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareGgScores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eating': !exists(json, 'eating') ? undefined : ((json['eating'] as Array<any>).map(GgFunctionScoreFromJSON)),
        'oral_hygiene': !exists(json, 'oral_hygiene') ? undefined : json['oral_hygiene'],
        'toileting': !exists(json, 'toileting') ? undefined : json['toileting'],
        'bed_mobility': !exists(json, 'bed_mobility') ? undefined : json['bed_mobility'],
        'transfer': !exists(json, 'transfer') ? undefined : json['transfer'],
        'walking': !exists(json, 'walking') ? undefined : json['walking'],
    };
}

export function MedicareGgScoresToJSON(value?: MedicareGgScores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eating': value.eating === undefined ? undefined : ((value.eating as Array<any>).map(GgFunctionScoreToJSON)),
        'oral_hygiene': value.oral_hygiene,
        'toileting': value.toileting,
        'bed_mobility': value.bed_mobility,
        'transfer': value.transfer,
        'walking': value.walking,
    };
}

