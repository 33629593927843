/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDocumentExclusionTermRequest
 */
export interface UpdateDocumentExclusionTermRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentExclusionTermRequest
     */
    term: string;
}

/**
 * Check if a given object implements the UpdateDocumentExclusionTermRequest interface.
 */
export function instanceOfUpdateDocumentExclusionTermRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "term" in value;

    return isInstance;
}

export function UpdateDocumentExclusionTermRequestFromJSON(json: any): UpdateDocumentExclusionTermRequest {
    return UpdateDocumentExclusionTermRequestFromJSONTyped(json, false);
}

export function UpdateDocumentExclusionTermRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDocumentExclusionTermRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'term': json['term'],
    };
}

export function UpdateDocumentExclusionTermRequestToJSON(value?: UpdateDocumentExclusionTermRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'term': value.term,
    };
}

