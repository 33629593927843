/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PtOtCaseMixGroup = {
    Ta: 'TA',
    Tb: 'TB',
    Tc: 'TC',
    Td: 'TD',
    Te: 'TE',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Ti: 'TI',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP'
} as const;
export type PtOtCaseMixGroup = typeof PtOtCaseMixGroup[keyof typeof PtOtCaseMixGroup];


export function PtOtCaseMixGroupFromJSON(json: any): PtOtCaseMixGroup {
    return PtOtCaseMixGroupFromJSONTyped(json, false);
}

export function PtOtCaseMixGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtCaseMixGroup {
    return json as PtOtCaseMixGroup;
}

export function PtOtCaseMixGroupToJSON(value?: PtOtCaseMixGroup | null): any {
    return value as any;
}

