import React, { useCallback, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MonitorHeart } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Link,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { FormHeader } from '@/components/FormHeader';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ORDERED_VITALS_BY_ID } from '@/constants';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { useVitalTypesQuery } from '@/hooks/useVitalsQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';

import { MedicationVitalsForm } from './MedicationVitalsForm';

interface MedicationVitalsProps {
  // Warning: Don't type this as ResidentMedicationForm or memory usage will spike
  formMethods: UseFormReturn<any>;
  handleBack: VoidFunction;
  handleToDetailsLink: VoidFunction;
  handleToTimeLink: VoidFunction;
  handleSave: VoidFunction;
  type: MedicationDrawerProps['type'];
  isSaving?: boolean;
  responsibleParties?: ResponsibleParty[];
}

/**
 * Keep vitals in the form state even if the user toggles vitals off. If they
 * toggle it back on, they don't have to re-enter all the information. When we
 * serialize form state to JSON payload, we can first check if vitals are
 * enabled, and if so, we can serialize the vitals data. If the user selected
 * a PRN freqency on the time step, then we should still serialize the vitals.
 */
export const MedicationVitals: React.FC<MedicationVitalsProps> = ({
  formMethods,
  handleBack,
  handleToDetailsLink,
  handleToTimeLink,
  handleSave,
  type,
  isSaving,
  responsibleParties
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const { data: vitals = [] } = useVitalTypesQuery().findAll({
    select: (vitals) =>
      vitals
        .filter((vital) => ORDERED_VITALS_BY_ID.includes(vital.id))
        .sort(
          (a, b) =>
            ORDERED_VITALS_BY_ID.indexOf(a.id) -
            ORDERED_VITALS_BY_ID.indexOf(b.id)
        )
  });
  const [vitalsEnabled, isEditable] = formMethods.watch([
    'vitalsEnabled',
    'is_editable'
  ]);
  const responsibleParty = formMethods.getValues('responsible_party');
  const isPrnFrequency = formMethods.getValues('frequency')?.freq === 'prn';

  const isStaffResponsibility = useCallback(isStaffResponsibilityV2, [
    responsibleParty,
    responsibleParties
  ]);

  return (
    <>
      <Grid container spacing={2}>
        {!isStaffResponsibility(responsibleParty, responsibleParties) ? (
          <Grid xs={12}>
            <Alert severity="info" data-testid="non-staff-party-alert-vitals">
              <AlertTitle>Non-Staff Responsible party selected</AlertTitle>
              Medication vital requirements are not applicable for non-staff
              responsible parties. If you would like to require vital readings
              for a med pass, please select a different responsible party{' '}
              <Link href="#" onClick={handleToDetailsLink}>
                here
              </Link>
              .
            </Alert>
          </Grid>
        ) : isPrnFrequency ? (
          <Grid xs={12}>
            <Alert severity="info" data-testid="prn-frequency-alert-vitals">
              <AlertTitle>PRN schedule selected</AlertTitle>
              Medication vital requirements are not applicable for PRN
              medications. If you would like to require vital readings for a med
              pass, please select a different medication schedule{' '}
              <Link href="#" onClick={handleToTimeLink}>
                here
              </Link>
              .
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid xs={12}>
              <FormHeader Icon={MonitorHeart} text="Vitals" />
            </Grid>
            <Grid xs={9}>
              <Typography color="secondary" fontSize="1rem">
                Would you like any vitals to be checked before administering the
                drug?
              </Typography>
            </Grid>
            <Grid xs={3} sx={{ display: 'flex', gap: 1 }}>
              <Button
                onClick={() => formMethods.setValue('vitalsEnabled', true)}
                size="small"
                color={vitalsEnabled ? 'primary' : 'secondary'}
                variant={'outlined'}
                disabled={isResidentArchived || !isEditable}
                sx={{ width: 60 }}>
                Yes
              </Button>
              <Button
                onClick={() => formMethods.setValue('vitalsEnabled', false)}
                size="small"
                color={!vitalsEnabled ? 'primary' : 'secondary'}
                disabled={isResidentArchived || !isEditable}
                variant={'outlined'}
                sx={{ width: 60 }}>
                No
              </Button>
            </Grid>
            <Collapse in={vitalsEnabled}>
              <Grid container spacing={2} m={0}>
                <Grid xs={12}>
                  <Typography color="secondary" fontSize="1rem">
                    Please select any or all of the vitals you would like to
                    track and their required min/max limits if necessary.
                  </Typography>
                </Grid>
                {vitals?.map((vital) => (
                  <MedicationVitalsForm
                    key={vital.id}
                    formMethods={formMethods}
                    disabled={isResidentArchived || !isEditable}
                    vital={vital}
                  />
                ))}
              </Grid>
            </Collapse>
          </>
        )}
      </Grid>
      {type === 'Add' && (
        <DrawerFooter>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <LoadingButton
            data-testid="medication-drawer-save-button"
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave();
            }}
            loading={isSaving}>
            Save
          </LoadingButton>
        </DrawerFooter>
      )}
    </>
  );
};
