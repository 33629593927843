/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllEmailAddressBookResponse } from './FindAllEmailAddressBookResponse';
import {
    FindAllEmailAddressBookResponseFromJSON,
    FindAllEmailAddressBookResponseFromJSONTyped,
    FindAllEmailAddressBookResponseToJSON,
} from './FindAllEmailAddressBookResponse';

/**
 * 
 * @export
 * @interface FindAllEmailAddressBook200Response
 */
export interface FindAllEmailAddressBook200Response {
    /**
     * 
     * @type {FindAllEmailAddressBookResponse}
     * @memberof FindAllEmailAddressBook200Response
     */
    data?: FindAllEmailAddressBookResponse;
}

/**
 * Check if a given object implements the FindAllEmailAddressBook200Response interface.
 */
export function instanceOfFindAllEmailAddressBook200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllEmailAddressBook200ResponseFromJSON(json: any): FindAllEmailAddressBook200Response {
    return FindAllEmailAddressBook200ResponseFromJSONTyped(json, false);
}

export function FindAllEmailAddressBook200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllEmailAddressBook200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindAllEmailAddressBookResponseFromJSON(json['data']),
    };
}

export function FindAllEmailAddressBook200ResponseToJSON(value?: FindAllEmailAddressBook200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindAllEmailAddressBookResponseToJSON(value.data),
    };
}

