export class ShiftBlockPayableTotalsPayload {
  manually_created_shift_time: string;
  name: string;
  overridden_shift_time: string;
  time_clocked_in: string;
  time_clocked_out: string;
  total_shifts: string;
  total_time: string;
  total_time_without_breaks: string;
  constructor(payload: ShiftBlockPayableTotalsPayload) {
    Object.assign(this, payload);
  }
}

export class ShiftBlockPayableTotalsModel extends ShiftBlockPayableTotalsPayload {
  constructor(payload: ShiftBlockPayableTotalsPayload) {
    super(payload);
  }
}
