/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCrmNotificationStatus
 */
export interface UserCrmNotificationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof UserCrmNotificationStatus
     */
    enable: boolean;
}

/**
 * Check if a given object implements the UserCrmNotificationStatus interface.
 */
export function instanceOfUserCrmNotificationStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enable" in value;

    return isInstance;
}

export function UserCrmNotificationStatusFromJSON(json: any): UserCrmNotificationStatus {
    return UserCrmNotificationStatusFromJSONTyped(json, false);
}

export function UserCrmNotificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCrmNotificationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enable': json['enable'],
    };
}

export function UserCrmNotificationStatusToJSON(value?: UserCrmNotificationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable': value.enable,
    };
}

