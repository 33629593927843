/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllPrescriptionOrders200Response,
  FindAllPrescriptions200Response,
  FindOnePrescriptionOrder200Response,
  LinkMedicationTaskRequest,
} from '../models/index';
import {
    FindAllPrescriptionOrders200ResponseFromJSON,
    FindAllPrescriptionOrders200ResponseToJSON,
    FindAllPrescriptions200ResponseFromJSON,
    FindAllPrescriptions200ResponseToJSON,
    FindOnePrescriptionOrder200ResponseFromJSON,
    FindOnePrescriptionOrder200ResponseToJSON,
    LinkMedicationTaskRequestFromJSON,
    LinkMedicationTaskRequestToJSON,
} from '../models/index';

export interface FindAllPrescriptionOrdersRequest {
    facilityId: string;
    page?: number;
    limit?: number;
    linkStatus?: string;
    residentSearchText?: string;
    drugSearchText?: string;
}

export interface FindAllPrescriptionsRequest {
    facilityId?: string;
    residentSearchText?: string;
    drugSearchText?: string;
    residentGroupIds?: string;
    orderStatus?: string;
    medAdminStatus?: string;
    controlledSubstance?: boolean;
    residentIds?: string;
    actions?: string;
    showDiscontinued?: string;
    page?: number;
    limit?: number;
}

export interface FindOnePrescriptionOrderRequest {
    id: string;
}

export interface LinkMedicationTaskOperationRequest {
    id: string;
    linkMedicationTaskRequest?: LinkMedicationTaskRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Find All Prescription Orders
     */
    async findAllPrescriptionOrdersRaw(requestParameters: FindAllPrescriptionOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllPrescriptionOrders200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllPrescriptionOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.linkStatus !== undefined) {
            queryParameters['link_status'] = requestParameters.linkStatus;
        }

        if (requestParameters.residentSearchText !== undefined) {
            queryParameters['resident_search_text'] = requestParameters.residentSearchText;
        }

        if (requestParameters.drugSearchText !== undefined) {
            queryParameters['drug_search_text'] = requestParameters.drugSearchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptions/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllPrescriptionOrders200ResponseFromJSON(jsonValue));
    }

    /**
     * Find All Prescription Orders
     */
    async findAllPrescriptionOrders(requestParameters: FindAllPrescriptionOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllPrescriptionOrders200Response> {
        const response = await this.findAllPrescriptionOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all prescriptions
     */
    async findAllPrescriptionsRaw(requestParameters: FindAllPrescriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllPrescriptions200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.residentSearchText !== undefined) {
            queryParameters['resident_search_text'] = requestParameters.residentSearchText;
        }

        if (requestParameters.drugSearchText !== undefined) {
            queryParameters['drug_search_text'] = requestParameters.drugSearchText;
        }

        if (requestParameters.residentGroupIds !== undefined) {
            queryParameters['resident_group_ids'] = requestParameters.residentGroupIds;
        }

        if (requestParameters.orderStatus !== undefined) {
            queryParameters['order_status'] = requestParameters.orderStatus;
        }

        if (requestParameters.medAdminStatus !== undefined) {
            queryParameters['med_admin_status'] = requestParameters.medAdminStatus;
        }

        if (requestParameters.controlledSubstance !== undefined) {
            queryParameters['controlled_substance'] = requestParameters.controlledSubstance;
        }

        if (requestParameters.residentIds !== undefined) {
            queryParameters['resident_ids'] = requestParameters.residentIds;
        }

        if (requestParameters.actions !== undefined) {
            queryParameters['actions'] = requestParameters.actions;
        }

        if (requestParameters.showDiscontinued !== undefined) {
            queryParameters['show_discontinued'] = requestParameters.showDiscontinued;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptions/linked`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllPrescriptions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all prescriptions
     */
    async findAllPrescriptions(requestParameters: FindAllPrescriptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllPrescriptions200Response> {
        const response = await this.findAllPrescriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find Prescription Order
     */
    async findOnePrescriptionOrderRaw(requestParameters: FindOnePrescriptionOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOnePrescriptionOrder200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findOnePrescriptionOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescription-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOnePrescriptionOrder200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Prescription Order
     */
    async findOnePrescriptionOrder(requestParameters: FindOnePrescriptionOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOnePrescriptionOrder200Response> {
        const response = await this.findOnePrescriptionOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link Medication Task
     */
    async linkMedicationTaskRaw(requestParameters: LinkMedicationTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling linkMedicationTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prescriptions/{id}/link-med-task`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkMedicationTaskRequestToJSON(requestParameters.linkMedicationTaskRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Link Medication Task
     */
    async linkMedicationTask(requestParameters: LinkMedicationTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkMedicationTaskRaw(requestParameters, initOverrides);
    }

}
