/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicareAssessmentFormOptions } from './MedicareAssessmentFormOptions';
import {
    MedicareAssessmentFormOptionsFromJSON,
    MedicareAssessmentFormOptionsFromJSONTyped,
    MedicareAssessmentFormOptionsToJSON,
} from './MedicareAssessmentFormOptions';

/**
 * 
 * @export
 * @interface GetMedicareAssessmentFormOptions200Response
 */
export interface GetMedicareAssessmentFormOptions200Response {
    /**
     * 
     * @type {MedicareAssessmentFormOptions}
     * @memberof GetMedicareAssessmentFormOptions200Response
     */
    data?: MedicareAssessmentFormOptions;
}

/**
 * Check if a given object implements the GetMedicareAssessmentFormOptions200Response interface.
 */
export function instanceOfGetMedicareAssessmentFormOptions200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetMedicareAssessmentFormOptions200ResponseFromJSON(json: any): GetMedicareAssessmentFormOptions200Response {
    return GetMedicareAssessmentFormOptions200ResponseFromJSONTyped(json, false);
}

export function GetMedicareAssessmentFormOptions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicareAssessmentFormOptions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : MedicareAssessmentFormOptionsFromJSON(json['data']),
    };
}

export function GetMedicareAssessmentFormOptions200ResponseToJSON(value?: GetMedicareAssessmentFormOptions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': MedicareAssessmentFormOptionsToJSON(value.data),
    };
}

