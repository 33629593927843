/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssessmentCareUnitsType = {
    Points: 'points',
    Dollars: 'dollars'
} as const;
export type AssessmentCareUnitsType = typeof AssessmentCareUnitsType[keyof typeof AssessmentCareUnitsType];


export function AssessmentCareUnitsTypeFromJSON(json: any): AssessmentCareUnitsType {
    return AssessmentCareUnitsTypeFromJSONTyped(json, false);
}

export function AssessmentCareUnitsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCareUnitsType {
    return json as AssessmentCareUnitsType;
}

export function AssessmentCareUnitsTypeToJSON(value?: AssessmentCareUnitsType | null): any {
    return value as any;
}

