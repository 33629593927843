/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlan } from './CarePlan';
import {
    CarePlanFromJSON,
    CarePlanFromJSONTyped,
    CarePlanToJSON,
} from './CarePlan';

/**
 * 
 * @export
 * @interface GetCarePlan200Response
 */
export interface GetCarePlan200Response {
    /**
     * 
     * @type {CarePlan}
     * @memberof GetCarePlan200Response
     */
    data?: CarePlan;
}

/**
 * Check if a given object implements the GetCarePlan200Response interface.
 */
export function instanceOfGetCarePlan200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCarePlan200ResponseFromJSON(json: any): GetCarePlan200Response {
    return GetCarePlan200ResponseFromJSONTyped(json, false);
}

export function GetCarePlan200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCarePlan200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CarePlanFromJSON(json['data']),
    };
}

export function GetCarePlan200ResponseToJSON(value?: GetCarePlan200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CarePlanToJSON(value.data),
    };
}

