/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmResidentListEntry } from './CrmResidentListEntry';
import {
    CrmResidentListEntryFromJSON,
    CrmResidentListEntryFromJSONTyped,
    CrmResidentListEntryToJSON,
} from './CrmResidentListEntry';

/**
 * 
 * @export
 * @interface CrmResidentsPage
 */
export interface CrmResidentsPage {
    /**
     * 
     * @type {Array<CrmResidentListEntry>}
     * @memberof CrmResidentsPage
     */
    residents: Array<CrmResidentListEntry>;
    /**
     * 
     * @type {number}
     * @memberof CrmResidentsPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the CrmResidentsPage interface.
 */
export function instanceOfCrmResidentsPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "residents" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function CrmResidentsPageFromJSON(json: any): CrmResidentsPage {
    return CrmResidentsPageFromJSONTyped(json, false);
}

export function CrmResidentsPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmResidentsPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residents': ((json['residents'] as Array<any>).map(CrmResidentListEntryFromJSON)),
        'total_count': json['total_count'],
    };
}

export function CrmResidentsPageToJSON(value?: CrmResidentsPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residents': ((value.residents as Array<any>).map(CrmResidentListEntryToJSON)),
        'total_count': value.total_count,
    };
}

