/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InformationalCarePlanInfoListItem
 */
export interface InformationalCarePlanInfoListItem {
    /**
     * 
     * @type {string}
     * @memberof InformationalCarePlanInfoListItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InformationalCarePlanInfoListItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InformationalCarePlanInfoListItem
     */
    responsible_party_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationalCarePlanInfoListItem
     */
    responsible_party: string;
    /**
     * 
     * @type {boolean}
     * @memberof InformationalCarePlanInfoListItem
     */
    is_new: boolean;
}

/**
 * Check if a given object implements the InformationalCarePlanInfoListItem interface.
 */
export function instanceOfInformationalCarePlanInfoListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "responsible_party" in value;
    isInstance = isInstance && "is_new" in value;

    return isInstance;
}

export function InformationalCarePlanInfoListItemFromJSON(json: any): InformationalCarePlanInfoListItem {
    return InformationalCarePlanInfoListItemFromJSONTyped(json, false);
}

export function InformationalCarePlanInfoListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformationalCarePlanInfoListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'responsible_party_id': !exists(json, 'responsible_party_id') ? undefined : json['responsible_party_id'],
        'responsible_party': json['responsible_party'],
        'is_new': json['is_new'],
    };
}

export function InformationalCarePlanInfoListItemToJSON(value?: InformationalCarePlanInfoListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'responsible_party_id': value.responsible_party_id,
        'responsible_party': value.responsible_party,
        'is_new': value.is_new,
    };
}

