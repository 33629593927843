import React from 'react';

export const TempHotLarge: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="90"
    viewBox="0 0 48 90"
    fill="none">
    <path
      d="M45 87C21.804 87 3 68.196 3 45C3 21.804 21.804 3 45 3"
      stroke="#B5C9D6"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <mask
      id="mask0_14581_366436"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="90">
      <path
        d="M45 87C21.804 87 3 68.196 3 45C3 21.804 21.804 3 45 3"
        stroke="#B5C9D6"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </mask>
    <g mask="url(#mask0_14581_366436)">
      <rect
        x="-0.652344"
        y="61.9785"
        width="51.1304"
        height="30.383"
        fill="#FFA858"
      />
      <rect y="28" width="11" height="34" fill="#FFA858" />
      <rect
        x="-0.652344"
        y="-2.36133"
        width="51.1304"
        height="30.383"
        fill="#FFA858"
      />
    </g>
  </svg>
);
