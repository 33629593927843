/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimePurchase } from './OneTimePurchase';
import {
    OneTimePurchaseFromJSON,
    OneTimePurchaseFromJSONTyped,
    OneTimePurchaseToJSON,
} from './OneTimePurchase';

/**
 * 
 * @export
 * @interface BulkCreateOneTimePurchasesRequest
 */
export interface BulkCreateOneTimePurchasesRequest {
    /**
     * 
     * @type {Array<OneTimePurchase>}
     * @memberof BulkCreateOneTimePurchasesRequest
     */
    purchases: Array<OneTimePurchase>;
}

/**
 * Check if a given object implements the BulkCreateOneTimePurchasesRequest interface.
 */
export function instanceOfBulkCreateOneTimePurchasesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purchases" in value;

    return isInstance;
}

export function BulkCreateOneTimePurchasesRequestFromJSON(json: any): BulkCreateOneTimePurchasesRequest {
    return BulkCreateOneTimePurchasesRequestFromJSONTyped(json, false);
}

export function BulkCreateOneTimePurchasesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateOneTimePurchasesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchases': ((json['purchases'] as Array<any>).map(OneTimePurchaseFromJSON)),
    };
}

export function BulkCreateOneTimePurchasesRequestToJSON(value?: BulkCreateOneTimePurchasesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchases': ((value.purchases as Array<any>).map(OneTimePurchaseToJSON)),
    };
}

