/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicarePrimaryDiagnosis } from './MedicarePrimaryDiagnosis';
import {
    MedicarePrimaryDiagnosisFromJSON,
    MedicarePrimaryDiagnosisFromJSONTyped,
    MedicarePrimaryDiagnosisToJSON,
} from './MedicarePrimaryDiagnosis';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface AssessmentFormDiagnosis
 */
export interface AssessmentFormDiagnosis {
    /**
     * 
     * @type {MedicarePrimaryDiagnosis}
     * @memberof AssessmentFormDiagnosis
     */
    primary_diagnosis: MedicarePrimaryDiagnosis;
    /**
     * 
     * @type {MedicarePrimaryDiagnosis}
     * @memberof AssessmentFormDiagnosis
     */
    readonly predicted_primary_diagnosis?: MedicarePrimaryDiagnosis;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFormDiagnosis
     */
    readonly predicted_primary_diagnosis_reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof AssessmentFormDiagnosis
     */
    predicted_primary_diagnosis_visual_references?: Array<VisualReference>;
}

/**
 * Check if a given object implements the AssessmentFormDiagnosis interface.
 */
export function instanceOfAssessmentFormDiagnosis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "primary_diagnosis" in value;

    return isInstance;
}

export function AssessmentFormDiagnosisFromJSON(json: any): AssessmentFormDiagnosis {
    return AssessmentFormDiagnosisFromJSONTyped(json, false);
}

export function AssessmentFormDiagnosisFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentFormDiagnosis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary_diagnosis': MedicarePrimaryDiagnosisFromJSON(json['primary_diagnosis']),
        'predicted_primary_diagnosis': !exists(json, 'predicted_primary_diagnosis') ? undefined : MedicarePrimaryDiagnosisFromJSON(json['predicted_primary_diagnosis']),
        'predicted_primary_diagnosis_reasoning': !exists(json, 'predicted_primary_diagnosis_reasoning') ? undefined : json['predicted_primary_diagnosis_reasoning'],
        'predicted_primary_diagnosis_visual_references': !exists(json, 'predicted_primary_diagnosis_visual_references') ? undefined : ((json['predicted_primary_diagnosis_visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
    };
}

export function AssessmentFormDiagnosisToJSON(value?: AssessmentFormDiagnosis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_diagnosis': MedicarePrimaryDiagnosisToJSON(value.primary_diagnosis),
        'predicted_primary_diagnosis_visual_references': value.predicted_primary_diagnosis_visual_references === undefined ? undefined : ((value.predicted_primary_diagnosis_visual_references as Array<any>).map(VisualReferenceToJSON)),
    };
}

