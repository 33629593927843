import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FrequencyTimeFormData } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { everyHourlyOrMinutelyOptions } from '@/components/FrequencyTimeForm/options';
import {
  PatternedRecurrenceEhr,
  RecurrencePatternEhr,
  RecurrencePatternTypeEhr,
  RecurrenceRangeEhr,
  RecurrenceRangeTypeEhr
} from '@/models/PatternedRecurrenceEhr';

dayjs.extend(customParseFormat);

export const transformFrequencyTimeFormDataToPatternedRecurrence = (
  frequency: FrequencyTimeFormData,
  tzid: string
): PatternedRecurrenceEhr => {
  const startTime = frequency.execution_window_start_time;
  const endTime = frequency.execution_window_end_time;
  const startDate = dayjs(frequency!.dtstart).format('YYYY-MM-DD');
  const startDateWithStartTime = dayjs(`${startDate} ${startTime}`);

  const selectedHourlyOrMinutelyValue = everyHourlyOrMinutelyOptions.find(
    (option) => option.value === frequency.interval!
  );

  const recurrencePatternType = selectedHourlyOrMinutelyValue?.type;

  if (
    !recurrencePatternType ||
    ![
      RecurrencePatternTypeEhr.Hourly,
      RecurrencePatternTypeEhr.Minutely
    ].includes(recurrencePatternType)
  ) {
    throw new Error(
      `Patterned recurrence range.type with value "${frequency.freq}" not supported`
    );
  }

  let rangeEndDate: string | undefined;

  if (frequency!.until) {
    const endDate = dayjs(frequency!.until).format('YYYY-MM-DD');
    const endDateWithEndTime = dayjs(`${endDate} ${endTime}`);
    rangeEndDate = endDateWithEndTime.toISOString();
  }

  const pattern: RecurrencePatternEhr = {
    type: recurrencePatternType,
    interval: frequency.interval!
  };
  const range: RecurrenceRangeEhr = {
    type: frequency!.until
      ? RecurrenceRangeTypeEhr.EndDate
      : RecurrenceRangeTypeEhr.NoEnd,
    startDate: startDateWithStartTime.toISOString(),
    endDate: rangeEndDate,
    recurrenceTimeZone: tzid,
    startTime: startTime,
    endTime: endTime
  };
  return {
    pattern: pattern,
    range: range
  } as PatternedRecurrenceEhr;
};
