/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CareTotals
 */
export interface CareTotals {
    /**
     * 
     * @type {number}
     * @memberof CareTotals
     */
    base_care_price: number;
    /**
     * 
     * @type {number}
     * @memberof CareTotals
     */
    care_packages_price: number;
    /**
     * 
     * @type {number}
     * @memberof CareTotals
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof CareTotals
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof CareTotals
     */
    total: number;
}

/**
 * Check if a given object implements the CareTotals interface.
 */
export function instanceOfCareTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "base_care_price" in value;
    isInstance = isInstance && "care_packages_price" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CareTotalsFromJSON(json: any): CareTotals {
    return CareTotalsFromJSONTyped(json, false);
}

export function CareTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base_care_price': json['base_care_price'],
        'care_packages_price': json['care_packages_price'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'total': json['total'],
    };
}

export function CareTotalsToJSON(value?: CareTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_care_price': value.base_care_price,
        'care_packages_price': value.care_packages_price,
        'discount': value.discount,
        'tax': value.tax,
        'total': value.total,
    };
}

