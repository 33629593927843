/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TaskPriority = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    Null: 'null'
} as const;
export type TaskPriority = typeof TaskPriority[keyof typeof TaskPriority];


export function TaskPriorityFromJSON(json: any): TaskPriority {
    return TaskPriorityFromJSONTyped(json, false);
}

export function TaskPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskPriority {
    return json as TaskPriority;
}

export function TaskPriorityToJSON(value?: TaskPriority | null): any {
    return value as any;
}

