/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCadence } from './AssessmentCadence';
import {
    AssessmentCadenceFromJSON,
    AssessmentCadenceFromJSONTyped,
    AssessmentCadenceToJSON,
} from './AssessmentCadence';
import type { AssessmentCompletion } from './AssessmentCompletion';
import {
    AssessmentCompletionFromJSON,
    AssessmentCompletionFromJSONTyped,
    AssessmentCompletionToJSON,
} from './AssessmentCompletion';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface ResidentAssessment
 */
export interface ResidentAssessment {
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessment
     */
    assessment_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessment
     */
    assessment_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentAssessment
     */
    is_care_plan_generator?: boolean;
    /**
     * 
     * @type {AssessmentCadence}
     * @memberof ResidentAssessment
     */
    cadence?: AssessmentCadence;
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessment
     */
    next_assessment_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessment
     */
    last_assessment_date?: string;
    /**
     * 
     * @type {UserName}
     * @memberof ResidentAssessment
     */
    last_assessment_user?: UserName;
    /**
     * 
     * @type {AssessmentCompletion}
     * @memberof ResidentAssessment
     */
    current_completion?: AssessmentCompletion;
}

/**
 * Check if a given object implements the ResidentAssessment interface.
 */
export function instanceOfResidentAssessment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assessment_id" in value;

    return isInstance;
}

export function ResidentAssessmentFromJSON(json: any): ResidentAssessment {
    return ResidentAssessmentFromJSONTyped(json, false);
}

export function ResidentAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'assessment_id': json['assessment_id'],
        'assessment_name': !exists(json, 'assessment_name') ? undefined : json['assessment_name'],
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
        'cadence': !exists(json, 'cadence') ? undefined : AssessmentCadenceFromJSON(json['cadence']),
        'next_assessment_date': !exists(json, 'next_assessment_date') ? undefined : json['next_assessment_date'],
        'last_assessment_date': !exists(json, 'last_assessment_date') ? undefined : json['last_assessment_date'],
        'last_assessment_user': !exists(json, 'last_assessment_user') ? undefined : UserNameFromJSON(json['last_assessment_user']),
        'current_completion': !exists(json, 'current_completion') ? undefined : AssessmentCompletionFromJSON(json['current_completion']),
    };
}

export function ResidentAssessmentToJSON(value?: ResidentAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'assessment_id': value.assessment_id,
        'assessment_name': value.assessment_name,
        'is_care_plan_generator': value.is_care_plan_generator,
        'cadence': AssessmentCadenceToJSON(value.cadence),
        'next_assessment_date': value.next_assessment_date,
        'last_assessment_date': value.last_assessment_date,
        'last_assessment_user': UserNameToJSON(value.last_assessment_user),
        'current_completion': AssessmentCompletionToJSON(value.current_completion),
    };
}

