/* tslint:disable */
/* eslint-disable */
/**
 * data-center
 * data-center
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DashboardListEntryScope = {
    Ehr: 'EHR',
    Crm: 'CRM',
    All: 'ALL'
} as const;
export type DashboardListEntryScope = typeof DashboardListEntryScope[keyof typeof DashboardListEntryScope];


export function DashboardListEntryScopeFromJSON(json: any): DashboardListEntryScope {
    return DashboardListEntryScopeFromJSONTyped(json, false);
}

export function DashboardListEntryScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardListEntryScope {
    return json as DashboardListEntryScope;
}

export function DashboardListEntryScopeToJSON(value?: DashboardListEntryScope | null): any {
    return value as any;
}

