/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentDocumentListEntry
 */
export interface IncidentDocumentListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentDocumentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentDocumentListEntry
     */
    url: string;
}

/**
 * Check if a given object implements the IncidentDocumentListEntry interface.
 */
export function instanceOfIncidentDocumentListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function IncidentDocumentListEntryFromJSON(json: any): IncidentDocumentListEntry {
    return IncidentDocumentListEntryFromJSONTyped(json, false);
}

export function IncidentDocumentListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentDocumentListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
    };
}

export function IncidentDocumentListEntryToJSON(value?: IncidentDocumentListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
    };
}

