import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCRMReferrals } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';

interface LeadOriginHeardSurvey {
  id: string;
  facility_id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

const USE_LEAD_ORIGIN_HEARD_SURVEYS_QUERY_KEY = ['lead-origin-heard-surveys'];
export const invalidate = () =>
  queryClient.invalidateQueries(USE_LEAD_ORIGIN_HEARD_SURVEYS_QUERY_KEY);
export const useLeadOriginHeardSurveysQuery = () => {
  const selectedFacilityId = useSelectedFacilityId()!;

  return {
    findAll: (options: UseQueryOptions<LeadOriginHeardSurvey[]> = {}) =>
      useQuery(
        [...USE_LEAD_ORIGIN_HEARD_SURVEYS_QUERY_KEY, selectedFacilityId],
        async () => {
          return fetchCRMReferrals.get<LeadOriginHeardSurvey[]>(
            '/lead-origin-heard-surveys',
            {
              searchParams: {
                facility_id: selectedFacilityId
              }
            }
          );
        },
        options as any
      ),

    mutations: () => ({
      create: useMutation(
        (name: string) => {
          return fetchCRMReferrals.post<LeadOriginHeardSurvey>(
            '/lead-origin-heard-surveys',
            {
              facility_id: selectedFacilityId,
              name
            }
          );
        },
        {
          onSuccess: invalidate
        }
      )
    })
  };
};
