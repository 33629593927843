import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';

import { DrawerFooter, MedicationDrawerProps } from '../MedicationDrawer';

import MedicationExtension from './MedicationExtrasExtension';
import MedicationExtrasVitals from './MedicationExtrasVitals';

interface MedicationExtrasProps {
  // Warning: Don't type this as ResidentMedicationForm or memory usage will spike
  formMethods: UseFormReturn<any>;
  handleBack: VoidFunction;
  handleToDetailsLink: VoidFunction;
  handleToTimeLink: VoidFunction;
  handleSave: VoidFunction;
  type: MedicationDrawerProps['type'];
  isSaving?: boolean;
  responsibleParties?: ResponsibleParty[];
}

export default function MedicationExtras(props: MedicationExtrasProps) {
  const {
    formMethods,
    handleBack,
    handleToDetailsLink,
    handleToTimeLink,
    handleSave,
    type,
    isSaving,
    responsibleParties
  } = props;

  return (
    <>
      <Stack gap={4}>
        <MedicationExtrasVitals
          disableEdit={type === 'Readonly'}
          formMethods={formMethods}
          handleToDetailsLink={handleToDetailsLink}
          handleToTimeLink={handleToTimeLink}
          responsibleParties={responsibleParties}
        />
        <MedicationExtension
          disableEdit={type === 'Readonly'}
          handleToDetailsLink={handleToDetailsLink}
          responsibleParties={responsibleParties}
          formMethods={formMethods}
          handleToTimeLink={handleToTimeLink}
        />
      </Stack>
      {(type === 'Add' || type === 'Readonly') && (
        <DrawerFooter>
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <LoadingButton
            data-testid="medication-drawer-save-button"
            variant="contained"
            color="primary"
            disabled={type === 'Readonly'}
            onClick={() => {
              handleSave();
            }}
            loading={isSaving}>
            Save
          </LoadingButton>
        </DrawerFooter>
      )}
    </>
  );
}
