/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFindOneResponseLoggedBy
 */
export interface IncidentFindOneResponseLoggedBy {
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponseLoggedBy
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFindOneResponseLoggedBy
     */
    role?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFindOneResponseLoggedBy
     */
    created_at?: Date;
}

/**
 * Check if a given object implements the IncidentFindOneResponseLoggedBy interface.
 */
export function instanceOfIncidentFindOneResponseLoggedBy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IncidentFindOneResponseLoggedByFromJSON(json: any): IncidentFindOneResponseLoggedBy {
    return IncidentFindOneResponseLoggedByFromJSONTyped(json, false);
}

export function IncidentFindOneResponseLoggedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFindOneResponseLoggedBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function IncidentFindOneResponseLoggedByToJSON(value?: IncidentFindOneResponseLoggedBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'role': value.role,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString()),
    };
}

