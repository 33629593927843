/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssessmentCompletionState = {
    Created: 'created',
    InProgress: 'in_progress',
    Completed: 'completed',
    InReview: 'in_review',
    Approved: 'approved'
} as const;
export type AssessmentCompletionState = typeof AssessmentCompletionState[keyof typeof AssessmentCompletionState];


export function AssessmentCompletionStateFromJSON(json: any): AssessmentCompletionState {
    return AssessmentCompletionStateFromJSONTyped(json, false);
}

export function AssessmentCompletionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletionState {
    return json as AssessmentCompletionState;
}

export function AssessmentCompletionStateToJSON(value?: AssessmentCompletionState | null): any {
    return value as any;
}

