import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchIncidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

import { IncidentOption } from './useCreateIncident';
import { ALL_FACILITIES } from './useFacilitiesQuery';
import { ResidentPayload } from './useResidentFacesheetsQuery';
import { UserPayload } from './useUserQuery';

export enum Status {
  OPEN = '0',
  CLOSED = '1'
}

export class IncidentPayload {
  incident: {
    id: string;
    resident_id: string;
    resident: ResidentPayload;
    createdAt: string;
    updatedAt: string;
    deleted_at: string | null;
    status: Status;
    type_id: string;
    type: IncidentOption;
    incident_type_other: string;
    occurence_date: string;
    location: string;
    location_other: string;
    resident_description: string | null;
    witness_description: string | null;
    nurses_description: string | null;
    notes_on_physical_environment: string | null;
    notes_on_psychological_state: string | null;
    injury_area: IncidentOption[] | null;
    injury_type: IncidentOption[] | null;
    pain_level: string | null;
    level_of_consiousness: string;
    orientation: string;
    blood_pressure: string;
    heart_rate: string;
    oxygen: string;
    temperature: string;
    respiration: string;
    actions_follow_up: string;
    family_power_of_attorney: IncidentOption[];
    action_plan_to_prevent: string;
    user_id: string;
    user: UserPayload | null;
    contact_name: string | null;
    contact_date: string | null;
    contact_email: string | null;
    contact_phone: string | null;
  };
  comments: IncidentComment[];
}

interface IncidentComment {
  id: number;
  s3_key: string;
  name: string;
  user_type: string;
  comment: string;
  created_at: string;
}

interface IncidentCountPayload {
  count: number;
}

export function useIncidentsQuery() {
  const QUERY_KEY = ['useIncidentsQuery'];
  const INCIDENTS_COUNT_QUERY_KEY = ['useIncidentsCountQuery'];
  const invalidate = () => queryClient.invalidateQueries(QUERY_KEY);
  const invalidateIncidentsCountQuery = () =>
    queryClient.invalidateQueries(INCIDENTS_COUNT_QUERY_KEY);
  return {
    invalidate,
    invalidateIncidentsCountQuery,
    findOne: (incidentId: string) => {
      return useQuery([...QUERY_KEY, incidentId], () => {
        return fetchIncidents.get<IncidentPayload>(`/incidents/${incidentId}`);
      });
    },
    count: (
      options: UseQueryOptions<IncidentCountPayload> = {},
      status: Status,
      facilityId: string | null,
      startDate?: string,
      endDate?: string,
      residentId?: string
    ) => {
      return useQuery({
        queryKey: [...QUERY_KEY, facilityId],
        queryFn: () => {
          if (facilityId === ALL_FACILITIES) facilityId = '';
          return fetchIncidents.get<IncidentCountPayload>('/incidents/count', {
            searchParams: {
              status: status,
              facility_id: facilityId,
              start_date: startDate,
              end_date: endDate,
              resident_id: residentId
            }
          });
        },
        enabled: !!facilityId,
        ...options
      });
    },
    findAll: (
      startDate?: string,
      endDate?: string,
      facilityId?: string | null,
      residentId?: string,
      includeAllOpen?: boolean,
      options?: UseQueryOptions<IncidentPayload[]>
    ) => {
      return useQuery({
        queryKey: [...QUERY_KEY, residentId, facilityId],
        queryFn: async () => {
          if (facilityId === ALL_FACILITIES) facilityId = '';
          return fetchIncidents.get<IncidentPayload[]>('/incidents', {
            searchParams: {
              facility_id: facilityId,
              start_date: startDate,
              end_date: endDate,
              resident_id: residentId,
              include_all_open: includeAllOpen
            }
          });
        },
        ...options
      });
    },
    mutations: {
      addComment: useMutation(
        (o: { incidentId: string; comment: string }) => {
          return fetchIncidents.post(`/incidents/${o.incidentId}/comments`, {
            comment: o.comment
          });
        },
        { onSuccess: invalidate }
      )
    }
  };
}
