/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmPrimaryContactResponse
 */
export interface ApfmPrimaryContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_relationship?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_home_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_cell_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_work_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmPrimaryContactResponse
     */
    primary_contact_fax_phone?: string;
}

/**
 * Check if a given object implements the ApfmPrimaryContactResponse interface.
 */
export function instanceOfApfmPrimaryContactResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmPrimaryContactResponseFromJSON(json: any): ApfmPrimaryContactResponse {
    return ApfmPrimaryContactResponseFromJSONTyped(json, false);
}

export function ApfmPrimaryContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmPrimaryContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary_contact_first_name': !exists(json, 'primary_contact_first_name') ? undefined : json['primary_contact_first_name'],
        'primary_contact_last_name': !exists(json, 'primary_contact_last_name') ? undefined : json['primary_contact_last_name'],
        'primary_contact_relationship': !exists(json, 'primary_contact_relationship') ? undefined : json['primary_contact_relationship'],
        'primary_contact_home_phone': !exists(json, 'primary_contact_home_phone') ? undefined : json['primary_contact_home_phone'],
        'primary_contact_cell_phone': !exists(json, 'primary_contact_cell_phone') ? undefined : json['primary_contact_cell_phone'],
        'primary_contact_work_phone': !exists(json, 'primary_contact_work_phone') ? undefined : json['primary_contact_work_phone'],
        'primary_contact_fax_phone': !exists(json, 'primary_contact_fax_phone') ? undefined : json['primary_contact_fax_phone'],
    };
}

export function ApfmPrimaryContactResponseToJSON(value?: ApfmPrimaryContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_contact_first_name': value.primary_contact_first_name,
        'primary_contact_last_name': value.primary_contact_last_name,
        'primary_contact_relationship': value.primary_contact_relationship,
        'primary_contact_home_phone': value.primary_contact_home_phone,
        'primary_contact_cell_phone': value.primary_contact_cell_phone,
        'primary_contact_work_phone': value.primary_contact_work_phone,
        'primary_contact_fax_phone': value.primary_contact_fax_phone,
    };
}

