/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmCareResponse
 */
export interface ApfmCareResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_assistance_bathing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_assistance_toileting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApfmCareResponse
     */
    profile_assistance_toileting_followup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_bathroom_reminder_needed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_bathroom_physical_difficulties?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_incontinence_type_bladder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApfmCareResponse
     */
    profile_incontinence_type_bowel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApfmCareResponse
     */
    profile_special_care?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmCareResponse
     */
    profile_other_assistance?: string;
}

/**
 * Check if a given object implements the ApfmCareResponse interface.
 */
export function instanceOfApfmCareResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmCareResponseFromJSON(json: any): ApfmCareResponse {
    return ApfmCareResponseFromJSONTyped(json, false);
}

export function ApfmCareResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmCareResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile_assistance_bathing': !exists(json, 'profile_assistance_bathing') ? undefined : json['profile_assistance_bathing'],
        'profile_assistance_toileting': !exists(json, 'profile_assistance_toileting') ? undefined : json['profile_assistance_toileting'],
        'profile_assistance_toileting_followup': !exists(json, 'profile_assistance_toileting_followup') ? undefined : json['profile_assistance_toileting_followup'],
        'profile_bathroom_reminder_needed': !exists(json, 'profile_bathroom_reminder_needed') ? undefined : json['profile_bathroom_reminder_needed'],
        'profile_bathroom_physical_difficulties': !exists(json, 'profile_bathroom_physical_difficulties') ? undefined : json['profile_bathroom_physical_difficulties'],
        'profile_incontinence_type_bladder': !exists(json, 'profile_incontinence_type_bladder') ? undefined : json['profile_incontinence_type_bladder'],
        'profile_incontinence_type_bowel': !exists(json, 'profile_incontinence_type_bowel') ? undefined : json['profile_incontinence_type_bowel'],
        'profile_special_care': !exists(json, 'profile_special_care') ? undefined : json['profile_special_care'],
        'profile_other_assistance': !exists(json, 'profile_other_assistance') ? undefined : json['profile_other_assistance'],
    };
}

export function ApfmCareResponseToJSON(value?: ApfmCareResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_assistance_bathing': value.profile_assistance_bathing,
        'profile_assistance_toileting': value.profile_assistance_toileting,
        'profile_assistance_toileting_followup': value.profile_assistance_toileting_followup,
        'profile_bathroom_reminder_needed': value.profile_bathroom_reminder_needed,
        'profile_bathroom_physical_difficulties': value.profile_bathroom_physical_difficulties,
        'profile_incontinence_type_bladder': value.profile_incontinence_type_bladder,
        'profile_incontinence_type_bowel': value.profile_incontinence_type_bowel,
        'profile_special_care': value.profile_special_care,
        'profile_other_assistance': value.profile_other_assistance,
    };
}

