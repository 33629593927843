/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCareUnitsType } from './AssessmentCareUnitsType';
import {
    AssessmentCareUnitsTypeFromJSON,
    AssessmentCareUnitsTypeFromJSONTyped,
    AssessmentCareUnitsTypeToJSON,
} from './AssessmentCareUnitsType';
import type { AssessmentState } from './AssessmentState';
import {
    AssessmentStateFromJSON,
    AssessmentStateFromJSONTyped,
    AssessmentStateToJSON,
} from './AssessmentState';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface AssessmentListEntry
 */
export interface AssessmentListEntry {
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    name: string;
    /**
     * 
     * @type {AssessmentState}
     * @memberof AssessmentListEntry
     */
    state: AssessmentState;
    /**
     * 
     * @type {UserName}
     * @memberof AssessmentListEntry
     */
    created_by: UserName;
    /**
     * 
     * @type {AssessmentCareUnitsType}
     * @memberof AssessmentListEntry
     */
    care_units_type: AssessmentCareUnitsType;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentListEntry
     */
    is_care_plan_generator?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    prev_version_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    date_created: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentListEntry
     */
    date_updated: string;
}

/**
 * Check if a given object implements the AssessmentListEntry interface.
 */
export function instanceOfAssessmentListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "care_units_type" in value;
    isInstance = isInstance && "date_created" in value;
    isInstance = isInstance && "date_updated" in value;

    return isInstance;
}

export function AssessmentListEntryFromJSON(json: any): AssessmentListEntry {
    return AssessmentListEntryFromJSONTyped(json, false);
}

export function AssessmentListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'name': json['name'],
        'state': AssessmentStateFromJSON(json['state']),
        'created_by': UserNameFromJSON(json['created_by']),
        'care_units_type': AssessmentCareUnitsTypeFromJSON(json['care_units_type']),
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
        'prev_version_id': !exists(json, 'prev_version_id') ? undefined : json['prev_version_id'],
        'date_created': json['date_created'],
        'date_updated': json['date_updated'],
    };
}

export function AssessmentListEntryToJSON(value?: AssessmentListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'state': AssessmentStateToJSON(value.state),
        'created_by': UserNameToJSON(value.created_by),
        'care_units_type': AssessmentCareUnitsTypeToJSON(value.care_units_type),
        'is_care_plan_generator': value.is_care_plan_generator,
        'prev_version_id': value.prev_version_id,
        'date_created': value.date_created,
        'date_updated': value.date_updated,
    };
}

