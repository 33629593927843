import {
  ALL_FACILITIES,
  FacilityPayload,
  FindAllFacilitiesResponse
} from '@/hooks/useFacilitiesQuery';
import { UserModel } from '@/models/UserModel';

export const getUserFacility = (
  selectedFacilityId: string | null,
  facilitiesResponse: FindAllFacilitiesResponse | undefined,
  currentUser: UserModel | undefined,
  setSelectedFacilityId: (id: string) => void
): FacilityPayload | null => {
  if (!facilitiesResponse || !currentUser) return null;

  if (
    selectedFacilityId === ALL_FACILITIES &&
    facilitiesResponse.can_select_all_facilities
  ) {
    return null;
  }

  if (
    !selectedFacilityId ||
    !currentUser.user_facility_accesses?.find(
      (item) => item.facility_id === selectedFacilityId
    )
  ) {
    setSelectedFacilityId(currentUser.facility_id);
    const userOwnFacility = facilitiesResponse.facilities?.find(
      (facility) => facility.id === currentUser.facility_id
    );
    if (!userOwnFacility) {
      // We should never reach this point. A user must always have access to his own facility. Throwing
      // this error as a prevention in case for some reason it is thrown we can identify it easily and fix it
      throw new Error(
        `user with ID #${currentUser.id} has not access granted to his own facility with ID #${currentUser.facility_id}`
      );
    }
    return userOwnFacility;
  }

  return facilitiesResponse.facilities?.find(
    (facility) => facility.id === selectedFacilityId
  )!;
};
