/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PdfReceiptUrlStatus = {
    Ready: 'Ready',
    Pending: 'Pending'
} as const;
export type PdfReceiptUrlStatus = typeof PdfReceiptUrlStatus[keyof typeof PdfReceiptUrlStatus];


export function PdfReceiptUrlStatusFromJSON(json: any): PdfReceiptUrlStatus {
    return PdfReceiptUrlStatusFromJSONTyped(json, false);
}

export function PdfReceiptUrlStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfReceiptUrlStatus {
    return json as PdfReceiptUrlStatus;
}

export function PdfReceiptUrlStatusToJSON(value?: PdfReceiptUrlStatus | null): any {
    return value as any;
}

