import React from 'react';
import { Badge, BadgeProps, styled } from '@mui/material';

export type SidebarItemBadgeProps = React.PropsWithChildren<
  BadgeProps & {
    expanded: boolean;
    noBackground?: boolean;
  }
>;

export const SidebarItemBadge: React.FC<SidebarItemBadgeProps> = ({
  expanded,
  children,
  ...props
}) => {
  return (
    <StyledBadge expanded={expanded} {...props}>
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'expanded' && prop !== 'noBackground'
})<{ expanded: boolean; noBackground?: boolean }>(
  ({ expanded, noBackground = false }) => ({
    display: 'flex',
    '& .MuiBadge-badge': {
      transition: 'all 200ms ease-in-out',
      backgroundColor: noBackground ? 'unset' : '#fff',
      ...(expanded
        ? {
            top: 32,
            right: 39
          }
        : {
            top: 14,
            right: 28
          })
    }
  })
);
