/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllRecurringPurchasesSummaryResponse
 */
export interface AllRecurringPurchasesSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    room_name: string;
    /**
     * 
     * @type {string}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    room_type: string;
    /**
     * 
     * @type {number}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    rent: number;
    /**
     * 
     * @type {number}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    care: number;
    /**
     * 
     * @type {number}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    other: number;
    /**
     * 
     * @type {number}
     * @memberof AllRecurringPurchasesSummaryResponse
     */
    total: number;
}

/**
 * Check if a given object implements the AllRecurringPurchasesSummaryResponse interface.
 */
export function instanceOfAllRecurringPurchasesSummaryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "room_name" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "room_type" in value;
    isInstance = isInstance && "rent" in value;
    isInstance = isInstance && "care" in value;
    isInstance = isInstance && "other" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function AllRecurringPurchasesSummaryResponseFromJSON(json: any): AllRecurringPurchasesSummaryResponse {
    return AllRecurringPurchasesSummaryResponseFromJSONTyped(json, false);
}

export function AllRecurringPurchasesSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllRecurringPurchasesSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room_name': json['room_name'],
        'resident_name': json['resident_name'],
        'resident_id': json['resident_id'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'room_type': json['room_type'],
        'rent': json['rent'],
        'care': json['care'],
        'other': json['other'],
        'total': json['total'],
    };
}

export function AllRecurringPurchasesSummaryResponseToJSON(value?: AllRecurringPurchasesSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_name': value.room_name,
        'resident_name': value.resident_name,
        'resident_id': value.resident_id,
        'resident_photo_url': value.resident_photo_url,
        'room_type': value.room_type,
        'rent': value.rent,
        'care': value.care,
        'other': value.other,
        'total': value.total,
    };
}

