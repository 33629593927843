import { Frequency } from 'rrule';

import { FrequencyTimeFormData } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  WeeklyDayOption,
  weeklyDayOptions
} from '@/components/FrequencyTimeForm/options';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { TaskSchedulePostBody } from '@/stores/residentCarePlanAtom';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { offsetDateToTimezonedMidnight } from './offsetDateToTimezonedMidnight';
import { transformFrequencyTimeFormDataToPatternedRecurrence } from './transformFrequencyTimeFormDataToPatternedRecurrence';
import { transformFrequencyTimeFormDataToRRule } from './transformFrequencyTimeFormDataToRRule';

// WARNING: If you change something here, make sure you also change it in
// transformScheduleFormToJsonBody.ts
export const transformRegimensFormToJsonBody = (
  form: Partial<ResidentMedicationForm>,
  tzid: string,
  resposibleParties: ResponsibleParty[]
): TaskSchedulePostBody[] => {
  // Frequency is no longer relevant at the root level. Each object in the
  // regimens array has its own frequency and schedule we need to parse

  if (
    !isStaffResponsibilityV2(form.responsible_party || null, resposibleParties)
  ) {
    return [];
  }

  return (
    form.regimens?.reduce((accum, regimen) => {
      const freq = regimen?.frequency?.freq;
      if (freq === 'hourly-or-minutely') {
        const scheduleWithPatternedRecurrence =
          getScheduleWithPatternedRecurrenceFromFrequency(
            regimen?.frequency!,
            tzid
          );
        accum.push(scheduleWithPatternedRecurrence);
      } else if (
        freq === 'custom' ||
        freq === 'twice-a-day' ||
        freq === 'three-times-a-day' ||
        freq === 'four-times-a-day' ||
        freq === 'five-times-a-day'
      ) {
        [
          ...weeklyDayOptions,
          { label: 'Everyday', value: 0 } as WeeklyDayOption
        ].forEach(({ label, value }) => {
          regimen.schedule?.[label]?.forEach(
            (frequency: FrequencyTimeFormData) => {
              if (
                frequency.execution_window_start_time &&
                frequency.execution_window_end_time &&
                frequency.type
              ) {
                const rrule = transformFrequencyTimeFormDataToRRule(frequency, {
                  // Custom schedule is assumed to be weekly on specified days
                  interval: 1,
                  freq: freq === 'custom' ? Frequency.WEEKLY : Frequency.DAILY,
                  byweekday: freq === 'custom' ? value : undefined,
                  // Fetch dtstart/until from the parent frequency because the
                  // individual schedules don't set them
                  dtstart: offsetDateToTimezonedMidnight(
                    regimen!.frequency!.dtstart!
                  ),
                  until: regimen!.frequency!.until
                    ? offsetDateToTimezonedMidnight(regimen!.frequency!.until)
                    : undefined,
                  tzid
                });
                accum.push({
                  id: frequency.id,
                  execution_window_start_time:
                    frequency.execution_window_start_time,
                  execution_window_end_time:
                    frequency.execution_window_end_time,
                  rrule: rrule?.toString() ?? '',
                  number_of_dose_units: getNumberOfDoseUnits(frequency),
                  ...(frequency.dose_ranges && {
                    dose_ranges: frequency.dose_ranges
                  }),
                  patterned_recurrence: null
                });
              }
            }
          );
        });
      } else {
        accum.push({
          ...(regimen?.frequency?.id ? { id: regimen.frequency.id } : {}),
          execution_window_start_time:
            regimen?.frequency?.execution_window_start_time ?? null,
          execution_window_end_time:
            regimen?.frequency?.execution_window_end_time ?? null,
          number_of_dose_units: getNumberOfDoseUnits(regimen.frequency),
          ...(regimen.frequency?.dose_ranges &&
            freq !== 'prn' && {
              dose_ranges: regimen.frequency?.dose_ranges
            }),
          rrule:
            transformFrequencyTimeFormDataToRRule(regimen?.frequency, {
              dtstart: offsetDateToTimezonedMidnight(
                regimen?.frequency?.dtstart!
              ),
              until: regimen.frequency?.until
                ? offsetDateToTimezonedMidnight(regimen.frequency!.until)
                : undefined,
              tzid
            })?.toString() ?? '',
          ...(freq === 'prn' &&
          form?.followupIntervalInMinutes &&
          form?.followupInstructions
            ? {
                additional_information: {
                  followUpTaskDefinition: {
                    instructions: form.followupInstructions,
                    timeIntervalInMinutes: form.followupIntervalInMinutes
                  }
                }
              }
            : {
                additional_information: null
              }),
          prn_min_interval_minutes: form?.prn_min_interval_minutes ?? null,
          prn_max_daily_number_of_dose_units:
            form?.prn_max_daily_number_of_dose_units ?? null,
          patterned_recurrence: null
        });
      }
      return accum;
    }, [] as TaskSchedulePostBody[]) ?? []
  );
};

const getNumberOfDoseUnits = (frequency: FrequencyTimeFormData | null) => {
  // If a sliding scale dose range is selected, we don't send a number of dose units
  const numberOfDoseUnits =
    (!frequency?.dose_ranges || frequency?.freq === 'prn') &&
    frequency?.number_of_dose_units
      ? frequency?.number_of_dose_units
      : null;
  return numberOfDoseUnits;
};

const getScheduleWithPatternedRecurrenceFromFrequency = (
  frequency: FrequencyTimeFormData,
  tzid: string
): TaskSchedulePostBody => {
  return {
    id: frequency.id,
    execution_window_start_time: null,
    execution_window_end_time: null,
    number_of_dose_units: getNumberOfDoseUnits(frequency),
    patterned_recurrence: transformFrequencyTimeFormDataToPatternedRecurrence(
      frequency,
      tzid
    ),
    ...(frequency.dose_ranges && {
      dose_ranges: frequency.dose_ranges
    }),
    rrule: null
  };
};
