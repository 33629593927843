import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import fetchExaCare from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

export interface CarePlanAssistanceLevel {
  description: string;
  id: string;
  level: number;
  title: string;
}

export function useCarePlanAssistanceLevelsQuery<
  T = CarePlanAssistanceLevel[]
>() {
  const QUERY_KEY = ['useCarePlanAssistanceLevelsQuery'];
  const invalidate = () => queryClient.invalidateQueries(QUERY_KEY);
  return {
    invalidate,

    findAll: (
      options: UseQueryOptions<CarePlanAssistanceLevel[], HTTPError, T> = {}
    ) => {
      return useQuery<CarePlanAssistanceLevel[], HTTPError, T>(
        QUERY_KEY,
        () => {
          return fetchExaCare.get<CarePlanAssistanceLevel[]>(
            '/care-plan-assistance-levels'
          );
        },
        options
      );
    },

    mutations: (options: UseMutationOptions = {}) => ({
      create: useMutation(
        (payload: Omit<CarePlanAssistanceLevel, 'id'>) => {
          return fetchExaCare.post('/care-plan-assistance-levels', payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      ),
      update: useMutation(
        (payload: CarePlanAssistanceLevel) => {
          return fetchExaCare.put(
            `/care-plan-assistance-levels/${payload.id}`,
            payload
          );
        },
        { onSuccess: invalidate, ...(options as any) }
      ),
      delete: useMutation(
        (payload: Pick<CarePlanAssistanceLevel, 'id'>) => {
          return fetchExaCare.delete(
            `/care-plan-assistance-levels/${payload.id}`
          );
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}
