/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApproveAssessmentCompletionRequest,
  AssessmentCompletion,
  AssessmentCompletionAnswer,
  AssessmentCompletionComment,
  AssessmentCompletionState,
  AssessmentQuestion,
  AssessmentState,
  CancelCarePlanEsignRequest,
  CarePackage,
  CarePackageCategory,
  CarePlanEntryTemplate,
  CarePlanEsignRecipient,
  CareScheme,
  CompleteResidentAssessmentRequest,
  CreateAssessment201Response,
  CreateAssessmentCategory201Response,
  CreateAssessmentCategoryRequest,
  CreateAssessmentQuestion201Response,
  CreateAssessmentRequest,
  CreateAssessmentVersion201Response,
  CreateCarePackage201Response,
  CreateCarePackageCategory201Response,
  CreateCarePlanEntryTemplate201Response,
  CreateCarePlanEsignRecipient200Response,
  CreateNgRepositoryEntry201Response,
  CreateResidentAssessment201Response,
  CreateResidentAssessment400Response,
  DuplicateAssessment201Response,
  DuplicateAssessmentRequest,
  FindAllAssessments200Response,
  FindAllCarePackageCategories200Response,
  FindAllCarePackages200Response,
  FindAllCarePackagesWithoutPrice200Response,
  FindAllCareSchemes200Response,
  FindAllFacilityAssessmentCompletions200Response,
  FindAllNgRepositoryEntries200Response,
  FindAllResidentAssessmentAnswers200Response,
  FindAllResidentAssessmentAnswersOrdered200Response,
  FindAllResidentAssessmentComments200Response,
  FindAllResidentAssessmentCompletions200Response,
  FindAllResidentAssessments200Response,
  FindOneCareScheme200Response,
  FindOneResidentAssessmentCompletion200Response,
  GetCarePlan200Response,
  GetCarePlanEsign200Response,
  GetCarePlanPrices200Response,
  NgRepositoryEntry,
  OverrideResidentAnswerPointsRequest,
  PutAssessmentCategoryOrderRequest,
  PutAssessmentQuestionOrderRequest,
  PutAssessmentRequest,
  PutCarePlanEsignRequest,
  PutManuallySignedDocumentS3KeyRequest,
  PutResidentAssessmentAnswer200Response,
  PutResidentAssessmentAnswer409Response,
  PutResidentAssessmentComment200Response,
  ResidentAssessment,
} from '../models/index';
import {
    ApproveAssessmentCompletionRequestFromJSON,
    ApproveAssessmentCompletionRequestToJSON,
    AssessmentCompletionFromJSON,
    AssessmentCompletionToJSON,
    AssessmentCompletionAnswerFromJSON,
    AssessmentCompletionAnswerToJSON,
    AssessmentCompletionCommentFromJSON,
    AssessmentCompletionCommentToJSON,
    AssessmentCompletionStateFromJSON,
    AssessmentCompletionStateToJSON,
    AssessmentQuestionFromJSON,
    AssessmentQuestionToJSON,
    AssessmentStateFromJSON,
    AssessmentStateToJSON,
    CancelCarePlanEsignRequestFromJSON,
    CancelCarePlanEsignRequestToJSON,
    CarePackageFromJSON,
    CarePackageToJSON,
    CarePackageCategoryFromJSON,
    CarePackageCategoryToJSON,
    CarePlanEntryTemplateFromJSON,
    CarePlanEntryTemplateToJSON,
    CarePlanEsignRecipientFromJSON,
    CarePlanEsignRecipientToJSON,
    CareSchemeFromJSON,
    CareSchemeToJSON,
    CompleteResidentAssessmentRequestFromJSON,
    CompleteResidentAssessmentRequestToJSON,
    CreateAssessment201ResponseFromJSON,
    CreateAssessment201ResponseToJSON,
    CreateAssessmentCategory201ResponseFromJSON,
    CreateAssessmentCategory201ResponseToJSON,
    CreateAssessmentCategoryRequestFromJSON,
    CreateAssessmentCategoryRequestToJSON,
    CreateAssessmentQuestion201ResponseFromJSON,
    CreateAssessmentQuestion201ResponseToJSON,
    CreateAssessmentRequestFromJSON,
    CreateAssessmentRequestToJSON,
    CreateAssessmentVersion201ResponseFromJSON,
    CreateAssessmentVersion201ResponseToJSON,
    CreateCarePackage201ResponseFromJSON,
    CreateCarePackage201ResponseToJSON,
    CreateCarePackageCategory201ResponseFromJSON,
    CreateCarePackageCategory201ResponseToJSON,
    CreateCarePlanEntryTemplate201ResponseFromJSON,
    CreateCarePlanEntryTemplate201ResponseToJSON,
    CreateCarePlanEsignRecipient200ResponseFromJSON,
    CreateCarePlanEsignRecipient200ResponseToJSON,
    CreateNgRepositoryEntry201ResponseFromJSON,
    CreateNgRepositoryEntry201ResponseToJSON,
    CreateResidentAssessment201ResponseFromJSON,
    CreateResidentAssessment201ResponseToJSON,
    CreateResidentAssessment400ResponseFromJSON,
    CreateResidentAssessment400ResponseToJSON,
    DuplicateAssessment201ResponseFromJSON,
    DuplicateAssessment201ResponseToJSON,
    DuplicateAssessmentRequestFromJSON,
    DuplicateAssessmentRequestToJSON,
    FindAllAssessments200ResponseFromJSON,
    FindAllAssessments200ResponseToJSON,
    FindAllCarePackageCategories200ResponseFromJSON,
    FindAllCarePackageCategories200ResponseToJSON,
    FindAllCarePackages200ResponseFromJSON,
    FindAllCarePackages200ResponseToJSON,
    FindAllCarePackagesWithoutPrice200ResponseFromJSON,
    FindAllCarePackagesWithoutPrice200ResponseToJSON,
    FindAllCareSchemes200ResponseFromJSON,
    FindAllCareSchemes200ResponseToJSON,
    FindAllFacilityAssessmentCompletions200ResponseFromJSON,
    FindAllFacilityAssessmentCompletions200ResponseToJSON,
    FindAllNgRepositoryEntries200ResponseFromJSON,
    FindAllNgRepositoryEntries200ResponseToJSON,
    FindAllResidentAssessmentAnswers200ResponseFromJSON,
    FindAllResidentAssessmentAnswers200ResponseToJSON,
    FindAllResidentAssessmentAnswersOrdered200ResponseFromJSON,
    FindAllResidentAssessmentAnswersOrdered200ResponseToJSON,
    FindAllResidentAssessmentComments200ResponseFromJSON,
    FindAllResidentAssessmentComments200ResponseToJSON,
    FindAllResidentAssessmentCompletions200ResponseFromJSON,
    FindAllResidentAssessmentCompletions200ResponseToJSON,
    FindAllResidentAssessments200ResponseFromJSON,
    FindAllResidentAssessments200ResponseToJSON,
    FindOneCareScheme200ResponseFromJSON,
    FindOneCareScheme200ResponseToJSON,
    FindOneResidentAssessmentCompletion200ResponseFromJSON,
    FindOneResidentAssessmentCompletion200ResponseToJSON,
    GetCarePlan200ResponseFromJSON,
    GetCarePlan200ResponseToJSON,
    GetCarePlanEsign200ResponseFromJSON,
    GetCarePlanEsign200ResponseToJSON,
    GetCarePlanPrices200ResponseFromJSON,
    GetCarePlanPrices200ResponseToJSON,
    NgRepositoryEntryFromJSON,
    NgRepositoryEntryToJSON,
    OverrideResidentAnswerPointsRequestFromJSON,
    OverrideResidentAnswerPointsRequestToJSON,
    PutAssessmentCategoryOrderRequestFromJSON,
    PutAssessmentCategoryOrderRequestToJSON,
    PutAssessmentQuestionOrderRequestFromJSON,
    PutAssessmentQuestionOrderRequestToJSON,
    PutAssessmentRequestFromJSON,
    PutAssessmentRequestToJSON,
    PutCarePlanEsignRequestFromJSON,
    PutCarePlanEsignRequestToJSON,
    PutManuallySignedDocumentS3KeyRequestFromJSON,
    PutManuallySignedDocumentS3KeyRequestToJSON,
    PutResidentAssessmentAnswer200ResponseFromJSON,
    PutResidentAssessmentAnswer200ResponseToJSON,
    PutResidentAssessmentAnswer409ResponseFromJSON,
    PutResidentAssessmentAnswer409ResponseToJSON,
    PutResidentAssessmentComment200ResponseFromJSON,
    PutResidentAssessmentComment200ResponseToJSON,
    ResidentAssessmentFromJSON,
    ResidentAssessmentToJSON,
} from '../models/index';

export interface ApproveAssessmentCompletionOperationRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    approveAssessmentCompletionRequest?: ApproveAssessmentCompletionRequest;
}

export interface CancelCarePlanEsignOperationRequest {
    residentAssessmentId: string;
    completionId: string;
    cancelCarePlanEsignRequest?: CancelCarePlanEsignRequest;
}

export interface CompleteResidentAssessmentOperationRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    completeResidentAssessmentRequest?: CompleteResidentAssessmentRequest;
}

export interface CreateAssessmentOperationRequest {
    createAssessmentRequest: CreateAssessmentRequest;
}

export interface CreateAssessmentCategoryOperationRequest {
    assessmentId: string;
    createAssessmentCategoryRequest: CreateAssessmentCategoryRequest;
}

export interface CreateAssessmentQuestionRequest {
    assessmentId: string;
    categoryId: string;
    assessmentQuestion: AssessmentQuestion;
}

export interface CreateAssessmentVersionRequest {
    assessmentId: string;
}

export interface CreateCarePackageRequest {
    categoryId: any;
    carePackage?: CarePackage;
}

export interface CreateCarePackageCategoryRequest {
    carePackageCategory?: CarePackageCategory;
}

export interface CreateCarePlanEntryTemplateRequest {
    carePlanEntryTemplate: CarePlanEntryTemplate;
}

export interface CreateCarePlanEsignRecipientRequest {
    residentAssessmentId: string;
    completionId: string;
    carePlanEsignRecipient?: CarePlanEsignRecipient;
}

export interface CreateNgRepositoryEntryRequest {
    ngRepositoryEntry?: NgRepositoryEntry;
}

export interface CreateResidentAssessmentRequest {
    residentId: string;
    residentAssessment?: ResidentAssessment;
}

export interface DeleteAssessmentRequest {
    assessmentId: string;
}

export interface DeleteAssessmentCategoryRequest {
    assessmentId: string;
    categoryId: string;
}

export interface DeleteAssessmentQuestionRequest {
    assessmentId: string;
    categoryId: string;
    questionId: string;
}

export interface DeleteCarePackageRequest {
    categoryId: any;
    packageId: string;
}

export interface DeleteCarePackageCategoryRequest {
    categoryId: string;
}

export interface DeleteCarePlanEntryTemplateRequest {
    templateId: string;
}

export interface DeleteCarePlanEsignRecipientRequest {
    residentAssessmentId: string;
    completionId: string;
    recipientId: string;
}

export interface DeleteNgRepositoryEntryRequest {
    entryId: string;
}

export interface DeleteResidentAssessmentRequest {
    residentId: string;
    residentAssessmentId: string;
    force?: boolean;
}

export interface DeleteResidentAssessmentAnswerRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    answerId: string;
    force?: boolean;
}

export interface DeleteResidentAssessmentCommentRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    commentId: string;
}

export interface DuplicateAssessmentOperationRequest {
    assessmentId: string;
    duplicateAssessmentRequest: DuplicateAssessmentRequest;
}

export interface FindAllAssessmentsRequest {
    facilityId?: string;
    state?: AssessmentState;
    latestVersionsOnly?: boolean;
}

export interface FindAllCarePackagesRequest {
    search?: string;
}

export interface FindAllCarePackagesByCategoryRequest {
    categoryId: any;
}

export interface FindAllCarePackagesWithoutPriceRequest {
    search?: string;
}

export interface FindAllFacilityAssessmentCompletionsRequest {
    facilityId?: string;
    residentId?: string;
    search?: string;
    state?: AssessmentCompletionState;
    offset?: number;
    limit?: number;
    withPackagesTotal?: boolean;
}

export interface FindAllResidentAssessmentAnswersRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface FindAllResidentAssessmentAnswersOrderedRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface FindAllResidentAssessmentCommentsRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface FindAllResidentAssessmentCompletionsRequest {
    residentId: string;
    residentAssessmentId: string;
    state?: AssessmentCompletionState;
}

export interface FindAllResidentAssessmentsRequest {
    residentId: string;
}

export interface FindOneAssessmentRequest {
    assessmentId: string;
}

export interface FindOneCarePlanEntryTemplateRequest {
    templateId: string;
}

export interface FindOneCareSchemeRequest {
    facilityId: string;
}

export interface FindOneResidentAssessmentRequest {
    residentId: string;
    residentAssessmentId: string;
}

export interface FindOneResidentAssessmentCompletionRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface GenerateCarePlanDocumentRequest {
    residentAssessmentId: string;
    completionId: string;
}

export interface GetCarePlanRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface GetCarePlanEsignRequest {
    residentAssessmentId: string;
    completionId: string;
}

export interface GetCarePlanPricesRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
}

export interface OverrideResidentAnswerPointsOperationRequest {
    completionId: string;
    residentAnswerId: string;
    overrideResidentAnswerPointsRequest?: OverrideResidentAnswerPointsRequest;
}

export interface PublishAssessmentRequest {
    assessmentId: any;
}

export interface PutAssessmentOperationRequest {
    assessmentId: string;
    putAssessmentRequest: PutAssessmentRequest;
}

export interface PutAssessmentCategoryRequest {
    assessmentId: string;
    categoryId: string;
    createAssessmentCategoryRequest: CreateAssessmentCategoryRequest;
}

export interface PutAssessmentCategoryOrderOperationRequest {
    assessmentId: string;
    putAssessmentCategoryOrderRequest: PutAssessmentCategoryOrderRequest;
}

export interface PutAssessmentQuestionRequest {
    assessmentId: string;
    categoryId: string;
    questionId: string;
    assessmentQuestion: AssessmentQuestion;
}

export interface PutAssessmentQuestionOrderOperationRequest {
    assessmentId: string;
    categoryId: string;
    putAssessmentQuestionOrderRequest: PutAssessmentQuestionOrderRequest;
}

export interface PutCarePlanEntryTemplateRequest {
    templateId: string;
    carePlanEntryTemplate: CarePlanEntryTemplate;
}

export interface PutCarePlanEsignOperationRequest {
    residentAssessmentId: string;
    completionId: string;
    putCarePlanEsignRequest?: PutCarePlanEsignRequest;
}

export interface PutManuallySignedDocumentS3KeyOperationRequest {
    residentAssessmentId: string;
    completionId: string;
    recipientId: any;
    putManuallySignedDocumentS3KeyRequest?: PutManuallySignedDocumentS3KeyRequest;
}

export interface PutResidentAssessmentAnswerRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    force?: boolean;
    assessmentCompletionAnswer?: AssessmentCompletionAnswer;
}

export interface PutResidentAssessmentCommentRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    assessmentCompletionComment?: AssessmentCompletionComment;
}

export interface ResendCarePlanEsignRequest {
    residentAssessmentId: string;
    completionId: string;
    recipientId?: string;
}

export interface SendCarePlanEsignRequest {
    residentAssessmentId: string;
    completionId: string;
}

export interface UpdateCarePackageRequest {
    categoryId: any;
    packageId: string;
    carePackage?: CarePackage;
}

export interface UpdateCarePackageCategoryRequest {
    categoryId: string;
    carePackageCategory?: CarePackageCategory;
}

export interface UpdateCareSchemeRequest {
    facilityId: string;
    careScheme?: CareScheme;
}

export interface UpdateNgRepositoryEntryRequest {
    entryId: string;
    ngRepositoryEntry?: NgRepositoryEntry;
}

export interface UpdateResidentAssessmentRequest {
    residentId: string;
    residentAssessmentId: string;
    residentAssessment?: ResidentAssessment;
}

export interface UpdateResidentAssessmentCompletionRequest {
    residentId: string;
    residentAssessmentId: string;
    completionId: string;
    assessmentCompletion?: AssessmentCompletion;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Approve an assessment completion (move it to the \'approved\' state)
     */
    async approveAssessmentCompletionRaw(requestParameters: ApproveAssessmentCompletionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling approveAssessmentCompletion.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling approveAssessmentCompletion.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling approveAssessmentCompletion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/approve`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveAssessmentCompletionRequestToJSON(requestParameters.approveAssessmentCompletionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve an assessment completion (move it to the \'approved\' state)
     */
    async approveAssessmentCompletion(requestParameters: ApproveAssessmentCompletionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveAssessmentCompletionRaw(requestParameters, initOverrides);
    }

    /**
     * Cancels a care plan e-signature
     */
    async cancelCarePlanEsignRaw(requestParameters: CancelCarePlanEsignOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling cancelCarePlanEsign.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling cancelCarePlanEsign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/cancel`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CancelCarePlanEsignRequestToJSON(requestParameters.cancelCarePlanEsignRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancels a care plan e-signature
     */
    async cancelCarePlanEsign(requestParameters: CancelCarePlanEsignOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelCarePlanEsignRaw(requestParameters, initOverrides);
    }

    /**
     * Complete an assessment (move it to the \'completed\' state so it\'s awaiting review)
     */
    async completeResidentAssessmentRaw(requestParameters: CompleteResidentAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling completeResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling completeResidentAssessment.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling completeResidentAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/complete`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteResidentAssessmentRequestToJSON(requestParameters.completeResidentAssessmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete an assessment (move it to the \'completed\' state so it\'s awaiting review)
     */
    async completeResidentAssessment(requestParameters: CompleteResidentAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.completeResidentAssessmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create assessment
     */
    async createAssessmentRaw(requestParameters: CreateAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessment201Response>> {
        if (requestParameters.createAssessmentRequest === null || requestParameters.createAssessmentRequest === undefined) {
            throw new runtime.RequiredError('createAssessmentRequest','Required parameter requestParameters.createAssessmentRequest was null or undefined when calling createAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssessmentRequestToJSON(requestParameters.createAssessmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Create assessment
     */
    async createAssessment(requestParameters: CreateAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessment201Response> {
        const response = await this.createAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create assessment category
     */
    async createAssessmentCategoryRaw(requestParameters: CreateAssessmentCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessmentCategory201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling createAssessmentCategory.');
        }

        if (requestParameters.createAssessmentCategoryRequest === null || requestParameters.createAssessmentCategoryRequest === undefined) {
            throw new runtime.RequiredError('createAssessmentCategoryRequest','Required parameter requestParameters.createAssessmentCategoryRequest was null or undefined when calling createAssessmentCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssessmentCategoryRequestToJSON(requestParameters.createAssessmentCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessmentCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create assessment category
     */
    async createAssessmentCategory(requestParameters: CreateAssessmentCategoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessmentCategory201Response> {
        const response = await this.createAssessmentCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create assessment question
     */
    async createAssessmentQuestionRaw(requestParameters: CreateAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessmentQuestion201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling createAssessmentQuestion.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling createAssessmentQuestion.');
        }

        if (requestParameters.assessmentQuestion === null || requestParameters.assessmentQuestion === undefined) {
            throw new runtime.RequiredError('assessmentQuestion','Required parameter requestParameters.assessmentQuestion was null or undefined when calling createAssessmentQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}/questions`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentQuestionToJSON(requestParameters.assessmentQuestion),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessmentQuestion201ResponseFromJSON(jsonValue));
    }

    /**
     * Create assessment question
     */
    async createAssessmentQuestion(requestParameters: CreateAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessmentQuestion201Response> {
        const response = await this.createAssessmentQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new version of the assessment (duplicates the assessment and increments the version number)
     */
    async createAssessmentVersionRaw(requestParameters: CreateAssessmentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessmentVersion201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling createAssessmentVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}/version`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessmentVersion201ResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new version of the assessment (duplicates the assessment and increments the version number)
     */
    async createAssessmentVersion(requestParameters: CreateAssessmentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessmentVersion201Response> {
        const response = await this.createAssessmentVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a care package
     */
    async createCarePackageRaw(requestParameters: CreateCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePackage201Response>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling createCarePackage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-packages/categories/{category_id}/packages`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarePackageToJSON(requestParameters.carePackage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePackage201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a care package
     */
    async createCarePackage(requestParameters: CreateCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePackage201Response> {
        const response = await this.createCarePackageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a care package category
     */
    async createCarePackageCategoryRaw(requestParameters: CreateCarePackageCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePackageCategory201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-packages/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarePackageCategoryToJSON(requestParameters.carePackageCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePackageCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a care package category
     */
    async createCarePackageCategory(requestParameters: CreateCarePackageCategoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePackageCategory201Response> {
        const response = await this.createCarePackageCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create assessment care plan entry template
     */
    async createCarePlanEntryTemplateRaw(requestParameters: CreateCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePlanEntryTemplate201Response>> {
        if (requestParameters.carePlanEntryTemplate === null || requestParameters.carePlanEntryTemplate === undefined) {
            throw new runtime.RequiredError('carePlanEntryTemplate','Required parameter requestParameters.carePlanEntryTemplate was null or undefined when calling createCarePlanEntryTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-plan-entry-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarePlanEntryTemplateToJSON(requestParameters.carePlanEntryTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePlanEntryTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create assessment care plan entry template
     */
    async createCarePlanEntryTemplate(requestParameters: CreateCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePlanEntryTemplate201Response> {
        const response = await this.createCarePlanEntryTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a recipient for a care plan e-signature
     */
    async createCarePlanEsignRecipientRaw(requestParameters: CreateCarePlanEsignRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePlanEsignRecipient200Response>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling createCarePlanEsignRecipient.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling createCarePlanEsignRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/recipients`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarePlanEsignRecipientToJSON(requestParameters.carePlanEsignRecipient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePlanEsignRecipient200ResponseFromJSON(jsonValue));
    }

    /**
     * Creates a recipient for a care plan e-signature
     */
    async createCarePlanEsignRecipient(requestParameters: CreateCarePlanEsignRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePlanEsignRecipient200Response> {
        const response = await this.createCarePlanEsignRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an NG repository entry
     */
    async createNgRepositoryEntryRaw(requestParameters: CreateNgRepositoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateNgRepositoryEntry201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ng-repository/entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NgRepositoryEntryToJSON(requestParameters.ngRepositoryEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateNgRepositoryEntry201ResponseFromJSON(jsonValue));
    }

    /**
     * Create an NG repository entry
     */
    async createNgRepositoryEntry(requestParameters: CreateNgRepositoryEntryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateNgRepositoryEntry201Response> {
        const response = await this.createNgRepositoryEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an assessment for a resident
     */
    async createResidentAssessmentRaw(requestParameters: CreateResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateResidentAssessment201Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling createResidentAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentAssessmentToJSON(requestParameters.residentAssessment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResidentAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Create an assessment for a resident
     */
    async createResidentAssessment(requestParameters: CreateResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateResidentAssessment201Response> {
        const response = await this.createResidentAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete assessment
     */
    async deleteAssessmentRaw(requestParameters: DeleteAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling deleteAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete assessment
     */
    async deleteAssessment(requestParameters: DeleteAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAssessmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete assessment category
     */
    async deleteAssessmentCategoryRaw(requestParameters: DeleteAssessmentCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling deleteAssessmentCategory.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteAssessmentCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete assessment category
     */
    async deleteAssessmentCategory(requestParameters: DeleteAssessmentCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAssessmentCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Delete assessment question
     */
    async deleteAssessmentQuestionRaw(requestParameters: DeleteAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling deleteAssessmentQuestion.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteAssessmentQuestion.');
        }

        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling deleteAssessmentQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}/questions/{question_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete assessment question
     */
    async deleteAssessmentQuestion(requestParameters: DeleteAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAssessmentQuestionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a care package
     */
    async deleteCarePackageRaw(requestParameters: DeleteCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCarePackage.');
        }

        if (requestParameters.packageId === null || requestParameters.packageId === undefined) {
            throw new runtime.RequiredError('packageId','Required parameter requestParameters.packageId was null or undefined when calling deleteCarePackage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages/categories/{category_id}/packages/{package_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"package_id"}}`, encodeURIComponent(String(requestParameters.packageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a care package
     */
    async deleteCarePackage(requestParameters: DeleteCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarePackageRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a care package category
     */
    async deleteCarePackageCategoryRaw(requestParameters: DeleteCarePackageCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCarePackageCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages/categories/{category_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a care package category
     */
    async deleteCarePackageCategory(requestParameters: DeleteCarePackageCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarePackageCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Delete assessment care plan entry template
     */
    async deleteCarePlanEntryTemplateRaw(requestParameters: DeleteCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling deleteCarePlanEntryTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-plan-entry-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete assessment care plan entry template
     */
    async deleteCarePlanEntryTemplate(requestParameters: DeleteCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarePlanEntryTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a recipient for a care plan e-signature
     */
    async deleteCarePlanEsignRecipientRaw(requestParameters: DeleteCarePlanEsignRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling deleteCarePlanEsignRecipient.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling deleteCarePlanEsignRecipient.');
        }

        if (requestParameters.recipientId === null || requestParameters.recipientId === undefined) {
            throw new runtime.RequiredError('recipientId','Required parameter requestParameters.recipientId was null or undefined when calling deleteCarePlanEsignRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/recipients/{recipient_id}`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))).replace(`{${"recipient_id"}}`, encodeURIComponent(String(requestParameters.recipientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a recipient for a care plan e-signature
     */
    async deleteCarePlanEsignRecipient(requestParameters: DeleteCarePlanEsignRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarePlanEsignRecipientRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an NG repository entry
     */
    async deleteNgRepositoryEntryRaw(requestParameters: DeleteNgRepositoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entryId === null || requestParameters.entryId === undefined) {
            throw new runtime.RequiredError('entryId','Required parameter requestParameters.entryId was null or undefined when calling deleteNgRepositoryEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ng-repository/entries/{entry_id}`.replace(`{${"entry_id"}}`, encodeURIComponent(String(requestParameters.entryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an NG repository entry
     */
    async deleteNgRepositoryEntry(requestParameters: DeleteNgRepositoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNgRepositoryEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a resident assessment
     */
    async deleteResidentAssessmentRaw(requestParameters: DeleteResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deleteResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling deleteResidentAssessment.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a resident assessment
     */
    async deleteResidentAssessment(requestParameters: DeleteResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResidentAssessmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an assessment answer
     */
    async deleteResidentAssessmentAnswerRaw(requestParameters: DeleteResidentAssessmentAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deleteResidentAssessmentAnswer.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling deleteResidentAssessmentAnswer.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling deleteResidentAssessmentAnswer.');
        }

        if (requestParameters.answerId === null || requestParameters.answerId === undefined) {
            throw new runtime.RequiredError('answerId','Required parameter requestParameters.answerId was null or undefined when calling deleteResidentAssessmentAnswer.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/answers/{answer_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))).replace(`{${"answer_id"}}`, encodeURIComponent(String(requestParameters.answerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an assessment answer
     */
    async deleteResidentAssessmentAnswer(requestParameters: DeleteResidentAssessmentAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResidentAssessmentAnswerRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an assessment comment
     */
    async deleteResidentAssessmentCommentRaw(requestParameters: DeleteResidentAssessmentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deleteResidentAssessmentComment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling deleteResidentAssessmentComment.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling deleteResidentAssessmentComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteResidentAssessmentComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/comments/{comment_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))).replace(`{${"comment_id"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an assessment comment
     */
    async deleteResidentAssessmentComment(requestParameters: DeleteResidentAssessmentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResidentAssessmentCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Duplicate assessment
     */
    async duplicateAssessmentRaw(requestParameters: DuplicateAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateAssessment201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling duplicateAssessment.');
        }

        if (requestParameters.duplicateAssessmentRequest === null || requestParameters.duplicateAssessmentRequest === undefined) {
            throw new runtime.RequiredError('duplicateAssessmentRequest','Required parameter requestParameters.duplicateAssessmentRequest was null or undefined when calling duplicateAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/duplicate`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateAssessmentRequestToJSON(requestParameters.duplicateAssessmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicateAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate assessment
     */
    async duplicateAssessment(requestParameters: DuplicateAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateAssessment201Response> {
        const response = await this.duplicateAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get assessments
     */
    async findAllAssessmentsRaw(requestParameters: FindAllAssessmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllAssessments200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.latestVersionsOnly !== undefined) {
            queryParameters['latest_versions_only'] = requestParameters.latestVersionsOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllAssessments200ResponseFromJSON(jsonValue));
    }

    /**
     * Get assessments
     */
    async findAllAssessments(requestParameters: FindAllAssessmentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllAssessments200Response> {
        const response = await this.findAllAssessmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all care package categories
     */
    async findAllCarePackageCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCarePackageCategories200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCarePackageCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all care package categories
     */
    async findAllCarePackageCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCarePackageCategories200Response> {
        const response = await this.findAllCarePackageCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all care packages
     */
    async findAllCarePackagesRaw(requestParameters: FindAllCarePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCarePackages200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCarePackages200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all care packages
     */
    async findAllCarePackages(requestParameters: FindAllCarePackagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCarePackages200Response> {
        const response = await this.findAllCarePackagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all care packages
     */
    async findAllCarePackagesByCategoryRaw(requestParameters: FindAllCarePackagesByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCarePackages200Response>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling findAllCarePackagesByCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages/categories/{category_id}/packages`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCarePackages200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all care packages
     */
    async findAllCarePackagesByCategory(requestParameters: FindAllCarePackagesByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCarePackages200Response> {
        const response = await this.findAllCarePackagesByCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all care packages for a community without price
     */
    async findAllCarePackagesWithoutPriceRaw(requestParameters: FindAllCarePackagesWithoutPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCarePackagesWithoutPrice200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-packages-without-price`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCarePackagesWithoutPrice200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all care packages for a community without price
     */
    async findAllCarePackagesWithoutPrice(requestParameters: FindAllCarePackagesWithoutPriceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCarePackagesWithoutPrice200Response> {
        const response = await this.findAllCarePackagesWithoutPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all care schemes for all communities
     */
    async findAllCareSchemesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCareSchemes200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-schemes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCareSchemes200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all care schemes for all communities
     */
    async findAllCareSchemes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCareSchemes200Response> {
        const response = await this.findAllCareSchemesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all assessment completions for a facility
     */
    async findAllFacilityAssessmentCompletionsRaw(requestParameters: FindAllFacilityAssessmentCompletionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllFacilityAssessmentCompletions200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.withPackagesTotal !== undefined) {
            queryParameters['with_packages_total'] = requestParameters.withPackagesTotal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessment-completions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllFacilityAssessmentCompletions200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessment completions for a facility
     */
    async findAllFacilityAssessmentCompletions(requestParameters: FindAllFacilityAssessmentCompletionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllFacilityAssessmentCompletions200Response> {
        const response = await this.findAllFacilityAssessmentCompletionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all NG repository entries
     */
    async findAllNgRepositoryEntriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllNgRepositoryEntries200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ng-repository/entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllNgRepositoryEntries200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all NG repository entries
     */
    async findAllNgRepositoryEntries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllNgRepositoryEntries200Response> {
        const response = await this.findAllNgRepositoryEntriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all assessment answers for a resident
     */
    async findAllResidentAssessmentAnswersRaw(requestParameters: FindAllResidentAssessmentAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentAssessmentAnswers200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findAllResidentAssessmentAnswers.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findAllResidentAssessmentAnswers.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling findAllResidentAssessmentAnswers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/answers`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentAssessmentAnswers200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessment answers for a resident
     */
    async findAllResidentAssessmentAnswers(requestParameters: FindAllResidentAssessmentAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentAssessmentAnswers200Response> {
        const response = await this.findAllResidentAssessmentAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all assessment answers for a resident grouped by assessment categories and ordered as in assessment
     */
    async findAllResidentAssessmentAnswersOrderedRaw(requestParameters: FindAllResidentAssessmentAnswersOrderedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentAssessmentAnswersOrdered200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findAllResidentAssessmentAnswersOrdered.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findAllResidentAssessmentAnswersOrdered.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling findAllResidentAssessmentAnswersOrdered.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/ordered_answers`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentAssessmentAnswersOrdered200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessment answers for a resident grouped by assessment categories and ordered as in assessment
     */
    async findAllResidentAssessmentAnswersOrdered(requestParameters: FindAllResidentAssessmentAnswersOrderedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentAssessmentAnswersOrdered200Response> {
        const response = await this.findAllResidentAssessmentAnswersOrderedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all assessment comments for a resident
     */
    async findAllResidentAssessmentCommentsRaw(requestParameters: FindAllResidentAssessmentCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentAssessmentComments200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findAllResidentAssessmentComments.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findAllResidentAssessmentComments.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling findAllResidentAssessmentComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/comments`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentAssessmentComments200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessment comments for a resident
     */
    async findAllResidentAssessmentComments(requestParameters: FindAllResidentAssessmentCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentAssessmentComments200Response> {
        const response = await this.findAllResidentAssessmentCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all assessment completions for a resident
     */
    async findAllResidentAssessmentCompletionsRaw(requestParameters: FindAllResidentAssessmentCompletionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentAssessmentCompletions200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findAllResidentAssessmentCompletions.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findAllResidentAssessmentCompletions.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentAssessmentCompletions200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessment completions for a resident
     */
    async findAllResidentAssessmentCompletions(requestParameters: FindAllResidentAssessmentCompletionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentAssessmentCompletions200Response> {
        const response = await this.findAllResidentAssessmentCompletionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all assessments for a resident
     */
    async findAllResidentAssessmentsRaw(requestParameters: FindAllResidentAssessmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentAssessments200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findAllResidentAssessments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentAssessments200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all assessments for a resident
     */
    async findAllResidentAssessments(requestParameters: FindAllResidentAssessmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentAssessments200Response> {
        const response = await this.findAllResidentAssessmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get assessment
     */
    async findOneAssessmentRaw(requestParameters: FindOneAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessment201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling findOneAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Get assessment
     */
    async findOneAssessment(requestParameters: FindOneAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessment201Response> {
        const response = await this.findOneAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get assessment care plan entry template
     */
    async findOneCarePlanEntryTemplateRaw(requestParameters: FindOneCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePlanEntryTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling findOneCarePlanEntryTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-plan-entry-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePlanEntryTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Get assessment care plan entry template
     */
    async findOneCarePlanEntryTemplate(requestParameters: FindOneCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePlanEntryTemplate201Response> {
        const response = await this.findOneCarePlanEntryTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find one care scheme for a community
     */
    async findOneCareSchemeRaw(requestParameters: FindOneCareSchemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneCareScheme200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findOneCareScheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facility_id}/care-scheme`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneCareScheme200ResponseFromJSON(jsonValue));
    }

    /**
     * Find one care scheme for a community
     */
    async findOneCareScheme(requestParameters: FindOneCareSchemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneCareScheme200Response> {
        const response = await this.findOneCareSchemeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a resident assessment
     */
    async findOneResidentAssessmentRaw(requestParameters: FindOneResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateResidentAssessment201Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findOneResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findOneResidentAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResidentAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Find a resident assessment
     */
    async findOneResidentAssessment(requestParameters: FindOneResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateResidentAssessment201Response> {
        const response = await this.findOneResidentAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a resident assessment completion
     */
    async findOneResidentAssessmentCompletionRaw(requestParameters: FindOneResidentAssessmentCompletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneResidentAssessmentCompletion200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findOneResidentAssessmentCompletion.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling findOneResidentAssessmentCompletion.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling findOneResidentAssessmentCompletion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneResidentAssessmentCompletion200ResponseFromJSON(jsonValue));
    }

    /**
     * Find a resident assessment completion
     */
    async findOneResidentAssessmentCompletion(requestParameters: FindOneResidentAssessmentCompletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneResidentAssessmentCompletion200Response> {
        const response = await this.findOneResidentAssessmentCompletionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Starts generation of a care plan document for esign
     */
    async generateCarePlanDocumentRaw(requestParameters: GenerateCarePlanDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling generateCarePlanDocument.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling generateCarePlanDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/document`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Starts generation of a care plan document for esign
     */
    async generateCarePlanDocument(requestParameters: GenerateCarePlanDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateCarePlanDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Get CPE templates based on the assessment completion
     */
    async getCarePlanRaw(requestParameters: GetCarePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarePlan200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getCarePlan.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getCarePlan.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling getCarePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/care-plan`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarePlan200ResponseFromJSON(jsonValue));
    }

    /**
     * Get CPE templates based on the assessment completion
     */
    async getCarePlan(requestParameters: GetCarePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarePlan200Response> {
        const response = await this.getCarePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets or creates e-signature entity for a care plan
     */
    async getCarePlanEsignRaw(requestParameters: GetCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarePlanEsign200Response>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getCarePlanEsign.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling getCarePlanEsign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarePlanEsign200ResponseFromJSON(jsonValue));
    }

    /**
     * Gets or creates e-signature entity for a care plan
     */
    async getCarePlanEsign(requestParameters: GetCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarePlanEsign200Response> {
        const response = await this.getCarePlanEsignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get prices for a care plan based on the assessment completion
     */
    async getCarePlanPricesRaw(requestParameters: GetCarePlanPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarePlanPrices200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getCarePlanPrices.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getCarePlanPrices.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling getCarePlanPrices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/prices`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarePlanPrices200ResponseFromJSON(jsonValue));
    }

    /**
     * Get prices for a care plan based on the assessment completion
     */
    async getCarePlanPrices(requestParameters: GetCarePlanPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarePlanPrices200Response> {
        const response = await this.getCarePlanPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Override points for an assessment answer
     */
    async overrideResidentAnswerPointsRaw(requestParameters: OverrideResidentAnswerPointsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling overrideResidentAnswerPoints.');
        }

        if (requestParameters.residentAnswerId === null || requestParameters.residentAnswerId === undefined) {
            throw new runtime.RequiredError('residentAnswerId','Required parameter requestParameters.residentAnswerId was null or undefined when calling overrideResidentAnswerPoints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessment-completions/{completion_id}/answers/{resident_answer_id}/override-points`.replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))).replace(`{${"resident_answer_id"}}`, encodeURIComponent(String(requestParameters.residentAnswerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OverrideResidentAnswerPointsRequestToJSON(requestParameters.overrideResidentAnswerPointsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Override points for an assessment answer
     */
    async overrideResidentAnswerPoints(requestParameters: OverrideResidentAnswerPointsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.overrideResidentAnswerPointsRaw(requestParameters, initOverrides);
    }

    /**
     * Publish assessment (and lock it for editing)
     */
    async publishAssessmentRaw(requestParameters: PublishAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling publishAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assessments/{assessment_id}/publish`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish assessment (and lock it for editing)
     */
    async publishAssessment(requestParameters: PublishAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishAssessmentRaw(requestParameters, initOverrides);
    }

    /**
     * Update assessment (allows to update name and care unit type)
     */
    async putAssessmentRaw(requestParameters: PutAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessment201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling putAssessment.');
        }

        if (requestParameters.putAssessmentRequest === null || requestParameters.putAssessmentRequest === undefined) {
            throw new runtime.RequiredError('putAssessmentRequest','Required parameter requestParameters.putAssessmentRequest was null or undefined when calling putAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAssessmentRequestToJSON(requestParameters.putAssessmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Update assessment (allows to update name and care unit type)
     */
    async putAssessment(requestParameters: PutAssessmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessment201Response> {
        const response = await this.putAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update assessment category
     */
    async putAssessmentCategoryRaw(requestParameters: PutAssessmentCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessmentCategory201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling putAssessmentCategory.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling putAssessmentCategory.');
        }

        if (requestParameters.createAssessmentCategoryRequest === null || requestParameters.createAssessmentCategoryRequest === undefined) {
            throw new runtime.RequiredError('createAssessmentCategoryRequest','Required parameter requestParameters.createAssessmentCategoryRequest was null or undefined when calling putAssessmentCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssessmentCategoryRequestToJSON(requestParameters.createAssessmentCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessmentCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update assessment category
     */
    async putAssessmentCategory(requestParameters: PutAssessmentCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessmentCategory201Response> {
        const response = await this.putAssessmentCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update assessment category order
     */
    async putAssessmentCategoryOrderRaw(requestParameters: PutAssessmentCategoryOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling putAssessmentCategoryOrder.');
        }

        if (requestParameters.putAssessmentCategoryOrderRequest === null || requestParameters.putAssessmentCategoryOrderRequest === undefined) {
            throw new runtime.RequiredError('putAssessmentCategoryOrderRequest','Required parameter requestParameters.putAssessmentCategoryOrderRequest was null or undefined when calling putAssessmentCategoryOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/order`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAssessmentCategoryOrderRequestToJSON(requestParameters.putAssessmentCategoryOrderRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update assessment category order
     */
    async putAssessmentCategoryOrder(requestParameters: PutAssessmentCategoryOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putAssessmentCategoryOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Update assessment question
     */
    async putAssessmentQuestionRaw(requestParameters: PutAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAssessmentQuestion201Response>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling putAssessmentQuestion.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling putAssessmentQuestion.');
        }

        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling putAssessmentQuestion.');
        }

        if (requestParameters.assessmentQuestion === null || requestParameters.assessmentQuestion === undefined) {
            throw new runtime.RequiredError('assessmentQuestion','Required parameter requestParameters.assessmentQuestion was null or undefined when calling putAssessmentQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}/questions/{question_id}`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentQuestionToJSON(requestParameters.assessmentQuestion),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAssessmentQuestion201ResponseFromJSON(jsonValue));
    }

    /**
     * Update assessment question
     */
    async putAssessmentQuestion(requestParameters: PutAssessmentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAssessmentQuestion201Response> {
        const response = await this.putAssessmentQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update assessment category order
     */
    async putAssessmentQuestionOrderRaw(requestParameters: PutAssessmentQuestionOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assessmentId === null || requestParameters.assessmentId === undefined) {
            throw new runtime.RequiredError('assessmentId','Required parameter requestParameters.assessmentId was null or undefined when calling putAssessmentQuestionOrder.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling putAssessmentQuestionOrder.');
        }

        if (requestParameters.putAssessmentQuestionOrderRequest === null || requestParameters.putAssessmentQuestionOrderRequest === undefined) {
            throw new runtime.RequiredError('putAssessmentQuestionOrderRequest','Required parameter requestParameters.putAssessmentQuestionOrderRequest was null or undefined when calling putAssessmentQuestionOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assessments/{assessment_id}/categories/{category_id}/questions/order`.replace(`{${"assessment_id"}}`, encodeURIComponent(String(requestParameters.assessmentId))).replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAssessmentQuestionOrderRequestToJSON(requestParameters.putAssessmentQuestionOrderRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update assessment category order
     */
    async putAssessmentQuestionOrder(requestParameters: PutAssessmentQuestionOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putAssessmentQuestionOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Update assessment care plan entry template
     */
    async putCarePlanEntryTemplateRaw(requestParameters: PutCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePlanEntryTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling putCarePlanEntryTemplate.');
        }

        if (requestParameters.carePlanEntryTemplate === null || requestParameters.carePlanEntryTemplate === undefined) {
            throw new runtime.RequiredError('carePlanEntryTemplate','Required parameter requestParameters.carePlanEntryTemplate was null or undefined when calling putCarePlanEntryTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-plan-entry-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CarePlanEntryTemplateToJSON(requestParameters.carePlanEntryTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePlanEntryTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Update assessment care plan entry template
     */
    async putCarePlanEntryTemplate(requestParameters: PutCarePlanEntryTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePlanEntryTemplate201Response> {
        const response = await this.putCarePlanEntryTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates email subject/body for a care plan e-signature
     */
    async putCarePlanEsignRaw(requestParameters: PutCarePlanEsignOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarePlanEsign200Response>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling putCarePlanEsign.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling putCarePlanEsign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCarePlanEsignRequestToJSON(requestParameters.putCarePlanEsignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarePlanEsign200ResponseFromJSON(jsonValue));
    }

    /**
     * Updates email subject/body for a care plan e-signature
     */
    async putCarePlanEsign(requestParameters: PutCarePlanEsignOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarePlanEsign200Response> {
        const response = await this.putCarePlanEsignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Attaches manual signed copy s3 key to a recipient for a care plan e-signature
     */
    async putManuallySignedDocumentS3KeyRaw(requestParameters: PutManuallySignedDocumentS3KeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling putManuallySignedDocumentS3Key.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling putManuallySignedDocumentS3Key.');
        }

        if (requestParameters.recipientId === null || requestParameters.recipientId === undefined) {
            throw new runtime.RequiredError('recipientId','Required parameter requestParameters.recipientId was null or undefined when calling putManuallySignedDocumentS3Key.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/recipients/{recipient_id}/manual`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))).replace(`{${"recipient_id"}}`, encodeURIComponent(String(requestParameters.recipientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutManuallySignedDocumentS3KeyRequestToJSON(requestParameters.putManuallySignedDocumentS3KeyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attaches manual signed copy s3 key to a recipient for a care plan e-signature
     */
    async putManuallySignedDocumentS3Key(requestParameters: PutManuallySignedDocumentS3KeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putManuallySignedDocumentS3KeyRaw(requestParameters, initOverrides);
    }

    /**
     * Creates/updates an assessment answer for a resident
     */
    async putResidentAssessmentAnswerRaw(requestParameters: PutResidentAssessmentAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutResidentAssessmentAnswer200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putResidentAssessmentAnswer.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling putResidentAssessmentAnswer.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling putResidentAssessmentAnswer.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/answers`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentCompletionAnswerToJSON(requestParameters.assessmentCompletionAnswer),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutResidentAssessmentAnswer200ResponseFromJSON(jsonValue));
    }

    /**
     * Creates/updates an assessment answer for a resident
     */
    async putResidentAssessmentAnswer(requestParameters: PutResidentAssessmentAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutResidentAssessmentAnswer200Response> {
        const response = await this.putResidentAssessmentAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates/updates an answer comment
     */
    async putResidentAssessmentCommentRaw(requestParameters: PutResidentAssessmentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutResidentAssessmentComment200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putResidentAssessmentComment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling putResidentAssessmentComment.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling putResidentAssessmentComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}/comments`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentCompletionCommentToJSON(requestParameters.assessmentCompletionComment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutResidentAssessmentComment200ResponseFromJSON(jsonValue));
    }

    /**
     * Creates/updates an answer comment
     */
    async putResidentAssessmentComment(requestParameters: PutResidentAssessmentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutResidentAssessmentComment200Response> {
        const response = await this.putResidentAssessmentCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resends a care plan e-signature
     */
    async resendCarePlanEsignRaw(requestParameters: ResendCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling resendCarePlanEsign.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling resendCarePlanEsign.');
        }

        const queryParameters: any = {};

        if (requestParameters.recipientId !== undefined) {
            queryParameters['recipient_id'] = requestParameters.recipientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/resend`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resends a care plan e-signature
     */
    async resendCarePlanEsign(requestParameters: ResendCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendCarePlanEsignRaw(requestParameters, initOverrides);
    }

    /**
     * Sends a care plan e-signature
     */
    async sendCarePlanEsignRaw(requestParameters: SendCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling sendCarePlanEsign.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling sendCarePlanEsign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-assessments/{resident_assessment_id}/completions/{completion_id}/care-plan/esign/send`.replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a care plan e-signature
     */
    async sendCarePlanEsign(requestParameters: SendCarePlanEsignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCarePlanEsignRaw(requestParameters, initOverrides);
    }

    /**
     * Update a care package
     */
    async updateCarePackageRaw(requestParameters: UpdateCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePackage201Response>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCarePackage.');
        }

        if (requestParameters.packageId === null || requestParameters.packageId === undefined) {
            throw new runtime.RequiredError('packageId','Required parameter requestParameters.packageId was null or undefined when calling updateCarePackage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-packages/categories/{category_id}/packages/{package_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"package_id"}}`, encodeURIComponent(String(requestParameters.packageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CarePackageToJSON(requestParameters.carePackage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePackage201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a care package
     */
    async updateCarePackage(requestParameters: UpdateCarePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePackage201Response> {
        const response = await this.updateCarePackageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a care package category
     */
    async updateCarePackageCategoryRaw(requestParameters: UpdateCarePackageCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCarePackageCategory201Response>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCarePackageCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-packages/categories/{category_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CarePackageCategoryToJSON(requestParameters.carePackageCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCarePackageCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a care package category
     */
    async updateCarePackageCategory(requestParameters: UpdateCarePackageCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCarePackageCategory201Response> {
        const response = await this.updateCarePackageCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a care scheme for a community
     */
    async updateCareSchemeRaw(requestParameters: UpdateCareSchemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneCareScheme200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateCareScheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facility_id}/care-scheme`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CareSchemeToJSON(requestParameters.careScheme),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneCareScheme200ResponseFromJSON(jsonValue));
    }

    /**
     * Update a care scheme for a community
     */
    async updateCareScheme(requestParameters: UpdateCareSchemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneCareScheme200Response> {
        const response = await this.updateCareSchemeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an NG repository entry (and a corresponding care plan entry template if attached)
     */
    async updateNgRepositoryEntryRaw(requestParameters: UpdateNgRepositoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateNgRepositoryEntry201Response>> {
        if (requestParameters.entryId === null || requestParameters.entryId === undefined) {
            throw new runtime.RequiredError('entryId','Required parameter requestParameters.entryId was null or undefined when calling updateNgRepositoryEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ng-repository/entries/{entry_id}`.replace(`{${"entry_id"}}`, encodeURIComponent(String(requestParameters.entryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NgRepositoryEntryToJSON(requestParameters.ngRepositoryEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateNgRepositoryEntry201ResponseFromJSON(jsonValue));
    }

    /**
     * Update an NG repository entry (and a corresponding care plan entry template if attached)
     */
    async updateNgRepositoryEntry(requestParameters: UpdateNgRepositoryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateNgRepositoryEntry201Response> {
        const response = await this.updateNgRepositoryEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a resident assessment
     */
    async updateResidentAssessmentRaw(requestParameters: UpdateResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateResidentAssessment201Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling updateResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling updateResidentAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentAssessmentToJSON(requestParameters.residentAssessment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResidentAssessment201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a resident assessment
     */
    async updateResidentAssessment(requestParameters: UpdateResidentAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateResidentAssessment201Response> {
        const response = await this.updateResidentAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a resident assessment completion
     */
    async updateResidentAssessmentCompletionRaw(requestParameters: UpdateResidentAssessmentCompletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneResidentAssessmentCompletion200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling updateResidentAssessmentCompletion.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling updateResidentAssessmentCompletion.');
        }

        if (requestParameters.completionId === null || requestParameters.completionId === undefined) {
            throw new runtime.RequiredError('completionId','Required parameter requestParameters.completionId was null or undefined when calling updateResidentAssessmentCompletion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/assessments/{resident_assessment_id}/completions/{completion_id}`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"resident_assessment_id"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"completion_id"}}`, encodeURIComponent(String(requestParameters.completionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssessmentCompletionToJSON(requestParameters.assessmentCompletion),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneResidentAssessmentCompletion200ResponseFromJSON(jsonValue));
    }

    /**
     * Update a resident assessment completion
     */
    async updateResidentAssessmentCompletion(requestParameters: UpdateResidentAssessmentCompletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneResidentAssessmentCompletion200Response> {
        const response = await this.updateResidentAssessmentCompletionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
