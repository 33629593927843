/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationStockSignOffSignedBy } from './MedicationStockSignOffSignedBy';
import {
    MedicationStockSignOffSignedByFromJSON,
    MedicationStockSignOffSignedByFromJSONTyped,
    MedicationStockSignOffSignedByToJSON,
} from './MedicationStockSignOffSignedBy';
import type { MedicationType } from './MedicationType';
import {
    MedicationTypeFromJSON,
    MedicationTypeFromJSONTyped,
    MedicationTypeToJSON,
} from './MedicationType';

/**
 * 
 * @export
 * @interface MedicationStockSignOff
 */
export interface MedicationStockSignOff {
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    signed_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    user_id: string;
    /**
     * 
     * @type {MedicationType}
     * @memberof MedicationStockSignOff
     */
    type: MedicationType;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    snapshot_s3_url: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationStockSignOff
     */
    updatedAt?: string;
    /**
     * 
     * @type {MedicationStockSignOffSignedBy}
     * @memberof MedicationStockSignOff
     */
    signed_by?: MedicationStockSignOffSignedBy;
}

/**
 * Check if a given object implements the MedicationStockSignOff interface.
 */
export function instanceOfMedicationStockSignOff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "snapshot_s3_url" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function MedicationStockSignOffFromJSON(json: any): MedicationStockSignOff {
    return MedicationStockSignOffFromJSONTyped(json, false);
}

export function MedicationStockSignOffFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationStockSignOff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'signed_at': !exists(json, 'signed_at') ? undefined : json['signed_at'],
        'user_id': json['user_id'],
        'type': MedicationTypeFromJSON(json['type']),
        'snapshot_s3_url': json['snapshot_s3_url'],
        'createdAt': json['createdAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'signed_by': !exists(json, 'signed_by') ? undefined : MedicationStockSignOffSignedByFromJSON(json['signed_by']),
    };
}

export function MedicationStockSignOffToJSON(value?: MedicationStockSignOff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'signed_at': value.signed_at,
        'user_id': value.user_id,
        'type': MedicationTypeToJSON(value.type),
        'snapshot_s3_url': value.snapshot_s3_url,
        'createdAt': value.createdAt,
        'deletedAt': value.deletedAt,
        'updatedAt': value.updatedAt,
        'signed_by': MedicationStockSignOffSignedByToJSON(value.signed_by),
    };
}

