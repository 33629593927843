import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ContactPage } from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowId
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useAtomValue } from 'jotai';

import { LoadingCircularProgress } from '@/components/CircularProgress';
import { DataGridContainer } from '@/components/DataGridContainer';
import ResidentPrintHeaderView from '@/components/ResidentPrintHeaderView';
import StatusChip from '@/components/StatusChip';
import { SignOpenIncident } from '@/hooks/useCreateIncident';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  selectedFacilityIdAtom,
  useFacilitiesQuery
} from '@/hooks/useFacilitiesQuery';
import {
  IncidentPayload,
  Status,
  useIncidentsQuery
} from '@/hooks/useIncidentsQuery';
import { usePrint } from '@/hooks/usePrint';
import { RoutePath } from '@/routes/RoutePath';

import { showAddIncidentConfirmationDialog } from './components/AddIncidentConfirmationDialog';
import IncidentDataGridToolbar from './components/IncidentDataGridToolbar';
import IncidentFirstNameCell from './components/IncidentFirstNameCell';
dayjs.extend(isBetween);

interface showIncidentConfirmationDialogProps {
  formMethods: UseFormReturn<SignOpenIncident, any>;
  handleClose: (value: any) => void;
  open: boolean;
  residentId: number;
  incidentTypeId: number;
  incidentType: string;
  occurenceDate: string;
  prevUserId: string | null;
}

export const showIncidentConfirmationDialog = (
  props: showIncidentConfirmationDialogProps
) => {
  showAddIncidentConfirmationDialog({
    ...props,
    status: '0'
  });
};

interface IncidentDataGridRow {
  id?: GridRowId;
  renderResidentCell: () => JSX.Element;
  residentFirstName: string;
  residentLastName: string;
  residentId: string;
  time: string;
  incidentId: string;
  incidentType: string;
  status: string;
  canSignIncident: boolean;
  prevUserId: string | null;
}

export const removeColumns = () => {
  return `button{ 
    display: none !important; 
  }`;
};

const IncidentsPage = () => {
  const [autoHeight, setAutoHeight] = useState(false);
  const { printView, handlePrintAsPdf, printRef, getPageMargins } = usePrint();
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  const [open, setOpen] = useState(false);
  const currentUser = useCurrentUser().data!;
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom);
  const { data: facility } = useFacilitiesQuery().findOne(selectedFacilityId, {
    enabled: selectedFacilityId !== 'ALL_FACILITIES' && !!selectedFacilityId
  });

  const incidentsQuery = useIncidentsQuery();
  const [dateRange, setDateRange] = React.useState<
    [dateFrom: Date, dateTo: Date]
  >([
    dayjs().subtract(14, 'days').toDate(),
    dayjs(new Date()).add(23, 'hour').toDate() // Accounts for incidents created today
  ]);
  const navigate = useNavigate();

  const formMethods = useForm<SignOpenIncident>({
    mode: 'all',
    defaultValues: {
      pin: ''
    }
  });

  const [allOpenFilteredIncidents, setAllOpenFilteredIncidents] = useState<
    IncidentPayload[]
  >([]);
  const [includeAllOpen, setIncludeAllOpen] = useState(true);

  const dateFrom = dayjs(dateRange[0]).format('YYYY-MM-DD');
  const dateTo = dayjs(dateRange[1]).format('YYYY-MM-DD');

  const {
    data: incidents = [],
    isFetching,
    refetch
  } = incidentsQuery.findAll(
    dateFrom,
    dateTo,
    selectedFacilityId,
    undefined,
    true,
    {
      onSuccess: (data: IncidentPayload[]) => {
        if (includeAllOpen) {
          setAllOpenFilteredIncidents(data);
        } else {
          filterAllOpenIncidents(data);
        }
      }
    }
  );

  const onChangeIncludeAllOpen = (checked: boolean) => {
    if (checked) {
      setAllOpenFilteredIncidents(incidents);
    } else {
      filterAllOpenIncidents();
    }
    setIncludeAllOpen(checked);
  };

  const filterAllOpenIncidents = (incidentsToCheck?: IncidentPayload[]) => {
    const filteredIncidents = (
      incidentsToCheck ? incidentsToCheck : incidents
    ).filter((incident) =>
      dayjs(incident.incident.occurence_date).isBetween(
        dateFrom,
        dateTo,
        'day',
        '[]'
      )
    );
    setAllOpenFilteredIncidents(filteredIncidents);
  };

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (!printView) {
      setAutoHeight(false);
    }
  }, [printView]);

  const rows: Array<IncidentDataGridRow> =
    allOpenFilteredIncidents?.map(({ incident }, i) => {
      return {
        id: i,
        renderResidentCell: () => (
          <IncidentFirstNameCell
            photo={incident.resident?.s3_photo_key}
            firstName={incident.resident?.first_name}
            lastName={incident.resident?.last_name}
          />
        ),
        residentFirstName: incident.resident?.first_name,
        residentLastName: incident.resident?.last_name,
        residentId: incident.resident_id,
        time: incident.occurence_date,
        incidentId: incident.id,
        status: incident.status,
        prevUserId: incident.user_id,
        incidentType: incident.type?.name,
        canSignIncident: currentUser.role === 'L0' && incident.status == '0'
      };
    }) ?? [];

  const columns: (
    | GridColDef<IncidentDataGridRow>
    | GridActionsColDef<IncidentDataGridRow>
  )[] = [
    {
      field: 'residentFirstName',
      headerName: 'First Name',
      flex: !autoHeight ? 1 : undefined,
      width: autoHeight ? 180 : undefined,
      valueGetter: ({ row }) => row.residentFirstName,
      renderCell: ({ row }) => row.renderResidentCell()
    },
    {
      field: 'residentLastName',
      headerName: 'Last Name',
      flex: !autoHeight ? 1 : undefined,
      width: autoHeight ? 180 : undefined,
      valueGetter: ({ row }) => row.residentLastName
    },
    {
      field: 'incidentId',
      headerName: !isMediaQueryDownLg ? 'Incident Report #' : 'ID',
      flex: !autoHeight ? (!isMediaQueryDownLg ? 1 : 0.5) : undefined,
      width: autoHeight ? 180 : undefined,
      valueGetter: ({ row }) => parseInt(row.incidentId)
    },
    {
      field: 'time',
      headerName: !isMediaQueryDownLg ? 'Date and Time' : 'Date',
      flex: !autoHeight ? 1 : undefined,
      width: autoHeight ? 180 : undefined,
      valueGetter: ({ row }) => row.time,
      renderCell: ({ row }) => (
        <Stack>
          <Typography variant="body2" color="#364955" textAlign="center">
            {dayjs(row.time).format('MMM DD, YYYY')}
          </Typography>
          <Typography variant="subtitle2" color="#667A86">
            {dayjs(row.time).format('hh:mm A')}
          </Typography>
        </Stack>
      )
    },
    {
      field: 'incidentType',
      headerName: !isMediaQueryDownLg ? 'Incident Type' : 'Type',
      flex: !autoHeight ? 1 : undefined,
      width: autoHeight ? 180 : undefined,
      valueGetter: ({ row }) => row.incidentType
    },
    {
      field: 'status',
      flex: !autoHeight ? 1 : undefined,
      width: autoHeight ? 180 : undefined,
      minWidth: 100,
      headerName: !isMediaQueryDownLg ? 'Status and Signatures' : 'Status',
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => row.status,
      renderCell: ({ row }) => (
        <StatusChip
          status={row.status === Status.OPEN ? 'Open' : 'Closed'}
          handleClick={() => {}}
        />
      )
    },
    {
      field: 'actions',
      type: 'actions',
      flex: !isMediaQueryDownLg ? 0.5 : 0.1,
      getActions: ({ row }) => [
        ...(row.canSignIncident && currentUser.hasMinimumUserRole('L0.1')
          ? [
              <GridActionsCellItem
                key="complete-and-sign"
                icon={<DriveFileRenameOutlineIcon />}
                label="Complete and Sign"
                onClick={() =>
                  showIncidentConfirmationDialog({
                    formMethods,
                    open,
                    prevUserId: row.prevUserId,
                    handleClose: () => setOpen(false),
                    residentId: parseInt(row.residentId),
                    incidentTypeId: parseInt(row.incidentId),
                    incidentType: row.incidentType,
                    occurenceDate: row.time
                  })
                }
                showInMenu
              />
            ]
          : []),
        <GridActionsCellItem
          key="view"
          icon={<ContactPage />}
          label="View"
          onClick={() => {
            navigate(
              RoutePath.ViewIncident.replace(':incident_id', row.incidentId)
            );
          }}
          showInMenu
        />
      ]
    }
  ];

  return (
    <>
      <Stack
        position="absolute"
        height="100vh"
        width="100%"
        zIndex={9}
        bgcolor="#FFF"
        display={printView ? 'block' : 'none'}>
        <LoadingCircularProgress />
      </Stack>
      <Stack ref={printRef}>
        {printView && (
          <>
            <style>{removeColumns()}</style>
            <style>{getPageMargins()}</style>
            <ResidentPrintHeaderView
              pageTitle="Incidents"
              reportUser={currentUser.getFullName()}
              communityName={
                selectedFacilityId === 'ALL_FACILITIES'
                  ? 'All Communities'
                  : facility?.name ?? ''
              }
              reportRangeDate={`${dayjs(dateFrom).format(
                'MMM D YYYY'
              )} - ${dayjs(dateTo).format('MMM D YYYY')}`}
            />
          </>
        )}
        <DataGridContainer>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeadersInner, & .MuiDataGrid-main, & .MuiDataGrid-autoHeight, & .MuiDataGrid-virtualScroller':
                {
                  overflow: printView ? 'visible !important' : ''
                },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: printView ? 'white !important' : ''
              },
              '& .MuiAvatar-root': {
                display: printView ? 'none' : 'block'
              }
            }}
            onRowClick={({ row }) => {
              navigate(
                RoutePath.ViewIncident.replace(':incident_id', row.incidentId)
              );
            }}
            rows={rows}
            columns={columns}
            loading={isFetching}
            rowHeight={1}
            pagination={!printView}
            autoHeight={autoHeight}
            hideFooter={printView}
            initialState={{
              sorting: { sortModel: [{ field: 'status', sort: 'asc' }] }
            }}
            slots={{
              toolbar: () =>
                !printView ? (
                  <IncidentDataGridToolbar
                    includeAllOpen={includeAllOpen}
                    onChangeIncludeAllOpen={(e, checked) =>
                      onChangeIncludeAllOpen(checked)
                    }
                    handlePrint={() => {
                      setAutoHeight(true);
                      setTimeout(() => {
                        handlePrintAsPdf();
                      }, 0);
                    }}
                    isLoading={isFetching}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                ) : null
            }}
            disableRowSelectionOnClick
          />
        </DataGridContainer>
      </Stack>
    </>
  );
};

export default IncidentsPage;
