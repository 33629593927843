import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export interface PatternedRecurrenceEhr {
  pattern?: RecurrencePatternEhr;
  range: RecurrenceRangeEhr;
}

export interface RecurrencePatternEhr {
  type: RecurrencePatternTypeEhr;
  interval: number;
}

export interface RecurrenceRangeEhr {
  type: RecurrenceRangeTypeEhr;
  startDate: string;
  endDate?: string;
  recurrenceTimeZone?: string;
  startTime?: string;
  endTime?: string;
}

export enum RecurrencePatternTypeEhr {
  Minutely = 'minutely',
  Hourly = 'hourly'
}

export enum RecurrenceRangeTypeEhr {
  EndDate = 'endDate',
  NoEnd = 'noEnd'
}

export class PatternedRecurrenceEhrModel implements PatternedRecurrenceEhr {
  pattern?: RecurrencePatternEhr | undefined;
  range: RecurrenceRangeEhr;

  constructor(payload: PatternedRecurrenceEhr) {
    Object.assign(this, payload);
  }

  generateTimes() {
    const { range, pattern } = this;
    const { startDate, startTime, endTime, recurrenceTimeZone } = range;
    const { type, interval } = pattern!;

    const startDateTime = dayjs
      .tz(startDate, recurrenceTimeZone)
      .hour(dayjs(startTime, 'hh:mm A').hour())
      .minute(dayjs(startTime, 'hh:mm A').minute())
      .second(0)
      .millisecond(0);
    const endDateTime = startDateTime
      .hour(dayjs(endTime, 'hh:mm A').hour())
      .minute(dayjs(endTime, 'hh:mm A').minute());

    const times: string[] = [];
    let currentTime = startDateTime;

    while (currentTime.isBefore(endDateTime)) {
      times.push(currentTime.format('hh:mm A'));
      if (type === 'hourly') {
        currentTime = currentTime.add(interval, 'hour');
      } else if (type === 'minutely') {
        currentTime = currentTime.add(interval, 'minute');
      }
    }

    return times;
  }
}
