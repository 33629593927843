/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentMoveOut
 */
export interface ResidentMoveOut {
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    recorded_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    move_out_reason: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    physical_move_out_date: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    rent_move_out_date: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    care_move_out_date: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMoveOut
     */
    other_recurring_move_out_date: string;
}

/**
 * Check if a given object implements the ResidentMoveOut interface.
 */
export function instanceOfResidentMoveOut(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "move_out_reason" in value;
    isInstance = isInstance && "physical_move_out_date" in value;
    isInstance = isInstance && "rent_move_out_date" in value;
    isInstance = isInstance && "care_move_out_date" in value;
    isInstance = isInstance && "other_recurring_move_out_date" in value;

    return isInstance;
}

export function ResidentMoveOutFromJSON(json: any): ResidentMoveOut {
    return ResidentMoveOutFromJSONTyped(json, false);
}

export function ResidentMoveOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentMoveOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'recorded_by': !exists(json, 'recorded_by') ? undefined : json['recorded_by'],
        'move_out_reason': json['move_out_reason'],
        'physical_move_out_date': json['physical_move_out_date'],
        'rent_move_out_date': json['rent_move_out_date'],
        'care_move_out_date': json['care_move_out_date'],
        'other_recurring_move_out_date': json['other_recurring_move_out_date'],
    };
}

export function ResidentMoveOutToJSON(value?: ResidentMoveOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'recorded_by': value.recorded_by,
        'move_out_reason': value.move_out_reason,
        'physical_move_out_date': value.physical_move_out_date,
        'rent_move_out_date': value.rent_move_out_date,
        'care_move_out_date': value.care_move_out_date,
        'other_recurring_move_out_date': value.other_recurring_move_out_date,
    };
}

