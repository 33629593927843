/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadComment } from './LeadComment';
import {
    LeadCommentFromJSON,
    LeadCommentFromJSONTyped,
    LeadCommentToJSON,
} from './LeadComment';

/**
 * 
 * @export
 * @interface FindAllCommentsForLead200Response
 */
export interface FindAllCommentsForLead200Response {
    /**
     * 
     * @type {Array<LeadComment>}
     * @memberof FindAllCommentsForLead200Response
     */
    data?: Array<LeadComment>;
}

/**
 * Check if a given object implements the FindAllCommentsForLead200Response interface.
 */
export function instanceOfFindAllCommentsForLead200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCommentsForLead200ResponseFromJSON(json: any): FindAllCommentsForLead200Response {
    return FindAllCommentsForLead200ResponseFromJSONTyped(json, false);
}

export function FindAllCommentsForLead200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCommentsForLead200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(LeadCommentFromJSON)),
    };
}

export function FindAllCommentsForLead200ResponseToJSON(value?: FindAllCommentsForLead200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(LeadCommentToJSON)),
    };
}

