import React from 'react';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  afternoonPartOfDayOption,
  EVENING_END_TIME,
  EVENING_START_TIME,
  eveningPartOfDayOption,
  ExactTimeOption,
  exactTimeTypeOption,
  MORNING_END_TIME,
  MORNING_START_TIME,
  morningPartOfDayOption,
  NIGHT_END_TIME,
  NIGHT_START_TIME,
  nightPartOfDayOption,
  PartOfDayOption,
  partOfDayTypeOption,
  periodTypeOption,
  TypeOption
} from '@/components/FrequencyTimeForm/options';
import { OVERDUE_TASKS_COPY } from '@/constants';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  CarePlanTaskInstancePayload,
  MedicationFollowupInstancePayload,
  MedicationTaskInstancePayload,
  TaskInstancePayload
} from '@/hooks/useTaskInstancesQuery';
import { getExecutionWindowTimeValue } from '@/utils/getExecutionWindowTimeValue';
import { capitalizeFirstLetter } from '@/utils/transformDraftToState';

import { CarePlanTaskInstanceModel } from './CarePlanTaskInstanceModel';
import { MedicationPrnFollowupInstanceModel } from './MedicationPrnFollowupInstanceModel';
import { MedicationTaskInstanceModel } from './MedicationTaskInstanceModel';
import { ResidentModel } from './ResidentModel';
import { UserModel } from './UserModel';

export interface EditTaskInstanceFormData {
  comment: string | null;
  type: TypeOption['value'];
  execution_window_end_time: ExactTimeOption['value'] | null;
  execution_window_start_time: ExactTimeOption['value'] | null;
  partOfDay: PartOfDayOption['value'];
  number_of_dose_units: number | null;
  instructions?: string;
}

// This should be type used for components that accept any type of task instance
export type TaskInstanceModel =
  | MedicationTaskInstanceModel
  | CarePlanTaskInstanceModel
  | MedicationPrnFollowupInstanceModel;

// This is the required interface for models CarePlanTaskInstanceModel,
// MedicationTaskInstanceModel, and MedicationPrnFollowupInstnaceModel. Various
// components like @/components/Tasks|TaskModals|TasksDataGrid use the interface
// for common methods that make it easier to work with those components.
export interface BaseTaskInstanceModelInterface {
  hasVitalRequirements: boolean;
  getRowId: () => string;
  renderTaskCell: () => React.ReactNode;
  getTaskName: () => string;
  getAssistanceLevel: () => string;
  getDialogTitle: () => string;
  getDialogTabName: () => string;
  getDialogRedirectUrl: () => string;
  getInstructions: () => string;
  getTotalDosage: () => string | null | React.ReactNode;
  getCellClassName: (field: string) => string;
  renderStatusCell: () => React.ReactNode;
  getFormattedDate: () => string;
  toFormData: () => EditTaskInstanceFormData;
  getTimeValue: () => number;
  renderTimeCell: () => React.ReactNode;
  getStatus: () => string;
  canMarkComplete: (currentUser: UserModel) => boolean;
  canViewDocumentation: (currentUser: UserModel) => boolean;
  canEdit: (currentUser: UserModel) => boolean;
  canCancel: (currentUser: UserModel) => boolean;
  canUncancel: (currentUser: UserModel) => boolean;
  canEditTaskInstances: (currentUser: UserModel) => boolean;
}

export class BaseTaskInstanceModel extends TaskInstancePayload {
  public resident: ResidentModel;

  constructor(
    payload:
      | CarePlanTaskInstancePayload
      | MedicationTaskInstancePayload
      | MedicationFollowupInstancePayload
  ) {
    super();
    Object.assign(this, payload);
  }

  public renderStatusCell = (): React.ReactNode => {
    const isMobileViewport = useIsMobileViewport();
    const renderOutOfCommunityStatusOveride = this.resident.out_of_community
      ? {
          backgroundColor: '#b5c8d7 !important',
          opacity: '1 !important',
          color: '#FCFEFF !important'
        }
      : {};

    if (this.status === 'overdue') {
      return (
        <Chip
          size={isMobileViewport ? 'small' : 'medium'}
          color="warning"
          label={OVERDUE_TASKS_COPY}
          sx={renderOutOfCommunityStatusOveride}
        />
      );
    }

    if (this.status === 'due') {
      return (
        <Chip
          size={isMobileViewport ? 'small' : 'medium'}
          color="primary"
          label="Due"
          sx={renderOutOfCommunityStatusOveride}
        />
      );
    }

    if (this.status === 'upcoming') {
      return (
        <Chip
          size={isMobileViewport ? 'small' : 'medium'}
          color="info"
          label="Upcoming"
          sx={renderOutOfCommunityStatusOveride}
        />
      );
    }

    if (this.status === 'completed') {
      return (
        <Chip
          size={isMobileViewport ? 'small' : 'medium'}
          color="success"
          label="Done"
        />
      );
    }

    if (this.status === 'cancelled') {
      return (
        <Chip
          size={isMobileViewport ? 'small' : 'medium'}
          color="info"
          disabled
          label="Cancelled"
          sx={renderOutOfCommunityStatusOveride}
        />
      );
    }
  };

  public getFormattedDate = (): string => {
    if (this.date) {
      return dayjs(this.date).format('MMMM D, YYYY');
    } else {
      return 'Undefined Date';
    }
  };

  public toFormData = (): EditTaskInstanceFormData => {
    const formData = {
      comment: this.comment ?? ''
    } as Partial<EditTaskInstanceFormData>;

    const startTime = this.execution_window_start_time;
    const endTime = this.execution_window_end_time;

    if (startTime === endTime) {
      formData.type = exactTimeTypeOption.value;
      formData.execution_window_start_time = startTime;
      formData.execution_window_end_time = endTime;
    } else if (
      startTime === MORNING_START_TIME.label &&
      endTime === MORNING_END_TIME.label
    ) {
      formData.type = partOfDayTypeOption.value;
      formData.partOfDay = morningPartOfDayOption.value;
      formData.execution_window_start_time = MORNING_START_TIME.value;
      formData.execution_window_end_time = MORNING_END_TIME.value;
    } else if (
      startTime === AFTERNOON_START_TIME.label &&
      endTime === AFTERNOON_END_TIME.label
    ) {
      formData.type = partOfDayTypeOption.value;
      formData.partOfDay = afternoonPartOfDayOption.value;
      formData.execution_window_start_time = AFTERNOON_START_TIME.value;
      formData.execution_window_end_time = AFTERNOON_END_TIME.value;
    } else if (
      startTime === EVENING_START_TIME.label &&
      endTime == EVENING_END_TIME.label
    ) {
      formData.type = partOfDayTypeOption.value;
      formData.partOfDay = eveningPartOfDayOption.value;
      formData.execution_window_start_time = EVENING_START_TIME.value;
      formData.execution_window_end_time = EVENING_END_TIME.value;
    } else if (
      startTime === NIGHT_START_TIME.label &&
      endTime === NIGHT_END_TIME.label
    ) {
      formData.type = partOfDayTypeOption.value;
      formData.partOfDay = nightPartOfDayOption.value;
      formData.execution_window_start_time = NIGHT_START_TIME.value;
      formData.execution_window_end_time = NIGHT_END_TIME.value;
    } else {
      formData.type = periodTypeOption.value;
      formData.execution_window_start_time = startTime;
      formData.execution_window_end_time = endTime;
    }

    return formData as EditTaskInstanceFormData;
  };

  public getTimeValue = () =>
    getExecutionWindowTimeValue({
      date_completed: this.date_completed,
      execution_window_start_time: this.execution_window_start_time,
      execution_window_end_time: this.execution_window_end_time
    }).value;

  public renderTimeCell = () =>
    getExecutionWindowTimeValue({
      date_completed: this.date_completed,
      execution_window_start_time: this.execution_window_start_time,
      execution_window_end_time: this.execution_window_end_time
    }).display;

  public getStatus = () => {
    if (this.status === 'overdue') {
      return OVERDUE_TASKS_COPY;
    }
    return capitalizeFirstLetter(this.status);
  };

  // These _can* methods are used as the base functionality to determine if the
  // action can be performed on the task instance. They are consumed by the
  // can* methods in the models that extend this BaseTaskInstanceModel. For
  // example, the MedicationTaksInstanceModel invokes the
  // BaseTaskInstanceModel via this._canMarkComplete() and additionally
  // checks if the current user has a minimum L3 role. The
  // CarePlanTaskInstanceModel does not have any additional checks, therefore
  // it assigns the _canMarkComplete() method directly to its canMarkComplete()
  public _canMarkComplete = () =>
    this.status !== 'completed' && this.status !== 'cancelled';

  public _canViewDocumentation = () => this.status === 'completed';

  public _canEdit = () =>
    this.status !== 'completed' && this.status !== 'cancelled';

  public _canCancel = () =>
    this.status !== 'completed' && this.status !== 'cancelled';

  public _canUncancel = () => this.status === 'cancelled';
}
