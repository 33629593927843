/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationCredentials } from './IntegrationCredentials';
import {
    IntegrationCredentialsFromJSON,
    IntegrationCredentialsFromJSONTyped,
    IntegrationCredentialsToJSON,
} from './IntegrationCredentials';

/**
 * 
 * @export
 * @interface CreateIntakeIntegration200Response
 */
export interface CreateIntakeIntegration200Response {
    /**
     * 
     * @type {IntegrationCredentials}
     * @memberof CreateIntakeIntegration200Response
     */
    data?: IntegrationCredentials;
}

/**
 * Check if a given object implements the CreateIntakeIntegration200Response interface.
 */
export function instanceOfCreateIntakeIntegration200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIntakeIntegration200ResponseFromJSON(json: any): CreateIntakeIntegration200Response {
    return CreateIntakeIntegration200ResponseFromJSONTyped(json, false);
}

export function CreateIntakeIntegration200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIntakeIntegration200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IntegrationCredentialsFromJSON(json['data']),
    };
}

export function CreateIntakeIntegration200ResponseToJSON(value?: CreateIntakeIntegration200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IntegrationCredentialsToJSON(value.data),
    };
}

