/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PtOtFunctionScoreRange = {
    _05: '0-5',
    _69: '6-9',
    _1023: '10-23',
    _24: '24'
} as const;
export type PtOtFunctionScoreRange = typeof PtOtFunctionScoreRange[keyof typeof PtOtFunctionScoreRange];


export function PtOtFunctionScoreRangeFromJSON(json: any): PtOtFunctionScoreRange {
    return PtOtFunctionScoreRangeFromJSONTyped(json, false);
}

export function PtOtFunctionScoreRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtFunctionScoreRange {
    return json as PtOtFunctionScoreRange;
}

export function PtOtFunctionScoreRangeToJSON(value?: PtOtFunctionScoreRange | null): any {
    return value as any;
}

