/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingRequiredServices = {
    UrinaryBowelToiletingProgram: 'urinary_bowel_toileting_program',
    PassiveActiveRangeOfMotion: 'passive_active_range_of_motion',
    SplintBraceAssistance: 'splint_brace_assistance',
    BedMobilityWalkingTraining: 'bed_mobility_walking_training',
    TransferTraining: 'transfer_training',
    DressingGroomingTraining: 'dressing_grooming_training',
    EatingSwallowingTraining: 'eating_swallowing_training',
    AmputationProthesisCare: 'amputation_prothesis_care',
    CommunicationTraining: 'communication_training'
} as const;
export type MedicareNursingRequiredServices = typeof MedicareNursingRequiredServices[keyof typeof MedicareNursingRequiredServices];


export function MedicareNursingRequiredServicesFromJSON(json: any): MedicareNursingRequiredServices {
    return MedicareNursingRequiredServicesFromJSONTyped(json, false);
}

export function MedicareNursingRequiredServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingRequiredServices {
    return json as MedicareNursingRequiredServices;
}

export function MedicareNursingRequiredServicesToJSON(value?: MedicareNursingRequiredServices | null): any {
    return value as any;
}

