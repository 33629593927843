/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostIncidentCommentEntry
 */
export interface PostIncidentCommentEntry {
    /**
     * 
     * @type {string}
     * @memberof PostIncidentCommentEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostIncidentCommentEntry
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof PostIncidentCommentEntry
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof PostIncidentCommentEntry
     */
    comment: string;
    /**
     * 
     * @type {Date}
     * @memberof PostIncidentCommentEntry
     */
    created_at: Date;
}

/**
 * Check if a given object implements the PostIncidentCommentEntry interface.
 */
export function instanceOfPostIncidentCommentEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function PostIncidentCommentEntryFromJSON(json: any): PostIncidentCommentEntry {
    return PostIncidentCommentEntryFromJSONTyped(json, false);
}

export function PostIncidentCommentEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostIncidentCommentEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_by': json['created_by'],
        'role': json['role'],
        'comment': json['comment'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function PostIncidentCommentEntryToJSON(value?: PostIncidentCommentEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by': value.created_by,
        'role': value.role,
        'comment': value.comment,
        'created_at': (value.created_at.toISOString()),
    };
}

