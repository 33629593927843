/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskInstanceHistoryListEntry
 */
export interface TaskInstanceHistoryListEntry {
    /**
     * 
     * @type {string}
     * @memberof TaskInstanceHistoryListEntry
     */
    user_photo?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInstanceHistoryListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInstanceHistoryListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInstanceHistoryListEntry
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInstanceHistoryListEntry
     */
    message: string;
}

/**
 * Check if a given object implements the TaskInstanceHistoryListEntry interface.
 */
export function instanceOfTaskInstanceHistoryListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function TaskInstanceHistoryListEntryFromJSON(json: any): TaskInstanceHistoryListEntry {
    return TaskInstanceHistoryListEntryFromJSONTyped(json, false);
}

export function TaskInstanceHistoryListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskInstanceHistoryListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_photo': !exists(json, 'user_photo') ? undefined : json['user_photo'],
        'name': json['name'],
        'title': json['title'],
        'date': json['date'],
        'message': json['message'],
    };
}

export function TaskInstanceHistoryListEntryToJSON(value?: TaskInstanceHistoryListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_photo': value.user_photo,
        'name': value.name,
        'title': value.title,
        'date': value.date,
        'message': value.message,
    };
}

