/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadCommentReaction } from './LeadCommentReaction';
import {
    LeadCommentReactionFromJSON,
    LeadCommentReactionFromJSONTyped,
    LeadCommentReactionToJSON,
} from './LeadCommentReaction';

/**
 * 
 * @export
 * @interface CreateOrDeleteLeadCommentReaction200Response
 */
export interface CreateOrDeleteLeadCommentReaction200Response {
    /**
     * 
     * @type {LeadCommentReaction}
     * @memberof CreateOrDeleteLeadCommentReaction200Response
     */
    data?: LeadCommentReaction;
}

/**
 * Check if a given object implements the CreateOrDeleteLeadCommentReaction200Response interface.
 */
export function instanceOfCreateOrDeleteLeadCommentReaction200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOrDeleteLeadCommentReaction200ResponseFromJSON(json: any): CreateOrDeleteLeadCommentReaction200Response {
    return CreateOrDeleteLeadCommentReaction200ResponseFromJSONTyped(json, false);
}

export function CreateOrDeleteLeadCommentReaction200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrDeleteLeadCommentReaction200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : LeadCommentReactionFromJSON(json['data']),
    };
}

export function CreateOrDeleteLeadCommentReaction200ResponseToJSON(value?: CreateOrDeleteLeadCommentReaction200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': LeadCommentReactionToJSON(value.data),
    };
}

