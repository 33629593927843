import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Stack,
  StackProps,
  Typography
} from '@mui/material';

export const LoadingCircularProgress = ({
  size = '3rem',
  ...props
}: StackProps & { size?: string | number }) => (
  <Stack
    direction="column"
    alignItems="center"
    justifyContent="center"
    padding={size}
    {...props}>
    <CircularProgress size={size} />
  </Stack>
);

export const UploadCircularProgress = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          variant="caption"
          component="div"
          color="secondary"
          {...(props.size && { fontSize: Number(props.size) / 4 })}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
