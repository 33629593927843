/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCareUnitsType } from './AssessmentCareUnitsType';
import {
    AssessmentCareUnitsTypeFromJSON,
    AssessmentCareUnitsTypeFromJSONTyped,
    AssessmentCareUnitsTypeToJSON,
} from './AssessmentCareUnitsType';
import type { AssessmentCompletionDiff } from './AssessmentCompletionDiff';
import {
    AssessmentCompletionDiffFromJSON,
    AssessmentCompletionDiffFromJSONTyped,
    AssessmentCompletionDiffToJSON,
} from './AssessmentCompletionDiff';
import type { AssessmentCompletionState } from './AssessmentCompletionState';
import {
    AssessmentCompletionStateFromJSON,
    AssessmentCompletionStateFromJSONTyped,
    AssessmentCompletionStateToJSON,
} from './AssessmentCompletionState';
import type { CareUpdateStatus } from './CareUpdateStatus';
import {
    CareUpdateStatusFromJSON,
    CareUpdateStatusFromJSONTyped,
    CareUpdateStatusToJSON,
} from './CareUpdateStatus';
import type { Resident } from './Resident';
import {
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './Resident';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface AssessmentCompletion
 */
export interface AssessmentCompletion {
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    resident_assessment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    assessment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    assessment_name?: string;
    /**
     * 
     * @type {AssessmentCareUnitsType}
     * @memberof AssessmentCompletion
     */
    assessment_units_type?: AssessmentCareUnitsType;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletion
     */
    is_care_plan_generator?: boolean;
    /**
     * 
     * @type {Resident}
     * @memberof AssessmentCompletion
     */
    resident?: Resident;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    scheduled_date?: string;
    /**
     * 
     * @type {AssessmentCompletionState}
     * @memberof AssessmentCompletion
     */
    state?: AssessmentCompletionState;
    /**
     * 
     * @type {UserName}
     * @memberof AssessmentCompletion
     */
    completed_by?: UserName;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    date_completed?: string;
    /**
     * 
     * @type {UserName}
     * @memberof AssessmentCompletion
     */
    approved_by?: UserName;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    date_approved?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    date_created?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletion
     */
    care_points_sum_total?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletion
     */
    care_minutes_per_month_total?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletion
     */
    care_packages_sum_total?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletion
     */
    date_modified?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletion
     */
    is_notified?: boolean;
    /**
     * 
     * @type {CareUpdateStatus}
     * @memberof AssessmentCompletion
     */
    care_plan_update_status?: CareUpdateStatus;
    /**
     * 
     * @type {CareUpdateStatus}
     * @memberof AssessmentCompletion
     */
    care_subscription_update_status?: CareUpdateStatus;
    /**
     * 
     * @type {AssessmentCompletionDiff}
     * @memberof AssessmentCompletion
     */
    previous_completion_diff?: AssessmentCompletionDiff;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletion
     */
    is_esign_created?: boolean;
}

/**
 * Check if a given object implements the AssessmentCompletion interface.
 */
export function instanceOfAssessmentCompletion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function AssessmentCompletionFromJSON(json: any): AssessmentCompletion {
    return AssessmentCompletionFromJSONTyped(json, false);
}

export function AssessmentCompletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_assessment_id': !exists(json, 'resident_assessment_id') ? undefined : json['resident_assessment_id'],
        'assessment_id': !exists(json, 'assessment_id') ? undefined : json['assessment_id'],
        'assessment_name': !exists(json, 'assessment_name') ? undefined : json['assessment_name'],
        'assessment_units_type': !exists(json, 'assessment_units_type') ? undefined : AssessmentCareUnitsTypeFromJSON(json['assessment_units_type']),
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
        'resident': !exists(json, 'resident') ? undefined : ResidentFromJSON(json['resident']),
        'scheduled_date': !exists(json, 'scheduled_date') ? undefined : json['scheduled_date'],
        'state': !exists(json, 'state') ? undefined : AssessmentCompletionStateFromJSON(json['state']),
        'completed_by': !exists(json, 'completed_by') ? undefined : UserNameFromJSON(json['completed_by']),
        'date_completed': !exists(json, 'date_completed') ? undefined : json['date_completed'],
        'approved_by': !exists(json, 'approved_by') ? undefined : UserNameFromJSON(json['approved_by']),
        'date_approved': !exists(json, 'date_approved') ? undefined : json['date_approved'],
        'date_created': !exists(json, 'date_created') ? undefined : json['date_created'],
        'care_points_sum_total': !exists(json, 'care_points_sum_total') ? undefined : json['care_points_sum_total'],
        'care_minutes_per_month_total': !exists(json, 'care_minutes_per_month_total') ? undefined : json['care_minutes_per_month_total'],
        'care_packages_sum_total': !exists(json, 'care_packages_sum_total') ? undefined : json['care_packages_sum_total'],
        'date_modified': !exists(json, 'date_modified') ? undefined : json['date_modified'],
        'is_notified': !exists(json, 'is_notified') ? undefined : json['is_notified'],
        'care_plan_update_status': !exists(json, 'care_plan_update_status') ? undefined : CareUpdateStatusFromJSON(json['care_plan_update_status']),
        'care_subscription_update_status': !exists(json, 'care_subscription_update_status') ? undefined : CareUpdateStatusFromJSON(json['care_subscription_update_status']),
        'previous_completion_diff': !exists(json, 'previous_completion_diff') ? undefined : AssessmentCompletionDiffFromJSON(json['previous_completion_diff']),
        'is_esign_created': !exists(json, 'is_esign_created') ? undefined : json['is_esign_created'],
    };
}

export function AssessmentCompletionToJSON(value?: AssessmentCompletion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_assessment_id': value.resident_assessment_id,
        'assessment_id': value.assessment_id,
        'assessment_name': value.assessment_name,
        'assessment_units_type': AssessmentCareUnitsTypeToJSON(value.assessment_units_type),
        'is_care_plan_generator': value.is_care_plan_generator,
        'resident': ResidentToJSON(value.resident),
        'scheduled_date': value.scheduled_date,
        'state': AssessmentCompletionStateToJSON(value.state),
        'completed_by': UserNameToJSON(value.completed_by),
        'date_completed': value.date_completed,
        'approved_by': UserNameToJSON(value.approved_by),
        'date_approved': value.date_approved,
        'date_created': value.date_created,
        'care_points_sum_total': value.care_points_sum_total,
        'care_minutes_per_month_total': value.care_minutes_per_month_total,
        'care_packages_sum_total': value.care_packages_sum_total,
        'date_modified': value.date_modified,
        'is_notified': value.is_notified,
        'care_plan_update_status': CareUpdateStatusToJSON(value.care_plan_update_status),
        'care_subscription_update_status': CareUpdateStatusToJSON(value.care_subscription_update_status),
        'previous_completion_diff': AssessmentCompletionDiffToJSON(value.previous_completion_diff),
        'is_esign_created': value.is_esign_created,
    };
}

