/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMedicationStockSignOff201Response,
  CreateMedicationStockSignOffRequest,
  FindAllInstanceHistory200Response,
  FindAllMedicationStockSignOffs200Response,
  FindRecentPrnActivity200Response,
  UpdateCompletionTimeRequest,
} from '../models/index';
import {
    CreateMedicationStockSignOff201ResponseFromJSON,
    CreateMedicationStockSignOff201ResponseToJSON,
    CreateMedicationStockSignOffRequestFromJSON,
    CreateMedicationStockSignOffRequestToJSON,
    FindAllInstanceHistory200ResponseFromJSON,
    FindAllInstanceHistory200ResponseToJSON,
    FindAllMedicationStockSignOffs200ResponseFromJSON,
    FindAllMedicationStockSignOffs200ResponseToJSON,
    FindRecentPrnActivity200ResponseFromJSON,
    FindRecentPrnActivity200ResponseToJSON,
    UpdateCompletionTimeRequestFromJSON,
    UpdateCompletionTimeRequestToJSON,
} from '../models/index';

export interface CreateMedicationStockSignOffOperationRequest {
    createMedicationStockSignOffRequest: CreateMedicationStockSignOffRequest;
}

export interface DeleteMedicationStockSignOffRequest {
    id: string;
}

export interface DeleteMedicationTaskInstanceRequest {
    id: string;
}

export interface FindAllInstanceHistoryRequest {
    id: string;
}

export interface FindAllMedicationStockSignOffsRequest {
    facilityId: string;
    search?: string;
    type?: string;
    page?: string;
    limit?: string;
}

export interface FindRecentPrnActivityRequest {
    id: string;
}

export interface MarkInstanceIncompleteRequest {
    id: string;
}

export interface UpdateInstanceCompletionTimeRequest {
    id: string;
    updateCompletionTimeRequest: UpdateCompletionTimeRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create a new medication stock sign-off
     */
    async createMedicationStockSignOffRaw(requestParameters: CreateMedicationStockSignOffOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateMedicationStockSignOff201Response>> {
        if (requestParameters.createMedicationStockSignOffRequest === null || requestParameters.createMedicationStockSignOffRequest === undefined) {
            throw new runtime.RequiredError('createMedicationStockSignOffRequest','Required parameter requestParameters.createMedicationStockSignOffRequest was null or undefined when calling createMedicationStockSignOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medications/sign-offs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMedicationStockSignOffRequestToJSON(requestParameters.createMedicationStockSignOffRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMedicationStockSignOff201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new medication stock sign-off
     */
    async createMedicationStockSignOff(requestParameters: CreateMedicationStockSignOffOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateMedicationStockSignOff201Response> {
        const response = await this.createMedicationStockSignOffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a medication stock sign-off
     */
    async deleteMedicationStockSignOffRaw(requestParameters: DeleteMedicationStockSignOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMedicationStockSignOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medications/sign-offs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a medication stock sign-off
     */
    async deleteMedicationStockSignOff(requestParameters: DeleteMedicationStockSignOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMedicationStockSignOffRaw(requestParameters, initOverrides);
    }

    /**
     * Delete medication task instance
     */
    async deleteMedicationTaskInstanceRaw(requestParameters: DeleteMedicationTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMedicationTaskInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medication-task-instances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete medication task instance
     */
    async deleteMedicationTaskInstance(requestParameters: DeleteMedicationTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMedicationTaskInstanceRaw(requestParameters, initOverrides);
    }

    /**
     * Get Medication Task Instance History
     */
    async findAllInstanceHistoryRaw(requestParameters: FindAllInstanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllInstanceHistory200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllInstanceHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medication-task-instances/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllInstanceHistory200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medication Task Instance History
     */
    async findAllInstanceHistory(requestParameters: FindAllInstanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllInstanceHistory200Response> {
        const response = await this.findAllInstanceHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all medication stock sign-offs
     */
    async findAllMedicationStockSignOffsRaw(requestParameters: FindAllMedicationStockSignOffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllMedicationStockSignOffs200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllMedicationStockSignOffs.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medications/sign-offs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllMedicationStockSignOffs200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all medication stock sign-offs
     */
    async findAllMedicationStockSignOffs(requestParameters: FindAllMedicationStockSignOffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllMedicationStockSignOffs200Response> {
        const response = await this.findAllMedicationStockSignOffsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get PRN Activity for last 24 hours
     */
    async findRecentPrnActivityRaw(requestParameters: FindRecentPrnActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindRecentPrnActivity200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findRecentPrnActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medication-tasks/{id}/activity`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindRecentPrnActivity200ResponseFromJSON(jsonValue));
    }

    /**
     * Get PRN Activity for last 24 hours
     */
    async findRecentPrnActivity(requestParameters: FindRecentPrnActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindRecentPrnActivity200Response> {
        const response = await this.findRecentPrnActivityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark Medication Task Instance Incomplete
     */
    async markInstanceIncompleteRaw(requestParameters: MarkInstanceIncompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markInstanceIncomplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medication-task-instances/{id}/mark-incomplete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Medication Task Instance Incomplete
     */
    async markInstanceIncomplete(requestParameters: MarkInstanceIncompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInstanceIncompleteRaw(requestParameters, initOverrides);
    }

    /**
     * Update Med Task Instance Completion Time
     */
    async updateInstanceCompletionTimeRaw(requestParameters: UpdateInstanceCompletionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInstanceCompletionTime.');
        }

        if (requestParameters.updateCompletionTimeRequest === null || requestParameters.updateCompletionTimeRequest === undefined) {
            throw new runtime.RequiredError('updateCompletionTimeRequest','Required parameter requestParameters.updateCompletionTimeRequest was null or undefined when calling updateInstanceCompletionTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medication-task-instances/{id}/completion-time`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompletionTimeRequestToJSON(requestParameters.updateCompletionTimeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Med Task Instance Completion Time
     */
    async updateInstanceCompletionTime(requestParameters: UpdateInstanceCompletionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInstanceCompletionTimeRaw(requestParameters, initOverrides);
    }

}
