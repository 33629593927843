/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolFollowup } from './IncidentProtocolFollowup';
import {
    IncidentProtocolFollowupFromJSON,
    IncidentProtocolFollowupFromJSONTyped,
    IncidentProtocolFollowupToJSON,
} from './IncidentProtocolFollowup';
import type { IncidentProtocolParty } from './IncidentProtocolParty';
import {
    IncidentProtocolPartyFromJSON,
    IncidentProtocolPartyFromJSONTyped,
    IncidentProtocolPartyToJSON,
} from './IncidentProtocolParty';

/**
 * 
 * @export
 * @interface IncidentProtocolFormUpdateRequest
 */
export interface IncidentProtocolFormUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    deadline?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    s3_key?: string;
    /**
     * 
     * @type {Array<IncidentProtocolParty>}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    responsible_parties?: Array<IncidentProtocolParty>;
    /**
     * 
     * @type {Array<IncidentProtocolFollowup>}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    followups?: Array<IncidentProtocolFollowup>;
    /**
     * 
     * @type {any}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFormUpdateRequest
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the IncidentProtocolFormUpdateRequest interface.
 */
export function instanceOfIncidentProtocolFormUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IncidentProtocolFormUpdateRequestFromJSON(json: any): IncidentProtocolFormUpdateRequest {
    return IncidentProtocolFormUpdateRequestFromJSONTyped(json, false);
}

export function IncidentProtocolFormUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolFormUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        's3_key': !exists(json, 's3_key') ? undefined : json['s3_key'],
        'responsible_parties': !exists(json, 'responsible_parties') ? undefined : ((json['responsible_parties'] as Array<any>).map(IncidentProtocolPartyFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentProtocolFollowupFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function IncidentProtocolFormUpdateRequestToJSON(value?: IncidentProtocolFormUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'deadline': value.deadline,
        's3_key': value.s3_key,
        'responsible_parties': value.responsible_parties === undefined ? undefined : ((value.responsible_parties as Array<any>).map(IncidentProtocolPartyToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentProtocolFollowupToJSON)),
        'complete_followups': value.complete_followups,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

