/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllRecurringPurchasesSummary200Response,
  BulkCreateOneTimePurchases201Response,
  BulkCreateOneTimePurchasesRequest,
  BulkUpdateRoomTypesRequestItem,
  CreateCredit201Response,
  CreateDeposit201Response,
  CreateDiscountCategory201Response,
  CreateDiscountRequest,
  CreateDiscounts201Response,
  CreateOneTimeCharge201Response,
  CreateOneTimeChargeCategory201Response,
  CreateRoomRequest,
  CreateTax201Response,
  CreateTaxCategory201Response,
  CreateTaxRequest,
  Credit,
  Deposit,
  DepositStatus,
  Discount,
  DiscountCategory,
  FacilityBillReceiptSettings,
  FacilityBillingInfo,
  FindAllCredits200Response,
  FindAllDeposits200Response,
  FindAllDiscountCategories200Response,
  FindAllDiscounts200Response,
  FindAllOneTimeChargeCategories200Response,
  FindAllOneTimeCharges200Response,
  FindAllOneTimeChargesWithoutPrice200Response,
  FindAllRecurringPurchasesCare200Response,
  FindAllRecurringPurchasesOther200Response,
  FindAllRecurringPurchasesRent200Response,
  FindAllResidentBillingInfos200Response,
  FindAllRoomTypes200Response,
  FindAllRooms200Response,
  FindAllTaxCategories200Response,
  FindAllTaxes200Response,
  FindOneFacilityBillReceiptSettings200Response,
  FindOneFacilityBillingInfo200Response,
  FindOneRecurringPurchasesSummary200Response,
  FindOneResidentBillingInfo200Response,
  GetDepositPdf200Response,
  OneTimeCharge,
  OneTimeChargeCategory,
  RecordOneTimePurchase201Response,
  RecordOneTimePurchaseRequest,
  RecordRecurringPurchaseRequest,
  SendDepositEmail200Response,
  SendDepositEmailRequest,
  Tax,
  TaxCategory,
  UpdateOneTimeChargeRequest,
  UpdateRecurringPurchaseRequest,
  UpdateResidentBillingInfoRequest,
  UpdateRoom200Response,
  UpdateRoomRequest,
} from '../models/index';
import {
    AllRecurringPurchasesSummary200ResponseFromJSON,
    AllRecurringPurchasesSummary200ResponseToJSON,
    BulkCreateOneTimePurchases201ResponseFromJSON,
    BulkCreateOneTimePurchases201ResponseToJSON,
    BulkCreateOneTimePurchasesRequestFromJSON,
    BulkCreateOneTimePurchasesRequestToJSON,
    BulkUpdateRoomTypesRequestItemFromJSON,
    BulkUpdateRoomTypesRequestItemToJSON,
    CreateCredit201ResponseFromJSON,
    CreateCredit201ResponseToJSON,
    CreateDeposit201ResponseFromJSON,
    CreateDeposit201ResponseToJSON,
    CreateDiscountCategory201ResponseFromJSON,
    CreateDiscountCategory201ResponseToJSON,
    CreateDiscountRequestFromJSON,
    CreateDiscountRequestToJSON,
    CreateDiscounts201ResponseFromJSON,
    CreateDiscounts201ResponseToJSON,
    CreateOneTimeCharge201ResponseFromJSON,
    CreateOneTimeCharge201ResponseToJSON,
    CreateOneTimeChargeCategory201ResponseFromJSON,
    CreateOneTimeChargeCategory201ResponseToJSON,
    CreateRoomRequestFromJSON,
    CreateRoomRequestToJSON,
    CreateTax201ResponseFromJSON,
    CreateTax201ResponseToJSON,
    CreateTaxCategory201ResponseFromJSON,
    CreateTaxCategory201ResponseToJSON,
    CreateTaxRequestFromJSON,
    CreateTaxRequestToJSON,
    CreditFromJSON,
    CreditToJSON,
    DepositFromJSON,
    DepositToJSON,
    DepositStatusFromJSON,
    DepositStatusToJSON,
    DiscountFromJSON,
    DiscountToJSON,
    DiscountCategoryFromJSON,
    DiscountCategoryToJSON,
    FacilityBillReceiptSettingsFromJSON,
    FacilityBillReceiptSettingsToJSON,
    FacilityBillingInfoFromJSON,
    FacilityBillingInfoToJSON,
    FindAllCredits200ResponseFromJSON,
    FindAllCredits200ResponseToJSON,
    FindAllDeposits200ResponseFromJSON,
    FindAllDeposits200ResponseToJSON,
    FindAllDiscountCategories200ResponseFromJSON,
    FindAllDiscountCategories200ResponseToJSON,
    FindAllDiscounts200ResponseFromJSON,
    FindAllDiscounts200ResponseToJSON,
    FindAllOneTimeChargeCategories200ResponseFromJSON,
    FindAllOneTimeChargeCategories200ResponseToJSON,
    FindAllOneTimeCharges200ResponseFromJSON,
    FindAllOneTimeCharges200ResponseToJSON,
    FindAllOneTimeChargesWithoutPrice200ResponseFromJSON,
    FindAllOneTimeChargesWithoutPrice200ResponseToJSON,
    FindAllRecurringPurchasesCare200ResponseFromJSON,
    FindAllRecurringPurchasesCare200ResponseToJSON,
    FindAllRecurringPurchasesOther200ResponseFromJSON,
    FindAllRecurringPurchasesOther200ResponseToJSON,
    FindAllRecurringPurchasesRent200ResponseFromJSON,
    FindAllRecurringPurchasesRent200ResponseToJSON,
    FindAllResidentBillingInfos200ResponseFromJSON,
    FindAllResidentBillingInfos200ResponseToJSON,
    FindAllRoomTypes200ResponseFromJSON,
    FindAllRoomTypes200ResponseToJSON,
    FindAllRooms200ResponseFromJSON,
    FindAllRooms200ResponseToJSON,
    FindAllTaxCategories200ResponseFromJSON,
    FindAllTaxCategories200ResponseToJSON,
    FindAllTaxes200ResponseFromJSON,
    FindAllTaxes200ResponseToJSON,
    FindOneFacilityBillReceiptSettings200ResponseFromJSON,
    FindOneFacilityBillReceiptSettings200ResponseToJSON,
    FindOneFacilityBillingInfo200ResponseFromJSON,
    FindOneFacilityBillingInfo200ResponseToJSON,
    FindOneRecurringPurchasesSummary200ResponseFromJSON,
    FindOneRecurringPurchasesSummary200ResponseToJSON,
    FindOneResidentBillingInfo200ResponseFromJSON,
    FindOneResidentBillingInfo200ResponseToJSON,
    GetDepositPdf200ResponseFromJSON,
    GetDepositPdf200ResponseToJSON,
    OneTimeChargeFromJSON,
    OneTimeChargeToJSON,
    OneTimeChargeCategoryFromJSON,
    OneTimeChargeCategoryToJSON,
    RecordOneTimePurchase201ResponseFromJSON,
    RecordOneTimePurchase201ResponseToJSON,
    RecordOneTimePurchaseRequestFromJSON,
    RecordOneTimePurchaseRequestToJSON,
    RecordRecurringPurchaseRequestFromJSON,
    RecordRecurringPurchaseRequestToJSON,
    SendDepositEmail200ResponseFromJSON,
    SendDepositEmail200ResponseToJSON,
    SendDepositEmailRequestFromJSON,
    SendDepositEmailRequestToJSON,
    TaxFromJSON,
    TaxToJSON,
    TaxCategoryFromJSON,
    TaxCategoryToJSON,
    UpdateOneTimeChargeRequestFromJSON,
    UpdateOneTimeChargeRequestToJSON,
    UpdateRecurringPurchaseRequestFromJSON,
    UpdateRecurringPurchaseRequestToJSON,
    UpdateResidentBillingInfoRequestFromJSON,
    UpdateResidentBillingInfoRequestToJSON,
    UpdateRoom200ResponseFromJSON,
    UpdateRoom200ResponseToJSON,
    UpdateRoomRequestFromJSON,
    UpdateRoomRequestToJSON,
} from '../models/index';

export interface AllRecurringPurchasesSummaryRequest {
    facilityId: string;
}

export interface BulkCreateOneTimePurchasesOperationRequest {
    bulkCreateOneTimePurchasesRequest: BulkCreateOneTimePurchasesRequest;
}

export interface BulkUpdateRoomTypesRequest {
    bulkUpdateRoomTypesRequestItem: Array<BulkUpdateRoomTypesRequestItem>;
}

export interface CancelDepositRequest {
    depositId: string;
}

export interface CancelRecurringPurchaseRequest {
    subscriptionId: string;
}

export interface CancelRoomRentPurchaseRequest {
    subscriptionId: string;
}

export interface CreateCreditRequest {
    credit: Credit;
}

export interface CreateDepositRequest {
    deposit: Deposit;
}

export interface CreateDiscountCategoryRequest {
    discountCategory: DiscountCategory;
}

export interface CreateDiscountsRequest {
    createDiscountRequest: CreateDiscountRequest;
}

export interface CreateOneTimeChargeRequest {
    oneTimeCharge: OneTimeCharge;
}

export interface CreateOneTimeChargeCategoryRequest {
    oneTimeChargeCategory: OneTimeChargeCategory;
}

export interface CreateRoomOperationRequest {
    createRoomRequest: CreateRoomRequest;
}

export interface CreateTaxOperationRequest {
    createTaxRequest: CreateTaxRequest;
}

export interface CreateTaxCategoryRequest {
    taxCategory: TaxCategory;
}

export interface DeleteRoomTypeRequest {
    roomTypeId: string;
}

export interface FindAllCreditsRequest {
    residentId?: string;
    applied?: boolean;
}

export interface FindAllDepositsRequest {
    facilityId?: string;
    residentId?: string;
    applied?: boolean;
    isRefundable?: boolean;
    status?: DepositStatus;
    fromDate?: string;
    toDate?: string;
    isCrmResident?: boolean;
}

export interface FindAllDiscountCategoriesRequest {
    facilityId?: string;
}

export interface FindAllDiscountsRequest {
    categoryId?: string;
    includeUpdatedBy?: boolean;
}

export interface FindAllOneTimeChargeCategoriesRequest {
    facilityId?: string;
}

export interface FindAllOneTimeChargesRequest {
    categoryId?: string;
}

export interface FindAllOneTimeChargesWithoutPriceRequest {
    categoryId?: string;
}

export interface FindAllRecurringPurchasesCareRequest {
    facilityId: string;
    residentId?: string;
}

export interface FindAllRecurringPurchasesOtherRequest {
    facilityId: string;
}

export interface FindAllRecurringPurchasesRentRequest {
    facilityId: string;
}

export interface FindAllResidentBillingInfosRequest {
    facilityId?: string;
    residentId?: string;
    searchQuery?: string;
    page?: number;
    limit?: number;
}

export interface FindAllRoomTypesRequest {
    facilityId: string;
}

export interface FindAllRoomsRequest {
    facilityId: string;
}

export interface FindAllTaxCategoriesRequest {
    facilityId?: string;
}

export interface FindAllTaxesRequest {
    categoryId?: string;
    includeUpdatedBy?: boolean;
}

export interface FindDiscountByIdRequest {
    id: string;
}

export interface FindOneFacilityBillReceiptSettingsRequest {
    facilityId: string;
}

export interface FindOneFacilityBillingInfoRequest {
    facilityId: string;
}

export interface FindOneRecurringPurchasesSummaryRequest {
    residentId: string;
}

export interface FindOneResidentBillingInfoRequest {
    residentId: string;
}

export interface FindTaxByIdRequest {
    id: string;
}

export interface GetDepositPdfRequest {
    depositId: string;
}

export interface RecordOneTimePurchaseOperationRequest {
    recordOneTimePurchaseRequest: RecordOneTimePurchaseRequest;
}

export interface RecordRecurringPurchaseOperationRequest {
    recordRecurringPurchaseRequest: RecordRecurringPurchaseRequest;
}

export interface RecordRoomRentPurchaseRequest {
    recordRecurringPurchaseRequest: RecordRecurringPurchaseRequest;
}

export interface SendDepositEmailOperationRequest {
    depositId: string;
    sendDepositEmailRequest: SendDepositEmailRequest;
}

export interface UpdateDepositByIdRequest {
    depositId: string;
    deposit: Deposit;
}

export interface UpdateDiscountByIdRequest {
    id: string;
    discount: Discount;
}

export interface UpdateDiscountCategoryRequest {
    id: string;
    discountCategory: DiscountCategory;
}

export interface UpdateFacilityBillReceiptSettingsRequest {
    facilityId: string;
    facilityBillReceiptSettings: FacilityBillReceiptSettings;
}

export interface UpdateFacilityBillingInfoRequest {
    facilityId: string;
    facilityBillingInfo: FacilityBillingInfo;
}

export interface UpdateOneTimeChargeOperationRequest {
    chargeId: string;
    updateOneTimeChargeRequest: UpdateOneTimeChargeRequest;
}

export interface UpdateOneTimeChargeCategoryRequest {
    id: string;
    oneTimeChargeCategory: OneTimeChargeCategory;
}

export interface UpdateRecurringPurchaseOperationRequest {
    subscriptionId: string;
    updateRecurringPurchaseRequest: UpdateRecurringPurchaseRequest;
}

export interface UpdateResidentBillingInfoOperationRequest {
    residentId: string;
    updateResidentBillingInfoRequest: UpdateResidentBillingInfoRequest;
}

export interface UpdateRoomOperationRequest {
    roomId: string;
    updateRoomRequest: UpdateRoomRequest;
}

export interface UpdateRoomRentPurchaseRequest {
    subscriptionId: string;
    updateRecurringPurchaseRequest: UpdateRecurringPurchaseRequest;
}

export interface UpdateTaxByIdRequest {
    id: string;
    tax: Tax;
}

export interface UpdateTaxCategoryRequest {
    id: string;
    taxCategory: TaxCategory;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get all recurring purchases by resident
     */
    async allRecurringPurchasesSummaryRaw(requestParameters: AllRecurringPurchasesSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllRecurringPurchasesSummary200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling allRecurringPurchasesSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/recurring/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllRecurringPurchasesSummary200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all recurring purchases by resident
     */
    async allRecurringPurchasesSummary(requestParameters: AllRecurringPurchasesSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllRecurringPurchasesSummary200Response> {
        const response = await this.allRecurringPurchasesSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bulk create one-time purchases
     */
    async bulkCreateOneTimePurchasesRaw(requestParameters: BulkCreateOneTimePurchasesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkCreateOneTimePurchases201Response>> {
        if (requestParameters.bulkCreateOneTimePurchasesRequest === null || requestParameters.bulkCreateOneTimePurchasesRequest === undefined) {
            throw new runtime.RequiredError('bulkCreateOneTimePurchasesRequest','Required parameter requestParameters.bulkCreateOneTimePurchasesRequest was null or undefined when calling bulkCreateOneTimePurchases.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/one-time/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCreateOneTimePurchasesRequestToJSON(requestParameters.bulkCreateOneTimePurchasesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkCreateOneTimePurchases201ResponseFromJSON(jsonValue));
    }

    /**
     * Bulk create one-time purchases
     */
    async bulkCreateOneTimePurchases(requestParameters: BulkCreateOneTimePurchasesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkCreateOneTimePurchases201Response> {
        const response = await this.bulkCreateOneTimePurchasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Room Types in bulk
     */
    async bulkUpdateRoomTypesRaw(requestParameters: BulkUpdateRoomTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRoomTypes200Response>> {
        if (requestParameters.bulkUpdateRoomTypesRequestItem === null || requestParameters.bulkUpdateRoomTypesRequestItem === undefined) {
            throw new runtime.RequiredError('bulkUpdateRoomTypesRequestItem','Required parameter requestParameters.bulkUpdateRoomTypesRequestItem was null or undefined when calling bulkUpdateRoomTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/room-types/bulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.bulkUpdateRoomTypesRequestItem.map(BulkUpdateRoomTypesRequestItemToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRoomTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Update Room Types in bulk
     */
    async bulkUpdateRoomTypes(requestParameters: BulkUpdateRoomTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRoomTypes200Response> {
        const response = await this.bulkUpdateRoomTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a deposit
     */
    async cancelDepositRaw(requestParameters: CancelDepositRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeposit201Response>> {
        if (requestParameters.depositId === null || requestParameters.depositId === undefined) {
            throw new runtime.RequiredError('depositId','Required parameter requestParameters.depositId was null or undefined when calling cancelDeposit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deposits/{deposit_id}/cancel`.replace(`{${"deposit_id"}}`, encodeURIComponent(String(requestParameters.depositId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeposit201ResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a deposit
     */
    async cancelDeposit(requestParameters: CancelDepositRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeposit201Response> {
        const response = await this.cancelDepositRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a recurring purchase (subscription)
     */
    async cancelRecurringPurchaseRaw(requestParameters: CancelRecurringPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling cancelRecurringPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/recurring/{subscription_id}/cancel`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a recurring purchase (subscription)
     */
    async cancelRecurringPurchase(requestParameters: CancelRecurringPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.cancelRecurringPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a recurring room rent purchase (subscription)
     */
    async cancelRoomRentPurchaseRaw(requestParameters: CancelRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling cancelRoomRentPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/room-rent/{subscription_id}/cancel`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a recurring room rent purchase (subscription)
     */
    async cancelRoomRentPurchase(requestParameters: CancelRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.cancelRoomRentPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a credit
     */
    async createCreditRaw(requestParameters: CreateCreditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCredit201Response>> {
        if (requestParameters.credit === null || requestParameters.credit === undefined) {
            throw new runtime.RequiredError('credit','Required parameter requestParameters.credit was null or undefined when calling createCredit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/credits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreditToJSON(requestParameters.credit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCredit201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a credit
     */
    async createCredit(requestParameters: CreateCreditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCredit201Response> {
        const response = await this.createCreditRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a deposit
     */
    async createDepositRaw(requestParameters: CreateDepositRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeposit201Response>> {
        if (requestParameters.deposit === null || requestParameters.deposit === undefined) {
            throw new runtime.RequiredError('deposit','Required parameter requestParameters.deposit was null or undefined when calling createDeposit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deposits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepositToJSON(requestParameters.deposit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeposit201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a deposit
     */
    async createDeposit(requestParameters: CreateDepositRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeposit201Response> {
        const response = await this.createDepositRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a discount category
     */
    async createDiscountCategoryRaw(requestParameters: CreateDiscountCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDiscountCategory201Response>> {
        if (requestParameters.discountCategory === null || requestParameters.discountCategory === undefined) {
            throw new runtime.RequiredError('discountCategory','Required parameter requestParameters.discountCategory was null or undefined when calling createDiscountCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discount-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountCategoryToJSON(requestParameters.discountCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDiscountCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a discount category
     */
    async createDiscountCategory(requestParameters: CreateDiscountCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDiscountCategory201Response> {
        const response = await this.createDiscountCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a discounts
     */
    async createDiscountsRaw(requestParameters: CreateDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDiscounts201Response>> {
        if (requestParameters.createDiscountRequest === null || requestParameters.createDiscountRequest === undefined) {
            throw new runtime.RequiredError('createDiscountRequest','Required parameter requestParameters.createDiscountRequest was null or undefined when calling createDiscounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDiscountRequestToJSON(requestParameters.createDiscountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDiscounts201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a discounts
     */
    async createDiscounts(requestParameters: CreateDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDiscounts201Response> {
        const response = await this.createDiscountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Care a one-time charge
     */
    async createOneTimeChargeRaw(requestParameters: CreateOneTimeChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeCharge201Response>> {
        if (requestParameters.oneTimeCharge === null || requestParameters.oneTimeCharge === undefined) {
            throw new runtime.RequiredError('oneTimeCharge','Required parameter requestParameters.oneTimeCharge was null or undefined when calling createOneTimeCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-charges`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeChargeToJSON(requestParameters.oneTimeCharge),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeCharge201ResponseFromJSON(jsonValue));
    }

    /**
     * Care a one-time charge
     */
    async createOneTimeCharge(requestParameters: CreateOneTimeChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeCharge201Response> {
        const response = await this.createOneTimeChargeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a one time charge category
     */
    async createOneTimeChargeCategoryRaw(requestParameters: CreateOneTimeChargeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeChargeCategory201Response>> {
        if (requestParameters.oneTimeChargeCategory === null || requestParameters.oneTimeChargeCategory === undefined) {
            throw new runtime.RequiredError('oneTimeChargeCategory','Required parameter requestParameters.oneTimeChargeCategory was null or undefined when calling createOneTimeChargeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-charge-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeChargeCategoryToJSON(requestParameters.oneTimeChargeCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeChargeCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a one time charge category
     */
    async createOneTimeChargeCategory(requestParameters: CreateOneTimeChargeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeChargeCategory201Response> {
        const response = await this.createOneTimeChargeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create room for a facility
     */
    async createRoomRaw(requestParameters: CreateRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateRoom200Response>> {
        if (requestParameters.createRoomRequest === null || requestParameters.createRoomRequest === undefined) {
            throw new runtime.RequiredError('createRoomRequest','Required parameter requestParameters.createRoomRequest was null or undefined when calling createRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/room-rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoomRequestToJSON(requestParameters.createRoomRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateRoom200ResponseFromJSON(jsonValue));
    }

    /**
     * Create room for a facility
     */
    async createRoom(requestParameters: CreateRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateRoom200Response> {
        const response = await this.createRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a tax
     */
    async createTaxRaw(requestParameters: CreateTaxOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTax201Response>> {
        if (requestParameters.createTaxRequest === null || requestParameters.createTaxRequest === undefined) {
            throw new runtime.RequiredError('createTaxRequest','Required parameter requestParameters.createTaxRequest was null or undefined when calling createTax.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/taxes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaxRequestToJSON(requestParameters.createTaxRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTax201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a tax
     */
    async createTax(requestParameters: CreateTaxOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTax201Response> {
        const response = await this.createTaxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a tax category
     */
    async createTaxCategoryRaw(requestParameters: CreateTaxCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTaxCategory201Response>> {
        if (requestParameters.taxCategory === null || requestParameters.taxCategory === undefined) {
            throw new runtime.RequiredError('taxCategory','Required parameter requestParameters.taxCategory was null or undefined when calling createTaxCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tax-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaxCategoryToJSON(requestParameters.taxCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaxCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a tax category
     */
    async createTaxCategory(requestParameters: CreateTaxCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTaxCategory201Response> {
        const response = await this.createTaxCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete room type
     */
    async deleteRoomTypeRaw(requestParameters: DeleteRoomTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomTypeId === null || requestParameters.roomTypeId === undefined) {
            throw new runtime.RequiredError('roomTypeId','Required parameter requestParameters.roomTypeId was null or undefined when calling deleteRoomType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/room-types/{room_type_id}`.replace(`{${"room_type_id"}}`, encodeURIComponent(String(requestParameters.roomTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete room type
     */
    async deleteRoomType(requestParameters: DeleteRoomTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRoomTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Get all credits
     */
    async findAllCreditsRaw(requestParameters: FindAllCreditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCredits200Response>> {
        const queryParameters: any = {};

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.applied !== undefined) {
            queryParameters['applied'] = requestParameters.applied;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCredits200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all credits
     */
    async findAllCredits(requestParameters: FindAllCreditsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCredits200Response> {
        const response = await this.findAllCreditsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deposits
     */
    async findAllDepositsRaw(requestParameters: FindAllDepositsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllDeposits200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.applied !== undefined) {
            queryParameters['applied'] = requestParameters.applied;
        }

        if (requestParameters.isRefundable !== undefined) {
            queryParameters['is_refundable'] = requestParameters.isRefundable;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.fromDate !== undefined) {
            queryParameters['from_date'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['to_date'] = requestParameters.toDate;
        }

        if (requestParameters.isCrmResident !== undefined) {
            queryParameters['is_crm_resident'] = requestParameters.isCrmResident;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deposits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllDeposits200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all deposits
     */
    async findAllDeposits(requestParameters: FindAllDepositsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllDeposits200Response> {
        const response = await this.findAllDepositsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all discount categories
     */
    async findAllDiscountCategoriesRaw(requestParameters: FindAllDiscountCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllDiscountCategories200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discount-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllDiscountCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all discount categories
     */
    async findAllDiscountCategories(requestParameters: FindAllDiscountCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllDiscountCategories200Response> {
        const response = await this.findAllDiscountCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all discounts
     */
    async findAllDiscountsRaw(requestParameters: FindAllDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllDiscounts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_id'] = requestParameters.categoryId;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllDiscounts200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all discounts
     */
    async findAllDiscounts(requestParameters: FindAllDiscountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllDiscounts200Response> {
        const response = await this.findAllDiscountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all one time charges categories
     */
    async findAllOneTimeChargeCategoriesRaw(requestParameters: FindAllOneTimeChargeCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllOneTimeChargeCategories200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/one-time-charge-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllOneTimeChargeCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all one time charges categories
     */
    async findAllOneTimeChargeCategories(requestParameters: FindAllOneTimeChargeCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllOneTimeChargeCategories200Response> {
        const response = await this.findAllOneTimeChargeCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all one-time charges
     */
    async findAllOneTimeChargesRaw(requestParameters: FindAllOneTimeChargesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllOneTimeCharges200Response>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_id'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/one-time-charges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllOneTimeCharges200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all one-time charges
     */
    async findAllOneTimeCharges(requestParameters: FindAllOneTimeChargesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllOneTimeCharges200Response> {
        const response = await this.findAllOneTimeChargesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all one-time charges without price
     */
    async findAllOneTimeChargesWithoutPriceRaw(requestParameters: FindAllOneTimeChargesWithoutPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllOneTimeChargesWithoutPrice200Response>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_id'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/one-time-charges-without-price`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllOneTimeChargesWithoutPrice200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all one-time charges without price
     */
    async findAllOneTimeChargesWithoutPrice(requestParameters: FindAllOneTimeChargesWithoutPriceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllOneTimeChargesWithoutPrice200Response> {
        const response = await this.findAllOneTimeChargesWithoutPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all base care and care packages subscriptions by resident
     */
    async findAllRecurringPurchasesCareRaw(requestParameters: FindAllRecurringPurchasesCareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRecurringPurchasesCare200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllRecurringPurchasesCare.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/recurring/care`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRecurringPurchasesCare200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all base care and care packages subscriptions by resident
     */
    async findAllRecurringPurchasesCare(requestParameters: FindAllRecurringPurchasesCareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRecurringPurchasesCare200Response> {
        const response = await this.findAllRecurringPurchasesCareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all other recurring purchases by resident
     */
    async findAllRecurringPurchasesOtherRaw(requestParameters: FindAllRecurringPurchasesOtherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRecurringPurchasesOther200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllRecurringPurchasesOther.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/recurring/other`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRecurringPurchasesOther200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all other recurring purchases by resident
     */
    async findAllRecurringPurchasesOther(requestParameters: FindAllRecurringPurchasesOtherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRecurringPurchasesOther200Response> {
        const response = await this.findAllRecurringPurchasesOtherRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all rent subscriptions by resident
     */
    async findAllRecurringPurchasesRentRaw(requestParameters: FindAllRecurringPurchasesRentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRecurringPurchasesRent200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllRecurringPurchasesRent.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/purchases/recurring/rent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRecurringPurchasesRent200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all rent subscriptions by resident
     */
    async findAllRecurringPurchasesRent(requestParameters: FindAllRecurringPurchasesRentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRecurringPurchasesRent200Response> {
        const response = await this.findAllRecurringPurchasesRentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all resident billing information
     */
    async findAllResidentBillingInfosRaw(requestParameters: FindAllResidentBillingInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllResidentBillingInfos200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['search_query'] = requestParameters.searchQuery;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-billing-infos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllResidentBillingInfos200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all resident billing information
     */
    async findAllResidentBillingInfos(requestParameters: FindAllResidentBillingInfosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllResidentBillingInfos200Response> {
        const response = await this.findAllResidentBillingInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all room types by facility ID
     */
    async findAllRoomTypesRaw(requestParameters: FindAllRoomTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRoomTypes200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllRoomTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/room-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRoomTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all room types by facility ID
     */
    async findAllRoomTypes(requestParameters: FindAllRoomTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRoomTypes200Response> {
        const response = await this.findAllRoomTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get rooms with billing product information for a facility
     */
    async findAllRoomsRaw(requestParameters: FindAllRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllRooms200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllRooms.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/room-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllRooms200ResponseFromJSON(jsonValue));
    }

    /**
     * Get rooms with billing product information for a facility
     */
    async findAllRooms(requestParameters: FindAllRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllRooms200Response> {
        const response = await this.findAllRoomsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all tax categories
     */
    async findAllTaxCategoriesRaw(requestParameters: FindAllTaxCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaxCategories200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tax-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaxCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all tax categories
     */
    async findAllTaxCategories(requestParameters: FindAllTaxCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaxCategories200Response> {
        const response = await this.findAllTaxCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all taxes
     */
    async findAllTaxesRaw(requestParameters: FindAllTaxesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaxes200Response>> {
        const queryParameters: any = {};

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_id'] = requestParameters.categoryId;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taxes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaxes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all taxes
     */
    async findAllTaxes(requestParameters: FindAllTaxesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaxes200Response> {
        const response = await this.findAllTaxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a discounts by id
     */
    async findDiscountByIdRaw(requestParameters: FindDiscountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDiscounts201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findDiscountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDiscounts201ResponseFromJSON(jsonValue));
    }

    /**
     * Get a discounts by id
     */
    async findDiscountById(requestParameters: FindDiscountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDiscounts201Response> {
        const response = await this.findDiscountByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get bill receipt general settings
     */
    async findOneFacilityBillReceiptSettingsRaw(requestParameters: FindOneFacilityBillReceiptSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneFacilityBillReceiptSettings200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findOneFacilityBillReceiptSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bill-receipt/settings/{facility_id}`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneFacilityBillReceiptSettings200ResponseFromJSON(jsonValue));
    }

    /**
     * Get bill receipt general settings
     */
    async findOneFacilityBillReceiptSettings(requestParameters: FindOneFacilityBillReceiptSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneFacilityBillReceiptSettings200Response> {
        const response = await this.findOneFacilityBillReceiptSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get facility billing information
     */
    async findOneFacilityBillingInfoRaw(requestParameters: FindOneFacilityBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneFacilityBillingInfo200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findOneFacilityBillingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facility-billing-infos/{facility_id}`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneFacilityBillingInfo200ResponseFromJSON(jsonValue));
    }

    /**
     * Get facility billing information
     */
    async findOneFacilityBillingInfo(requestParameters: FindOneFacilityBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneFacilityBillingInfo200Response> {
        const response = await this.findOneFacilityBillingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get resident recurring purchases summary
     */
    async findOneRecurringPurchasesSummaryRaw(requestParameters: FindOneRecurringPurchasesSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneRecurringPurchasesSummary200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findOneRecurringPurchasesSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/purchases/recurring/summary`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneRecurringPurchasesSummary200ResponseFromJSON(jsonValue));
    }

    /**
     * Get resident recurring purchases summary
     */
    async findOneRecurringPurchasesSummary(requestParameters: FindOneRecurringPurchasesSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneRecurringPurchasesSummary200Response> {
        const response = await this.findOneRecurringPurchasesSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get resident billing information
     */
    async findOneResidentBillingInfoRaw(requestParameters: FindOneResidentBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneResidentBillingInfo200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling findOneResidentBillingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{resident_id}/billing-info`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneResidentBillingInfo200ResponseFromJSON(jsonValue));
    }

    /**
     * Get resident billing information
     */
    async findOneResidentBillingInfo(requestParameters: FindOneResidentBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneResidentBillingInfo200Response> {
        const response = await this.findOneResidentBillingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a tax by id
     */
    async findTaxByIdRaw(requestParameters: FindTaxByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTax201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findTaxById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taxes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTax201ResponseFromJSON(jsonValue));
    }

    /**
     * Get a tax by id
     */
    async findTaxById(requestParameters: FindTaxByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTax201Response> {
        const response = await this.findTaxByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get deposit PDF
     */
    async getDepositPdfRaw(requestParameters: GetDepositPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDepositPdf200Response>> {
        if (requestParameters.depositId === null || requestParameters.depositId === undefined) {
            throw new runtime.RequiredError('depositId','Required parameter requestParameters.depositId was null or undefined when calling getDepositPdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/deposits/{deposit_id}/pdf`.replace(`{${"deposit_id"}}`, encodeURIComponent(String(requestParameters.depositId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDepositPdf200ResponseFromJSON(jsonValue));
    }

    /**
     * Get deposit PDF
     */
    async getDepositPdf(requestParameters: GetDepositPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDepositPdf200Response> {
        const response = await this.getDepositPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a one-time purchase
     */
    async recordOneTimePurchaseRaw(requestParameters: RecordOneTimePurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.recordOneTimePurchaseRequest === null || requestParameters.recordOneTimePurchaseRequest === undefined) {
            throw new runtime.RequiredError('recordOneTimePurchaseRequest','Required parameter requestParameters.recordOneTimePurchaseRequest was null or undefined when calling recordOneTimePurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/one-time`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordOneTimePurchaseRequestToJSON(requestParameters.recordOneTimePurchaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Record a one-time purchase
     */
    async recordOneTimePurchase(requestParameters: RecordOneTimePurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.recordOneTimePurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a recurring purchase (subscription)
     */
    async recordRecurringPurchaseRaw(requestParameters: RecordRecurringPurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.recordRecurringPurchaseRequest === null || requestParameters.recordRecurringPurchaseRequest === undefined) {
            throw new runtime.RequiredError('recordRecurringPurchaseRequest','Required parameter requestParameters.recordRecurringPurchaseRequest was null or undefined when calling recordRecurringPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/recurring`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordRecurringPurchaseRequestToJSON(requestParameters.recordRecurringPurchaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Record a recurring purchase (subscription)
     */
    async recordRecurringPurchase(requestParameters: RecordRecurringPurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.recordRecurringPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a recurring room rent purchase (subscription)
     */
    async recordRoomRentPurchaseRaw(requestParameters: RecordRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.recordRecurringPurchaseRequest === null || requestParameters.recordRecurringPurchaseRequest === undefined) {
            throw new runtime.RequiredError('recordRecurringPurchaseRequest','Required parameter requestParameters.recordRecurringPurchaseRequest was null or undefined when calling recordRoomRentPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/room-rent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordRecurringPurchaseRequestToJSON(requestParameters.recordRecurringPurchaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Record a recurring room rent purchase (subscription)
     */
    async recordRoomRentPurchase(requestParameters: RecordRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.recordRoomRentPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send deposit email
     */
    async sendDepositEmailRaw(requestParameters: SendDepositEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendDepositEmail200Response>> {
        if (requestParameters.depositId === null || requestParameters.depositId === undefined) {
            throw new runtime.RequiredError('depositId','Required parameter requestParameters.depositId was null or undefined when calling sendDepositEmail.');
        }

        if (requestParameters.sendDepositEmailRequest === null || requestParameters.sendDepositEmailRequest === undefined) {
            throw new runtime.RequiredError('sendDepositEmailRequest','Required parameter requestParameters.sendDepositEmailRequest was null or undefined when calling sendDepositEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deposits/{deposit_id}/email`.replace(`{${"deposit_id"}}`, encodeURIComponent(String(requestParameters.depositId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SendDepositEmailRequestToJSON(requestParameters.sendDepositEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendDepositEmail200ResponseFromJSON(jsonValue));
    }

    /**
     * Send deposit email
     */
    async sendDepositEmail(requestParameters: SendDepositEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendDepositEmail200Response> {
        const response = await this.sendDepositEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a deposit by id
     */
    async updateDepositByIdRaw(requestParameters: UpdateDepositByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeposit201Response>> {
        if (requestParameters.depositId === null || requestParameters.depositId === undefined) {
            throw new runtime.RequiredError('depositId','Required parameter requestParameters.depositId was null or undefined when calling updateDepositById.');
        }

        if (requestParameters.deposit === null || requestParameters.deposit === undefined) {
            throw new runtime.RequiredError('deposit','Required parameter requestParameters.deposit was null or undefined when calling updateDepositById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deposits/{deposit_id}`.replace(`{${"deposit_id"}}`, encodeURIComponent(String(requestParameters.depositId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DepositToJSON(requestParameters.deposit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeposit201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a deposit by id
     */
    async updateDepositById(requestParameters: UpdateDepositByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeposit201Response> {
        const response = await this.updateDepositByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a discount by id
     */
    async updateDiscountByIdRaw(requestParameters: UpdateDiscountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDiscounts201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDiscountById.');
        }

        if (requestParameters.discount === null || requestParameters.discount === undefined) {
            throw new runtime.RequiredError('discount','Required parameter requestParameters.discount was null or undefined when calling updateDiscountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountToJSON(requestParameters.discount),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDiscounts201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a discount by id
     */
    async updateDiscountById(requestParameters: UpdateDiscountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDiscounts201Response> {
        const response = await this.updateDiscountByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a discount category
     */
    async updateDiscountCategoryRaw(requestParameters: UpdateDiscountCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDiscountCategory201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDiscountCategory.');
        }

        if (requestParameters.discountCategory === null || requestParameters.discountCategory === undefined) {
            throw new runtime.RequiredError('discountCategory','Required parameter requestParameters.discountCategory was null or undefined when calling updateDiscountCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discount-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountCategoryToJSON(requestParameters.discountCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDiscountCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a discount category
     */
    async updateDiscountCategory(requestParameters: UpdateDiscountCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDiscountCategory201Response> {
        const response = await this.updateDiscountCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update bill receipt general settings
     */
    async updateFacilityBillReceiptSettingsRaw(requestParameters: UpdateFacilityBillReceiptSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneFacilityBillReceiptSettings200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateFacilityBillReceiptSettings.');
        }

        if (requestParameters.facilityBillReceiptSettings === null || requestParameters.facilityBillReceiptSettings === undefined) {
            throw new runtime.RequiredError('facilityBillReceiptSettings','Required parameter requestParameters.facilityBillReceiptSettings was null or undefined when calling updateFacilityBillReceiptSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bill-receipt/settings/{facility_id}`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FacilityBillReceiptSettingsToJSON(requestParameters.facilityBillReceiptSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneFacilityBillReceiptSettings200ResponseFromJSON(jsonValue));
    }

    /**
     * Update bill receipt general settings
     */
    async updateFacilityBillReceiptSettings(requestParameters: UpdateFacilityBillReceiptSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneFacilityBillReceiptSettings200Response> {
        const response = await this.updateFacilityBillReceiptSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update facility billing information
     */
    async updateFacilityBillingInfoRaw(requestParameters: UpdateFacilityBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneFacilityBillingInfo200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateFacilityBillingInfo.');
        }

        if (requestParameters.facilityBillingInfo === null || requestParameters.facilityBillingInfo === undefined) {
            throw new runtime.RequiredError('facilityBillingInfo','Required parameter requestParameters.facilityBillingInfo was null or undefined when calling updateFacilityBillingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facility-billing-infos/{facility_id}`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FacilityBillingInfoToJSON(requestParameters.facilityBillingInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneFacilityBillingInfo200ResponseFromJSON(jsonValue));
    }

    /**
     * Update facility billing information
     */
    async updateFacilityBillingInfo(requestParameters: UpdateFacilityBillingInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneFacilityBillingInfo200Response> {
        const response = await this.updateFacilityBillingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a one-time charge
     */
    async updateOneTimeChargeRaw(requestParameters: UpdateOneTimeChargeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeCharge201Response>> {
        if (requestParameters.chargeId === null || requestParameters.chargeId === undefined) {
            throw new runtime.RequiredError('chargeId','Required parameter requestParameters.chargeId was null or undefined when calling updateOneTimeCharge.');
        }

        if (requestParameters.updateOneTimeChargeRequest === null || requestParameters.updateOneTimeChargeRequest === undefined) {
            throw new runtime.RequiredError('updateOneTimeChargeRequest','Required parameter requestParameters.updateOneTimeChargeRequest was null or undefined when calling updateOneTimeCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-charges/{charge_id}`.replace(`{${"charge_id"}}`, encodeURIComponent(String(requestParameters.chargeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOneTimeChargeRequestToJSON(requestParameters.updateOneTimeChargeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeCharge201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a one-time charge
     */
    async updateOneTimeCharge(requestParameters: UpdateOneTimeChargeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeCharge201Response> {
        const response = await this.updateOneTimeChargeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a one time charge category
     */
    async updateOneTimeChargeCategoryRaw(requestParameters: UpdateOneTimeChargeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeChargeCategory201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOneTimeChargeCategory.');
        }

        if (requestParameters.oneTimeChargeCategory === null || requestParameters.oneTimeChargeCategory === undefined) {
            throw new runtime.RequiredError('oneTimeChargeCategory','Required parameter requestParameters.oneTimeChargeCategory was null or undefined when calling updateOneTimeChargeCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-charge-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeChargeCategoryToJSON(requestParameters.oneTimeChargeCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeChargeCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a one time charge category
     */
    async updateOneTimeChargeCategory(requestParameters: UpdateOneTimeChargeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeChargeCategory201Response> {
        const response = await this.updateOneTimeChargeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a recurring purchase (subscription)
     */
    async updateRecurringPurchaseRaw(requestParameters: UpdateRecurringPurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling updateRecurringPurchase.');
        }

        if (requestParameters.updateRecurringPurchaseRequest === null || requestParameters.updateRecurringPurchaseRequest === undefined) {
            throw new runtime.RequiredError('updateRecurringPurchaseRequest','Required parameter requestParameters.updateRecurringPurchaseRequest was null or undefined when calling updateRecurringPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/recurring/{subscription_id}`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecurringPurchaseRequestToJSON(requestParameters.updateRecurringPurchaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a recurring purchase (subscription)
     */
    async updateRecurringPurchase(requestParameters: UpdateRecurringPurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.updateRecurringPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update resident billing information
     */
    async updateResidentBillingInfoRaw(requestParameters: UpdateResidentBillingInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneResidentBillingInfo200Response>> {
        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling updateResidentBillingInfo.');
        }

        if (requestParameters.updateResidentBillingInfoRequest === null || requestParameters.updateResidentBillingInfoRequest === undefined) {
            throw new runtime.RequiredError('updateResidentBillingInfoRequest','Required parameter requestParameters.updateResidentBillingInfoRequest was null or undefined when calling updateResidentBillingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/{resident_id}/billing-info`.replace(`{${"resident_id"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResidentBillingInfoRequestToJSON(requestParameters.updateResidentBillingInfoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneResidentBillingInfo200ResponseFromJSON(jsonValue));
    }

    /**
     * Update resident billing information
     */
    async updateResidentBillingInfo(requestParameters: UpdateResidentBillingInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneResidentBillingInfo200Response> {
        const response = await this.updateResidentBillingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update room for a facility
     */
    async updateRoomRaw(requestParameters: UpdateRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateRoom200Response>> {
        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling updateRoom.');
        }

        if (requestParameters.updateRoomRequest === null || requestParameters.updateRoomRequest === undefined) {
            throw new runtime.RequiredError('updateRoomRequest','Required parameter requestParameters.updateRoomRequest was null or undefined when calling updateRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/room-rates/{room_id}`.replace(`{${"room_id"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRoomRequestToJSON(requestParameters.updateRoomRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateRoom200ResponseFromJSON(jsonValue));
    }

    /**
     * Update room for a facility
     */
    async updateRoom(requestParameters: UpdateRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateRoom200Response> {
        const response = await this.updateRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a recurring room rent purchase (subscription)
     */
    async updateRoomRentPurchaseRaw(requestParameters: UpdateRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordOneTimePurchase201Response>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling updateRoomRentPurchase.');
        }

        if (requestParameters.updateRecurringPurchaseRequest === null || requestParameters.updateRecurringPurchaseRequest === undefined) {
            throw new runtime.RequiredError('updateRecurringPurchaseRequest','Required parameter requestParameters.updateRecurringPurchaseRequest was null or undefined when calling updateRoomRentPurchase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/purchases/room-rent/{subscription_id}`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecurringPurchaseRequestToJSON(requestParameters.updateRecurringPurchaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordOneTimePurchase201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a recurring room rent purchase (subscription)
     */
    async updateRoomRentPurchase(requestParameters: UpdateRoomRentPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordOneTimePurchase201Response> {
        const response = await this.updateRoomRentPurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a tax by id
     */
    async updateTaxByIdRaw(requestParameters: UpdateTaxByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTax201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTaxById.');
        }

        if (requestParameters.tax === null || requestParameters.tax === undefined) {
            throw new runtime.RequiredError('tax','Required parameter requestParameters.tax was null or undefined when calling updateTaxById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/taxes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxToJSON(requestParameters.tax),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTax201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a tax by id
     */
    async updateTaxById(requestParameters: UpdateTaxByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTax201Response> {
        const response = await this.updateTaxByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a tax category
     */
    async updateTaxCategoryRaw(requestParameters: UpdateTaxCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTaxCategory201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTaxCategory.');
        }

        if (requestParameters.taxCategory === null || requestParameters.taxCategory === undefined) {
            throw new runtime.RequiredError('taxCategory','Required parameter requestParameters.taxCategory was null or undefined when calling updateTaxCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tax-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxCategoryToJSON(requestParameters.taxCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaxCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a tax category
     */
    async updateTaxCategory(requestParameters: UpdateTaxCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTaxCategory201Response> {
        const response = await this.updateTaxCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
