import React from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { HTTPError } from 'ky';

import { useReferrals } from '@/hooks/useReferrals';
import ErrorPage from '@/pages/ErrorPage';

export const CrmReferralSourceLoader: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const referralId = useParams().referral_source_id!;

  const { error, isLoading } = useReferrals().findOne(referralId, {
    enabled: !!referralId,
    retry: false
  });

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (error instanceof HTTPError && error.response.status === 404) {
    return (
      <ErrorPage
        title="Requested Referral Source Not Found"
        message="This referral source does not exist. Please check the URL and try again."
      />
    );
  }

  return <>{children}</>;
};
