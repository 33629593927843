/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskType } from './TaskType';
import {
    TaskTypeFromJSON,
    TaskTypeFromJSONTyped,
    TaskTypeToJSON,
} from './TaskType';

/**
 * 
 * @export
 * @interface FindAllTaskTypes200Response
 */
export interface FindAllTaskTypes200Response {
    /**
     * 
     * @type {Array<TaskType>}
     * @memberof FindAllTaskTypes200Response
     */
    data?: Array<TaskType>;
}

/**
 * Check if a given object implements the FindAllTaskTypes200Response interface.
 */
export function instanceOfFindAllTaskTypes200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllTaskTypes200ResponseFromJSON(json: any): FindAllTaskTypes200Response {
    return FindAllTaskTypes200ResponseFromJSONTyped(json, false);
}

export function FindAllTaskTypes200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllTaskTypes200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(TaskTypeFromJSON)),
    };
}

export function FindAllTaskTypes200ResponseToJSON(value?: FindAllTaskTypes200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(TaskTypeToJSON)),
    };
}

