/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentsFindAllResponse } from './ResidentsFindAllResponse';
import {
    ResidentsFindAllResponseFromJSON,
    ResidentsFindAllResponseFromJSONTyped,
    ResidentsFindAllResponseToJSON,
} from './ResidentsFindAllResponse';

/**
 * 
 * @export
 * @interface FindAllResidents200Response
 */
export interface FindAllResidents200Response {
    /**
     * 
     * @type {ResidentsFindAllResponse}
     * @memberof FindAllResidents200Response
     */
    data?: ResidentsFindAllResponse;
}

/**
 * Check if a given object implements the FindAllResidents200Response interface.
 */
export function instanceOfFindAllResidents200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllResidents200ResponseFromJSON(json: any): FindAllResidents200Response {
    return FindAllResidents200ResponseFromJSONTyped(json, false);
}

export function FindAllResidents200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidents200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ResidentsFindAllResponseFromJSON(json['data']),
    };
}

export function FindAllResidents200ResponseToJSON(value?: FindAllResidents200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ResidentsFindAllResponseToJSON(value.data),
    };
}

