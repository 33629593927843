/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TextTemplateListEntry } from './TextTemplateListEntry';
import {
    TextTemplateListEntryFromJSON,
    TextTemplateListEntryFromJSONTyped,
    TextTemplateListEntryToJSON,
} from './TextTemplateListEntry';

/**
 * 
 * @export
 * @interface FindAllTextTemplates200Response
 */
export interface FindAllTextTemplates200Response {
    /**
     * 
     * @type {Array<TextTemplateListEntry>}
     * @memberof FindAllTextTemplates200Response
     */
    data?: Array<TextTemplateListEntry>;
}

/**
 * Check if a given object implements the FindAllTextTemplates200Response interface.
 */
export function instanceOfFindAllTextTemplates200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllTextTemplates200ResponseFromJSON(json: any): FindAllTextTemplates200Response {
    return FindAllTextTemplates200ResponseFromJSONTyped(json, false);
}

export function FindAllTextTemplates200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllTextTemplates200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(TextTemplateListEntryFromJSON)),
    };
}

export function FindAllTextTemplates200ResponseToJSON(value?: FindAllTextTemplates200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(TextTemplateListEntryToJSON)),
    };
}

