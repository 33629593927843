/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAddressBookItem
 */
export interface EmailAddressBookItem {
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    resource_id: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressBookItem
     */
    type: string;
}

/**
 * Check if a given object implements the EmailAddressBookItem interface.
 */
export function instanceOfEmailAddressBookItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "resource_id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function EmailAddressBookItemFromJSON(json: any): EmailAddressBookItem {
    return EmailAddressBookItemFromJSONTyped(json, false);
}

export function EmailAddressBookItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAddressBookItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'resource_id': json['resource_id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': json['email'],
        'photo_url': !exists(json, 'photo_url') ? undefined : json['photo_url'],
        'type': json['type'],
    };
}

export function EmailAddressBookItemToJSON(value?: EmailAddressBookItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'resource_id': value.resource_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'photo_url': value.photo_url,
        'type': value.type,
    };
}

