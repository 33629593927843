/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignTextStats
 */
export interface CampaignTextStats {
    /**
     * 
     * @type {number}
     * @memberof CampaignTextStats
     */
    sent_today_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignTextStats
     */
    sent_count: number;
}

/**
 * Check if a given object implements the CampaignTextStats interface.
 */
export function instanceOfCampaignTextStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sent_today_count" in value;
    isInstance = isInstance && "sent_count" in value;

    return isInstance;
}

export function CampaignTextStatsFromJSON(json: any): CampaignTextStats {
    return CampaignTextStatsFromJSONTyped(json, false);
}

export function CampaignTextStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignTextStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sent_today_count': json['sent_today_count'],
        'sent_count': json['sent_count'],
    };
}

export function CampaignTextStatsToJSON(value?: CampaignTextStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sent_today_count': value.sent_today_count,
        'sent_count': value.sent_count,
    };
}

