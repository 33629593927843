/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepositEmailStatus } from './DepositEmailStatus';
import {
    DepositEmailStatusFromJSON,
    DepositEmailStatusFromJSONTyped,
    DepositEmailStatusToJSON,
} from './DepositEmailStatus';

/**
 * 
 * @export
 * @interface SendDepositEmailResponse
 */
export interface SendDepositEmailResponse {
    /**
     * 
     * @type {DepositEmailStatus}
     * @memberof SendDepositEmailResponse
     */
    status: DepositEmailStatus;
}

/**
 * Check if a given object implements the SendDepositEmailResponse interface.
 */
export function instanceOfSendDepositEmailResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SendDepositEmailResponseFromJSON(json: any): SendDepositEmailResponse {
    return SendDepositEmailResponseFromJSONTyped(json, false);
}

export function SendDepositEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendDepositEmailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': DepositEmailStatusFromJSON(json['status']),
    };
}

export function SendDepositEmailResponseToJSON(value?: SendDepositEmailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': DepositEmailStatusToJSON(value.status),
    };
}

