/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceContact } from './SourceContact';
import {
    SourceContactFromJSON,
    SourceContactFromJSONTyped,
    SourceContactToJSON,
} from './SourceContact';

/**
 * 
 * @export
 * @interface FindAllSourceContacts200Response
 */
export interface FindAllSourceContacts200Response {
    /**
     * 
     * @type {Array<SourceContact>}
     * @memberof FindAllSourceContacts200Response
     */
    data?: Array<SourceContact>;
}

/**
 * Check if a given object implements the FindAllSourceContacts200Response interface.
 */
export function instanceOfFindAllSourceContacts200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllSourceContacts200ResponseFromJSON(json: any): FindAllSourceContacts200Response {
    return FindAllSourceContacts200ResponseFromJSONTyped(json, false);
}

export function FindAllSourceContacts200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllSourceContacts200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(SourceContactFromJSON)),
    };
}

export function FindAllSourceContacts200ResponseToJSON(value?: FindAllSourceContacts200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(SourceContactToJSON)),
    };
}

