/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentAnswer } from './AssessmentAnswer';
import {
    AssessmentAnswerFromJSON,
    AssessmentAnswerFromJSONTyped,
    AssessmentAnswerToJSON,
} from './AssessmentAnswer';
import type { AssessmentQuestionTrigger } from './AssessmentQuestionTrigger';
import {
    AssessmentQuestionTriggerFromJSON,
    AssessmentQuestionTriggerFromJSONTyped,
    AssessmentQuestionTriggerToJSON,
} from './AssessmentQuestionTrigger';
import type { AssessmentQuestionType } from './AssessmentQuestionType';
import {
    AssessmentQuestionTypeFromJSON,
    AssessmentQuestionTypeFromJSONTyped,
    AssessmentQuestionTypeToJSON,
} from './AssessmentQuestionType';

/**
 * 
 * @export
 * @interface AssessmentQuestion
 */
export interface AssessmentQuestion {
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuestion
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuestion
     */
    text: string;
    /**
     * 
     * @type {AssessmentQuestionType}
     * @memberof AssessmentQuestion
     */
    type: AssessmentQuestionType;
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuestion
     */
    instructions: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuestion
     */
    comment?: string;
    /**
     * 
     * @type {Array<AssessmentQuestionTrigger>}
     * @memberof AssessmentQuestion
     */
    triggered_by?: Array<AssessmentQuestionTrigger>;
    /**
     * 
     * @type {Array<AssessmentAnswer>}
     * @memberof AssessmentQuestion
     */
    answers?: Array<AssessmentAnswer>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentQuestion
     */
    vital_type_ids?: Array<string>;
}

/**
 * Check if a given object implements the AssessmentQuestion interface.
 */
export function instanceOfAssessmentQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "instructions" in value;

    return isInstance;
}

export function AssessmentQuestionFromJSON(json: any): AssessmentQuestion {
    return AssessmentQuestionFromJSONTyped(json, false);
}

export function AssessmentQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'text': json['text'],
        'type': AssessmentQuestionTypeFromJSON(json['type']),
        'instructions': json['instructions'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'triggered_by': !exists(json, 'triggered_by') ? undefined : ((json['triggered_by'] as Array<any>).map(AssessmentQuestionTriggerFromJSON)),
        'answers': !exists(json, 'answers') ? undefined : ((json['answers'] as Array<any>).map(AssessmentAnswerFromJSON)),
        'vital_type_ids': !exists(json, 'vital_type_ids') ? undefined : json['vital_type_ids'],
    };
}

export function AssessmentQuestionToJSON(value?: AssessmentQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'type': AssessmentQuestionTypeToJSON(value.type),
        'instructions': value.instructions,
        'comment': value.comment,
        'triggered_by': value.triggered_by === undefined ? undefined : ((value.triggered_by as Array<any>).map(AssessmentQuestionTriggerToJSON)),
        'answers': value.answers === undefined ? undefined : ((value.answers as Array<any>).map(AssessmentAnswerToJSON)),
        'vital_type_ids': value.vital_type_ids,
    };
}

