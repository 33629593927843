/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentFollowupListEntry } from './IncidentFollowupListEntry';
import {
    IncidentFollowupListEntryFromJSON,
    IncidentFollowupListEntryFromJSONTyped,
    IncidentFollowupListEntryToJSON,
} from './IncidentFollowupListEntry';
import type { IncidentProtocolResponsiblePartyListEntry } from './IncidentProtocolResponsiblePartyListEntry';
import {
    IncidentProtocolResponsiblePartyListEntryFromJSON,
    IncidentProtocolResponsiblePartyListEntryFromJSONTyped,
    IncidentProtocolResponsiblePartyListEntryToJSON,
} from './IncidentProtocolResponsiblePartyListEntry';

/**
 * 
 * @export
 * @interface IncidentProtocolAction
 */
export interface IncidentProtocolAction {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    modified_by?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    modified_date?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    deadline?: string;
    /**
     * 
     * @type {Array<IncidentProtocolResponsiblePartyListEntry>}
     * @memberof IncidentProtocolAction
     */
    responsible_parties?: Array<IncidentProtocolResponsiblePartyListEntry>;
    /**
     * 
     * @type {Array<IncidentFollowupListEntry>}
     * @memberof IncidentProtocolAction
     */
    followups?: Array<IncidentFollowupListEntry>;
    /**
     * 
     * @type {any}
     * @memberof IncidentProtocolAction
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentProtocolAction
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolAction
     */
    incomplete_followup_time?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolAction
     */
    date_created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolAction
     */
    date_updated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocolAction
     */
    date_deleted?: Date;
}

/**
 * Check if a given object implements the IncidentProtocolAction interface.
 */
export function instanceOfIncidentProtocolAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_protocol_id" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_optional" in value;

    return isInstance;
}

export function IncidentProtocolActionFromJSON(json: any): IncidentProtocolAction {
    return IncidentProtocolActionFromJSONTyped(json, false);
}

export function IncidentProtocolActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_protocol_id': json['incident_protocol_id'],
        'created_by': json['created_by'],
        'modified_by': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'modified_date': !exists(json, 'modified_date') ? undefined : json['modified_date'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        'responsible_parties': !exists(json, 'responsible_parties') ? undefined : ((json['responsible_parties'] as Array<any>).map(IncidentProtocolResponsiblePartyListEntryFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentFollowupListEntryFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'is_optional': json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
        'date_created': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'date_updated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'date_deleted': !exists(json, 'date_deleted') ? undefined : (new Date(json['date_deleted'])),
    };
}

export function IncidentProtocolActionToJSON(value?: IncidentProtocolAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_protocol_id': value.incident_protocol_id,
        'created_by': value.created_by,
        'modified_by': value.modified_by,
        'modified_date': value.modified_date,
        'name': value.name,
        'description': value.description,
        'deadline': value.deadline,
        'responsible_parties': value.responsible_parties === undefined ? undefined : ((value.responsible_parties as Array<any>).map(IncidentProtocolResponsiblePartyListEntryToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentFollowupListEntryToJSON)),
        'complete_followups': value.complete_followups,
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
        'date_created': value.date_created === undefined ? undefined : (value.date_created.toISOString()),
        'date_updated': value.date_updated === undefined ? undefined : (value.date_updated.toISOString()),
        'date_deleted': value.date_deleted === undefined ? undefined : (value.date_deleted.toISOString()),
    };
}

