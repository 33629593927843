/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExclusionTerm } from './ExclusionTerm';
import {
    ExclusionTermFromJSON,
    ExclusionTermFromJSONTyped,
    ExclusionTermToJSON,
} from './ExclusionTerm';

/**
 * 
 * @export
 * @interface CreateDocumentExclusionTerm201Response
 */
export interface CreateDocumentExclusionTerm201Response {
    /**
     * 
     * @type {ExclusionTerm}
     * @memberof CreateDocumentExclusionTerm201Response
     */
    data?: ExclusionTerm;
}

/**
 * Check if a given object implements the CreateDocumentExclusionTerm201Response interface.
 */
export function instanceOfCreateDocumentExclusionTerm201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDocumentExclusionTerm201ResponseFromJSON(json: any): CreateDocumentExclusionTerm201Response {
    return CreateDocumentExclusionTerm201ResponseFromJSONTyped(json, false);
}

export function CreateDocumentExclusionTerm201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentExclusionTerm201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ExclusionTermFromJSON(json['data']),
    };
}

export function CreateDocumentExclusionTerm201ResponseToJSON(value?: CreateDocumentExclusionTerm201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ExclusionTermToJSON(value.data),
    };
}

