/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDocumentExclusionTermRequest
 */
export interface CreateDocumentExclusionTermRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentExclusionTermRequest
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentExclusionTermRequest
     */
    term: string;
}

/**
 * Check if a given object implements the CreateDocumentExclusionTermRequest interface.
 */
export function instanceOfCreateDocumentExclusionTermRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "term" in value;

    return isInstance;
}

export function CreateDocumentExclusionTermRequestFromJSON(json: any): CreateDocumentExclusionTermRequest {
    return CreateDocumentExclusionTermRequestFromJSONTyped(json, false);
}

export function CreateDocumentExclusionTermRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentExclusionTermRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'term': json['term'],
    };
}

export function CreateDocumentExclusionTermRequestToJSON(value?: CreateDocumentExclusionTermRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'term': value.term,
    };
}

