/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmInquiryResponse
 */
export interface ApfmInquiryResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmInquiryResponse
     */
    lead_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmInquiryResponse
     */
    inquiry_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmInquiryResponse
     */
    inquiry_method?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmInquiryResponse
     */
    referral_type?: string;
}

/**
 * Check if a given object implements the ApfmInquiryResponse interface.
 */
export function instanceOfApfmInquiryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmInquiryResponseFromJSON(json: any): ApfmInquiryResponse {
    return ApfmInquiryResponseFromJSONTyped(json, false);
}

export function ApfmInquiryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmInquiryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
        'inquiry_date': !exists(json, 'inquiry_date') ? undefined : json['inquiry_date'],
        'inquiry_method': !exists(json, 'inquiry_method') ? undefined : json['inquiry_method'],
        'referral_type': !exists(json, 'referral_type') ? undefined : json['referral_type'],
    };
}

export function ApfmInquiryResponseToJSON(value?: ApfmInquiryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lead_id': value.lead_id,
        'inquiry_date': value.inquiry_date,
        'inquiry_method': value.inquiry_method,
        'referral_type': value.referral_type,
    };
}

