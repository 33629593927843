import React from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface ResidentPrintHeaderViewProps {
  pageTitle?: string;
  residentName?: string;
  reportRangeDate?: string;
  reportUser: string;
  communityName?: string;
}

export const HeaderPrintItem: React.FC<{ label: string; value: string }> = ({
  label,
  value
}) => {
  return (
    <Grid item mb="16px">
      <Typography variant="body1" color="#667A86" fontSize="14px" mb="4px">
        {label}
      </Typography>
      <Typography
        variant="body1"
        color="#0A1E28"
        fontWeight="bold"
        fontSize="14px">
        {value}
      </Typography>
    </Grid>
  );
};

const ResidentPrintHeaderView: React.FC<ResidentPrintHeaderViewProps> = ({
  pageTitle,
  residentName,
  reportRangeDate,
  reportUser,
  communityName
}) => {
  const today = dayjs().format('MMM D YYYY');

  return (
    <Grid xs={12} container bgcolor="#FFF">
      <Grid xs={12} item mb="16px">
        <Typography
          variant="h6"
          color="#0A1E28"
          fontWeight="bold"
          fontSize="22px">
          {pageTitle}
        </Typography>
      </Grid>
      {residentName && (
        <Grid item flex={1}>
          <HeaderPrintItem label="Resident" value={residentName} />
        </Grid>
      )}
      {reportRangeDate && (
        <Grid item flex={1}>
          <HeaderPrintItem
            label="Report By Date Range"
            value={reportRangeDate}
          />
        </Grid>
      )}
      <Grid item flex={1}>
        <HeaderPrintItem label="Generated By" value={reportUser} />
      </Grid>
      {communityName && (
        <Grid item flex={1}>
          <HeaderPrintItem label="Community Name" value={communityName} />
        </Grid>
      )}
      <Grid item flex={1}>
        <HeaderPrintItem label="Generated On" value={today} />
      </Grid>
      <Grid item flex={1}>
        <HeaderPrintItem label="Generated On" value={today} />
      </Grid>
    </Grid>
  );
};

export default ResidentPrintHeaderView;
