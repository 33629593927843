/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnscheduledCareTaskInstance
 */
export interface UnscheduledCareTaskInstance {
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareTaskInstance
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareTaskInstance
     */
    completed_by_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledCareTaskInstance
     */
    date_completed: string;
}

/**
 * Check if a given object implements the UnscheduledCareTaskInstance interface.
 */
export function instanceOfUnscheduledCareTaskInstance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "completed_by_user_id" in value;
    isInstance = isInstance && "date_completed" in value;

    return isInstance;
}

export function UnscheduledCareTaskInstanceFromJSON(json: any): UnscheduledCareTaskInstance {
    return UnscheduledCareTaskInstanceFromJSONTyped(json, false);
}

export function UnscheduledCareTaskInstanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnscheduledCareTaskInstance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'completed_by_user_id': json['completed_by_user_id'],
        'date_completed': json['date_completed'],
    };
}

export function UnscheduledCareTaskInstanceToJSON(value?: UnscheduledCareTaskInstance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'completed_by_user_id': value.completed_by_user_id,
        'date_completed': value.date_completed,
    };
}

