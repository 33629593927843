/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DynamicPermission } from './DynamicPermission';
import {
    DynamicPermissionFromJSON,
    DynamicPermissionFromJSONTyped,
    DynamicPermissionToJSON,
} from './DynamicPermission';

/**
 * 
 * @export
 * @interface FindAllUserDynamicPermissions200Response
 */
export interface FindAllUserDynamicPermissions200Response {
    /**
     * 
     * @type {Array<DynamicPermission>}
     * @memberof FindAllUserDynamicPermissions200Response
     */
    data?: Array<DynamicPermission>;
}

/**
 * Check if a given object implements the FindAllUserDynamicPermissions200Response interface.
 */
export function instanceOfFindAllUserDynamicPermissions200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllUserDynamicPermissions200ResponseFromJSON(json: any): FindAllUserDynamicPermissions200Response {
    return FindAllUserDynamicPermissions200ResponseFromJSONTyped(json, false);
}

export function FindAllUserDynamicPermissions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllUserDynamicPermissions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(DynamicPermissionFromJSON)),
    };
}

export function FindAllUserDynamicPermissions200ResponseToJSON(value?: FindAllUserDynamicPermissions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(DynamicPermissionToJSON)),
    };
}

