/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettlementPayment
 */
export interface SettlementPayment {
    /**
     * 
     * @type {string}
     * @memberof SettlementPayment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPayment
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementPayment
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementPayment
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPayment
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementPayment
     */
    method: SettlementPaymentMethodEnum;
}


/**
 * @export
 */
export const SettlementPaymentMethodEnum = {
    Cash: 'Cash',
    Check: 'Check',
    Other: 'Other'
} as const;
export type SettlementPaymentMethodEnum = typeof SettlementPaymentMethodEnum[keyof typeof SettlementPaymentMethodEnum];


/**
 * Check if a given object implements the SettlementPayment interface.
 */
export function instanceOfSettlementPayment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "method" in value;

    return isInstance;
}

export function SettlementPaymentFromJSON(json: any): SettlementPayment {
    return SettlementPaymentFromJSONTyped(json, false);
}

export function SettlementPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementPayment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'date': json['date'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'method': json['method'],
    };
}

export function SettlementPaymentToJSON(value?: SettlementPayment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'amount': value.amount,
        'date': value.date,
        'description': value.description,
        'method': value.method,
    };
}

