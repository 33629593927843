/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolActionListEntry } from './IncidentProtocolActionListEntry';
import {
    IncidentProtocolActionListEntryFromJSON,
    IncidentProtocolActionListEntryFromJSONTyped,
    IncidentProtocolActionListEntryToJSON,
} from './IncidentProtocolActionListEntry';

/**
 * 
 * @export
 * @interface FindAllProtocolActions200Response
 */
export interface FindAllProtocolActions200Response {
    /**
     * 
     * @type {Array<IncidentProtocolActionListEntry>}
     * @memberof FindAllProtocolActions200Response
     */
    data?: Array<IncidentProtocolActionListEntry>;
}

/**
 * Check if a given object implements the FindAllProtocolActions200Response interface.
 */
export function instanceOfFindAllProtocolActions200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllProtocolActions200ResponseFromJSON(json: any): FindAllProtocolActions200Response {
    return FindAllProtocolActions200ResponseFromJSONTyped(json, false);
}

export function FindAllProtocolActions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllProtocolActions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(IncidentProtocolActionListEntryFromJSON)),
    };
}

export function FindAllProtocolActions200ResponseToJSON(value?: FindAllProtocolActions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(IncidentProtocolActionListEntryToJSON)),
    };
}

