/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCareUnitsType } from './AssessmentCareUnitsType';
import {
    AssessmentCareUnitsTypeFromJSON,
    AssessmentCareUnitsTypeFromJSONTyped,
    AssessmentCareUnitsTypeToJSON,
} from './AssessmentCareUnitsType';

/**
 * 
 * @export
 * @interface CreateAssessmentRequest
 */
export interface CreateAssessmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAssessmentRequest
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssessmentRequest
     */
    name: string;
    /**
     * 
     * @type {AssessmentCareUnitsType}
     * @memberof CreateAssessmentRequest
     */
    care_units_type?: AssessmentCareUnitsType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAssessmentRequest
     */
    is_care_plan_generator?: boolean;
}

/**
 * Check if a given object implements the CreateAssessmentRequest interface.
 */
export function instanceOfCreateAssessmentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateAssessmentRequestFromJSON(json: any): CreateAssessmentRequest {
    return CreateAssessmentRequestFromJSONTyped(json, false);
}

export function CreateAssessmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssessmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'name': json['name'],
        'care_units_type': !exists(json, 'care_units_type') ? undefined : AssessmentCareUnitsTypeFromJSON(json['care_units_type']),
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
    };
}

export function CreateAssessmentRequestToJSON(value?: CreateAssessmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'name': value.name,
        'care_units_type': AssessmentCareUnitsTypeToJSON(value.care_units_type),
        'is_care_plan_generator': value.is_care_plan_generator,
    };
}

