/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmResidentsPage } from './CrmResidentsPage';
import {
    CrmResidentsPageFromJSON,
    CrmResidentsPageFromJSONTyped,
    CrmResidentsPageToJSON,
} from './CrmResidentsPage';

/**
 * 
 * @export
 * @interface FindAllCrmContactsResidents200Response
 */
export interface FindAllCrmContactsResidents200Response {
    /**
     * 
     * @type {CrmResidentsPage}
     * @memberof FindAllCrmContactsResidents200Response
     */
    data?: CrmResidentsPage;
}

/**
 * Check if a given object implements the FindAllCrmContactsResidents200Response interface.
 */
export function instanceOfFindAllCrmContactsResidents200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCrmContactsResidents200ResponseFromJSON(json: any): FindAllCrmContactsResidents200Response {
    return FindAllCrmContactsResidents200ResponseFromJSONTyped(json, false);
}

export function FindAllCrmContactsResidents200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCrmContactsResidents200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CrmResidentsPageFromJSON(json['data']),
    };
}

export function FindAllCrmContactsResidents200ResponseToJSON(value?: FindAllCrmContactsResidents200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CrmResidentsPageToJSON(value.data),
    };
}

