/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CombineLeads200Response,
  CombineLeadsRequest,
  CreateDocumentExclusionTerm201Response,
  CreateDocumentExclusionTermRequest,
  GetDocumentExclusionTerms200Response,
  UpdateDocumentExclusionTermRequest,
} from '../models/index';
import {
    CombineLeads200ResponseFromJSON,
    CombineLeads200ResponseToJSON,
    CombineLeadsRequestFromJSON,
    CombineLeadsRequestToJSON,
    CreateDocumentExclusionTerm201ResponseFromJSON,
    CreateDocumentExclusionTerm201ResponseToJSON,
    CreateDocumentExclusionTermRequestFromJSON,
    CreateDocumentExclusionTermRequestToJSON,
    GetDocumentExclusionTerms200ResponseFromJSON,
    GetDocumentExclusionTerms200ResponseToJSON,
    UpdateDocumentExclusionTermRequestFromJSON,
    UpdateDocumentExclusionTermRequestToJSON,
} from '../models/index';

export interface CombineLeadsOperationRequest {
    combineLeadsRequest: CombineLeadsRequest;
}

export interface CreateDocumentExclusionTermOperationRequest {
    createDocumentExclusionTermRequest: CreateDocumentExclusionTermRequest;
}

export interface DeleteDocumentExclusionTermRequest {
    termId: string;
}

export interface GetDocumentExclusionTermsRequest {
    facilityId: string;
}

export interface UpdateDocumentExclusionTermOperationRequest {
    termId: string;
    updateDocumentExclusionTermRequest: UpdateDocumentExclusionTermRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Combine leads
     * Combine leads
     */
    async combineLeadsRaw(requestParameters: CombineLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CombineLeads200Response>> {
        if (requestParameters.combineLeadsRequest === null || requestParameters.combineLeadsRequest === undefined) {
            throw new runtime.RequiredError('combineLeadsRequest','Required parameter requestParameters.combineLeadsRequest was null or undefined when calling combineLeads.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/combine`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CombineLeadsRequestToJSON(requestParameters.combineLeadsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CombineLeads200ResponseFromJSON(jsonValue));
    }

    /**
     * Combine leads
     * Combine leads
     */
    async combineLeads(requestParameters: CombineLeadsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CombineLeads200Response> {
        const response = await this.combineLeadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new document exclusion term for a specific organization and facility
     * Create document exclusion term
     */
    async createDocumentExclusionTermRaw(requestParameters: CreateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentExclusionTerm201Response>> {
        if (requestParameters.createDocumentExclusionTermRequest === null || requestParameters.createDocumentExclusionTermRequest === undefined) {
            throw new runtime.RequiredError('createDocumentExclusionTermRequest','Required parameter requestParameters.createDocumentExclusionTermRequest was null or undefined when calling createDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-exclusion-terms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentExclusionTermRequestToJSON(requestParameters.createDocumentExclusionTermRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentExclusionTerm201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new document exclusion term for a specific organization and facility
     * Create document exclusion term
     */
    async createDocumentExclusionTerm(requestParameters: CreateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentExclusionTerm201Response> {
        const response = await this.createDocumentExclusionTermRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing document exclusion term
     * Delete document exclusion term
     */
    async deleteDocumentExclusionTermRaw(requestParameters: DeleteDocumentExclusionTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.termId === null || requestParameters.termId === undefined) {
            throw new runtime.RequiredError('termId','Required parameter requestParameters.termId was null or undefined when calling deleteDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-exclusion-terms/{term_id}`.replace(`{${"term_id"}}`, encodeURIComponent(String(requestParameters.termId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing document exclusion term
     * Delete document exclusion term
     */
    async deleteDocumentExclusionTerm(requestParameters: DeleteDocumentExclusionTermRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentExclusionTermRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve document exclusion terms for a facility
     * Get document exclusion terms
     */
    async getDocumentExclusionTermsRaw(requestParameters: GetDocumentExclusionTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentExclusionTerms200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getDocumentExclusionTerms.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-exclusion-terms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentExclusionTerms200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve document exclusion terms for a facility
     * Get document exclusion terms
     */
    async getDocumentExclusionTerms(requestParameters: GetDocumentExclusionTermsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentExclusionTerms200Response> {
        const response = await this.getDocumentExclusionTermsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing document exclusion term
     * Update document exclusion term
     */
    async updateDocumentExclusionTermRaw(requestParameters: UpdateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentExclusionTerm201Response>> {
        if (requestParameters.termId === null || requestParameters.termId === undefined) {
            throw new runtime.RequiredError('termId','Required parameter requestParameters.termId was null or undefined when calling updateDocumentExclusionTerm.');
        }

        if (requestParameters.updateDocumentExclusionTermRequest === null || requestParameters.updateDocumentExclusionTermRequest === undefined) {
            throw new runtime.RequiredError('updateDocumentExclusionTermRequest','Required parameter requestParameters.updateDocumentExclusionTermRequest was null or undefined when calling updateDocumentExclusionTerm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-exclusion-terms/{term_id}`.replace(`{${"term_id"}}`, encodeURIComponent(String(requestParameters.termId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentExclusionTermRequestToJSON(requestParameters.updateDocumentExclusionTermRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentExclusionTerm201ResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing document exclusion term
     * Update document exclusion term
     */
    async updateDocumentExclusionTerm(requestParameters: UpdateDocumentExclusionTermOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentExclusionTerm201Response> {
        const response = await this.updateDocumentExclusionTermRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
