/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileAttachment } from './FileAttachment';
import {
    FileAttachmentFromJSON,
    FileAttachmentFromJSONTyped,
    FileAttachmentToJSON,
} from './FileAttachment';

/**
 * 
 * @export
 * @interface EmailStep
 */
export interface EmailStep {
    /**
     * 
     * @type {string}
     * @memberof EmailStep
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailStep
     */
    body_html: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailStep
     */
    send_in_thread: boolean;
    /**
     * 
     * @type {Array<FileAttachment>}
     * @memberof EmailStep
     */
    attachments?: Array<FileAttachment>;
    /**
     * 
     * @type {object}
     * @memberof EmailStep
     */
    json_body?: object;
    /**
     * 
     * @type {boolean}
     * @memberof EmailStep
     */
    has_json_body?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailStep
     */
    send_time: string | null;
}

/**
 * Check if a given object implements the EmailStep interface.
 */
export function instanceOfEmailStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body_html" in value;
    isInstance = isInstance && "send_in_thread" in value;
    isInstance = isInstance && "send_time" in value;

    return isInstance;
}

export function EmailStepFromJSON(json: any): EmailStep {
    return EmailStepFromJSONTyped(json, false);
}

export function EmailStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body_html': json['body_html'],
        'send_in_thread': json['send_in_thread'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(FileAttachmentFromJSON)),
        'json_body': !exists(json, 'json_body') ? undefined : json['json_body'],
        'has_json_body': !exists(json, 'has_json_body') ? undefined : json['has_json_body'],
        'send_time': json['send_time'],
    };
}

export function EmailStepToJSON(value?: EmailStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'body_html': value.body_html,
        'send_in_thread': value.send_in_thread,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(FileAttachmentToJSON)),
        'json_body': value.json_body,
        'has_json_body': value.has_json_body,
        'send_time': value.send_time,
    };
}

