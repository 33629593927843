/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllRecurringPurchasesOtherResponse } from './FindAllRecurringPurchasesOtherResponse';
import {
    FindAllRecurringPurchasesOtherResponseFromJSON,
    FindAllRecurringPurchasesOtherResponseFromJSONTyped,
    FindAllRecurringPurchasesOtherResponseToJSON,
} from './FindAllRecurringPurchasesOtherResponse';

/**
 * 
 * @export
 * @interface FindAllRecurringPurchasesOther200Response
 */
export interface FindAllRecurringPurchasesOther200Response {
    /**
     * 
     * @type {Array<FindAllRecurringPurchasesOtherResponse>}
     * @memberof FindAllRecurringPurchasesOther200Response
     */
    data?: Array<FindAllRecurringPurchasesOtherResponse>;
}

/**
 * Check if a given object implements the FindAllRecurringPurchasesOther200Response interface.
 */
export function instanceOfFindAllRecurringPurchasesOther200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllRecurringPurchasesOther200ResponseFromJSON(json: any): FindAllRecurringPurchasesOther200Response {
    return FindAllRecurringPurchasesOther200ResponseFromJSONTyped(json, false);
}

export function FindAllRecurringPurchasesOther200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllRecurringPurchasesOther200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(FindAllRecurringPurchasesOtherResponseFromJSON)),
    };
}

export function FindAllRecurringPurchasesOther200ResponseToJSON(value?: FindAllRecurringPurchasesOther200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(FindAllRecurringPurchasesOtherResponseToJSON)),
    };
}

