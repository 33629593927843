/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileAttachment
 */
export interface FileAttachment {
    /**
     * 
     * @type {string}
     * @memberof FileAttachment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FileAttachment
     */
    s3_key: string;
}

/**
 * Check if a given object implements the FileAttachment interface.
 */
export function instanceOfFileAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "s3_key" in value;

    return isInstance;
}

export function FileAttachmentFromJSON(json: any): FileAttachment {
    return FileAttachmentFromJSONTyped(json, false);
}

export function FileAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        's3_key': json['s3_key'],
    };
}

export function FileAttachmentToJSON(value?: FileAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        's3_key': value.s3_key,
    };
}

