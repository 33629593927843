import React from 'react';
import { FieldArrayPath, UseFormReturn } from 'react-hook-form';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  styled,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { MedicationDoseSqlRow } from '@/stores/residentMedicationsAtom';

import { NumberOfDoseUnits } from '../MedicationDrawer/NumberOfDoseUnits';

import { CustomFrequencyDayForm } from './CustomFrequencyDayForm';
import { FrequencyTimeFormData, ScheduleFormData } from './FrequencyTimeForm';
import { WeeklyDayOption, weeklyDayOptions } from './options';

interface CustomFrequencyProps {
  formMethods: UseFormReturn<any>;
  isMedicationsDrawer: boolean;
  schedulePath: string;
  frequencyPath: string;
  disabled?: boolean;
}

export const CustomFrequency: React.FC<CustomFrequencyProps> = ({
  formMethods,
  isMedicationsDrawer = false,
  schedulePath,
  frequencyPath,
  disabled = false
}) => {
  const [scheduleHasSameDoseUnits, setScheduleHasSameDoseUnits] =
    React.useState(false);
  const [numberOfDoseUnits, dose] = formMethods.watch([
    `${frequencyPath}.number_of_dose_units`,
    'dose'
  ]);

  // This is to initialize the drawer with same number of dose units for
  // schedule checked off. The CustomFrequencyDayForm components handles the
  // logic for setting the individual FrequencyTimeFormData to the "master"
  // number of dose units
  React.useEffect(() => {
    const { scheduleHasSameDoseUnits, numberOfDoseUnits } =
      parseScheduleForAllSameDosage(
        formMethods.getValues(schedulePath) as ScheduleFormData,
        dose
      );
    setScheduleHasSameDoseUnits(scheduleHasSameDoseUnits);
    formMethods.setValue(
      `${frequencyPath}.number_of_dose_units`,
      numberOfDoseUnits
    );
  }, [setScheduleHasSameDoseUnits, dose]);

  // Initialize selectedDays with days that have a schedule in it
  const daysWithSelections = weeklyDayOptions.reduce((set, day) => {
    const scheduleFormDay = formMethods.getValues(
      `${schedulePath}.${day.label}`
    ) as Array<FrequencyTimeFormData>;

    if (scheduleFormDay.length > 0) {
      set.add(day);
    }

    return set;
  }, new Set<WeeklyDayOption>());

  // If no previous persisted form data exists, show Monday by default
  if (daysWithSelections.size === 0) {
    daysWithSelections.add(weeklyDayOptions[0]);
    // Add an empty frequency form to the day array
    formMethods.setValue(`${schedulePath}.${weeklyDayOptions[0].label}`, [
      { number_of_dose_units: numberOfDoseUnits } as FrequencyTimeFormData
    ]);
  }

  const [selectedDays, setSelectedDays] =
    React.useState<Set<WeeklyDayOption>>(daysWithSelections);

  const handleSelectDay = (option: WeeklyDayOption) => {
    const newSelectedDays = new Set(selectedDays);

    if (newSelectedDays.has(option)) {
      // Don't allow last day to be removed
      if (newSelectedDays.size > 1) {
        newSelectedDays.delete(option);
        // Clear all frequencies from the day array
        formMethods.setValue(`${schedulePath}.${option.label}`, []);
      }
    } else {
      newSelectedDays.add(option);
      // Add an empty frequency form to the day array
      formMethods.setValue(`${schedulePath}.${option.label}`, [
        { number_of_dose_units: numberOfDoseUnits } as FrequencyTimeFormData
      ]);
    }

    setSelectedDays(newSelectedDays);
  };

  return (
    <>
      {isMedicationsDrawer && (
        <>
          <Grid xs={12} justifyContent="flex-start">
            <FormGroup
              onKeyDown={(e) =>
                e.key === 'Enter' && setScheduleHasSameDoseUnits((old) => !old)
              }
              onChange={() => setScheduleHasSameDoseUnits((old) => !old)}>
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px'
                  }
                }}
                checked={scheduleHasSameDoseUnits}
                control={<Checkbox />}
                label="Same number of dosage units for schedule"
                disabled={disabled}
              />
            </FormGroup>
          </Grid>
        </>
      )}
      {scheduleHasSameDoseUnits && isMedicationsDrawer && (
        <NumberOfDoseUnits
          formMethods={formMethods}
          name={`${frequencyPath}.number_of_dose_units`}
        />
      )}
      <Grid xs={12} display="flex" gap={1}>
        {weeklyDayOptions.map((option) => (
          <DayChip
            key={option.value}
            label={option.label.substring(0, 3)}
            onClick={() => handleSelectDay(option)}
            selected={selectedDays.has(option)}
            disabled={disabled}
          />
        ))}
      </Grid>
      {weeklyDayOptions.map((dayOption) => {
        /**
         * The field array name passed as name prop to useFieldName
         * ${schedulePath}.monday
         * ${schedulePath}.tuesday
         * ${schedulePath}.wednesday
         * ...
         */
        const fieldArrayName =
          `${schedulePath}.${dayOption.label}` as FieldArrayPath<{
            schedule: ScheduleFormData;
          }>;

        return (
          selectedDays.has(dayOption) && (
            <Grid key={dayOption.label} xs={12}>
              <CustomFrequencyDayForm
                day={dayOption.label}
                fieldArrayName={fieldArrayName}
                formMethods={formMethods}
                scheduleHasSameDoseUnits={scheduleHasSameDoseUnits}
                numberOfDoseUnits={numberOfDoseUnits}
                isMedicationsDrawer={isMedicationsDrawer}
                disabled={disabled}
              />
            </Grid>
          )
        );
      })}
    </>
  );
};

const DayChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected: boolean }>(({ theme, selected }) => () => {
  const styles = selected
    ? {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
      }
    : {
        color: theme.palette.secondary.main,
        background: 'none',
        border: '1px solid rgba(0, 0, 0, 0.26)'
      };

  return {
    fontWeight: 500,
    width: 44,
    height: 44,
    borderRadius: '320px',
    '& .MuiChip-label': {
      overflow: 'unset',
      textOverflow: 'unset'
    },
    ...styles,
    ':hover': {
      cursor: 'pointer',
      ...styles
    },
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40
    }
  };
});

export const parseScheduleForAllSameDosage = (
  schedule: ScheduleFormData,
  dose: MedicationDoseSqlRow | null
) => {
  if (dose?.id === null) {
    return {
      scheduleHasSameDoseUnits: true,
      numberOfDoseUnits: null
    };
  }

  const doseUnitMap: Record<number, boolean> = {};
  (Object.keys(schedule) as Array<keyof ScheduleFormData>).forEach((day) => {
    schedule[day].forEach((item) => {
      if (item.number_of_dose_units !== null) {
        doseUnitMap[item.number_of_dose_units] = true;
      }
    });
  });
  if (Object.keys(doseUnitMap).length === 1) {
    return {
      scheduleHasSameDoseUnits: true,
      numberOfDoseUnits: Number(Object.keys(doseUnitMap)[0])
    };
  }
  return {
    scheduleHasSameDoseUnits: false,
    numberOfDoseUnits: 1
  };
};

/**
 * This function checks if all the schedules have the same dose_ranges values
 * and if they do, this slidign_scale_dose_range is returned
 * @param schedule
 * @returns
 */
export const getCommonSlidingScaleDoseForSchedules = (
  schedule: ScheduleFormData
) => {
  const allDaysSchedules: FrequencyTimeFormData[] = Object.keys(
    schedule
  ).flatMap((day) => schedule[day as keyof ScheduleFormData]);

  const rangesArrays = allDaysSchedules.map((item) =>
    item.dose_ranges
      ?.map((schedule) => ({
        vital_type_id: schedule.vital_type_id,
        from: schedule.from,
        to: schedule.to,
        comment: schedule.comment,
        number_of_dose_units: schedule.number_of_dose_units,
        blood_pressure_type: schedule.blood_pressure_type
      }))
      .sort((a, b) => parseFloat(a.from) - parseFloat(b.from))
  );

  // If there are no schedules with a sliding scale ar  uf any sliding scale
  // is null or undefined we can return null
  if (rangesArrays.some((ranges) => ranges == null) || !rangesArrays.length) {
    return null;
  }
  // Check if all schedules are equal
  const areAllSchedulesEqual = rangesArrays.every(
    (ranges, index, arrays) =>
      JSON.stringify(ranges) === JSON.stringify(arrays[0])
  );

  if (areAllSchedulesEqual) {
    return rangesArrays[0];
  }
  return null;
};
