import React, { useContext, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  AccessTime,
  Cancel,
  FileCopy,
  MedicalServices
} from '@mui/icons-material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Stack, styled } from '@mui/material';
import dayjs from 'dayjs';

import { DialogStep, DialogStepper } from '@/components/DialogStepper';
import {
  MedicationTaskPayload,
  useFindMedicationTaskById,
  useResidentMedications
} from '@/hooks/useResidentMedications';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { useFindAllResponsiblePartiesQuery } from '@/hooks/useResponsiblePartiesQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import { MedicationTaskModel } from '@/models/MedicationTaskModel';
import { TaskExtensionModel } from '@/models/TaskExtensionModel';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import { useMedicationTaskAudit } from '@/pages/ResidentPage/ResidentMedications/MedicationHistory/hooks/useMedicationTaskAudit';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { MedicationDetails } from './MedicationDetails/MedicationDetails';
import MedicationExtras from './MedicationExtras/MedicationExtras';
import { MedicationPrescription } from './MedicationPrescription/MedicationPrescription';
import { MedicationTime } from './MedicationTime/MedicationTime';
import { MedicationVitals } from './MedicationVitals/MedicationVitals';
import { showDuplicateMedicationDialog } from './ConfirmDuplicateMedicationDialog';
import { showSchedulesUpdateDialog } from './ConfirmSchedulesUpdateDialog';

export const prescriptionTypeOptions = [
  'Fax',
  'Email',
  'Handwritten',
  'Called-in',
  'Scanned'
];

export interface MedicationDrawerProps {
  residentId: string;
  medicationForm?: ResidentMedicationForm;
  type?: 'Edit' | 'Add' | 'Readonly';
  onClose: VoidFunction;
  moduleName?: 'confirmReceipt' | null;
  activeMedications?: MedicationTaskPayload[];
}

export const MedicationDrawer: React.FC<MedicationDrawerProps> = ({
  onClose,
  medicationForm,
  type,
  moduleName,
  residentId: residentIdProp,
  activeMedications
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const { invalidate: invalidateHistory } = useMedicationTaskAudit({
    medication_task_ids: medicationForm?.id
  });
  const steps: DialogStep[] = [
    {
      key: 0,
      label: 'Medication',
      Icon: MedicalServices
    },
    {
      key: 1,
      label: 'Time',
      Icon: AccessTime
    },
    {
      key: 2,
      label: 'Prescription Review',
      Icon: FileCopy
    },

    {
      key: 3,
      label: 'Extras',
      Icon: DashboardCustomizeIcon
    }
  ];
  const { data: responsibleParties, isFetching: isFetchingResponsibleParties } =
    useFindAllResponsiblePartiesQuery();
  const [mustGoNextAfterFirstStep, setMustGoNextAfterFirstStep] =
    React.useState<boolean>(false);
  const [step, setStep] = React.useState(0);
  const params = useParams();
  const residentId = residentIdProp ?? params.resident_id!;

  const { data: resident, isFetching: isFetchingResident } =
    useFindOneResidentQuery(
      {
        residentId: residentId!,
        timezone: true
      },
      { enabled: !!residentId }
    );
  const { showSnackbar } = useSnackbar();
  const formMethods = useForm<ResidentMedicationForm>({
    mode: 'all',
    defaultValues: {
      ...medicationForm,
      task_extension: TaskExtensionModel.toTaskExtensionForm(
        medicationForm?.task_extension
      )
    }
  });

  const { data: medicationTask, isFetching: isFetchingMedicationTask } =
    useFindMedicationTaskById().query(medicationForm?.id as string, {
      enabled: !!medicationForm?.id && type !== 'Readonly'
    });

  const { post, put } = useResidentMedications(residentId).mutations(
    medicationForm?.id
  );
  const mutation = type === 'Add' ? post : put;
  const doSave = async (
    values: ResidentMedicationForm,
    applySchedulesUpdateImmediately = false
  ) => {
    if (
      (formMethods.getValues('s3_prescription') ||
        formMethods.formState.isDirty) &&
      resident
    ) {
      const payload = MedicationTaskModel.toMedicationPayload(
        values,
        resident?.getTzid(),
        responsibleParties
      );
      if (type === 'Edit' && applySchedulesUpdateImmediately) {
        payload.applySchedulesUpdateImmediately = true;
      }
      mutation.mutate(payload, {
        onSuccess: () => {
          invalidateHistory();
          showSnackbar({
            severity: 'success',
            message: `Successfully ${type === 'Add' ? 'added' : 'edited'} ${
              values.medication?.drug_name || 'medication'
            }`
          });
          onClose();
        },
        onError: () => {
          showSnackbar({
            severity: 'error',
            message: `Error ${type === 'Add' ? 'adding' : 'editing'} ${
              values.medication?.drug_name || 'medication'
            }`
          });
        }
      });
    } else {
      showSnackbar({
        severity: 'success',
        message: `Successfully ${type === 'Add' ? 'added' : 'edited'} ${
          values.medication?.drug_name || 'medication'
        }`
      });
      onClose();
    }
  };
  const hasDuplicateActiveMedication = (
    fdbDrugId: string | null,
    customMedicationId: string | null
  ) => {
    if (!activeMedications || type !== 'Add') return false;

    return activeMedications.some((med) => {
      return (
        (fdbDrugId && med.fdb_dispensable_drug_id === fdbDrugId) ||
        (customMedicationId && med.medication_id === customMedicationId)
      );
    });
  };

  const getLatestTaskInstanceScheduledForFuture = React.useCallback(() => {
    const taskInstances = medicationTask?.medication_task_instances ?? [];
    const today = dayjs().startOf('day');
    return taskInstances
      .filter((task) => {
        const taskDate = dayjs(task.date).startOf('day');
        return taskDate.isSame(today) || taskDate.isAfter(today);
      })
      .sort((a, b) => {
        return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
      })
      .pop();
  }, [medicationTask]);

  const { isDirty: regimensFieldChanged } = useFormState({
    control: formMethods.control,
    name: 'regimens'
  });

  const handleSave = React.useMemo(
    () =>
      formMethods.handleSubmit(async (values) => {
        if (
          hasDuplicateActiveMedication(
            values.DispensableDrugID,
            values.medication_id
          )
        ) {
          showDuplicateMedicationDialog({
            drugName: values.medication?.drug_name || 'medication',
            residentName: resident?.getFullName() ?? 'Resident',
            onConfirm: () => {
              doSave(values);
            },
            onCancel: () => {
              onClose();
            }
          });
          return;
        }

        if (type === 'Edit' && regimensFieldChanged) {
          const taskInstance = getLatestTaskInstanceScheduledForFuture();
          if (taskInstance) {
            showSchedulesUpdateDialog({
              taskInstance: taskInstance,
              medicationName: values.medication?.drug_name,
              onConfirm: () => {
                doSave(values);
              },
              onApplyImmediately: () => {
                doSave(values, true);
              }
            });
            return;
          }
        }

        doSave(values);
      }),
    [
      formMethods.formState.isDirty,
      resident,
      responsibleParties,
      regimensFieldChanged
    ]
  );

  // The next button that shows for all drawer pages when editing a med task.
  // If we're adding a new med task then each page renders their own med task
  // task drawer of which is hidden if editing. We should call the
  // react-hook-form handle submit function to validate the currently rendered
  // form as well
  const handleEditHandleNext = formMethods.handleSubmit(() => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      handleSave();
    }
  });

  const handleStepClick =
    type === 'Edit' && !mustGoNextAfterFirstStep
      ? // Validate rendered form before jumping steps
        (step: number) => formMethods.handleSubmit(() => setStep(step))()
      : undefined;

  useEffect(() => {
    if (
      !responsibleParties ||
      responsibleParties.length === 0 ||
      type === 'Edit'
    )
      return;
    const defaultParty = responsibleParties?.find(
      (rp) => rp.default_for_medication === true
    );
    if (defaultParty) {
      formMethods.setValue('responsible_party', defaultParty.id);
    }
  }, [responsibleParties]);

  const isMedicationsFormValid = useMemo(() => {
    return formMethods.formState.isValid;
  }, [formMethods.formState.isValid]);

  return (
    <>
      <Stack component="header">
        {moduleName == 'confirmReceipt'
          ? 'Confirm Receipt of New Prescription'
          : `${type !== 'Readonly' ? type : 'Inactive'} Medication`}
        <IconButton onClick={onClose}>
          <Cancel />
        </IconButton>
      </Stack>
      <DialogStepper
        steps={steps}
        step={step}
        handleStepClick={handleStepClick}
      />
      <Stack
        className="drawer-paper-content"
        gap={2}
        justifyContent="space-between"
        flex={1}>
        {step === 0 && (
          <MedicationDetails
            residentId={residentId}
            residentName={resident?.getFullName()}
            formMethods={formMethods}
            disableEdit={
              (type === 'Edit' && !medicationForm?.is_editable) ||
              type === 'Readonly'
            }
            handleNext={() => {
              if (isMedicationsFormValid || type === 'Readonly') {
                setStep(1);
              } else {
                formMethods.trigger();
              }
            }}
            handleClose={onClose}
            type={type}
            responsibleParties={responsibleParties}
            onResponsiblePartyChanged={(hasChanged) =>
              setMustGoNextAfterFirstStep(hasChanged)
            }
            activeMedications={activeMedications}
          />
        )}
        {step === 1 && (
          <MedicationTime
            residentId={residentIdProp}
            formMethods={formMethods}
            handleBack={() => setStep(0)}
            handleNext={() => {
              if (type === 'Readonly') {
                setStep(2);
              } else {
                formMethods.handleSubmit(() => setStep(2))();
              }
            }}
            type={type}
            isStartTimeEditable={medicationForm?.is_editable}
            shouldShowDirectionsRecommendations={false}
            responsibleParties={responsibleParties}
          />
        )}
        {step === 2 && (
          <MedicationPrescription
            residentId={residentIdProp}
            formMethods={formMethods}
            handleBack={() => setStep(1)}
            handleNext={() => setStep(3)}
            isSaving={mutation.isLoading}
            disableEdit={isResidentArchived || type === 'Readonly'}
            type={type}
          />
        )}
        {step === 3 &&
          (FeatureFlagService.isEnabled(ExacareFeature.EHR_TASK_EXTENSIONS) ? (
            <MedicationExtras
              formMethods={formMethods}
              handleBack={() => setStep(2)}
              handleToDetailsLink={() => setStep(0)}
              handleToTimeLink={() => setStep(1)}
              handleSave={handleSave}
              isSaving={mutation.isLoading}
              responsibleParties={responsibleParties}
              type={type}
            />
          ) : (
            <MedicationVitals
              formMethods={formMethods}
              handleBack={() => setStep(2)}
              handleToDetailsLink={() => setStep(0)}
              handleToTimeLink={() => setStep(1)}
              handleSave={handleSave}
              isSaving={mutation.isLoading}
              responsibleParties={responsibleParties}
              type={type}
            />
          ))}
        {type === 'Edit' && (
          <DrawerFooter
            sx={{
              justifyContent: 'space-between'
            }}>
            <Stack direction="row" gap={2} alignItems="center">
              {step === 0 && (
                <Button variant="outlined" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              )}
              {step > 0 && (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => setStep(step - 1)}>
                  Back
                </Button>
              )}
              {step < steps.length - 1 && (
                <Button
                  disabled={
                    Object.keys(formMethods.formState.errors).length > 0
                  }
                  color="secondary"
                  variant="contained"
                  onClick={handleEditHandleNext}>
                  Next
                </Button>
              )}
            </Stack>
            {(step != 0 || !mustGoNextAfterFirstStep) && !isResidentArchived && (
              <LoadingButton
                data-testid="medication-drawer-save-button"
                disabled={
                  isFetchingResident ||
                  isFetchingResponsibleParties ||
                  isFetchingMedicationTask
                }
                loading={mutation.isLoading}
                onClick={handleSave}
                color="primary"
                variant="contained">
                Save
              </LoadingButton>
            )}
          </DrawerFooter>
        )}
      </Stack>
    </>
  );
};

export const DrawerFooter = styled('footer')(({ theme }) => ({
  background: '#FCFEFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minHeight: 90,
  borderTop: '1px solid #E0F3FF',
  padding: '0 24px',
  margin: 'auto -24px 0 -24px',
  gap: '8px',
  '& .MuiButton-root': {
    minWidth: 134
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 80,
    width: '100vw',
    padding: '0 16px',
    margin: 'auto 0 0 -16px',
    '& .MuiButton-root': {
      minWidth: 'unset'
    }
  }
}));
