import React from 'react';
import { isMobile } from 'react-device-detect';
import debounce from 'lodash/debounce';

// For iOS Safari, special logic is required to resize the layout container
// to the window's inner height. It is not enough to set the height to 100vh
// because the browser's address bar is not included in the viewport height.
// A resize event is also triggered when the device orientation changes.
export const useMobileResizeHeight = (
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const handleMobileResizeHeight = () => {
    if (containerRef?.current) {
      containerRef.current.style.height = `${window.innerHeight}px`;
    }
  };
  const debouncedHandleMobileResizeHeight = debounce(
    handleMobileResizeHeight,
    250
  );

  React.useEffect(() => {
    if (containerRef?.current && isMobile) {
      window.addEventListener('resize', debouncedHandleMobileResizeHeight);
    }
    return () =>
      window.removeEventListener('resize', debouncedHandleMobileResizeHeight);
  }, [containerRef]);
};
