/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PtOtCaseMixGroupScoreRange } from './PtOtCaseMixGroupScoreRange';
import {
    PtOtCaseMixGroupScoreRangeFromJSON,
    PtOtCaseMixGroupScoreRangeFromJSONTyped,
    PtOtCaseMixGroupScoreRangeToJSON,
} from './PtOtCaseMixGroupScoreRange';
import type { PtOtClinicalCategory } from './PtOtClinicalCategory';
import {
    PtOtClinicalCategoryFromJSON,
    PtOtClinicalCategoryFromJSONTyped,
    PtOtClinicalCategoryToJSON,
} from './PtOtClinicalCategory';

/**
 * 
 * @export
 * @interface PtOtClinicalCategoryToCaseMixGroupMapping
 */
export interface PtOtClinicalCategoryToCaseMixGroupMapping {
    /**
     * 
     * @type {PtOtClinicalCategory}
     * @memberof PtOtClinicalCategoryToCaseMixGroupMapping
     */
    clinical_category: PtOtClinicalCategory;
    /**
     * 
     * @type {Array<PtOtCaseMixGroupScoreRange>}
     * @memberof PtOtClinicalCategoryToCaseMixGroupMapping
     */
    case_mix_groups: Array<PtOtCaseMixGroupScoreRange>;
}

/**
 * Check if a given object implements the PtOtClinicalCategoryToCaseMixGroupMapping interface.
 */
export function instanceOfPtOtClinicalCategoryToCaseMixGroupMapping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clinical_category" in value;
    isInstance = isInstance && "case_mix_groups" in value;

    return isInstance;
}

export function PtOtClinicalCategoryToCaseMixGroupMappingFromJSON(json: any): PtOtClinicalCategoryToCaseMixGroupMapping {
    return PtOtClinicalCategoryToCaseMixGroupMappingFromJSONTyped(json, false);
}

export function PtOtClinicalCategoryToCaseMixGroupMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtClinicalCategoryToCaseMixGroupMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinical_category': PtOtClinicalCategoryFromJSON(json['clinical_category']),
        'case_mix_groups': ((json['case_mix_groups'] as Array<any>).map(PtOtCaseMixGroupScoreRangeFromJSON)),
    };
}

export function PtOtClinicalCategoryToCaseMixGroupMappingToJSON(value?: PtOtClinicalCategoryToCaseMixGroupMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinical_category': PtOtClinicalCategoryToJSON(value.clinical_category),
        'case_mix_groups': ((value.case_mix_groups as Array<any>).map(PtOtCaseMixGroupScoreRangeToJSON)),
    };
}

