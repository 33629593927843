/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanEntryTemplateSchedule } from './CarePlanEntryTemplateSchedule';
import {
    CarePlanEntryTemplateScheduleFromJSON,
    CarePlanEntryTemplateScheduleFromJSONTyped,
    CarePlanEntryTemplateScheduleToJSON,
} from './CarePlanEntryTemplateSchedule';
import type { CarePlanEntryTemplateTaskExtension } from './CarePlanEntryTemplateTaskExtension';
import {
    CarePlanEntryTemplateTaskExtensionFromJSON,
    CarePlanEntryTemplateTaskExtensionFromJSONTyped,
    CarePlanEntryTemplateTaskExtensionToJSON,
} from './CarePlanEntryTemplateTaskExtension';

/**
 * 
 * @export
 * @interface CarePlanEntryTemplate
 */
export interface CarePlanEntryTemplate {
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    care_category_id: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    goal: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarePlanEntryTemplate
     */
    interventions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    care_plan_assistance_level_id: string;
    /**
     * 
     * @type {number}
     * @memberof CarePlanEntryTemplate
     */
    number_of_assistants: number;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    responsible_party_id: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    care_description: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    preferences?: string;
    /**
     * 
     * @type {number}
     * @memberof CarePlanEntryTemplate
     */
    length_mins?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarePlanEntryTemplate
     */
    is_informational?: boolean;
    /**
     * 
     * @type {Array<CarePlanEntryTemplateSchedule>}
     * @memberof CarePlanEntryTemplate
     */
    schedules?: Array<CarePlanEntryTemplateSchedule>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarePlanEntryTemplate
     */
    care_package_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntryTemplate
     */
    ng_repository_entry_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarePlanEntryTemplate
     */
    task_extension_enabled: boolean;
    /**
     * 
     * @type {CarePlanEntryTemplateTaskExtension}
     * @memberof CarePlanEntryTemplate
     */
    task_extension?: CarePlanEntryTemplateTaskExtension;
}

/**
 * Check if a given object implements the CarePlanEntryTemplate interface.
 */
export function instanceOfCarePlanEntryTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "care_category_id" in value;
    isInstance = isInstance && "goal" in value;
    isInstance = isInstance && "care_plan_assistance_level_id" in value;
    isInstance = isInstance && "number_of_assistants" in value;
    isInstance = isInstance && "responsible_party_id" in value;
    isInstance = isInstance && "care_description" in value;
    isInstance = isInstance && "task_extension_enabled" in value;

    return isInstance;
}

export function CarePlanEntryTemplateFromJSON(json: any): CarePlanEntryTemplate {
    return CarePlanEntryTemplateFromJSONTyped(json, false);
}

export function CarePlanEntryTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanEntryTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'care_category_id': json['care_category_id'],
        'goal': json['goal'],
        'interventions': !exists(json, 'interventions') ? undefined : json['interventions'],
        'care_plan_assistance_level_id': json['care_plan_assistance_level_id'],
        'number_of_assistants': json['number_of_assistants'],
        'responsible_party_id': json['responsible_party_id'],
        'care_description': json['care_description'],
        'preferences': !exists(json, 'preferences') ? undefined : json['preferences'],
        'length_mins': !exists(json, 'length_mins') ? undefined : json['length_mins'],
        'is_informational': !exists(json, 'is_informational') ? undefined : json['is_informational'],
        'schedules': !exists(json, 'schedules') ? undefined : ((json['schedules'] as Array<any>).map(CarePlanEntryTemplateScheduleFromJSON)),
        'care_package_ids': !exists(json, 'care_package_ids') ? undefined : json['care_package_ids'],
        'ng_repository_entry_id': !exists(json, 'ng_repository_entry_id') ? undefined : json['ng_repository_entry_id'],
        'task_extension_enabled': json['task_extension_enabled'],
        'task_extension': !exists(json, 'task_extension') ? undefined : CarePlanEntryTemplateTaskExtensionFromJSON(json['task_extension']),
    };
}

export function CarePlanEntryTemplateToJSON(value?: CarePlanEntryTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'care_category_id': value.care_category_id,
        'goal': value.goal,
        'interventions': value.interventions,
        'care_plan_assistance_level_id': value.care_plan_assistance_level_id,
        'number_of_assistants': value.number_of_assistants,
        'responsible_party_id': value.responsible_party_id,
        'care_description': value.care_description,
        'preferences': value.preferences,
        'length_mins': value.length_mins,
        'is_informational': value.is_informational,
        'schedules': value.schedules === undefined ? undefined : ((value.schedules as Array<any>).map(CarePlanEntryTemplateScheduleToJSON)),
        'care_package_ids': value.care_package_ids,
        'ng_repository_entry_id': value.ng_repository_entry_id,
        'task_extension_enabled': value.task_extension_enabled,
        'task_extension': CarePlanEntryTemplateTaskExtensionToJSON(value.task_extension),
    };
}

