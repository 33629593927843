interface TagTypeChipProps {
  label: string;
}

export const TagTypeToChipMapping: {
  [key: number]: (props: TagTypeChipProps) => JSX.Element;
} = {
  1: (props: TagTypeChipProps) => (
    <Chip
      sx={{ height: '24px', fontWeight: 500, backgroundColor: '#00000014' }}
      label={props.label}
    />
  ),
  2: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        height: '24px',
        fontWeight: 500,
        backgroundColor: '#E7EEF4',
        border: 'none'
      }}
      color="primary"
      variant="outlined"
      label={props.label}
    />
  ),
  3: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        height: '24px',
        fontWeight: 500,
        backgroundColor: '#EAF2EA',
        border: 'none',
        color: '#569E59'
      }}
      label={props.label}
    />
  ),
  4: (props: TagTypeChipProps) => (
    <Chip
      color="warning"
      sx={{
        height: '24px',
        fontWeight: 500,
        backgroundColor: '#FFE9D4',
        border: 'none'
      }}
      variant="outlined"
      label={props.label}
    />
  ),
  5: (props: TagTypeChipProps) => (
    <Chip
      sx={{
        height: '24px',
        fontWeight: 500,
        backgroundColor: '#FFDAD4',
        border: 'none',
        color: '#BA1B1B'
      }}
      variant="outlined"
      label={props.label}
    />
  ),
  6: (props: TagTypeChipProps) => (
    <Chip
      sx={{ height: '24px', fontWeight: 500 }}
      color="primary"
      label={props.label}
    />
  ),
  7: (props: TagTypeChipProps) => (
    <Chip
      sx={{ height: '24px', fontWeight: 500 }}
      color="success"
      label={props.label}
    />
  ),
  8: (props: TagTypeChipProps) => (
    <Chip
      sx={{ height: '24px', fontWeight: 500 }}
      color="warning"
      label={props.label}
    />
  ),
  9: (props: TagTypeChipProps) => (
    <Chip
      sx={{ height: '24px', fontWeight: 500 }}
      color="error"
      label={props.label}
    />
  )
};

import React from 'react';
import { Chip } from '@mui/material';

export default function TagChip({
  type,
  label
}: {
  type: number;
  label: string;
}) {
  const TagTypeChip = TagTypeToChipMapping[type];
  if (!TagTypeChip) {
    return null;
  }
  return (
    <TagTypeChip
      {...{
        label
      }}
    />
  );
}
