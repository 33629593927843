/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddRemoveAudienceMembersBulkRequest,
  Audience,
  CampaignAudience,
  CampaignStep,
  CreateAudience201Response,
  CreateCampaignAudience201Response,
  CreateCampaignStep201Response,
  CreateTaskTemplate201Response,
  CreateTextTemplate201Response,
  FindAllAudienceMembers200Response,
  FindAllAudiences200Response,
  FindAllCampaignSteps200Response,
  FindAllCrmContactsCloseConnections200Response,
  FindAllCrmContactsProspects200Response,
  FindAllCrmContactsReferralSources200Response,
  FindAllCrmContactsResidents200Response,
  FindAllTaskTemplates200Response,
  FindAllTaskTypes200Response,
  FindAllTextTemplateTypes200Response,
  FindAllTextTemplates200Response,
  FindCampaignAudiences200Response,
  GetCampaignStats200Response,
  TaskTemplate,
  TextTemplate,
} from '../models/index';
import {
    AddRemoveAudienceMembersBulkRequestFromJSON,
    AddRemoveAudienceMembersBulkRequestToJSON,
    AudienceFromJSON,
    AudienceToJSON,
    CampaignAudienceFromJSON,
    CampaignAudienceToJSON,
    CampaignStepFromJSON,
    CampaignStepToJSON,
    CreateAudience201ResponseFromJSON,
    CreateAudience201ResponseToJSON,
    CreateCampaignAudience201ResponseFromJSON,
    CreateCampaignAudience201ResponseToJSON,
    CreateCampaignStep201ResponseFromJSON,
    CreateCampaignStep201ResponseToJSON,
    CreateTaskTemplate201ResponseFromJSON,
    CreateTaskTemplate201ResponseToJSON,
    CreateTextTemplate201ResponseFromJSON,
    CreateTextTemplate201ResponseToJSON,
    FindAllAudienceMembers200ResponseFromJSON,
    FindAllAudienceMembers200ResponseToJSON,
    FindAllAudiences200ResponseFromJSON,
    FindAllAudiences200ResponseToJSON,
    FindAllCampaignSteps200ResponseFromJSON,
    FindAllCampaignSteps200ResponseToJSON,
    FindAllCrmContactsCloseConnections200ResponseFromJSON,
    FindAllCrmContactsCloseConnections200ResponseToJSON,
    FindAllCrmContactsProspects200ResponseFromJSON,
    FindAllCrmContactsProspects200ResponseToJSON,
    FindAllCrmContactsReferralSources200ResponseFromJSON,
    FindAllCrmContactsReferralSources200ResponseToJSON,
    FindAllCrmContactsResidents200ResponseFromJSON,
    FindAllCrmContactsResidents200ResponseToJSON,
    FindAllTaskTemplates200ResponseFromJSON,
    FindAllTaskTemplates200ResponseToJSON,
    FindAllTaskTypes200ResponseFromJSON,
    FindAllTaskTypes200ResponseToJSON,
    FindAllTextTemplateTypes200ResponseFromJSON,
    FindAllTextTemplateTypes200ResponseToJSON,
    FindAllTextTemplates200ResponseFromJSON,
    FindAllTextTemplates200ResponseToJSON,
    FindCampaignAudiences200ResponseFromJSON,
    FindCampaignAudiences200ResponseToJSON,
    GetCampaignStats200ResponseFromJSON,
    GetCampaignStats200ResponseToJSON,
    TaskTemplateFromJSON,
    TaskTemplateToJSON,
    TextTemplateFromJSON,
    TextTemplateToJSON,
} from '../models/index';

export interface AddRemoveAudienceMembersBulkOperationRequest {
    audienceId: string;
    addRemoveAudienceMembersBulkRequest: AddRemoveAudienceMembersBulkRequest;
}

export interface CreateAudienceRequest {
    audience: Audience;
}

export interface CreateCampaignAudienceRequest {
    campaignId: string;
    campaignAudience: CampaignAudience;
}

export interface CreateCampaignStepRequest {
    campaignId: string;
    campaignStep: CampaignStep;
}

export interface CreateTaskTemplateRequest {
    taskTemplate: TaskTemplate;
}

export interface CreateTextTemplateRequest {
    textTemplate: TextTemplate;
}

export interface DeleteAudienceByIdRequest {
    audienceId: string;
}

export interface DeleteCampaignAudienceRequest {
    campaignId: string;
    campaignAudienceId: string;
}

export interface DeleteCampaignStepByIdRequest {
    campaignId: string;
    stepId: string;
}

export interface DeleteTaskTemplateByIdRequest {
    templateId: string;
}

export interface DeleteTextTemplateByIdRequest {
    templateId: string;
}

export interface FindAllAudienceMembersRequest {
    audienceId: string;
    relationTypes?: string;
    limit?: string;
    offset?: string;
    search?: string;
}

export interface FindAllAudiencesRequest {
    facilityId?: string;
}

export interface FindAllCampaignStepsRequest {
    campaignId: string;
}

export interface FindAllCrmContactsCloseConnectionsRequest {
    facilityId: string;
    type: FindAllCrmContactsCloseConnectionsTypeEnum;
    limit?: string;
    offset?: string;
    audienceId?: string;
    dealStage?: string;
    dealTemperature?: string;
    membersOnly?: string;
    search?: string;
    flags?: string;
}

export interface FindAllCrmContactsProspectsRequest {
    facilityId: string;
    limit?: string;
    offset?: string;
    audienceId?: string;
    dealStage?: string;
    dealTemperature?: string;
    membersOnly?: string;
    search?: string;
}

export interface FindAllCrmContactsReferralSourcesRequest {
    facilityId: string;
    limit?: string;
    offset?: string;
    audienceId?: string;
    companyName?: string;
    lifecycleStep?: string;
    membersOnly?: string;
    search?: string;
}

export interface FindAllCrmContactsResidentsRequest {
    facilityId: string;
    limit?: string;
    offset?: string;
    audienceId?: string;
    residentGroup?: string;
    search?: string;
    membersOnly?: string;
}

export interface FindAllTaskTemplatesRequest {
    facilityId?: string;
}

export interface FindAllTextTemplatesRequest {
    facilityId?: string;
}

export interface FindAudienceByIdRequest {
    audienceId: string;
}

export interface FindCampaignAudiencesRequest {
    campaignId: string;
}

export interface FindCampaignStepByIdRequest {
    campaignId: string;
    stepId: string;
}

export interface FindTaskTemplateByIdRequest {
    templateId: string;
}

export interface FindTextTemplateByIdRequest {
    templateId: string;
}

export interface GetCampaignStatsRequest {
    campaignId: string;
}

export interface RemoveAudienceMemberRequest {
    audienceId: string;
    memberId: string;
}

export interface UpdateAudienceByIdRequest {
    audienceId: string;
    audience: Audience;
}

export interface UpdateCampaignStepByIdRequest {
    campaignId: string;
    stepId: string;
    campaignStep: CampaignStep;
}

export interface UpdateTaskTemplateByIdRequest {
    templateId: string;
    taskTemplate: TaskTemplate;
}

export interface UpdateTextTemplateByIdRequest {
    templateId: string;
    textTemplate: TextTemplate;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add/Remove audience members in bulk
     */
    async addRemoveAudienceMembersBulkRaw(requestParameters: AddRemoveAudienceMembersBulkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling addRemoveAudienceMembersBulk.');
        }

        if (requestParameters.addRemoveAudienceMembersBulkRequest === null || requestParameters.addRemoveAudienceMembersBulkRequest === undefined) {
            throw new runtime.RequiredError('addRemoveAudienceMembersBulkRequest','Required parameter requestParameters.addRemoveAudienceMembersBulkRequest was null or undefined when calling addRemoveAudienceMembersBulk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/audiences/{audience_id}/members-bulk`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRemoveAudienceMembersBulkRequestToJSON(requestParameters.addRemoveAudienceMembersBulkRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add/Remove audience members in bulk
     */
    async addRemoveAudienceMembersBulk(requestParameters: AddRemoveAudienceMembersBulkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addRemoveAudienceMembersBulkRaw(requestParameters, initOverrides);
    }

    /**
     * Create audience
     */
    async createAudienceRaw(requestParameters: CreateAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAudience201Response>> {
        if (requestParameters.audience === null || requestParameters.audience === undefined) {
            throw new runtime.RequiredError('audience','Required parameter requestParameters.audience was null or undefined when calling createAudience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/audiences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AudienceToJSON(requestParameters.audience),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAudience201ResponseFromJSON(jsonValue));
    }

    /**
     * Create audience
     */
    async createAudience(requestParameters: CreateAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAudience201Response> {
        const response = await this.createAudienceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Attach audience to campaign
     */
    async createCampaignAudienceRaw(requestParameters: CreateCampaignAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCampaignAudience201Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling createCampaignAudience.');
        }

        if (requestParameters.campaignAudience === null || requestParameters.campaignAudience === undefined) {
            throw new runtime.RequiredError('campaignAudience','Required parameter requestParameters.campaignAudience was null or undefined when calling createCampaignAudience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/campaigns/{campaign_id}/audiences`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignAudienceToJSON(requestParameters.campaignAudience),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCampaignAudience201ResponseFromJSON(jsonValue));
    }

    /**
     * Attach audience to campaign
     */
    async createCampaignAudience(requestParameters: CreateCampaignAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCampaignAudience201Response> {
        const response = await this.createCampaignAudienceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create campaign step
     */
    async createCampaignStepRaw(requestParameters: CreateCampaignStepRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCampaignStep201Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling createCampaignStep.');
        }

        if (requestParameters.campaignStep === null || requestParameters.campaignStep === undefined) {
            throw new runtime.RequiredError('campaignStep','Required parameter requestParameters.campaignStep was null or undefined when calling createCampaignStep.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/campaigns/{campaign_id}/steps`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignStepToJSON(requestParameters.campaignStep),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCampaignStep201ResponseFromJSON(jsonValue));
    }

    /**
     * Create campaign step
     */
    async createCampaignStep(requestParameters: CreateCampaignStepRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCampaignStep201Response> {
        const response = await this.createCampaignStepRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create task template
     */
    async createTaskTemplateRaw(requestParameters: CreateTaskTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTaskTemplate201Response>> {
        if (requestParameters.taskTemplate === null || requestParameters.taskTemplate === undefined) {
            throw new runtime.RequiredError('taskTemplate','Required parameter requestParameters.taskTemplate was null or undefined when calling createTaskTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/task-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskTemplateToJSON(requestParameters.taskTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaskTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create task template
     */
    async createTaskTemplate(requestParameters: CreateTaskTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTaskTemplate201Response> {
        const response = await this.createTaskTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create text template
     */
    async createTextTemplateRaw(requestParameters: CreateTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTextTemplate201Response>> {
        if (requestParameters.textTemplate === null || requestParameters.textTemplate === undefined) {
            throw new runtime.RequiredError('textTemplate','Required parameter requestParameters.textTemplate was null or undefined when calling createTextTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/text-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TextTemplateToJSON(requestParameters.textTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTextTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create text template
     */
    async createTextTemplate(requestParameters: CreateTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTextTemplate201Response> {
        const response = await this.createTextTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete audience by id
     */
    async deleteAudienceByIdRaw(requestParameters: DeleteAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling deleteAudienceById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audiences/{audience_id}`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete audience by id
     */
    async deleteAudienceById(requestParameters: DeleteAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAudienceByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Detach audience from campaign
     */
    async deleteCampaignAudienceRaw(requestParameters: DeleteCampaignAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling deleteCampaignAudience.');
        }

        if (requestParameters.campaignAudienceId === null || requestParameters.campaignAudienceId === undefined) {
            throw new runtime.RequiredError('campaignAudienceId','Required parameter requestParameters.campaignAudienceId was null or undefined when calling deleteCampaignAudience.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/audiences/{campaign_audience_id}`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))).replace(`{${"campaign_audience_id"}}`, encodeURIComponent(String(requestParameters.campaignAudienceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detach audience from campaign
     */
    async deleteCampaignAudience(requestParameters: DeleteCampaignAudienceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCampaignAudienceRaw(requestParameters, initOverrides);
    }

    /**
     * Delete campaign step by id
     */
    async deleteCampaignStepByIdRaw(requestParameters: DeleteCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling deleteCampaignStepById.');
        }

        if (requestParameters.stepId === null || requestParameters.stepId === undefined) {
            throw new runtime.RequiredError('stepId','Required parameter requestParameters.stepId was null or undefined when calling deleteCampaignStepById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/steps/{step_id}`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))).replace(`{${"step_id"}}`, encodeURIComponent(String(requestParameters.stepId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete campaign step by id
     */
    async deleteCampaignStepById(requestParameters: DeleteCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCampaignStepByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete task template by id
     */
    async deleteTaskTemplateByIdRaw(requestParameters: DeleteTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling deleteTaskTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete task template by id
     */
    async deleteTaskTemplateById(requestParameters: DeleteTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTaskTemplateByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete text template by id
     */
    async deleteTextTemplateByIdRaw(requestParameters: DeleteTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling deleteTextTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/text-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete text template by id
     */
    async deleteTextTemplateById(requestParameters: DeleteTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTextTemplateByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get all audience members
     */
    async findAllAudienceMembersRaw(requestParameters: FindAllAudienceMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllAudienceMembers200Response>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling findAllAudienceMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.relationTypes !== undefined) {
            queryParameters['relation_types'] = requestParameters.relationTypes;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audiences/{audience_id}/members`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllAudienceMembers200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all audience members
     */
    async findAllAudienceMembers(requestParameters: FindAllAudienceMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllAudienceMembers200Response> {
        const response = await this.findAllAudienceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all audiences
     */
    async findAllAudiencesRaw(requestParameters: FindAllAudiencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllAudiences200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audiences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllAudiences200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all audiences
     */
    async findAllAudiences(requestParameters: FindAllAudiencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllAudiences200Response> {
        const response = await this.findAllAudiencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all campaign steps
     */
    async findAllCampaignStepsRaw(requestParameters: FindAllCampaignStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCampaignSteps200Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling findAllCampaignSteps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/steps`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCampaignSteps200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all campaign steps
     */
    async findAllCampaignSteps(requestParameters: FindAllCampaignStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCampaignSteps200Response> {
        const response = await this.findAllCampaignStepsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all close connections
     */
    async findAllCrmContactsCloseConnectionsRaw(requestParameters: FindAllCrmContactsCloseConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCrmContactsCloseConnections200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllCrmContactsCloseConnections.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling findAllCrmContactsCloseConnections.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.audienceId !== undefined) {
            queryParameters['audience_id'] = requestParameters.audienceId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.dealStage !== undefined) {
            queryParameters['deal_stage'] = requestParameters.dealStage;
        }

        if (requestParameters.dealTemperature !== undefined) {
            queryParameters['deal_temperature'] = requestParameters.dealTemperature;
        }

        if (requestParameters.membersOnly !== undefined) {
            queryParameters['members_only'] = requestParameters.membersOnly;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.flags !== undefined) {
            queryParameters['flags'] = requestParameters.flags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/crm-contacts/close-connections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCrmContactsCloseConnections200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all close connections
     */
    async findAllCrmContactsCloseConnections(requestParameters: FindAllCrmContactsCloseConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCrmContactsCloseConnections200Response> {
        const response = await this.findAllCrmContactsCloseConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all prospects
     */
    async findAllCrmContactsProspectsRaw(requestParameters: FindAllCrmContactsProspectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCrmContactsProspects200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllCrmContactsProspects.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.audienceId !== undefined) {
            queryParameters['audience_id'] = requestParameters.audienceId;
        }

        if (requestParameters.dealStage !== undefined) {
            queryParameters['deal_stage'] = requestParameters.dealStage;
        }

        if (requestParameters.dealTemperature !== undefined) {
            queryParameters['deal_temperature'] = requestParameters.dealTemperature;
        }

        if (requestParameters.membersOnly !== undefined) {
            queryParameters['members_only'] = requestParameters.membersOnly;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/crm-contacts/prospects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCrmContactsProspects200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all prospects
     */
    async findAllCrmContactsProspects(requestParameters: FindAllCrmContactsProspectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCrmContactsProspects200Response> {
        const response = await this.findAllCrmContactsProspectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all referral sources
     */
    async findAllCrmContactsReferralSourcesRaw(requestParameters: FindAllCrmContactsReferralSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCrmContactsReferralSources200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllCrmContactsReferralSources.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.audienceId !== undefined) {
            queryParameters['audience_id'] = requestParameters.audienceId;
        }

        if (requestParameters.companyName !== undefined) {
            queryParameters['company_name'] = requestParameters.companyName;
        }

        if (requestParameters.lifecycleStep !== undefined) {
            queryParameters['lifecycle_step'] = requestParameters.lifecycleStep;
        }

        if (requestParameters.membersOnly !== undefined) {
            queryParameters['members_only'] = requestParameters.membersOnly;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/crm-contacts/referral-sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCrmContactsReferralSources200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all referral sources
     */
    async findAllCrmContactsReferralSources(requestParameters: FindAllCrmContactsReferralSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCrmContactsReferralSources200Response> {
        const response = await this.findAllCrmContactsReferralSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all residents
     */
    async findAllCrmContactsResidentsRaw(requestParameters: FindAllCrmContactsResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCrmContactsResidents200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllCrmContactsResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.audienceId !== undefined) {
            queryParameters['audience_id'] = requestParameters.audienceId;
        }

        if (requestParameters.residentGroup !== undefined) {
            queryParameters['resident_group'] = requestParameters.residentGroup;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.membersOnly !== undefined) {
            queryParameters['members_only'] = requestParameters.membersOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/crm-contacts/residents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCrmContactsResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all residents
     */
    async findAllCrmContactsResidents(requestParameters: FindAllCrmContactsResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCrmContactsResidents200Response> {
        const response = await this.findAllCrmContactsResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all task templates
     */
    async findAllTaskTemplatesRaw(requestParameters: FindAllTaskTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaskTemplates200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaskTemplates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all task templates
     */
    async findAllTaskTemplates(requestParameters: FindAllTaskTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaskTemplates200Response> {
        const response = await this.findAllTaskTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all task types
     */
    async findAllTaskTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTaskTypes200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTaskTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all task types
     */
    async findAllTaskTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTaskTypes200Response> {
        const response = await this.findAllTaskTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all text template types
     */
    async findAllTextTemplateTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTextTemplateTypes200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/text-templates-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTextTemplateTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all text template types
     */
    async findAllTextTemplateTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTextTemplateTypes200Response> {
        const response = await this.findAllTextTemplateTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all text templates
     */
    async findAllTextTemplatesRaw(requestParameters: FindAllTextTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllTextTemplates200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/text-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllTextTemplates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all text templates
     */
    async findAllTextTemplates(requestParameters: FindAllTextTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllTextTemplates200Response> {
        const response = await this.findAllTextTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get audience by id
     */
    async findAudienceByIdRaw(requestParameters: FindAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAudience201Response>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling findAudienceById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audiences/{audience_id}`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAudience201ResponseFromJSON(jsonValue));
    }

    /**
     * Get audience by id
     */
    async findAudienceById(requestParameters: FindAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAudience201Response> {
        const response = await this.findAudienceByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get campaign audiences
     */
    async findCampaignAudiencesRaw(requestParameters: FindCampaignAudiencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindCampaignAudiences200Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling findCampaignAudiences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/audiences`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindCampaignAudiences200ResponseFromJSON(jsonValue));
    }

    /**
     * Get campaign audiences
     */
    async findCampaignAudiences(requestParameters: FindCampaignAudiencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindCampaignAudiences200Response> {
        const response = await this.findCampaignAudiencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get campaign step by id
     */
    async findCampaignStepByIdRaw(requestParameters: FindCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCampaignStep201Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling findCampaignStepById.');
        }

        if (requestParameters.stepId === null || requestParameters.stepId === undefined) {
            throw new runtime.RequiredError('stepId','Required parameter requestParameters.stepId was null or undefined when calling findCampaignStepById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/steps/{step_id}`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))).replace(`{${"step_id"}}`, encodeURIComponent(String(requestParameters.stepId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCampaignStep201ResponseFromJSON(jsonValue));
    }

    /**
     * Get campaign step by id
     */
    async findCampaignStepById(requestParameters: FindCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCampaignStep201Response> {
        const response = await this.findCampaignStepByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get task template by id
     */
    async findTaskTemplateByIdRaw(requestParameters: FindTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTaskTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling findTaskTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/task-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaskTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Get task template by id
     */
    async findTaskTemplateById(requestParameters: FindTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTaskTemplate201Response> {
        const response = await this.findTaskTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get text template by id
     */
    async findTextTemplateByIdRaw(requestParameters: FindTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTextTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling findTextTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/text-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTextTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Get text template by id
     */
    async findTextTemplateById(requestParameters: FindTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTextTemplate201Response> {
        const response = await this.findTextTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get campaign stats
     */
    async getCampaignStatsRaw(requestParameters: GetCampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCampaignStats200Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling getCampaignStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaigns/{campaign_id}/stats`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCampaignStats200ResponseFromJSON(jsonValue));
    }

    /**
     * Get campaign stats
     */
    async getCampaignStats(requestParameters: GetCampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCampaignStats200Response> {
        const response = await this.getCampaignStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove audience member
     */
    async removeAudienceMemberRaw(requestParameters: RemoveAudienceMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling removeAudienceMember.');
        }

        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling removeAudienceMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audiences/{audience_id}/members/{member_id}`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))).replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove audience member
     */
    async removeAudienceMember(requestParameters: RemoveAudienceMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAudienceMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Update audience by id
     */
    async updateAudienceByIdRaw(requestParameters: UpdateAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAudience201Response>> {
        if (requestParameters.audienceId === null || requestParameters.audienceId === undefined) {
            throw new runtime.RequiredError('audienceId','Required parameter requestParameters.audienceId was null or undefined when calling updateAudienceById.');
        }

        if (requestParameters.audience === null || requestParameters.audience === undefined) {
            throw new runtime.RequiredError('audience','Required parameter requestParameters.audience was null or undefined when calling updateAudienceById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/audiences/{audience_id}`.replace(`{${"audience_id"}}`, encodeURIComponent(String(requestParameters.audienceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AudienceToJSON(requestParameters.audience),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAudience201ResponseFromJSON(jsonValue));
    }

    /**
     * Update audience by id
     */
    async updateAudienceById(requestParameters: UpdateAudienceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAudience201Response> {
        const response = await this.updateAudienceByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update campaign step by id
     */
    async updateCampaignStepByIdRaw(requestParameters: UpdateCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCampaignStep201Response>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling updateCampaignStepById.');
        }

        if (requestParameters.stepId === null || requestParameters.stepId === undefined) {
            throw new runtime.RequiredError('stepId','Required parameter requestParameters.stepId was null or undefined when calling updateCampaignStepById.');
        }

        if (requestParameters.campaignStep === null || requestParameters.campaignStep === undefined) {
            throw new runtime.RequiredError('campaignStep','Required parameter requestParameters.campaignStep was null or undefined when calling updateCampaignStepById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/campaigns/{campaign_id}/steps/{step_id}`.replace(`{${"campaign_id"}}`, encodeURIComponent(String(requestParameters.campaignId))).replace(`{${"step_id"}}`, encodeURIComponent(String(requestParameters.stepId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignStepToJSON(requestParameters.campaignStep),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCampaignStep201ResponseFromJSON(jsonValue));
    }

    /**
     * Update campaign step by id
     */
    async updateCampaignStepById(requestParameters: UpdateCampaignStepByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCampaignStep201Response> {
        const response = await this.updateCampaignStepByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update task template by id
     */
    async updateTaskTemplateByIdRaw(requestParameters: UpdateTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTaskTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling updateTaskTemplateById.');
        }

        if (requestParameters.taskTemplate === null || requestParameters.taskTemplate === undefined) {
            throw new runtime.RequiredError('taskTemplate','Required parameter requestParameters.taskTemplate was null or undefined when calling updateTaskTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/task-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaskTemplateToJSON(requestParameters.taskTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaskTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Update task template by id
     */
    async updateTaskTemplateById(requestParameters: UpdateTaskTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTaskTemplate201Response> {
        const response = await this.updateTaskTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update text template by id
     */
    async updateTextTemplateByIdRaw(requestParameters: UpdateTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTextTemplate201Response>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling updateTextTemplateById.');
        }

        if (requestParameters.textTemplate === null || requestParameters.textTemplate === undefined) {
            throw new runtime.RequiredError('textTemplate','Required parameter requestParameters.textTemplate was null or undefined when calling updateTextTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/text-templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TextTemplateToJSON(requestParameters.textTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTextTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Update text template by id
     */
    async updateTextTemplateById(requestParameters: UpdateTextTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTextTemplate201Response> {
        const response = await this.updateTextTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const FindAllCrmContactsCloseConnectionsTypeEnum = {
    Prospect: 'prospect',
    Resident: 'resident'
} as const;
export type FindAllCrmContactsCloseConnectionsTypeEnum = typeof FindAllCrmContactsCloseConnectionsTypeEnum[keyof typeof FindAllCrmContactsCloseConnectionsTypeEnum];
