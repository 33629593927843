/* tslint:disable */
/* eslint-disable */
/**
 * notifications
 * notifications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationPreferenceSettings
 */
export interface NotificationPreferenceSettings {
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceSettings
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceSettings
     */
    notification_preference_id?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceSettings
     */
    user_type_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationPreferenceSettings
     */
    batch_enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceSettings
     */
    batch_window?: string | null;
}

/**
 * Check if a given object implements the NotificationPreferenceSettings interface.
 */
export function instanceOfNotificationPreferenceSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationPreferenceSettingsFromJSON(json: any): NotificationPreferenceSettings {
    return NotificationPreferenceSettingsFromJSONTyped(json, false);
}

export function NotificationPreferenceSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPreferenceSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'notification_preference_id': !exists(json, 'notification_preference_id') ? undefined : json['notification_preference_id'],
        'user_type_id': !exists(json, 'user_type_id') ? undefined : json['user_type_id'],
        'batch_enabled': !exists(json, 'batch_enabled') ? undefined : json['batch_enabled'],
        'batch_window': !exists(json, 'batch_window') ? undefined : json['batch_window'],
    };
}

export function NotificationPreferenceSettingsToJSON(value?: NotificationPreferenceSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'notification_preference_id': value.notification_preference_id,
        'user_type_id': value.user_type_id,
        'batch_enabled': value.batch_enabled,
        'batch_window': value.batch_window,
    };
}

