/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentFormConditionsServices } from './AssessmentFormConditionsServices';
import {
    AssessmentFormConditionsServicesFromJSON,
    AssessmentFormConditionsServicesFromJSONTyped,
    AssessmentFormConditionsServicesToJSON,
} from './AssessmentFormConditionsServices';
import type { AssessmentFormDiagnosis } from './AssessmentFormDiagnosis';
import {
    AssessmentFormDiagnosisFromJSON,
    AssessmentFormDiagnosisFromJSONTyped,
    AssessmentFormDiagnosisToJSON,
} from './AssessmentFormDiagnosis';
import type { CognitiveLevelAssessmentForm } from './CognitiveLevelAssessmentForm';
import {
    CognitiveLevelAssessmentFormFromJSON,
    CognitiveLevelAssessmentFormFromJSONTyped,
    CognitiveLevelAssessmentFormToJSON,
} from './CognitiveLevelAssessmentForm';
import type { MedicareGgScores } from './MedicareGgScores';
import {
    MedicareGgScoresFromJSON,
    MedicareGgScoresFromJSONTyped,
    MedicareGgScoresToJSON,
} from './MedicareGgScores';
import type { MedicareSlpAssessmentForm } from './MedicareSlpAssessmentForm';
import {
    MedicareSlpAssessmentFormFromJSON,
    MedicareSlpAssessmentFormFromJSONTyped,
    MedicareSlpAssessmentFormToJSON,
} from './MedicareSlpAssessmentForm';
import type { NursingAssessmentForm } from './NursingAssessmentForm';
import {
    NursingAssessmentFormFromJSON,
    NursingAssessmentFormFromJSONTyped,
    NursingAssessmentFormToJSON,
} from './NursingAssessmentForm';

/**
 * 
 * @export
 * @interface MedicareAssessmentForm
 */
export interface MedicareAssessmentForm {
    /**
     * 
     * @type {AssessmentFormDiagnosis}
     * @memberof MedicareAssessmentForm
     */
    diagnosis?: AssessmentFormDiagnosis;
    /**
     * 
     * @type {MedicareGgScores}
     * @memberof MedicareAssessmentForm
     */
    gg_scores?: MedicareGgScores;
    /**
     * 
     * @type {MedicareSlpAssessmentForm}
     * @memberof MedicareAssessmentForm
     */
    slp_assessment?: MedicareSlpAssessmentForm;
    /**
     * 
     * @type {CognitiveLevelAssessmentForm}
     * @memberof MedicareAssessmentForm
     */
    cognitive_level_assessment?: CognitiveLevelAssessmentForm;
    /**
     * 
     * @type {AssessmentFormConditionsServices}
     * @memberof MedicareAssessmentForm
     */
    conditions_services?: AssessmentFormConditionsServices;
    /**
     * 
     * @type {NursingAssessmentForm}
     * @memberof MedicareAssessmentForm
     */
    nursing_assessment?: NursingAssessmentForm;
}

/**
 * Check if a given object implements the MedicareAssessmentForm interface.
 */
export function instanceOfMedicareAssessmentForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicareAssessmentFormFromJSON(json: any): MedicareAssessmentForm {
    return MedicareAssessmentFormFromJSONTyped(json, false);
}

export function MedicareAssessmentFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareAssessmentForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diagnosis': !exists(json, 'diagnosis') ? undefined : AssessmentFormDiagnosisFromJSON(json['diagnosis']),
        'gg_scores': !exists(json, 'gg_scores') ? undefined : MedicareGgScoresFromJSON(json['gg_scores']),
        'slp_assessment': !exists(json, 'slp_assessment') ? undefined : MedicareSlpAssessmentFormFromJSON(json['slp_assessment']),
        'cognitive_level_assessment': !exists(json, 'cognitive_level_assessment') ? undefined : CognitiveLevelAssessmentFormFromJSON(json['cognitive_level_assessment']),
        'conditions_services': !exists(json, 'conditions_services') ? undefined : AssessmentFormConditionsServicesFromJSON(json['conditions_services']),
        'nursing_assessment': !exists(json, 'nursing_assessment') ? undefined : NursingAssessmentFormFromJSON(json['nursing_assessment']),
    };
}

export function MedicareAssessmentFormToJSON(value?: MedicareAssessmentForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diagnosis': AssessmentFormDiagnosisToJSON(value.diagnosis),
        'gg_scores': MedicareGgScoresToJSON(value.gg_scores),
        'slp_assessment': MedicareSlpAssessmentFormToJSON(value.slp_assessment),
        'cognitive_level_assessment': CognitiveLevelAssessmentFormToJSON(value.cognitive_level_assessment),
        'conditions_services': AssessmentFormConditionsServicesToJSON(value.conditions_services),
        'nursing_assessment': NursingAssessmentFormToJSON(value.nursing_assessment),
    };
}

