import React from 'react';
import { Chip, Menu, Stack, SxProps, Theme } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import TagChip from '@/components/ui/TagChip/TagChip';

import { ResidentTagPayload } from './useResidentTagsQuery';

interface ResidentCustomTagsProps {
  residentTags: ResidentTagPayload[];
  displayedTags?: number;
  sx?: SxProps<Theme> | undefined;
}

export default function ResidentCustomTags(props: ResidentCustomTagsProps) {
  const { residentTags, displayedTags = 2, sx: customSx } = props;
  return (
    <>
      {residentTags && residentTags.length <= displayedTags ? (
        residentTags.map((tag, i) => (
          <TagChip key={i} label={tag.tag?.name!} type={tag.tag?.type!} />
        ))
      ) : residentTags ? (
        <>
          {residentTags.slice(0, displayedTags).map((tag, i) => (
            <TagChip key={i} label={tag.tag?.name!} type={tag.tag?.type!} />
          ))}
          <PopupState variant="popover">
            {(tagsPopup) => (
              <>
                <Chip
                  sx={{
                    height: '24px',
                    fontWeight: 500,
                    backgroundColor: '#E7EEF4',
                    border: 'none',
                    ...customSx
                  }}
                  color="primary"
                  variant="outlined"
                  {...bindTrigger(tagsPopup)}
                  label={`${residentTags?.length! - displayedTags} +`}
                />
                <Menu
                  {...bindMenu(tagsPopup)}
                  PaperProps={{
                    style: {
                      marginLeft: '20px'
                    }
                  }}>
                  <Stack gap={2} p={2}>
                    {residentTags!
                      .slice(displayedTags, residentTags?.length! + 1)
                      .map((tag, i) => (
                        <TagChip
                          key={i}
                          label={tag.tag?.name!}
                          type={tag.tag?.type!}
                        />
                      ))}
                  </Stack>
                </Menu>
              </>
            )}
          </PopupState>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
