import React from 'react';
import { Circle, Close } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import SuprSendInbox from '@suprsend/react-inbox';
import dayjs from 'dayjs';

import NotificationToast from '@/components/ui/Toast/NotificationToast';
import { Placeholder } from '@/pages/CRM/components/Placeholder';

import 'react-toastify/dist/ReactToastify.css';

const WORKSPACE_KEY = import.meta.env.VITE_SUPR_SEND_API_KEY as string;

interface InboxModalProps {
  userId: string;
  subscriberId: string;
}

const InboxModal: React.FC<InboxModalProps> = ({ userId, subscriberId }) => {
  return (
    <SuprSendInbox
      workspaceKey={WORKSPACE_KEY}
      hideAvatar
      toastProps={{
        position: 'top-right',
        toastComponent: (data) => {
          // dismissToast is not exposed in suprsend/react-inbox types but it is available in the data object. Upon updating this package, confirm if dismissToast was renamed to closeToast as per Suprsend team's suggestion
          // @ts-ignore
          const { notificationData, dismissToast } = data;
          return (
            <NotificationToast
              dismissToast={dismissToast}
              title={notificationData.message.header}
              message={notificationData.message.text}
              actions={
                <Stack direction="row" gap={2} mt="6px">
                  {notificationData.message.actions?.map(
                    (action: any, index: number) => (
                      <Button
                        size="medium"
                        variant="contained"
                        sx={{ marginTop: '8px' }}
                        color={index === 0 ? 'primary' : 'secondary'}
                        onClick={() => {
                          if (action.open_in_new_tab) {
                            window.open(action.url, '_blank');
                          } else {
                            window.location.href = action.url;
                          }
                        }}
                        key={index}>
                        {action.name}
                      </Button>
                    )
                  )}
                </Stack>
              }
            />
          );
        }
      }}
      bellComponent={() => <NotificationsIcon htmlColor="#364955" />}
      notificationComponent={(data) => {
        const { notificationData, markArchived } = data;
        return (
          <Stack
            direction="column"
            sx={{
              borderTop: '1px solid #D3E5F4',
              cursor: !notificationData.seen_on ? 'pointer' : 'default',
              padding: '16px'
            }}
            bgcolor={!notificationData.seen_on ? '#EEF4F9' : '#FFFFFF'}>
            <Stack
              direction="row"
              justifyContent="space-between"
              pb="4px"
              alignItems="flex-start">
              <Stack flex={1.4}>
                <Typography
                  color="#0A1E28"
                  fontWeight={500}
                  fontSize="16px"
                  display="flex"
                  sx={{ alignItems: 'flex-start' }}>
                  <Circle
                    htmlColor={notificationData.seen_on ? '#B5C9D6' : '#1DB8F2'}
                    sx={{
                      width: '6px',
                      height: '6px',
                      marginRight: '8px',
                      marginTop: '8px'
                    }}
                  />
                  {notificationData.message.header}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography color="#667A86" fontWeight={400} fontSize="10px">
                  {dayjs(notificationData.created_on).format('MMM D h:mm A')}
                </Typography>
                <IconButton
                  onClick={() => markArchived(notificationData.n_id)}
                  size="small">
                  <Close htmlColor="#667A86" sx={{ width: 16, height: 16 }} />
                </IconButton>
              </Stack>
            </Stack>
            <Typography color="#0A1E28" fontSize="14px" fontWeight={400}>
              {notificationData.message.text}
            </Typography>
            {notificationData?.message?.subtext ? (
              <Typography
                color="#364955"
                fontSize="12px"
                fontWeight={400}
                mt={1}>
                {notificationData?.message?.subtext.text}
              </Typography>
            ) : (
              <></>
            )}
            {notificationData?.message?.actions?.length > 0 ? (
              <Stack direction="row" gap={2} mt={1}>
                {notificationData.message.actions?.map(
                  (action: any, index: number) => (
                    <Button
                      size="medium"
                      variant="contained"
                      sx={{ marginTop: '8px' }}
                      color={index === 0 ? 'primary' : 'secondary'}
                      onClick={() => {
                        if (action.open_in_new_tab) {
                          window.open(action.url, '_blank');
                        } else {
                          window.location.href = action.url;
                        }
                      }}
                      key={index}>
                      {action.name}
                    </Button>
                  )
                )}
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        );
      }}
      noNotificationsComponent={() => (
        <Placeholder
          Icon={NotificationsIcon}
          sx={{ textAlign: 'center' }}
          header="There are no unread notifications"
        />
      )}
      theme={{
        bell: {
          margin: '0 !important'
        },
        badge: {
          backgroundColor: '#1DB8F2'
        },
        notification: {
          container: {
            backgroungColor: 'red'
          }
        },
        header: {
          color: '#1DB8F2',
          markAllReadText: { color: '#1DB8F2', fontWeight: 600 }
        }
      }}
      subscriberId={subscriberId}
      distinctId={userId}
    />
  );
};

export default InboxModal;
