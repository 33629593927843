/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskStep
 */
export interface TaskStep {
    /**
     * 
     * @type {string}
     * @memberof TaskStep
     */
    template_id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskStep
     */
    assignee_id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskStep
     */
    create_time: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaskStep
     */
    due_days_interval: number;
    /**
     * 
     * @type {string}
     * @memberof TaskStep
     */
    due_time: string;
}

/**
 * Check if a given object implements the TaskStep interface.
 */
export function instanceOfTaskStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "template_id" in value;
    isInstance = isInstance && "assignee_id" in value;
    isInstance = isInstance && "create_time" in value;
    isInstance = isInstance && "due_days_interval" in value;
    isInstance = isInstance && "due_time" in value;

    return isInstance;
}

export function TaskStepFromJSON(json: any): TaskStep {
    return TaskStepFromJSONTyped(json, false);
}

export function TaskStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template_id': json['template_id'],
        'assignee_id': json['assignee_id'],
        'create_time': json['create_time'],
        'due_days_interval': json['due_days_interval'],
        'due_time': json['due_time'],
    };
}

export function TaskStepToJSON(value?: TaskStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_id': value.template_id,
        'assignee_id': value.assignee_id,
        'create_time': value.create_time,
        'due_days_interval': value.due_days_interval,
        'due_time': value.due_time,
    };
}

