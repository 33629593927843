import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import { queryClient } from '@/adapters/query';
import { residentsClient } from '@/adapters/schemaClients';
import {
  FIND_ALL_DOCTORS_QUERY_KEY,
  FIND_ALL_RESIDENT_DOCTORS_QUERY_KEY
} from '@/hooks/useDoctorsQuery';
import {
  AttachDoctorToResidentRequest,
  CreateResidentDoctorRequest,
  ResidentDoctor
} from '@/types/residents';

import { ResidentDoctorModel } from './ResidentDoctorsModel';

interface UseResidentDoctorsSearchQueryProps {
  residentId: string;
  providerName?: string;
  providerNpi?: string;
  providerDea?: string;
  options?: UseQueryOptions<ResidentDoctorModel, Error>;
}

export const SEARCH_RESIDENT_DOCTORS_QUERY_KEY = 'searchResidentDoctors';

const invalidate = () => {
  queryClient.invalidateQueries([FIND_ALL_RESIDENT_DOCTORS_QUERY_KEY]);
  queryClient.invalidateQueries([FIND_ALL_DOCTORS_QUERY_KEY]);
  queryClient.invalidateQueries([SEARCH_RESIDENT_DOCTORS_QUERY_KEY]);
};

export const useResidentDoctorsSearchQuery = ({
  residentId,
  providerName,
  providerNpi,
  providerDea,
  options = {}
}: UseResidentDoctorsSearchQueryProps) => {
  return useQuery<ResidentDoctorModel, Error>({
    queryKey: [
      SEARCH_RESIDENT_DOCTORS_QUERY_KEY,
      {
        residentId,
        providerName,
        providerNpi,
        providerDea
      }
    ],
    queryFn: async () => {
      const response = await residentsClient.searchDoctors({
        id: residentId,
        providerName,
        providerNpi,
        providerDea
      });
      return new ResidentDoctorModel(response.data!, providerName);
    },
    enabled: !!residentId,
    ...(options as any)
  });
};

export const useAttachDoctorToResidentMutation = (
  options: UseMutationOptions<
    ResidentDoctor,
    Error,
    AttachDoctorToResidentRequest
  >
) => {
  return useMutation<ResidentDoctor, Error, AttachDoctorToResidentRequest>({
    mutationFn: async (params) => {
      const response = await residentsClient.attachDoctorToResident(params);
      return response.data!;
    },
    onSuccess: (data, variables, context) => {
      invalidate();
      options.onSuccess?.(data, variables, context);
    }
  });
};

export const useCreateResidentDoctorMutation = (
  options: UseMutationOptions<
    ResidentDoctor,
    Error,
    CreateResidentDoctorRequest
  >
) => {
  return useMutation<ResidentDoctor, Error, CreateResidentDoctorRequest>({
    mutationFn: async (params) => {
      const response = await residentsClient.createResidentDoctor(params);
      return response.data!;
    },
    onSuccess: (data, variables, context) => {
      invalidate();
      options.onSuccess?.(data, variables, context);
    }
  });
};
