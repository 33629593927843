import React from 'react';
import { Badge, Chip, Stack, Typography } from '@mui/material';

import { MedicationIndicatorChip } from '@/components/MedicationIndicators/MedicationIndicators';
import { DEA_SCHEDULE_CODES } from '@/constants';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';

interface ResidentInfo {
  firstName: string;
  lastName: string;
  residentId: string;
}

interface StockInfo {
  dosesForTheNextWeek: number;
  remainingStockUnits: number;
  numberOfDaysRemaining: number;
  isEnoughStock: boolean;
}

interface DoctorInfo {
  name?: string;
  doctorId?: string;
}

export class MedicationStockItemPayload {
  medicationTaskId: number;
  isPrn: boolean;
  doctor: DoctorInfo;
  resident: ResidentInfo;
  medication: MedicationsPayload;
  stock: StockInfo;
}

export class MedicationStockModel extends MedicationStockItemPayload {
  constructor(payload: MedicationStockItemPayload) {
    super();
    Object.assign(this, payload);
  }

  public get isControlledSubstance(): boolean {
    return DEA_SCHEDULE_CODES.includes(
      this.medication.FederalDEAClassCode ?? ''
    );
  }

  public renderMedicationCell = (): JSX.Element => {
    return (
      <div>
        <Stack direction="row" spacing={1}>
          <Typography
            component="span"
            color="secondary"
            fontSize="0.9375rem"
            fontWeight="500">
            {this.medication?.DrugNameDesc}
          </Typography>
          {this.isControlledSubstance && (
            <MedicationIndicatorChip
              type="controlled-substance"
              label="CS"
              size="small"
            />
          )}
        </Stack>
        <Typography color="#364955" fontSize="0.875rem">
          {this.medication?.GenericDispensableDrugDesc || ''}
        </Typography>
      </div>
    );
  };

  public getDoctorName = (): string => {
    return this.doctor.name ?? 'N/A';
  };

  public getDosesPerNextWeek = (): string => {
    if (this.isPrn) {
      return 'PRN';
    } else {
      return this.stock.dosesForTheNextWeek.toString();
    }
  };

  public numberOfUnitsRemaining(): string {
    return this.stock.remainingStockUnits?.toString() ?? 'N/A';
  }

  public numberOfDaysRemainingGetter = (): number => {
    if (this.isPrn || !this.stock.numberOfDaysRemaining) {
      return 1000; // return big number to display at the bottom
    }
    return this.stock.numberOfDaysRemaining;
  };

  public renderNumberOfDaysChip = (): JSX.Element => {
    if (
      this.isPrn ||
      this.stock.numberOfDaysRemaining === null ||
      this.stock.numberOfDaysRemaining === undefined
    ) {
      return <Chip label="N/A" />;
    }

    if (this.stock.numberOfDaysRemaining > 20) {
      return (
        <Chip
          label="20+"
          sx={{
            backgroundColor: '#569E59',
            color: '#FCFEFF'
          }}
        />
      );
    }

    if (this.stock.isEnoughStock || this.stock.numberOfDaysRemaining > 14) {
      return (
        <Chip
          label={this.stock.numberOfDaysRemaining}
          sx={{
            backgroundColor: '#569E59',
            color: '#FCFEFF'
          }}
        />
      );
    }
    let badgeColor;
    if (this.stock.numberOfDaysRemaining > 7) {
      badgeColor = '#F38D2F';
    } else if (this.stock.numberOfDaysRemaining <= 7) {
      badgeColor = '#DD3730';
    }
    return (
      <Badge
        badgeContent={'!'}
        sx={{
          '& .MuiBadge-badge': {
            color: '#FFFFFF',
            backgroundColor: badgeColor
          }
        }}>
        <Chip label={this.stock.numberOfDaysRemaining} />
      </Badge>
    );
  };
}
