import React from 'react';
import { Stack, styled, Typography } from '@mui/material';

interface StepFormLayoutProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const StepFormLayoutWrapper = styled(Stack)({
  '& > div': {
    backgroundColor: '#FCFEFF'
  }
});

const StepFormLayoutHeader = styled(Stack)({
  borderTopLeftRadius: '12px',
  padding: '22px 24px',
  borderTopRightRadius: '12px',
  background:
    'linear-gradient(0deg, rgba(0, 102, 138, 0.05), rgba(0, 102, 138, 0.05)), #FBFCFF;'
});

const StepFormLayout: React.FC<StepFormLayoutProps> = ({ title, children }) => {
  return (
    <StepFormLayoutWrapper>
      <StepFormLayoutHeader>
        {typeof title === 'string' ? (
          <Typography fontSize="18px" color="black">
            {title}
          </Typography>
        ) : (
          title
        )}
      </StepFormLayoutHeader>
      <Stack padding={2}>{children}</Stack>
    </StepFormLayoutWrapper>
  );
};

export default StepFormLayout;
