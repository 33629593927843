/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceContactTag } from './SourceContactTag';
import {
    SourceContactTagFromJSON,
    SourceContactTagFromJSONTyped,
    SourceContactTagToJSON,
} from './SourceContactTag';

/**
 * 
 * @export
 * @interface CreateSourceContactTag201Response
 */
export interface CreateSourceContactTag201Response {
    /**
     * 
     * @type {SourceContactTag}
     * @memberof CreateSourceContactTag201Response
     */
    data?: SourceContactTag;
}

/**
 * Check if a given object implements the CreateSourceContactTag201Response interface.
 */
export function instanceOfCreateSourceContactTag201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSourceContactTag201ResponseFromJSON(json: any): CreateSourceContactTag201Response {
    return CreateSourceContactTag201ResponseFromJSONTyped(json, false);
}

export function CreateSourceContactTag201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSourceContactTag201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : SourceContactTagFromJSON(json['data']),
    };
}

export function CreateSourceContactTag201ResponseToJSON(value?: CreateSourceContactTag201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SourceContactTagToJSON(value.data),
    };
}

