/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostIncidentResponsibleParty } from './PostIncidentResponsibleParty';
import {
    PostIncidentResponsiblePartyFromJSON,
    PostIncidentResponsiblePartyFromJSONTyped,
    PostIncidentResponsiblePartyToJSON,
} from './PostIncidentResponsibleParty';

/**
 * 
 * @export
 * @interface IncidentProtocolParty
 */
export interface IncidentProtocolParty {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolParty
     */
    id?: string;
    /**
     * 
     * @type {PostIncidentResponsibleParty}
     * @memberof IncidentProtocolParty
     */
    type?: PostIncidentResponsibleParty;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolParty
     */
    value?: string;
}

/**
 * Check if a given object implements the IncidentProtocolParty interface.
 */
export function instanceOfIncidentProtocolParty(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IncidentProtocolPartyFromJSON(json: any): IncidentProtocolParty {
    return IncidentProtocolPartyFromJSONTyped(json, false);
}

export function IncidentProtocolPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : PostIncidentResponsiblePartyFromJSON(json['type']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function IncidentProtocolPartyToJSON(value?: IncidentProtocolParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': PostIncidentResponsiblePartyToJSON(value.type),
        'value': value.value,
    };
}

