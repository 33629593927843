/* tslint:disable */
/* eslint-disable */
/**
 * data-center
 * data-center
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllDashboards200Response,
  UserFavoriteDashboardRequest,
} from '../models/index';
import {
    FindAllDashboards200ResponseFromJSON,
    FindAllDashboards200ResponseToJSON,
    UserFavoriteDashboardRequestFromJSON,
    UserFavoriteDashboardRequestToJSON,
} from '../models/index';

export interface UpdateUserFavoriteDashboardRequest {
    id: string;
    userFavoriteDashboardRequest: UserFavoriteDashboardRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get dashboards
     */
    async findAllDashboardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllDashboards200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllDashboards200ResponseFromJSON(jsonValue));
    }

    /**
     * Get dashboards
     */
    async findAllDashboards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllDashboards200Response> {
        const response = await this.findAllDashboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update user favorite dashboard
     */
    async updateUserFavoriteDashboardRaw(requestParameters: UpdateUserFavoriteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserFavoriteDashboard.');
        }

        if (requestParameters.userFavoriteDashboardRequest === null || requestParameters.userFavoriteDashboardRequest === undefined) {
            throw new runtime.RequiredError('userFavoriteDashboardRequest','Required parameter requestParameters.userFavoriteDashboardRequest was null or undefined when calling updateUserFavoriteDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dashboards/{id}/favorite`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserFavoriteDashboardRequestToJSON(requestParameters.userFavoriteDashboardRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user favorite dashboard
     */
    async updateUserFavoriteDashboard(requestParameters: UpdateUserFavoriteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserFavoriteDashboardRaw(requestParameters, initOverrides);
    }

}
