/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaxCategory } from './TaxCategory';
import {
    TaxCategoryFromJSON,
    TaxCategoryFromJSONTyped,
    TaxCategoryToJSON,
} from './TaxCategory';

/**
 * 
 * @export
 * @interface FindAllTaxCategories200Response
 */
export interface FindAllTaxCategories200Response {
    /**
     * 
     * @type {Array<TaxCategory>}
     * @memberof FindAllTaxCategories200Response
     */
    data?: Array<TaxCategory>;
}

/**
 * Check if a given object implements the FindAllTaxCategories200Response interface.
 */
export function instanceOfFindAllTaxCategories200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllTaxCategories200ResponseFromJSON(json: any): FindAllTaxCategories200Response {
    return FindAllTaxCategories200ResponseFromJSONTyped(json, false);
}

export function FindAllTaxCategories200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllTaxCategories200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(TaxCategoryFromJSON)),
    };
}

export function FindAllTaxCategories200ResponseToJSON(value?: FindAllTaxCategories200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(TaxCategoryToJSON)),
    };
}

