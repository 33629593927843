/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProgressNotesListEntry
 */
export interface ProgressNotesListEntry {
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    profile_image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressNotesListEntry
     */
    user_title: string;
    /**
     * 
     * @type {Date}
     * @memberof ProgressNotesListEntry
     */
    date_created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProgressNotesListEntry
     */
    is_restricted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProgressNotesListEntry
     */
    date_modified: Date;
}

/**
 * Check if a given object implements the ProgressNotesListEntry interface.
 */
export function instanceOfProgressNotesListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "user_name" in value;
    isInstance = isInstance && "user_title" in value;
    isInstance = isInstance && "date_created" in value;
    isInstance = isInstance && "is_restricted" in value;
    isInstance = isInstance && "date_modified" in value;

    return isInstance;
}

export function ProgressNotesListEntryFromJSON(json: any): ProgressNotesListEntry {
    return ProgressNotesListEntryFromJSONTyped(json, false);
}

export function ProgressNotesListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgressNotesListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'content': json['content'],
        'user_id': json['user_id'],
        'profile_image': !exists(json, 'profile_image') ? undefined : json['profile_image'],
        'user_name': json['user_name'],
        'user_title': json['user_title'],
        'date_created': (new Date(json['date_created'])),
        'is_restricted': json['is_restricted'],
        'date_modified': (new Date(json['date_modified'])),
    };
}

export function ProgressNotesListEntryToJSON(value?: ProgressNotesListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'content': value.content,
        'user_id': value.user_id,
        'profile_image': value.profile_image,
        'user_name': value.user_name,
        'user_title': value.user_title,
        'date_created': (value.date_created.toISOString()),
        'is_restricted': value.is_restricted,
        'date_modified': (value.date_modified.toISOString()),
    };
}

