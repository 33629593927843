/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LifecycleStep = {
    Opportunity: 'Opportunity',
    Qualified: 'Qualified',
    Nurture: 'Nurture',
    Relationship: 'Relationship'
} as const;
export type LifecycleStep = typeof LifecycleStep[keyof typeof LifecycleStep];


export function LifecycleStepFromJSON(json: any): LifecycleStep {
    return LifecycleStepFromJSONTyped(json, false);
}

export function LifecycleStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifecycleStep {
    return json as LifecycleStep;
}

export function LifecycleStepToJSON(value?: LifecycleStep | null): any {
    return value as any;
}

