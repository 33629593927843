/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NgRepositoryEntry } from './NgRepositoryEntry';
import {
    NgRepositoryEntryFromJSON,
    NgRepositoryEntryFromJSONTyped,
    NgRepositoryEntryToJSON,
} from './NgRepositoryEntry';

/**
 * 
 * @export
 * @interface CreateNgRepositoryEntry201Response
 */
export interface CreateNgRepositoryEntry201Response {
    /**
     * 
     * @type {NgRepositoryEntry}
     * @memberof CreateNgRepositoryEntry201Response
     */
    data?: NgRepositoryEntry;
}

/**
 * Check if a given object implements the CreateNgRepositoryEntry201Response interface.
 */
export function instanceOfCreateNgRepositoryEntry201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateNgRepositoryEntry201ResponseFromJSON(json: any): CreateNgRepositoryEntry201Response {
    return CreateNgRepositoryEntry201ResponseFromJSONTyped(json, false);
}

export function CreateNgRepositoryEntry201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNgRepositoryEntry201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : NgRepositoryEntryFromJSON(json['data']),
    };
}

export function CreateNgRepositoryEntry201ResponseToJSON(value?: CreateNgRepositoryEntry201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': NgRepositoryEntryToJSON(value.data),
    };
}

