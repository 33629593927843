/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PRNActivityListItem
 */
export interface PRNActivityListItem {
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    date_completed: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    dose_info: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    result: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    completing_user: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    completing_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    followup_completing_user?: string;
    /**
     * 
     * @type {string}
     * @memberof PRNActivityListItem
     */
    follow_up_date_completed?: string;
}

/**
 * Check if a given object implements the PRNActivityListItem interface.
 */
export function instanceOfPRNActivityListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date_completed" in value;
    isInstance = isInstance && "dose_info" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "completing_user" in value;
    isInstance = isInstance && "completing_user_id" in value;

    return isInstance;
}

export function PRNActivityListItemFromJSON(json: any): PRNActivityListItem {
    return PRNActivityListItemFromJSONTyped(json, false);
}

export function PRNActivityListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PRNActivityListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date_completed': json['date_completed'],
        'dose_info': json['dose_info'],
        'result': json['result'],
        'completing_user': json['completing_user'],
        'completing_user_id': json['completing_user_id'],
        'followup_completing_user': !exists(json, 'followup_completing_user') ? undefined : json['followup_completing_user'],
        'follow_up_date_completed': !exists(json, 'follow_up_date_completed') ? undefined : json['follow_up_date_completed'],
    };
}

export function PRNActivityListItemToJSON(value?: PRNActivityListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date_completed': value.date_completed,
        'dose_info': value.dose_info,
        'result': value.result,
        'completing_user': value.completing_user,
        'completing_user_id': value.completing_user_id,
        'followup_completing_user': value.followup_completing_user,
        'follow_up_date_completed': value.follow_up_date_completed,
    };
}

