import { Theme, useMediaQuery } from '@mui/material';
import { atom, SetStateAction, useAtom } from 'jotai';

export const sidebarExpandAtom = atom<boolean | null>(null);

export const useSidebarExpandedAtom = (): [
  boolean,
  (update: SetStateAction<boolean>) => void
] => {
  const [expanded, setExpanded] = useAtom(sidebarExpandAtom);
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  if (expanded === null) {
    setExpanded(isMediaQueryDownLg);
  }

  return [
    expanded as boolean,
    setExpanded as (update: SetStateAction<boolean>) => void
  ];
};
