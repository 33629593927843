/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Resident } from './Resident';
import {
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './Resident';

/**
 * 
 * @export
 * @interface FindAllResidentsWithExistingStatement200Response
 */
export interface FindAllResidentsWithExistingStatement200Response {
    /**
     * 
     * @type {Array<Resident>}
     * @memberof FindAllResidentsWithExistingStatement200Response
     */
    data?: Array<Resident>;
}

/**
 * Check if a given object implements the FindAllResidentsWithExistingStatement200Response interface.
 */
export function instanceOfFindAllResidentsWithExistingStatement200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllResidentsWithExistingStatement200ResponseFromJSON(json: any): FindAllResidentsWithExistingStatement200Response {
    return FindAllResidentsWithExistingStatement200ResponseFromJSONTyped(json, false);
}

export function FindAllResidentsWithExistingStatement200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentsWithExistingStatement200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ResidentFromJSON)),
    };
}

export function FindAllResidentsWithExistingStatement200ResponseToJSON(value?: FindAllResidentsWithExistingStatement200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ResidentToJSON)),
    };
}

