/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssessmentState = {
    Created: 'created',
    Published: 'published',
    Unpublished: 'unpublished',
    Archived: 'archived'
} as const;
export type AssessmentState = typeof AssessmentState[keyof typeof AssessmentState];


export function AssessmentStateFromJSON(json: any): AssessmentState {
    return AssessmentStateFromJSONTyped(json, false);
}

export function AssessmentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentState {
    return json as AssessmentState;
}

export function AssessmentStateToJSON(value?: AssessmentState | null): any {
    return value as any;
}

