/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillReceiptSettingsIncludePOAEntry
 */
export interface BillReceiptSettingsIncludePOAEntry {
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludePOAEntry
     */
    field?: BillReceiptSettingsIncludePOAEntryFieldEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BillReceiptSettingsIncludePOAEntry
     */
    value?: boolean;
}


/**
 * @export
 */
export const BillReceiptSettingsIncludePOAEntryFieldEnum = {
    Name: 'include_poa_name',
    Address: 'include_poa_address',
    Email: 'include_poa_email',
    Phone: 'include_poa_phone'
} as const;
export type BillReceiptSettingsIncludePOAEntryFieldEnum = typeof BillReceiptSettingsIncludePOAEntryFieldEnum[keyof typeof BillReceiptSettingsIncludePOAEntryFieldEnum];


/**
 * Check if a given object implements the BillReceiptSettingsIncludePOAEntry interface.
 */
export function instanceOfBillReceiptSettingsIncludePOAEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillReceiptSettingsIncludePOAEntryFromJSON(json: any): BillReceiptSettingsIncludePOAEntry {
    return BillReceiptSettingsIncludePOAEntryFromJSONTyped(json, false);
}

export function BillReceiptSettingsIncludePOAEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillReceiptSettingsIncludePOAEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function BillReceiptSettingsIncludePOAEntryToJSON(value?: BillReceiptSettingsIncludePOAEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
    };
}

