/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionConfigDate } from './BidirectionalActionConfigDate';
import {
    BidirectionalActionConfigDateFromJSON,
    BidirectionalActionConfigDateFromJSONTyped,
    BidirectionalActionConfigDateToJSON,
} from './BidirectionalActionConfigDate';
import type { BidirectionalActionConfigTextMessage } from './BidirectionalActionConfigTextMessage';
import {
    BidirectionalActionConfigTextMessageFromJSON,
    BidirectionalActionConfigTextMessageFromJSONTyped,
    BidirectionalActionConfigTextMessageToJSON,
} from './BidirectionalActionConfigTextMessage';
import type { BidirectionalActionType } from './BidirectionalActionType';
import {
    BidirectionalActionTypeFromJSON,
    BidirectionalActionTypeFromJSONTyped,
    BidirectionalActionTypeToJSON,
} from './BidirectionalActionType';
import type { ConnectorType } from './ConnectorType';
import {
    ConnectorTypeFromJSON,
    ConnectorTypeFromJSONTyped,
    ConnectorTypeToJSON,
} from './ConnectorType';
import type { Question } from './Question';
import {
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface BidirectionalActionConfig
 */
export interface BidirectionalActionConfig {
    /**
     * 
     * @type {BidirectionalActionType}
     * @memberof BidirectionalActionConfig
     */
    action_type: BidirectionalActionType;
    /**
     * 
     * @type {ConnectorType}
     * @memberof BidirectionalActionConfig
     */
    connector: ConnectorType;
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionConfig
     */
    questions_mutually_exclusive: boolean;
    /**
     * 
     * @type {Array<Question>}
     * @memberof BidirectionalActionConfig
     */
    questions: Array<Question>;
    /**
     * 
     * @type {BidirectionalActionConfigTextMessage}
     * @memberof BidirectionalActionConfig
     */
    text_message: BidirectionalActionConfigTextMessage;
    /**
     * 
     * @type {BidirectionalActionConfigDate}
     * @memberof BidirectionalActionConfig
     */
    date?: BidirectionalActionConfigDate;
    /**
     * 
     * @type {BidirectionalActionConfigDate}
     * @memberof BidirectionalActionConfig
     */
    time?: BidirectionalActionConfigDate;
}

/**
 * Check if a given object implements the BidirectionalActionConfig interface.
 */
export function instanceOfBidirectionalActionConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action_type" in value;
    isInstance = isInstance && "connector" in value;
    isInstance = isInstance && "questions_mutually_exclusive" in value;
    isInstance = isInstance && "questions" in value;
    isInstance = isInstance && "text_message" in value;

    return isInstance;
}

export function BidirectionalActionConfigFromJSON(json: any): BidirectionalActionConfig {
    return BidirectionalActionConfigFromJSONTyped(json, false);
}

export function BidirectionalActionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_type': BidirectionalActionTypeFromJSON(json['action_type']),
        'connector': ConnectorTypeFromJSON(json['connector']),
        'questions_mutually_exclusive': json['questions_mutually_exclusive'],
        'questions': ((json['questions'] as Array<any>).map(QuestionFromJSON)),
        'text_message': BidirectionalActionConfigTextMessageFromJSON(json['text_message']),
        'date': !exists(json, 'date') ? undefined : BidirectionalActionConfigDateFromJSON(json['date']),
        'time': !exists(json, 'time') ? undefined : BidirectionalActionConfigDateFromJSON(json['time']),
    };
}

export function BidirectionalActionConfigToJSON(value?: BidirectionalActionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': BidirectionalActionTypeToJSON(value.action_type),
        'connector': ConnectorTypeToJSON(value.connector),
        'questions_mutually_exclusive': value.questions_mutually_exclusive,
        'questions': ((value.questions as Array<any>).map(QuestionToJSON)),
        'text_message': BidirectionalActionConfigTextMessageToJSON(value.text_message),
        'date': BidirectionalActionConfigDateToJSON(value.date),
        'time': BidirectionalActionConfigDateToJSON(value.time),
    };
}

