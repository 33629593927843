/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentProduct } from './ResidentProduct';
import {
    ResidentProductFromJSON,
    ResidentProductFromJSONTyped,
    ResidentProductToJSON,
} from './ResidentProduct';

/**
 * 
 * @export
 * @interface BulkCreateOneTimePurchases201Response
 */
export interface BulkCreateOneTimePurchases201Response {
    /**
     * 
     * @type {Array<ResidentProduct>}
     * @memberof BulkCreateOneTimePurchases201Response
     */
    data?: Array<ResidentProduct>;
}

/**
 * Check if a given object implements the BulkCreateOneTimePurchases201Response interface.
 */
export function instanceOfBulkCreateOneTimePurchases201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BulkCreateOneTimePurchases201ResponseFromJSON(json: any): BulkCreateOneTimePurchases201Response {
    return BulkCreateOneTimePurchases201ResponseFromJSONTyped(json, false);
}

export function BulkCreateOneTimePurchases201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateOneTimePurchases201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ResidentProductFromJSON)),
    };
}

export function BulkCreateOneTimePurchases201ResponseToJSON(value?: BulkCreateOneTimePurchases201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ResidentProductToJSON)),
    };
}

