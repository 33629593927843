/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealStage } from './DealStage';
import {
    DealStageFromJSON,
    DealStageFromJSONTyped,
    DealStageToJSON,
} from './DealStage';
import type { DealTemperature } from './DealTemperature';
import {
    DealTemperatureFromJSON,
    DealTemperatureFromJSONTyped,
    DealTemperatureToJSON,
} from './DealTemperature';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface CrmProspectListEntry
 */
export interface CrmProspectListEntry {
    /**
     * 
     * @type {string}
     * @memberof CrmProspectListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CrmProspectListEntry
     */
    avatar_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrmProspectListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmProspectListEntry
     */
    last_name: string;
    /**
     * 
     * @type {DealStage}
     * @memberof CrmProspectListEntry
     */
    deal_stage: DealStage | null;
    /**
     * 
     * @type {DealTemperature}
     * @memberof CrmProspectListEntry
     */
    deal_temperature: DealTemperature | null;
    /**
     * 
     * @type {RelationType}
     * @memberof CrmProspectListEntry
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof CrmProspectListEntry
     */
    relation_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrmProspectListEntry
     */
    is_added_to_audience?: boolean;
}

/**
 * Check if a given object implements the CrmProspectListEntry interface.
 */
export function instanceOfCrmProspectListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "avatar_url" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "deal_stage" in value;
    isInstance = isInstance && "deal_temperature" in value;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;

    return isInstance;
}

export function CrmProspectListEntryFromJSON(json: any): CrmProspectListEntry {
    return CrmProspectListEntryFromJSONTyped(json, false);
}

export function CrmProspectListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmProspectListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'avatar_url': json['avatar_url'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'deal_stage': DealStageFromJSON(json['deal_stage']),
        'deal_temperature': DealTemperatureFromJSON(json['deal_temperature']),
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
        'is_added_to_audience': !exists(json, 'is_added_to_audience') ? undefined : json['is_added_to_audience'],
    };
}

export function CrmProspectListEntryToJSON(value?: CrmProspectListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avatar_url': value.avatar_url,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'deal_stage': DealStageToJSON(value.deal_stage),
        'deal_temperature': DealTemperatureToJSON(value.deal_temperature),
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
        'is_added_to_audience': value.is_added_to_audience,
    };
}

