import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import postAccessUrl from '@/api/postAccessUrl';

export function useAccessUrlQuery(
  keys: string[],
  options = {} as UseQueryOptions
) {
  const formattedKeys = keys?.filter(
    (key) => key !== undefined && key !== null && key !== ''
  );
  return useQuery(
    ['useAccessUrlQuery', ...formattedKeys],
    () => (formattedKeys.length > 0 ? postAccessUrl(formattedKeys) : []),
    {
      ...(options as any),
      enabled: formattedKeys.length > 0
    }
  );
}
