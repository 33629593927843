/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmProspectListEntry } from './CrmProspectListEntry';
import {
    CrmProspectListEntryFromJSON,
    CrmProspectListEntryFromJSONTyped,
    CrmProspectListEntryToJSON,
} from './CrmProspectListEntry';

/**
 * 
 * @export
 * @interface CrmProspectsPage
 */
export interface CrmProspectsPage {
    /**
     * 
     * @type {Array<CrmProspectListEntry>}
     * @memberof CrmProspectsPage
     */
    prospects: Array<CrmProspectListEntry>;
    /**
     * 
     * @type {number}
     * @memberof CrmProspectsPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the CrmProspectsPage interface.
 */
export function instanceOfCrmProspectsPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "prospects" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function CrmProspectsPageFromJSON(json: any): CrmProspectsPage {
    return CrmProspectsPageFromJSONTyped(json, false);
}

export function CrmProspectsPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmProspectsPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prospects': ((json['prospects'] as Array<any>).map(CrmProspectListEntryFromJSON)),
        'total_count': json['total_count'],
    };
}

export function CrmProspectsPageToJSON(value?: CrmProspectsPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prospects': ((value.prospects as Array<any>).map(CrmProspectListEntryToJSON)),
        'total_count': value.total_count,
    };
}

