/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimeCharge } from './OneTimeCharge';
import {
    OneTimeChargeFromJSON,
    OneTimeChargeFromJSONTyped,
    OneTimeChargeToJSON,
} from './OneTimeCharge';

/**
 * 
 * @export
 * @interface CreateOneTimeCharge201Response
 */
export interface CreateOneTimeCharge201Response {
    /**
     * 
     * @type {OneTimeCharge}
     * @memberof CreateOneTimeCharge201Response
     */
    data?: OneTimeCharge;
}

/**
 * Check if a given object implements the CreateOneTimeCharge201Response interface.
 */
export function instanceOfCreateOneTimeCharge201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOneTimeCharge201ResponseFromJSON(json: any): CreateOneTimeCharge201Response {
    return CreateOneTimeCharge201ResponseFromJSONTyped(json, false);
}

export function CreateOneTimeCharge201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOneTimeCharge201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : OneTimeChargeFromJSON(json['data']),
    };
}

export function CreateOneTimeCharge201ResponseToJSON(value?: CreateOneTimeCharge201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': OneTimeChargeToJSON(value.data),
    };
}

