/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcessPaymentResponse } from './ProcessPaymentResponse';
import {
    ProcessPaymentResponseFromJSON,
    ProcessPaymentResponseFromJSONTyped,
    ProcessPaymentResponseToJSON,
} from './ProcessPaymentResponse';

/**
 * 
 * @export
 * @interface ProcessPayment200Response
 */
export interface ProcessPayment200Response {
    /**
     * 
     * @type {ProcessPaymentResponse}
     * @memberof ProcessPayment200Response
     */
    data?: ProcessPaymentResponse;
}

/**
 * Check if a given object implements the ProcessPayment200Response interface.
 */
export function instanceOfProcessPayment200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessPayment200ResponseFromJSON(json: any): ProcessPayment200Response {
    return ProcessPayment200ResponseFromJSONTyped(json, false);
}

export function ProcessPayment200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessPayment200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ProcessPaymentResponseFromJSON(json['data']),
    };
}

export function ProcessPayment200ResponseToJSON(value?: ProcessPayment200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ProcessPaymentResponseToJSON(value.data),
    };
}

