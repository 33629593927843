import { DEA_SCHEDULE_CODES } from '@/constants';

import { MedicationsMARFilters } from '../hooks/useMARContextAtoms';

import { MARMedicationModel } from './MARMedicationModel';

export interface FilterMARParams extends MedicationsMARFilters {
  query: string;
}

export class MARResponsePayload {
  daysInMonth: number;
  medications: MARMedicationModel[];
  usersInitialsMap: Record<string, string>;
  formattedInitials: string;
  searchableTerms: string;

  constructor(payload: MARResponsePayload) {
    this.daysInMonth = payload.daysInMonth;
    this.medications = payload.medications.map(
      (medication) => new MARMedicationModel(medication)
    );
    this.formattedInitials = this.formatInitials(payload.usersInitialsMap);
    this.usersInitialsMap = payload.usersInitialsMap;
  }

  private formatInitials(usersInitialsMap: Record<string, string>) {
    const joinedInitials = Object.keys(usersInitialsMap)
      .map((initials) => `${initials} (${usersInitialsMap[initials]})`)
      .join(', ');
    return joinedInitials.length
      ? `The assigned initials are as follows: ${joinedInitials}.`
      : '';
  }

  public filterMedications = (
    filters: FilterMARParams
  ): MARMedicationModel[] => {
    return this.medications.filter((medication) => {
      if (medication.is_informational) {
        return true;
      }

      let matchesQuery = true;
      let matchesScheduled = true;
      let matchesPrn = true;
      let matchesControlledSubstance = true;
      let matchesHasException = true;

      if (filters.query) {
        matchesQuery = medication.searchableTerms.includes(
          filters.query.toLowerCase()
        );
      }

      if (filters.scheduled) {
        matchesScheduled = !medication.is_prn;
      }

      if (filters.prn) {
        matchesPrn = medication.is_prn;
      }

      if (filters.controlled_substance) {
        matchesControlledSubstance =
          !!medication.fdb_dispensable_drug.FederalDEAClassCode &&
          DEA_SCHEDULE_CODES.includes(
            medication.fdb_dispensable_drug.FederalDEAClassCode
          );
      }

      if (filters.has_exception) {
        matchesHasException = medication.has_exception;
      }

      return (
        matchesQuery &&
        (matchesScheduled && matchesPrn
          ? true
          : (filters.scheduled && matchesScheduled) ||
            (filters.prn && matchesPrn)) &&
        matchesControlledSubstance &&
        matchesHasException
      );
    });
  };
}
