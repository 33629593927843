/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentDoctor
 */
export interface ResidentDoctor {
    /**
     * 
     * @type {string}
     * @memberof ResidentDoctor
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentDoctor
     */
    resident_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentDoctor
     */
    doctor_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentDoctor
     */
    is_primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResidentDoctor
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentDoctor
     */
    updatedAt?: string;
}

/**
 * Check if a given object implements the ResidentDoctor interface.
 */
export function instanceOfResidentDoctor(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResidentDoctorFromJSON(json: any): ResidentDoctor {
    return ResidentDoctorFromJSONTyped(json, false);
}

export function ResidentDoctorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentDoctor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': !exists(json, 'resident_id') ? undefined : json['resident_id'],
        'doctor_id': !exists(json, 'doctor_id') ? undefined : json['doctor_id'],
        'is_primary': !exists(json, 'is_primary') ? undefined : json['is_primary'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
    };
}

export function ResidentDoctorToJSON(value?: ResidentDoctor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'doctor_id': value.doctor_id,
        'is_primary': value.is_primary,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

