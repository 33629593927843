/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareSchemeListEntry } from './CareSchemeListEntry';
import {
    CareSchemeListEntryFromJSON,
    CareSchemeListEntryFromJSONTyped,
    CareSchemeListEntryToJSON,
} from './CareSchemeListEntry';

/**
 * 
 * @export
 * @interface FindAllCareSchemes200Response
 */
export interface FindAllCareSchemes200Response {
    /**
     * 
     * @type {Array<CareSchemeListEntry>}
     * @memberof FindAllCareSchemes200Response
     */
    data?: Array<CareSchemeListEntry>;
}

/**
 * Check if a given object implements the FindAllCareSchemes200Response interface.
 */
export function instanceOfFindAllCareSchemes200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCareSchemes200ResponseFromJSON(json: any): FindAllCareSchemes200Response {
    return FindAllCareSchemes200ResponseFromJSONTyped(json, false);
}

export function FindAllCareSchemes200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCareSchemes200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(CareSchemeListEntryFromJSON)),
    };
}

export function FindAllCareSchemes200ResponseToJSON(value?: FindAllCareSchemes200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(CareSchemeListEntryToJSON)),
    };
}

