/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentActionComment } from './IncidentActionComment';
import {
    IncidentActionCommentFromJSON,
    IncidentActionCommentFromJSONTyped,
    IncidentActionCommentToJSON,
} from './IncidentActionComment';

/**
 * 
 * @export
 * @interface CreateIncidentActionComment200Response
 */
export interface CreateIncidentActionComment200Response {
    /**
     * 
     * @type {IncidentActionComment}
     * @memberof CreateIncidentActionComment200Response
     */
    data?: IncidentActionComment;
}

/**
 * Check if a given object implements the CreateIncidentActionComment200Response interface.
 */
export function instanceOfCreateIncidentActionComment200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIncidentActionComment200ResponseFromJSON(json: any): CreateIncidentActionComment200Response {
    return CreateIncidentActionComment200ResponseFromJSONTyped(json, false);
}

export function CreateIncidentActionComment200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncidentActionComment200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentActionCommentFromJSON(json['data']),
    };
}

export function CreateIncidentActionComment200ResponseToJSON(value?: CreateIncidentActionComment200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentActionCommentToJSON(value.data),
    };
}

