/**
 * Re-usable middleware for checking if a new version of the app is available.
 * Used for both ky and fetch clients.
 * @param response Response
 * @returns Response
 */
export function newVersionMiddleware(response: Response) {
  const remoteConfigVersion = response.headers.get('x-version');
  if (!remoteConfigVersion || VERSION_NUMBER === null) {
    return response;
  }
  if (parseInt(remoteConfigVersion) > parseInt(VERSION_NUMBER)) {
    const a = document.getElementById('new-version-available-modal');
    a?.style.setProperty('display', 'block');
  }
  return response;
}
