import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { BLOOD_PRESSURE_VITAL_ID, VITAL_ID_TO_UNIT_MAP } from '@/constants';
import { VitalType } from '@/hooks/useVitalsQuery';

interface MedicationVitalFormProps {
  formMethods: UseFormReturn;
  disabled: boolean;
  vital: VitalType;
}

export const MedicationVitalsForm: React.FC<MedicationVitalFormProps> = ({
  formMethods,
  disabled,
  vital
}) => {
  const vitalEnabledFieldName = `vitals.${vital.id}.enabled`;
  const isVitalEnabled = formMethods.watch(vitalEnabledFieldName);

  return (
    <React.Fragment key={vital.id}>
      <Grid xs={12}>
        <Controller
          name={vitalEnabledFieldName}
          control={formMethods.control}
          render={({ field }) => (
            <FormControlLabel
              sx={{ px: 2 }}
              checked={!!field.value}
              onChange={field.onChange}
              control={<Checkbox />}
              label={vital.name}
              disabled={disabled}
            />
          )}
        />
      </Grid>
      {isVitalEnabled && (
        <>
          <Grid xs={1} />
          <Grid xs={10}>
            <Typography color="#667A86" fontSize="0.875rem">
              Would you like to set required min/max values? If no, leave blank.
            </Typography>
          </Grid>
          <Grid xs={1} />
          {vital.id === BLOOD_PRESSURE_VITAL_ID ? (
            <React.Fragment key={vital.id}>
              <Grid xs={1} />
              <Grid xs={10} md={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="Systolic min"
                  type="number"
                  name={`vitals.${BLOOD_PRESSURE_VITAL_ID}.minSystolicBloodPressure`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: {
                      isNotGreaterThanSystolicMax: (value) => {
                        const systolicMax = formMethods.getValues(
                          `vitals.${BLOOD_PRESSURE_VITAL_ID}.maxSystolicBloodPressure`
                        );
                        if (
                          value &&
                          Number(value) > Number(systolicMax) &&
                          Number(systolicMax) !== 0
                        ) {
                          return 'systolic min is more than max';
                        }
                        return true;
                      }
                    }
                  }}
                />
              </Grid>
              <Grid xs={1} display={{ md: 'none' }} />

              <Grid xs={1} display={{ md: 'none' }} />
              <Grid xs={10} md={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="Diastolic min"
                  type="number"
                  name={`vitals.${BLOOD_PRESSURE_VITAL_ID}.minDiastolicBloodPressure`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: {
                      isNotGreaterThanMaxDiastolic: (value) => {
                        const diastolicMax = formMethods.getValues(
                          `vitals.${BLOOD_PRESSURE_VITAL_ID}.maxDiastolicBloodPressure`
                        );

                        if (
                          value &&
                          Number(value) > Number(diastolicMax) &&
                          Number(diastolicMax) !== 0
                        ) {
                          return 'diastolic min is more than max';
                        }
                        return true;
                      }
                    }
                  }}
                />
              </Grid>
              <Grid xs={1} />

              <Grid xs={1} />
              <Grid xs={10} md={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="Systolic max"
                  type="number"
                  name={`vitals.${BLOOD_PRESSURE_VITAL_ID}.maxSystolicBloodPressure`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: {
                      isNotLessThanMinSystolic: (value) => {
                        const systolicMin = formMethods.getValues(
                          `vitals.${BLOOD_PRESSURE_VITAL_ID}.minSystolicBloodPressure`
                        );
                        if (
                          value &&
                          Number(value) < Number(systolicMin) &&
                          Number(value) !== 0
                        ) {
                          return 'systolic max is less than min';
                        }
                        return true;
                      }
                    }
                  }}
                />
              </Grid>
              <Grid xs={1} display={{ md: 'none' }} />

              <Grid xs={1} display={{ md: 'none' }} />
              <Grid xs={10} md={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="Diastolic max"
                  type="number"
                  name={`vitals.${BLOOD_PRESSURE_VITAL_ID}.maxDiastolicBloodPressure`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: {
                      isNotLessThanMinDiastolic: (value) => {
                        const diastolicMin = formMethods.getValues(
                          `vitals.${BLOOD_PRESSURE_VITAL_ID}.minDiastolicBloodPressure`
                        );

                        if (
                          value &&
                          Number(value) < Number(diastolicMin) &&
                          Number(value) !== 0
                        ) {
                          return 'diastolic max is less than min';
                        }
                        return true;
                      }
                    }
                  }}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment key={vital.id}>
              <Grid xs={1} />
              <Grid xs={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="min"
                  type="number"
                  name={`vitals.${vital.id}.min`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: (value) => {
                      const vitalMax = formMethods.getValues(
                        `vitals.${vital.id}.max`
                      );
                      return (
                        !value ||
                        !vitalMax ||
                        Number(value) <= Number(vitalMax) ||
                        'min is more than max'
                      );
                    }
                  }}
                />
              </Grid>
              <Grid xs={5}>
                <ControlledTextField
                  control={formMethods.control}
                  label="max"
                  type="number"
                  name={`vitals.${vital.id}.max`}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <Typography color="#9AAEBB" fontSize="1rem">
                        {VITAL_ID_TO_UNIT_MAP[vital?.id as string]}
                      </Typography>
                    )
                  }}
                  rules={{
                    validate: (value) => {
                      const vitalMin = formMethods.getValues(
                        `vitals.${vital.id}.min`
                      );
                      return (
                        !value ||
                        !vitalMin ||
                        Number(value) >= Number(vitalMin) ||
                        'max is less than min'
                      );
                    }
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
        </>
      )}
    </React.Fragment>
  );
};
