/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareScheme } from './CareScheme';
import {
    CareSchemeFromJSON,
    CareSchemeFromJSONTyped,
    CareSchemeToJSON,
} from './CareScheme';

/**
 * 
 * @export
 * @interface FindOneCareScheme200Response
 */
export interface FindOneCareScheme200Response {
    /**
     * 
     * @type {CareScheme}
     * @memberof FindOneCareScheme200Response
     */
    data?: CareScheme;
}

/**
 * Check if a given object implements the FindOneCareScheme200Response interface.
 */
export function instanceOfFindOneCareScheme200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOneCareScheme200ResponseFromJSON(json: any): FindOneCareScheme200Response {
    return FindOneCareScheme200ResponseFromJSONTyped(json, false);
}

export function FindOneCareScheme200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneCareScheme200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CareSchemeFromJSON(json['data']),
    };
}

export function FindOneCareScheme200ResponseToJSON(value?: FindOneCareScheme200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CareSchemeToJSON(value.data),
    };
}

