/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFollowupListEntry
 */
export interface IncidentFollowupListEntry {
    /**
     * User or User type id
     * @type {string}
     * @memberof IncidentFollowupListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFollowupListEntry
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFollowupListEntry
     */
    name: string;
}

/**
 * Check if a given object implements the IncidentFollowupListEntry interface.
 */
export function instanceOfIncidentFollowupListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IncidentFollowupListEntryFromJSON(json: any): IncidentFollowupListEntry {
    return IncidentFollowupListEntryFromJSONTyped(json, false);
}

export function IncidentFollowupListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFollowupListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'name': json['name'],
    };
}

export function IncidentFollowupListEntryToJSON(value?: IncidentFollowupListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
    };
}

