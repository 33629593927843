/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatient
 */
export interface PccPatient {
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    birth_date: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    gender: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    medicaid_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    medicare_number?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    social_beneficiary_identifier?: string;
}

/**
 * Check if a given object implements the PccPatient interface.
 */
export function instanceOfPccPatient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "birth_date" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "gender" in value;

    return isInstance;
}

export function PccPatientFromJSON(json: any): PccPatient {
    return PccPatientFromJSONTyped(json, false);
}

export function PccPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birth_date': json['birth_date'],
        'facility_id': json['facility_id'],
        'first_name': json['first_name'],
        'middle_name': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'last_name': json['last_name'],
        'gender': json['gender'],
        'medicaid_number': !exists(json, 'medicaid_number') ? undefined : json['medicaid_number'],
        'medicare_number': !exists(json, 'medicare_number') ? undefined : json['medicare_number'],
        'social_beneficiary_identifier': !exists(json, 'social_beneficiary_identifier') ? undefined : json['social_beneficiary_identifier'],
    };
}

export function PccPatientToJSON(value?: PccPatient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birth_date': value.birth_date,
        'facility_id': value.facility_id,
        'first_name': value.first_name,
        'middle_name': value.middle_name,
        'last_name': value.last_name,
        'gender': value.gender,
        'medicaid_number': value.medicaid_number,
        'medicare_number': value.medicare_number,
        'social_beneficiary_identifier': value.social_beneficiary_identifier,
    };
}

