/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicarePrimaryDiagnosis } from './MedicarePrimaryDiagnosis';
import {
    MedicarePrimaryDiagnosisFromJSON,
    MedicarePrimaryDiagnosisFromJSONTyped,
    MedicarePrimaryDiagnosisToJSON,
} from './MedicarePrimaryDiagnosis';
import type { PtOtClinicalCategory } from './PtOtClinicalCategory';
import {
    PtOtClinicalCategoryFromJSON,
    PtOtClinicalCategoryFromJSONTyped,
    PtOtClinicalCategoryToJSON,
} from './PtOtClinicalCategory';

/**
 * 
 * @export
 * @interface PtOtDiagnosisToClinicalCategoryMapping
 */
export interface PtOtDiagnosisToClinicalCategoryMapping {
    /**
     * 
     * @type {MedicarePrimaryDiagnosis}
     * @memberof PtOtDiagnosisToClinicalCategoryMapping
     */
    diagnosis: MedicarePrimaryDiagnosis;
    /**
     * 
     * @type {PtOtClinicalCategory}
     * @memberof PtOtDiagnosisToClinicalCategoryMapping
     */
    clinical_category: PtOtClinicalCategory;
}

/**
 * Check if a given object implements the PtOtDiagnosisToClinicalCategoryMapping interface.
 */
export function instanceOfPtOtDiagnosisToClinicalCategoryMapping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "diagnosis" in value;
    isInstance = isInstance && "clinical_category" in value;

    return isInstance;
}

export function PtOtDiagnosisToClinicalCategoryMappingFromJSON(json: any): PtOtDiagnosisToClinicalCategoryMapping {
    return PtOtDiagnosisToClinicalCategoryMappingFromJSONTyped(json, false);
}

export function PtOtDiagnosisToClinicalCategoryMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtDiagnosisToClinicalCategoryMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diagnosis': MedicarePrimaryDiagnosisFromJSON(json['diagnosis']),
        'clinical_category': PtOtClinicalCategoryFromJSON(json['clinical_category']),
    };
}

export function PtOtDiagnosisToClinicalCategoryMappingToJSON(value?: PtOtDiagnosisToClinicalCategoryMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diagnosis': MedicarePrimaryDiagnosisToJSON(value.diagnosis),
        'clinical_category': PtOtClinicalCategoryToJSON(value.clinical_category),
    };
}

