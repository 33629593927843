/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateV2Request,
  CountCRMResidents200Response,
  FindAllEmailAddressBook200Response,
  FindAllPreArrivalResidents200Response,
  FindDuplicatesForResident200Response,
  FindLatestAPFMUpdates200Response,
  ResidentMoveInRequest,
  ResolveDuplicatesRequest,
  UpdateProspectFacilityRequest,
} from '../models/index';
import {
    BulkUpdateV2RequestFromJSON,
    BulkUpdateV2RequestToJSON,
    CountCRMResidents200ResponseFromJSON,
    CountCRMResidents200ResponseToJSON,
    FindAllEmailAddressBook200ResponseFromJSON,
    FindAllEmailAddressBook200ResponseToJSON,
    FindAllPreArrivalResidents200ResponseFromJSON,
    FindAllPreArrivalResidents200ResponseToJSON,
    FindDuplicatesForResident200ResponseFromJSON,
    FindDuplicatesForResident200ResponseToJSON,
    FindLatestAPFMUpdates200ResponseFromJSON,
    FindLatestAPFMUpdates200ResponseToJSON,
    ResidentMoveInRequestFromJSON,
    ResidentMoveInRequestToJSON,
    ResolveDuplicatesRequestFromJSON,
    ResolveDuplicatesRequestToJSON,
    UpdateProspectFacilityRequestFromJSON,
    UpdateProspectFacilityRequestToJSON,
} from '../models/index';

export interface BulkUpdateV2OperationRequest {
    bulkUpdateV2Request: BulkUpdateV2Request;
}

export interface CountCRMResidentsRequest {
    facilityId: string;
}

export interface DeleteProspectRequest {
    id: string;
}

export interface FindAllEmailAddressBookRequest {
    facilityId: string;
    emails?: string;
    type?: string;
    resourceId?: string;
    searchQuery?: string;
    page?: number;
    limit?: number;
}

export interface FindAllPreArrivalResidentsRequest {
    facilityId: string;
    page?: string;
    limit?: string;
    residentName?: string;
}

export interface FindDuplicatesForResidentRequest {
    id: string;
}

export interface FindLatestAPFMUpdatesRequest {
    id: string;
}

export interface ResidentMoveInOperationRequest {
    residentMoveInRequest: ResidentMoveInRequest;
}

export interface ResolveDuplicatesOperationRequest {
    resolveDuplicatesRequest: ResolveDuplicatesRequest;
}

export interface UpdateProspectFacilityOperationRequest {
    id: string;
    updateProspectFacilityRequest: UpdateProspectFacilityRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * V2 Bulk Resident Info Update
     */
    async bulkUpdateV2Raw(requestParameters: BulkUpdateV2OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bulkUpdateV2Request === null || requestParameters.bulkUpdateV2Request === undefined) {
            throw new runtime.RequiredError('bulkUpdateV2Request','Required parameter requestParameters.bulkUpdateV2Request was null or undefined when calling bulkUpdateV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/resident-infos/bulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateV2RequestToJSON(requestParameters.bulkUpdateV2Request),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * V2 Bulk Resident Info Update
     */
    async bulkUpdateV2(requestParameters: BulkUpdateV2OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateV2Raw(requestParameters, initOverrides);
    }

    /**
     * Get count
     */
    async countCRMResidentsRaw(requestParameters: CountCRMResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountCRMResidents200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling countCRMResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-infos/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountCRMResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Get count
     */
    async countCRMResidents(requestParameters: CountCRMResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountCRMResidents200Response> {
        const response = await this.countCRMResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Prospect By Id
     */
    async deleteProspectRaw(requestParameters: DeleteProspectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProspect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{id}/prospect`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Prospect By Id
     */
    async deleteProspect(requestParameters: DeleteProspectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProspectRaw(requestParameters, initOverrides);
    }

    /**
     * Get email addresses from book
     */
    async findAllEmailAddressBookRaw(requestParameters: FindAllEmailAddressBookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllEmailAddressBook200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllEmailAddressBook.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.emails !== undefined) {
            queryParameters['emails'] = requestParameters.emails;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['search_query'] = requestParameters.searchQuery;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/email-address-book`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllEmailAddressBook200ResponseFromJSON(jsonValue));
    }

    /**
     * Get email addresses from book
     */
    async findAllEmailAddressBook(requestParameters: FindAllEmailAddressBookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllEmailAddressBook200Response> {
        const response = await this.findAllEmailAddressBookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all crm residents in the Pre-arrival lifecycle step
     */
    async findAllPreArrivalResidentsRaw(requestParameters: FindAllPreArrivalResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllPreArrivalResidents200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllPreArrivalResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.residentName !== undefined) {
            queryParameters['resident_name'] = requestParameters.residentName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/resident-infos/pre-arrival`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllPreArrivalResidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all crm residents in the Pre-arrival lifecycle step
     */
    async findAllPreArrivalResidents(requestParameters: FindAllPreArrivalResidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllPreArrivalResidents200Response> {
        const response = await this.findAllPreArrivalResidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find Duplicates for Resident by id
     */
    async findDuplicatesForResidentRaw(requestParameters: FindDuplicatesForResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindDuplicatesForResident200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findDuplicatesForResident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{id}/duplicate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindDuplicatesForResident200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Duplicates for Resident by id
     */
    async findDuplicatesForResident(requestParameters: FindDuplicatesForResidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindDuplicatesForResident200Response> {
        const response = await this.findDuplicatesForResidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Latest APFM Values
     */
    async findLatestAPFMUpdatesRaw(requestParameters: FindLatestAPFMUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindLatestAPFMUpdates200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findLatestAPFMUpdates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{id}/apfm`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindLatestAPFMUpdates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Latest APFM Values
     */
    async findLatestAPFMUpdates(requestParameters: FindLatestAPFMUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindLatestAPFMUpdates200Response> {
        const response = await this.findLatestAPFMUpdatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark Resident as Moved In
     */
    async residentMoveInRaw(requestParameters: ResidentMoveInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.residentMoveInRequest === null || requestParameters.residentMoveInRequest === undefined) {
            throw new runtime.RequiredError('residentMoveInRequest','Required parameter requestParameters.residentMoveInRequest was null or undefined when calling residentMoveIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/move-in`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentMoveInRequestToJSON(requestParameters.residentMoveInRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Resident as Moved In
     */
    async residentMoveIn(requestParameters: ResidentMoveInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.residentMoveInRaw(requestParameters, initOverrides);
    }

    /**
     * Resolve Duplicates
     */
    async resolveDuplicatesRaw(requestParameters: ResolveDuplicatesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resolveDuplicatesRequest === null || requestParameters.resolveDuplicatesRequest === undefined) {
            throw new runtime.RequiredError('resolveDuplicatesRequest','Required parameter requestParameters.resolveDuplicatesRequest was null or undefined when calling resolveDuplicates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/residents/duplicate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResolveDuplicatesRequestToJSON(requestParameters.resolveDuplicatesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resolve Duplicates
     */
    async resolveDuplicates(requestParameters: ResolveDuplicatesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resolveDuplicatesRaw(requestParameters, initOverrides);
    }

    /**
     * Update Prospect Facility
     */
    async updateProspectFacilityRaw(requestParameters: UpdateProspectFacilityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProspectFacility.');
        }

        if (requestParameters.updateProspectFacilityRequest === null || requestParameters.updateProspectFacilityRequest === undefined) {
            throw new runtime.RequiredError('updateProspectFacilityRequest','Required parameter requestParameters.updateProspectFacilityRequest was null or undefined when calling updateProspectFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resident-infos/{id}/facility`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProspectFacilityRequestToJSON(requestParameters.updateProspectFacilityRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Prospect Facility
     */
    async updateProspectFacility(requestParameters: UpdateProspectFacilityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProspectFacilityRaw(requestParameters, initOverrides);
    }

}
