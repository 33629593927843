/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentRecordType } from './PaymentRecordType';
import {
    PaymentRecordTypeFromJSON,
    PaymentRecordTypeFromJSONTyped,
    PaymentRecordTypeToJSON,
} from './PaymentRecordType';
import type { PaymentStatus } from './PaymentStatus';
import {
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';
import type { Refund } from './Refund';
import {
    RefundFromJSON,
    RefundFromJSONTyped,
    RefundToJSON,
} from './Refund';

/**
 * 
 * @export
 * @interface BillPaymentRecord
 */
export interface BillPaymentRecord {
    /**
     * 
     * @type {string}
     * @memberof BillPaymentRecord
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof BillPaymentRecord
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof BillPaymentRecord
     */
    refunded_amount: number;
    /**
     * 
     * @type {number}
     * @memberof BillPaymentRecord
     */
    pending_refund_amount: number;
    /**
     * 
     * @type {Date}
     * @memberof BillPaymentRecord
     */
    date: Date;
    /**
     * 
     * @type {PaymentRecordType}
     * @memberof BillPaymentRecord
     */
    type: PaymentRecordType;
    /**
     * 
     * @type {string}
     * @memberof BillPaymentRecord
     */
    description: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof BillPaymentRecord
     */
    status: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof BillPaymentRecord
     */
    fees?: number;
    /**
     * 
     * @type {Array<Refund>}
     * @memberof BillPaymentRecord
     */
    refunds: Array<Refund>;
    /**
     * 
     * @type {string}
     * @memberof BillPaymentRecord
     */
    original_payment_record_id: string;
}

/**
 * Check if a given object implements the BillPaymentRecord interface.
 */
export function instanceOfBillPaymentRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "refunded_amount" in value;
    isInstance = isInstance && "pending_refund_amount" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "refunds" in value;
    isInstance = isInstance && "original_payment_record_id" in value;

    return isInstance;
}

export function BillPaymentRecordFromJSON(json: any): BillPaymentRecord {
    return BillPaymentRecordFromJSONTyped(json, false);
}

export function BillPaymentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillPaymentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'refunded_amount': json['refunded_amount'],
        'pending_refund_amount': json['pending_refund_amount'],
        'date': (new Date(json['date'])),
        'type': PaymentRecordTypeFromJSON(json['type']),
        'description': json['description'],
        'status': PaymentStatusFromJSON(json['status']),
        'fees': !exists(json, 'fees') ? undefined : json['fees'],
        'refunds': ((json['refunds'] as Array<any>).map(RefundFromJSON)),
        'original_payment_record_id': json['original_payment_record_id'],
    };
}

export function BillPaymentRecordToJSON(value?: BillPaymentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'refunded_amount': value.refunded_amount,
        'pending_refund_amount': value.pending_refund_amount,
        'date': (value.date.toISOString().substring(0,10)),
        'type': PaymentRecordTypeToJSON(value.type),
        'description': value.description,
        'status': PaymentStatusToJSON(value.status),
        'fees': value.fees,
        'refunds': ((value.refunds as Array<any>).map(RefundToJSON)),
        'original_payment_record_id': value.original_payment_record_id,
    };
}

