/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOneTimeChargeRequest
 */
export interface UpdateOneTimeChargeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOneTimeChargeRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneTimeChargeRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOneTimeChargeRequest
     */
    price?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateOneTimeChargeRequest
     */
    effective_from?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneTimeChargeRequest
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneTimeChargeRequest
     */
    gl_code?: string;
}

/**
 * Check if a given object implements the UpdateOneTimeChargeRequest interface.
 */
export function instanceOfUpdateOneTimeChargeRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOneTimeChargeRequestFromJSON(json: any): UpdateOneTimeChargeRequest {
    return UpdateOneTimeChargeRequestFromJSONTyped(json, false);
}

export function UpdateOneTimeChargeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOneTimeChargeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'effective_from': !exists(json, 'effective_from') ? undefined : (new Date(json['effective_from'])),
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
    };
}

export function UpdateOneTimeChargeRequestToJSON(value?: UpdateOneTimeChargeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'price': value.price,
        'effective_from': value.effective_from === undefined ? undefined : (value.effective_from.toISOString().substring(0,10)),
        'category_id': value.category_id,
        'gl_code': value.gl_code,
    };
}

