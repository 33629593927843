/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NextPreviousBill
 */
export interface NextPreviousBill {
    /**
     * 
     * @type {string}
     * @memberof NextPreviousBill
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NextPreviousBill
     */
    resident_id?: string;
    /**
     * 
     * @type {string}
     * @memberof NextPreviousBill
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof NextPreviousBill
     */
    resident_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NextPreviousBill
     */
    resident_last_name?: string;
}

/**
 * Check if a given object implements the NextPreviousBill interface.
 */
export function instanceOfNextPreviousBill(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NextPreviousBillFromJSON(json: any): NextPreviousBill {
    return NextPreviousBillFromJSONTyped(json, false);
}

export function NextPreviousBillFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextPreviousBill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': !exists(json, 'resident_id') ? undefined : json['resident_id'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'resident_first_name': !exists(json, 'resident_first_name') ? undefined : json['resident_first_name'],
        'resident_last_name': !exists(json, 'resident_last_name') ? undefined : json['resident_last_name'],
    };
}

export function NextPreviousBillToJSON(value?: NextPreviousBill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'resident_photo_url': value.resident_photo_url,
        'resident_first_name': value.resident_first_name,
        'resident_last_name': value.resident_last_name,
    };
}

