/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DuplicateResidentListEntry
 */
export interface DuplicateResidentListEntry {
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    avatar?: string;
    /**
     * Refers to the sub header that specifies between residents and prospect residents
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    created_date: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidentListEntry
     */
    integration?: string;
}

/**
 * Check if a given object implements the DuplicateResidentListEntry interface.
 */
export function instanceOfDuplicateResidentListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created_date" in value;

    return isInstance;
}

export function DuplicateResidentListEntryFromJSON(json: any): DuplicateResidentListEntry {
    return DuplicateResidentListEntryFromJSONTyped(json, false);
}

export function DuplicateResidentListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateResidentListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'status': json['status'],
        'created_date': json['created_date'],
        'integration': !exists(json, 'integration') ? undefined : json['integration'],
    };
}

export function DuplicateResidentListEntryToJSON(value?: DuplicateResidentListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'phone': value.phone,
        'avatar': value.avatar,
        'status': value.status,
        'created_date': value.created_date,
        'integration': value.integration,
    };
}

