import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

import { AdminHubAssessmentRoutes } from './getAdminHubAssessmentRoutes';

const AdminHub = loadable(() => import("@/pages/AdminHub")); // prettier-ignore

const AdminHubIncidents = loadable(() => import("@/pages/AdminHub/IncidentsV2")); // prettier-ignore
const AdminHubIncidentsProtocol = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsProtocol")); // prettier-ignore
const AdminHubIncidentsProtocolCreate = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsProtocol/CreateIncidentProtocolPage")); // prettier-ignore
const AdminHubIncidentsReview = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsReview")); // prettier-ignore
const AdminHubIncidentsSettings = loadable(() => import("@/pages/AdminHub/IncidentsV2/IncidentsSettings")); // prettier-ignore
const AdminHubManageCustomMedList = loadable(() => import("@/pages/AdminHub/ManageCustomMedList")); // prettier-ignore
const AdminHubNotificationPreferences = loadable(() => import("@/pages/AdminHub/Notifications")); // prettier-ignore
const AdminHubFacilities = loadable(() => import("@/pages/AdminHub/Facilities")); // prettier-ignore
const AdminHubCommunitySettings = loadable(() => import("@/pages/AdminHub/CommunitySettings")); // prettier-ignore
const AssessmentBuilder = loadable(() => import("@/pages/AdminHub/AssessmentBuilder")); // prettier-ignore

export enum AdminHubGeneralRoutes {
  AdminHub = '/:domain/admin-hub',
  AdminHubNotificationPreferences = '/:domain/admin-hub/notification-preferences',
  AdminHubCommunities = '/:domain/admin-hub/communities',
  AdminHubCommunity = '/:domain/admin-hub/community/:facility_id',
  AdminHubManageCustomMedList = '/:domain/admin-hub/custom-med-list/:facility_id',
  AdminHubIncidents = '/:domain/admin-hub/incidents',
  AdminHubIncidentsReview = '/:domain/admin-hub/incidents/review',
  AdminHubIncidentsProtocol = '/:domain/admin-hub/incidents/protocols',
  AdminHubIncidentsSettings = '/:domain/admin-hub/incidents/settings',
  AdminHubIncidentsProtocolCreate = '/:domain/admin-hub/incidents/create-protocol/:protocol'
}

export const getAdminHubGeneralRoutes = (): RouteObject[] => {
  return [
    {
      path: AdminHubGeneralRoutes.AdminHub,
      element: <ProtectedRoute component={AdminHub} role="L1" />,
      children: [
        {
          path: AdminHubAssessmentRoutes.AdminHubAssessment,
          element: <AssessmentBuilder />
        },
        {
          path: AdminHubGeneralRoutes.AdminHubIncidents,
          element: <AdminHubIncidents />,
          children: [
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsReview,
              element: <AdminHubIncidentsReview />
            },
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsProtocol,
              element: <AdminHubIncidentsProtocol />
            },
            {
              path: AdminHubGeneralRoutes.AdminHubIncidentsSettings,
              element: <AdminHubIncidentsSettings />
            }
          ]
        },
        {
          path: AdminHubGeneralRoutes.AdminHubIncidentsProtocolCreate,
          element: <AdminHubIncidentsProtocolCreate />
        },

        {
          path: AdminHubGeneralRoutes.AdminHubCommunities,
          element: <AdminHubFacilities />
        },
        {
          path: AdminHubGeneralRoutes.AdminHubCommunity,
          element: <AdminHubCommunitySettings includeBackButton />
        },
        {
          path: AdminHubGeneralRoutes.AdminHubNotificationPreferences,
          element: (
            <ProtectedRoute
              role="L1"
              component={AdminHubNotificationPreferences}
            />
          )
        },
        {
          path: AdminHubGeneralRoutes.AdminHubManageCustomMedList,
          element: <AdminHubManageCustomMedList />
        }
      ]
    }
  ];
};
