/* tslint:disable */
/* eslint-disable */
/**
 * data-center
 * data-center
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardListEntryScope } from './DashboardListEntryScope';
import {
    DashboardListEntryScopeFromJSON,
    DashboardListEntryScopeFromJSONTyped,
    DashboardListEntryScopeToJSON,
} from './DashboardListEntryScope';

/**
 * 
 * @export
 * @interface DashboardListEntry
 */
export interface DashboardListEntry {
    /**
     * 
     * @type {string}
     * @memberof DashboardListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardListEntry
     */
    explo_dashboard_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardListEntry
     */
    is_admin: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardListEntry
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardListEntry
     */
    is_favorite: boolean;
    /**
     * 
     * @type {DashboardListEntryScope}
     * @memberof DashboardListEntry
     */
    scope: DashboardListEntryScope;
}

/**
 * Check if a given object implements the DashboardListEntry interface.
 */
export function instanceOfDashboardListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "explo_dashboard_id" in value;
    isInstance = isInstance && "is_admin" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "is_favorite" in value;
    isInstance = isInstance && "scope" in value;

    return isInstance;
}

export function DashboardListEntryFromJSON(json: any): DashboardListEntry {
    return DashboardListEntryFromJSONTyped(json, false);
}

export function DashboardListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'explo_dashboard_id': json['explo_dashboard_id'],
        'is_admin': json['is_admin'],
        'title': json['title'],
        'description': json['description'],
        'is_favorite': json['is_favorite'],
        'scope': DashboardListEntryScopeFromJSON(json['scope']),
    };
}

export function DashboardListEntryToJSON(value?: DashboardListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'explo_dashboard_id': value.explo_dashboard_id,
        'is_admin': value.is_admin,
        'title': value.title,
        'description': value.description,
        'is_favorite': value.is_favorite,
        'scope': DashboardListEntryScopeToJSON(value.scope),
    };
}

