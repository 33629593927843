/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateIncident200Response,
  CreateIncidentAction200Response,
  CreateIncidentActionComment200Response,
  CreateIncidentComment200Response,
  CreateIncidentFormComment200Response,
  CreateIncidentProtocolActionRequest,
  CreateIncidentProtocolFormRequest,
  CreateLocations200Response,
  CreatePostIncidentCommentRequest,
  CreateProtocol200Response,
  CreateProtocolAction200Response,
  CreateProtocolForm200Response,
  FindAllIncidentActions200Response,
  FindAllIncidentComments200Response,
  FindAllIncidentForms200Response,
  FindAllIncidents200Response,
  FindAllLocations200Response,
  FindAllProtocolActions200Response,
  FindAllProtocolForms200Response,
  FindAllProtocols200Response,
  FindIncidentAction200Response,
  FindIncidentById200Response,
  FindIncidentForm200Response,
  FindProtocolAction200Response,
  FindProtocolForm200Response,
  IncidentActionCreateRequest,
  IncidentCommentCreateRequest,
  IncidentCommentUpdateRequest,
  IncidentCreateRequest,
  IncidentFormUpdateRequest,
  IncidentProtocol,
  IncidentProtocolActionUpdateRequest,
  IncidentProtocolFormUpdateRequest,
  IncidentProtocolUpdateRequest,
  IncidentSettingCreateRequest,
  MarkIncidentCompleteRequest,
  MarkIncidentFormComplete422Response,
  MarkIncidentOption,
} from '../models/index';
import {
    CreateIncident200ResponseFromJSON,
    CreateIncident200ResponseToJSON,
    CreateIncidentAction200ResponseFromJSON,
    CreateIncidentAction200ResponseToJSON,
    CreateIncidentActionComment200ResponseFromJSON,
    CreateIncidentActionComment200ResponseToJSON,
    CreateIncidentComment200ResponseFromJSON,
    CreateIncidentComment200ResponseToJSON,
    CreateIncidentFormComment200ResponseFromJSON,
    CreateIncidentFormComment200ResponseToJSON,
    CreateIncidentProtocolActionRequestFromJSON,
    CreateIncidentProtocolActionRequestToJSON,
    CreateIncidentProtocolFormRequestFromJSON,
    CreateIncidentProtocolFormRequestToJSON,
    CreateLocations200ResponseFromJSON,
    CreateLocations200ResponseToJSON,
    CreatePostIncidentCommentRequestFromJSON,
    CreatePostIncidentCommentRequestToJSON,
    CreateProtocol200ResponseFromJSON,
    CreateProtocol200ResponseToJSON,
    CreateProtocolAction200ResponseFromJSON,
    CreateProtocolAction200ResponseToJSON,
    CreateProtocolForm200ResponseFromJSON,
    CreateProtocolForm200ResponseToJSON,
    FindAllIncidentActions200ResponseFromJSON,
    FindAllIncidentActions200ResponseToJSON,
    FindAllIncidentComments200ResponseFromJSON,
    FindAllIncidentComments200ResponseToJSON,
    FindAllIncidentForms200ResponseFromJSON,
    FindAllIncidentForms200ResponseToJSON,
    FindAllIncidents200ResponseFromJSON,
    FindAllIncidents200ResponseToJSON,
    FindAllLocations200ResponseFromJSON,
    FindAllLocations200ResponseToJSON,
    FindAllProtocolActions200ResponseFromJSON,
    FindAllProtocolActions200ResponseToJSON,
    FindAllProtocolForms200ResponseFromJSON,
    FindAllProtocolForms200ResponseToJSON,
    FindAllProtocols200ResponseFromJSON,
    FindAllProtocols200ResponseToJSON,
    FindIncidentAction200ResponseFromJSON,
    FindIncidentAction200ResponseToJSON,
    FindIncidentById200ResponseFromJSON,
    FindIncidentById200ResponseToJSON,
    FindIncidentForm200ResponseFromJSON,
    FindIncidentForm200ResponseToJSON,
    FindProtocolAction200ResponseFromJSON,
    FindProtocolAction200ResponseToJSON,
    FindProtocolForm200ResponseFromJSON,
    FindProtocolForm200ResponseToJSON,
    IncidentActionCreateRequestFromJSON,
    IncidentActionCreateRequestToJSON,
    IncidentCommentCreateRequestFromJSON,
    IncidentCommentCreateRequestToJSON,
    IncidentCommentUpdateRequestFromJSON,
    IncidentCommentUpdateRequestToJSON,
    IncidentCreateRequestFromJSON,
    IncidentCreateRequestToJSON,
    IncidentFormUpdateRequestFromJSON,
    IncidentFormUpdateRequestToJSON,
    IncidentProtocolFromJSON,
    IncidentProtocolToJSON,
    IncidentProtocolActionUpdateRequestFromJSON,
    IncidentProtocolActionUpdateRequestToJSON,
    IncidentProtocolFormUpdateRequestFromJSON,
    IncidentProtocolFormUpdateRequestToJSON,
    IncidentProtocolUpdateRequestFromJSON,
    IncidentProtocolUpdateRequestToJSON,
    IncidentSettingCreateRequestFromJSON,
    IncidentSettingCreateRequestToJSON,
    MarkIncidentCompleteRequestFromJSON,
    MarkIncidentCompleteRequestToJSON,
    MarkIncidentFormComplete422ResponseFromJSON,
    MarkIncidentFormComplete422ResponseToJSON,
    MarkIncidentOptionFromJSON,
    MarkIncidentOptionToJSON,
} from '../models/index';

export interface CreateActionTypesRequest {
    facilityId: string;
    incidentSettingCreateRequest: IncidentSettingCreateRequest;
}

export interface CreateConsciousnessRequest {
    facilityId: string;
    incidentSettingCreateRequest: IncidentSettingCreateRequest;
}

export interface CreateIncidentRequest {
    incidentCreateRequest: IncidentCreateRequest;
}

export interface CreateIncidentActionRequest {
    incidentActionCreateRequest: IncidentActionCreateRequest;
}

export interface CreateIncidentActionCommentRequest {
    id: string;
    createPostIncidentCommentRequest: CreatePostIncidentCommentRequest;
}

export interface CreateIncidentCommentRequest {
    incidentCommentCreateRequest: IncidentCommentCreateRequest;
}

export interface CreateIncidentFormCommentRequest {
    id: string;
    createPostIncidentCommentRequest: CreatePostIncidentCommentRequest;
}

export interface CreateLocationsRequest {
    facilityId: string;
    incidentSettingCreateRequest: IncidentSettingCreateRequest;
}

export interface CreatePainLevelsRequest {
    facilityId: string;
    incidentSettingCreateRequest: IncidentSettingCreateRequest;
}

export interface CreateProtocolRequest {
    incidentProtocol: IncidentProtocol;
}

export interface CreateProtocolActionRequest {
    createIncidentProtocolActionRequest: CreateIncidentProtocolActionRequest;
}

export interface CreateProtocolFormRequest {
    createIncidentProtocolFormRequest: CreateIncidentProtocolFormRequest;
}

export interface DeleteActionTypesRequest {
    id: string;
}

export interface DeleteConsciousnessRequest {
    id: string;
}

export interface DeleteIncidentActionCommentRequest {
    id: string;
}

export interface DeleteIncidentByIdRequest {
    id: string;
}

export interface DeleteIncidentCommentRequest {
    id: string;
}

export interface DeleteIncidentFormCommentRequest {
    id: string;
}

export interface DeleteLocationRequest {
    id: string;
}

export interface DeletePainLevelRequest {
    id: string;
}

export interface DeleteProtocolRequest {
    id: string;
}

export interface DeleteProtocolActionRequest {
    id: string;
}

export interface DeleteProtocolFormRequest {
    id: string;
}

export interface DuplicateProtocolRequest {
    facilityIds: string;
    id: string;
}

export interface FindAllActionTypesRequest {
    facilityId: string;
}

export interface FindAllConsciousnessRequest {
    facilityId: string;
}

export interface FindAllIncidentActionsRequest {
    id: string;
}

export interface FindAllIncidentCommentsRequest {
    id: string;
}

export interface FindAllIncidentFormsRequest {
    id: string;
}

export interface FindAllIncidentsRequest {
    facilityIds?: string;
    name?: string;
    page?: number;
    limit?: number;
    startDateUtc?: Date;
    endDateUtc?: Date;
    residentId?: string;
    incidentProtocolIds?: string;
    status?: string;
}

export interface FindAllLocationsRequest {
    facilityId: string;
}

export interface FindAllPainLevelsRequest {
    facilityId: string;
}

export interface FindAllProtocolActionsRequest {
    id: string;
}

export interface FindAllProtocolFormsRequest {
    id: string;
}

export interface FindAllProtocolsRequest {
    facilityId: string;
    condensed?: boolean;
}

export interface FindIncidentActionRequest {
    id: string;
}

export interface FindIncidentByIdRequest {
    id: string;
}

export interface FindIncidentFormRequest {
    id: string;
}

export interface FindProtocolActionRequest {
    id: string;
}

export interface FindProtocolFormRequest {
    id: string;
}

export interface MarkIncidentActionCancelledRequest {
    id: string;
    markIncidentOption: MarkIncidentOption;
}

export interface MarkIncidentActionCompleteRequest {
    id: string;
    markIncidentOption: MarkIncidentOption;
}

export interface MarkIncidentActionUnCancelledRequest {
    id: string;
}

export interface MarkIncidentCompleteOperationRequest {
    id: string;
    markIncidentCompleteRequest: MarkIncidentCompleteRequest;
}

export interface MarkIncidentFormCancelledRequest {
    id: string;
    markIncidentOption: MarkIncidentOption;
}

export interface MarkIncidentFormCompleteRequest {
    id: string;
    markIncidentOption: MarkIncidentOption;
    forceComplete?: boolean;
}

export interface MarkIncidentFormUnCancelledRequest {
    id: string;
}

export interface PublishProtocolRequest {
    id: string;
}

export interface UnPublishProtocolRequest {
    id: string;
}

export interface UpdateIncidentActionCommentRequest {
    id: string;
    incidentCommentUpdateRequest: IncidentCommentUpdateRequest;
}

export interface UpdateIncidentCommentRequest {
    id: string;
    incidentCommentUpdateRequest: IncidentCommentUpdateRequest;
}

export interface UpdateIncidentFormRequest {
    id: string;
    incidentFormUpdateRequest: IncidentFormUpdateRequest;
}

export interface UpdateIncidentFormCommentRequest {
    id: string;
    incidentCommentUpdateRequest: IncidentCommentUpdateRequest;
}

export interface UpdateProtocolRequest {
    id: string;
    incidentProtocolUpdateRequest: IncidentProtocolUpdateRequest;
}

export interface UpdateProtocolActionRequest {
    id: string;
    incidentProtocolActionUpdateRequest: IncidentProtocolActionUpdateRequest;
}

export interface UpdateProtocolFormRequest {
    id: string;
    incidentProtocolFormUpdateRequest: IncidentProtocolFormUpdateRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create action types
     */
    async createActionTypesRaw(requestParameters: CreateActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createActionTypes.');
        }

        if (requestParameters.incidentSettingCreateRequest === null || requestParameters.incidentSettingCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentSettingCreateRequest','Required parameter requestParameters.incidentSettingCreateRequest was null or undefined when calling createActionTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/action-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSettingCreateRequestToJSON(requestParameters.incidentSettingCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Create action types
     */
    async createActionTypes(requestParameters: CreateActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLocations200Response> {
        const response = await this.createActionTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create consciousness
     */
    async createConsciousnessRaw(requestParameters: CreateConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createConsciousness.');
        }

        if (requestParameters.incidentSettingCreateRequest === null || requestParameters.incidentSettingCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentSettingCreateRequest','Required parameter requestParameters.incidentSettingCreateRequest was null or undefined when calling createConsciousness.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/consciousness-levels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSettingCreateRequestToJSON(requestParameters.incidentSettingCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Create consciousness
     */
    async createConsciousness(requestParameters: CreateConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLocations200Response> {
        const response = await this.createConsciousnessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Incident
     */
    async createIncidentRaw(requestParameters: CreateIncidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIncident200Response>> {
        if (requestParameters.incidentCreateRequest === null || requestParameters.incidentCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentCreateRequest','Required parameter requestParameters.incidentCreateRequest was null or undefined when calling createIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incidentsv2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCreateRequestToJSON(requestParameters.incidentCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIncident200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Incident
     */
    async createIncident(requestParameters: CreateIncidentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIncident200Response> {
        const response = await this.createIncidentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Incident Action
     */
    async createIncidentActionRaw(requestParameters: CreateIncidentActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIncidentAction200Response>> {
        if (requestParameters.incidentActionCreateRequest === null || requestParameters.incidentActionCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentActionCreateRequest','Required parameter requestParameters.incidentActionCreateRequest was null or undefined when calling createIncidentAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentActionCreateRequestToJSON(requestParameters.incidentActionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIncidentAction200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Incident Action
     */
    async createIncidentAction(requestParameters: CreateIncidentActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIncidentAction200Response> {
        const response = await this.createIncidentActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Incident Action Comment
     */
    async createIncidentActionCommentRaw(requestParameters: CreateIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIncidentActionComment200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createIncidentActionComment.');
        }

        if (requestParameters.createPostIncidentCommentRequest === null || requestParameters.createPostIncidentCommentRequest === undefined) {
            throw new runtime.RequiredError('createPostIncidentCommentRequest','Required parameter requestParameters.createPostIncidentCommentRequest was null or undefined when calling createIncidentActionComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-actions/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePostIncidentCommentRequestToJSON(requestParameters.createPostIncidentCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIncidentActionComment200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Incident Action Comment
     */
    async createIncidentActionComment(requestParameters: CreateIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIncidentActionComment200Response> {
        const response = await this.createIncidentActionCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Incident Comment
     */
    async createIncidentCommentRaw(requestParameters: CreateIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIncidentComment200Response>> {
        if (requestParameters.incidentCommentCreateRequest === null || requestParameters.incidentCommentCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentCommentCreateRequest','Required parameter requestParameters.incidentCommentCreateRequest was null or undefined when calling createIncidentComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCommentCreateRequestToJSON(requestParameters.incidentCommentCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIncidentComment200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Incident Comment
     */
    async createIncidentComment(requestParameters: CreateIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIncidentComment200Response> {
        const response = await this.createIncidentCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Incident Form Comment
     */
    async createIncidentFormCommentRaw(requestParameters: CreateIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIncidentFormComment200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createIncidentFormComment.');
        }

        if (requestParameters.createPostIncidentCommentRequest === null || requestParameters.createPostIncidentCommentRequest === undefined) {
            throw new runtime.RequiredError('createPostIncidentCommentRequest','Required parameter requestParameters.createPostIncidentCommentRequest was null or undefined when calling createIncidentFormComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-forms/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePostIncidentCommentRequestToJSON(requestParameters.createPostIncidentCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIncidentFormComment200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Incident Form Comment
     */
    async createIncidentFormComment(requestParameters: CreateIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIncidentFormComment200Response> {
        const response = await this.createIncidentFormCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create locations
     */
    async createLocationsRaw(requestParameters: CreateLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createLocations.');
        }

        if (requestParameters.incidentSettingCreateRequest === null || requestParameters.incidentSettingCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentSettingCreateRequest','Required parameter requestParameters.incidentSettingCreateRequest was null or undefined when calling createLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSettingCreateRequestToJSON(requestParameters.incidentSettingCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Create locations
     */
    async createLocations(requestParameters: CreateLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLocations200Response> {
        const response = await this.createLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Pain Levels
     */
    async createPainLevelsRaw(requestParameters: CreatePainLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createPainLevels.');
        }

        if (requestParameters.incidentSettingCreateRequest === null || requestParameters.incidentSettingCreateRequest === undefined) {
            throw new runtime.RequiredError('incidentSettingCreateRequest','Required parameter requestParameters.incidentSettingCreateRequest was null or undefined when calling createPainLevels.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/pain-levels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentSettingCreateRequestToJSON(requestParameters.incidentSettingCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Pain Levels
     */
    async createPainLevels(requestParameters: CreatePainLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLocations200Response> {
        const response = await this.createPainLevelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create protocol
     */
    async createProtocolRaw(requestParameters: CreateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProtocol200Response>> {
        if (requestParameters.incidentProtocol === null || requestParameters.incidentProtocol === undefined) {
            throw new runtime.RequiredError('incidentProtocol','Required parameter requestParameters.incidentProtocol was null or undefined when calling createProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentProtocolToJSON(requestParameters.incidentProtocol),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProtocol200ResponseFromJSON(jsonValue));
    }

    /**
     * Create protocol
     */
    async createProtocol(requestParameters: CreateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProtocol200Response> {
        const response = await this.createProtocolRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create protocol action
     */
    async createProtocolActionRaw(requestParameters: CreateProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProtocolAction200Response>> {
        if (requestParameters.createIncidentProtocolActionRequest === null || requestParameters.createIncidentProtocolActionRequest === undefined) {
            throw new runtime.RequiredError('createIncidentProtocolActionRequest','Required parameter requestParameters.createIncidentProtocolActionRequest was null or undefined when calling createProtocolAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols/actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIncidentProtocolActionRequestToJSON(requestParameters.createIncidentProtocolActionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProtocolAction200ResponseFromJSON(jsonValue));
    }

    /**
     * Create protocol action
     */
    async createProtocolAction(requestParameters: CreateProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProtocolAction200Response> {
        const response = await this.createProtocolActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create protocol form
     */
    async createProtocolFormRaw(requestParameters: CreateProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProtocolForm200Response>> {
        if (requestParameters.createIncidentProtocolFormRequest === null || requestParameters.createIncidentProtocolFormRequest === undefined) {
            throw new runtime.RequiredError('createIncidentProtocolFormRequest','Required parameter requestParameters.createIncidentProtocolFormRequest was null or undefined when calling createProtocolForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols/forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIncidentProtocolFormRequestToJSON(requestParameters.createIncidentProtocolFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProtocolForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Create protocol form
     */
    async createProtocolForm(requestParameters: CreateProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProtocolForm200Response> {
        const response = await this.createProtocolFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete action types
     */
    async deleteActionTypesRaw(requestParameters: DeleteActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteActionTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/action-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete action types
     */
    async deleteActionTypes(requestParameters: DeleteActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteActionTypesRaw(requestParameters, initOverrides);
    }

    /**
     * Delete consciousness
     */
    async deleteConsciousnessRaw(requestParameters: DeleteConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsciousness.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/consciousness-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete consciousness
     */
    async deleteConsciousness(requestParameters: DeleteConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConsciousnessRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Incident Action Comment
     */
    async deleteIncidentActionCommentRaw(requestParameters: DeleteIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteIncidentActionComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-action-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Incident Action Comment
     */
    async deleteIncidentActionComment(requestParameters: DeleteIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIncidentActionCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Incident By Id
     */
    async deleteIncidentByIdRaw(requestParameters: DeleteIncidentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteIncidentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Incident By Id
     */
    async deleteIncidentById(requestParameters: DeleteIncidentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIncidentByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Incident Comment
     */
    async deleteIncidentCommentRaw(requestParameters: DeleteIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteIncidentComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Incident Comment
     */
    async deleteIncidentComment(requestParameters: DeleteIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIncidentCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Incident Form Comment
     */
    async deleteIncidentFormCommentRaw(requestParameters: DeleteIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteIncidentFormComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-form-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Incident Form Comment
     */
    async deleteIncidentFormComment(requestParameters: DeleteIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIncidentFormCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete location
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete location
     */
    async deleteLocation(requestParameters: DeleteLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLocationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Pain Level
     */
    async deletePainLevelRaw(requestParameters: DeletePainLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePainLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/pain-levels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Pain Level
     */
    async deletePainLevel(requestParameters: DeletePainLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePainLevelRaw(requestParameters, initOverrides);
    }

    /**
     * Delete protocol
     */
    async deleteProtocolRaw(requestParameters: DeleteProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete protocol
     */
    async deleteProtocol(requestParameters: DeleteProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProtocolRaw(requestParameters, initOverrides);
    }

    /**
     * Delete protocol action
     */
    async deleteProtocolActionRaw(requestParameters: DeleteProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProtocolAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete protocol action
     */
    async deleteProtocolAction(requestParameters: DeleteProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProtocolActionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete protocol form
     */
    async deleteProtocolFormRaw(requestParameters: DeleteProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProtocolForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete protocol form
     */
    async deleteProtocolForm(requestParameters: DeleteProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProtocolFormRaw(requestParameters, initOverrides);
    }

    /**
     * Duplicate protocol
     */
    async duplicateProtocolRaw(requestParameters: DuplicateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityIds === null || requestParameters.facilityIds === undefined) {
            throw new runtime.RequiredError('facilityIds','Required parameter requestParameters.facilityIds was null or undefined when calling duplicateProtocol.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling duplicateProtocol.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityIds !== undefined) {
            queryParameters['facility_ids'] = requestParameters.facilityIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}/duplicate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Duplicate protocol
     */
    async duplicateProtocol(requestParameters: DuplicateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.duplicateProtocolRaw(requestParameters, initOverrides);
    }

    /**
     * Get all action types
     */
    async findAllActionTypesRaw(requestParameters: FindAllActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllActionTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/action-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all action types
     */
    async findAllActionTypes(requestParameters: FindAllActionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLocations200Response> {
        const response = await this.findAllActionTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all consciousness
     */
    async findAllConsciousnessRaw(requestParameters: FindAllConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllConsciousness.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/consciousness-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all consciousness
     */
    async findAllConsciousness(requestParameters: FindAllConsciousnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLocations200Response> {
        const response = await this.findAllConsciousnessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all incident actions
     */
    async findAllIncidentActionsRaw(requestParameters: FindAllIncidentActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIncidentActions200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllIncidentActions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2/{id}/actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIncidentActions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all incident actions
     */
    async findAllIncidentActions(requestParameters: FindAllIncidentActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIncidentActions200Response> {
        const response = await this.findAllIncidentActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all incident comments
     */
    async findAllIncidentCommentsRaw(requestParameters: FindAllIncidentCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIncidentComments200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllIncidentComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIncidentComments200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all incident comments
     */
    async findAllIncidentComments(requestParameters: FindAllIncidentCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIncidentComments200Response> {
        const response = await this.findAllIncidentCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all incident forms
     */
    async findAllIncidentFormsRaw(requestParameters: FindAllIncidentFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIncidentForms200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllIncidentForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2/{id}/forms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIncidentForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all incident forms
     */
    async findAllIncidentForms(requestParameters: FindAllIncidentFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIncidentForms200Response> {
        const response = await this.findAllIncidentFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find All Incidents
     */
    async findAllIncidentsRaw(requestParameters: FindAllIncidentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIncidents200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityIds !== undefined) {
            queryParameters['facility_ids'] = requestParameters.facilityIds;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.startDateUtc !== undefined) {
            queryParameters['start_date_utc'] = (requestParameters.startDateUtc as any).toISOString();
        }

        if (requestParameters.endDateUtc !== undefined) {
            queryParameters['end_date_utc'] = (requestParameters.endDateUtc as any).toISOString();
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.incidentProtocolIds !== undefined) {
            queryParameters['incident_protocol_ids'] = requestParameters.incidentProtocolIds;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIncidents200ResponseFromJSON(jsonValue));
    }

    /**
     * Find All Incidents
     */
    async findAllIncidents(requestParameters: FindAllIncidentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIncidents200Response> {
        const response = await this.findAllIncidentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all locations
     */
    async findAllLocationsRaw(requestParameters: FindAllLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all locations
     */
    async findAllLocations(requestParameters: FindAllLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLocations200Response> {
        const response = await this.findAllLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pain levels
     */
    async findAllPainLevelsRaw(requestParameters: FindAllPainLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLocations200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllPainLevels.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/pain-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLocations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all pain levels
     */
    async findAllPainLevels(requestParameters: FindAllPainLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLocations200Response> {
        const response = await this.findAllPainLevelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all protocol actions
     */
    async findAllProtocolActionsRaw(requestParameters: FindAllProtocolActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllProtocolActions200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllProtocolActions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}/actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllProtocolActions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all protocol actions
     */
    async findAllProtocolActions(requestParameters: FindAllProtocolActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllProtocolActions200Response> {
        const response = await this.findAllProtocolActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all protocol forms
     */
    async findAllProtocolFormsRaw(requestParameters: FindAllProtocolFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllProtocolForms200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllProtocolForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}/forms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllProtocolForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all protocol forms
     */
    async findAllProtocolForms(requestParameters: FindAllProtocolFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllProtocolForms200Response> {
        const response = await this.findAllProtocolFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all protocols
     */
    async findAllProtocolsRaw(requestParameters: FindAllProtocolsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllProtocols200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllProtocols.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.condensed !== undefined) {
            queryParameters['condensed'] = requestParameters.condensed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllProtocols200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all protocols
     */
    async findAllProtocols(requestParameters: FindAllProtocolsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllProtocols200Response> {
        const response = await this.findAllProtocolsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Incident Action
     */
    async findIncidentActionRaw(requestParameters: FindIncidentActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindIncidentAction200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findIncidentAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindIncidentAction200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Incident Action
     */
    async findIncidentAction(requestParameters: FindIncidentActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindIncidentAction200Response> {
        const response = await this.findIncidentActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find Incident
     */
    async findIncidentByIdRaw(requestParameters: FindIncidentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindIncidentById200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findIncidentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incidentsv2/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindIncidentById200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Incident
     */
    async findIncidentById(requestParameters: FindIncidentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindIncidentById200Response> {
        const response = await this.findIncidentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Incident Form
     */
    async findIncidentFormRaw(requestParameters: FindIncidentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindIncidentForm200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findIncidentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindIncidentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Incident Form
     */
    async findIncidentForm(requestParameters: FindIncidentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindIncidentForm200Response> {
        const response = await this.findIncidentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get protocol action
     */
    async findProtocolActionRaw(requestParameters: FindProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindProtocolAction200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findProtocolAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindProtocolAction200ResponseFromJSON(jsonValue));
    }

    /**
     * Get protocol action
     */
    async findProtocolAction(requestParameters: FindProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindProtocolAction200Response> {
        const response = await this.findProtocolActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get protocol form
     */
    async findProtocolFormRaw(requestParameters: FindProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindProtocolForm200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findProtocolForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindProtocolForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Get protocol form
     */
    async findProtocolForm(requestParameters: FindProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindProtocolForm200Response> {
        const response = await this.findProtocolFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark Incident Action Cancelled
     */
    async markIncidentActionCancelledRaw(requestParameters: MarkIncidentActionCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentActionCancelled.');
        }

        if (requestParameters.markIncidentOption === null || requestParameters.markIncidentOption === undefined) {
            throw new runtime.RequiredError('markIncidentOption','Required parameter requestParameters.markIncidentOption was null or undefined when calling markIncidentActionCancelled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-actions/{id}/mark-cancelled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkIncidentOptionToJSON(requestParameters.markIncidentOption),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Action Cancelled
     */
    async markIncidentActionCancelled(requestParameters: MarkIncidentActionCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentActionCancelledRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Action Complete
     */
    async markIncidentActionCompleteRaw(requestParameters: MarkIncidentActionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentActionComplete.');
        }

        if (requestParameters.markIncidentOption === null || requestParameters.markIncidentOption === undefined) {
            throw new runtime.RequiredError('markIncidentOption','Required parameter requestParameters.markIncidentOption was null or undefined when calling markIncidentActionComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-actions/{id}/mark-complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkIncidentOptionToJSON(requestParameters.markIncidentOption),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Action Complete
     */
    async markIncidentActionComplete(requestParameters: MarkIncidentActionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentActionCompleteRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Action UnCancelled
     */
    async markIncidentActionUnCancelledRaw(requestParameters: MarkIncidentActionUnCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentActionUnCancelled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-actions/{id}/mark-uncancelled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Action UnCancelled
     */
    async markIncidentActionUnCancelled(requestParameters: MarkIncidentActionUnCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentActionUnCancelledRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Complete
     */
    async markIncidentCompleteRaw(requestParameters: MarkIncidentCompleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentComplete.');
        }

        if (requestParameters.markIncidentCompleteRequest === null || requestParameters.markIncidentCompleteRequest === undefined) {
            throw new runtime.RequiredError('markIncidentCompleteRequest','Required parameter requestParameters.markIncidentCompleteRequest was null or undefined when calling markIncidentComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incidentsv2/{id}/mark-complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkIncidentCompleteRequestToJSON(requestParameters.markIncidentCompleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Complete
     */
    async markIncidentComplete(requestParameters: MarkIncidentCompleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentCompleteRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Form Cancelled
     */
    async markIncidentFormCancelledRaw(requestParameters: MarkIncidentFormCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentFormCancelled.');
        }

        if (requestParameters.markIncidentOption === null || requestParameters.markIncidentOption === undefined) {
            throw new runtime.RequiredError('markIncidentOption','Required parameter requestParameters.markIncidentOption was null or undefined when calling markIncidentFormCancelled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-forms/{id}/mark-cancelled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkIncidentOptionToJSON(requestParameters.markIncidentOption),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Form Cancelled
     */
    async markIncidentFormCancelled(requestParameters: MarkIncidentFormCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentFormCancelledRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Form Complete
     */
    async markIncidentFormCompleteRaw(requestParameters: MarkIncidentFormCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentFormComplete.');
        }

        if (requestParameters.markIncidentOption === null || requestParameters.markIncidentOption === undefined) {
            throw new runtime.RequiredError('markIncidentOption','Required parameter requestParameters.markIncidentOption was null or undefined when calling markIncidentFormComplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.forceComplete !== undefined) {
            queryParameters['force_complete'] = requestParameters.forceComplete;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-forms/{id}/mark-complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkIncidentOptionToJSON(requestParameters.markIncidentOption),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Form Complete
     */
    async markIncidentFormComplete(requestParameters: MarkIncidentFormCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentFormCompleteRaw(requestParameters, initOverrides);
    }

    /**
     * Mark Incident Form UnCancelled
     */
    async markIncidentFormUnCancelledRaw(requestParameters: MarkIncidentFormUnCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncidentFormUnCancelled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/incident-forms/{id}/mark-uncancelled`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Incident Form UnCancelled
     */
    async markIncidentFormUnCancelled(requestParameters: MarkIncidentFormUnCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIncidentFormUnCancelledRaw(requestParameters, initOverrides);
    }

    /**
     * Publish protocol
     */
    async publishProtocolRaw(requestParameters: PublishProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publishProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish protocol
     */
    async publishProtocol(requestParameters: PublishProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishProtocolRaw(requestParameters, initOverrides);
    }

    /**
     * UnPublish protocol
     */
    async unPublishProtocolRaw(requestParameters: UnPublishProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unPublishProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/protocols/{id}/unpublish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * UnPublish protocol
     */
    async unPublishProtocol(requestParameters: UnPublishProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unPublishProtocolRaw(requestParameters, initOverrides);
    }

    /**
     * Update Incident Action Comment
     */
    async updateIncidentActionCommentRaw(requestParameters: UpdateIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateIncidentActionComment.');
        }

        if (requestParameters.incidentCommentUpdateRequest === null || requestParameters.incidentCommentUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentCommentUpdateRequest','Required parameter requestParameters.incidentCommentUpdateRequest was null or undefined when calling updateIncidentActionComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-action-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCommentUpdateRequestToJSON(requestParameters.incidentCommentUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Incident Action Comment
     */
    async updateIncidentActionComment(requestParameters: UpdateIncidentActionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIncidentActionCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Update Incident Comment
     */
    async updateIncidentCommentRaw(requestParameters: UpdateIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateIncidentComment.');
        }

        if (requestParameters.incidentCommentUpdateRequest === null || requestParameters.incidentCommentUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentCommentUpdateRequest','Required parameter requestParameters.incidentCommentUpdateRequest was null or undefined when calling updateIncidentComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCommentUpdateRequestToJSON(requestParameters.incidentCommentUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Incident Comment
     */
    async updateIncidentComment(requestParameters: UpdateIncidentCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIncidentCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Update Incident Form
     */
    async updateIncidentFormRaw(requestParameters: UpdateIncidentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateIncidentForm.');
        }

        if (requestParameters.incidentFormUpdateRequest === null || requestParameters.incidentFormUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentFormUpdateRequest','Required parameter requestParameters.incidentFormUpdateRequest was null or undefined when calling updateIncidentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentFormUpdateRequestToJSON(requestParameters.incidentFormUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Incident Form
     */
    async updateIncidentForm(requestParameters: UpdateIncidentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIncidentFormRaw(requestParameters, initOverrides);
    }

    /**
     * Update Incident Form Comment
     */
    async updateIncidentFormCommentRaw(requestParameters: UpdateIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateIncidentFormComment.');
        }

        if (requestParameters.incidentCommentUpdateRequest === null || requestParameters.incidentCommentUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentCommentUpdateRequest','Required parameter requestParameters.incidentCommentUpdateRequest was null or undefined when calling updateIncidentFormComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/incident-form-comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentCommentUpdateRequestToJSON(requestParameters.incidentCommentUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Incident Form Comment
     */
    async updateIncidentFormComment(requestParameters: UpdateIncidentFormCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIncidentFormCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Update protocol
     */
    async updateProtocolRaw(requestParameters: UpdateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProtocol.');
        }

        if (requestParameters.incidentProtocolUpdateRequest === null || requestParameters.incidentProtocolUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentProtocolUpdateRequest','Required parameter requestParameters.incidentProtocolUpdateRequest was null or undefined when calling updateProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentProtocolUpdateRequestToJSON(requestParameters.incidentProtocolUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update protocol
     */
    async updateProtocol(requestParameters: UpdateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProtocolRaw(requestParameters, initOverrides);
    }

    /**
     * Update protocol action
     */
    async updateProtocolActionRaw(requestParameters: UpdateProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProtocolAction.');
        }

        if (requestParameters.incidentProtocolActionUpdateRequest === null || requestParameters.incidentProtocolActionUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentProtocolActionUpdateRequest','Required parameter requestParameters.incidentProtocolActionUpdateRequest was null or undefined when calling updateProtocolAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols/actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentProtocolActionUpdateRequestToJSON(requestParameters.incidentProtocolActionUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update protocol action
     */
    async updateProtocolAction(requestParameters: UpdateProtocolActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProtocolActionRaw(requestParameters, initOverrides);
    }

    /**
     * Update protocol form
     */
    async updateProtocolFormRaw(requestParameters: UpdateProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProtocolForm.');
        }

        if (requestParameters.incidentProtocolFormUpdateRequest === null || requestParameters.incidentProtocolFormUpdateRequest === undefined) {
            throw new runtime.RequiredError('incidentProtocolFormUpdateRequest','Required parameter requestParameters.incidentProtocolFormUpdateRequest was null or undefined when calling updateProtocolForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/protocols/forms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentProtocolFormUpdateRequestToJSON(requestParameters.incidentProtocolFormUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update protocol form
     */
    async updateProtocolForm(requestParameters: UpdateProtocolFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProtocolFormRaw(requestParameters, initOverrides);
    }

}
