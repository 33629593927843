import {
  useQueries,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import fetchExaCare from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom,
  useFacilitiesQuery
} from '@/hooks/useFacilitiesQuery';
import { AllTaskSummaryPayload } from '@/models/ResidentModel';

const QUERY_KEY = 'useAllTasksDashboardQuery';

export const useAllTasksDashboardQuery = (
  options: UseQueryOptions<AllTaskSummaryPayload> = {}
) => {
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom);
  let facilitiesToFetch: string[];
  const { data: facilities = [] } = useFacilitiesQuery().findAll();
  if (selectedFacilityId === ALL_FACILITIES) {
    facilitiesToFetch = facilities.map((v) => v.id);
  } else {
    facilitiesToFetch = selectedFacilityId ? [selectedFacilityId] : [];
  }
  const queries = useQueries({
    queries: facilitiesToFetch.map((facility) => ({
      queryKey: [QUERY_KEY, facility],
      queryFn: () => {
        return fetchExaCare.get<AllTaskSummaryPayload>(
          `/dashboard/residents/task-summaries`,
          {
            searchParams: {
              facilityIds: facility,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
          }
        );
      },
      enabled: !!selectedFacilityId,
      ...options
    }))
  });

  const facilityToQueryMap = new Map<
    string,
    UseQueryResult<AllTaskSummaryPayload>
  >();
  for (let i = 0; i < facilitiesToFetch.length; ++i) {
    facilityToQueryMap.set(facilitiesToFetch[i], queries[i]);
  }

  const allTaskSummaries: AllTaskSummaryPayload = [];
  queries
    .map((v) => v.data)
    .forEach((v) => {
      if (v) {
        allTaskSummaries.push(...v);
      }
    });
  return {
    allTaskSummaries,
    facilityToQueryMap
  };
};

export const invalidateAllTasksDashboardQuery = () => {
  queryClient.invalidateQueries([QUERY_KEY]);
};
