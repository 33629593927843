/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionsFindAllResponse } from './PrescriptionsFindAllResponse';
import {
    PrescriptionsFindAllResponseFromJSON,
    PrescriptionsFindAllResponseFromJSONTyped,
    PrescriptionsFindAllResponseToJSON,
} from './PrescriptionsFindAllResponse';

/**
 * 
 * @export
 * @interface FindAllPrescriptions200Response
 */
export interface FindAllPrescriptions200Response {
    /**
     * 
     * @type {PrescriptionsFindAllResponse}
     * @memberof FindAllPrescriptions200Response
     */
    data?: PrescriptionsFindAllResponse;
}

/**
 * Check if a given object implements the FindAllPrescriptions200Response interface.
 */
export function instanceOfFindAllPrescriptions200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllPrescriptions200ResponseFromJSON(json: any): FindAllPrescriptions200Response {
    return FindAllPrescriptions200ResponseFromJSONTyped(json, false);
}

export function FindAllPrescriptions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllPrescriptions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : PrescriptionsFindAllResponseFromJSON(json['data']),
    };
}

export function FindAllPrescriptions200ResponseToJSON(value?: FindAllPrescriptions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PrescriptionsFindAllResponseToJSON(value.data),
    };
}

