import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { fetchResidents as fetchResidentsApi } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { AVAILABLE_ROOMS_QUERY_KEY } from '@/components/AvailableRoomsAutocomplete/useAvailableRooms';
import { ALL_FACILITIES } from '@/hooks/useFacilitiesQuery';
import { ResidentModel } from '@/models/ResidentModel';
import { invalidateAudiencesQueries } from '@/pages/CRM/hooks/useAudienceQuery';
import {
  CRM_TASK_INSTANCES_COUNT_QUERY_KEY,
  CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
} from '@/pages/CRM/hooks/useCrmTaskInstancesQuery';
import { CRM_TASKS_FIND_ONE_QUERY_KEY } from '@/pages/CRM/hooks/useCrmTasksQuery';

import { invalidateResidentsQueriesV2 } from './residentsv2/useResidentsQuery';
import {
  invalidateProspectResidentsQueries,
  PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
} from './useProspectResidents';
import { ResidentPayload } from './useResidentFacesheetsQuery';
import { useSnackbar } from './useSnackbar';

interface FetchResidentsArgs {
  facilityId?: null | string | typeof ALL_FACILITIES;
  timezone?: boolean;
  residentId?: string;
  residentGroup?: boolean;
  archived?: boolean;
  includeTags?: boolean;
  addToRecentlyViewed?: boolean;
}

interface FetchResidentsParams {
  facility?: string;
  timezone?: boolean;
  resident_group?: boolean;
  archived?: boolean;
  tags?: boolean;
  add_to_recently_viewed?: boolean;
}

async function fetchResidents(args: FetchResidentsArgs) {
  const {
    facilityId,
    timezone,
    residentId,
    residentGroup,
    archived = false,
    includeTags = false,
    addToRecentlyViewed = false
  } = args;

  const url = residentId ? `/residents/${residentId}` : '/residents';

  const searchParams: FetchResidentsParams = {};
  if (facilityId && facilityId !== ALL_FACILITIES) {
    searchParams.facility = facilityId;
  }
  if (timezone && timezone === true) {
    searchParams.timezone = true;
  }
  if (residentGroup && residentGroup === true) {
    searchParams.resident_group = true;
  }
  if (archived && archived === true) {
    searchParams.archived = true;
  }
  if (includeTags) {
    searchParams.tags = true;
  }
  if (addToRecentlyViewed) {
    searchParams.add_to_recently_viewed = true;
  }

  const residents = await fetchResidentsApi
    .get<ResidentPayload[]>(url, { searchParams })
    .then((residents) => (Array.isArray(residents) ? residents : [residents]));

  return residents.map((resident) => {
    return new ResidentModel(resident);
  });
}

export const invalidateResidentsQueries = () => {
  queryClient.invalidateQueries([FIND_ONE_RESIDENT_QUERY_KEY]);
  queryClient.invalidateQueries([FIND_ALL_RESIDENTS_QUERY_KEY]);
};

export const FIND_ONE_RESIDENT_QUERY_KEY = 'useFindOneResidentQuery';
export const FIND_ALL_RESIDENTS_QUERY_KEY = 'useFindAllResidentsQuery';

interface FindResidentParams {
  residentId?: string;
  timezone?: boolean;
  facilityId?: string;
  addToRecentlyViewed?: boolean;
}

// Query options are split out to allow for useQueries consumption
export const findOneResidentQueryOptions = ({
  residentId,
  timezone,
  facilityId,
  addToRecentlyViewed
}: FindResidentParams) => ({
  queryKey: [
    FIND_ONE_RESIDENT_QUERY_KEY,
    { residentId, timezone, addToRecentlyViewed }
  ],
  queryFn: async () => {
    return await fetchResidents({
      facilityId,
      residentId,
      timezone,
      residentGroup: true,
      addToRecentlyViewed
    }).then(([resident]) => resident);
  }
});

export function useFindOneResidentQuery(
  { residentId, timezone, facilityId, addToRecentlyViewed }: FindResidentParams,
  options = {} as UseQueryOptions
) {
  return useQuery<ResidentModel>({
    ...findOneResidentQueryOptions({
      residentId,
      timezone,
      facilityId,
      addToRecentlyViewed
    }),
    enabled: !!residentId,
    ...(options as any)
  });
}

export function useFindAllResidentsQuery(
  facilityId?: string | null | typeof ALL_FACILITIES,
  archived?: boolean,
  includeTags?: boolean,
  options = {} as UseQueryOptions<ResidentModel[]>
) {
  return useQuery<ResidentModel[]>(
    [FIND_ALL_RESIDENTS_QUERY_KEY, { facilityId, archived, includeTags }],
    () =>
      fetchResidents({
        facilityId,
        residentGroup: true,
        archived,
        includeTags
      }),
    options
  );
}

interface AddResidentPostBody {
  facility_id: string;
  resident: ResidentPayload;
  tzid: string;
}
export function useAddResident(options: UseMutationOptions = {}) {
  const { showSnackbar } = useSnackbar();
  return useMutation(
    (payload: ResidentPayload) => {
      const { facility_id, timezone, ...body } = payload;
      return fetchResidentsApi.post<ResidentPayload>('/residents', {
        facility_id,
        resident: body,
        tzid: timezone!.id,
        ...({
          vitals: [],
          allergies: [],
          medical_history: [],
          limitations: [],
          cognitive_statuses: [],
          nursing_treatment: [],
          resident_records: []
        } as any)
      } as AddResidentPostBody);
    },
    {
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries([FIND_ALL_RESIDENTS_QUERY_KEY]);
        queryClient.invalidateQueries([AVAILABLE_ROOMS_QUERY_KEY]);
        invalidateResidentsQueriesV2();
        showSnackbar({
          message: `Resident created: ${payload.first_name}`,
          severity: 'success'
        });
      },
      onError: async (error) => {
        if (error instanceof HTTPError && error.response.status === 400) {
          const message = await error.response.text();
          showSnackbar({
            message,
            severity: 'error'
          });
          return;
        }

        showSnackbar({
          message: 'Error creating resident, please try again',
          severity: 'error'
        });
      },
      ...(options as any)
    }
  );
}

export function useDeleteResident(options: UseMutationOptions = {}) {
  return useMutation(
    (residentId: string) => {
      return fetchResidentsApi.delete(`/residents/${residentId}`);
    },
    {
      onSuccess: (data, residentId) => {
        queryClient.invalidateQueries([FIND_ALL_RESIDENTS_QUERY_KEY]);
        queryClient.invalidateQueries([
          FIND_ONE_RESIDENT_QUERY_KEY,
          { residentId }
        ]);
        invalidateProspectResidentsQueries();
        queryClient.invalidateQueries([
          PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY,
          residentId
        ]);
        // Deactivating the resident also deletes all CRM tasks associated with the resident
        queryClient.invalidateQueries([CRM_TASKS_FIND_ONE_QUERY_KEY]);
        queryClient.invalidateQueries([CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY]);
        queryClient.invalidateQueries([CRM_TASK_INSTANCES_COUNT_QUERY_KEY]);
        invalidateAudiencesQueries();
        invalidateResidentsQueriesV2();
      },
      ...(options as any)
    }
  );
}
