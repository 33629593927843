/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordUnscheduledCareRequest
 */
export interface RecordUnscheduledCareRequest {
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    care_category_id: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    responsible_party_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    completed_by_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    date_completed: string;
    /**
     * 
     * @type {number}
     * @memberof RecordUnscheduledCareRequest
     */
    length_mins?: number;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    care_plan_assistance_level_id: string;
    /**
     * 
     * @type {number}
     * @memberof RecordUnscheduledCareRequest
     */
    number_of_assistants?: number;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    instructions?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordUnscheduledCareRequest
     */
    pin: string;
}

/**
 * Check if a given object implements the RecordUnscheduledCareRequest interface.
 */
export function instanceOfRecordUnscheduledCareRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "care_category_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "completed_by_user_id" in value;
    isInstance = isInstance && "date_completed" in value;
    isInstance = isInstance && "care_plan_assistance_level_id" in value;
    isInstance = isInstance && "pin" in value;

    return isInstance;
}

export function RecordUnscheduledCareRequestFromJSON(json: any): RecordUnscheduledCareRequest {
    return RecordUnscheduledCareRequestFromJSONTyped(json, false);
}

export function RecordUnscheduledCareRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordUnscheduledCareRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'care_category_id': json['care_category_id'],
        'responsible_party_id': !exists(json, 'responsible_party_id') ? undefined : json['responsible_party_id'],
        'title': json['title'],
        'completed_by_user_id': json['completed_by_user_id'],
        'date_completed': json['date_completed'],
        'length_mins': !exists(json, 'length_mins') ? undefined : json['length_mins'],
        'care_plan_assistance_level_id': json['care_plan_assistance_level_id'],
        'number_of_assistants': !exists(json, 'number_of_assistants') ? undefined : json['number_of_assistants'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'pin': json['pin'],
    };
}

export function RecordUnscheduledCareRequestToJSON(value?: RecordUnscheduledCareRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'care_category_id': value.care_category_id,
        'responsible_party_id': value.responsible_party_id,
        'title': value.title,
        'completed_by_user_id': value.completed_by_user_id,
        'date_completed': value.date_completed,
        'length_mins': value.length_mins,
        'care_plan_assistance_level_id': value.care_plan_assistance_level_id,
        'number_of_assistants': value.number_of_assistants,
        'instructions': value.instructions,
        'pin': value.pin,
    };
}

