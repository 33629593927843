import { LatestAPFMUpdatesResponse } from 'types/crm/models/LatestAPFMUpdatesResponse';

export enum EventLogMappings {
  resident_first_name = 'First Name',
  resident_last_name = 'Last Name',
  profile_age = 'Age',
  profile_marital_status = 'Marital Status',
  resident_contact_second_resident_name = 'Cohabiting Spouse/Companion',
  profile_veteran = 'Veteran',
  desired_zip = 'Desired Zip Code',
  tour_date = 'Desired Tour Date',
  profile_timeframe = 'Desired Move-In Date',
  resident_home_phone = 'Home Phone',
  resident_work_phone = 'Work Phone',
  resident_cell_phone = 'Cell Phone',
  resident_fax_phone = 'Fax',
  property_name = 'Community Name',
  property_postal_region = 'Community ZIP',
  partner_property_id = 'ExaCare Community ID',
  apfm_property_id = 'APFM Community ID',
  referral_date = 'Referral Date',
  referral_date_time = 'Referral To Community On',
  profile_reasons_for_search = 'Reason for Search',
  profile_length_care_needed = 'Length of Care',
  advisor_first_name = 'APFM Advisor First Name',
  advisor_last_name = 'APFM Advisor Last Name',
  advisor_email = 'APFM Advisor Email',
  advisor_referral_notes = 'APFM Advisor Referral Note',
  advisor_toll_free = 'APFM Phone Number (Toll Free)',
  advisor_work_phone = 'APFM Advisor Phone Number (Local)',
  primary_contact_first_name = 'First Name',
  primary_contact_last_name = 'Last Name',
  primary_contact_relationship = 'Relation to Resident',
  primary_contact_home_phone = 'Home Phone',
  primary_contact_cell_phone = 'Cell Phone',
  primary_contact_work_phone = 'Work Phone',
  primary_contact_fax_phone = 'Fax',
  lead_id = 'APFM Lead ID',
  inquiry_date = 'APFM Inquiry Date',
  inquiry_method = 'APFM Inquiry Method',
  referral_type = 'APFM Lead Status',
  profile_assistance_bathing = 'Bathing Assistance',
  profile_assistance_toileting = 'Toileting Assistance',
  profile_assistance_toileting_followup = 'Type of Toileting Assistance',
  profile_bathroom_reminder_needed = 'Bathroom Reminders',
  profile_bathroom_physical_difficulties = 'Bathroom Physical Assistance',
  profile_incontinence_type_bladder = 'Bladder Incontinence',
  profile_incontinence_type_bowel = 'Bladder and Bowel Incontinence',
  profile_special_care = 'Special Care Needs',
  profile_other_assistance = 'Other Daily Assistance',
  profile_medication = 'Medications',
  profile_taking_medication = 'Medication Needs',
  profile_medical_history = 'Medical History',
  profile_medical_diagnosis = 'Medication Needs',
  profile_diabetic_care_needs = 'Diabetic Care Needs',
  profile_ambulation = 'Description of Mobility',
  profile_wheelchair_assistance = 'Wheelchair Assistance',
  profile_wheelchair_move_from = 'Assistance Transferring from Wheelchair',
  profile_wheelchair_self_propel = 'Self-Propelled Wheelchair',
  profile_disability = 'Cognitive Impairments',
  profile_memory_loss = 'Memory Loss',
  profile_memory_diagnosis = 'Memory Diagnosis',
  profile_memory_combative = 'Combative/Wandering Issues',
  profile_activity_preferences = 'Activity Preferences',
  profile_other_accommodations = 'Cultural or Language Accommodations',
  profile_sleeping_habits = 'Sleeping Habits',
  profile_other_preferences = 'Other Preferences',
  profile_other_living_situation = 'Current Living Situation (Other)',
  secondary_resident_name = 'Secondary Potential Resident',
  profile_living_situation = 'Current Living Situation',
  profile_budget = 'Budget',
  profile_alternate_funding_types = 'Additional Fund Source',
  do_not_call = 'Do Not Call',
  primary_contact_allow_contact = 'Allowed to contact Primary Contact?',
  resident_contact_allow_contact = 'Allowed to contact Prospect?'
}

export const EventLogContentCategories: {
  [key: string]: {
    category: string;
    property: keyof LatestAPFMUpdatesResponse;
    fields: (keyof typeof EventLogMappings)[];
  }[];
} = {
  overview: [
    {
      category: 'Prospect Details',
      property: 'prospect',
      fields: [
        'resident_first_name',
        'resident_last_name',
        'profile_age',
        'profile_marital_status',
        'resident_contact_second_resident_name',
        'profile_veteran',
        'desired_zip',
        'tour_date',
        'profile_timeframe',
        'resident_home_phone',
        'resident_work_phone',
        'resident_cell_phone',
        'resident_fax_phone'
      ]
    },
    {
      category: 'Referred To',
      property: 'referred_to',
      fields: [
        'property_name',
        'property_postal_region',
        'partner_property_id',
        'apfm_property_id'
      ]
    },
    {
      category: 'Referral Details',
      property: 'referral_details',
      fields: [
        'referral_date_time',
        'profile_reasons_for_search',
        'profile_length_care_needed',
        'advisor_first_name',
        'advisor_last_name',
        'advisor_email',
        'advisor_toll_free',
        'advisor_work_phone',
        'advisor_referral_notes'
      ]
    },
    {
      category: "Prospect's Primary Contact Details",
      property: 'primary_contact',
      fields: [
        'primary_contact_first_name',
        'primary_contact_last_name',
        'primary_contact_relationship',
        'primary_contact_home_phone',
        'primary_contact_cell_phone',
        'primary_contact_work_phone',
        'primary_contact_fax_phone'
      ]
    },
    {
      category: 'Original APFM Inquiry Details',
      property: 'inquiry',
      fields: ['inquiry_date', 'inquiry_method', 'lead_id', 'referral_type']
    }
  ],
  prospect_profile: [
    {
      category: 'Care',

      property: 'care',
      fields: [
        'profile_assistance_bathing',
        'profile_assistance_toileting',
        'profile_assistance_toileting_followup',
        'profile_bathroom_reminder_needed',
        'profile_bathroom_physical_difficulties',
        'profile_incontinence_type_bladder',
        'profile_incontinence_type_bowel',
        'profile_special_care',
        'profile_other_assistance'
      ]
    },
    {
      category: 'Medications',
      property: 'medication',
      fields: ['profile_medication', 'profile_taking_medication']
    },
    {
      category: 'Medical',
      property: 'medical',
      fields: [
        'profile_medical_history',
        'profile_medical_diagnosis',
        'profile_diabetic_care_needs'
      ]
    },
    {
      category: 'Mobility',
      property: 'mobility',
      fields: [
        'profile_ambulation',
        'profile_wheelchair_assistance',
        'profile_wheelchair_move_from',
        'profile_wheelchair_self_propel'
      ]
    },
    {
      category: 'Cognitive',
      property: 'cognitive',
      fields: [
        'profile_disability',
        'profile_memory_loss',
        'profile_memory_diagnosis',
        'profile_memory_combative'
      ]
    },
    {
      category: 'Other',
      property: 'other',
      fields: [
        'profile_activity_preferences',
        'profile_other_accommodations',
        'profile_sleeping_habits',
        'profile_other_preferences',
        'profile_living_situation',
        'profile_other_living_situation',
        'secondary_resident_name'
      ]
    },
    {
      category: 'Financial',
      property: 'financial',
      fields: ['profile_budget', 'profile_alternate_funding_types']
    },
    {
      category: 'Permissions',
      property: 'permission',
      fields: [
        'do_not_call',
        'primary_contact_allow_contact',
        'resident_contact_allow_contact'
      ]
    }
  ]
};

export const A_PLACE_FOR_MOM_LOGO_URL =
  'https://public-exacare.s3.amazonaws.com/a_place_for_mom_logo.png' as const;

export const A_PLACE_FOR_MOM_INTEGRATION_NAME = 'A Place For Mom' as const;
