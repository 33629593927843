import React from 'react';
import { Control } from 'react-hook-form';
import { Timer } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';

interface ControlledTimeInputProps {
  control: Control<any, any>;
  max: number;
  type: 'Minutes' | 'Hours';
  step: number;
  label: string;
  name: string;
  disabled?: boolean;
  validate?: (value: any) => boolean | string;
  required?: boolean;
}

export const ControlledTimeInput: React.FC<ControlledTimeInputProps> = ({
  control,
  max,
  type,
  step,
  label,
  name,
  disabled,
  validate,
  required
}) => {
  return (
    <ControlledTextField
      inputProps={{
        min: 0,
        max,
        step
      }}
      format="integer"
      type="number"
      control={control}
      label={label}
      name={name}
      disabled={disabled}
      rules={{
        required: required,
        validate: (value) => {
          if (disabled && !required) {
            return true;
          }
          const number = Number(value);
          if (!(number >= 0 && number <= max)) {
            return `${type} must be between 0 and ${max}`;
          }
          return validate ? validate(value) : true;
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Timer htmlColor="#9AAEBB" />
          </InputAdornment>
        )
      }}
    />
  );
};
