/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCareUnitsType } from './AssessmentCareUnitsType';
import {
    AssessmentCareUnitsTypeFromJSON,
    AssessmentCareUnitsTypeFromJSONTyped,
    AssessmentCareUnitsTypeToJSON,
} from './AssessmentCareUnitsType';

/**
 * 
 * @export
 * @interface PutAssessmentRequest
 */
export interface PutAssessmentRequest {
    /**
     * 
     * @type {string}
     * @memberof PutAssessmentRequest
     */
    name?: string;
    /**
     * 
     * @type {AssessmentCareUnitsType}
     * @memberof PutAssessmentRequest
     */
    care_units_type?: AssessmentCareUnitsType;
    /**
     * 
     * @type {boolean}
     * @memberof PutAssessmentRequest
     */
    is_care_plan_generator?: boolean;
}

/**
 * Check if a given object implements the PutAssessmentRequest interface.
 */
export function instanceOfPutAssessmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PutAssessmentRequestFromJSON(json: any): PutAssessmentRequest {
    return PutAssessmentRequestFromJSONTyped(json, false);
}

export function PutAssessmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutAssessmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'care_units_type': !exists(json, 'care_units_type') ? undefined : AssessmentCareUnitsTypeFromJSON(json['care_units_type']),
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
    };
}

export function PutAssessmentRequestToJSON(value?: PutAssessmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'care_units_type': AssessmentCareUnitsTypeToJSON(value.care_units_type),
        'is_care_plan_generator': value.is_care_plan_generator,
    };
}

