/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FunctionScoreAction } from './FunctionScoreAction';
import {
    FunctionScoreActionFromJSON,
    FunctionScoreActionFromJSONTyped,
    FunctionScoreActionToJSON,
} from './FunctionScoreAction';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface GgFunctionScore
 */
export interface GgFunctionScore {
    /**
     * 
     * @type {FunctionScoreAction}
     * @memberof GgFunctionScore
     */
    action: FunctionScoreAction;
    /**
     * 
     * @type {number}
     * @memberof GgFunctionScore
     */
    score: number | null;
    /**
     * 
     * @type {number}
     * @memberof GgFunctionScore
     */
    readonly predicted_score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GgFunctionScore
     */
    readonly reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof GgFunctionScore
     */
    visual_references?: Array<VisualReference>;
}

/**
 * Check if a given object implements the GgFunctionScore interface.
 */
export function instanceOfGgFunctionScore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "score" in value;

    return isInstance;
}

export function GgFunctionScoreFromJSON(json: any): GgFunctionScore {
    return GgFunctionScoreFromJSONTyped(json, false);
}

export function GgFunctionScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): GgFunctionScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': FunctionScoreActionFromJSON(json['action']),
        'score': json['score'],
        'predicted_score': !exists(json, 'predicted_score') ? undefined : json['predicted_score'],
        'reasoning': !exists(json, 'reasoning') ? undefined : json['reasoning'],
        'visual_references': !exists(json, 'visual_references') ? undefined : ((json['visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
    };
}

export function GgFunctionScoreToJSON(value?: GgFunctionScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': FunctionScoreActionToJSON(value.action),
        'score': value.score,
        'visual_references': value.visual_references === undefined ? undefined : ((value.visual_references as Array<any>).map(VisualReferenceToJSON)),
    };
}

