/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HospitalMessage } from './HospitalMessage';
import {
    HospitalMessageFromJSON,
    HospitalMessageFromJSONTyped,
    HospitalMessageToJSON,
} from './HospitalMessage';

/**
 * 
 * @export
 * @interface FindAllHospitalMessages200Response
 */
export interface FindAllHospitalMessages200Response {
    /**
     * 
     * @type {Array<HospitalMessage>}
     * @memberof FindAllHospitalMessages200Response
     */
    data?: Array<HospitalMessage>;
}

/**
 * Check if a given object implements the FindAllHospitalMessages200Response interface.
 */
export function instanceOfFindAllHospitalMessages200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllHospitalMessages200ResponseFromJSON(json: any): FindAllHospitalMessages200Response {
    return FindAllHospitalMessages200ResponseFromJSONTyped(json, false);
}

export function FindAllHospitalMessages200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllHospitalMessages200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HospitalMessageFromJSON)),
    };
}

export function FindAllHospitalMessages200ResponseToJSON(value?: FindAllHospitalMessages200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HospitalMessageToJSON)),
    };
}

