import React from 'react';
import { Refresh } from '@mui/icons-material';
import {
  Alert,
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';

import { ExacareFeature } from '@/utils/featureFlagService';
import { FeatureFlagService } from '@/utils/featureFlagService';

import { localStorageFeatureFlagsAtom } from './localStorageFeatureFlagsAtom';

const OnOffChip: React.FC<{ value: true | false | undefined }> = ({
  value
}) => {
  return (
    <Chip
      size="small"
      color={
        value === true ? 'success' : value === false ? 'warning' : 'default'
      }
      label={value === true ? 'On' : value === false ? 'Off' : '—'}
    />
  );
};

export default function SecretFeatureFlagPage() {
  const [featureFlags, setFeatureFlags] = useAtom(localStorageFeatureFlagsAtom);

  const turnOn = (key: ExacareFeature) => {
    setFeatureFlags((prev) => ({ ...prev, [key]: true }));
  };

  const turnOff = (key: ExacareFeature) => {
    setFeatureFlags((prev) => ({ ...prev, [key]: false }));
  };

  const clear = (key: ExacareFeature) => {
    setFeatureFlags((prev) => {
      const { [key]: _, ...rest } = prev; // eslint-disable-line @typescript-eslint/no-unused-vars
      return rest;
    });
  };

  const clearOverrides = () => {
    setFeatureFlags(RESET);
  };

  return (
    <Stack className="panel panel-border-radius" gap={2} p={3}>
      <Alert
        sx={{ width: 'max-content' }}
        severity="info"
        action={
          <Button
            variant="outlined"
            size="small"
            startIcon={<Refresh />}
            onClick={() => location.reload()}>
            Refresh
          </Button>
        }>
        When you turn a flag on/off, you may need to refresh the page to see the
        effects
      </Alert>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Feature Flag Name</TableCell>
              <TableCell>Default</TableCell>
              <TableCell>Override</TableCell>
              <TableCell>Default + Override</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={clearOverrides}>
                  Clear All Overrides
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(ExacareFeature).map((value) => (
              <TableRow key={value}>
                <TableCell>{value}</TableCell>
                <TableCell>
                  <OnOffChip value={import.meta.env[value] === 'true'} />
                </TableCell>
                <TableCell>
                  <OnOffChip value={featureFlags[value]} />
                </TableCell>
                <TableCell>
                  <OnOffChip value={FeatureFlagService.isEnabled(value)} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="medium"
                    disabled={featureFlags[value] === true}
                    onClick={() => turnOn(value)}
                    color="success">
                    Turn On
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    disabled={featureFlags[value] === false}
                    variant="contained"
                    size="medium"
                    color="warning"
                    onClick={() => turnOff(value)}>
                    Turn Off
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    disabled={
                      featureFlags[value] !== true &&
                      featureFlags[value] !== false
                    }
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => clear(value)}>
                    Clear Override
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
