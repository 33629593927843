/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindAllResidentBillingInfosResponse } from './FindAllResidentBillingInfosResponse';
import {
    FindAllResidentBillingInfosResponseFromJSON,
    FindAllResidentBillingInfosResponseFromJSONTyped,
    FindAllResidentBillingInfosResponseToJSON,
} from './FindAllResidentBillingInfosResponse';

/**
 * 
 * @export
 * @interface FindAllResidentBillingInfos200Response
 */
export interface FindAllResidentBillingInfos200Response {
    /**
     * 
     * @type {FindAllResidentBillingInfosResponse}
     * @memberof FindAllResidentBillingInfos200Response
     */
    data?: FindAllResidentBillingInfosResponse;
}

/**
 * Check if a given object implements the FindAllResidentBillingInfos200Response interface.
 */
export function instanceOfFindAllResidentBillingInfos200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllResidentBillingInfos200ResponseFromJSON(json: any): FindAllResidentBillingInfos200Response {
    return FindAllResidentBillingInfos200ResponseFromJSONTyped(json, false);
}

export function FindAllResidentBillingInfos200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentBillingInfos200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindAllResidentBillingInfosResponseFromJSON(json['data']),
    };
}

export function FindAllResidentBillingInfos200ResponseToJSON(value?: FindAllResidentBillingInfos200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindAllResidentBillingInfosResponseToJSON(value.data),
    };
}

