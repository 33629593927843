/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormOption } from './FormOption';
import {
    FormOptionFromJSON,
    FormOptionFromJSONTyped,
    FormOptionToJSON,
} from './FormOption';

/**
 * 
 * @export
 * @interface MedicareAssessmentFormOptions
 */
export interface MedicareAssessmentFormOptions {
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    primary_diagnosis: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    slp_related_comorbidities: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    cognitive_level_assessment_daily_decision_making: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    cognitive_level_assessment_makes_self_understood: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    conditions_services: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_extensive_services: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_special_care_high: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_special_care_low: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_clinically_complex: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_other_symptoms: Array<FormOption>;
    /**
     * 
     * @type {Array<FormOption>}
     * @memberof MedicareAssessmentFormOptions
     */
    nursing_assessment_services_required: Array<FormOption>;
}

/**
 * Check if a given object implements the MedicareAssessmentFormOptions interface.
 */
export function instanceOfMedicareAssessmentFormOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "primary_diagnosis" in value;
    isInstance = isInstance && "slp_related_comorbidities" in value;
    isInstance = isInstance && "cognitive_level_assessment_daily_decision_making" in value;
    isInstance = isInstance && "cognitive_level_assessment_makes_self_understood" in value;
    isInstance = isInstance && "conditions_services" in value;
    isInstance = isInstance && "nursing_assessment_extensive_services" in value;
    isInstance = isInstance && "nursing_assessment_special_care_high" in value;
    isInstance = isInstance && "nursing_assessment_special_care_low" in value;
    isInstance = isInstance && "nursing_assessment_clinically_complex" in value;
    isInstance = isInstance && "nursing_assessment_other_symptoms" in value;
    isInstance = isInstance && "nursing_assessment_services_required" in value;

    return isInstance;
}

export function MedicareAssessmentFormOptionsFromJSON(json: any): MedicareAssessmentFormOptions {
    return MedicareAssessmentFormOptionsFromJSONTyped(json, false);
}

export function MedicareAssessmentFormOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareAssessmentFormOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primary_diagnosis': ((json['primary_diagnosis'] as Array<any>).map(FormOptionFromJSON)),
        'slp_related_comorbidities': ((json['slp_related_comorbidities'] as Array<any>).map(FormOptionFromJSON)),
        'cognitive_level_assessment_daily_decision_making': ((json['cognitive_level_assessment_daily_decision_making'] as Array<any>).map(FormOptionFromJSON)),
        'cognitive_level_assessment_makes_self_understood': ((json['cognitive_level_assessment_makes_self_understood'] as Array<any>).map(FormOptionFromJSON)),
        'conditions_services': ((json['conditions_services'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_extensive_services': ((json['nursing_assessment_extensive_services'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_special_care_high': ((json['nursing_assessment_special_care_high'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_special_care_low': ((json['nursing_assessment_special_care_low'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_clinically_complex': ((json['nursing_assessment_clinically_complex'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_other_symptoms': ((json['nursing_assessment_other_symptoms'] as Array<any>).map(FormOptionFromJSON)),
        'nursing_assessment_services_required': ((json['nursing_assessment_services_required'] as Array<any>).map(FormOptionFromJSON)),
    };
}

export function MedicareAssessmentFormOptionsToJSON(value?: MedicareAssessmentFormOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'primary_diagnosis': ((value.primary_diagnosis as Array<any>).map(FormOptionToJSON)),
        'slp_related_comorbidities': ((value.slp_related_comorbidities as Array<any>).map(FormOptionToJSON)),
        'cognitive_level_assessment_daily_decision_making': ((value.cognitive_level_assessment_daily_decision_making as Array<any>).map(FormOptionToJSON)),
        'cognitive_level_assessment_makes_self_understood': ((value.cognitive_level_assessment_makes_self_understood as Array<any>).map(FormOptionToJSON)),
        'conditions_services': ((value.conditions_services as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_extensive_services': ((value.nursing_assessment_extensive_services as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_special_care_high': ((value.nursing_assessment_special_care_high as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_special_care_low': ((value.nursing_assessment_special_care_low as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_clinically_complex': ((value.nursing_assessment_clinically_complex as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_other_symptoms': ((value.nursing_assessment_other_symptoms as Array<any>).map(FormOptionToJSON)),
        'nursing_assessment_services_required': ((value.nursing_assessment_services_required as Array<any>).map(FormOptionToJSON)),
    };
}

