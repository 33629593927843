/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CloseConnectionFlag = {
    Poa: 'poa',
    MedicalPoa: 'medical_poa',
    BillRecipient: 'bill_recipient',
    EmergencyContact: 'emergency_contact',
    NewsMailingsRecipient: 'news_mailings_recipient',
    ChiefPoc: 'chief_poc'
} as const;
export type CloseConnectionFlag = typeof CloseConnectionFlag[keyof typeof CloseConnectionFlag];


export function CloseConnectionFlagFromJSON(json: any): CloseConnectionFlag {
    return CloseConnectionFlagFromJSONTyped(json, false);
}

export function CloseConnectionFlagFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloseConnectionFlag {
    return json as CloseConnectionFlag;
}

export function CloseConnectionFlagToJSON(value?: CloseConnectionFlag | null): any {
    return value as any;
}

