import { matchRoutes, useLocation } from 'react-router-dom';

import { RoutePath } from '../routes/RoutePath';

const routes = Object.values(RoutePath).map((path) => ({ path }));

// Return the current react-router route the user is on depending on their
// location.
export const useCurrentRoute = () => {
  const location = useLocation();
  return matchRoutes(routes, location)?.[0]?.route?.path as RoutePath;
};
