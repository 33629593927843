/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimeChargeCategory } from './OneTimeChargeCategory';
import {
    OneTimeChargeCategoryFromJSON,
    OneTimeChargeCategoryFromJSONTyped,
    OneTimeChargeCategoryToJSON,
} from './OneTimeChargeCategory';

/**
 * 
 * @export
 * @interface CreateOneTimeChargeCategory201Response
 */
export interface CreateOneTimeChargeCategory201Response {
    /**
     * 
     * @type {OneTimeChargeCategory}
     * @memberof CreateOneTimeChargeCategory201Response
     */
    data?: OneTimeChargeCategory;
}

/**
 * Check if a given object implements the CreateOneTimeChargeCategory201Response interface.
 */
export function instanceOfCreateOneTimeChargeCategory201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOneTimeChargeCategory201ResponseFromJSON(json: any): CreateOneTimeChargeCategory201Response {
    return CreateOneTimeChargeCategory201ResponseFromJSONTyped(json, false);
}

export function CreateOneTimeChargeCategory201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOneTimeChargeCategory201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : OneTimeChargeCategoryFromJSON(json['data']),
    };
}

export function CreateOneTimeChargeCategory201ResponseToJSON(value?: CreateOneTimeChargeCategory201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': OneTimeChargeCategoryToJSON(value.data),
    };
}

