/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentActionListEntry } from './IncidentActionListEntry';
import {
    IncidentActionListEntryFromJSON,
    IncidentActionListEntryFromJSONTyped,
    IncidentActionListEntryToJSON,
} from './IncidentActionListEntry';

/**
 * 
 * @export
 * @interface FindAllIncidentActions200Response
 */
export interface FindAllIncidentActions200Response {
    /**
     * 
     * @type {Array<IncidentActionListEntry>}
     * @memberof FindAllIncidentActions200Response
     */
    data?: Array<IncidentActionListEntry>;
}

/**
 * Check if a given object implements the FindAllIncidentActions200Response interface.
 */
export function instanceOfFindAllIncidentActions200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllIncidentActions200ResponseFromJSON(json: any): FindAllIncidentActions200Response {
    return FindAllIncidentActions200ResponseFromJSONTyped(json, false);
}

export function FindAllIncidentActions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllIncidentActions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(IncidentActionListEntryFromJSON)),
    };
}

export function FindAllIncidentActions200ResponseToJSON(value?: FindAllIncidentActions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(IncidentActionListEntryToJSON)),
    };
}

