/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface AudienceMemberListEntry
 */
export interface AudienceMemberListEntry {
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    id: string;
    /**
     * 
     * @type {RelationType}
     * @memberof AudienceMemberListEntry
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    relation_id: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    avatar_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceMemberListEntry
     */
    date_added: string;
}

/**
 * Check if a given object implements the AudienceMemberListEntry interface.
 */
export function instanceOfAudienceMemberListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;
    isInstance = isInstance && "avatar_url" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "date_added" in value;

    return isInstance;
}

export function AudienceMemberListEntryFromJSON(json: any): AudienceMemberListEntry {
    return AudienceMemberListEntryFromJSONTyped(json, false);
}

export function AudienceMemberListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudienceMemberListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
        'avatar_url': json['avatar_url'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'date_added': json['date_added'],
    };
}

export function AudienceMemberListEntryToJSON(value?: AudienceMemberListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
        'avatar_url': value.avatar_url,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'date_added': value.date_added,
    };
}

