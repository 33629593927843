/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentAnswersCategory } from './AssessmentAnswersCategory';
import {
    AssessmentAnswersCategoryFromJSON,
    AssessmentAnswersCategoryFromJSONTyped,
    AssessmentAnswersCategoryToJSON,
} from './AssessmentAnswersCategory';

/**
 * 
 * @export
 * @interface AssessmentAnswers
 */
export interface AssessmentAnswers {
    /**
     * 
     * @type {Array<AssessmentAnswersCategory>}
     * @memberof AssessmentAnswers
     */
    categories: Array<AssessmentAnswersCategory>;
}

/**
 * Check if a given object implements the AssessmentAnswers interface.
 */
export function instanceOfAssessmentAnswers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function AssessmentAnswersFromJSON(json: any): AssessmentAnswers {
    return AssessmentAnswersFromJSONTyped(json, false);
}

export function AssessmentAnswersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentAnswers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(AssessmentAnswersCategoryFromJSON)),
    };
}

export function AssessmentAnswersToJSON(value?: AssessmentAnswers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(AssessmentAnswersCategoryToJSON)),
    };
}

