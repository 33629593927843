/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentCommentCreateRequest
 */
export interface IncidentCommentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentCreateRequest
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCommentCreateRequest
     */
    comment: string;
}

/**
 * Check if a given object implements the IncidentCommentCreateRequest interface.
 */
export function instanceOfIncidentCommentCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "incident_id" in value;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function IncidentCommentCreateRequestFromJSON(json: any): IncidentCommentCreateRequest {
    return IncidentCommentCreateRequestFromJSONTyped(json, false);
}

export function IncidentCommentCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCommentCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_id': json['incident_id'],
        'comment': json['comment'],
    };
}

export function IncidentCommentCreateRequestToJSON(value?: IncidentCommentCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_id': value.incident_id,
        'comment': value.comment,
    };
}

