/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealTemperature = {
    Cold: 'Cold',
    Warm: 'Warm',
    Hot: 'Hot',
    Null: 'null'
} as const;
export type DealTemperature = typeof DealTemperature[keyof typeof DealTemperature];


export function DealTemperatureFromJSON(json: any): DealTemperature {
    return DealTemperatureFromJSONTyped(json, false);
}

export function DealTemperatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealTemperature {
    return json as DealTemperature;
}

export function DealTemperatureToJSON(value?: DealTemperature | null): any {
    return value as any;
}

