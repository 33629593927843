import React from 'react';
import { Container, Fade, styled } from '@mui/material';

import exacareLogo from '../images/exacare_logo.png';

interface LoadingScreenProps {
  animate?: boolean;
  fade?: boolean;
}

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'animate'
})<LoadingScreenProps>((props) => ({
  width: '60vw',
  [props.theme.breakpoints.up('sm')]: {
    width: 400
  },
  animation: props.animate ? 'breathing 4s ease-out infinite normal' : 'none',
  '@keyframes breathing': {
    '0%': {
      transform: 'scale(1)'
    },
    '25%': {
      transform: 'scale(0.9)'
    },
    '50%': {
      transform: 'scale(1)'
    },
    '75%': {
      transform: 'scale(0.9)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  animate = false,
  fade = false
}) => {
  const content = <StyledImage src={exacareLogo} animate={animate} />;

  return (
    <Container
      sx={{
        width: '100vw',
        height: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      {fade ? (
        <Fade in={fade} timeout={{ enter: 1000 }}>
          {content}
        </Fade>
      ) : (
        content
      )}
    </Container>
  );
};

export default LoadingScreen;
