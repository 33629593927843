import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { fetchUsers } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { usersv2Client } from '@/adapters/schemaClients';
import { ALL_FACILITIES } from '@/hooks/useFacilitiesQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import { UserModel } from '@/models/UserModel';
import { invalidateAudiencesQueries } from '@/pages/CRM/hooks/useAudienceQuery';
import { StaffFormData } from '@/stores/staffFormDataAtom';
import {
  DeleteUserRequest,
  UpdateUserCrmNotificationStatusRequest,
  UpdateUserPinRequest
} from '@/types/users';

import { RecordsPostBody } from './useRecords';
import { UserType } from './useUserTypesQuery';

interface FetchAllUsersParams {
  facilityId?: null | string | typeof ALL_FACILITIES;
  hours?: number;
  userId?: string | null;
  paranoid?: boolean;
  requireCrmPermissions?: boolean;
}

interface FindAllUsersParams {
  facilityId: null | string | typeof ALL_FACILITIES;
  requireCrmPermissions?: boolean;
}

interface UserFacilityAccess {
  id: string;
  facility_id: string;
  user_id: string;
  granted_by_user_id: string;
  facility: {
    id: string;
    name: string;
    address: string;
  };
}

export class UserPayload {
  id: string;
  first_name: string;
  last_name: string;
  state_id: string;
  s3_photo_key: string;
  date_of_hire: string;
  dob: string;
  ssn: string;
  email: string;
  gender: string;
  phone: string;
  user_type_id: string;
  user_type?: UserType | null;
  is_deleted: boolean;
  facility_id: string;
  auth0_id: string;
  pin: string;
  timezone_id: string;
  last_sign_in_time: string;
  temp_password: string;
  receive_crm_notifications: boolean | null;
  organization_employment_type_id: string;
  address: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  deletedAt?: string | null;
  organization_settings?: {
    // Only available when calling /users/users/current
    billing_enabled: boolean;
    is_screener_customer: boolean;
  };
  username: string | null;
  enabled_dynamic_permissions?: string[] | null;
  login_url_preference?: string;
  user_facility_accesses?: UserFacilityAccess[];

  constructor(payload: UserPayload) {
    Object.assign(this, payload);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get initials(): string {
    return `${this.first_name[0]}${this.last_name[0]}`;
  }
}

async function fetchAllUsers({
  facilityId,
  hours,
  userId,
  paranoid,
  requireCrmPermissions
}: FetchAllUsersParams = {}) {
  let url = userId ? `/users/${userId}` : '/users';

  if (Number.isFinite(hours)) url = '/users/recent';

  const searchParams = {
    ...(facilityId && facilityId != ALL_FACILITIES && { facility: facilityId }),
    ...(Number.isFinite(hours) && { hours }),
    ...(paranoid != null && { paranoid }),
    ...(requireCrmPermissions && {
      require_crm_permissions: requireCrmPermissions
    })
  };

  const users = await fetchUsers
    .get<UserPayload[]>(url, { searchParams })
    .then((users) =>
      Array.isArray(users)
        ? users.map((user) => new UserModel(user))
        : [new UserModel(users)]
    );
  return users;
}

export const USERS_FIND_ALL_QUERY_KEY = 'useUsersFindAllQuery';
export const USERS_FIND_ONE_QUERY_KEY = 'useUsersFindOneQuery';

export function useUsersFindOneQuery(
  userId?: string | null,
  options = {} as UseQueryOptions,
  paranoid?: boolean
) {
  return useQuery(
    [USERS_FIND_ONE_QUERY_KEY, userId],
    () => fetchAllUsers({ userId, paranoid }).then(([user]) => user),
    {
      enabled: !!userId,
      ...options
    } as any
  );
}

export function useUsersFindAllQuery(
  params?: FindAllUsersParams,
  options: UseQueryOptions<UserModel[], HTTPError> = {}
) {
  return useQuery(
    [USERS_FIND_ALL_QUERY_KEY, params],
    () =>
      fetchAllUsers({
        facilityId: params?.facilityId,
        requireCrmPermissions: params?.requireCrmPermissions
      }),
    options as any
  );
}

export function useRecentUsersQuery(
  hours = 24,
  options = {} as UseQueryOptions
) {
  return useQuery(
    ['useRecentUsersQuery', hours],
    () => fetchAllUsers({ hours }),
    options as any
  );
}

export function useMutatePutUser(
  options: UseMutationOptions<
    unknown,
    HTTPError,
    Partial<UserPayload>,
    { previousUserModel: UserModel }
  > = {}
) {
  return useMutation((payload: Partial<UserPayload>) => {
    return fetchUsers.put(`/users/${payload.id}`, payload);
  }, options as any);
}

interface PostUserPayload extends StaffFormData {
  user_records: RecordsPostBody[];
}

export function useMutatePostUser(
  options: UseMutationOptions = {},
  facilityId: string | null = ALL_FACILITIES
) {
  return useMutation(
    (payload: Partial<PostUserPayload>) => {
      return fetchUsers.post<UserPayload>('/users/staff', payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [USERS_FIND_ALL_QUERY_KEY, { facilityId }]
        });
      },
      ...(options as any)
    }
  );
}

export interface ChangePinFields {
  id: string;
  oldPin: string;
  newPin: string;
  newPinConfirm: string;
}

export function useUpdateUserPin(
  options: UseMutationOptions<unknown, HTTPError> = {}
) {
  return useMutation((payload: ChangePinFields): Promise<any> => {
    return fetchUsers.put(`/users-pin/${payload.id}`, payload);
  }, options as any);
}

interface UpdateUserEmailPayload {
  userId: string;
  newEmail: string;
  regenerate?: boolean;
}

export function useUpdateUserEmail(options: UseMutationOptions = {}) {
  const { showSnackbar } = useSnackbar();

  return useMutation(
    (payload: UpdateUserEmailPayload) => {
      return fetchUsers.put('/users/email', payload);
    },
    {
      onError: async (error: any) => {
        const errorMessage = await (error as HTTPError)?.response.text();
        if (error instanceof HTTPError) {
          showSnackbar({
            message: `Update email failed: "${errorMessage}"`,
            severity: 'error'
          });
        }
      },
      ...(options as any)
    }
  );
}

export function useCheckUsername(options: UseMutationOptions = {}) {
  return useMutation((username: string) => {
    return fetchUsers.get(`/users/username-check`, {
      searchParams: { username }
    });
  }, options as any);
}

export function updateCrmNotificationStatus(options: UseMutationOptions = {}) {
  const invalidate = () =>
    queryClient.invalidateQueries([USERS_FIND_ONE_QUERY_KEY]);
  return {
    invalidate,
    update: useMutation(
      async (params: UpdateUserCrmNotificationStatusRequest) => {
        return usersv2Client.updateUserCrmNotificationStatus(params);
      },
      {
        onSuccess: invalidate,
        ...(options as any)
      }
    )
  };
}

export function useDeleteUser(options: UseMutationOptions = {}) {
  return useMutation(
    (params: DeleteUserRequest) => {
      return usersv2Client.deleteUser(params);
    },
    {
      onSuccess: invalidateAudiencesQueries(),
      ...(options as any)
    }
  );
}

export function useUpdateStaffPin(options: UseMutationOptions = {}) {
  const invalidate = () =>
    queryClient.invalidateQueries([USERS_FIND_ONE_QUERY_KEY]);
  return {
    invalidate,
    update: useMutation(
      async (params: UpdateUserPinRequest) => {
        return usersv2Client.updateUserPin(params);
      },
      {
        onSuccess: invalidate,
        ...(options as any)
      }
    )
  };
}
