import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const CRMStaticAudiencePage = loadable(() => import("@/pages/CRM/AudiencePage/StaticAudiencePage/StaticAudiencePage")); // prettier-ignore
const CRMDynamicAudiencePage = loadable(() => import("@/pages/CRM/AudiencePage/DynamicAudiencePage/DynamicAudiencePage")) // prettier-ignore
const CRMAudienceMembers = loadable(() => import("@/pages/CRM/AudiencePage/Members")) // prettier-ignore
const CRMDynamicAudienceManageAutoEntry = loadable(() => import("@/pages/CRM/AudiencePage/DynamicAudiencePage/ManageAutoEntry/ManageAutoEntry")) // prettier-ignore
const CRMStaticAudienceProspects = loadable(() => import("@/pages/CRM/AudiencePage/StaticAudiencePage/Prospects/AudienceProspects")); // prettier-ignore
const CRMStaticAudienceResidents = loadable(() => import("@/pages/CRM/AudiencePage/StaticAudiencePage/Residents/AudienceResidents")); // prettier-ignore
const CRMStaticAudienceProspectFamilyFriends = loadable(() => import("@/pages/CRM/AudiencePage/StaticAudiencePage/ProspectFamilyFriends/ProspectFamilyFriends")); // prettier-ignore
const CRMStaticAudienceReferralSources = loadable(() => import("@/pages/CRM/AudiencePage/StaticAudiencePage/ReferralSources/ReferralSources")); // prettier-ignore

export enum CRMAudienceRoutes {
  CRMStaticAudience = '/crm/static-audience/:audience_id/',
  CRMStaticAudienceMembers = '/crm/static-audience/:audience_id/members',
  CRMStaticAudienceProspects = '/crm/static-audience/:audience_id/prospects',
  CRMStaticAudienceProspectFamilyFriends = '/crm/static-audience/:audience_id/prospect-family-friends',
  CRMStaticAudienceReferralSources = '/crm/static-audience/:audience_id/referral-sources',
  CRMStaticAudienceResidents = '/crm/static-audience/:audience_id/residents',
  CRMDynamicAudience = '/crm/dynamic-audience/:audience_id',
  CRMDynamicAudienceMembers = '/crm/dynamic-audience/:audience_id/members',
  CRMDynamicAudienceManage = '/crm/dynamic-audience/:audience_id/manage'
}

export const getCrmAudienceRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMAudienceRoutes.CRMStaticAudience,
      element: <CRMStaticAudiencePage />,
      children: [
        {
          path: CRMAudienceRoutes.CRMStaticAudienceMembers,
          element: <CRMAudienceMembers />
        },
        {
          path: CRMAudienceRoutes.CRMStaticAudienceProspects,
          element: <CRMStaticAudienceProspects />
        },
        {
          path: CRMAudienceRoutes.CRMStaticAudienceProspectFamilyFriends,
          element: <CRMStaticAudienceProspectFamilyFriends />
        },
        {
          path: CRMAudienceRoutes.CRMStaticAudienceReferralSources,
          element: <CRMStaticAudienceReferralSources />
        },
        {
          path: CRMAudienceRoutes.CRMStaticAudienceResidents,
          element: <CRMStaticAudienceResidents />
        }
      ]
    },
    {
      path: CRMAudienceRoutes.CRMDynamicAudience,
      element: <CRMDynamicAudiencePage />,
      children: [
        {
          path: CRMAudienceRoutes.CRMDynamicAudienceMembers,
          element: <CRMAudienceMembers />
        },
        {
          path: CRMAudienceRoutes.CRMDynamicAudienceManage,
          element: <CRMDynamicAudienceManageAutoEntry />
        }
      ]
    }
  ];
};
