/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DuplicateResidentListEntry } from './DuplicateResidentListEntry';
import {
    DuplicateResidentListEntryFromJSON,
    DuplicateResidentListEntryFromJSONTyped,
    DuplicateResidentListEntryToJSON,
} from './DuplicateResidentListEntry';

/**
 * 
 * @export
 * @interface FindDuplicateResidentResponse
 */
export interface FindDuplicateResidentResponse {
    /**
     * 
     * @type {DuplicateResidentListEntry}
     * @memberof FindDuplicateResidentResponse
     */
    resident: DuplicateResidentListEntry;
    /**
     * 
     * @type {Array<DuplicateResidentListEntry>}
     * @memberof FindDuplicateResidentResponse
     */
    duplicates: Array<DuplicateResidentListEntry>;
}

/**
 * Check if a given object implements the FindDuplicateResidentResponse interface.
 */
export function instanceOfFindDuplicateResidentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident" in value;
    isInstance = isInstance && "duplicates" in value;

    return isInstance;
}

export function FindDuplicateResidentResponseFromJSON(json: any): FindDuplicateResidentResponse {
    return FindDuplicateResidentResponseFromJSONTyped(json, false);
}

export function FindDuplicateResidentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindDuplicateResidentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident': DuplicateResidentListEntryFromJSON(json['resident']),
        'duplicates': ((json['duplicates'] as Array<any>).map(DuplicateResidentListEntryFromJSON)),
    };
}

export function FindDuplicateResidentResponseToJSON(value?: FindDuplicateResidentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident': DuplicateResidentListEntryToJSON(value.resident),
        'duplicates': ((value.duplicates as Array<any>).map(DuplicateResidentListEntryToJSON)),
    };
}

