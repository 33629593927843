import React from 'react';
import axios from 'axios';

import fetchExaCare from '@/adapters/fetchExaCare';

export interface S3File {
  name: string;
  key: string;
  size: number;
  mime_type: string;
}

export interface UploadProgressState {
  file?: File;
  loaded: number;
}

export async function postS3Files(
  files: FileList | File[] | null,
  setUploadProgress?: React.Dispatch<React.SetStateAction<UploadProgressState>>
): Promise<S3File[]> {
  if (!files) {
    return [];
  }

  const s3files: S3File[] = [];

  try {
    // Can't loop through FileList with an iterator so have to use indexes
    for (let i = 0; i < files?.length; i += 1) {
      const file = files[i];
      const { signedUrl, key } = (await fetchExaCare.post('/upload-url', {
        extension: file.name.split('.').slice(-1)[0],
        type: file.type
      })) as { signedUrl: string; key: string };
      await axios.put(signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (event) => {
          const loaded = (event.loaded * 100) / event.total!;
          setUploadProgress?.({ file, loaded });
        }
      });
      s3files.push({
        name: file.name,
        key,
        size: file.size,
        mime_type: file.type
      });
    }
  } finally {
    setUploadProgress?.({} as UploadProgressState);
  }
  return s3files;
}
