import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai/utils';

import { fetchCRM } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { selectedFacilityIdAtom } from '@/hooks/useFacilitiesQuery';

import { CrmTaskModel, CrmTaskPayload } from '../models/CrmTaskModel';

import { EVENT_LOG_FIND_ALL_QUERY_KEY } from './useCrmEventLogQuery';
import {
  CRM_TASK_INSTANCES_COUNT_QUERY_KEY,
  CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY
} from './useCrmTaskInstancesQuery';

export const CRM_TASKS_FIND_ONE_QUERY_KEY = 'useCrmTasksFindOneQueryKey';

export const useCrmTasksQuery = () => {
  const facilityId = useAtomValue(selectedFacilityIdAtom)!;
  // Invalidate all queries by this prefix for now
  const invalidate = () => {
    queryClient.invalidateQueries([CRM_TASKS_FIND_ONE_QUERY_KEY]);
    queryClient.invalidateQueries([CRM_TASK_INSTANCES_FIND_ALL_QUERY_KEY]);
    queryClient.invalidateQueries([CRM_TASK_INSTANCES_COUNT_QUERY_KEY]);
    queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
  };

  return {
    invalidate,

    findOne: (id: string) => {
      return useQuery([CRM_TASKS_FIND_ONE_QUERY_KEY, { id }], async () => {
        const response = await fetchCRM.get<CrmTaskPayload>(`/tasks/${id}`);
        return new CrmTaskModel(response);
      });
    },
    mutations: {
      create: useMutation(
        async (params: Partial<CrmTaskPayload>) => {
          const paramsWithFacilityId = {
            ...params,
            facility_id: facilityId
          };
          const response = await fetchCRM.post<CrmTaskPayload>(
            '/tasks',
            paramsWithFacilityId
          );
          return new CrmTaskModel(response);
        },
        {
          onSuccess: invalidate
        }
      ),
      update: useMutation(
        async (body: Partial<CrmTaskPayload>) => {
          const response = await fetchCRM.put<CrmTaskPayload>(
            `/tasks/${body.id}`,
            body
          );
          return new CrmTaskModel(response);
        },
        {
          onSuccess: invalidate
        }
      ),
      delete: useMutation(
        async (id: string) => fetchCRM.delete(`/tasks/${id}`),
        {
          onSuccess: invalidate
        }
      )
    }
  };
};
