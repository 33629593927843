/* tslint:disable */
/* eslint-disable */
/**
 * notifications
 * notifications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindAllPreferences200Response,
  NotificationPreferenceSettings,
  NotificationPreferencesUpdateRequest,
} from '../models/index';
import {
    FindAllPreferences200ResponseFromJSON,
    FindAllPreferences200ResponseToJSON,
    NotificationPreferenceSettingsFromJSON,
    NotificationPreferenceSettingsToJSON,
    NotificationPreferencesUpdateRequestFromJSON,
    NotificationPreferencesUpdateRequestToJSON,
} from '../models/index';

export interface CreatePreferenceSettingsRequest {
    notificationPreferenceSettings: NotificationPreferenceSettings;
}

export interface FindAllPreferencesRequest {
    userTypeIds: string;
}

export interface UpdatePreferenceSettingsRequest {
    id: string;
    notificationPreferenceSettings: NotificationPreferenceSettings;
}

export interface UpdatePreferencesRequest {
    notificationPreferencesUpdateRequest: NotificationPreferencesUpdateRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create User Type Preference settings
     */
    async createPreferenceSettingsRaw(requestParameters: CreatePreferenceSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationPreferenceSettings === null || requestParameters.notificationPreferenceSettings === undefined) {
            throw new runtime.RequiredError('notificationPreferenceSettings','Required parameter requestParameters.notificationPreferenceSettings was null or undefined when calling createPreferenceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/preference-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationPreferenceSettingsToJSON(requestParameters.notificationPreferenceSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create User Type Preference settings
     */
    async createPreferenceSettings(requestParameters: CreatePreferenceSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPreferenceSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Get all preferences
     */
    async findAllPreferencesRaw(requestParameters: FindAllPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllPreferences200Response>> {
        if (requestParameters.userTypeIds === null || requestParameters.userTypeIds === undefined) {
            throw new runtime.RequiredError('userTypeIds','Required parameter requestParameters.userTypeIds was null or undefined when calling findAllPreferences.');
        }

        const queryParameters: any = {};

        if (requestParameters.userTypeIds !== undefined) {
            queryParameters['user_type_ids'] = requestParameters.userTypeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllPreferences200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all preferences
     */
    async findAllPreferences(requestParameters: FindAllPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllPreferences200Response> {
        const response = await this.findAllPreferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User Type Preference settings
     */
    async updatePreferenceSettingsRaw(requestParameters: UpdatePreferenceSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePreferenceSettings.');
        }

        if (requestParameters.notificationPreferenceSettings === null || requestParameters.notificationPreferenceSettings === undefined) {
            throw new runtime.RequiredError('notificationPreferenceSettings','Required parameter requestParameters.notificationPreferenceSettings was null or undefined when calling updatePreferenceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/preference-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationPreferenceSettingsToJSON(requestParameters.notificationPreferenceSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update User Type Preference settings
     */
    async updatePreferenceSettings(requestParameters: UpdatePreferenceSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePreferenceSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Update User Type Preferences
     */
    async updatePreferencesRaw(requestParameters: UpdatePreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationPreferencesUpdateRequest === null || requestParameters.notificationPreferencesUpdateRequest === undefined) {
            throw new runtime.RequiredError('notificationPreferencesUpdateRequest','Required parameter requestParameters.notificationPreferencesUpdateRequest was null or undefined when calling updatePreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationPreferencesUpdateRequestToJSON(requestParameters.notificationPreferencesUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update User Type Preferences
     */
    async updatePreferences(requestParameters: UpdatePreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePreferencesRaw(requestParameters, initOverrides);
    }

}
