import React from 'react';
import styled from '@emotion/styled';
import { Comment } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';

export const OutOfCommunityChip = styled(Chip)({
  color: '#F38D2F',
  backgroundColor: '#F6F2EF',
  '& .MuiChip-icon': {
    color: '#F38D2F'
  }
});

interface OutOfCommunityChipWithTooltipProps {
  absenceReason?: string | undefined;
}

const OutOfCommunityChipWithTooltip: React.FC<
  OutOfCommunityChipWithTooltipProps
> = ({ absenceReason }) => {
  return absenceReason ? (
    <Tooltip title={absenceReason} arrow>
      <OutOfCommunityChip label="Out" size="small" icon={<Comment />} />
    </Tooltip>
  ) : (
    <OutOfCommunityChip label="Out" size="small" icon={<Comment />} />
  );
};

export default OutOfCommunityChipWithTooltip;
