/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillStatus } from './BillStatus';
import {
    BillStatusFromJSON,
    BillStatusFromJSONTyped,
    BillStatusToJSON,
} from './BillStatus';
import type { BillTotals } from './BillTotals';
import {
    BillTotalsFromJSON,
    BillTotalsFromJSONTyped,
    BillTotalsToJSON,
} from './BillTotals';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * 
 * @export
 * @interface BillListItem
 */
export interface BillListItem {
    /**
     * 
     * @type {string}
     * @memberof BillListItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillListItem
     */
    resident_id: string;
    /**
     * 
     * @type {Month}
     * @memberof BillListItem
     */
    month: Month;
    /**
     * 
     * @type {number}
     * @memberof BillListItem
     */
    year: number;
    /**
     * 
     * @type {BillStatus}
     * @memberof BillListItem
     */
    status: BillStatus;
    /**
     * 
     * @type {Date}
     * @memberof BillListItem
     */
    billed_from: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillListItem
     */
    billed_to: Date;
    /**
     * 
     * @type {BillTotals}
     * @memberof BillListItem
     */
    bill_totals: BillTotals;
    /**
     * 
     * @type {Date}
     * @memberof BillListItem
     */
    created_at: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BillListItem
     */
    emailed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillListItem
     */
    is_move_out_bill: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillListItem
     */
    name: string;
}

/**
 * Check if a given object implements the BillListItem interface.
 */
export function instanceOfBillListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "billed_from" in value;
    isInstance = isInstance && "billed_to" in value;
    isInstance = isInstance && "bill_totals" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "emailed" in value;
    isInstance = isInstance && "is_move_out_bill" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function BillListItemFromJSON(json: any): BillListItem {
    return BillListItemFromJSONTyped(json, false);
}

export function BillListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'month': MonthFromJSON(json['month']),
        'year': json['year'],
        'status': BillStatusFromJSON(json['status']),
        'billed_from': (new Date(json['billed_from'])),
        'billed_to': (new Date(json['billed_to'])),
        'bill_totals': BillTotalsFromJSON(json['bill_totals']),
        'created_at': (new Date(json['created_at'])),
        'emailed': json['emailed'],
        'is_move_out_bill': json['is_move_out_bill'],
        'name': json['name'],
    };
}

export function BillListItemToJSON(value?: BillListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'month': MonthToJSON(value.month),
        'year': value.year,
        'status': BillStatusToJSON(value.status),
        'billed_from': (value.billed_from.toISOString().substring(0,10)),
        'billed_to': (value.billed_to.toISOString().substring(0,10)),
        'bill_totals': BillTotalsToJSON(value.bill_totals),
        'created_at': (value.created_at.toISOString()),
        'emailed': value.emailed,
        'is_move_out_bill': value.is_move_out_bill,
        'name': value.name,
    };
}

