export const ASSESSMENT_ID = '2';
export const DEA_SCHEDULE_CODES = ['1', '2', '3', '4', '5', '6'];
export const BLOOD_PRESSURE_VITAL_ID = '3';
// Keep blood pressure at the first array spot so that during med pass the
// blood pressure systolic/diastolic input fields take up 12 grid columns and
// then the rest 6 grid columns
export const ORDERED_VITALS_BY_ID = [
  BLOOD_PRESSURE_VITAL_ID,
  '5',
  '7',
  '6',
  '4',
  '9',
  '1'
];
export const VITAL_ID_TO_UNIT_MAP: Record<string, string> = {
  [BLOOD_PRESSURE_VITAL_ID]: 'mmHg',
  5: '%',
  7: 'BPM',
  6: 'BPM',
  4: 'mg/dl',
  9: '°F',
  1: 'lbs'
};

export enum VitalsNames {
  Weight = 'Weight',
  Height = 'Height',
  BloodPressure = 'Blood Pressure',
  BloodSugar = 'Blood Sugar',
  OxygenSaturation = 'Oxygen Saturation',
  Pulse = 'Pulse',
  Respiration = 'Respiration',
  LungSounds = 'Lung Sounds',
  Temperature = 'Temperature',
  PainLevel = 'Pain Level'
}

export const EMAIL_REGEX = RegExp('(^$|^.+@.+\\..+$)');
export const CRM_EMAIL_TYPES = [
  'Welcome',
  'Invitation',
  'Follow-Up',
  'Onboarding',
  'Confirmation',
  'Thank You',
  'Newsletter',
  'Feedback/Survey',
  'Job Application/Interview',
  'Sales/Promotional',
  'Cancellation/Rescheduling',
  'Other'
];

export const OVERDUE_TASKS_COPY = 'Outstanding';
