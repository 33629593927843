/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface LeadCommentReaction
 */
export interface LeadCommentReaction {
    /**
     * 
     * @type {string}
     * @memberof LeadCommentReaction
     */
    comment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadCommentReaction
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadCommentReaction
     */
    reaction_type?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadCommentReaction
     */
    createdAt?: string;
    /**
     * 
     * @type {User}
     * @memberof LeadCommentReaction
     */
    user?: User;
}

/**
 * Check if a given object implements the LeadCommentReaction interface.
 */
export function instanceOfLeadCommentReaction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadCommentReactionFromJSON(json: any): LeadCommentReaction {
    return LeadCommentReactionFromJSONTyped(json, false);
}

export function LeadCommentReactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadCommentReaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment_id': !exists(json, 'comment_id') ? undefined : json['comment_id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'reaction_type': !exists(json, 'reaction_type') ? undefined : json['reaction_type'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function LeadCommentReactionToJSON(value?: LeadCommentReaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment_id': value.comment_id,
        'user_id': value.user_id,
        'reaction_type': value.reaction_type,
        'createdAt': value.createdAt,
        'user': UserToJSON(value.user),
    };
}

