import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMInboxPage = loadable(() => import("@/pages/CRM/InboxPage")); // prettier-ignore
const CRMInboxEmailsPage = loadable(() => import("@/pages/CRM/InboxPage/EmailInbox")); // prettier-ignore
const CRMInboxEmailsMessages = loadable(() => import("@/pages/CRM/InboxPage/EmailInbox/components/EmailMessages")); // prettier-ignore
const CRMInboxEmailsCompose = loadable(() => import("@/pages/CRM/InboxPage/EmailInbox/components/EmailCompose")); // prettier-ignore
const CRMInboxCallsPage = loadable(() => import("@/pages/CRM/InboxPage/CallInbox")); // prettier-ignore
const CRMInboxCallReview = loadable(() => import("@/pages/CRM/InboxPage/CallInbox/components/CallReview")); // prettier-ignore
const CRMInboxTextsPage = loadable(() => import("@/pages/CRM/InboxPage/TextInbox")); // prettier-ignore
const CRMInboxConversationView = loadable(() => import("@/pages/CRM/InboxPage/TextInbox/components/ConversationView")); // prettier-ignore

export enum CRMInboxRoutes {
  CRMInbox = '/crm/inbox',
  CRMInboxEmails = '/crm/inbox/emails',
  CRMInboxEmailsThread = '/crm/inbox/emails/:thread_id',
  CRMInboxEmailsCompose = '/crm/inbox/emails/compose',
  CRMInboxCalls = '/crm/inbox/calls',
  CRMInboxCallReview = '/crm/inbox/calls/:call_id',
  CRMInboxTexts = '/crm/inbox/texts',
  CRMInboxConversationView = '/crm/inbox/texts/:conversation_sid'
}

export const getCrmInboxRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMInboxRoutes.CRMInbox,
      element: <CRMInboxPage />,
      children: [
        {
          path: CRMInboxRoutes.CRMInboxEmails,
          element: <CRMInboxEmailsPage />,
          children: [
            {
              path: CRMInboxRoutes.CRMInboxEmailsThread,
              element: <CRMInboxEmailsMessages />
            },
            {
              path: CRMInboxRoutes.CRMInboxEmailsCompose,
              element: <CRMInboxEmailsCompose />
            }
          ]
        },
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_RESIDENT_CALLS)
          ? [
              {
                path: CRMInboxRoutes.CRMInboxCalls,
                element: <CRMInboxCallsPage />,
                children: [
                  {
                    path: CRMInboxRoutes.CRMInboxCallReview,
                    element: <CRMInboxCallReview />
                  }
                ]
              }
            ]
          : []),
        ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_RESIDENT_TEXTS)
          ? [
              {
                path: CRMInboxRoutes.CRMInboxTexts,
                element: <CRMInboxTextsPage />,
                children: [
                  {
                    path: CRMInboxRoutes.CRMInboxConversationView,
                    element: <CRMInboxConversationView />
                  }
                ]
              }
            ]
          : [])
      ]
    }
  ];
};
