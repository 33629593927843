/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface AudienceMember
 */
export interface AudienceMember {
    /**
     * 
     * @type {string}
     * @memberof AudienceMember
     */
    id?: string;
    /**
     * 
     * @type {RelationType}
     * @memberof AudienceMember
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof AudienceMember
     */
    relation_id: string;
}

/**
 * Check if a given object implements the AudienceMember interface.
 */
export function instanceOfAudienceMember(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;

    return isInstance;
}

export function AudienceMemberFromJSON(json: any): AudienceMember {
    return AudienceMemberFromJSONTyped(json, false);
}

export function AudienceMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudienceMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
    };
}

export function AudienceMemberToJSON(value?: AudienceMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
    };
}

