import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { atomWithStorage, useAtomValue } from 'jotai/utils';

import fetchExaCare from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { USE_CRM_FACILITIES_QUERY_KEY } from '@/pages/AdminHub/CommunitySettings/useCRMFacilitiesQuery';

import {
  ResidentGroup,
  USE_RESIDENT_GROUPS_QUERY_KEY
} from './useResidentGroupsQuery';
import { TimezonePayload } from './useTimezonesQuery';

// When the selectedFacilityIdAtom initializes to null, it means we should
// initialize it to the user's facility_id of their db row. We shouldn't assume
// that null signifies all facilities selected, otherwise users lower than L1
// would see their facility selected as all facilities. The ALL_FACILITIES const
// below signals downstream queries to not apply the facility search param
export const ALL_FACILITIES = 'ALL_FACILITIES';

// - null, initial value to be set to user's facility_id
// - string, facility_id
// - ALL_FACILITIES, special identifier for all facilities selection
export const selectedFacilityIdAtom = atomWithStorage<
  null | string | typeof ALL_FACILITIES
>('selectedFacilityIdAtom', null);

export const useSelectedFacilityId = () => {
  return useAtomValue(selectedFacilityIdAtom);
};

export interface FindAllFacilitiesResponse {
  can_select_all_facilities: boolean;
  facilities: FacilityPayload[];
}

export interface FacilityPayload {
  address: string;
  id: string;
  name: string;
  organization_id: string;
  state_id: string;
  time_zone_id: string;
  fax_number?: string;
  timezone: TimezonePayload;
  primary_phone?: string;
  seats: {
    rooms: number;
    residents: number;
  };
  resident_groups?: ResidentGroup[];
  pcc_fac_id: string | null;
}

export const USE_FACILITIES_QUERY_KEY = ['useFacilitiesQuery'];

export function useFacilitiesQuery() {
  const invalidate = () => {
    queryClient.invalidateQueries(USE_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_CRM_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_RESIDENT_GROUPS_QUERY_KEY);
  };

  return {
    invalidate,

    findAllOrOne: (
      facilityId?: string | null | typeof ALL_FACILITIES,
      options: UseQueryOptions<FacilityPayload[]> = {}
    ) =>
      useQuery(
        [...USE_FACILITIES_QUERY_KEY, 'findAllOrOne', facilityId],
        async () => {
          if (facilityId === ALL_FACILITIES) {
            const findAllFacilitiesResponse =
              await fetchExaCare.get<FindAllFacilitiesResponse>('/facilities');
            return findAllFacilitiesResponse.facilities;
          } else {
            const payload = await fetchExaCare.get<FacilityPayload>(
              `/facilities/${facilityId}`
            );
            return [payload];
          }
        },
        {
          enabled: !!facilityId,
          ...(options as any)
        }
      ),

    findAll: (options: UseQueryOptions<FacilityPayload[]> = {}) => {
      return useQuery(
        USE_FACILITIES_QUERY_KEY,
        async () => {
          const response = await fetchExaCare.get<FindAllFacilitiesResponse>(
            '/facilities'
          );
          return response.facilities;
        },
        options as any
      );
    },

    findAllWithCanSelectAllFlag: (
      options: UseQueryOptions<FindAllFacilitiesResponse> = {}
    ) => {
      return useQuery(
        [...USE_FACILITIES_QUERY_KEY, 'findAllWithCanSelectAllFlag'],
        async () => {
          const response = await fetchExaCare.get<FindAllFacilitiesResponse>(
            '/facilities'
          );
          return response;
        },
        options as any
      );
    },
    findOne: (
      facilityId?: string | null,
      options: UseQueryOptions<FacilityPayload> = {}
    ) => {
      return useQuery(
        [...USE_FACILITIES_QUERY_KEY, facilityId],
        () => {
          return fetchExaCare.get<FacilityPayload>(`/facilities/${facilityId}`);
        },
        {
          enabled: facilityId !== ALL_FACILITIES && !!facilityId,
          ...(options as any)
        }
      );
    },

    mutations: (options: UseMutationOptions<FacilityPayload> = {}) => ({
      update: useMutation(
        (payload: Partial<FacilityPayload>) => {
          return fetchExaCare.put(`/facilities/${payload.id}`, payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}
