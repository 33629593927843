/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DailyDecisionMakingSkillsOption } from './DailyDecisionMakingSkillsOption';
import {
    DailyDecisionMakingSkillsOptionFromJSON,
    DailyDecisionMakingSkillsOptionFromJSONTyped,
    DailyDecisionMakingSkillsOptionToJSON,
} from './DailyDecisionMakingSkillsOption';
import type { MakesSelfUnderstoodOption } from './MakesSelfUnderstoodOption';
import {
    MakesSelfUnderstoodOptionFromJSON,
    MakesSelfUnderstoodOptionFromJSONTyped,
    MakesSelfUnderstoodOptionToJSON,
} from './MakesSelfUnderstoodOption';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface CognitiveLevelAssessmentForm
 */
export interface CognitiveLevelAssessmentForm {
    /**
     * 
     * @type {boolean}
     * @memberof CognitiveLevelAssessmentForm
     */
    severe_impairment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_severe_impairment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_severe_impairment_reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof CognitiveLevelAssessmentForm
     */
    predicted_severe_impairment_visual_references?: Array<VisualReference>;
    /**
     * 
     * @type {DailyDecisionMakingSkillsOption}
     * @memberof CognitiveLevelAssessmentForm
     */
    skills_for_daily_decision_making?: DailyDecisionMakingSkillsOption;
    /**
     * 
     * @type {DailyDecisionMakingSkillsOption}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_skills_for_daily_decision_making?: DailyDecisionMakingSkillsOption;
    /**
     * 
     * @type {string}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_skills_for_daily_decision_making_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_skills_for_daily_decision_making_visual_references?: any | null;
    /**
     * 
     * @type {MakesSelfUnderstoodOption}
     * @memberof CognitiveLevelAssessmentForm
     */
    makes_self_understood?: MakesSelfUnderstoodOption;
    /**
     * 
     * @type {MakesSelfUnderstoodOption}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_makes_self_understood?: MakesSelfUnderstoodOption;
    /**
     * 
     * @type {string}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_makes_self_understood_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_makes_self_understood_visual_references?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof CognitiveLevelAssessmentForm
     */
    has_memory_problems?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_has_memory_problems?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_has_memory_problems_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CognitiveLevelAssessmentForm
     */
    readonly predicted_has_memory_problems_visual_references?: any | null;
}

/**
 * Check if a given object implements the CognitiveLevelAssessmentForm interface.
 */
export function instanceOfCognitiveLevelAssessmentForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CognitiveLevelAssessmentFormFromJSON(json: any): CognitiveLevelAssessmentForm {
    return CognitiveLevelAssessmentFormFromJSONTyped(json, false);
}

export function CognitiveLevelAssessmentFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): CognitiveLevelAssessmentForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'severe_impairment': !exists(json, 'severe_impairment') ? undefined : json['severe_impairment'],
        'predicted_severe_impairment': !exists(json, 'predicted_severe_impairment') ? undefined : json['predicted_severe_impairment'],
        'predicted_severe_impairment_reasoning': !exists(json, 'predicted_severe_impairment_reasoning') ? undefined : json['predicted_severe_impairment_reasoning'],
        'predicted_severe_impairment_visual_references': !exists(json, 'predicted_severe_impairment_visual_references') ? undefined : ((json['predicted_severe_impairment_visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
        'skills_for_daily_decision_making': !exists(json, 'skills_for_daily_decision_making') ? undefined : DailyDecisionMakingSkillsOptionFromJSON(json['skills_for_daily_decision_making']),
        'predicted_skills_for_daily_decision_making': !exists(json, 'predicted_skills_for_daily_decision_making') ? undefined : DailyDecisionMakingSkillsOptionFromJSON(json['predicted_skills_for_daily_decision_making']),
        'predicted_skills_for_daily_decision_making_reasoning': !exists(json, 'predicted_skills_for_daily_decision_making_reasoning') ? undefined : json['predicted_skills_for_daily_decision_making_reasoning'],
        'predicted_skills_for_daily_decision_making_visual_references': !exists(json, 'predicted_skills_for_daily_decision_making_visual_references') ? undefined : json['predicted_skills_for_daily_decision_making_visual_references'],
        'makes_self_understood': !exists(json, 'makes_self_understood') ? undefined : MakesSelfUnderstoodOptionFromJSON(json['makes_self_understood']),
        'predicted_makes_self_understood': !exists(json, 'predicted_makes_self_understood') ? undefined : MakesSelfUnderstoodOptionFromJSON(json['predicted_makes_self_understood']),
        'predicted_makes_self_understood_reasoning': !exists(json, 'predicted_makes_self_understood_reasoning') ? undefined : json['predicted_makes_self_understood_reasoning'],
        'predicted_makes_self_understood_visual_references': !exists(json, 'predicted_makes_self_understood_visual_references') ? undefined : json['predicted_makes_self_understood_visual_references'],
        'has_memory_problems': !exists(json, 'has_memory_problems') ? undefined : json['has_memory_problems'],
        'predicted_has_memory_problems': !exists(json, 'predicted_has_memory_problems') ? undefined : json['predicted_has_memory_problems'],
        'predicted_has_memory_problems_reasoning': !exists(json, 'predicted_has_memory_problems_reasoning') ? undefined : json['predicted_has_memory_problems_reasoning'],
        'predicted_has_memory_problems_visual_references': !exists(json, 'predicted_has_memory_problems_visual_references') ? undefined : json['predicted_has_memory_problems_visual_references'],
    };
}

export function CognitiveLevelAssessmentFormToJSON(value?: CognitiveLevelAssessmentForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'severe_impairment': value.severe_impairment,
        'predicted_severe_impairment_visual_references': value.predicted_severe_impairment_visual_references === undefined ? undefined : ((value.predicted_severe_impairment_visual_references as Array<any>).map(VisualReferenceToJSON)),
        'skills_for_daily_decision_making': DailyDecisionMakingSkillsOptionToJSON(value.skills_for_daily_decision_making),
        'makes_self_understood': MakesSelfUnderstoodOptionToJSON(value.makes_self_understood),
        'has_memory_problems': value.has_memory_problems,
    };
}

