/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Rectangle
 */
export interface Rectangle {
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    minX: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    maxX: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    maxY: number;
}

/**
 * Check if a given object implements the Rectangle interface.
 */
export function instanceOfRectangle(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minX" in value;
    isInstance = isInstance && "minY" in value;
    isInstance = isInstance && "maxX" in value;
    isInstance = isInstance && "maxY" in value;

    return isInstance;
}

export function RectangleFromJSON(json: any): Rectangle {
    return RectangleFromJSONTyped(json, false);
}

export function RectangleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rectangle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minX': json['minX'],
        'minY': json['minY'],
        'maxX': json['maxX'],
        'maxY': json['maxY'],
    };
}

export function RectangleToJSON(value?: Rectangle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minX': value.minX,
        'minY': value.minY,
        'maxX': value.maxX,
        'maxY': value.maxY,
    };
}

