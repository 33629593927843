import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { fetchMedications } from '@/adapters/fetchExaCare';

export function useMedicationDetailsQuery(
  medicationId?: string,
  options: UseQueryOptions<MedicationsPayload[]> = {}
) {
  return useQuery(
    ['useMedicationDetailsQuery', medicationId],
    () => {
      return fetchMedications.get<MedicationsPayload[]>('/medications', {
        searchParams: { searchFilter: `DrugNameID:${medicationId}` }
      });
    },
    { enabled: !!medicationId, ...(options as any) }
  );
}

export interface MedicationImagesItem {
  id: string;
  urls: string[];
  error?: string;
}

function transformArrayToMap(
  array: MedicationImagesMap
): Map<string, MedicationImagesItem> {
  const result = new Map<string, MedicationImagesItem>();

  array.forEach((item) => {
    const id = Object.keys(item)[0];
    const data: MedicationImagesItem = (item as any)[id];
    result.set(id, data);
  });

  return result;
}

export type MedicationImagesMap = Map<string, MedicationImagesItem>;

export function useMedicationImagesQuery(
  DispensableDrugIDs: string[],
  options: UseQueryOptions<string> = {}
) {
  return useQuery(
    ['useMedicationImagesQuery', DispensableDrugIDs],
    async () => {
      return await fetchMedications
        .get<MedicationImagesMap>(
          `/medications/images?ids=${DispensableDrugIDs.join(',')}`
        )
        .then((response) => transformArrayToMap(response));
    },
    {
      enabled: true,
      cacheTime: dayjs(0).add(1, 'week').valueOf(),
      staleTime: dayjs(0).add(1, 'week').valueOf(),
      ...(options as any)
    }
  );
}

export function getDrugImage(
  drugId?: string,
  images?: MedicationImagesMap
): string | undefined {
  if (!drugId) {
    return undefined;
  }
  if (images && images.get(drugId) && images.get(drugId)?.urls?.length) {
    return images.get(drugId)?.urls[0];
  }
  return undefined;
}

export interface MedicationsPayload {
  ControlledSubstanceNarcoticsInd: boolean;
  DispensableDrugDesc?: string;
  DispensableDrugID?: string;
  DispensableDrugTallManDesc?: string;
  DispensableGenericDesc?: string;
  DoseFormDesc: string;
  DoseFormID?: string;
  DrugNameDesc: string;
  DrugNameID?: string;
  FederalDEAClassCode?: string;
  GenericDispensableDrugDesc?: string;
  GenericDrugNameCodeDesc?: string;
  Ingredients?: {
    IngredientDesc: string;
    IngredientID: string;
    IngredientType: string;
  }[];
  MedStrength: string;
  MedStrengthUnit: string;
  NameTypeCode?: string;
  NameTypeCodeDesc: string;
  RouteDesc: string;
  RouteID?: string;
  RoutedDoseFormDrugDesc?: string;
  TotalDailyDoses?: string;
  TotalDailyDosesUnit?: string;
  DrugDose?: string;
  medication_id?: string;
  isFdb: boolean;
}
