/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserFacilityAccessRequest
 */
export interface UpdateUserFacilityAccessRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserFacilityAccessRequest
     */
    facility_ids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFacilityAccessRequest
     */
    action: UpdateUserFacilityAccessRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFacilityAccessRequest
     */
    pin: string;
}


/**
 * @export
 */
export const UpdateUserFacilityAccessRequestActionEnum = {
    Grant: 'Grant',
    Revoke: 'Revoke'
} as const;
export type UpdateUserFacilityAccessRequestActionEnum = typeof UpdateUserFacilityAccessRequestActionEnum[keyof typeof UpdateUserFacilityAccessRequestActionEnum];


/**
 * Check if a given object implements the UpdateUserFacilityAccessRequest interface.
 */
export function instanceOfUpdateUserFacilityAccessRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_ids" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "pin" in value;

    return isInstance;
}

export function UpdateUserFacilityAccessRequestFromJSON(json: any): UpdateUserFacilityAccessRequest {
    return UpdateUserFacilityAccessRequestFromJSONTyped(json, false);
}

export function UpdateUserFacilityAccessRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserFacilityAccessRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_ids': json['facility_ids'],
        'action': json['action'],
        'pin': json['pin'],
    };
}

export function UpdateUserFacilityAccessRequestToJSON(value?: UpdateUserFacilityAccessRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_ids': value.facility_ids,
        'action': value.action,
        'pin': value.pin,
    };
}

