import React from 'react';
import { Stack, SxProps, Theme, Typography } from '@mui/material';

interface EmptyPageLayoutProps {
  Icon: React.FC<{ htmlColor: string; sx: { width: string; height: string } }>;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

/**
 *
 * @deprecated Use Placeholder component instead
 */
const EmptyPageLayout: React.FC<EmptyPageLayoutProps> = ({
  children,
  Icon,
  sx
}) => {
  return (
    <Stack
      gap={1}
      sx={{
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}>
      <Stack
        sx={{
          borderRadius: '50%'
        }}
        bgcolor="#B5C9D6"
        p="8px">
        <Icon htmlColor="white" sx={{ width: '40px', height: 'auto' }} />
      </Stack>
      <Typography fontWeight={500} fontSize="22px" color="#667A86">
        {children}
      </Typography>
    </Stack>
  );
};

export default EmptyPageLayout;
