/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Rectangle } from './Rectangle';
import {
    RectangleFromJSON,
    RectangleFromJSONTyped,
    RectangleToJSON,
} from './Rectangle';

/**
 * 
 * @export
 * @interface VisualReference
 */
export interface VisualReference {
    /**
     * 
     * @type {string}
     * @memberof VisualReference
     */
    document_id: string;
    /**
     * 
     * @type {string}
     * @memberof VisualReference
     */
    document_name: string;
    /**
     * 
     * @type {string}
     * @memberof VisualReference
     */
    page_num: string;
    /**
     * 
     * @type {string}
     * @memberof VisualReference
     */
    text: string;
    /**
     * 
     * @type {Rectangle}
     * @memberof VisualReference
     */
    rect: Rectangle;
}

/**
 * Check if a given object implements the VisualReference interface.
 */
export function instanceOfVisualReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "document_id" in value;
    isInstance = isInstance && "document_name" in value;
    isInstance = isInstance && "page_num" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "rect" in value;

    return isInstance;
}

export function VisualReferenceFromJSON(json: any): VisualReference {
    return VisualReferenceFromJSONTyped(json, false);
}

export function VisualReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisualReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document_id': json['document_id'],
        'document_name': json['document_name'],
        'page_num': json['page_num'],
        'text': json['text'],
        'rect': RectangleFromJSON(json['rect']),
    };
}

export function VisualReferenceToJSON(value?: VisualReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document_id': value.document_id,
        'document_name': value.document_name,
        'page_num': value.page_num,
        'text': value.text,
        'rect': RectangleToJSON(value.rect),
    };
}

