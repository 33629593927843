/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuggestionRequest
 */
export interface SuggestionRequest {
    /**
     * Criteria for suggesting the rule
     * @type {string}
     * @memberof SuggestionRequest
     */
    criteria?: string;
}

/**
 * Check if a given object implements the SuggestionRequest interface.
 */
export function instanceOfSuggestionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuggestionRequestFromJSON(json: any): SuggestionRequest {
    return SuggestionRequestFromJSONTyped(json, false);
}

export function SuggestionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': !exists(json, 'criteria') ? undefined : json['criteria'],
    };
}

export function SuggestionRequestToJSON(value?: SuggestionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': value.criteria,
    };
}

