/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceMemberListEntry } from './AudienceMemberListEntry';
import {
    AudienceMemberListEntryFromJSON,
    AudienceMemberListEntryFromJSONTyped,
    AudienceMemberListEntryToJSON,
} from './AudienceMemberListEntry';

/**
 * 
 * @export
 * @interface AudienceMembersPage
 */
export interface AudienceMembersPage {
    /**
     * 
     * @type {Array<AudienceMemberListEntry>}
     * @memberof AudienceMembersPage
     */
    members: Array<AudienceMemberListEntry>;
    /**
     * 
     * @type {number}
     * @memberof AudienceMembersPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the AudienceMembersPage interface.
 */
export function instanceOfAudienceMembersPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "members" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function AudienceMembersPageFromJSON(json: any): AudienceMembersPage {
    return AudienceMembersPageFromJSONTyped(json, false);
}

export function AudienceMembersPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudienceMembersPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'members': ((json['members'] as Array<any>).map(AudienceMemberListEntryFromJSON)),
        'total_count': json['total_count'],
    };
}

export function AudienceMembersPageToJSON(value?: AudienceMembersPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'members': ((value.members as Array<any>).map(AudienceMemberListEntryToJSON)),
        'total_count': value.total_count,
    };
}

