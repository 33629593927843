import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Badge,
  CorporateFare,
  DateRange,
  Home,
  MoveToInbox,
  People,
  Task,
  Warning,
  Web
} from '@mui/icons-material';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import { Tooltip } from '@mui/material';
import { UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  useSelectedFacilityId
} from '@/hooks/useFacilitiesQuery';
import { useCrmTaskInstancesQuery } from '@/pages/CRM/hooks/useCrmTaskInstancesQuery';
import { useFacilityAuthenticatedEmailQuery } from '@/pages/CRM/InboxPage/EmailInbox/hooks/useFacilityAuthenticatedEmailQuery';
import { RoutePath } from '@/routes/RoutePath';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';
import { generatePathWithDomain } from '@/utils/generatePathWithDomain';

import { SidebarItem } from './getSidebarEHRItems';

const useCrmTasksBadgeCount = () => {
  const pathname = useLocation().pathname;
  const facilityId = useSelectedFacilityId();
  const commonSearchQuery = {
    // Time period to show incomplete tasks (3 days ago to now)
    from_date: dayjs().subtract(3, 'days').startOf('day').toISOString(),
    to_date: dayjs().endOf('day').toISOString()
  };
  const commonOptions: UseQueryOptions<any> = {
    enabled: pathname.startsWith('/crm') && facilityId !== ALL_FACILITIES
  };
  const { data: referralTaskCount } = useCrmTaskInstancesQuery().count(
    {
      ...commonSearchQuery,
      relation_type: 'REFERRAL'
    },
    commonOptions
  );
  const { data: prospectResidentTaskCount } = useCrmTaskInstancesQuery().count(
    {
      ...commonSearchQuery,
      relation_type: 'PROSPECT_RESIDENT'
    },
    commonOptions
  );
  const { data: residentCloseConnectionTaskCount } =
    useCrmTaskInstancesQuery().count(
      {
        ...commonSearchQuery,
        relation_type: 'RESIDENT_CLOSE_CONNECTION'
      },
      commonOptions
    );
  const { data: userTaskCount } = useCrmTaskInstancesQuery().count(
    {
      ...commonSearchQuery,
      relation_type: 'USER'
    },
    commonOptions
  );
  return [
    referralTaskCount,
    prospectResidentTaskCount,
    residentCloseConnectionTaskCount,
    userTaskCount
  ].reduce((tally, model) => (tally += model?.incompleteTaskCount ?? 0), 0);
};

const useCrmReAuthenticateWarning = () => {
  const pathname = useLocation().pathname;
  const { data } = useFacilityAuthenticatedEmailQuery({
    enabled: pathname.startsWith('/crm')
  });
  if (data?.email && data.reauthenticate) {
    return (
      <Tooltip
        title="All active campaigns are paused. Please reconnect your email address to resume your active campaigns."
        arrow
        placement="top">
        <Warning color="warning" sx={{ fontSize: '17px' }} />
      </Tooltip>
    );
  }
};

export const getSidebarCRMItems = (): SidebarItem[] => {
  const currentUser = useCurrentUser().data!;
  const crmTasksBadgeCount = useCrmTasksBadgeCount();
  const crmReauthenticateWarning = useCrmReAuthenticateWarning();
  return [
    ...(currentUser.hasMinimumUserRole('L1')
      ? [
          {
            icon: <CorporateFare />,
            text: 'Admin Hub',
            path: generatePathWithDomain(RoutePath.AdminHub)
          }
        ]
      : []),
    ...(currentUser.isScreenerCustomer()
      ? []
      : [
          {
            icon: <People />,
            text: 'Prospects',
            path: RoutePath.CRMProspectsResidents,
            showOnMobile: true
          }
        ]),
    ...(FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)
      ? [
          {
            icon: <ScreenSearchDesktopIcon />,
            text: 'Screener',
            path: RoutePath.CRMScreenerAllLeads,
            showOnMobile: true
          }
        ]
      : []),
    ...(currentUser.isScreenerCustomer()
      ? []
      : [
          {
            icon: <DateRange />,
            text: 'Tasks',
            path: RoutePath.CRMTasks,
            showOnMobile: true,
            badgeProps: {
              badgeContent: crmTasksBadgeCount
            }
          },
          {
            icon: <Badge />,
            text: 'Referral Sources',
            path: RoutePath.CRMReferralSources
          },
          {
            icon: <MoveToInbox />,
            text: 'Inbox',
            path: RoutePath.CRMInboxEmails,
            showOnMobile: true
          },
          {
            icon: <Web />,
            text: 'Outbound Center',
            path: RoutePath.CRMOutboundCenterCampaigns,
            badgeProps: {
              badgeContent: crmReauthenticateWarning,
              noBackground: !!crmReauthenticateWarning
            }
          }
        ]),
    ...(FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) &&
    currentUser.isDataCenterEnabled()
      ? [
          {
            icon: <Task />,
            text: 'Data Center',
            path: generatePathWithDomain(RoutePath.DataCenterDashboards)
          }
        ]
      : [])
  ];
};

export const itemEHR: SidebarItem = {
  icon: <Home />,
  text: 'ExaCare EHR',
  path: RoutePath.ResidentOverview
};
