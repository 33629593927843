import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchResidents } from '@/adapters/fetchExaCare';

export function useMedicationInteractionsQuery(
  residentId: string,
  medicationId?: string,
  options: UseQueryOptions<DrugInteraction[]> = {}
) {
  return useQuery(
    ['useMedicationInteractionsQuery', residentId, medicationId],
    () => {
      return fetchResidents.get<DrugInteraction[]>(
        `/residents/${residentId}/medication-interactions/${medicationId}`
      );
    },
    { enabled: !!medicationId, ...(options as any) }
  );
}

export interface DrugInteraction {
  ScreenMessage: string;
  ClinicalEffects: ClinicalEffect[];
  InteractionDesc: string;
  Severity: '1' | '2' | '3' | '9';
  ScreenDrug1: ScreenDrug1;
  ScreenDrug2: ScreenDrug2;
  HasHumanClinicalTrial: boolean;
  HasCaseReports: boolean;
  HasMeetingAbstract: boolean;
  HasVitroOrAnimalStudy: boolean;
  HasMfgInfo: boolean;
  HasReview: boolean;
  MonographID: number;
  InteractionID: number;
  SeverityDesc: string;
  EDIPageReference: string;
  ScreenDrugs: ScreenDrug[];
  ClinicalEffectsNarrative: string;
}

interface ClinicalEffect {
  ClinicalEffectCode: string;
  ClinicalEffectDesc: string;
}

interface ScreenDrug1 {
  DrugDose: any;
  Prospective: boolean;
  DrugDesc: string;
  DrugID: string;
  DrugConceptType: number;
}

interface ScreenDrug2 {
  DrugDose: any;
  Prospective: boolean;
  DrugDesc: string;
  DrugID: string;
  DrugConceptType: number;
}

interface ScreenDrug {
  DrugDose: any;
  Prospective: boolean;
  DrugDesc: string;
  DrugID: string;
  DrugConceptType: number;
}

export interface DrugInteractionAlertType {
  id: string;
  accept: boolean;
  Severity: DrugInteraction['Severity'];
  ScreenMessage: DrugInteraction['ScreenMessage'];
  ClinicalEffectsNarrative: DrugInteraction['ClinicalEffectsNarrative'];
  SeverityDesc: DrugInteraction['SeverityDesc'];
}
