/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DocumentProcessingStatus = {
    InProcess: 'In Process',
    Processed: 'Processed',
    Failed: 'Failed'
} as const;
export type DocumentProcessingStatus = typeof DocumentProcessingStatus[keyof typeof DocumentProcessingStatus];


export function DocumentProcessingStatusFromJSON(json: any): DocumentProcessingStatus {
    return DocumentProcessingStatusFromJSONTyped(json, false);
}

export function DocumentProcessingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentProcessingStatus {
    return json as DocumentProcessingStatus;
}

export function DocumentProcessingStatusToJSON(value?: DocumentProcessingStatus | null): any {
    return value as any;
}

