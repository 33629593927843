import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { generateStaticDate } from '@/components/FrequencyTimeForm/options';

dayjs.extend(localizedFormat);

interface GetExactExecutionTimeParams {
  time: string;
  format?: string | null;
}

export const getExactExecutionTime = ({
  time,
  format
}: GetExactExecutionTimeParams): {
  display: string;
  value: number;
} => {
  try {
    const dayjsDate = dayjs(time);
    const value = generateStaticDate({
      hour: dayjsDate.hour(),
      minute: dayjsDate.minute()
    }).getTime();
    return {
      display: dayjsDate.format(format || 'LT'),
      value
    };
  } catch (e) {
    Sentry.captureException(e);
    return {
      display: '',
      value: 0
    };
  }
};
