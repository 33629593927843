import React from 'react';
import { Button, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { UserClockAction } from '@/hooks/useTimeClock';

import { ClockInDialogCaptureFrame } from './ClockInDialogCaptureFrame';
import { ClockInDialogHeaders } from './ClockInDialogHeaders';

interface ClockInDialogConfirmProps {
  handleRetakePhoto: VoidFunction;
  handleConfirmPhoto: VoidFunction;
  dataUrl: string | null;
  action: UserClockAction;
  date: dayjs.Dayjs;
}

export const ClockInDialogConfirm: React.FC<ClockInDialogConfirmProps> = ({
  handleRetakePhoto,
  handleConfirmPhoto,
  dataUrl,
  action,
  date
}) => {
  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
        height="100%">
        <ClockInDialogHeaders action={action} />
        <ClockInDialogCaptureFrame
          action={action}
          date={date}
          sx={{
            background: `url("${dataUrl}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundColor: 'black'
          }}
        />
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleRetakePhoto}>
            Retake Photo
          </Button>
          <Button
            sx={{
              textTransform: 'capitalize'
            }}
            color="primary"
            variant="contained"
            onClick={handleConfirmPhoto}>
            Confirm Clock {action}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
