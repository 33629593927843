/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TaskExtensionType = {
    MedicationTask: 'MEDICATION_TASK',
    CarePlanEntry: 'CARE_PLAN_ENTRY'
} as const;
export type TaskExtensionType = typeof TaskExtensionType[keyof typeof TaskExtensionType];


export function TaskExtensionTypeFromJSON(json: any): TaskExtensionType {
    return TaskExtensionTypeFromJSONTyped(json, false);
}

export function TaskExtensionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskExtensionType {
    return json as TaskExtensionType;
}

export function TaskExtensionTypeToJSON(value?: TaskExtensionType | null): any {
    return value as any;
}

