/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrescriptionOrderListEntry
 */
export interface PrescriptionOrderListEntry {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    fax_id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    order_status: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    prescription: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    pharmacy_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionOrderListEntry
     */
    is_linked: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderListEntry
     */
    prescription_id?: string;
}

/**
 * Check if a given object implements the PrescriptionOrderListEntry interface.
 */
export function instanceOfPrescriptionOrderListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "fax_id" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "order_status" in value;
    isInstance = isInstance && "prescription" in value;
    isInstance = isInstance && "pharmacy_name" in value;
    isInstance = isInstance && "is_linked" in value;

    return isInstance;
}

export function PrescriptionOrderListEntryFromJSON(json: any): PrescriptionOrderListEntry {
    return PrescriptionOrderListEntryFromJSONTyped(json, false);
}

export function PrescriptionOrderListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionOrderListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'fax_id': json['fax_id'],
        'photo_url': !exists(json, 'photo_url') ? undefined : json['photo_url'],
        'resident_name': json['resident_name'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'order_status': json['order_status'],
        'prescription': json['prescription'],
        'pharmacy_name': json['pharmacy_name'],
        'is_linked': json['is_linked'],
        'prescription_id': !exists(json, 'prescription_id') ? undefined : json['prescription_id'],
    };
}

export function PrescriptionOrderListEntryToJSON(value?: PrescriptionOrderListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'fax_id': value.fax_id,
        'photo_url': value.photo_url,
        'resident_name': value.resident_name,
        'room': value.room,
        'order_status': value.order_status,
        'prescription': value.prescription,
        'pharmacy_name': value.pharmacy_name,
        'is_linked': value.is_linked,
        'prescription_id': value.prescription_id,
    };
}

