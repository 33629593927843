/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PdfConversionStatus = {
    None: 'none',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type PdfConversionStatus = typeof PdfConversionStatus[keyof typeof PdfConversionStatus];


export function PdfConversionStatusFromJSON(json: any): PdfConversionStatus {
    return PdfConversionStatusFromJSONTyped(json, false);
}

export function PdfConversionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfConversionStatus {
    return json as PdfConversionStatus;
}

export function PdfConversionStatusToJSON(value?: PdfConversionStatus | null): any {
    return value as any;
}

