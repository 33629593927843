import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import { fetchCRM } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { USE_FACILITIES_QUERY_KEY } from '@/hooks/useFacilitiesQuery';
import {
  ResidentGroup,
  USE_RESIDENT_GROUPS_QUERY_KEY
} from '@/hooks/useResidentGroupsQuery';
import { TimezonePayload } from '@/hooks/useTimezonesQuery';

interface CRMFacilityInfoPayload {
  id: string;
  email: string;
  description: string;
  leadership_vision: string;
  services_provided: string;
  features: string;
}

export interface CRMFacilityPayload {
  id: string;
  state_id: string;
  time_zone_id: string;
  address: string;
  name: string;
  primary_phone: string | null;
  fax_number: string | null;
  timezone: TimezonePayload;
  crm_facility_info: CRMFacilityInfoPayload | null;
  seats: {
    rooms: number;
    residents: number;
  };
  resident_groups: ResidentGroup[];
}

export interface CRMUpdateFacilityPayload {
  id: string;
  // FACILITY FIELDS:
  address: string | null;
  name: string | null;
  primary_phone: string | null;
  fax_number: string | null;
  // CRM INFO FIELDS:
  email: string | null;
  description: string | null;
  leadership_vision: string | null;
  services_provided: string | null;
  features: string | null;
  resident_groups?: ResidentGroup[];
}

export const USE_CRM_FACILITIES_QUERY_KEY = ['useCRMFacilitiesQuery'];

export function useCRMFacilitiesQuery() {
  const invalidate = () => {
    queryClient.invalidateQueries(USE_CRM_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_FACILITIES_QUERY_KEY);
    queryClient.invalidateQueries(USE_RESIDENT_GROUPS_QUERY_KEY);
  };

  return {
    invalidate,

    findOne: (
      facilityId?: string | null,
      options: UseQueryOptions<CRMFacilityPayload> = {}
    ) => {
      return useQuery(
        [...USE_CRM_FACILITIES_QUERY_KEY, facilityId],
        () => {
          return fetchCRM.get<CRMFacilityPayload>(
            `/crm-facilities/${facilityId}`
          );
        },
        {
          enabled: !!facilityId,
          ...(options as any)
        }
      );
    },

    mutations: (options: UseMutationOptions<CRMFacilityPayload> = {}) => ({
      update: useMutation(
        (payload: Partial<CRMUpdateFacilityPayload>) => {
          return fetchCRM.put(`/crm-facilities/${payload.id}`, payload);
        },
        { onSuccess: invalidate, ...(options as any) }
      )
    })
  };
}
