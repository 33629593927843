/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessPaymentRequest
 */
export interface ProcessPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessPaymentRequest
     */
    payment_method_id: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessPaymentRequest
     */
    memo?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessPaymentRequest
     */
    amount: number;
}

/**
 * Check if a given object implements the ProcessPaymentRequest interface.
 */
export function instanceOfProcessPaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payment_method_id" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function ProcessPaymentRequestFromJSON(json: any): ProcessPaymentRequest {
    return ProcessPaymentRequestFromJSONTyped(json, false);
}

export function ProcessPaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessPaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment_method_id': json['payment_method_id'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'amount': json['amount'],
    };
}

export function ProcessPaymentRequestToJSON(value?: ProcessPaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_method_id': value.payment_method_id,
        'memo': value.memo,
        'amount': value.amount,
    };
}

