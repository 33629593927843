import React from 'react';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemTextProps,
  Typography
} from '@mui/material';

import { ResidentPayload } from '@/hooks/useResidentFacesheetsQuery';
import theme from '@/theme';

interface RenderListItemParams {
  ListItemTextProps?: ListItemTextProps;
}

export const ResidentCloseConnectionTags = {
  chiefPointOfContact: 'chief_point_of_contact',
  powerOfAttorney: 'power_of_attorney',
  medicalPowerOfAttorney: 'medical_power_of_attorney',
  billRecipient: 'bill_recipient',
  newsMailingRecipient: 'news_mailings_recipient'
} as const;

export const CloseConnectionFlagDisplay = {
  chief_point_of_contact: 'Primary Contact',
  power_of_attorney: 'POA',
  medical_power_of_attorney: 'Medical POA',
  bill_recipient: 'Bill Recipient',
  news_mailings_recipient: 'News/Mailing Recipient'
} as const;

export type ResidentCloseConnectionTags =
  typeof ResidentCloseConnectionTags[keyof typeof ResidentCloseConnectionTags];
export class ResidentCloseConnectionPayload {
  id: string;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
  gender: string;
  current_address: string;
  phone: string;
  email: string;
  relationship: string;
  chief_point_of_contact: boolean | null;
  power_of_attorney: boolean | null;
  medical_power_of_attorney: boolean | null;
  bill_recipient: boolean | null;
  news_mailings_recipient: boolean | null;
  resident?: ResidentPayload;
  emergency_contact?: boolean;

  constructor(payload: ResidentCloseConnectionPayload) {
    Object.assign(this, payload);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name || ''}`;
  }

  get initials(): string {
    return `${this.first_name[0]}${this.last_name ? this.last_name[0] : ''}`;
  }

  public static toApi = (
    form: Partial<ResidentCloseConnectionModel>
  ): Partial<ResidentCloseConnectionModel> => {
    return {
      id: form.id || undefined,
      resident_id: form.resident_id || undefined,
      first_name: form.first_name,
      last_name: form.last_name || undefined,
      dob: form.dob || undefined,
      gender: form.gender || undefined,
      current_address: form.current_address || undefined,
      phone: form.phone || undefined,
      email: form.email || undefined,
      relationship: form.relationship || undefined,
      chief_point_of_contact: form.chief_point_of_contact,
      power_of_attorney: form.power_of_attorney,
      medical_power_of_attorney: form.medical_power_of_attorney,
      bill_recipient: form.bill_recipient,
      news_mailings_recipient: form.news_mailings_recipient,
      emergency_contact: form.emergency_contact
    };
  };
}

export class ResidentCloseConnectionModel extends ResidentCloseConnectionPayload {
  constructor(payload: ResidentCloseConnectionPayload) {
    super(payload);
  }

  public getRelationshipWithResident() {
    const residentName = this.resident?.first_name
      ? `${this.resident?.first_name} ${this.resident?.last_name}`
      : 'this resident';
    return `${
      this.relationship ? `${this.relationship} of` : 'Related to'
    } ${residentName}`;
  }

  public renderListItem = ({
    ListItemTextProps = {}
  }: RenderListItemParams = {}): JSX.Element => {
    return (
      <ListItem disableGutters disablePadding dense>
        <ListItemAvatar>
          <Avatar sx={{ width: '32px', height: '32px' }}>
            {this.initials}
          </Avatar>
        </ListItemAvatar>
        <ListItemText {...ListItemTextProps} />
      </ListItem>
    );
  };

  public renderDetailsCell = (showNoEmailError = false): JSX.Element => (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      key={`close-connection-${this.id}`}>
      <Box>
        <Avatar sx={{ height: 32, width: 32, fontSize: '1rem' }}>
          {this.initials}
        </Avatar>
      </Box>
      <Box display="column">
        <Box display="flex" alignItems="center" gap="8px">
          {this.fullName}
          <Typography variant="body2">
            {this.getRelationshipWithResident()}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={
              showNoEmailError && !this.email
                ? theme.palette.error.main
                : theme.palette.primary.main
            }>
            {showNoEmailError && !this.email ? 'No Email' : this.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
