/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceContactTag } from './SourceContactTag';
import {
    SourceContactTagFromJSON,
    SourceContactTagFromJSONTyped,
    SourceContactTagToJSON,
} from './SourceContactTag';

/**
 * 
 * @export
 * @interface SourceContact
 */
export interface SourceContact {
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    phone_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    position?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SourceContact
     */
    primary_contact: boolean;
    /**
     * 
     * @type {string}
     * @memberof SourceContact
     */
    tag_id?: string | null;
    /**
     * 
     * @type {SourceContactTag}
     * @memberof SourceContact
     */
    tag?: SourceContactTag;
}

/**
 * Check if a given object implements the SourceContact interface.
 */
export function instanceOfSourceContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "primary_contact" in value;

    return isInstance;
}

export function SourceContactFromJSON(json: any): SourceContact {
    return SourceContactFromJSONTyped(json, false);
}

export function SourceContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source_id': json['source_id'],
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'primary_contact': json['primary_contact'],
        'tag_id': !exists(json, 'tag_id') ? undefined : json['tag_id'],
        'tag': !exists(json, 'tag') ? undefined : SourceContactTagFromJSON(json['tag']),
    };
}

export function SourceContactToJSON(value?: SourceContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'source_id': value.source_id,
        'name': value.name,
        'email': value.email,
        'phone_number': value.phone_number,
        'position': value.position,
        'primary_contact': value.primary_contact,
        'tag_id': value.tag_id,
        'tag': SourceContactTagToJSON(value.tag),
    };
}

