/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillDeposit
 */
export interface BillDeposit {
    /**
     * 
     * @type {string}
     * @memberof BillDeposit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDeposit
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof BillDeposit
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof BillDeposit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillDeposit
     */
    gl_code: string;
    /**
     * 
     * @type {string}
     * @memberof BillDeposit
     */
    createdAt?: string;
}

/**
 * Check if a given object implements the BillDeposit interface.
 */
export function instanceOfBillDeposit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "gl_code" in value;

    return isInstance;
}

export function BillDepositFromJSON(json: any): BillDeposit {
    return BillDepositFromJSONTyped(json, false);
}

export function BillDepositFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillDeposit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'name': json['name'],
        'gl_code': json['gl_code'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function BillDepositToJSON(value?: BillDeposit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'amount': value.amount,
        'name': value.name,
        'gl_code': value.gl_code,
        'createdAt': value.createdAt,
    };
}

