import * as React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import uniqWith from 'lodash/uniqWith';

import { fetchMedications } from '@/adapters/fetchExaCare';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';

export function SelectMedicationInput<
  Option extends Record<string, string>,
  TFields extends FieldValues
>({
  name,
  control,
  rules,
  optionIdKey,
  optionLabelKey,
  label,
  onChange,
  disabled = false
}: React.PropsWithChildren<
  UseControllerProps<TFields> & {
    label: string;
    optionIdKey: keyof Option;
    optionLabelKey: keyof Option;
    onChange?: () => void;
    disabled?: boolean;
  }
>) {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const [loading, setLoading] = React.useState(false);

  const {
    field: { onChange: onChangeField, onBlur, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  React.useEffect(() => {
    if (value?.[optionIdKey]) {
      setInputValue(value?.[optionLabelKey] ?? '');
      setOptions([value]);
    }
  }, [onChange, onBlur]);

  const fetchQuery = async (query: string) => {
    try {
      setLoading(true);
      const medications = (await fetchMedications.get('/medications', {
        searchParams: { searchText: query }
      })) as MedicationsPayload[];
      setOptions(
        // @ts-ignore
        uniqWith(medications, (a, b) => a.DrugNameID == b.DrugNameID).map(
          (v) => ({
            [optionLabelKey]: v.DrugNameDesc,
            [optionIdKey]: v.DrugNameID
          })
        )
      );
    } catch (e) {
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = React.useMemo(
    () => debounce(fetchQuery, 500, { trailing: true }),
    []
  );

  React.useEffect(() => {
    // Prevents fetching when an option is selected
    if (value?.[optionLabelKey] === inputValue) {
      return undefined;
    }

    if (inputValue === '') {
      return undefined;
    }

    setLoading(true);
    setOptions([]);
    debouncedFetch(inputValue);
  }, [inputValue, value, debouncedFetch]);

  return (
    <Autocomplete
      id={`mui-component-autocomplete-${name}`}
      // @ts-ignore
      getOptionLabel={(option) => option[optionLabelKey]}
      isOptionEqualToValue={(option, value) => {
        return option[optionIdKey] == value[optionIdKey];
      }}
      filterOptions={(x) => x}
      options={options}
      loading={loading}
      loadingText="Searching medications..."
      noOptionsText={`No medications found for "${inputValue}"`}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      // @ts-expect-error
      onChange={(event: any, newValue: Option | null) => {
        if (newValue === null) {
          setInputValue('');
          onChangeField(null);
          setOptions([]);
        } else {
          onChangeField(newValue);
          setInputValue(newValue[optionLabelKey]);
        }
        onChange?.();
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          InputProps={{
            ...InputProps
          }}
          error={!!error}
          label={label}
          fullWidth
          placeholder={`Type to search for medications ie "metformin"`}
        />
      )}
      renderOption={(props, option) => {
        // Override the default because getOptionLabel sets the key to the
        // label field and there may be multiple rows with the same label
        return (
          <li {...props} key={option[optionIdKey]}>
            {option[optionLabelKey]}
          </li>
        );
      }}
    />
  );
}
