import { Fetcher, FetcherInit } from '@/adapters/fetcher';

class ExaCareFetcher extends Fetcher {
  constructor(options = {} as Partial<FetcherInit>) {
    super({
      ...options,
      beforeInit: [
        async (input, init) => {
          const bearer = `Bearer ${await window.getAccessTokenSilently()}`;
          init.headers['Authorization'] = bearer;
        },
        async (input, init) => {
          if (!init.headers['Content-Type']) {
            if (init.json) {
              init.headers['Content-Type'] = 'application/json';
            } else {
              init.headers['Content-Type'] = 'text/plain';
            }
          }
        },
        async (input, init) => {
          init.headers['x-fe-page'] = window.location.href;
        },
        ...(options.beforeInit ?? [])
      ]
    });
  }

  async json<T = Record<string, any>>(
    input: string,
    options = {} as Partial<FetcherInit>
  ) {
    const json = await super.json(input, options);
    return ('data' in json ? json.data : json) as T;
  }

  get<T = Record<string, any>>(
    input: string,
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'GET' });
  }
  post<T = Record<string, any>>(
    input: string,
    json = {} as FetcherInit['json'],
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'POST', json });
  }
  put<T = Record<string, any>>(
    input: string,
    json: FetcherInit['json'],
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'PUT', json });
  }
  patch<T = Record<string, any>>(
    input: string,
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'PATCH' });
  }
  head<T = Record<string, any>>(
    input: string,
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'HEAD' });
  }
  delete<T = Record<string, any>>(
    input: string,
    json: FetcherInit['json'] = {},
    options = {} as Partial<FetcherInit>
  ) {
    return this.json<T>(input, { ...options, method: 'DELETE', json });
  }
}

const fetchExaCare = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_APP
});
export default fetchExaCare;

export const fetchResidentResources = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_RESIDENT_RESOURCES
});

export const fetchResidents = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_RESIDENTS
});

export const fetchBilling = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_BILLING
});

export const fetchMedications = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_MEDICATION
});

export const fetchPharmacyIntegration = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_PHARMACY
});

export const fetchTimeclock = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_TIMECLOCK
});

export const fetchIncidents = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_INCIDENTS
});

export const fetchUsers = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_USERS
});

export const fetchCRM = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_CRM
});

export const fetchCRMReferrals = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_CRM_REFERRALS
});

export const fetchCRMCalls = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_CRM_CALLS
});

export const fetchCRMTexts = new ExaCareFetcher({
  prefixUrl: import.meta.env.VITE_API_BASE_PATH_CRM_TEXTS
});
