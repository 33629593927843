/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplyCreditRequest,
  ApplyDepositRequest,
  CreateBillItem201Response,
  CreateCorrectionCreditRequest,
  CreateOneTimeBillItem201Response,
  CreateOneTimeBillRequest,
  CreatePaymentRecord201Response,
  CreatePaymentRecordRequest,
  FindAllBills200Response,
  FindAllPayments200Response,
  FindAllStatements200Response,
  FindOneBill200Response,
  GenerateBillRequest,
  GenerateBills200Response,
  GetBillPdfReceiptUrl200Response,
  GetPaymentLink200Response,
  ManualBillItem,
  ManualUpdateBillItemRequest,
  Month,
  OneTimeBillItem,
  ProcessDepositPayment200Response,
  ProcessDepositPaymentRequest,
  ProcessPayment200Response,
  ProcessPaymentRequest,
  RecordSettlementPayment201Response,
  RefundPayment200Response,
  RefundPaymentRecordRequest,
  SendBillEmail200Response,
  SendBillEmailRequest,
  SettlementPayment,
  UpdatePaymentRequest,
} from '../models/index';
import {
    ApplyCreditRequestFromJSON,
    ApplyCreditRequestToJSON,
    ApplyDepositRequestFromJSON,
    ApplyDepositRequestToJSON,
    CreateBillItem201ResponseFromJSON,
    CreateBillItem201ResponseToJSON,
    CreateCorrectionCreditRequestFromJSON,
    CreateCorrectionCreditRequestToJSON,
    CreateOneTimeBillItem201ResponseFromJSON,
    CreateOneTimeBillItem201ResponseToJSON,
    CreateOneTimeBillRequestFromJSON,
    CreateOneTimeBillRequestToJSON,
    CreatePaymentRecord201ResponseFromJSON,
    CreatePaymentRecord201ResponseToJSON,
    CreatePaymentRecordRequestFromJSON,
    CreatePaymentRecordRequestToJSON,
    FindAllBills200ResponseFromJSON,
    FindAllBills200ResponseToJSON,
    FindAllPayments200ResponseFromJSON,
    FindAllPayments200ResponseToJSON,
    FindAllStatements200ResponseFromJSON,
    FindAllStatements200ResponseToJSON,
    FindOneBill200ResponseFromJSON,
    FindOneBill200ResponseToJSON,
    GenerateBillRequestFromJSON,
    GenerateBillRequestToJSON,
    GenerateBills200ResponseFromJSON,
    GenerateBills200ResponseToJSON,
    GetBillPdfReceiptUrl200ResponseFromJSON,
    GetBillPdfReceiptUrl200ResponseToJSON,
    GetPaymentLink200ResponseFromJSON,
    GetPaymentLink200ResponseToJSON,
    ManualBillItemFromJSON,
    ManualBillItemToJSON,
    ManualUpdateBillItemRequestFromJSON,
    ManualUpdateBillItemRequestToJSON,
    MonthFromJSON,
    MonthToJSON,
    OneTimeBillItemFromJSON,
    OneTimeBillItemToJSON,
    ProcessDepositPayment200ResponseFromJSON,
    ProcessDepositPayment200ResponseToJSON,
    ProcessDepositPaymentRequestFromJSON,
    ProcessDepositPaymentRequestToJSON,
    ProcessPayment200ResponseFromJSON,
    ProcessPayment200ResponseToJSON,
    ProcessPaymentRequestFromJSON,
    ProcessPaymentRequestToJSON,
    RecordSettlementPayment201ResponseFromJSON,
    RecordSettlementPayment201ResponseToJSON,
    RefundPayment200ResponseFromJSON,
    RefundPayment200ResponseToJSON,
    RefundPaymentRecordRequestFromJSON,
    RefundPaymentRecordRequestToJSON,
    SendBillEmail200ResponseFromJSON,
    SendBillEmail200ResponseToJSON,
    SendBillEmailRequestFromJSON,
    SendBillEmailRequestToJSON,
    SettlementPaymentFromJSON,
    SettlementPaymentToJSON,
    UpdatePaymentRequestFromJSON,
    UpdatePaymentRequestToJSON,
} from '../models/index';

export interface ApplyCreditToBillRequest {
    billId: string;
    applyCreditRequest: ApplyCreditRequest;
}

export interface ApplyDepositToBillRequest {
    billId: string;
    applyDepositRequest: ApplyDepositRequest;
}

export interface ApproveBillRequest {
    billId: string;
}

export interface CancelPaymentLinkRequest {
    paymentId: string;
}

export interface CreateBillItemRequest {
    billId: string;
    manualBillItem: ManualBillItem;
}

export interface CreateCorrectionCreditOperationRequest {
    billId: string;
    itemId: string;
    createCorrectionCreditRequest: CreateCorrectionCreditRequest;
}

export interface CreateOneTimeBillOperationRequest {
    createOneTimeBillRequest: CreateOneTimeBillRequest;
}

export interface CreateOneTimeBillItemRequest {
    billId: string;
    oneTimeBillItem: OneTimeBillItem;
}

export interface CreatePaymentRecordOperationRequest {
    createPaymentRecordRequest: CreatePaymentRecordRequest;
}

export interface DeleteBillItemRequest {
    billId: string;
    itemId: string;
}

export interface DeleteOneTimeBillItemRequest {
    billId: string;
    itemId: string;
}

export interface FindAllBillsRequest {
    oneTimeBills: boolean;
    residentId?: string;
    facilityId?: string;
    month?: Month;
    year?: number;
}

export interface FindAllPaymentsRequest {
    startDate: string;
    endDate: string;
    page: number;
    limit: number;
    facilityId?: string;
    status?: string;
    residentId?: string;
    search?: string;
}

export interface FindAllStatementsRequest {
    residentId?: string;
    facilityId?: string;
    month?: Month;
    year?: number;
}

export interface FindOneBillRequest {
    billId: string;
    facilityId?: string;
}

export interface GenerateBillsRequest {
    generateBillRequest: GenerateBillRequest;
}

export interface GetBillPdfReceiptUrlRequest {
    billId: string;
}

export interface GetPaymentLinkRequest {
    paymentId: string;
}

export interface GetPaymentRecordPdfReceiptUrlRequest {
    paymentId: string;
    isBillPayment: boolean;
}

export interface GetRefundPdfReceiptUrlRequest {
    refundId: string;
}

export interface GetStatementPdfUrlRequest {
    statementId: string;
}

export interface ProcessDepositPaymentOperationRequest {
    depositId: string;
    processDepositPaymentRequest: ProcessDepositPaymentRequest;
}

export interface ProcessPaymentOperationRequest {
    billId: string;
    processPaymentRequest: ProcessPaymentRequest;
}

export interface RecordSettlementPaymentRequest {
    settlementPayment: SettlementPayment;
}

export interface RefundPaymentRequest {
    paymentId: string;
    refundPaymentRecordRequest: RefundPaymentRecordRequest;
}

export interface RegenerateBillRequest {
    billId: string;
}

export interface ResetBillApprovalRequest {
    billId: string;
}

export interface SendBillEmailOperationRequest {
    billId: string;
    sendBillEmailRequest: SendBillEmailRequest;
}

export interface UpdateBillItemRequest {
    billId: string;
    itemId: string;
    manualUpdateBillItemRequest: ManualUpdateBillItemRequest;
}

export interface UpdateOneTimeBillItemRequest {
    billId: string;
    itemId: string;
    oneTimeBillItem: OneTimeBillItem;
}

export interface UpdatePaymentRecordRequest {
    paymentId: string;
    updatePaymentRequest: UpdatePaymentRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Apply credits to a bill
     */
    async applyCreditToBillRaw(requestParameters: ApplyCreditToBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling applyCreditToBill.');
        }

        if (requestParameters.applyCreditRequest === null || requestParameters.applyCreditRequest === undefined) {
            throw new runtime.RequiredError('applyCreditRequest','Required parameter requestParameters.applyCreditRequest was null or undefined when calling applyCreditToBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/credits/apply`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyCreditRequestToJSON(requestParameters.applyCreditRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Apply credits to a bill
     */
    async applyCreditToBill(requestParameters: ApplyCreditToBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.applyCreditToBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Apply deposits to a bill
     */
    async applyDepositToBillRaw(requestParameters: ApplyDepositToBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling applyDepositToBill.');
        }

        if (requestParameters.applyDepositRequest === null || requestParameters.applyDepositRequest === undefined) {
            throw new runtime.RequiredError('applyDepositRequest','Required parameter requestParameters.applyDepositRequest was null or undefined when calling applyDepositToBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/deposits/apply`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyDepositRequestToJSON(requestParameters.applyDepositRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Apply deposits to a bill
     */
    async applyDepositToBill(requestParameters: ApplyDepositToBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.applyDepositToBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve a bill
     */
    async approveBillRaw(requestParameters: ApproveBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling approveBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}/approve`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Approve a bill
     */
    async approveBill(requestParameters: ApproveBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.approveBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel payment link
     */
    async cancelPaymentLinkRaw(requestParameters: CancelPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentLink200Response>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling cancelPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment-records/{payment_id}/link/cancel`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Cancel payment link
     */
    async cancelPaymentLink(requestParameters: CancelPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentLink200Response> {
        const response = await this.cancelPaymentLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually add a bill item to a bill
     */
    async createBillItemRaw(requestParameters: CreateBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBillItem201Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling createBillItem.');
        }

        if (requestParameters.manualBillItem === null || requestParameters.manualBillItem === undefined) {
            throw new runtime.RequiredError('manualBillItem','Required parameter requestParameters.manualBillItem was null or undefined when calling createBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/items`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualBillItemToJSON(requestParameters.manualBillItem),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBillItem201ResponseFromJSON(jsonValue));
    }

    /**
     * Manually add a bill item to a bill
     */
    async createBillItem(requestParameters: CreateBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBillItem201Response> {
        const response = await this.createBillItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create correction credit for a bill item
     */
    async createCorrectionCreditRaw(requestParameters: CreateCorrectionCreditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling createCorrectionCredit.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling createCorrectionCredit.');
        }

        if (requestParameters.createCorrectionCreditRequest === null || requestParameters.createCorrectionCreditRequest === undefined) {
            throw new runtime.RequiredError('createCorrectionCreditRequest','Required parameter requestParameters.createCorrectionCreditRequest was null or undefined when calling createCorrectionCredit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/items/{item_id}/credit`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCorrectionCreditRequestToJSON(requestParameters.createCorrectionCreditRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Create correction credit for a bill item
     */
    async createCorrectionCredit(requestParameters: CreateCorrectionCreditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.createCorrectionCreditRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create one-time bills for a facility
     */
    async createOneTimeBillRaw(requestParameters: CreateOneTimeBillOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.createOneTimeBillRequest === null || requestParameters.createOneTimeBillRequest === undefined) {
            throw new runtime.RequiredError('createOneTimeBillRequest','Required parameter requestParameters.createOneTimeBillRequest was null or undefined when calling createOneTimeBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/one-time`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOneTimeBillRequestToJSON(requestParameters.createOneTimeBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Create one-time bills for a facility
     */
    async createOneTimeBill(requestParameters: CreateOneTimeBillOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.createOneTimeBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually add a one time bill item to a bill
     */
    async createOneTimeBillItemRaw(requestParameters: CreateOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeBillItem201Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling createOneTimeBillItem.');
        }

        if (requestParameters.oneTimeBillItem === null || requestParameters.oneTimeBillItem === undefined) {
            throw new runtime.RequiredError('oneTimeBillItem','Required parameter requestParameters.oneTimeBillItem was null or undefined when calling createOneTimeBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-bills/{bill_id}/items`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeBillItemToJSON(requestParameters.oneTimeBillItem),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeBillItem201ResponseFromJSON(jsonValue));
    }

    /**
     * Manually add a one time bill item to a bill
     */
    async createOneTimeBillItem(requestParameters: CreateOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeBillItem201Response> {
        const response = await this.createOneTimeBillItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a cash or check payment record
     */
    async createPaymentRecordRaw(requestParameters: CreatePaymentRecordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePaymentRecord201Response>> {
        if (requestParameters.createPaymentRecordRequest === null || requestParameters.createPaymentRecordRequest === undefined) {
            throw new runtime.RequiredError('createPaymentRecordRequest','Required parameter requestParameters.createPaymentRecordRequest was null or undefined when calling createPaymentRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/payment-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentRecordRequestToJSON(requestParameters.createPaymentRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentRecord201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a cash or check payment record
     */
    async createPaymentRecord(requestParameters: CreatePaymentRecordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePaymentRecord201Response> {
        const response = await this.createPaymentRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually delete a bill item from a bill
     */
    async deleteBillItemRaw(requestParameters: DeleteBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling deleteBillItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling deleteBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}/items/{item_id}`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Manually delete a bill item from a bill
     */
    async deleteBillItem(requestParameters: DeleteBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBillItemRaw(requestParameters, initOverrides);
    }

    /**
     * Manually delete a one time bill item from a bill
     */
    async deleteOneTimeBillItemRaw(requestParameters: DeleteOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling deleteOneTimeBillItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling deleteOneTimeBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/one-time-bills/{bill_id}/items/{item_id}`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Manually delete a one time bill item from a bill
     */
    async deleteOneTimeBillItem(requestParameters: DeleteOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOneTimeBillItemRaw(requestParameters, initOverrides);
    }

    /**
     * Get all generated bills
     */
    async findAllBillsRaw(requestParameters: FindAllBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllBills200Response>> {
        if (requestParameters.oneTimeBills === null || requestParameters.oneTimeBills === undefined) {
            throw new runtime.RequiredError('oneTimeBills','Required parameter requestParameters.oneTimeBills was null or undefined when calling findAllBills.');
        }

        const queryParameters: any = {};

        if (requestParameters.oneTimeBills !== undefined) {
            queryParameters['one_time_bills'] = requestParameters.oneTimeBills;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllBills200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all generated bills
     */
    async findAllBills(requestParameters: FindAllBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllBills200Response> {
        const response = await this.findAllBillsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all payment records
     */
    async findAllPaymentsRaw(requestParameters: FindAllPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllPayments200Response>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling findAllPayments.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling findAllPayments.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling findAllPayments.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling findAllPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment-records`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllPayments200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all payment records
     */
    async findAllPayments(requestParameters: FindAllPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllPayments200Response> {
        const response = await this.findAllPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all statements
     */
    async findAllStatementsRaw(requestParameters: FindAllStatementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllStatements200Response>> {
        const queryParameters: any = {};

        if (requestParameters.residentId !== undefined) {
            queryParameters['resident_id'] = requestParameters.residentId;
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllStatements200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all statements
     */
    async findAllStatements(requestParameters: FindAllStatementsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllStatements200Response> {
        const response = await this.findAllStatementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get one bill by id
     */
    async findOneBillRaw(requestParameters: FindOneBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling findOneBill.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Get one bill by id
     */
    async findOneBill(requestParameters: FindOneBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.findOneBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate bills for a facility
     */
    async generateBillsRaw(requestParameters: GenerateBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateBills200Response>> {
        if (requestParameters.generateBillRequest === null || requestParameters.generateBillRequest === undefined) {
            throw new runtime.RequiredError('generateBillRequest','Required parameter requestParameters.generateBillRequest was null or undefined when calling generateBills.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateBillRequestToJSON(requestParameters.generateBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateBills200ResponseFromJSON(jsonValue));
    }

    /**
     * Generate bills for a facility
     */
    async generateBills(requestParameters: GenerateBillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateBills200Response> {
        const response = await this.generateBillsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Approved bill PDF receipt URL
     */
    async getBillPdfReceiptUrlRaw(requestParameters: GetBillPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillPdfReceiptUrl200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling getBillPdfReceiptUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}/pdf-receipt`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillPdfReceiptUrl200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Approved bill PDF receipt URL
     */
    async getBillPdfReceiptUrl(requestParameters: GetBillPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillPdfReceiptUrl200Response> {
        const response = await this.getBillPdfReceiptUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment link
     */
    async getPaymentLinkRaw(requestParameters: GetPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentLink200Response>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment-records/{payment_id}/link`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Get payment link
     */
    async getPaymentLink(requestParameters: GetPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentLink200Response> {
        const response = await this.getPaymentLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment record PDF receipt URL
     */
    async getPaymentRecordPdfReceiptUrlRaw(requestParameters: GetPaymentRecordPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillPdfReceiptUrl200Response>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getPaymentRecordPdfReceiptUrl.');
        }

        if (requestParameters.isBillPayment === null || requestParameters.isBillPayment === undefined) {
            throw new runtime.RequiredError('isBillPayment','Required parameter requestParameters.isBillPayment was null or undefined when calling getPaymentRecordPdfReceiptUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.isBillPayment !== undefined) {
            queryParameters['is_bill_payment'] = requestParameters.isBillPayment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment-records/{payment_id}/pdf-receipt`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillPdfReceiptUrl200ResponseFromJSON(jsonValue));
    }

    /**
     * Get payment record PDF receipt URL
     */
    async getPaymentRecordPdfReceiptUrl(requestParameters: GetPaymentRecordPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillPdfReceiptUrl200Response> {
        const response = await this.getPaymentRecordPdfReceiptUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a refund PDF receipt URL
     */
    async getRefundPdfReceiptUrlRaw(requestParameters: GetRefundPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillPdfReceiptUrl200Response>> {
        if (requestParameters.refundId === null || requestParameters.refundId === undefined) {
            throw new runtime.RequiredError('refundId','Required parameter requestParameters.refundId was null or undefined when calling getRefundPdfReceiptUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/refunds/{refund_id}/pdf-receipt`.replace(`{${"refund_id"}}`, encodeURIComponent(String(requestParameters.refundId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillPdfReceiptUrl200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a refund PDF receipt URL
     */
    async getRefundPdfReceiptUrl(requestParameters: GetRefundPdfReceiptUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillPdfReceiptUrl200Response> {
        const response = await this.getRefundPdfReceiptUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a statement PDF URL
     */
    async getStatementPdfUrlRaw(requestParameters: GetStatementPdfUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillPdfReceiptUrl200Response>> {
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId','Required parameter requestParameters.statementId was null or undefined when calling getStatementPdfUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statements/{statement_id}/pdf`.replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillPdfReceiptUrl200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a statement PDF URL
     */
    async getStatementPdfUrl(requestParameters: GetStatementPdfUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillPdfReceiptUrl200Response> {
        const response = await this.getStatementPdfUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process a deposit payment
     */
    async processDepositPaymentRaw(requestParameters: ProcessDepositPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessDepositPayment200Response>> {
        if (requestParameters.depositId === null || requestParameters.depositId === undefined) {
            throw new runtime.RequiredError('depositId','Required parameter requestParameters.depositId was null or undefined when calling processDepositPayment.');
        }

        if (requestParameters.processDepositPaymentRequest === null || requestParameters.processDepositPaymentRequest === undefined) {
            throw new runtime.RequiredError('processDepositPaymentRequest','Required parameter requestParameters.processDepositPaymentRequest was null or undefined when calling processDepositPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deposits/{deposit_id}/process-payment`.replace(`{${"deposit_id"}}`, encodeURIComponent(String(requestParameters.depositId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessDepositPaymentRequestToJSON(requestParameters.processDepositPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessDepositPayment200ResponseFromJSON(jsonValue));
    }

    /**
     * Process a deposit payment
     */
    async processDepositPayment(requestParameters: ProcessDepositPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessDepositPayment200Response> {
        const response = await this.processDepositPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process payment for a bill
     */
    async processPaymentRaw(requestParameters: ProcessPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessPayment200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling processPayment.');
        }

        if (requestParameters.processPaymentRequest === null || requestParameters.processPaymentRequest === undefined) {
            throw new runtime.RequiredError('processPaymentRequest','Required parameter requestParameters.processPaymentRequest was null or undefined when calling processPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/process-payment`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessPaymentRequestToJSON(requestParameters.processPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessPayment200ResponseFromJSON(jsonValue));
    }

    /**
     * Process payment for a bill
     */
    async processPayment(requestParameters: ProcessPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessPayment200Response> {
        const response = await this.processPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a settlement payment
     */
    async recordSettlementPaymentRaw(requestParameters: RecordSettlementPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordSettlementPayment201Response>> {
        if (requestParameters.settlementPayment === null || requestParameters.settlementPayment === undefined) {
            throw new runtime.RequiredError('settlementPayment','Required parameter requestParameters.settlementPayment was null or undefined when calling recordSettlementPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settlement-payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettlementPaymentToJSON(requestParameters.settlementPayment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSettlementPayment201ResponseFromJSON(jsonValue));
    }

    /**
     * Record a settlement payment
     */
    async recordSettlementPayment(requestParameters: RecordSettlementPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordSettlementPayment201Response> {
        const response = await this.recordSettlementPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refund a payment record
     */
    async refundPaymentRaw(requestParameters: RefundPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefundPayment200Response>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling refundPayment.');
        }

        if (requestParameters.refundPaymentRecordRequest === null || requestParameters.refundPaymentRecordRequest === undefined) {
            throw new runtime.RequiredError('refundPaymentRecordRequest','Required parameter requestParameters.refundPaymentRecordRequest was null or undefined when calling refundPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/payment-records/{payment_id}/refund`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RefundPaymentRecordRequestToJSON(requestParameters.refundPaymentRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefundPayment200ResponseFromJSON(jsonValue));
    }

    /**
     * Refund a payment record
     */
    async refundPayment(requestParameters: RefundPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefundPayment200Response> {
        const response = await this.refundPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Regenerate a bill
     */
    async regenerateBillRaw(requestParameters: RegenerateBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling regenerateBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}/regenerate`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Regenerate a bill
     */
    async regenerateBill(requestParameters: RegenerateBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.regenerateBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset approval status of a bill
     */
    async resetBillApprovalRaw(requestParameters: ResetBillApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneBill200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling resetBillApproval.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bills/{bill_id}/reset-approval`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneBill200ResponseFromJSON(jsonValue));
    }

    /**
     * Reset approval status of a bill
     */
    async resetBillApproval(requestParameters: ResetBillApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneBill200Response> {
        const response = await this.resetBillApprovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send bill email to resident
     */
    async sendBillEmailRaw(requestParameters: SendBillEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendBillEmail200Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling sendBillEmail.');
        }

        if (requestParameters.sendBillEmailRequest === null || requestParameters.sendBillEmailRequest === undefined) {
            throw new runtime.RequiredError('sendBillEmailRequest','Required parameter requestParameters.sendBillEmailRequest was null or undefined when calling sendBillEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/email`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendBillEmailRequestToJSON(requestParameters.sendBillEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendBillEmail200ResponseFromJSON(jsonValue));
    }

    /**
     * Send bill email to resident
     */
    async sendBillEmail(requestParameters: SendBillEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendBillEmail200Response> {
        const response = await this.sendBillEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually update a bill item on a bill
     */
    async updateBillItemRaw(requestParameters: UpdateBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBillItem201Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling updateBillItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling updateBillItem.');
        }

        if (requestParameters.manualUpdateBillItemRequest === null || requestParameters.manualUpdateBillItemRequest === undefined) {
            throw new runtime.RequiredError('manualUpdateBillItemRequest','Required parameter requestParameters.manualUpdateBillItemRequest was null or undefined when calling updateBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bills/{bill_id}/items/{item_id}`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManualUpdateBillItemRequestToJSON(requestParameters.manualUpdateBillItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBillItem201ResponseFromJSON(jsonValue));
    }

    /**
     * Manually update a bill item on a bill
     */
    async updateBillItem(requestParameters: UpdateBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBillItem201Response> {
        const response = await this.updateBillItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually update a one time bill item on a bill
     */
    async updateOneTimeBillItemRaw(requestParameters: UpdateOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOneTimeBillItem201Response>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling updateOneTimeBillItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling updateOneTimeBillItem.');
        }

        if (requestParameters.oneTimeBillItem === null || requestParameters.oneTimeBillItem === undefined) {
            throw new runtime.RequiredError('oneTimeBillItem','Required parameter requestParameters.oneTimeBillItem was null or undefined when calling updateOneTimeBillItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/one-time-bills/{bill_id}/items/{item_id}`.replace(`{${"bill_id"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeBillItemToJSON(requestParameters.oneTimeBillItem),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOneTimeBillItem201ResponseFromJSON(jsonValue));
    }

    /**
     * Manually update a one time bill item on a bill
     */
    async updateOneTimeBillItem(requestParameters: UpdateOneTimeBillItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOneTimeBillItem201Response> {
        const response = await this.updateOneTimeBillItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a payment record
     */
    async updatePaymentRecordRaw(requestParameters: UpdatePaymentRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePaymentRecord201Response>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling updatePaymentRecord.');
        }

        if (requestParameters.updatePaymentRequest === null || requestParameters.updatePaymentRequest === undefined) {
            throw new runtime.RequiredError('updatePaymentRequest','Required parameter requestParameters.updatePaymentRequest was null or undefined when calling updatePaymentRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/payment-records/{payment_id}`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentRequestToJSON(requestParameters.updatePaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePaymentRecord201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a payment record
     */
    async updatePaymentRecord(requestParameters: UpdatePaymentRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePaymentRecord201Response> {
        const response = await this.updatePaymentRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
