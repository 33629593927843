/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface TextTemplateListEntry
 */
export interface TextTemplateListEntry {
    /**
     * 
     * @type {string}
     * @memberof TextTemplateListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplateListEntry
     */
    facility_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof TextTemplateListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplateListEntry
     */
    type_id: string;
    /**
     * 
     * @type {UserName}
     * @memberof TextTemplateListEntry
     */
    created_by: UserName;
    /**
     * 
     * @type {string}
     * @memberof TextTemplateListEntry
     */
    date_created: string;
}

/**
 * Check if a given object implements the TextTemplateListEntry interface.
 */
export function instanceOfTextTemplateListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type_id" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "date_created" in value;

    return isInstance;
}

export function TextTemplateListEntryFromJSON(json: any): TextTemplateListEntry {
    return TextTemplateListEntryFromJSONTyped(json, false);
}

export function TextTemplateListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextTemplateListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'title': json['title'],
        'type_id': json['type_id'],
        'created_by': UserNameFromJSON(json['created_by']),
        'date_created': json['date_created'],
    };
}

export function TextTemplateListEntryToJSON(value?: TextTemplateListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'title': value.title,
        'type_id': value.type_id,
        'created_by': UserNameToJSON(value.created_by),
        'date_created': value.date_created,
    };
}

