/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScreenerSpecialCriteria } from './ScreenerSpecialCriteria';
import {
    ScreenerSpecialCriteriaFromJSON,
    ScreenerSpecialCriteriaFromJSONTyped,
    ScreenerSpecialCriteriaToJSON,
} from './ScreenerSpecialCriteria';

/**
 * 
 * @export
 * @interface UpdateSpecialCriteriaById200Response
 */
export interface UpdateSpecialCriteriaById200Response {
    /**
     * 
     * @type {ScreenerSpecialCriteria}
     * @memberof UpdateSpecialCriteriaById200Response
     */
    data?: ScreenerSpecialCriteria;
}

/**
 * Check if a given object implements the UpdateSpecialCriteriaById200Response interface.
 */
export function instanceOfUpdateSpecialCriteriaById200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateSpecialCriteriaById200ResponseFromJSON(json: any): UpdateSpecialCriteriaById200Response {
    return UpdateSpecialCriteriaById200ResponseFromJSONTyped(json, false);
}

export function UpdateSpecialCriteriaById200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSpecialCriteriaById200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ScreenerSpecialCriteriaFromJSON(json['data']),
    };
}

export function UpdateSpecialCriteriaById200ResponseToJSON(value?: UpdateSpecialCriteriaById200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ScreenerSpecialCriteriaToJSON(value.data),
    };
}

