import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom
} from '@/hooks/useFacilitiesQuery';

export const CrmRequireSelectedFacility: React.FC<PropsWithChildren> = ({
  children
}) => {
  const { data: currentUser } = useCurrentUser();
  const location = useLocation();
  const [selectedFacilityId, setSelectedFacilityId] = useAtom(
    selectedFacilityIdAtom
  );

  useEffect(() => {
    if (
      location.pathname.startsWith('/crm') &&
      selectedFacilityId === ALL_FACILITIES
    ) {
      setSelectedFacilityId(currentUser?.facility_id!);
    }
  }, [location.pathname, selectedFacilityId, currentUser?.facility_id]);

  if (
    location.pathname.startsWith('/crm') &&
    selectedFacilityId === ALL_FACILITIES
  ) {
    return null;
  }

  return <>{children}</>;
};
