/* tslint:disable */
/* eslint-disable */
/**
 * notifications
 * notifications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationChannelListEntry } from './NotificationChannelListEntry';
import {
    NotificationChannelListEntryFromJSON,
    NotificationChannelListEntryFromJSONTyped,
    NotificationChannelListEntryToJSON,
} from './NotificationChannelListEntry';
import type { NotificationPreferenceSettings } from './NotificationPreferenceSettings';
import {
    NotificationPreferenceSettingsFromJSON,
    NotificationPreferenceSettingsFromJSONTyped,
    NotificationPreferenceSettingsToJSON,
} from './NotificationPreferenceSettings';

/**
 * 
 * @export
 * @interface NotificationPreferenceListEntry
 */
export interface NotificationPreferenceListEntry {
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceListEntry
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPreferenceListEntry
     */
    mandatory_message?: string;
    /**
     * 
     * @type {Array<NotificationChannelListEntry>}
     * @memberof NotificationPreferenceListEntry
     */
    channels?: Array<NotificationChannelListEntry>;
    /**
     * 
     * @type {NotificationPreferenceSettings}
     * @memberof NotificationPreferenceListEntry
     */
    preference_settings?: NotificationPreferenceSettings;
}

/**
 * Check if a given object implements the NotificationPreferenceListEntry interface.
 */
export function instanceOfNotificationPreferenceListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function NotificationPreferenceListEntryFromJSON(json: any): NotificationPreferenceListEntry {
    return NotificationPreferenceListEntryFromJSONTyped(json, false);
}

export function NotificationPreferenceListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPreferenceListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'mandatory_message': !exists(json, 'mandatory_message') ? undefined : json['mandatory_message'],
        'channels': !exists(json, 'channels') ? undefined : ((json['channels'] as Array<any>).map(NotificationChannelListEntryFromJSON)),
        'preference_settings': !exists(json, 'preference_settings') ? undefined : NotificationPreferenceSettingsFromJSON(json['preference_settings']),
    };
}

export function NotificationPreferenceListEntryToJSON(value?: NotificationPreferenceListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'mandatory_message': value.mandatory_message,
        'channels': value.channels === undefined ? undefined : ((value.channels as Array<any>).map(NotificationChannelListEntryToJSON)),
        'preference_settings': NotificationPreferenceSettingsToJSON(value.preference_settings),
    };
}

