/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentStatus } from './PaymentStatus';
import {
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';

/**
 * 
 * @export
 * @interface UpdatePaymentRequest
 */
export interface UpdatePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentRequest
     */
    failure_reason?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof UpdatePaymentRequest
     */
    status: PaymentStatus;
}

/**
 * Check if a given object implements the UpdatePaymentRequest interface.
 */
export function instanceOfUpdatePaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UpdatePaymentRequestFromJSON(json: any): UpdatePaymentRequest {
    return UpdatePaymentRequestFromJSONTyped(json, false);
}

export function UpdatePaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failure_reason': !exists(json, 'failure_reason') ? undefined : json['failure_reason'],
        'status': PaymentStatusFromJSON(json['status']),
    };
}

export function UpdatePaymentRequestToJSON(value?: UpdatePaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failure_reason': value.failure_reason,
        'status': PaymentStatusToJSON(value.status),
    };
}

