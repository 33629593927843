import fetchExaCare from '@/adapters/fetchExaCare';

export interface PostAccessUrlPayload {
  url: string;
  key: string;
}

export default async function postAccessUrl(keys: string[]) {
  return fetchExaCare.post<PostAccessUrlPayload[]>('/access-url', {
    keys
  });
}
