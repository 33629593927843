import { RouteObject } from 'react-router-dom';

import { UserModel } from '@/models/UserModel';

import { getAddStaffRoutes } from './getAddStaffRoutes';
import { getAdminHubAssessmentRoutes } from './getAdminHubAssessmentRoutes';
import { getAdminHubGeneralRoutes } from './getAdminHubGeneralRoutes';
import { getAdminHubPricesProductRoutes } from './getAdminHubPricesProductRoutes';
import { getAdminHubProviderRoutes } from './getAdminHubProviderRoutes';
import { getAdminHubStaffOverviewRoutes } from './getAdminHubStaffOverviewRoutes';
import { getAdminHubStatementBillingCenterRoutes } from './getAdminHubStatementBillingCenterRoutes';
import { getDataCenterRoutes } from './getDataCenterRoutes';
import { getStaffRoutes } from './getStaffRoutes';

export const getSharedRoutes = (currentUser: UserModel): RouteObject[] => {
  return [
    ...getAddStaffRoutes(),
    ...getAdminHubAssessmentRoutes(currentUser),
    ...getAdminHubGeneralRoutes(),
    ...getAdminHubPricesProductRoutes(currentUser),
    ...getAdminHubProviderRoutes(),
    ...getAdminHubStatementBillingCenterRoutes(currentUser),
    ...getAdminHubStaffOverviewRoutes(),
    ...getDataCenterRoutes(currentUser),
    ...getStaffRoutes()
  ];
};

// TODO: Create a generic catch-all route for shared/ehr/crm.
