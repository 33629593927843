import React from 'react';
import { BuildCircle, Comment } from '@mui/icons-material';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import chunk from 'lodash/chunk';

import { UserClockPayload } from '@/hooks/useTimeClock';

export class ShiftBlockSummaryPayload {
  name: string;
  shift_block_id: string;
  shift_start: string;
  shift_end: string;
  time_clocked_in: string;
  time_clocked_out: string;
  total_shift_time: string;
  comment: string | null;
  user_id: string;
  createdByUserId: string | null;
  manually_created_shift_time: string | null;
  overriden_shift_time: string | null;
  total_shift_time_without_breaks: string | null;
  user_clocks: UserClockPayload[];
  id: string;

  constructor(payload: ShiftBlockSummaryPayload) {
    Object.assign(this, payload);
    this.id = this.shift_block_id;
  }
}

interface UserClockDetail {
  clockInDate: string | null;
  clockInTime: string | null;
  clockOutDate: string | null;
  clockOutTime: string | null;
}

export class ShiftBlockSummaryModel extends ShiftBlockSummaryPayload {
  public userClockDetails: UserClockDetail[];

  constructor(payload: ShiftBlockSummaryPayload) {
    super(payload);
    this.userClockDetails = this.formatUserClockDetails(this.user_clocks);
  }

  public get canEdit() {
    // A summary that has an odd number of user_clocks is considered to be
    // clocked in and has yet to clock out. Prevent the user from editing this
    // entry for now.
    return this.user_clocks.length % 2 === 0 || this.hasManualOverride();
  }

  public renderDate = (
    field: keyof Pick<ShiftBlockSummaryPayload, 'shift_start' | 'shift_end'>
  ) => {
    const date = dayjs(this[field]);
    if (!date.isValid()) {
      return this[field];
    }
    const formattedDate = date.format('M/D/YYYY');
    const formattedTime = date.format('h:mm A');
    return (
      <>
        {formattedDate}
        <br />
        {formattedTime}
      </>
    );
  };

  public hasManualOverride = () =>
    this.manually_created_shift_time !== '—' ||
    this.overriden_shift_time !== '—';

  // Used to determine the sort order of the column since they show icons
  public getAdditionalInformationValue = () => {
    let sumScore = 0;
    if (this.hasManualOverride() || this.createdByUserId !== null) {
      sumScore += 1;
    }
    if (this.comment) {
      sumScore += 1;
    }
    return sumScore;
  };

  public renderAdditionalInformation = () => (
    <Stack direction="row" gap={1} width="100%">
      {this.comment && (
        <Tooltip arrow title={this.comment as string}>
          <Comment htmlColor="#364955" />
        </Tooltip>
      )}
      {(this.hasManualOverride() || this.createdByUserId !== null) && (
        <Tooltip
          arrow
          title={this.createdByUserId ? 'Manual entry' : 'Has manual override'}>
          <BuildCircle htmlColor="#364955" />
        </Tooltip>
      )}
    </Stack>
  );

  public renderComment = () => {
    if (!this.comment) {
      return null;
    }

    return (
      <Tooltip arrow title={this.comment as string}>
        <Comment />
      </Tooltip>
    );
  };

  public getActionsCellClassName = () =>
    `data-grid-action-cell-shift-block-id-${this.shift_block_id}`;

  public getHoursAndMinutes = (
    field: keyof Pick<
      this,
      'time_clocked_in' | 'time_clocked_out' | 'total_shift_time'
    >
  ): [string, string] => {
    return (this[field]?.split(':') as [string, string]) ?? ['0', '0'];
  };

  // If a shift block summary has an overridden shift end, then we need to
  // calculate the shift end based on the shift start and the total shift time.
  public getFormattedShiftEnd = (): string => {
    const shiftEnd = dayjs(this.shift_end);
    if (shiftEnd.isValid()) {
      return shiftEnd.format('h:mma');
    }
    // It is "—" if overridden
    const shiftStart = dayjs(this.shift_start);
    const [hours, minutes] = this.getHoursAndMinutes('total_shift_time');
    return shiftStart
      .add(Number(hours) || 0, 'hours')
      .add(Number(minutes) || 0, 'minutes')
      .format('h:mma');
  };

  private formatUserClockDetails = (
    userClocks: UserClockPayload[]
  ): UserClockDetail[] => {
    const chunkedBreaks = chunk(
      userClocks.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
      2
    );
    return chunkedBreaks.map(([clockIn, clockOut]) => {
      const timestampIn = dayjs(clockIn.timestamp);
      const timestampOut = clockOut ? dayjs(clockOut.timestamp) : null;
      return {
        clockInDate: timestampIn.format('M/D/YYYY'),
        clockInTime: timestampIn.format('h:mm A'),
        clockOutDate: timestampOut?.format('M/D/YYYY') ?? 'Pending',
        clockOutTime: timestampOut?.format('h:mm A') ?? null
      };
    });
  };

  public static getDetailPanelContent: DataGridProProps<ShiftBlockSummaryModel>['getDetailPanelContent'] =
    ({ row }) => {
      if (row.userClockDetails.length === 0) {
        return null;
      }

      return (
        <div
          style={{
            margin: '24px',
            borderRadius: '16px',
            overflow: 'hidden',
            border: '1px solid rgba(224, 224, 224, 1)'
          }}>
          <TableContainer>
            <Table sx={{ border: 'none' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Clock-in</TableCell>
                  <TableCell>Clock-out</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.userClockDetails.map((userClock, index) => (
                  <TableRow key={index}>
                    <TableCell width={200}>
                      {userClock.clockInDate}
                      <br />
                      {userClock.clockInTime}
                    </TableCell>
                    <TableCell width={2000}>
                      {userClock.clockOutDate}
                      <br />
                      {userClock.clockOutTime}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    };
}
