/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignTaskStats
 */
export interface CampaignTaskStats {
    /**
     * 
     * @type {number}
     * @memberof CampaignTaskStats
     */
    created_today_count: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignTaskStats
     */
    created_count: number;
}

/**
 * Check if a given object implements the CampaignTaskStats interface.
 */
export function instanceOfCampaignTaskStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created_today_count" in value;
    isInstance = isInstance && "created_count" in value;

    return isInstance;
}

export function CampaignTaskStatsFromJSON(json: any): CampaignTaskStats {
    return CampaignTaskStatsFromJSONTyped(json, false);
}

export function CampaignTaskStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignTaskStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_today_count': json['created_today_count'],
        'created_count': json['created_count'],
    };
}

export function CampaignTaskStatsToJSON(value?: CampaignTaskStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_today_count': value.created_today_count,
        'created_count': value.created_count,
    };
}

