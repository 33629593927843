import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface CachedPinAtom {
  expiresAt: string;
  encodedPin: string;
}

const cachedPinAtom = atomWithStorage<CachedPinAtom | null>('cachedPin', null);

export const useCachedPin = () => {
  const [cachedPin, setCachedPin] = useAtom(cachedPinAtom);

  const encodePin = (pin: string) => {
    return window.btoa(pin);
  };

  const decodePin = (encodedPin: string) => {
    try {
      return window.atob(encodedPin);
    } catch (e) {
      clearCachedPin();
      return null;
    }
  };

  const isExpired = (expiryDate: string) => {
    return dayjs().isAfter(dayjs(expiryDate));
  };

  const cachePin = (newPin: string) => {
    let expiresAt = dayjs().add(8, 'hours').toISOString();
    // Don't overwrite the expiry date if the pin is already cached
    if (cachedPin && !isExpired(cachedPin.expiresAt)) {
      expiresAt = cachedPin.expiresAt;
    }

    setCachedPin({
      expiresAt,
      encodedPin: encodePin(newPin)
    });
  };

  const clearCachedPin = () => {
    setCachedPin(null);
  };

  let decodedPin = null;
  if (cachedPin) {
    if (isExpired(cachedPin.expiresAt)) {
      clearCachedPin();
    } else {
      decodedPin = decodePin(cachedPin.encodedPin);
    }
  }

  return {
    pin: decodedPin,
    cachePin,
    clearCachedPin
  };
};
