/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DuplicateResidents
 */
export interface DuplicateResidents {
    /**
     * 
     * @type {number}
     * @memberof DuplicateResidents
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidents
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidents
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidents
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateResidents
     */
    phone?: string;
}

/**
 * Check if a given object implements the DuplicateResidents interface.
 */
export function instanceOfDuplicateResidents(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;

    return isInstance;
}

export function DuplicateResidentsFromJSON(json: any): DuplicateResidents {
    return DuplicateResidentsFromJSONTyped(json, false);
}

export function DuplicateResidentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateResidents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function DuplicateResidentsToJSON(value?: DuplicateResidents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'phone': value.phone,
    };
}

