/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { SubscriptionType } from './SubscriptionType';
import {
    SubscriptionTypeFromJSON,
    SubscriptionTypeFromJSONTyped,
    SubscriptionTypeToJSON,
} from './SubscriptionType';
import type { Tax } from './Tax';
import {
    TaxFromJSON,
    TaxFromJSONTyped,
    TaxToJSON,
} from './Tax';
import type { Totals } from './Totals';
import {
    TotalsFromJSON,
    TotalsFromJSONTyped,
    TotalsToJSON,
} from './Totals';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    product_name: string;
    /**
     * 
     * @type {SubscriptionType}
     * @memberof Subscription
     */
    subscription_type: SubscriptionType;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    product_description?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    service_start_date: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    service_end_date: string;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Subscription
     */
    discounts: Array<Discount>;
    /**
     * 
     * @type {Array<Tax>}
     * @memberof Subscription
     */
    taxes: Array<Tax>;
    /**
     * 
     * @type {Totals}
     * @memberof Subscription
     */
    totals: Totals;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "product_name" in value;
    isInstance = isInstance && "subscription_type" in value;
    isInstance = isInstance && "service_start_date" in value;
    isInstance = isInstance && "service_end_date" in value;
    isInstance = isInstance && "discounts" in value;
    isInstance = isInstance && "taxes" in value;
    isInstance = isInstance && "totals" in value;

    return isInstance;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product_id': json['product_id'],
        'product_name': json['product_name'],
        'subscription_type': SubscriptionTypeFromJSON(json['subscription_type']),
        'product_description': !exists(json, 'product_description') ? undefined : json['product_description'],
        'service_start_date': json['service_start_date'],
        'service_end_date': json['service_end_date'],
        'discounts': ((json['discounts'] as Array<any>).map(DiscountFromJSON)),
        'taxes': ((json['taxes'] as Array<any>).map(TaxFromJSON)),
        'totals': TotalsFromJSON(json['totals']),
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_id': value.product_id,
        'product_name': value.product_name,
        'subscription_type': SubscriptionTypeToJSON(value.subscription_type),
        'product_description': value.product_description,
        'service_start_date': value.service_start_date,
        'service_end_date': value.service_end_date,
        'discounts': ((value.discounts as Array<any>).map(DiscountToJSON)),
        'taxes': ((value.taxes as Array<any>).map(TaxToJSON)),
        'totals': TotalsToJSON(value.totals),
    };
}

