import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { Role } from '@/hooks/useUserTypesQuery';
import { UserModel } from '@/models/UserModel';

import { RoutePath } from './RoutePath';

interface RawProtectedRouteProps {
  Component: LoadableComponent<any> | (() => JSX.Element);
  user: UserModel;
  role: Role;
}

// This is much easier to unit test instead of ProtectedRoute route because mocking
// inner modules is a nightmare. Instead, the hook values are passed in which is
// a breeze to mock
export const RawProtectedRoute: React.FC<RawProtectedRouteProps> = ({
  Component,
  role,
  user
}) => {
  if (role && !user.hasMinimumUserRole(role)) {
    return <Navigate to={RoutePath.Profile} replace />;
  }

  return <Component />;
};

interface ProtectedRouteProps {
  component: LoadableComponent<any> | (() => JSX.Element);
  role: Role;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...props
}) => {
  const user = useCurrentUser().data!;
  return <RawProtectedRoute {...props} user={user} Component={component} />;
};
