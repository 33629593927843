/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentFormListEntry } from './IncidentFormListEntry';
import {
    IncidentFormListEntryFromJSON,
    IncidentFormListEntryFromJSONTyped,
    IncidentFormListEntryToJSON,
} from './IncidentFormListEntry';

/**
 * 
 * @export
 * @interface FindAllIncidentForms200Response
 */
export interface FindAllIncidentForms200Response {
    /**
     * 
     * @type {Array<IncidentFormListEntry>}
     * @memberof FindAllIncidentForms200Response
     */
    data?: Array<IncidentFormListEntry>;
}

/**
 * Check if a given object implements the FindAllIncidentForms200Response interface.
 */
export function instanceOfFindAllIncidentForms200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllIncidentForms200ResponseFromJSON(json: any): FindAllIncidentForms200Response {
    return FindAllIncidentForms200ResponseFromJSONTyped(json, false);
}

export function FindAllIncidentForms200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllIncidentForms200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(IncidentFormListEntryFromJSON)),
    };
}

export function FindAllIncidentForms200ResponseToJSON(value?: FindAllIncidentForms200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(IncidentFormListEntryToJSON)),
    };
}

