import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { carePlanClient } from '@/adapters/schemaClients';
import {
  CountInformationalCPE200Response,
  CountInformationalCPERequest,
  FindAllInformationalCPE200Response,
  FindAllInformationalCPERequest
} from '@/types/care-plan';

const RESIDENT_INFORMATIONAL_QUERY = 'useResidentInformationalQuery';

export function useResidentInformationalQuery() {
  const invalidate = () =>
    queryClient.invalidateQueries([RESIDENT_INFORMATIONAL_QUERY]);

  return {
    invalidate,
    count: (
      params: CountInformationalCPERequest,
      options: UseQueryOptions<
        CountInformationalCPE200Response['data'],
        HTTPError
      > = {}
    ) => {
      return useQuery<CountInformationalCPE200Response['data'], HTTPError>(
        [RESIDENT_INFORMATIONAL_QUERY, { ...params, type: 'count' }],
        async () => {
          const response = await carePlanClient.countInformationalCPE(params);
          return response.data;
        },
        {
          ...options
        }
      );
    },
    findAll: (
      params: FindAllInformationalCPERequest,
      options: UseQueryOptions<
        FindAllInformationalCPE200Response['data'],
        HTTPError
      > = {}
    ) => {
      return useQuery<FindAllInformationalCPE200Response['data'], HTTPError>(
        [RESIDENT_INFORMATIONAL_QUERY, { ...params, type: 'findAll' }],
        async () => {
          const response = await carePlanClient.findAllInformationalCPE(params);
          return response.data;
        },
        {
          ...options
        }
      );
    }
  };
}
