import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

const StaffOverviewDataGrid = loadable(() => import("@/pages/AdminHub/StaffOverview/StaffOverviewDataGrid")); // prettier-ignore
const StaffOverviewPage = loadable(() => import("@/pages/AdminHub/StaffOverview")); // prettier-ignore
const StaffShiftView = loadable(() => import("@/pages/AdminHub/StaffOverview/StaffShiftView")); // prettier-ignore
const StaffPayableTotals = loadable(() => import("@/pages/AdminHub/StaffOverview/StaffPayableTotals")); // prettier-ignore

export enum AdminHubStaffOverviewRoutes {
  AdminHubStaffOverview = '/:domain/admin-hub/staff-overview',
  AdminHubStaffShiftView = '/:domain/admin-hub/staff-overview/shifts',
  AdminHubStaffPayableTotals = '/:domain/admin-hub/staff-overview/payable-totals'
}

export const getAdminHubStaffOverviewRoutes = (): RouteObject[] => {
  return [
    {
      path: AdminHubStaffOverviewRoutes.AdminHubStaffOverview,
      element: <ProtectedRoute component={StaffOverviewPage} role="L1" />,
      children: [
        {
          path: AdminHubStaffOverviewRoutes.AdminHubStaffOverview,
          element: <StaffOverviewDataGrid />
        },
        {
          path: AdminHubStaffOverviewRoutes.AdminHubStaffShiftView,
          element: <StaffShiftView />
        },
        {
          path: AdminHubStaffOverviewRoutes.AdminHubStaffPayableTotals,
          element: <StaffPayableTotals />
        }
      ]
    }
  ];
};
