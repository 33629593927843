/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvailableRoomListEntry } from './AvailableRoomListEntry';
import {
    AvailableRoomListEntryFromJSON,
    AvailableRoomListEntryFromJSONTyped,
    AvailableRoomListEntryToJSON,
} from './AvailableRoomListEntry';

/**
 * 
 * @export
 * @interface FindAllAvailableRooms200Response
 */
export interface FindAllAvailableRooms200Response {
    /**
     * 
     * @type {Array<AvailableRoomListEntry>}
     * @memberof FindAllAvailableRooms200Response
     */
    data?: Array<AvailableRoomListEntry>;
}

/**
 * Check if a given object implements the FindAllAvailableRooms200Response interface.
 */
export function instanceOfFindAllAvailableRooms200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllAvailableRooms200ResponseFromJSON(json: any): FindAllAvailableRooms200Response {
    return FindAllAvailableRooms200ResponseFromJSONTyped(json, false);
}

export function FindAllAvailableRooms200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllAvailableRooms200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AvailableRoomListEntryFromJSON)),
    };
}

export function FindAllAvailableRooms200ResponseToJSON(value?: FindAllAvailableRooms200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AvailableRoomListEntryToJSON)),
    };
}

