import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import postAccessUrl from '@/api/postAccessUrl';
import { postS3Files, UploadProgressState } from '@/api/postS3Files';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

export const usePrescriptionFiles = (
  formMethods: UseFormReturn<ResidentMedicationForm>
) => {
  const [progress, setProgress] = useState({} as UploadProgressState);
  const { showSnackbar } = useSnackbar();
  const s3Prescription = formMethods.watch('s3_prescription');

  const handleOnFileUpload = async (acceptedFiles: File[]) => {
    try {
      const files = await postS3Files(acceptedFiles, setProgress);
      formMethods.setValue('s3_prescription', files[0]);
      showSnackbar({
        message: `Successfully uploaded "${files[0].name}" prescription image`,
        severity: 'success'
      });
    } catch (e) {
      showSnackbar({
        message: `Failed to upload "${acceptedFiles[0].name}" prescription image`,
        severity: 'error'
      });
    }
  };

  const handleOnFileRemove = () => {
    formMethods.setValue('s3_prescription', null);
  };

  const handleDownloadPrescription = async () => {
    if (s3Prescription) {
      try {
        await postAccessUrl([s3Prescription.key]).then(([{ url }]) => {
          window.open(url, '_blank');
        });
      } catch (e) {
        showSnackbar({
          message: 'Failed to download prescription. Please try again',
          severity: 'error'
        });
      }
    }
  };

  return {
    progress,
    s3Prescription,
    handleOnFileUpload,
    handleOnFileRemove,
    handleDownloadPrescription
  };
};
