/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyDepositRequest
 */
export interface ApplyDepositRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplyDepositRequest
     */
    deposit_ids: Array<string>;
}

/**
 * Check if a given object implements the ApplyDepositRequest interface.
 */
export function instanceOfApplyDepositRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deposit_ids" in value;

    return isInstance;
}

export function ApplyDepositRequestFromJSON(json: any): ApplyDepositRequest {
    return ApplyDepositRequestFromJSONTyped(json, false);
}

export function ApplyDepositRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyDepositRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deposit_ids': json['deposit_ids'],
    };
}

export function ApplyDepositRequestToJSON(value?: ApplyDepositRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deposit_ids': value.deposit_ids,
    };
}

