/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentStatus = {
    Initiated: 'Initiated',
    Success: 'Success',
    Failed: 'Failed',
    Cancelled: 'Cancelled',
    Refunded: 'Refunded',
    PartiallyRefunded: 'Partially Refunded'
} as const;
export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


export function PaymentStatusFromJSON(json: any): PaymentStatus {
    return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatus {
    return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
    return value as any;
}

