import { GridApiPro } from '@mui/x-data-grid-pro';

/**
 * A convenience function to handle filter changes for the isAnyOf operator that
 * handles 90% of the filter changes in the application.
 * @param gridApiRef The grid api reference
 * @returns Function that handles filter change
 */
export function scopeHandleFilterOnChange(
  gridApiRef: React.MutableRefObject<GridApiPro>
) {
  return (column: string, filter: any) => {
    const filterItem = gridApiRef.current.state.filter.filterModel.items.find(
      (item) => item.field === column
    ) ?? {
      id: column,
      field: column,
      operator: 'isAnyOf',
      value: []
    };

    const valueSet = new Set(filterItem.value);
    valueSet.has(filter) ? valueSet.delete(filter) : valueSet.add(filter);

    if (valueSet.size) {
      filterItem.value = Array.from(valueSet);
      gridApiRef.current.upsertFilterItem(filterItem);
    } else {
      gridApiRef.current.deleteFilterItem(filterItem);
    }
  };
}
/**
 * A convenience function to check whether or not a filter value exists within
 * a filter item's value array.
 * @param gridApiRef The grid api reference
 * @returns boolean
 */
export function scopeIsFilterChecked(
  gridApiRef: React.MutableRefObject<GridApiPro>
) {
  return (column: string, filter: any) => {
    return (
      gridApiRef.current.state.filter.filterModel.items
        .find((item) => item.field === column)
        ?.value?.some((value: any) => value === filter) ?? false
    );
  };
}

/**
 * A convienence function to count the number of active selections within a filter dropdown
 * @param gridApiRef The grid api reference
 * @param column The data model column to count active selections
 * @returns
 */
export function countCheckedFilters(
  gridApiRef: React.MutableRefObject<GridApiPro>,
  column: string
): number {
  const filters = gridApiRef.current.state.filter.filterModel.items
    .filter((item) => item.field === column)
    .flatMap((item) => (Array.isArray(item.value) ? item.value : [item.value]));
  return new Set(filters).size;
}
