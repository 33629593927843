import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import fetchExaCare, { fetchResidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { PatternedRecurrenceEhr } from '@/models/PatternedRecurrenceEhr';
import {
  TaskExtensionModel,
  TaskExtensionPayload
} from '@/models/TaskExtensionModel';
import { AddResidentCarePlan } from '@/stores/residentCarePlanAtom';
import { transformScheduleFormToJsonBody } from '@/utils/transformScheduleFormToJsonBody';

import { invalidateTaskInstancesQuery } from './useTaskInstancesQuery';

export interface ScheduleAdditionalInformation {
  followUpTaskDefinition: {
    instructions: string;
    timeIntervalInMinutes: number;
  };
}

export interface SchedulePayload {
  id: string;
  execution_window_start_time: string | null;
  execution_window_end_time: string | null;
  rrule: string | null;
  patterned_recurrence: PatternedRecurrenceEhr | null;
  schedule_is_active_start_date: string | null;
  schedule_is_active_end_date: string | null;
  instance_generation_start_date: string;
  instance_generation_end_date: string | null;
  has_all_been_generated: boolean;
  generated_until: string;
  updatedAt: string | null;
  createdAt: string | null;
  number_of_dose_units: number | null;
  additional_information?: ScheduleAdditionalInformation | null;
  created_by_user_id: string;
  start_time: string;
  end_time: string;
  date_archived: string | null;
}

export interface ResidentCarePlanSchedulePayload extends SchedulePayload {
  care_plan_entries_id: string;
}

export class ResidentCarePlanPayload {
  care_category_id: string;
  care_plan_assistance_level_id: string;
  care_plan_schedules: ResidentCarePlanSchedulePayload[];
  created_by_user_id: string;
  createdAt: string;
  id: string;
  instructions: string;
  is_deleted: boolean;
  length_mins: number;
  modified_by: string;
  number_of_assistants: string;
  /** @deprecated Use responsible_party_id instead */
  responsible_party: string | null;
  resident_id: string;
  title: string;
  is_informational: boolean | null;
  is_unscheduled: boolean;
  preferences?: string | null;
  is_generated_by_assessment: boolean | null;
  responsible_party_id: string | null;
  responsible_party_instance: ResponsibleParty | null;
  task_extension: TaskExtensionPayload | null;

  constructor(payload: ResidentCarePlanPayload) {
    Object.assign(this, payload);
  }
}

export interface BulkEditCarePlanPayload {
  id: string;
  responsible_party_id: string | null;
  is_informational: boolean;
  care_plan_schedules: ResidentCarePlanSchedulePayload[];
}

const isFrequencyInformational = (
  form: AddResidentCarePlan,
  responsibleParties: ResponsibleParty[]
) => {
  return (
    (form && form.frequency && form.frequency.freq === 'informational') ||
    !isStaffResponsibilityV2(form.responsible_party, responsibleParties)
  );
};

export function toCarePlanPayload(
  form: AddResidentCarePlan,
  tzid: string,
  responsibleParties: ResponsibleParty[]
) {
  return {
    care_category_id: form.careCategory?.id,
    care_plan_assistance_level_id: form.careLevel?.id,
    id: form.id,
    instructions: form.instructions,
    length_mins: form.duration?.value,
    number_of_assistants: form.numAssistants?.value,
    responsible_party: getDeprecatedResponsiblePartyValue(
      form.responsible_party
    ),
    resident_id: form.payload?.resident_id,
    title: form.title,
    is_informational: isFrequencyInformational(form, responsibleParties),
    preferences: form.preferences,
    care_plan_schedules: transformScheduleFormToJsonBody(
      form,
      tzid,
      responsibleParties
    ),
    responsible_party_id: getResponsiblePartyId(form.responsible_party),
    task_extension:
      form.extensionEnabled &&
      form.frequency?.freq !== 'prn' &&
      form.frequency?.freq !== 'informational'
        ? TaskExtensionModel.toTaskExtensionPayload(form.task_extension!)
        : null
  } as Partial<ResidentCarePlanPayload> as ResidentCarePlanPayload;
}

const getDeprecatedResponsiblePartyValue = (
  responsibleParty: string | null
): string | null => {
  return responsibleParty && isNaN(parseInt(responsibleParty))
    ? responsibleParty
    : null;
};

const getResponsiblePartyId = (
  responsibleParty: string | null
): string | null => {
  return responsibleParty && isNaN(parseInt(responsibleParty))
    ? null
    : responsibleParty;
};

export default function useResidentCarePlanQuery(residentId: string) {
  const QUERY_KEY = ['useResidentCarePlanQuery', residentId];
  const invalidate = () => {
    queryClient.invalidateQueries(QUERY_KEY);
    invalidateTaskInstancesQuery(residentId);
  };

  return {
    invalidate,

    findAll: (
      options: UseQueryOptions<
        ResidentCarePlanPayload[],
        HTTPError,
        ResidentCarePlanPayload[],
        typeof QUERY_KEY
      >
    ) =>
      useQuery(
        QUERY_KEY,
        () =>
          fetchResidents.get<ResidentCarePlanPayload[]>(
            `/residents/${residentId}/care-plans`
          ),
        {
          enabled: !!residentId,
          ...options
        }
      ),

    mutations: {
      post: useMutation(
        (payload: ResidentCarePlanPayload) => {
          return fetchExaCare.post<ResidentCarePlanPayload>(
            '/care-plan-entries',
            {
              ...payload,
              resident_id: residentId
            }
          );
        },
        {
          onSettled: invalidate
        }
      ),
      put: useMutation(
        (payload: ResidentCarePlanPayload) => {
          return fetchExaCare.put<ResidentCarePlanPayload>(
            `/care-plan-entries/${payload.id}`,
            payload,
            {
              timeout: 30000
            }
          );
        },
        {
          onSettled: invalidate
        }
      ),
      editMultiple: useMutation(
        (payload: BulkEditCarePlanPayload[]) => {
          return fetchExaCare.put<BulkEditCarePlanPayload>(
            `/care-plan-entries/bulk`,
            payload,
            {
              timeout: 30000
            }
          );
        }
        // Don't invalidate here because the edits are batched in groups
        // of 5
      ),
      delete: useMutation(
        (payload: ResidentCarePlanPayload) => {
          return fetchExaCare.delete<ResidentCarePlanPayload>(
            `/care-plan-entries/${payload.id}`
          );
        },
        {
          onSettled: invalidate
        }
      ),
      deleteMultiple: useMutation((payload: Array<string>) => {
        return fetchExaCare.delete<ResidentCarePlanPayload>(
          `/care-plan-entries/bulk`,
          payload,
          {
            timeout: 30000
          }
        );
        // Don't invalidate here because the deletes are batched in groups
        // of 10
      })
    }
  };
}
