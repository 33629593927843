/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRoomRequest
 */
export interface UpdateRoomRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomRequest
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomRequest
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomRequest
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomRequest
     */
    gl_code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomRequest
     */
    effective_from?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomRequest
     */
    room_type_id?: string;
}

/**
 * Check if a given object implements the UpdateRoomRequest interface.
 */
export function instanceOfUpdateRoomRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRoomRequestFromJSON(json: any): UpdateRoomRequest {
    return UpdateRoomRequestFromJSONTyped(json, false);
}

export function UpdateRoomRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRoomRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'effective_from': !exists(json, 'effective_from') ? undefined : json['effective_from'],
        'room_type_id': !exists(json, 'room_type_id') ? undefined : json['room_type_id'],
    };
}

export function UpdateRoomRequestToJSON(value?: UpdateRoomRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'price': value.price,
        'description': value.description,
        'capacity': value.capacity,
        'gl_code': value.gl_code,
        'effective_from': value.effective_from,
        'room_type_id': value.room_type_id,
    };
}

