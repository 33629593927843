/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentAnswer
 */
export interface AssessmentAnswer {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswer
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnswer
     */
    care_units: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswer
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswer
     */
    care_plan_entry_template_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnswer
     */
    ng_repository_entry_id?: string;
}

/**
 * Check if a given object implements the AssessmentAnswer interface.
 */
export function instanceOfAssessmentAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "care_units" in value;
    isInstance = isInstance && "note" in value;

    return isInstance;
}

export function AssessmentAnswerFromJSON(json: any): AssessmentAnswer {
    return AssessmentAnswerFromJSONTyped(json, false);
}

export function AssessmentAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'text': json['text'],
        'care_units': json['care_units'],
        'note': json['note'],
        'care_plan_entry_template_id': !exists(json, 'care_plan_entry_template_id') ? undefined : json['care_plan_entry_template_id'],
        'ng_repository_entry_id': !exists(json, 'ng_repository_entry_id') ? undefined : json['ng_repository_entry_id'],
    };
}

export function AssessmentAnswerToJSON(value?: AssessmentAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'care_units': value.care_units,
        'note': value.note,
        'care_plan_entry_template_id': value.care_plan_entry_template_id,
        'ng_repository_entry_id': value.ng_repository_entry_id,
    };
}

