import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { residentsResourcesClient } from '@/adapters/schemaClients';
import {
  FindAllTaskExtensionTemplatesRequest,
  TaskExtensionTemplate
} from '@/types/resident-resources';

const FIND_ALL_TASK_EXTENSION_TEMPLATES_QUERY_KEY =
  'findAllTaskExtensionTemplates';

export const useTaskExtensionTemplatesQuery = () => {
  return {
    findAll: (
      params: FindAllTaskExtensionTemplatesRequest,
      options: UseQueryOptions<TaskExtensionTemplate[], HTTPError> = {}
    ) =>
      useQuery<TaskExtensionTemplate[], HTTPError>(
        [FIND_ALL_TASK_EXTENSION_TEMPLATES_QUERY_KEY, params],
        async () => {
          const response =
            await residentsResourcesClient.findAllTaskExtensionTemplates(
              params
            );
          return response.data!;
        },
        {
          ...options,
          enabled: !!params.facilityId && !!params.extensionType
        }
      )
  };
};
