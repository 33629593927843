/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentsFindAllResponse } from './IncidentsFindAllResponse';
import {
    IncidentsFindAllResponseFromJSON,
    IncidentsFindAllResponseFromJSONTyped,
    IncidentsFindAllResponseToJSON,
} from './IncidentsFindAllResponse';

/**
 * 
 * @export
 * @interface FindAllIncidents200Response
 */
export interface FindAllIncidents200Response {
    /**
     * 
     * @type {IncidentsFindAllResponse}
     * @memberof FindAllIncidents200Response
     */
    data?: IncidentsFindAllResponse;
}

/**
 * Check if a given object implements the FindAllIncidents200Response interface.
 */
export function instanceOfFindAllIncidents200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllIncidents200ResponseFromJSON(json: any): FindAllIncidents200Response {
    return FindAllIncidents200ResponseFromJSONTyped(json, false);
}

export function FindAllIncidents200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllIncidents200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentsFindAllResponseFromJSON(json['data']),
    };
}

export function FindAllIncidents200ResponseToJSON(value?: FindAllIncidents200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentsFindAllResponseToJSON(value.data),
    };
}

