import React from 'react';
import { Chip, Stack, Typography } from '@mui/material';

import CarePlanIndicators from '@/components/CarePlanIndicators/CarePlanIndicators';
import { CarePlanAssistanceLevel } from '@/hooks/useCarePlanAssistanceLevelsQuery';
import { CarePlanTaskInstancePayload } from '@/hooks/useTaskInstancesQuery';
import { RoutePath } from '@/routes/RoutePath';

import { ResidentModel } from './ResidentModel';
import {
  BaseTaskInstanceModel,
  BaseTaskInstanceModelInterface
} from './TaskInstanceModel';

export interface CarePlanTaskInstanceModel extends CarePlanTaskInstancePayload {
} // prettier-ignore
export class CarePlanTaskInstanceModel
  extends BaseTaskInstanceModel
  implements BaseTaskInstanceModelInterface
{
  careLevel: CarePlanAssistanceLevel;
  hasVitalRequirements: false;

  constructor(
    payload: CarePlanTaskInstancePayload,
    careLevels: CarePlanAssistanceLevel[],
    resident: ResidentModel
  ) {
    super(payload);
    Object.assign(this, payload);
    this.careLevel = careLevels.find((v) => v.id === this.assistance_level_id)!;
    this.resident = resident;
  }

  public getRowId = (): string => `carePlanTaskInstanceModel_id=${this.id}`;

  public renderTaskCell = (): React.ReactNode => {
    return (
      <Stack>
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <Typography color="secondary" fontSize="0.9375rem" fontWeight="500">
            {this.getTaskName()}
          </Typography>
          {this.care_plan_entry.is_unscheduled && (
            <Chip size="small" label="Unscheduled" />
          )}
          <CarePlanIndicators model={this} />
        </Stack>
        {this.care_plan_entry.is_unscheduled &&
          this.care_plan_entry.length_mins > 0 && (
            <Typography color="#667A86" fontSize="14px">
              {this.care_plan_entry.length_mins} mins
            </Typography>
          )}
      </Stack>
    );
  };

  public getTaskName = (): string => this.description;

  public getAssistanceLevel = (): string => this.careLevel?.title ?? '';

  public getDialogTitle = (): string => 'Care Plan';

  public getDialogTabName = (): string => 'Care Plan';

  public getInstructions = (): string =>
    this.care_plan_entry.instructions || 'n/a';

  public getDialogRedirectUrl = (): string =>
    RoutePath.ResidentCarePlan.replace(':resident_id', this.resident.id);

  public getTotalDosage = (): string => 'n/a';

  public getCellClassName = (field: string): string =>
    `tasks-data-grid-care-plan-schedule-id-${this.care_plan_schedule_id}-${field}-cell`;

  public canViewDocumentation = this._canViewDocumentation;
  public canMarkComplete = this._canMarkComplete;
  public canEdit = this._canEdit;
  public canCancel = this._canCancel;
  public canUncancel = this._canUncancel;
  public canEditTaskInstances = this._canViewDocumentation;
}
