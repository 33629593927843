/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentLinks } from './PaymentLinks';
import {
    PaymentLinksFromJSON,
    PaymentLinksFromJSONTyped,
    PaymentLinksToJSON,
} from './PaymentLinks';

/**
 * 
 * @export
 * @interface ProcessDepositPaymentResponse
 */
export interface ProcessDepositPaymentResponse {
    /**
     * 
     * @type {PaymentLinks}
     * @memberof ProcessDepositPaymentResponse
     */
    payment_links?: PaymentLinks;
}

/**
 * Check if a given object implements the ProcessDepositPaymentResponse interface.
 */
export function instanceOfProcessDepositPaymentResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcessDepositPaymentResponseFromJSON(json: any): ProcessDepositPaymentResponse {
    return ProcessDepositPaymentResponseFromJSONTyped(json, false);
}

export function ProcessDepositPaymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessDepositPaymentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment_links': !exists(json, 'payment_links') ? undefined : PaymentLinksFromJSON(json['payment_links']),
    };
}

export function ProcessDepositPaymentResponseToJSON(value?: ProcessDepositPaymentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_links': PaymentLinksToJSON(value.payment_links),
    };
}

