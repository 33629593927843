/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadStatus
 */
export interface LeadStatus {
    /**
     * 
     * @type {string}
     * @memberof LeadStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadStatus
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadStatus
     */
    is_default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadStatus
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadStatus
     */
    background_color?: string;
}

/**
 * Check if a given object implements the LeadStatus interface.
 */
export function instanceOfLeadStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function LeadStatusFromJSON(json: any): LeadStatus {
    return LeadStatusFromJSONTyped(json, false);
}

export function LeadStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'is_default': !exists(json, 'is_default') ? undefined : json['is_default'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'background_color': !exists(json, 'background_color') ? undefined : json['background_color'],
    };
}

export function LeadStatusToJSON(value?: LeadStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'is_default': value.is_default,
        'color': value.color,
        'background_color': value.background_color,
    };
}

