import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const CRMCampaignPage = loadable(() => import("@/pages/CRM/CampaignPage/CampaignPage")); // prettier-ignore
const CRMCampaignSubscribers = loadable(() => import("@/pages/CRM/CampaignPage/CampaignSubscribers/CampaignSubscribers")); // prettier-ignore
const CRMCampaignSteps = loadable(() => import("@/pages/CRM/CampaignPage/CampaignSteps/CampaignSteps")); // prettier-ignore
const CRMCampaignAudience = loadable(() => import("@/pages/CRM/CampaignPage/CampaignAudience/CampaignAudience")); // prettier-ignore
const CRMCampaignSettings = loadable(() => import("@/pages/CRM/CampaignPage/CampaignSettings")); // prettier-ignore

export enum CRMCampaignRoutes {
  CRMCampaign = '/crm/campaign/:campaign_id',
  CRMCampaignSteps = '/crm/campaign/:campaign_id/steps',
  CRMCampaignSubscribers = '/crm/campaign/:campaign_id/subscribers',
  CRMCampaignAudiences = '/crm/campaign/:campaign_id/audiences',
  CRMCampaignSettings = '/crm/campaign/:campaign_id/settings'
}

export const getCrmCampaignRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMCampaignRoutes.CRMCampaign,
      element: <CRMCampaignPage />,
      children: [
        {
          path: CRMCampaignRoutes.CRMCampaignSteps,
          element: <CRMCampaignSteps />
        },
        {
          path: CRMCampaignRoutes.CRMCampaignSubscribers,
          element: <CRMCampaignSubscribers />
        },
        {
          path: CRMCampaignRoutes.CRMCampaignAudiences,
          element: <CRMCampaignAudience />
        },
        {
          path: CRMCampaignRoutes.CRMCampaignSettings,
          element: <CRMCampaignSettings />
        }
      ]
    }
  ];
};
