import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack
} from '@mui/material';
import { GridApi } from '@mui/x-data-grid-pro';
import { atom, useAtom } from 'jotai';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from '@/components/StyledFilterAccordion';

import {
  medicationInventoryHistoryTypeFilters,
  useMedicationInventoryHistoryListFilters
} from './useMedicationInventoryHistoryListFilters';

interface MedicationInventoryHistoryListFilterProps {
  gridApiRef: React.MutableRefObject<GridApi>;
}

type Sections = 'type';

const gridFilterSectionExpandedAtom = atom<Record<Sections, boolean>>({
  type: false
});
const MedicationInventoryHistoryFiltersComponent: React.FC<
  MedicationInventoryHistoryListFilterProps
> = ({ gridApiRef }) => {
  const [expanded, setExpanded] = useAtom(gridFilterSectionExpandedAtom);

  const { handleFilterOnChange, isFilterChecked } =
    useMedicationInventoryHistoryListFilters(gridApiRef);

  const toggleExpanded = (section: Sections) => {
    setExpanded((oldExpanded) => ({
      ...oldExpanded,
      [section]: !oldExpanded[section]
    }));
  };
  const filters = medicationInventoryHistoryTypeFilters;
  const column = 'type';

  return (
    <Stack
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
        overflow: 'auto',
        width: '300px'
      }}>
      <StyledAccordion expanded={expanded.type}>
        <StyledAccordionSummary
          expandIcon={<ExpandMore htmlColor="#364955" />}
          onClick={() => toggleExpanded('type')}>
          Type
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <FormGroup>
            {filters.map((item, i: number) => (
              <FormControlLabel
                key={i}
                control={<Checkbox />}
                label={item.label}
                onChange={() => handleFilterOnChange(column, item.value)}
                checked={isFilterChecked(column, item.value)}
              />
            ))}
          </FormGroup>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Stack>
  );
};

export const MedicationInventoryHistoryListFilter = (
  props: MedicationInventoryHistoryListFilterProps
) => (
  <React.Suspense fallback={<CircularProgress />}>
    <MedicationInventoryHistoryFiltersComponent {...props} />
  </React.Suspense>
);

export default MedicationInventoryHistoryListFilter;
