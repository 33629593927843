/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface BillReceiptSettingsIncludeFooterEntry
 */
export interface BillReceiptSettingsIncludeFooterEntry {
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    field?: BillReceiptSettingsIncludeFooterEntryFieldEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    value?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    custom_footer?: string;
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    last_modified_by?: string;
    /**
     * 
     * @type {string}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    last_modified_date?: string;
    /**
     * 
     * @type {User}
     * @memberof BillReceiptSettingsIncludeFooterEntry
     */
    last_modified_by_user?: User;
}


/**
 * @export
 */
export const BillReceiptSettingsIncludeFooterEntryFieldEnum = {
    AutopayCardAch: 'include_autopay_card_ach',
    NonAutopayCardAch: 'include_non_autopay_card_ach',
    CheckCashOther: 'include_check_cash_other',
    MultiMethodAutopay: 'include_multi_method_autopay',
    FirstBills: 'include_first_bills'
} as const;
export type BillReceiptSettingsIncludeFooterEntryFieldEnum = typeof BillReceiptSettingsIncludeFooterEntryFieldEnum[keyof typeof BillReceiptSettingsIncludeFooterEntryFieldEnum];


/**
 * Check if a given object implements the BillReceiptSettingsIncludeFooterEntry interface.
 */
export function instanceOfBillReceiptSettingsIncludeFooterEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillReceiptSettingsIncludeFooterEntryFromJSON(json: any): BillReceiptSettingsIncludeFooterEntry {
    return BillReceiptSettingsIncludeFooterEntryFromJSONTyped(json, false);
}

export function BillReceiptSettingsIncludeFooterEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillReceiptSettingsIncludeFooterEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'custom_footer': !exists(json, 'custom_footer') ? undefined : json['custom_footer'],
        'last_modified_by': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'last_modified_date': !exists(json, 'last_modified_date') ? undefined : json['last_modified_date'],
        'last_modified_by_user': !exists(json, 'last_modified_by_user') ? undefined : UserFromJSON(json['last_modified_by_user']),
    };
}

export function BillReceiptSettingsIncludeFooterEntryToJSON(value?: BillReceiptSettingsIncludeFooterEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
        'custom_footer': value.custom_footer,
        'last_modified_by': value.last_modified_by,
        'last_modified_date': value.last_modified_date,
        'last_modified_by_user': UserToJSON(value.last_modified_by_user),
    };
}

