/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentSettingFindAllResponse } from './IncidentSettingFindAllResponse';
import {
    IncidentSettingFindAllResponseFromJSON,
    IncidentSettingFindAllResponseFromJSONTyped,
    IncidentSettingFindAllResponseToJSON,
} from './IncidentSettingFindAllResponse';

/**
 * 
 * @export
 * @interface FindAllLocations200Response
 */
export interface FindAllLocations200Response {
    /**
     * 
     * @type {IncidentSettingFindAllResponse}
     * @memberof FindAllLocations200Response
     */
    data?: IncidentSettingFindAllResponse;
}

/**
 * Check if a given object implements the FindAllLocations200Response interface.
 */
export function instanceOfFindAllLocations200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllLocations200ResponseFromJSON(json: any): FindAllLocations200Response {
    return FindAllLocations200ResponseFromJSONTyped(json, false);
}

export function FindAllLocations200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllLocations200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : IncidentSettingFindAllResponseFromJSON(json['data']),
    };
}

export function FindAllLocations200ResponseToJSON(value?: FindAllLocations200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IncidentSettingFindAllResponseToJSON(value.data),
    };
}

