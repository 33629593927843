export const capitalizeFirstLetter = (str: string) => {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

export function formatValuesToState(field: Array<{ [k: string]: any }> | null) {
  const values =
    field !== null
      ? field.map(({ value }) => {
          return capitalizeFirstLetter(
            value.replaceAll('_', ' ').replaceAll('-', '/')
          );
        })
      : null;
  return values;
}

export function getOthersValue(field: Array<{ [k: string]: string }>) {
  const other = field?.find(({ key }) => key.toLowerCase() === 'other');
  return other?.value;
}

export function transformJSONtoStateArray(field: Array<string> | null) {
  return field !== null
    ? field.map((value) =>
        capitalizeFirstLetter(value.replaceAll('_', ' ').replaceAll('_', '/'))
      )
    : null;
}
