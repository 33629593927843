/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCompletion } from './AssessmentCompletion';
import {
    AssessmentCompletionFromJSON,
    AssessmentCompletionFromJSONTyped,
    AssessmentCompletionToJSON,
} from './AssessmentCompletion';

/**
 * 
 * @export
 * @interface AssessmentCompletionsPage
 */
export interface AssessmentCompletionsPage {
    /**
     * 
     * @type {Array<AssessmentCompletion>}
     * @memberof AssessmentCompletionsPage
     */
    completions: Array<AssessmentCompletion>;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionsPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the AssessmentCompletionsPage interface.
 */
export function instanceOfAssessmentCompletionsPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "completions" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function AssessmentCompletionsPageFromJSON(json: any): AssessmentCompletionsPage {
    return AssessmentCompletionsPageFromJSONTyped(json, false);
}

export function AssessmentCompletionsPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletionsPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completions': ((json['completions'] as Array<any>).map(AssessmentCompletionFromJSON)),
        'total_count': json['total_count'],
    };
}

export function AssessmentCompletionsPageToJSON(value?: AssessmentCompletionsPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completions': ((value.completions as Array<any>).map(AssessmentCompletionToJSON)),
        'total_count': value.total_count,
    };
}

