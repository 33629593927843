import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const offsetDateToTimezonedMidnight = (date: Date | string): Date => {
  // Using local time here as it will be converted to UTC when saved to the database
  const dayjsDate = dayjs(date).startOf('day');
  return dayjsDate.toDate();
};
