/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentRecordType } from './PaymentRecordType';
import {
    PaymentRecordTypeFromJSON,
    PaymentRecordTypeFromJSONTyped,
    PaymentRecordTypeToJSON,
} from './PaymentRecordType';

/**
 * 
 * @export
 * @interface CreatePaymentRecordRequest
 */
export interface CreatePaymentRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRecordRequest
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentRecordRequest
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof CreatePaymentRecordRequest
     */
    date: Date;
    /**
     * 
     * @type {PaymentRecordType}
     * @memberof CreatePaymentRecordRequest
     */
    type: PaymentRecordType;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRecordRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRecordRequest
     */
    applied_to_bill_id?: string;
}

/**
 * Check if a given object implements the CreatePaymentRecordRequest interface.
 */
export function instanceOfCreatePaymentRecordRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreatePaymentRecordRequestFromJSON(json: any): CreatePaymentRecordRequest {
    return CreatePaymentRecordRequestFromJSONTyped(json, false);
}

export function CreatePaymentRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'date': (new Date(json['date'])),
        'type': PaymentRecordTypeFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applied_to_bill_id': !exists(json, 'applied_to_bill_id') ? undefined : json['applied_to_bill_id'],
    };
}

export function CreatePaymentRecordRequestToJSON(value?: CreatePaymentRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'amount': value.amount,
        'date': (value.date.toISOString().substring(0,10)),
        'type': PaymentRecordTypeToJSON(value.type),
        'description': value.description,
        'applied_to_bill_id': value.applied_to_bill_id,
    };
}

