/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserListEntry
 */
export interface UserListEntry {
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    user_type_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    user_type?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    user_title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    s3_photo_key?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListEntry
     */
    date_of_hire?: string;
}

/**
 * Check if a given object implements the UserListEntry interface.
 */
export function instanceOfUserListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;

    return isInstance;
}

export function UserListEntryFromJSON(json: any): UserListEntry {
    return UserListEntryFromJSONTyped(json, false);
}

export function UserListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_type_id': !exists(json, 'user_type_id') ? undefined : json['user_type_id'],
        'user_type': !exists(json, 'user_type') ? undefined : json['user_type'],
        'user_title': !exists(json, 'user_title') ? undefined : json['user_title'],
        'email': json['email'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        's3_photo_key': !exists(json, 's3_photo_key') ? undefined : json['s3_photo_key'],
        'date_of_hire': !exists(json, 'date_of_hire') ? undefined : json['date_of_hire'],
    };
}

export function UserListEntryToJSON(value?: UserListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_type_id': value.user_type_id,
        'user_type': value.user_type,
        'user_title': value.user_title,
        'email': value.email,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'phone': value.phone,
        's3_photo_key': value.s3_photo_key,
        'date_of_hire': value.date_of_hire,
    };
}

