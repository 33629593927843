/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-api
 * crm-screener-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CombineLeads200ResponseData
 */
export interface CombineLeads200ResponseData {
    /**
     * Combined lead ID
     * @type {string}
     * @memberof CombineLeads200ResponseData
     */
    combined_lead_id: string;
}

/**
 * Check if a given object implements the CombineLeads200ResponseData interface.
 */
export function instanceOfCombineLeads200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "combined_lead_id" in value;

    return isInstance;
}

export function CombineLeads200ResponseDataFromJSON(json: any): CombineLeads200ResponseData {
    return CombineLeads200ResponseDataFromJSONTyped(json, false);
}

export function CombineLeads200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombineLeads200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combined_lead_id': json['combined_lead_id'],
    };
}

export function CombineLeads200ResponseDataToJSON(value?: CombineLeads200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combined_lead_id': value.combined_lead_id,
    };
}

