import { User } from '@auth0/auth0-react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { format } from 'date-fns';

import { fetchResidents } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { SlidingScaleDoseRange } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { invalidateAllTasksDashboardQuery } from '@/hooks/useAllTasksDashboardQuery';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { TaskExtensionSubmissionPayload } from '@/models/TaskExtensionSubmissionModel';

import { ResidentCarePlanPayload } from './useResidentCarePlanQuery';
import {
  invalidateResidentMedicationsQuery,
  MedicationTaskPayload
} from './useResidentMedications';
import { TaskInstanceComment } from './useTaskInstanceComments';
import { ResidentVital } from './useVitalsQuery';

export type TaskInstanceStatus =
  | 'overdue'
  | 'upcoming'
  | 'completed'
  | 'cancelled'
  | 'due';

export enum TaskFollowupType {
  PRN = 'PRN'
}

export class TaskInstancePayload {
  comment: string;
  date: string;
  date_completed: string;
  date_modified: string | null;
  documentation_s3_key: string;
  execution_window_end_time: string;
  execution_window_start_time: string;
  id: string;
  most_recent_comment?: Omit<TaskInstanceComment, 'user'>;
  status: TaskInstanceStatus;
  cancellation_reason: string;
  cancelled_by_user_id: string | null;
  completed_by_user_id: string | null;
  task_extension_submissions?: TaskExtensionSubmissionPayload[];
  is_prn?: boolean;
}

export class MedicationTaskInstancePayload extends TaskInstancePayload {
  fdb_dispensable_drug: MedicationsPayload;
  medication_task: MedicationTaskPayload;
  medication_task_schedule_id: string;
  number_of_dose_units: number;
  is_new: boolean;
  acetaminophen_24_hours?: number;
  dose_range?: SlidingScaleDoseRange | null;
  dose_range_resident_vitals?: Partial<ResidentVital>;
}

export class CarePlanTaskInstancePayload extends TaskInstancePayload {
  assistance_level_id: string;
  care_plan_entry: ResidentCarePlanPayload;
  care_plan_schedule_id: string;
  description: string;
}

export class MedicationFollowupInstancePayload extends TaskInstancePayload {
  createdAt: string;
  instructions: string;
  is_cancelled: boolean;
  resident_id: number;
  result: null;
  scheduled_time: string;
  task_type: TaskFollowupType;
  med_inst: {
    id: string;
    date_completed: string;
    number_of_dose_units: number;
    medication_task: MedicationTaskPayload;
  };
  created_by_user: User;
  fdb_dispensable_drug: MedicationsPayload;
  completed_by: number | null;
  completed_by_user?: User | null;
}

export interface TaskInstancesPayload {
  carePlanTaskInstances: CarePlanTaskInstancePayload[];
  medicationTaskInstances: MedicationTaskInstancePayload[];
  medicationFollowupInstances: MedicationFollowupInstancePayload[];
}

const QUERY_TASK_INSTANCES_KEY = 'useTaskInstancesQuery';

export function getTaskInstancesQueryKey(residentId: string, date?: Date) {
  if (!(date instanceof Date)) {
    date = new Date();
  }
  const formattedDate = format(date, 'y-MM-dd');
  return [QUERY_TASK_INSTANCES_KEY, residentId, formattedDate];
}

export const invalidateTaskInstancesQuery = (residentId?: string) => {
  queryClient.invalidateQueries([QUERY_TASK_INSTANCES_KEY, residentId]);
  invalidateAllTasksDashboardQuery();
  invalidateResidentMedicationsQuery(residentId);
};

export const useTaskInstancesQuery = (residentId?: string) => {
  return {
    invalidate: invalidateTaskInstancesQuery,

    query: (
      date: Date,
      options = {} as UseQueryOptions<TaskInstancesPayload>
    ) => {
      if (!(date instanceof Date)) {
        date = new Date();
      }
      const formattedDate = format(date, 'y-MM-dd');

      return useQuery<TaskInstancesPayload>(
        getTaskInstancesQueryKey(residentId!, date),
        async () => {
          const instances = (await fetchResidents.get(
            `/residents/${residentId}/task-instances`,
            { searchParams: { date: formattedDate } }
          )) as TaskInstancesPayload;
          const {
            carePlanTaskInstances,
            medicationTaskInstances,
            medicationFollowupInstances
          } = instances;
          return {
            carePlanTaskInstances: carePlanTaskInstances ?? [],
            medicationTaskInstances: medicationTaskInstances ?? [],
            medicationFollowupInstances: medicationFollowupInstances ?? []
          } as TaskInstancesPayload;
        },
        {
          enabled: !!residentId,
          ...options
        }
      );
    }
  };
};
