/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanEntryTemplate } from './CarePlanEntryTemplate';
import {
    CarePlanEntryTemplateFromJSON,
    CarePlanEntryTemplateFromJSONTyped,
    CarePlanEntryTemplateToJSON,
} from './CarePlanEntryTemplate';

/**
 * 
 * @export
 * @interface CarePlanEntry
 */
export interface CarePlanEntry {
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntry
     */
    resident_answer_id: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntry
     */
    question_id: string;
    /**
     * 
     * @type {string}
     * @memberof CarePlanEntry
     */
    answer_option_id: string;
    /**
     * 
     * @type {number}
     * @memberof CarePlanEntry
     */
    overridden_care_points?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarePlanEntry
     */
    is_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarePlanEntry
     */
    is_custom_template_updated: boolean;
    /**
     * 
     * @type {CarePlanEntryTemplate}
     * @memberof CarePlanEntry
     */
    template: CarePlanEntryTemplate;
}

/**
 * Check if a given object implements the CarePlanEntry interface.
 */
export function instanceOfCarePlanEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_answer_id" in value;
    isInstance = isInstance && "question_id" in value;
    isInstance = isInstance && "answer_option_id" in value;
    isInstance = isInstance && "is_default" in value;
    isInstance = isInstance && "is_custom_template_updated" in value;
    isInstance = isInstance && "template" in value;

    return isInstance;
}

export function CarePlanEntryFromJSON(json: any): CarePlanEntry {
    return CarePlanEntryFromJSONTyped(json, false);
}

export function CarePlanEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_answer_id': json['resident_answer_id'],
        'question_id': json['question_id'],
        'answer_option_id': json['answer_option_id'],
        'overridden_care_points': !exists(json, 'overridden_care_points') ? undefined : json['overridden_care_points'],
        'is_default': json['is_default'],
        'is_custom_template_updated': json['is_custom_template_updated'],
        'template': CarePlanEntryTemplateFromJSON(json['template']),
    };
}

export function CarePlanEntryToJSON(value?: CarePlanEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_answer_id': value.resident_answer_id,
        'question_id': value.question_id,
        'answer_option_id': value.answer_option_id,
        'overridden_care_points': value.overridden_care_points,
        'is_default': value.is_default,
        'is_custom_template_updated': value.is_custom_template_updated,
        'template': CarePlanEntryTemplateToJSON(value.template),
    };
}

