/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SendDepositEmailResponse } from './SendDepositEmailResponse';
import {
    SendDepositEmailResponseFromJSON,
    SendDepositEmailResponseFromJSONTyped,
    SendDepositEmailResponseToJSON,
} from './SendDepositEmailResponse';

/**
 * 
 * @export
 * @interface SendDepositEmail200Response
 */
export interface SendDepositEmail200Response {
    /**
     * 
     * @type {SendDepositEmailResponse}
     * @memberof SendDepositEmail200Response
     */
    data?: SendDepositEmailResponse;
}

/**
 * Check if a given object implements the SendDepositEmail200Response interface.
 */
export function instanceOfSendDepositEmail200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendDepositEmail200ResponseFromJSON(json: any): SendDepositEmail200Response {
    return SendDepositEmail200ResponseFromJSONTyped(json, false);
}

export function SendDepositEmail200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendDepositEmail200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : SendDepositEmailResponseFromJSON(json['data']),
    };
}

export function SendDepositEmail200ResponseToJSON(value?: SendDepositEmail200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SendDepositEmailResponseToJSON(value.data),
    };
}

