/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanEsignRecipient } from './CarePlanEsignRecipient';
import {
    CarePlanEsignRecipientFromJSON,
    CarePlanEsignRecipientFromJSONTyped,
    CarePlanEsignRecipientToJSON,
} from './CarePlanEsignRecipient';

/**
 * 
 * @export
 * @interface CreateCarePlanEsignRecipient200Response
 */
export interface CreateCarePlanEsignRecipient200Response {
    /**
     * 
     * @type {CarePlanEsignRecipient}
     * @memberof CreateCarePlanEsignRecipient200Response
     */
    data?: CarePlanEsignRecipient;
}

/**
 * Check if a given object implements the CreateCarePlanEsignRecipient200Response interface.
 */
export function instanceOfCreateCarePlanEsignRecipient200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCarePlanEsignRecipient200ResponseFromJSON(json: any): CreateCarePlanEsignRecipient200Response {
    return CreateCarePlanEsignRecipient200ResponseFromJSONTyped(json, false);
}

export function CreateCarePlanEsignRecipient200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCarePlanEsignRecipient200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CarePlanEsignRecipientFromJSON(json['data']),
    };
}

export function CreateCarePlanEsignRecipient200ResponseToJSON(value?: CreateCarePlanEsignRecipient200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CarePlanEsignRecipientToJSON(value.data),
    };
}

