import { Frequency } from 'rrule';

import { FrequencyTimeFormData } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  WeeklyDayOption,
  weeklyDayOptions
} from '@/components/FrequencyTimeForm/options';
import { isStaffResponsibilityV2 } from '@/components/ResponsiblePartySelectable/options';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import {
  AddResidentCarePlan,
  TaskFrequencyPostBody
} from '@/stores/residentCarePlanAtom';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { offsetDateToTimezonedMidnight } from './offsetDateToTimezonedMidnight';
import { transformFrequencyTimeFormDataToPatternedRecurrence } from './transformFrequencyTimeFormDataToPatternedRecurrence';
import { transformFrequencyTimeFormDataToRRule } from './transformFrequencyTimeFormDataToRRule';

// WARNING: If you change something here, make sure you also change it in
// transformRegimensFormToJsonBody.ts
export const transformScheduleFormToJsonBody = (
  form: Partial<AddResidentCarePlan> & Partial<ResidentMedicationForm>,
  tzid: string,
  responsibleParties: ResponsibleParty[]
): TaskFrequencyPostBody['schedule'] => {
  const freq = form.frequency!.freq;

  if (freq === 'hourly-or-minutely') {
    const patternedRecurrence =
      transformFrequencyTimeFormDataToPatternedRecurrence(
        form.frequency!,
        tzid
      );
    return [
      {
        ...(form?.frequency?.id ? { id: form.frequency.id } : {}),
        execution_window_start_time: null,
        execution_window_end_time: null,
        number_of_dose_units: form.frequency!.number_of_dose_units,
        patterned_recurrence: patternedRecurrence,
        rrule: null
      }
    ];
  }

  if (
    freq === 'informational' ||
    !isStaffResponsibilityV2(form.responsible_party || null, responsibleParties)
  ) {
    return [];
  }

  if (
    freq === 'custom' ||
    freq === 'twice-a-day' ||
    freq === 'three-times-a-day' ||
    freq === 'four-times-a-day' ||
    freq === 'five-times-a-day'
  ) {
    const schedule = [
      ...weeklyDayOptions,
      { label: 'Everyday', value: 0 } as WeeklyDayOption
    ].reduce((accum, { label, value }) => {
      form.schedule?.[label]?.forEach((frequency: FrequencyTimeFormData) => {
        if (
          frequency.execution_window_start_time &&
          frequency.execution_window_end_time &&
          frequency.type
        ) {
          const rrule = transformFrequencyTimeFormDataToRRule(frequency, {
            // Custom schedule is assumed to be weekly on specified days
            interval: 1,
            freq: freq === 'custom' ? Frequency.WEEKLY : Frequency.DAILY,
            byweekday: freq === 'custom' ? value : undefined,
            // Fetch dtstart/until from the parent form.frequency because the
            // individual schedules don't set them
            dtstart: offsetDateToTimezonedMidnight(form.frequency!.dtstart),
            until: form.frequency?.until
              ? offsetDateToTimezonedMidnight(form.frequency!.until)
              : undefined,
            tzid
          });
          accum.push({
            id: frequency.id,
            execution_window_start_time: frequency.execution_window_start_time,
            execution_window_end_time: frequency.execution_window_end_time,
            number_of_dose_units: frequency.number_of_dose_units,
            rrule: rrule?.toString() ?? ''
          });
        }
      });
      return accum;
    }, [] as TaskFrequencyPostBody['schedule']);
    return schedule;
  }

  return [
    {
      ...(form?.frequency?.id ? { id: form.frequency.id } : {}),
      execution_window_start_time:
        form?.frequency?.execution_window_start_time ?? null,
      execution_window_end_time:
        form?.frequency?.execution_window_end_time ?? null,
      number_of_dose_units: form?.frequency?.number_of_dose_units ?? null,
      rrule:
        transformFrequencyTimeFormDataToRRule(form?.frequency, {
          dtstart: offsetDateToTimezonedMidnight(form?.frequency?.dtstart!),
          until: form.frequency?.until
            ? offsetDateToTimezonedMidnight(form.frequency!.until)
            : undefined,
          tzid
        })?.toString() ?? '',
      ...(freq === 'prn' &&
      form?.followupIntervalInMinutes &&
      form?.followupInstructions
        ? {
            additional_information: {
              followUpTaskDefinition: {
                instructions: form.followupInstructions,
                timeIntervalInMinutes: form.followupIntervalInMinutes
              }
            }
          }
        : {
            additional_information: null
          }),
      prn_min_interval_minutes: form?.prn_min_interval_minutes ?? null,
      prn_max_daily_number_of_dose_units:
        form?.prn_max_daily_number_of_dose_units ?? null
    }
  ];
};
