/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentCompletedEntry } from './IncidentCompletedEntry';
import {
    IncidentCompletedEntryFromJSON,
    IncidentCompletedEntryFromJSONTyped,
    IncidentCompletedEntryToJSON,
} from './IncidentCompletedEntry';

/**
 * 
 * @export
 * @interface IncidentListEntry
 */
export interface IncidentListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    resident_photo?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    room?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentListEntry
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    incident_protocol_name?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentListEntry
     */
    status: string;
    /**
     * 
     * @type {IncidentCompletedEntry}
     * @memberof IncidentListEntry
     */
    forms?: IncidentCompletedEntry;
    /**
     * 
     * @type {IncidentCompletedEntry}
     * @memberof IncidentListEntry
     */
    actions?: IncidentCompletedEntry;
}

/**
 * Check if a given object implements the IncidentListEntry interface.
 */
export function instanceOfIncidentListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function IncidentListEntryFromJSON(json: any): IncidentListEntry {
    return IncidentListEntryFromJSONTyped(json, false);
}

export function IncidentListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'resident_name': json['resident_name'],
        'resident_photo': !exists(json, 'resident_photo') ? undefined : json['resident_photo'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'date': (new Date(json['date'])),
        'incident_protocol_name': !exists(json, 'incident_protocol_name') ? undefined : json['incident_protocol_name'],
        'status': json['status'],
        'forms': !exists(json, 'forms') ? undefined : IncidentCompletedEntryFromJSON(json['forms']),
        'actions': !exists(json, 'actions') ? undefined : IncidentCompletedEntryFromJSON(json['actions']),
    };
}

export function IncidentListEntryToJSON(value?: IncidentListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'resident_photo': value.resident_photo,
        'room': value.room,
        'date': (value.date.toISOString()),
        'incident_protocol_name': value.incident_protocol_name,
        'status': value.status,
        'forms': IncidentCompletedEntryToJSON(value.forms),
        'actions': IncidentCompletedEntryToJSON(value.actions),
    };
}

