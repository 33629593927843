import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { ControlledTextField } from '@/components/ControlledTextField';
import {
  CreateUserClockPayload,
  UseCreateUserClockMutationResult,
  UserClockAction
} from '@/hooks/useTimeClock';

import { UseMediaStreamReturn } from './useMediaStream';

interface ClockInDialogPinProps {
  formMethods: UseFormReturn<Pick<CreateUserClockPayload, 'pin'>>;
  handleRetakePhoto: VoidFunction;
  handleConfirmClockIn: VoidFunction;
  createUserClockMutation: UseCreateUserClockMutationResult;
  hasPinError: boolean;
  action: UserClockAction;
  mediaStream: UseMediaStreamReturn;
  date: dayjs.Dayjs;
}

export const ClockInDialogPin: React.FC<ClockInDialogPinProps> = ({
  formMethods,
  handleRetakePhoto,
  handleConfirmClockIn,
  createUserClockMutation,
  hasPinError,
  action,
  mediaStream,
  date
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      const pinInput = document.querySelector(
        'input[name="pin"]'
      ) as HTMLInputElement;
      pinInput?.focus?.();
    }, 250);
  }, []);

  const headerText = mediaStream.isStreamSupported
    ? `Are you sure you want to clock ${action}? You will not be able to undo this action without administrative approval.`
    : `No camera detected. You can clock ${action} with your pin only or adjust your camera settings for this device and then try again.`;

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={3}
        justifyContent="center"
        maxWidth="432px"
        height="100%">
        <Stack direction="column" alignItems="center" spacing={1.5}>
          <Typography
            variant="h5"
            color="secondary"
            fontSize="1.375rem"
            textTransform="capitalize">
            Clock {action} Confirmation
          </Typography>
          <Typography color="#667A86" textAlign="center" fontSize="1rem">
            {headerText}
          </Typography>
          <Typography color="#667A86" fontSize="0.875rem">
            Clock {action} Time{' '}
            <strong style={{ color: '#0A1E28' }}>{date.format('h:mma')}</strong>
          </Typography>
          <Typography color="#667A86" fontSize="0.75rem">
            Based on when you initiated the Clock{' '}
            <span style={{ textTransform: 'capitalize' }}>{action}</span>{' '}
            process.
          </Typography>
        </Stack>

        <ControlledTextField
          control={formMethods.control}
          label="Confirm using pin code"
          name="pin"
          type="text"
          format="pin"
          placeholder="0291"
          error={hasPinError}
          helperText={hasPinError && 'Incorrect pin'}
          rules={{
            required: true,
            minLength: 4
          }}
        />

        <Stack direction="row" spacing={2}>
          {mediaStream.isStreamSupported && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleRetakePhoto}>
              Retake Photo
            </Button>
          )}
          <LoadingButton
            sx={{ textTransform: 'capitalize' }}
            color="primary"
            variant="contained"
            onClick={handleConfirmClockIn}
            disabled={!formMethods.formState.isValid}
            loading={createUserClockMutation.isLoading}>
            Confirm Clock {action}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};
