/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingOtherSymptoms = {
    Hallucinations: 'hallucinations',
    Delusions: 'delusions',
    PhysicalBehavioralSymptomsTowardOthers: 'physical_behavioral_symptoms_toward_others',
    VerbalBehavioralSymptomsTowardOthers: 'verbal_behavioral_symptoms_toward_others',
    OtherBehavioralSymptomsNotTowardOthers: 'other_behavioral_symptoms_not_toward_others',
    RejectionOfCare: 'rejection_of_care',
    Wandering: 'wandering'
} as const;
export type MedicareNursingOtherSymptoms = typeof MedicareNursingOtherSymptoms[keyof typeof MedicareNursingOtherSymptoms];


export function MedicareNursingOtherSymptomsFromJSON(json: any): MedicareNursingOtherSymptoms {
    return MedicareNursingOtherSymptomsFromJSONTyped(json, false);
}

export function MedicareNursingOtherSymptomsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingOtherSymptoms {
    return json as MedicareNursingOtherSymptoms;
}

export function MedicareNursingOtherSymptomsToJSON(value?: MedicareNursingOtherSymptoms | null): any {
    return value as any;
}

