import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import fetchExaCare from '@/adapters/fetchExaCare';
import { Room } from '@/stores/residentFacesheetsAtom';

import { ALL_FACILITIES } from './useFacilitiesQuery';

export function useFindOneRoomQuery(
  roomId?: string,
  options: UseQueryOptions<Room> = {}
) {
  return useQuery<Room>(
    ['useFindOneRoomQuery', roomId],
    () => fetchExaCare.get<Room>(`/rooms/${roomId}`),
    {
      enabled: !!roomId,
      ...options
    }
  );
}

export function useFindAllRoomsQuery(
  facilityId?: null | string | typeof ALL_FACILITIES,
  options: UseQueryOptions<Room[]> = {}
) {
  return useQuery<Room[]>(
    ['useFindAllRoomsQuery', facilityId],
    () =>
      fetchExaCare.get<Room[]>('/rooms', {
        ...(facilityId &&
          facilityId != ALL_FACILITIES && {
            searchParams: { facility: facilityId }
          })
      }),
    options
  );
}
