/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolFollowup } from './IncidentProtocolFollowup';
import {
    IncidentProtocolFollowupFromJSON,
    IncidentProtocolFollowupFromJSONTyped,
    IncidentProtocolFollowupToJSON,
} from './IncidentProtocolFollowup';
import type { IncidentProtocolParty } from './IncidentProtocolParty';
import {
    IncidentProtocolPartyFromJSON,
    IncidentProtocolPartyFromJSONTyped,
    IncidentProtocolPartyToJSON,
} from './IncidentProtocolParty';

/**
 * 
 * @export
 * @interface CreateIncidentProtocolFormRequest
 */
export interface CreateIncidentProtocolFormRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolFormRequest
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolFormRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolFormRequest
     */
    deadline?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolFormRequest
     */
    s3_key: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIncidentProtocolFormRequest
     */
    is_optional?: boolean;
    /**
     * 
     * @type {Array<IncidentProtocolParty>}
     * @memberof CreateIncidentProtocolFormRequest
     */
    responsible_parties?: Array<IncidentProtocolParty>;
    /**
     * 
     * @type {Array<IncidentProtocolFollowup>}
     * @memberof CreateIncidentProtocolFormRequest
     */
    followups?: Array<IncidentProtocolFollowup>;
    /**
     * 
     * @type {any}
     * @memberof CreateIncidentProtocolFormRequest
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolFormRequest
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the CreateIncidentProtocolFormRequest interface.
 */
export function instanceOfCreateIncidentProtocolFormRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "incident_protocol_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "s3_key" in value;

    return isInstance;
}

export function CreateIncidentProtocolFormRequestFromJSON(json: any): CreateIncidentProtocolFormRequest {
    return CreateIncidentProtocolFormRequestFromJSONTyped(json, false);
}

export function CreateIncidentProtocolFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncidentProtocolFormRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_protocol_id': json['incident_protocol_id'],
        'name': json['name'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        's3_key': json['s3_key'],
        'is_optional': !exists(json, 'is_optional') ? undefined : json['is_optional'],
        'responsible_parties': !exists(json, 'responsible_parties') ? undefined : ((json['responsible_parties'] as Array<any>).map(IncidentProtocolPartyFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentProtocolFollowupFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function CreateIncidentProtocolFormRequestToJSON(value?: CreateIncidentProtocolFormRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_protocol_id': value.incident_protocol_id,
        'name': value.name,
        'deadline': value.deadline,
        's3_key': value.s3_key,
        'is_optional': value.is_optional,
        'responsible_parties': value.responsible_parties === undefined ? undefined : ((value.responsible_parties as Array<any>).map(IncidentProtocolPartyToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentProtocolFollowupToJSON)),
        'complete_followups': value.complete_followups,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

