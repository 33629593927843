import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';

import TagChip from '@/components/ui/TagChip/TagChip';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';
import { useTaskExtensionTagsQuery } from '@/hooks/useTaskExtensionTagsQuery';
import { TaskExtensionType } from '@/models/TaskExtensionModel';

import { InstanceWithTaskExtensionFormType } from '.';

interface TaskExtensionTagFormProps {
  formMethods: UseFormReturn<InstanceWithTaskExtensionFormType>;
  taskExtensionType: TaskExtensionType;
  cleanTemplateSelect: VoidFunction;
  disabled?: boolean;
  isOrgWide?: boolean;
}

export default function TaskExtensionTagForm(props: TaskExtensionTagFormProps) {
  const {
    formMethods,
    taskExtensionType,
    cleanTemplateSelect,
    disabled = false,
    isOrgWide = false
  } = props;
  const currentUser = useCurrentUser().data!;
  const selectedFacilityId = useSelectedFacilityId();

  const { data: taskExtensionTags = [] } = useTaskExtensionTagsQuery().findAll({
    facilityId:
      selectedFacilityId === 'ALL_FACILITIES'
        ? currentUser.facility_id
        : selectedFacilityId!,
    extensionType: taskExtensionType
  });

  const handleOnChangeTag = async (
    event: SelectChangeEvent<string | null>,
    onChange: (...event: any[]) => void
  ) => {
    const value = event.target.value;
    onChange(value);
  };

  return (
    <>
      <Alert severity="info">
        Optionally assign a tag to this extension. Tags are used for reporting
        so you can see extension results across different questions.
      </Alert>
      <Tooltip
        title={
          isOrgWide
            ? 'Tag cannot be selected on organization wide task extension'
            : ''
        }>
        <Box>
          <Controller
            name="task_extension.tag_id"
            control={formMethods.control}
            defaultValue={''}
            render={({ field: { onChange, ...field } }) => (
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="tag-select-label">Tag</InputLabel>
                <Select
                  disabled={disabled || isOrgWide}
                  labelId="tag-select-label"
                  label="Tag"
                  onChange={(e: any) => {
                    cleanTemplateSelect();
                    handleOnChangeTag(e, onChange);
                  }}
                  {...field}>
                  {taskExtensionTags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <TagChip label={tag.name} type={tag.type} />
                    </MenuItem>
                  ))}
                  <MenuItem value={''}>N/A</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Box>
      </Tooltip>
    </>
  );
}
