import React from 'react';
import { Apartment } from '@mui/icons-material';
import { Stack } from '@mui/material';

import ToolbarFacilitySelectorButton from '@/pages/AdminHub/IncidentsV2/IncidentsProtocol/components/ToolbarFacilitySelectorButton';

import EmptyPageLayout from './EmptyPageLayout';

const SelectCommunityLayout = () => {
  return (
    <EmptyPageLayout Icon={Apartment}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="center"
        alignItems="center">
        Please Select a Community <ToolbarFacilitySelectorButton />
      </Stack>
    </EmptyPageLayout>
  );
};

export default SelectCommunityLayout;
