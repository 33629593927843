import React from 'react';
import { Business } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import { UserPayload } from '@/hooks/useUserQuery';
import { ReferralModel, ReferralPayload } from '@/models/ReferralModel';
import { UserModel } from '@/models/UserModel';

import { ReferralSourceCompanyTypePayload } from './ReferralSourceCompanyTypeModel';

export class ReferralSourceCompanyPayload {
  constructor(payload: ReferralSourceCompanyPayload) {
    Object.assign(this, payload);
  }

  id: string;
  facility_id: string;
  name: string;
  description: string | null;
  referral_source_company_type_id: string | null;
  crm_referral_sources?: ReferralPayload[];
  crm_referral_source_company_type?: ReferralSourceCompanyTypePayload;
  email: string | null;
  phone: string | null;
  address: string | null;
  owner_id: string | null;
  owner?: UserPayload;
  paid: boolean;
  prospects_total?: number;
  prospects_won?: number;
}

export class ReferralSourceCompanyModel extends ReferralSourceCompanyPayload {
  declare owner?: UserModel;
  declare crm_referral_sources?: ReferralModel[];

  constructor(payload: ReferralSourceCompanyPayload) {
    super(payload);
    if (payload.owner) {
      this.owner = new UserModel(payload.owner);
    }
    if (this.crm_referral_sources) {
      this.crm_referral_sources = this.crm_referral_sources.map(
        (referral) => new ReferralModel(referral)
      );
    }
  }

  public renderClass = (): JSX.Element => {
    return (
      <Chip
        size="small"
        label={this.paid ? 'Paid' : 'Unpaid'}
        sx={{
          color: this.paid ? '#1FA081' : '#364955',
          backgroundColor: this.paid ? '#E5F2EF' : '#E7EEF4'
        }}
      />
    );
  };

  public renderListItem = (): JSX.Element => {
    return (
      <ListItem disableGutters disablePadding dense>
        <ListItemAvatar>
          <Avatar sx={{ width: '32px', height: '32px' }}>
            <Business htmlColor="#FFFFFF" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            '& .MuiListItemText-primary': {
              width: 'max-content',
              '&::after': {
                content: `"${this.paid ? 'Paid' : 'Unpaid'}"`,
                fontWeight: 400,
                color: '#667A86',
                marginLeft: '8px'
              }
            }
          }}
          primary={this.name}
          secondary={this.email}
          primaryTypographyProps={{ fontWeight: 500 }}
          secondaryTypographyProps={{ color: '#009BD0!important' }}
        />
      </ListItem>
    );
  };
}
