import React from 'react';

import { CarePlanTaskInstanceModel } from '@/models/CarePlanTaskInstanceModel';
import { MedicationTaskInstanceModel } from '@/models/MedicationTaskInstanceModel';
import { MedicationTaskModel } from '@/models/MedicationTaskModel';
import { ResidentCarePlanModel } from '@/pages/ResidentPage/ResidentCarePlanPage/ResidentCarePlanModel';
import { TaskExtensionTag } from '@/types/resident-resources';

import TagChip from '../ui/TagChip/TagChip';

export default function TaskExtensionIndicator({
  model
}: {
  model:
    | MedicationTaskInstanceModel
    | CarePlanTaskInstanceModel
    | MedicationTaskModel
    | ResidentCarePlanModel;
}) {
  let tag: TaskExtensionTag | null | undefined;

  if (
    model instanceof MedicationTaskInstanceModel ||
    model instanceof CarePlanTaskInstanceModel
  ) {
    if (
      model.task_extension_submissions &&
      model.task_extension_submissions.length > 0
    ) {
      tag =
        model.task_extension_submissions[0].task_extension?.task_extension_tag;
    } else {
      if (model instanceof MedicationTaskInstanceModel) {
        tag = model.medication_task.task_extension?.task_extension_tag;
      }
      if (model instanceof CarePlanTaskInstanceModel) {
        tag = model.care_plan_entry.task_extension?.task_extension_tag;
      }
    }
  }
  if (
    model instanceof MedicationTaskModel ||
    model instanceof ResidentCarePlanModel
  ) {
    tag = model.task_extension?.task_extension_tag;
  }
  return tag ? <TagChip label={tag.name} type={tag.type} /> : null;
}
