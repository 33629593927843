/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentAnswers } from './AssessmentAnswers';
import {
    AssessmentAnswersFromJSON,
    AssessmentAnswersFromJSONTyped,
    AssessmentAnswersToJSON,
} from './AssessmentAnswers';

/**
 * 
 * @export
 * @interface FindAllResidentAssessmentAnswersOrdered200Response
 */
export interface FindAllResidentAssessmentAnswersOrdered200Response {
    /**
     * 
     * @type {AssessmentAnswers}
     * @memberof FindAllResidentAssessmentAnswersOrdered200Response
     */
    data?: AssessmentAnswers;
}

/**
 * Check if a given object implements the FindAllResidentAssessmentAnswersOrdered200Response interface.
 */
export function instanceOfFindAllResidentAssessmentAnswersOrdered200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllResidentAssessmentAnswersOrdered200ResponseFromJSON(json: any): FindAllResidentAssessmentAnswersOrdered200Response {
    return FindAllResidentAssessmentAnswersOrdered200ResponseFromJSONTyped(json, false);
}

export function FindAllResidentAssessmentAnswersOrdered200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentAssessmentAnswersOrdered200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : AssessmentAnswersFromJSON(json['data']),
    };
}

export function FindAllResidentAssessmentAnswersOrdered200ResponseToJSON(value?: FindAllResidentAssessmentAnswersOrdered200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AssessmentAnswersToJSON(value.data),
    };
}

