/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Statement } from './Statement';
import {
    StatementFromJSON,
    StatementFromJSONTyped,
    StatementToJSON,
} from './Statement';

/**
 * 
 * @export
 * @interface ResidentStatements
 */
export interface ResidentStatements {
    /**
     * 
     * @type {string}
     * @memberof ResidentStatements
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentStatements
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentStatements
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentStatements
     */
    facility_id: string;
    /**
     * 
     * @type {Array<Statement>}
     * @memberof ResidentStatements
     */
    statements: Array<Statement>;
}

/**
 * Check if a given object implements the ResidentStatements interface.
 */
export function instanceOfResidentStatements(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "statements" in value;

    return isInstance;
}

export function ResidentStatementsFromJSON(json: any): ResidentStatements {
    return ResidentStatementsFromJSONTyped(json, false);
}

export function ResidentStatementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentStatements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'resident_name': json['resident_name'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'facility_id': json['facility_id'],
        'statements': ((json['statements'] as Array<any>).map(StatementFromJSON)),
    };
}

export function ResidentStatementsToJSON(value?: ResidentStatements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'resident_photo_url': value.resident_photo_url,
        'facility_id': value.facility_id,
        'statements': ((value.statements as Array<any>).map(StatementToJSON)),
    };
}

