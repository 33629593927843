/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileAttachment } from './FileAttachment';
import {
    FileAttachmentFromJSON,
    FileAttachmentFromJSONTyped,
    FileAttachmentToJSON,
} from './FileAttachment';
import type { TaskPriority } from './TaskPriority';
import {
    TaskPriorityFromJSON,
    TaskPriorityFromJSONTyped,
    TaskPriorityToJSON,
} from './TaskPriority';
import type { TaskTemplateReminders } from './TaskTemplateReminders';
import {
    TaskTemplateRemindersFromJSON,
    TaskTemplateRemindersFromJSONTyped,
    TaskTemplateRemindersToJSON,
} from './TaskTemplateReminders';

/**
 * 
 * @export
 * @interface TaskTemplate
 */
export interface TaskTemplate {
    /**
     * 
     * @type {string}
     * @memberof TaskTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplate
     */
    facility_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplate
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplate
     */
    task_type_id: string | null;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TaskTemplate
     */
    priority: TaskPriority | null;
    /**
     * 
     * @type {Array<FileAttachment>}
     * @memberof TaskTemplate
     */
    attachments: Array<FileAttachment>;
    /**
     * 
     * @type {Array<TaskTemplateReminders>}
     * @memberof TaskTemplate
     */
    reminders?: Array<TaskTemplateReminders>;
}

/**
 * Check if a given object implements the TaskTemplate interface.
 */
export function instanceOfTaskTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "task_type_id" in value;
    isInstance = isInstance && "priority" in value;
    isInstance = isInstance && "attachments" in value;

    return isInstance;
}

export function TaskTemplateFromJSON(json: any): TaskTemplate {
    return TaskTemplateFromJSONTyped(json, false);
}

export function TaskTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'title': json['title'],
        'description': json['description'],
        'task_type_id': json['task_type_id'],
        'priority': TaskPriorityFromJSON(json['priority']),
        'attachments': ((json['attachments'] as Array<any>).map(FileAttachmentFromJSON)),
        'reminders': !exists(json, 'reminders') ? undefined : ((json['reminders'] as Array<any>).map(TaskTemplateRemindersFromJSON)),
    };
}

export function TaskTemplateToJSON(value?: TaskTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'title': value.title,
        'description': value.description,
        'task_type_id': value.task_type_id,
        'priority': TaskPriorityToJSON(value.priority),
        'attachments': ((value.attachments as Array<any>).map(FileAttachmentToJSON)),
        'reminders': value.reminders === undefined ? undefined : ((value.reminders as Array<any>).map(TaskTemplateRemindersToJSON)),
    };
}

