import React, { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Add } from '@mui/icons-material';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';

import { ControlledSelect } from '@/components/ControlledSelect';
import {
  FrequencyTimeFormProps,
  generateScheduleFormDataDefaultValues
} from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { medicationDurationOptions } from '@/components/FrequencyTimeForm/options';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

import { MedicationRegimen } from './MedicationRegimen';

export const MedicationRegimens: React.FC<
  Pick<
    FrequencyTimeFormProps,
    'residentId' | 'formMethods' | 'isStartTimeEditable' | 'type'
  >
> = ({ residentId, formMethods, type, isStartTimeEditable }) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const { data: resident } = useFindOneResidentQuery({
    residentId,
    timezone: true
  });

  const inputsDisabled = isResidentArchived || type === 'Readonly';

  React.useEffect(() => {
    // Default rrule dtstart to the resident's physical move in date or today's
    // date if null
    if (resident && !formMethods.getValues('frequency.dtstart')) {
      formMethods.setValue(
        'frequency.dtstart',
        resident.service_start_date
          ? new Date(resident.service_start_date)
          : new Date()
      );
    }
  }, [formMethods, resident]);

  const fieldArrayFormMethods = useFieldArray({
    control: formMethods.control,
    name: 'regimens',
    keyName: '_id'
  });

  const handleAddRegimen = () => {
    fieldArrayFormMethods.append({
      frequency: null,
      schedule: generateScheduleFormDataDefaultValues(),
      sameDoseUnitsForSchedule: false
    });
  };

  return (
    <>
      <Grid xs={4}>
        <ControlledSelect
          control={formMethods.control}
          label="Length"
          name="duration"
          tooltip="Time it takes to administer this medication. Only applicable for medications that take time to administer e.g. a glucose intravenous infusion"
          options={medicationDurationOptions}
          optionIdKey="value"
          optionLabelKey="label"
          rules={{ required: true }}
          disabled={inputsDisabled}
        />
      </Grid>
      {fieldArrayFormMethods.fields.map((regimen, index) => (
        <MedicationRegimen
          disabled={inputsDisabled}
          key={regimen._id}
          index={index}
          fieldArrayFormMethods={fieldArrayFormMethods}
          formMethods={formMethods}
          type={type}
          isStartTimeEditable={isStartTimeEditable}
        />
      ))}
      {!inputsDisabled && (
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={handleAddRegimen}
          sx={{ width: 'max-content' }}>
          Add Regimen
        </Button>
      )}
    </>
  );
};
