/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountCategory } from './DiscountCategory';
import {
    DiscountCategoryFromJSON,
    DiscountCategoryFromJSONTyped,
    DiscountCategoryToJSON,
} from './DiscountCategory';

/**
 * 
 * @export
 * @interface CreateDiscountCategory201Response
 */
export interface CreateDiscountCategory201Response {
    /**
     * 
     * @type {DiscountCategory}
     * @memberof CreateDiscountCategory201Response
     */
    data?: DiscountCategory;
}

/**
 * Check if a given object implements the CreateDiscountCategory201Response interface.
 */
export function instanceOfCreateDiscountCategory201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDiscountCategory201ResponseFromJSON(json: any): CreateDiscountCategory201Response {
    return CreateDiscountCategory201ResponseFromJSONTyped(json, false);
}

export function CreateDiscountCategory201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDiscountCategory201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : DiscountCategoryFromJSON(json['data']),
    };
}

export function CreateDiscountCategory201ResponseToJSON(value?: CreateDiscountCategory201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DiscountCategoryToJSON(value.data),
    };
}

