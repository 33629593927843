/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicarePtOtMappings } from './MedicarePtOtMappings';
import {
    MedicarePtOtMappingsFromJSON,
    MedicarePtOtMappingsFromJSONTyped,
    MedicarePtOtMappingsToJSON,
} from './MedicarePtOtMappings';

/**
 * 
 * @export
 * @interface GetMedicarePtOtMappings200Response
 */
export interface GetMedicarePtOtMappings200Response {
    /**
     * 
     * @type {MedicarePtOtMappings}
     * @memberof GetMedicarePtOtMappings200Response
     */
    data?: MedicarePtOtMappings;
}

/**
 * Check if a given object implements the GetMedicarePtOtMappings200Response interface.
 */
export function instanceOfGetMedicarePtOtMappings200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetMedicarePtOtMappings200ResponseFromJSON(json: any): GetMedicarePtOtMappings200Response {
    return GetMedicarePtOtMappings200ResponseFromJSONTyped(json, false);
}

export function GetMedicarePtOtMappings200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicarePtOtMappings200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : MedicarePtOtMappingsFromJSON(json['data']),
    };
}

export function GetMedicarePtOtMappings200ResponseToJSON(value?: GetMedicarePtOtMappings200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': MedicarePtOtMappingsToJSON(value.data),
    };
}

