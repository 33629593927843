/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillCredit } from './BillCredit';
import {
    BillCreditFromJSON,
    BillCreditFromJSONTyped,
    BillCreditToJSON,
} from './BillCredit';
import type { Price } from './Price';
import {
    PriceFromJSON,
    PriceFromJSONTyped,
    PriceToJSON,
} from './Price';

/**
 * 
 * @export
 * @interface BillItem
 */
export interface BillItem {
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    category: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillItem
     */
    is_recurring: boolean;
    /**
     * 
     * @type {Price}
     * @memberof BillItem
     */
    price: Price;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    service_start_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    service_end_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    single_service_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    gl_code?: string;
    /**
     * 
     * @type {Array<BillCredit>}
     * @memberof BillItem
     */
    correction_credits: Array<BillCredit>;
}

/**
 * Check if a given object implements the BillItem interface.
 */
export function instanceOfBillItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "is_recurring" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "correction_credits" in value;

    return isInstance;
}

export function BillItemFromJSON(json: any): BillItem {
    return BillItemFromJSONTyped(json, false);
}

export function BillItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product_id': json['product_id'],
        'name': json['name'],
        'description': json['description'],
        'category': json['category'],
        'is_recurring': json['is_recurring'],
        'price': PriceFromJSON(json['price']),
        'service_start_date': !exists(json, 'service_start_date') ? undefined : json['service_start_date'],
        'service_end_date': !exists(json, 'service_end_date') ? undefined : json['service_end_date'],
        'single_service_date': !exists(json, 'single_service_date') ? undefined : json['single_service_date'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'correction_credits': ((json['correction_credits'] as Array<any>).map(BillCreditFromJSON)),
    };
}

export function BillItemToJSON(value?: BillItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_id': value.product_id,
        'name': value.name,
        'description': value.description,
        'category': value.category,
        'is_recurring': value.is_recurring,
        'price': PriceToJSON(value.price),
        'service_start_date': value.service_start_date,
        'service_end_date': value.service_end_date,
        'single_service_date': value.single_service_date,
        'gl_code': value.gl_code,
        'correction_credits': ((value.correction_credits as Array<any>).map(BillCreditToJSON)),
    };
}

