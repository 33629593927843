import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { residentsResourcesClient } from '@/adapters/schemaClients';
import {
  FindAllAvailableRooms200Response,
  FindAllAvailableRoomsRequest
} from '@/types/resident-resources';

export const AVAILABLE_ROOMS_QUERY_KEY = 'useAvailableRooms';

function useAvailableRooms() {
  const invalidate = () =>
    queryClient.invalidateQueries([AVAILABLE_ROOMS_QUERY_KEY]);

  return {
    invalidate,
    findAll: (
      params: FindAllAvailableRoomsRequest,
      options: UseQueryOptions<
        FindAllAvailableRooms200Response['data'],
        HTTPError
      > = {}
    ) => {
      return useQuery<FindAllAvailableRooms200Response['data'], HTTPError>(
        [AVAILABLE_ROOMS_QUERY_KEY, params],
        async () => {
          const response = await residentsResourcesClient.findAllAvailableRooms(
            params
          );
          return response.data!;
        },
        {
          ...options
        }
      );
    }
  };
}
export default useAvailableRooms;
