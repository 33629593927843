import { UseFormReturn } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { Frequency, RRule } from 'rrule';

import {
  FrequencyTimeFormData,
  generateScheduleFormDataDefaultValues
} from './FrequencyTimeForm';
import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  dailyIntervalOptions,
  EVENING_END_TIME,
  EVENING_START_TIME,
  fiveTimesADayOptions,
  MORNING_END_TIME,
  MORNING_START_TIME,
  NIGHT_END_TIME,
  NIGHT_START_TIME
} from './options';

const defaultTwiceADaySchedule: Partial<FrequencyTimeFormData>[] = [
  {
    type: 'partOfDay',
    execution_window_start_time: MORNING_START_TIME.value,
    execution_window_end_time: MORNING_END_TIME.value,
    partOfDay: 'morning',
    number_of_dose_units: 1
  },
  {
    type: 'partOfDay',
    execution_window_start_time: AFTERNOON_START_TIME.value,
    execution_window_end_time: AFTERNOON_END_TIME.value,
    partOfDay: 'afternoon',
    number_of_dose_units: 1
  }
];

const defaultThreeTimesADaySchedule: Partial<FrequencyTimeFormData>[] = [
  ...defaultTwiceADaySchedule,
  {
    type: 'partOfDay',
    execution_window_start_time: EVENING_START_TIME.value,
    execution_window_end_time: EVENING_END_TIME.value,
    partOfDay: 'evening',
    number_of_dose_units: 1
  }
];

const defaultFourTimesADaySchedule: Partial<FrequencyTimeFormData>[] = [
  ...defaultThreeTimesADaySchedule,
  {
    type: 'partOfDay',
    execution_window_start_time: NIGHT_START_TIME.value,
    execution_window_end_time: NIGHT_END_TIME.value,
    partOfDay: 'night',
    number_of_dose_units: 1
  }
];

const defaultFiveTimesADaySchedule: Partial<FrequencyTimeFormData>[] =
  fiveTimesADayOptions.map((exactTimeOption) => ({
    type: 'exactTime',
    execution_window_start_time: exactTimeOption.value,
    execution_window_end_time: exactTimeOption.value,
    number_of_dose_units: 1
  }));

interface ChangeFrequencyHandlerParams {
  formMethods: UseFormReturn<any>;
  frequencyFieldPath: string;
  scheduleFieldPath: string;
}

interface ChangeFrequencyHandlerReturn {
  (frequency: string | SelectChangeEvent<unknown> | Frequency): void;
}

export const changeFrequencyHandler = ({
  formMethods,
  frequencyFieldPath,
  scheduleFieldPath
}: ChangeFrequencyHandlerParams): ChangeFrequencyHandlerReturn => {
  return (frequency) => {
    // Default number of dose units of custom schedule. It'll only change all
    // individual FrequencyTimeForm's in the schedule if scheduleHasSameDoseUnits
    // prop is passed to CustomFrequencyDayForm. See CustomFrequency for more
    // context
    if (
      frequency === 'custom' ||
      frequency === 'twice-a-day' ||
      frequency === 'three-times-a-day' ||
      frequency === 'four-times-a-day' ||
      frequency === 'five-times-a-day'
    ) {
      formMethods.setValue(`${frequencyFieldPath}.number_of_dose_units`, 1);
      formMethods.setValue(
        scheduleFieldPath,
        generateScheduleFormDataDefaultValues()
      );
    }

    if (frequency === RRule.DAILY) {
      formMethods.setValue(
        `${frequencyFieldPath}.interval`,
        dailyIntervalOptions[0].value
      );
    }

    if (frequency === 'twice-a-day') {
      formMethods.setValue(
        `${scheduleFieldPath}.Everyday`,
        defaultTwiceADaySchedule as FrequencyTimeFormData[]
      );
    }

    if (frequency === 'three-times-a-day') {
      formMethods.setValue(
        `${scheduleFieldPath}.Everyday`,
        defaultThreeTimesADaySchedule as FrequencyTimeFormData[]
      );
    }

    if (frequency === 'four-times-a-day') {
      formMethods.setValue(
        `${scheduleFieldPath}.Everyday`,
        defaultFourTimesADaySchedule as FrequencyTimeFormData[]
      );
    }

    if (frequency === 'five-times-a-day') {
      formMethods.setValue(
        `${scheduleFieldPath}.Everyday`,
        defaultFiveTimesADaySchedule as FrequencyTimeFormData[]
      );
    }

    if (frequency === 'prn' || frequency === 'informational') {
      // These fields shouldn't be set when selecting a PRN frequency. Otherwise
      // execution_window_start/end_time would be included in medication sched
      // payload and system would treat it as non-PRN, thus generating instances
      formMethods.unregister(
        `${frequencyFieldPath}.execution_window_start_time`
      );
      formMethods.unregister(`${frequencyFieldPath}.execution_window_end_time`);
      formMethods.unregister(`${frequencyFieldPath}.type`);
      formMethods.unregister(`${frequencyFieldPath}.partOfDay`);
      formMethods.unregister(`${frequencyFieldPath}.bymonthday`);
      formMethods.unregister(`${frequencyFieldPath}.byweekday`);
      formMethods.unregister(`${frequencyFieldPath}.interval`);
      formMethods.unregister(`duration`);
    }

    if (frequency === 'informational') {
      formMethods.unregister(`${frequencyFieldPath}.dtstart`);
      formMethods.unregister(`${frequencyFieldPath}.until`);
    }
  };
};
