/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Suggestion } from './Suggestion';
import {
    SuggestionFromJSON,
    SuggestionFromJSONTyped,
    SuggestionToJSON,
} from './Suggestion';

/**
 * 
 * @export
 * @interface Suggestion200Response
 */
export interface Suggestion200Response {
    /**
     * 
     * @type {Suggestion}
     * @memberof Suggestion200Response
     */
    data?: Suggestion;
}

/**
 * Check if a given object implements the Suggestion200Response interface.
 */
export function instanceOfSuggestion200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Suggestion200ResponseFromJSON(json: any): Suggestion200Response {
    return Suggestion200ResponseFromJSONTyped(json, false);
}

export function Suggestion200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Suggestion200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : SuggestionFromJSON(json['data']),
    };
}

export function Suggestion200ResponseToJSON(value?: Suggestion200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SuggestionToJSON(value.data),
    };
}

