/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApfmReferralDetailsResponse
 */
export interface ApfmReferralDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    referral_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    referral_date_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    profile_reasons_for_search?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    profile_length_care_needed?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_referral_notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_toll_free?: string;
    /**
     * 
     * @type {string}
     * @memberof ApfmReferralDetailsResponse
     */
    advisor_work_phone?: string;
}

/**
 * Check if a given object implements the ApfmReferralDetailsResponse interface.
 */
export function instanceOfApfmReferralDetailsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApfmReferralDetailsResponseFromJSON(json: any): ApfmReferralDetailsResponse {
    return ApfmReferralDetailsResponseFromJSONTyped(json, false);
}

export function ApfmReferralDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApfmReferralDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referral_date': !exists(json, 'referral_date') ? undefined : json['referral_date'],
        'referral_date_time': !exists(json, 'referral_date_time') ? undefined : json['referral_date_time'],
        'profile_reasons_for_search': !exists(json, 'profile_reasons_for_search') ? undefined : json['profile_reasons_for_search'],
        'profile_length_care_needed': !exists(json, 'profile_length_care_needed') ? undefined : json['profile_length_care_needed'],
        'advisor_first_name': !exists(json, 'advisor_first_name') ? undefined : json['advisor_first_name'],
        'advisor_last_name': !exists(json, 'advisor_last_name') ? undefined : json['advisor_last_name'],
        'advisor_email': !exists(json, 'advisor_email') ? undefined : json['advisor_email'],
        'advisor_referral_notes': !exists(json, 'advisor_referral_notes') ? undefined : json['advisor_referral_notes'],
        'advisor_toll_free': !exists(json, 'advisor_toll_free') ? undefined : json['advisor_toll_free'],
        'advisor_work_phone': !exists(json, 'advisor_work_phone') ? undefined : json['advisor_work_phone'],
    };
}

export function ApfmReferralDetailsResponseToJSON(value?: ApfmReferralDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_date': value.referral_date,
        'referral_date_time': value.referral_date_time,
        'profile_reasons_for_search': value.profile_reasons_for_search,
        'profile_length_care_needed': value.profile_length_care_needed,
        'advisor_first_name': value.advisor_first_name,
        'advisor_last_name': value.advisor_last_name,
        'advisor_email': value.advisor_email,
        'advisor_referral_notes': value.advisor_referral_notes,
        'advisor_toll_free': value.advisor_toll_free,
        'advisor_work_phone': value.advisor_work_phone,
    };
}

