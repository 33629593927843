import React, { useContext } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { RegularBreakpoints, Unstable_Grid2 as Grid } from '@mui/material';
import dayjs from 'dayjs';

import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

import { ControlledSelectPrimitive } from '../ControlledSelectPrimitive';
import { ControlledTimePicker } from '../ControlledTimePicker';

import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  EVENING_END_TIME,
  EVENING_START_TIME,
  ExactTimeOption,
  MORNING_END_TIME,
  MORNING_START_TIME,
  NIGHT_END_TIME,
  NIGHT_START_TIME,
  PartOfDayOption,
  partOfDayOptions,
  TypeOption,
  typeOptions
} from './options';
import PeriodTimeInput from './PeriodTimeInput';

interface TypeAndTimeInputProps {
  fieldNames: Record<string, string>;
  formMethods: UseFormReturn<any>;
  gridBreakpoints?: {
    from: RegularBreakpoints['md'];
    to: RegularBreakpoints['md'];
  };
  handleOnChange?: VoidFunction;
  disabled?: boolean;
}

export const TypeAndTimeInput: React.FC<TypeAndTimeInputProps> = ({
  fieldNames,
  formMethods,
  gridBreakpoints = {
    from: true,
    to: true
  },
  handleOnChange = () => {},
  disabled = false
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const type = useWatch({
    control: formMethods.control,
    name: fieldNames.type
  }) as TypeOption['value'];

  const startTime = useWatch({
    control: formMethods.control,
    name: fieldNames.startTime
  }) as ExactTimeOption['value'];

  // For exact time, if the user selects a start time then end time is set to
  // that as well
  React.useEffect(() => {
    if (type === 'exactTime' && startTime) {
      formMethods.setValue(fieldNames.endTime, startTime);
    }
  }, [type, startTime]);

  const handleSelectStartTime = () => {
    // Selecting a start time should wipe the end time just in case they
    // pick the same time. Or, if they select an exact time it wipes end time
    // as well
    formMethods.setValue(fieldNames.endTime, null);
  };

  const handleSelectPartOfDay = (partOfDayValue: PartOfDayOption['value']) => {
    if (partOfDayValue === 'morning') {
      formMethods.setValue(fieldNames.startTime, MORNING_START_TIME.value);
      formMethods.setValue(fieldNames.endTime, MORNING_END_TIME.value);
    }
    if (partOfDayValue === 'afternoon') {
      formMethods.setValue(fieldNames.startTime, AFTERNOON_START_TIME.value);
      formMethods.setValue(fieldNames.endTime, AFTERNOON_END_TIME.value);
    }
    if (partOfDayValue === 'evening') {
      formMethods.setValue(fieldNames.startTime, EVENING_START_TIME.value);
      formMethods.setValue(fieldNames.endTime, EVENING_END_TIME.value);
    }
    if (partOfDayValue === 'night') {
      formMethods.setValue(fieldNames.startTime, NIGHT_START_TIME.value);
      formMethods.setValue(fieldNames.endTime, NIGHT_END_TIME.value);
    }
  };

  const handleExactTimeChange = (newValue: Date | null) => {
    if (dayjs(newValue).isValid()) {
      formMethods.setValue(
        fieldNames.startTime,
        dayjs(newValue).format('h:mm A'),
        {
          shouldDirty: true
        }
      );
      handleSelectStartTime();
      handleOnChange();
    } else {
      formMethods.setValue(fieldNames.startTime, null);
    }
  };

  return (
    <>
      <Grid xs={12} md={4}>
        <ControlledSelectPrimitive
          control={formMethods.control}
          name={fieldNames.type}
          label="Type"
          options={typeOptions}
          disabled={isResidentArchived || disabled}
          optionIdKey="value"
          optionLabelKey="label"
          rules={{ required: true }}
          onChange={handleOnChange}
        />
      </Grid>
      {type === 'period' && (
        <PeriodTimeInput
          formMethods={formMethods}
          gridBreakpoints={gridBreakpoints}
          fromFieldDisabled={isResidentArchived || disabled}
          toFieldDisabled={isResidentArchived || disabled}
          fromFieldName={fieldNames.startTime}
          toFieldName={fieldNames.endTime}
          fromFieldLabel="From"
          toFieldLabel="To"
        />
      )}
      {type === 'partOfDay' && (
        <Grid xs>
          <ControlledSelectPrimitive
            control={formMethods.control}
            label="Time"
            name={fieldNames.partOfDay}
            options={partOfDayOptions}
            disabled={isResidentArchived || disabled}
            optionLabelKey="label"
            optionIdKey="value"
            onChange={(value) => {
              handleSelectPartOfDay(value as PartOfDayOption['value']);
              handleOnChange();
            }}
            rules={{ required: true }}
          />
        </Grid>
      )}
      {type === 'exactTime' && (
        <Grid xs>
          <ControlledTimePicker
            control={formMethods.control}
            label="Time"
            name={fieldNames.startTime}
            disabled={isResidentArchived || disabled}
            onChange={(newValue: Date | null) =>
              handleExactTimeChange(newValue)
            }
            rules={{ required: true }}
          />
        </Grid>
      )}
    </>
  );
};
