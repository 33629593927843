/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceType } from './AudienceType';
import {
    AudienceTypeFromJSON,
    AudienceTypeFromJSONTyped,
    AudienceTypeToJSON,
} from './AudienceType';
import type { DynamicAudienceSettings } from './DynamicAudienceSettings';
import {
    DynamicAudienceSettingsFromJSON,
    DynamicAudienceSettingsFromJSONTyped,
    DynamicAudienceSettingsToJSON,
} from './DynamicAudienceSettings';

/**
 * 
 * @export
 * @interface Audience
 */
export interface Audience {
    /**
     * 
     * @type {string}
     * @memberof Audience
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Audience
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Audience
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Audience
     */
    description: string;
    /**
     * 
     * @type {AudienceType}
     * @memberof Audience
     */
    type: AudienceType;
    /**
     * 
     * @type {number}
     * @memberof Audience
     */
    readonly member_count?: number;
    /**
     * 
     * @type {DynamicAudienceSettings}
     * @memberof Audience
     */
    dynamic_settings?: DynamicAudienceSettings;
}

/**
 * Check if a given object implements the Audience interface.
 */
export function instanceOfAudience(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function AudienceFromJSON(json: any): Audience {
    return AudienceFromJSONTyped(json, false);
}

export function AudienceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Audience {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'title': json['title'],
        'description': json['description'],
        'type': AudienceTypeFromJSON(json['type']),
        'member_count': !exists(json, 'member_count') ? undefined : json['member_count'],
        'dynamic_settings': !exists(json, 'dynamic_settings') ? undefined : DynamicAudienceSettingsFromJSON(json['dynamic_settings']),
    };
}

export function AudienceToJSON(value?: Audience | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'title': value.title,
        'description': value.description,
        'type': AudienceTypeToJSON(value.type),
        'dynamic_settings': DynamicAudienceSettingsToJSON(value.dynamic_settings),
    };
}

