import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { FormHeader } from '@/components/FormHeader';
import {
  ObjectWithTaskExtension,
  TaskExtensionType
} from '@/models/TaskExtensionModel';

import TaskExtensionForm, {
  InstanceWithTaskExtensionFormType
} from '../TaskExtensions/TaskExtensionForm';

interface CarePlanExtrasProps {
  formMethods: UseFormReturn<ObjectWithTaskExtension>;
  isResidentArchived: boolean;
  disabled?: boolean;
  isOrgWide?: boolean;
}

export default function CarePlanExtras(props: CarePlanExtrasProps) {
  const {
    formMethods,
    isResidentArchived,
    disabled = false,
    isOrgWide = false
  } = props;
  const [extensionEnabled] = formMethods.watch(['extensionEnabled']);
  return (
    <Grid container spacing={2}>
      <Grid xs={12} mt={1}>
        <FormHeader Icon={DashboardCustomizeIcon} text="Extension" />
      </Grid>
      <Grid xs={12} md={9}>
        <Typography color="secondary" fontSize="1rem">
          Ask for extra information at task completion?
        </Typography>
      </Grid>
      <Grid xs={12} md={3} sx={{ display: 'flex', gap: 1 }}>
        <Button
          onClick={() =>
            formMethods.setValue('extensionEnabled', true, {
              shouldDirty: true
            })
          }
          size="small"
          color={extensionEnabled ? 'primary' : 'secondary'}
          variant={'outlined'}
          disabled={isResidentArchived || disabled}
          sx={{ width: 60 }}>
          Yes
        </Button>
        <Button
          onClick={() =>
            formMethods.setValue('extensionEnabled', false, {
              shouldDirty: true
            })
          }
          size="small"
          color={!extensionEnabled ? 'primary' : 'secondary'}
          disabled={isResidentArchived || disabled}
          variant={'outlined'}
          sx={{ width: 60 }}>
          No
        </Button>
      </Grid>
      {extensionEnabled && (
        <>
          <Grid xs={12}>
            <Typography color="secondary" fontSize="1rem">
              Select a template extension or create your own below.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TaskExtensionForm
              disabled={isResidentArchived || disabled}
              formMethods={
                formMethods as unknown as UseFormReturn<InstanceWithTaskExtensionFormType>
              }
              taskExtensionType={TaskExtensionType.CARE_PLAN_ENTRY}
              isOrgWide={isOrgWide}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
