/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingSpecialCareLow = {
    CerebralPalsy: 'cerebral_palsy',
    MultipleSclerosis: 'multiple_sclerosis',
    ParkinsonsDisease: 'parkinsons_disease',
    RespiratoryFailureOxygenTherapy14days: 'respiratory_failure_oxygen_therapy_14days',
    NutritionTube7days: 'nutrition_tube_7days',
    TwoOrMoreStage2PressureUlcers: 'two_or_more_stage_2_pressure_ulcers',
    OneOrMoreStage34PressureUlcers: 'one_or_more_stage_3_4_pressure_ulcers',
    OneStage2PressureUlcerOneVenousUlcer: 'one_stage_2_pressure_ulcer_one_venous_ulcer',
    FootInfectionDiabeticUlcerOpenLesion: 'foot_infection_diabetic_ulcer_open_lesion',
    Radiation14days: 'radiation_14days',
    Dialysis14days: 'dialysis_14days'
} as const;
export type MedicareNursingSpecialCareLow = typeof MedicareNursingSpecialCareLow[keyof typeof MedicareNursingSpecialCareLow];


export function MedicareNursingSpecialCareLowFromJSON(json: any): MedicareNursingSpecialCareLow {
    return MedicareNursingSpecialCareLowFromJSONTyped(json, false);
}

export function MedicareNursingSpecialCareLowFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingSpecialCareLow {
    return json as MedicareNursingSpecialCareLow;
}

export function MedicareNursingSpecialCareLowToJSON(value?: MedicareNursingSpecialCareLow | null): any {
    return value as any;
}

