import React, { useCallback, useEffect, useState } from 'react';
import { Delete, FilterAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useAtomValue } from 'jotai';

import postAccessUrl from '@/api/postAccessUrl';
import { LoadingCircularProgress } from '@/components/CircularProgress';
import { DataGridContainer } from '@/components/DataGridContainer';
import { selectedFacilityIdAtom } from '@/hooks/useFacilitiesQuery';
import {
  FIND_ALL_MEDICATION_SIGN_OFF_DEFAULT_LIMIT,
  useMedicationSignOffQuery
} from '@/hooks/useMedicationSignOffQuery';
import { usePrint } from '@/hooks/usePrint';
import { MedicationStockItemPayload } from '@/models/MedicationStockModel';
import { MedicationInventorySignOffModel } from '@/pages/CRM/models/MedicationInventorySignOffModel';
import { removeColumns } from '@/pages/Incidents';
import { FindAllMedicationStockSignOffsRequest } from '@/types/medications';

import { showDeleteMedicationInventoryDialogue } from './DeleteMedicationInventoryDialogue';
import MedicationInventoryHistoryListFilter from './MedicationInventoryHistoryListFilter';
import MedicationInventoryHistoryToolbar from './MedicationInventoryHistoryToolbar';
import MedicationInventoryPrintView from './MedicationInventoryPrintView';

export const fetchJSONFromS3Data = async (url: string) => {
  try {
    const response = await fetch(url);

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error fetching data from S3: ${response.statusText}`);
    }

    // Parse the JSON data
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching or parsing JSON data:', error);
    throw error; // Re-throw the error if you want to handle it further up the chain
  }
};

export const MedicationInventoryHistoryDataGrid: React.FC = () => {
  const gridApiRef = useGridApiRef();

  const [autoHeight, setAutoHeight] = useState(false);
  const { printView, handlePrintAsPdf, printRef, getPageMargins } = usePrint();
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom)!;

  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);

  const [printMedicationSignOffData, setPrintMedicationSignOffData] =
    useState<MedicationStockItemPayload[]>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: []
  });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 1, // BE expects page > 0
    pageSize: FIND_ALL_MEDICATION_SIGN_OFF_DEFAULT_LIMIT
  });

  const findAllMedicationInventorySignOffsRequest: FindAllMedicationStockSignOffsRequest =
    React.useMemo(() => {
      const typeFilters = filterModel.items
        .filter((item) => item.field === 'type')
        .flatMap((item) => item.value ?? [])
        .join(',');

      const searchFilters = filterModel.quickFilterValues?.join(' ');

      const page =
        paginationModel.page < 1 ? '1' : paginationModel.page.toString();

      return {
        facilityId: selectedFacilityId,
        limit: paginationModel.pageSize.toString(),
        page: page,
        search: searchFilters,
        type: typeFilters
      };
    }, [filterModel, paginationModel, selectedFacilityId]);

  const { data: medicationStockSignOffs, isFetching } =
    useMedicationSignOffQuery().findAll(
      findAllMedicationInventorySignOffsRequest
    );

  const [rowCountState, setRowCountState] = useState(
    medicationStockSignOffs?.total_count || 0
  );

  const [selectedRowData, setSelectedRowData] =
    useState<MedicationInventorySignOffModel>();

  useEffect(() => {
    if (!printView) {
      setAutoHeight(false);
    }
  }, [printView]);

  const handleViewClick = useCallback(
    async (row: MedicationInventorySignOffModel) => {
      setLoadingRowId(row.id);
      try {
        setSelectedRowData(row);
        const signedS3Url = await postAccessUrl([row.snapshot_s3_url]);
        const printViewData = await fetchJSONFromS3Data(signedS3Url[0].url);
        setPrintMedicationSignOffData(printViewData);
        setAutoHeight(true);
        setTimeout(() => {
          handlePrintAsPdf();
        }, 0);
      } finally {
        setLoadingRowId(null);
      }
    },
    [handlePrintAsPdf]
  );

  const handlePrintMostRecent = useCallback(async () => {
    if (medicationStockSignOffs && medicationStockSignOffs.total_count > 0) {
      const mostRecentRow = medicationStockSignOffs.medicationStockSignOffs[0];
      await handleViewClick(mostRecentRow);
    }
  }, [medicationStockSignOffs, handleViewClick]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      medicationStockSignOffs?.total_count !== undefined
        ? medicationStockSignOffs?.total_count
        : prevRowCountState
    );
  }, [medicationStockSignOffs?.total_count, setRowCountState]);

  return (
    <>
      <Stack
        position="absolute"
        height="100vh"
        width="100%"
        zIndex={9}
        bgcolor="#FFF"
        display={printView ? 'block' : 'none'}>
        <LoadingCircularProgress />
      </Stack>
      <Stack ref={printRef}>
        {printView && (
          <>
            <style>{removeColumns()}</style>
            <style>{getPageMargins()}</style>
            <MedicationInventoryPrintView
              ref={printRef}
              details={{
                signed_by: selectedRowData?.signed_by!,
                type: selectedRowData?.type!,
                date: selectedRowData?.getFullDateDisplay()!
              }}
              data={printMedicationSignOffData!}
              facilityId={selectedFacilityId}
            />
          </>
        )}
        <DataGridContainer>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeadersInner, & .MuiDataGrid-main, & .MuiDataGrid-autoHeight, & .MuiDataGrid-virtualScroller':
                {
                  overflow: printView ? 'visible !important' : ''
                },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: printView ? 'white !important' : ''
              },
              '& .MuiAvatar-root': {
                display: printView ? 'none' : 'block'
              }
            }}
            rows={medicationStockSignOffs?.medicationStockSignOffs || []}
            columns={renderColumns(handleViewClick, loadingRowId)}
            paginationMode="server"
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowCount={rowCountState}
            pagination={!printView}
            autoHeight={autoHeight}
            hideFooter={printView}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[FIND_ALL_MEDICATION_SIGN_OFF_DEFAULT_LIMIT]}
            loading={isFetching}
            apiRef={gridApiRef}
            slots={{
              openFilterButtonIcon: FilterAlt,
              filterPanel: MedicationInventoryHistoryListFilter,
              toolbar: !printView ? MedicationInventoryHistoryToolbar : null
            }}
            slotProps={{
              filterPanel: {
                // @ts-expect-error
                gridApiRef
              },
              toolbar: {
                isLoading: false,
                handlePrintMostRecent: handlePrintMostRecent
              },
              panel: {
                placement: 'bottom-end'
              }
            }}
          />
        </DataGridContainer>
      </Stack>
    </>
  );
};

const renderColumns = (
  handleViewClick: (row: MedicationInventorySignOffModel) => void,
  loadingRowId: string | null
): (
  | GridColDef<MedicationInventorySignOffModel>
  | GridActionsColDef<MedicationInventorySignOffModel>
)[] => [
  {
    field: 'signed_at',
    headerName: 'Date & Time',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Stack direction="column" display="flex">
          {row.getSignedAtDateOnly()}
          <Typography fontSize="12px" fontWeight="400" color="textSecondary">
            {row.getSignedAtTimeOnly()}
          </Typography>
        </Stack>
      );
    }
  },
  {
    field: 'signed_by',
    headerName: 'Signed-Off By',
    flex: 1,
    valueGetter: ({ row }) => row.getSignedByFullName()
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    valueGetter: ({ row }) => row.getTypeDisplay()
  },
  {
    field: 'actions',
    type: 'actions',
    width: 120,
    getActions: ({ row }) => [
      <LoadingButton
        key="view-inventory-sign-off"
        variant="contained"
        color="secondary"
        loading={loadingRowId === row.id}
        onClick={() => handleViewClick(row)}>
        View
      </LoadingButton>,
      <GridActionsCellItem
        key="delete-sign-off"
        icon={<Delete />}
        label="Delete Sign-Off Entry"
        onClick={() => showDeleteMedicationInventoryDialogue({ row })}
        showInMenu
      />
    ]
  }
];
