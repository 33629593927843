export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.replace(/[^\d.]/g, '').length > 10) {
    return phoneNumber
      .replace(/[^\d.]/g, '')
      .replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, '+$1 ($2) $3-$4');
  }
  return phoneNumber
    .replace(/[^\d.]/g, '')
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
};

export const normalizePhoneNumber = (phoneNumber: string): string => {
  const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
  if (formattedPhoneNumber.length === 10) {
    return `+1${formattedPhoneNumber}`;
  } else if (formattedPhoneNumber.length === 11) {
    return `+${formattedPhoneNumber}`;
  }
  return phoneNumber.replace(/[^0-9+]/g, '');
};
