import dayjs from 'dayjs';

import {
  PatternedRecurrenceEhr,
  RecurrencePatternTypeEhr
} from '@/models/PatternedRecurrenceEhr';

export const transformPatternedRecurrenceToHumanText = (
  patternedRecurrence: PatternedRecurrenceEhr,
  includeFromAndTo = true
): string => {
  const patternType = patternedRecurrence.pattern?.type;
  return `Every ${patternedRecurrence.pattern?.interval} ${
    patternType === RecurrencePatternTypeEhr.Hourly ? 'hour' : 'minute'
  }${
    patternedRecurrence.pattern?.interval! > 1 ? 's' : ''
  } between ${getPatternedRecurrenceStartEndTime(patternedRecurrence)}${
    includeFromAndTo
      ? `, ${getPatternedRecurrenceFromAndToText(patternedRecurrence)}`
      : ''
  }`;
};

const getPatternedRecurrenceFromAndToText = (
  patternedRecurrence: PatternedRecurrenceEhr
) => {
  return `from ${dayjs(patternedRecurrence.range.startDate).format('ll')}${
    patternedRecurrence.range.endDate
      ? ` to ${dayjs(patternedRecurrence.range.endDate).format('ll')}`
      : ''
  }`;
};

export const getPatternedRecurrenceStartEndTime = (
  patternedRecurrence: PatternedRecurrenceEhr
) => {
  const startTime = patternedRecurrence.range.startTime;
  const endTime = patternedRecurrence.range.endTime;
  return `${startTime} - ${endTime}`;
};
