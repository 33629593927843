/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Refund
 */
export interface Refund {
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    failure_message?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    refund_reason: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    created_at: string;
}

/**
 * Check if a given object implements the Refund interface.
 */
export function instanceOfRefund(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "refund_reason" in value;
    isInstance = isInstance && "created_at" in value;

    return isInstance;
}

export function RefundFromJSON(json: any): Refund {
    return RefundFromJSONTyped(json, false);
}

export function RefundFromJSONTyped(json: any, ignoreDiscriminator: boolean): Refund {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'status': json['status'],
        'failure_message': !exists(json, 'failure_message') ? undefined : json['failure_message'],
        'refund_reason': json['refund_reason'],
        'created_at': json['created_at'],
    };
}

export function RefundToJSON(value?: Refund | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'status': value.status,
        'failure_message': value.failure_message,
        'refund_reason': value.refund_reason,
        'created_at': value.created_at,
    };
}

