/* tslint:disable */
/* eslint-disable */
/**
 * resident-resources
 * resident-resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskExtensionType } from './TaskExtensionType';
import {
    TaskExtensionTypeFromJSON,
    TaskExtensionTypeFromJSONTyped,
    TaskExtensionTypeToJSON,
} from './TaskExtensionType';

/**
 * 
 * @export
 * @interface TaskExtensionTag
 */
export interface TaskExtensionTag {
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTag
     */
    facility_id: string;
    /**
     * 
     * @type {TaskExtensionType}
     * @memberof TaskExtensionTag
     */
    extension_type?: TaskExtensionType;
    /**
     * 
     * @type {string}
     * @memberof TaskExtensionTag
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TaskExtensionTag
     */
    type: number;
}

/**
 * Check if a given object implements the TaskExtensionTag interface.
 */
export function instanceOfTaskExtensionTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function TaskExtensionTagFromJSON(json: any): TaskExtensionTag {
    return TaskExtensionTagFromJSONTyped(json, false);
}

export function TaskExtensionTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskExtensionTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'extension_type': !exists(json, 'extension_type') ? undefined : TaskExtensionTypeFromJSON(json['extension_type']),
        'name': json['name'],
        'type': json['type'],
    };
}

export function TaskExtensionTagToJSON(value?: TaskExtensionTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'extension_type': TaskExtensionTypeToJSON(value.extension_type),
        'name': value.name,
        'type': value.type,
    };
}

