import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCRM } from '@/adapters/fetchExaCare';

import { CrmEventLog } from '../models/CrmEventLogModel';
import { CrmRelationType } from '../models/CrmTaskModel';

export const EVENT_LOG_FIND_ALL_QUERY_KEY = 'eventLogFindAll';

export const useEventLogQuery = () => {
  return {
    findAll: (
      relationType: CrmRelationType,
      relationId: string,
      options: UseQueryOptions<CrmEventLog[]> = {}
    ) =>
      useQuery<CrmEventLog[]>(
        [EVENT_LOG_FIND_ALL_QUERY_KEY, { relationType, relationId }],
        async () => {
          return await fetchCRM.get<CrmEventLog[]>('event-log', {
            searchParams: {
              relation_type: relationType,
              relation_id: relationId
            }
          });
        },
        options
      )
  };
};
