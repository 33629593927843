/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCareUnitsType } from './AssessmentCareUnitsType';
import {
    AssessmentCareUnitsTypeFromJSON,
    AssessmentCareUnitsTypeFromJSONTyped,
    AssessmentCareUnitsTypeToJSON,
} from './AssessmentCareUnitsType';
import type { AssessmentCategory } from './AssessmentCategory';
import {
    AssessmentCategoryFromJSON,
    AssessmentCategoryFromJSONTyped,
    AssessmentCategoryToJSON,
} from './AssessmentCategory';
import type { AssessmentState } from './AssessmentState';
import {
    AssessmentStateFromJSON,
    AssessmentStateFromJSONTyped,
    AssessmentStateToJSON,
} from './AssessmentState';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface Assessment
 */
export interface Assessment {
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    name?: string;
    /**
     * 
     * @type {AssessmentState}
     * @memberof Assessment
     */
    state?: AssessmentState;
    /**
     * 
     * @type {UserName}
     * @memberof Assessment
     */
    created_by?: UserName;
    /**
     * 
     * @type {AssessmentCareUnitsType}
     * @memberof Assessment
     */
    care_units_type?: AssessmentCareUnitsType;
    /**
     * 
     * @type {boolean}
     * @memberof Assessment
     */
    is_care_plan_generator?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    prev_version_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    next_version_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    date_created?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    date_updated?: string;
    /**
     * 
     * @type {Array<AssessmentCategory>}
     * @memberof Assessment
     */
    categories?: Array<AssessmentCategory>;
}

/**
 * Check if a given object implements the Assessment interface.
 */
export function instanceOfAssessment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssessmentFromJSON(json: any): Assessment {
    return AssessmentFromJSONTyped(json, false);
}

export function AssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Assessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : AssessmentStateFromJSON(json['state']),
        'created_by': !exists(json, 'created_by') ? undefined : UserNameFromJSON(json['created_by']),
        'care_units_type': !exists(json, 'care_units_type') ? undefined : AssessmentCareUnitsTypeFromJSON(json['care_units_type']),
        'is_care_plan_generator': !exists(json, 'is_care_plan_generator') ? undefined : json['is_care_plan_generator'],
        'prev_version_id': !exists(json, 'prev_version_id') ? undefined : json['prev_version_id'],
        'next_version_id': !exists(json, 'next_version_id') ? undefined : json['next_version_id'],
        'date_created': !exists(json, 'date_created') ? undefined : json['date_created'],
        'date_updated': !exists(json, 'date_updated') ? undefined : json['date_updated'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(AssessmentCategoryFromJSON)),
    };
}

export function AssessmentToJSON(value?: Assessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'state': AssessmentStateToJSON(value.state),
        'created_by': UserNameToJSON(value.created_by),
        'care_units_type': AssessmentCareUnitsTypeToJSON(value.care_units_type),
        'is_care_plan_generator': value.is_care_plan_generator,
        'prev_version_id': value.prev_version_id,
        'next_version_id': value.next_version_id,
        'date_created': value.date_created,
        'date_updated': value.date_updated,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(AssessmentCategoryToJSON)),
    };
}

