import React from 'react';
import { Apartment } from '@mui/icons-material';
import {
  CircularProgress,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  styled
} from '@mui/material';
import { useAtom } from 'jotai';

import { queryClient } from '@/adapters/query';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom,
  useFacilitiesQuery
} from '@/hooks/useFacilitiesQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useMutatePutUser } from '@/hooks/useUserQuery';
import { UserModel } from '@/models/UserModel';

import useProtocolsQuery from '../../hooks/useProtocolsQuery';

const StyledSelectFacility = styled(Select)({
  color: '#364955 !important',
  '& .MuiInputBase-input, & fieldset': {
    border: 'none !important'
  }
});

const StyledSelectFacilityWrapper = styled(Stack)({
  border: '1px solid #D4DEE7',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '0px 16px',
  borderRadius: '1000px',
  height: '42px'
});

const ToolbarFacilitySelectorButton: React.FC = () => {
  const user = useCurrentUser().data!;
  const { showSnackbar } = useSnackbar();
  const { data: facilities, isLoading: isLoadingFacilities } =
    useFacilitiesQuery().findAll();

  const invalidateRows = useProtocolsQuery().invalidate;

  const [selectedFacilityId, setSelectedFacilityId] = useAtom(
    selectedFacilityIdAtom
  );

  const mutation = useMutatePutUser({
    onMutate: async (newFacility) => {
      const previousUserModel = queryClient.getQueryData([
        'useCurrentUser'
      ]) as UserModel;
      const newUserModel = previousUserModel.clone();
      newUserModel.facility_id = newFacility.facility_id!;
      queryClient.setQueryData(['useCurrentUser'], newUserModel);
      setSelectedFacilityId(newFacility.facility_id!);
      return { previousUserModel };
    },
    onError: async (err, newFacility, context) => {
      if (context?.previousUserModel) {
        queryClient.setQueryData(['useCurrentUser'], context.previousUserModel);
        setSelectedFacilityId(context.previousUserModel.facility_id);
      }
      showSnackbar({
        message: 'Server encountered an error, please try again',
        severity: 'error'
      });
    }
  });

  const onSelectFacility = (facilityId: string) => {
    if (facilityId !== ALL_FACILITIES && user!.facility_id !== facilityId) {
      mutation.mutate({ id: user!.id, facility_id: facilityId });
    }
    setSelectedFacilityId(facilityId);
    // Reset all queries except for useCurrentUser to prevent stale data instead
    // of having to invalidate every single query indiviudally
    queryClient.getQueriesData([]).forEach(([queryKey]) => {
      if (queryKey[0] !== 'useCurrentUser') {
        queryClient.resetQueries(queryKey);
      }
    });
    invalidateRows();
  };

  return (
    <StyledSelectFacilityWrapper>
      <ListItemIcon sx={{ minWidth: 'unset' }}>
        <Apartment sx={{ color: '#364955' }} />
      </ListItemIcon>
      {!isLoadingFacilities ? (
        <StyledSelectFacility
          value={selectedFacilityId}
          onChange={({ target }) =>
            onSelectFacility((target as HTMLInputElement).value)
          }>
          {facilities?.map((facility) => (
            <MenuItem key={facility.id} value={facility.id}>
              {facility.name}
            </MenuItem>
          ))}
        </StyledSelectFacility>
      ) : (
        <Stack
          sx={{ width: '200px' }}
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </StyledSelectFacilityWrapper>
  );
};

export default ToolbarFacilitySelectorButton;
