/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AudienceType } from './AudienceType';
import {
    AudienceTypeFromJSON,
    AudienceTypeFromJSONTyped,
    AudienceTypeToJSON,
} from './AudienceType';

/**
 * 
 * @export
 * @interface CampaignAudience
 */
export interface CampaignAudience {
    /**
     * 
     * @type {string}
     * @memberof CampaignAudience
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAudience
     */
    audience_id: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAudience
     */
    readonly audience_title?: string;
    /**
     * 
     * @type {AudienceType}
     * @memberof CampaignAudience
     */
    readonly audience_type?: AudienceType;
}

/**
 * Check if a given object implements the CampaignAudience interface.
 */
export function instanceOfCampaignAudience(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "audience_id" in value;

    return isInstance;
}

export function CampaignAudienceFromJSON(json: any): CampaignAudience {
    return CampaignAudienceFromJSONTyped(json, false);
}

export function CampaignAudienceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignAudience {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'audience_id': json['audience_id'],
        'audience_title': !exists(json, 'audience_title') ? undefined : json['audience_title'],
        'audience_type': !exists(json, 'audience_type') ? undefined : AudienceTypeFromJSON(json['audience_type']),
    };
}

export function CampaignAudienceToJSON(value?: CampaignAudience | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'audience_id': value.audience_id,
    };
}

