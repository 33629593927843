import React from 'react';

export const TempHotSmall: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="44"
    viewBox="0 0 24 44"
    fill="none">
    <path
      d="M22 42C10.9543 42 2 33.0457 2 22C2 10.9543 10.9543 2 22 2"
      stroke="#B5C9D6"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <mask
      id="mask0_22945_283302"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="44">
      <path
        d="M22 42C10.9543 42 2 33.0457 2 22C2 10.9543 10.9543 2 22 2"
        stroke="#B5C9D6"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </mask>
    <g mask="url(#mask0_22945_283302)">
      <rect
        x="0.261719"
        y="30.0859"
        width="24.3478"
        height="14.4681"
        fill="#FFA858"
      />
      <rect
        x="-0.609375"
        y="13.9141"
        width="7.82609"
        height="16.1702"
        fill="#FFA858"
      />
      <rect
        x="0.261719"
        y="-0.552734"
        width="24.3478"
        height="14.4681"
        fill="#FFA858"
      />
    </g>
  </svg>
);
