/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadMedication
 */
export interface LeadMedication {
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    lead_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    extracted_medication_name?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    extracted_medication_strength?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    extracted_medication_frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    extracted_medication_route?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    extracted_quantity_per_week?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadMedication
     */
    search_terms?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    fdb_dispensable_drug_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    fdb_packaged_drug_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadMedication
     */
    is_generic?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeadMedication
     */
    fdb_price_swd?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadMedication
     */
    fdb_price_swdpkg?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    dispensable_drug_description?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    ndc?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    price_range?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadMedication
     */
    estimated_weekly_cost?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    status_reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadMedication
     */
    is_non_drug?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadMedication
     */
    deleted_at?: string;
}

/**
 * Check if a given object implements the LeadMedication interface.
 */
export function instanceOfLeadMedication(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadMedicationFromJSON(json: any): LeadMedication {
    return LeadMedicationFromJSONTyped(json, false);
}

export function LeadMedicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadMedication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
        'extracted_medication_name': !exists(json, 'extracted_medication_name') ? undefined : json['extracted_medication_name'],
        'extracted_medication_strength': !exists(json, 'extracted_medication_strength') ? undefined : json['extracted_medication_strength'],
        'extracted_medication_frequency': !exists(json, 'extracted_medication_frequency') ? undefined : json['extracted_medication_frequency'],
        'extracted_medication_route': !exists(json, 'extracted_medication_route') ? undefined : json['extracted_medication_route'],
        'extracted_quantity_per_week': !exists(json, 'extracted_quantity_per_week') ? undefined : json['extracted_quantity_per_week'],
        'search_terms': !exists(json, 'search_terms') ? undefined : json['search_terms'],
        'fdb_dispensable_drug_id': !exists(json, 'fdb_dispensable_drug_id') ? undefined : json['fdb_dispensable_drug_id'],
        'fdb_packaged_drug_id': !exists(json, 'fdb_packaged_drug_id') ? undefined : json['fdb_packaged_drug_id'],
        'is_generic': !exists(json, 'is_generic') ? undefined : json['is_generic'],
        'fdb_price_swd': !exists(json, 'fdb_price_swd') ? undefined : json['fdb_price_swd'],
        'fdb_price_swdpkg': !exists(json, 'fdb_price_swdpkg') ? undefined : json['fdb_price_swdpkg'],
        'dispensable_drug_description': !exists(json, 'dispensable_drug_description') ? undefined : json['dispensable_drug_description'],
        'ndc': !exists(json, 'ndc') ? undefined : json['ndc'],
        'price_range': !exists(json, 'price_range') ? undefined : json['price_range'],
        'estimated_weekly_cost': !exists(json, 'estimated_weekly_cost') ? undefined : json['estimated_weekly_cost'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'status_reason': !exists(json, 'status_reason') ? undefined : json['status_reason'],
        'is_non_drug': !exists(json, 'is_non_drug') ? undefined : json['is_non_drug'],
        'deleted_at': !exists(json, 'deleted_at') ? undefined : json['deleted_at'],
    };
}

export function LeadMedicationToJSON(value?: LeadMedication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'extracted_medication_name': value.extracted_medication_name,
        'extracted_medication_strength': value.extracted_medication_strength,
        'extracted_medication_frequency': value.extracted_medication_frequency,
        'extracted_medication_route': value.extracted_medication_route,
        'extracted_quantity_per_week': value.extracted_quantity_per_week,
        'search_terms': value.search_terms,
        'fdb_dispensable_drug_id': value.fdb_dispensable_drug_id,
        'fdb_packaged_drug_id': value.fdb_packaged_drug_id,
        'is_generic': value.is_generic,
        'fdb_price_swd': value.fdb_price_swd,
        'fdb_price_swdpkg': value.fdb_price_swdpkg,
        'dispensable_drug_description': value.dispensable_drug_description,
        'ndc': value.ndc,
        'price_range': value.price_range,
        'estimated_weekly_cost': value.estimated_weekly_cost,
        'status': value.status,
        'status_reason': value.status_reason,
        'is_non_drug': value.is_non_drug,
        'deleted_at': value.deleted_at,
    };
}

