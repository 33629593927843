/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Resident } from './Resident';
import {
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './Resident';

/**
 * 
 * @export
 * @interface ResidentProduct
 */
export interface ResidentProduct {
    /**
     * 
     * @type {string}
     * @memberof ResidentProduct
     */
    id: string;
    /**
     * 
     * @type {Resident}
     * @memberof ResidentProduct
     */
    resident?: Resident;
}

/**
 * Check if a given object implements the ResidentProduct interface.
 */
export function instanceOfResidentProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ResidentProductFromJSON(json: any): ResidentProduct {
    return ResidentProductFromJSONTyped(json, false);
}

export function ResidentProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident': !exists(json, 'resident') ? undefined : ResidentFromJSON(json['resident']),
    };
}

export function ResidentProductToJSON(value?: ResidentProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident': ResidentToJSON(value.resident),
    };
}

