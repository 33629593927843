/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SlpRelatedComorbidities } from './SlpRelatedComorbidities';
import {
    SlpRelatedComorbiditiesFromJSON,
    SlpRelatedComorbiditiesFromJSONTyped,
    SlpRelatedComorbiditiesToJSON,
} from './SlpRelatedComorbidities';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface MedicareSlpAssessmentForm
 */
export interface MedicareSlpAssessmentForm {
    /**
     * 
     * @type {Array<SlpRelatedComorbidities>}
     * @memberof MedicareSlpAssessmentForm
     */
    comorbidities?: Array<SlpRelatedComorbidities>;
    /**
     * 
     * @type {Array<SlpRelatedComorbidities>}
     * @memberof MedicareSlpAssessmentForm
     */
    predicted_comorbidities?: Array<SlpRelatedComorbidities>;
    /**
     * 
     * @type {string}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_comorbidities_reasoning?: string | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof MedicareSlpAssessmentForm
     */
    predicted_comorbidities_visual_references?: Array<VisualReference>;
    /**
     * 
     * @type {boolean}
     * @memberof MedicareSlpAssessmentForm
     */
    has_swallowing_disorder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_swallowing_disorder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_swallowing_disorder_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_swallowing_disorder_visual_references?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicareSlpAssessmentForm
     */
    has_mechanically_altered_diet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_mechanically_altered_diet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_mechanically_altered_diet_reasoning?: string | null;
    /**
     * 
     * @type {any}
     * @memberof MedicareSlpAssessmentForm
     */
    readonly predicted_has_mechanically_altered_diet_visual_references?: any | null;
}

/**
 * Check if a given object implements the MedicareSlpAssessmentForm interface.
 */
export function instanceOfMedicareSlpAssessmentForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicareSlpAssessmentFormFromJSON(json: any): MedicareSlpAssessmentForm {
    return MedicareSlpAssessmentFormFromJSONTyped(json, false);
}

export function MedicareSlpAssessmentFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareSlpAssessmentForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comorbidities': !exists(json, 'comorbidities') ? undefined : ((json['comorbidities'] as Array<any>).map(SlpRelatedComorbiditiesFromJSON)),
        'predicted_comorbidities': !exists(json, 'predicted_comorbidities') ? undefined : ((json['predicted_comorbidities'] as Array<any>).map(SlpRelatedComorbiditiesFromJSON)),
        'predicted_comorbidities_reasoning': !exists(json, 'predicted_comorbidities_reasoning') ? undefined : json['predicted_comorbidities_reasoning'],
        'predicted_comorbidities_visual_references': !exists(json, 'predicted_comorbidities_visual_references') ? undefined : ((json['predicted_comorbidities_visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
        'has_swallowing_disorder': !exists(json, 'has_swallowing_disorder') ? undefined : json['has_swallowing_disorder'],
        'predicted_has_swallowing_disorder': !exists(json, 'predicted_has_swallowing_disorder') ? undefined : json['predicted_has_swallowing_disorder'],
        'predicted_has_swallowing_disorder_reasoning': !exists(json, 'predicted_has_swallowing_disorder_reasoning') ? undefined : json['predicted_has_swallowing_disorder_reasoning'],
        'predicted_has_swallowing_disorder_visual_references': !exists(json, 'predicted_has_swallowing_disorder_visual_references') ? undefined : json['predicted_has_swallowing_disorder_visual_references'],
        'has_mechanically_altered_diet': !exists(json, 'has_mechanically_altered_diet') ? undefined : json['has_mechanically_altered_diet'],
        'predicted_has_mechanically_altered_diet': !exists(json, 'predicted_has_mechanically_altered_diet') ? undefined : json['predicted_has_mechanically_altered_diet'],
        'predicted_has_mechanically_altered_diet_reasoning': !exists(json, 'predicted_has_mechanically_altered_diet_reasoning') ? undefined : json['predicted_has_mechanically_altered_diet_reasoning'],
        'predicted_has_mechanically_altered_diet_visual_references': !exists(json, 'predicted_has_mechanically_altered_diet_visual_references') ? undefined : json['predicted_has_mechanically_altered_diet_visual_references'],
    };
}

export function MedicareSlpAssessmentFormToJSON(value?: MedicareSlpAssessmentForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comorbidities': value.comorbidities === undefined ? undefined : ((value.comorbidities as Array<any>).map(SlpRelatedComorbiditiesToJSON)),
        'predicted_comorbidities': value.predicted_comorbidities === undefined ? undefined : ((value.predicted_comorbidities as Array<any>).map(SlpRelatedComorbiditiesToJSON)),
        'predicted_comorbidities_visual_references': value.predicted_comorbidities_visual_references === undefined ? undefined : ((value.predicted_comorbidities_visual_references as Array<any>).map(VisualReferenceToJSON)),
        'has_swallowing_disorder': value.has_swallowing_disorder,
        'has_mechanically_altered_diet': value.has_mechanically_altered_diet,
    };
}

