/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FindDuplicateResidentResponse } from './FindDuplicateResidentResponse';
import {
    FindDuplicateResidentResponseFromJSON,
    FindDuplicateResidentResponseFromJSONTyped,
    FindDuplicateResidentResponseToJSON,
} from './FindDuplicateResidentResponse';

/**
 * 
 * @export
 * @interface FindDuplicatesForResident200Response
 */
export interface FindDuplicatesForResident200Response {
    /**
     * 
     * @type {FindDuplicateResidentResponse}
     * @memberof FindDuplicatesForResident200Response
     */
    data?: FindDuplicateResidentResponse;
}

/**
 * Check if a given object implements the FindDuplicatesForResident200Response interface.
 */
export function instanceOfFindDuplicatesForResident200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindDuplicatesForResident200ResponseFromJSON(json: any): FindDuplicatesForResident200Response {
    return FindDuplicatesForResident200ResponseFromJSONTyped(json, false);
}

export function FindDuplicatesForResident200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindDuplicatesForResident200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FindDuplicateResidentResponseFromJSON(json['data']),
    };
}

export function FindDuplicatesForResident200ResponseToJSON(value?: FindDuplicatesForResident200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FindDuplicateResidentResponseToJSON(value.data),
    };
}

