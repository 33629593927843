/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * 
 * @export
 * @interface GenerateBillRequest
 */
export interface GenerateBillRequest {
    /**
     * 
     * @type {Month}
     * @memberof GenerateBillRequest
     */
    month: Month;
    /**
     * 
     * @type {number}
     * @memberof GenerateBillRequest
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateBillRequest
     */
    facility_id: string;
}

/**
 * Check if a given object implements the GenerateBillRequest interface.
 */
export function instanceOfGenerateBillRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "facility_id" in value;

    return isInstance;
}

export function GenerateBillRequestFromJSON(json: any): GenerateBillRequest {
    return GenerateBillRequestFromJSONTyped(json, false);
}

export function GenerateBillRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateBillRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': MonthFromJSON(json['month']),
        'year': json['year'],
        'facility_id': json['facility_id'],
    };
}

export function GenerateBillRequestToJSON(value?: GenerateBillRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': MonthToJSON(value.month),
        'year': value.year,
        'facility_id': value.facility_id,
    };
}

