/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OneTimePurchase
 */
export interface OneTimePurchase {
    /**
     * 
     * @type {string}
     * @memberof OneTimePurchase
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimePurchase
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimePurchase
     */
    service_date: string;
}

/**
 * Check if a given object implements the OneTimePurchase interface.
 */
export function instanceOfOneTimePurchase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "service_date" in value;

    return isInstance;
}

export function OneTimePurchaseFromJSON(json: any): OneTimePurchase {
    return OneTimePurchaseFromJSONTyped(json, false);
}

export function OneTimePurchaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OneTimePurchase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'product_id': json['product_id'],
        'service_date': json['service_date'],
    };
}

export function OneTimePurchaseToJSON(value?: OneTimePurchase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'product_id': value.product_id,
        'service_date': value.service_date,
    };
}

