import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { fetchResidentResources } from '@/adapters/fetchExaCare';

export interface ResponsibleParty {
  id: string;
  name: string;
  is_informational: boolean;
  default_for_medication: boolean;
  default_for_care_plan: boolean;
}

export function useFindAllResponsiblePartiesQuery<T = ResponsibleParty[]>(
  options: UseQueryOptions<ResponsibleParty[], HTTPError, T> = {}
) {
  return useQuery<ResponsibleParty[], HTTPError, T>(
    ['useFindAllResponsiblePartiesQuery'],
    async () =>
      fetchResidentResources.get<ResponsibleParty[]>(`/responsible-parties`),
    options
  );
}
