import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Stack, styled, Typography } from '@mui/material';

import { RoutePath } from '@/routes/RoutePath';

export const MedicationInventoryHistoryHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledBox
      id="medication-inventory-header-details"
      className="panel panel-border-radius">
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={() => navigate(RoutePath.PharmacyMedicationStock)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" className="name">
          History of Med Inventory Sign-Offs
        </Typography>
      </Stack>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '16px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
});
