import React from 'react';
import { useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { useCustomMedicationsQuery } from '@/hooks/useCustomMedicationsQuery';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useSnackbar } from '@/hooks/useSnackbar';
import { CustomMedicationPayload } from '@/pages/CRM/models/CustomMedicationModel';

type CreateQuickCustomMedicationDialogProps = {
  facilityId?: string;
} & InstanceProps<{
  newCustomMed: CustomMedicationPayload;
}>;

const CreateQuickCustomMedicationDialog: React.FC<
  CreateQuickCustomMedicationDialogProps
> = ({ onResolve, onReject, isOpen, facilityId }) => {
  const isMobileViewport = useIsMobileViewport();
  const formMethods = useForm<CustomMedicationPayload>({
    defaultValues: {
      facility_id: facilityId!,
      name: '',
      brand: '',
      route: '',
      form: '',
      dosage: null,
      dosage_unit: null
    }
  });
  const { showSnackbar } = useSnackbar();
  const create = useCustomMedicationsQuery().mutations().create;
  const handleConfirm = formMethods.handleSubmit(async (values) => {
    create.mutate(values, {
      onSuccess: (newCustomMed) => {
        showSnackbar({
          message: 'Successfully created medication',
          severity: 'success'
        });
        return onResolve({
          newCustomMed: newCustomMed
        });
      },
      onError: () => {
        showSnackbar({
          message: 'Error creating medication, please try again',
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => onReject()}
      PaperProps={{
        component: 'form',
        onSubmit: (e: React.FormEvent) => {
          e.preventDefault();
          handleConfirm(e);
        }
      }}
      fullScreen={isMobileViewport}>
      <DialogTitle>
        Add New Medication
        <IconButton onClick={() => onReject()}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ControlledTextField
              control={formMethods.control}
              name="name"
              label="Medication Name"
              rules={{ required: true }}
              placeholder="Vitamin C"
            />
          </Grid>
          <Grid xs={12}>
            <ControlledTextField
              control={formMethods.control}
              name="brand"
              label="Brand"
              placeholder="BioNTech"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="route"
              label="Route"
              placeholder="Oral"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="form"
              label="Form"
              placeholder="Tablet"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="dosage"
              label="Dosage"
              placeholder="40"
              type="number"
              rules={{ required: false }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="dosage_unit"
              label="Dosage Unit"
              placeholder="mg"
              rules={{ required: false }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: '24px' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onReject()}
          size="medium">
          Cancel
        </Button>
        <LoadingButton
          loading={create.isLoading}
          variant="contained"
          color="primary"
          type="submit"
          size="medium">
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const showCreateQuickCustomMedicationDialog = create(
  CreateQuickCustomMedicationDialog
);
