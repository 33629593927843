/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentDoctor } from './ResidentDoctor';
import {
    ResidentDoctorFromJSON,
    ResidentDoctorFromJSONTyped,
    ResidentDoctorToJSON,
} from './ResidentDoctor';

/**
 * 
 * @export
 * @interface AttachDoctorToResident200Response
 */
export interface AttachDoctorToResident200Response {
    /**
     * 
     * @type {ResidentDoctor}
     * @memberof AttachDoctorToResident200Response
     */
    data?: ResidentDoctor;
}

/**
 * Check if a given object implements the AttachDoctorToResident200Response interface.
 */
export function instanceOfAttachDoctorToResident200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttachDoctorToResident200ResponseFromJSON(json: any): AttachDoctorToResident200Response {
    return AttachDoctorToResident200ResponseFromJSONTyped(json, false);
}

export function AttachDoctorToResident200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachDoctorToResident200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ResidentDoctorFromJSON(json['data']),
    };
}

export function AttachDoctorToResident200ResponseToJSON(value?: AttachDoctorToResident200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ResidentDoctorToJSON(value.data),
    };
}

