/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubscriptionType = {
    Rent: 'Rent',
    Base: 'Base',
    CarePackage: 'Care Package',
    Other: 'Other'
} as const;
export type SubscriptionType = typeof SubscriptionType[keyof typeof SubscriptionType];


export function SubscriptionTypeFromJSON(json: any): SubscriptionType {
    return SubscriptionTypeFromJSONTyped(json, false);
}

export function SubscriptionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionType {
    return json as SubscriptionType;
}

export function SubscriptionTypeToJSON(value?: SubscriptionType | null): any {
    return value as any;
}

