import { Loader } from '@googlemaps/js-api-loader';
import debounce from 'lodash/debounce';

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
  region: 'us'
});

loader.load().then(() => {
  window.GOOGLE_MAPS_AUTOCOMPLETE_SERVICE =
    new google.maps.places.AutocompleteService();
  // @ts-expect-error
  window.GOOGLE_MAPS_AUTOCOMPLETE_SERVICE.getPlacePredictions = debounce(
    window.GOOGLE_MAPS_AUTOCOMPLETE_SERVICE.getPlacePredictions,
    1000
  );
});
