/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrDeleteLeadCommentReactionRequest
 */
export interface CreateOrDeleteLeadCommentReactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrDeleteLeadCommentReactionRequest
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrDeleteLeadCommentReactionRequest
     */
    reaction_type?: string;
}

/**
 * Check if a given object implements the CreateOrDeleteLeadCommentReactionRequest interface.
 */
export function instanceOfCreateOrDeleteLeadCommentReactionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOrDeleteLeadCommentReactionRequestFromJSON(json: any): CreateOrDeleteLeadCommentReactionRequest {
    return CreateOrDeleteLeadCommentReactionRequestFromJSONTyped(json, false);
}

export function CreateOrDeleteLeadCommentReactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrDeleteLeadCommentReactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'reaction_type': !exists(json, 'reaction_type') ? undefined : json['reaction_type'],
    };
}

export function CreateOrDeleteLeadCommentReactionRequestToJSON(value?: CreateOrDeleteLeadCommentReactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'reaction_type': value.reaction_type,
    };
}

