import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';

import { MedicationTaskInstanceModel } from '@/models/MedicationTaskInstanceModel';
import { MedicationTaskModel } from '@/models/MedicationTaskModel';
export const AcetaminophenLimitIndicator = ({
  model
}: {
  model: MedicationTaskInstanceModel | MedicationTaskModel;
}) => {
  if (!model.acetaminophen_24_hours) return null;
  const totalConsumed = model.acetaminophen_24_hours;
  if (totalConsumed <= 2500) return null;
  const limitIndicatorType: 'warning' | 'error' =
    totalConsumed > 2500 && totalConsumed < 3000 ? 'warning' : 'error';
  const tooltipTitle = `Resident ${
    limitIndicatorType === 'warning' ? 'is approaching' : 'has exceeded'
  } 3000 mg of acetaminophen in the past 24 hours.`;
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <WarningIcon color={limitIndicatorType} />
    </Tooltip>
  );
};
