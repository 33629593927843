/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MultiPaymentEntry } from './MultiPaymentEntry';
import {
    MultiPaymentEntryFromJSON,
    MultiPaymentEntryFromJSONTyped,
    MultiPaymentEntryToJSON,
} from './MultiPaymentEntry';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface ResidentBillingInfo
 */
export interface ResidentBillingInfo {
    /**
     * 
     * @type {string}
     * @memberof ResidentBillingInfo
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentBillingInfo
     */
    resident_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentBillingInfo
     */
    resident_photo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentBillingInfo
     */
    facility_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentBillingInfo
     */
    auto_bill_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentBillingInfo
     */
    multi_payment_enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResidentBillingInfo
     */
    auto_bill_day_of_month: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof ResidentBillingInfo
     */
    default_payment_method?: PaymentMethod;
    /**
     * 
     * @type {Array<MultiPaymentEntry>}
     * @memberof ResidentBillingInfo
     */
    multi_payment_entries: Array<MultiPaymentEntry>;
}

/**
 * Check if a given object implements the ResidentBillingInfo interface.
 */
export function instanceOfResidentBillingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "auto_bill_enabled" in value;
    isInstance = isInstance && "multi_payment_enabled" in value;
    isInstance = isInstance && "auto_bill_day_of_month" in value;
    isInstance = isInstance && "multi_payment_entries" in value;

    return isInstance;
}

export function ResidentBillingInfoFromJSON(json: any): ResidentBillingInfo {
    return ResidentBillingInfoFromJSONTyped(json, false);
}

export function ResidentBillingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentBillingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'resident_name': !exists(json, 'resident_name') ? undefined : json['resident_name'],
        'resident_photo_url': !exists(json, 'resident_photo_url') ? undefined : json['resident_photo_url'],
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'auto_bill_enabled': json['auto_bill_enabled'],
        'multi_payment_enabled': json['multi_payment_enabled'],
        'auto_bill_day_of_month': json['auto_bill_day_of_month'],
        'default_payment_method': !exists(json, 'default_payment_method') ? undefined : PaymentMethodFromJSON(json['default_payment_method']),
        'multi_payment_entries': ((json['multi_payment_entries'] as Array<any>).map(MultiPaymentEntryFromJSON)),
    };
}

export function ResidentBillingInfoToJSON(value?: ResidentBillingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'resident_photo_url': value.resident_photo_url,
        'facility_id': value.facility_id,
        'auto_bill_enabled': value.auto_bill_enabled,
        'multi_payment_enabled': value.multi_payment_enabled,
        'auto_bill_day_of_month': value.auto_bill_day_of_month,
        'default_payment_method': PaymentMethodToJSON(value.default_payment_method),
        'multi_payment_entries': ((value.multi_payment_entries as Array<any>).map(MultiPaymentEntryToJSON)),
    };
}

