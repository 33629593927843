/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareScheme } from './CareScheme';
import {
    CareSchemeFromJSON,
    CareSchemeFromJSONTyped,
    CareSchemeToJSON,
} from './CareScheme';

/**
 * 
 * @export
 * @interface CareSchemeListEntry
 */
export interface CareSchemeListEntry {
    /**
     * 
     * @type {string}
     * @memberof CareSchemeListEntry
     */
    facility_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CareSchemeListEntry
     */
    facility_name?: string;
    /**
     * 
     * @type {CareScheme}
     * @memberof CareSchemeListEntry
     */
    scheme?: CareScheme;
}

/**
 * Check if a given object implements the CareSchemeListEntry interface.
 */
export function instanceOfCareSchemeListEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CareSchemeListEntryFromJSON(json: any): CareSchemeListEntry {
    return CareSchemeListEntryFromJSONTyped(json, false);
}

export function CareSchemeListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareSchemeListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': !exists(json, 'facility_id') ? undefined : json['facility_id'],
        'facility_name': !exists(json, 'facility_name') ? undefined : json['facility_name'],
        'scheme': !exists(json, 'scheme') ? undefined : CareSchemeFromJSON(json['scheme']),
    };
}

export function CareSchemeListEntryToJSON(value?: CareSchemeListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'facility_name': value.facility_name,
        'scheme': CareSchemeToJSON(value.scheme),
    };
}

