/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualBillItem
 */
export interface ManualBillItem {
    /**
     * 
     * @type {string}
     * @memberof ManualBillItem
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof ManualBillItem
     */
    product_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManualBillItem
     */
    discount_ids: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManualBillItem
     */
    tax_ids: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ManualBillItem
     */
    is_recurring: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualBillItem
     */
    service_start_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualBillItem
     */
    service_end_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualBillItem
     */
    single_service_date?: string | null;
}

/**
 * Check if a given object implements the ManualBillItem interface.
 */
export function instanceOfManualBillItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "discount_ids" in value;
    isInstance = isInstance && "tax_ids" in value;
    isInstance = isInstance && "is_recurring" in value;

    return isInstance;
}

export function ManualBillItemFromJSON(json: any): ManualBillItem {
    return ManualBillItemFromJSONTyped(json, false);
}

export function ManualBillItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualBillItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'product_id': json['product_id'],
        'discount_ids': json['discount_ids'],
        'tax_ids': json['tax_ids'],
        'is_recurring': json['is_recurring'],
        'service_start_date': !exists(json, 'service_start_date') ? undefined : json['service_start_date'],
        'service_end_date': !exists(json, 'service_end_date') ? undefined : json['service_end_date'],
        'single_service_date': !exists(json, 'single_service_date') ? undefined : json['single_service_date'],
    };
}

export function ManualBillItemToJSON(value?: ManualBillItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'product_id': value.product_id,
        'discount_ids': value.discount_ids,
        'tax_ids': value.tax_ids,
        'is_recurring': value.is_recurring,
        'service_start_date': value.service_start_date,
        'service_end_date': value.service_end_date,
        'single_service_date': value.single_service_date,
    };
}

