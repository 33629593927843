/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { Tax } from './Tax';
import {
    TaxFromJSON,
    TaxFromJSONTyped,
    TaxToJSON,
} from './Tax';
import type { Totals } from './Totals';
import {
    TotalsFromJSON,
    TotalsFromJSONTyped,
    TotalsToJSON,
} from './Totals';

/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {Date}
     * @memberof Price
     */
    effective_from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Price
     */
    effective_to?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Price
     */
    single_service_date?: Date;
    /**
     * 
     * @type {Array<Tax>}
     * @memberof Price
     */
    taxes?: Array<Tax>;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Price
     */
    discounts?: Array<Discount>;
    /**
     * 
     * @type {Totals}
     * @memberof Price
     */
    totals?: Totals;
}

/**
 * Check if a given object implements the Price interface.
 */
export function instanceOfPrice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PriceFromJSON(json: any): Price {
    return PriceFromJSONTyped(json, false);
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effective_from': !exists(json, 'effective_from') ? undefined : (new Date(json['effective_from'])),
        'effective_to': !exists(json, 'effective_to') ? undefined : (new Date(json['effective_to'])),
        'single_service_date': !exists(json, 'single_service_date') ? undefined : (new Date(json['single_service_date'])),
        'taxes': !exists(json, 'taxes') ? undefined : ((json['taxes'] as Array<any>).map(TaxFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(DiscountFromJSON)),
        'totals': !exists(json, 'totals') ? undefined : TotalsFromJSON(json['totals']),
    };
}

export function PriceToJSON(value?: Price | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effective_from': value.effective_from === undefined ? undefined : (value.effective_from.toISOString().substring(0,10)),
        'effective_to': value.effective_to === undefined ? undefined : (value.effective_to.toISOString().substring(0,10)),
        'single_service_date': value.single_service_date === undefined ? undefined : (value.single_service_date.toISOString().substring(0,10)),
        'taxes': value.taxes === undefined ? undefined : ((value.taxes as Array<any>).map(TaxToJSON)),
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(DiscountToJSON)),
        'totals': TotalsToJSON(value.totals),
    };
}

