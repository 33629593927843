import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';

import { useMedicationSignOffQuery } from '@/hooks/useMedicationSignOffQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import { MedicationInventorySignOffModel } from '@/pages/CRM/models/MedicationInventorySignOffModel';

type DeleteMedicationInventoryDialogueProps = {
  row: MedicationInventorySignOffModel;
} & InstanceProps<unknown>;

const DeleteMedicationInventoryDialogue: React.FC<
  DeleteMedicationInventoryDialogueProps
> = ({ row, onResolve, isOpen }) => {
  const { showSnackbar } = useSnackbar();
  const deleteMutation = useMedicationSignOffQuery().mutations().deleteMutation;

  const onSubmit = async () => {
    await deleteMutation.mutate(row.id, {
      onSuccess: () => {
        showSnackbar({
          severity: 'success',
          message: 'Medication Inventory Sign Off deleted successfully'
        });
        onResolve();
      },
      onError: () => {
        showSnackbar({
          severity: 'error',
          message: 'Failed to delete sign off, please try again'
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onResolve}>
      <DialogTitle>
        Delete Inventory Sign-Off Entry?
        <IconButton onClick={onResolve}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this inventory sign-off entry for{' '}
          {row.getSignedAtDateOnly()}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onResolve}
          size="medium">
          No, Cancel
        </Button>
        <LoadingButton
          loading={deleteMutation.isLoading}
          variant="contained"
          color="primary"
          type="submit"
          size="medium"
          onClick={onSubmit}>
          Yes, Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const showDeleteMedicationInventoryDialogue = create(
  DeleteMedicationInventoryDialogue
);
