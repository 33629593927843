/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormListEntry
 */
export interface IncidentFormListEntry {
    /**
     * 
     * @type {string}
     * @memberof IncidentFormListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormListEntry
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentFormListEntry
     */
    assigned_to: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFormListEntry
     */
    deadline: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormListEntry
     */
    completed_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFormListEntry
     */
    completed_date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentFormListEntry
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormListEntry
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the IncidentFormListEntry interface.
 */
export function instanceOfIncidentFormListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "assigned_to" in value;
    isInstance = isInstance && "deadline" in value;
    isInstance = isInstance && "is_optional" in value;

    return isInstance;
}

export function IncidentFormListEntryFromJSON(json: any): IncidentFormListEntry {
    return IncidentFormListEntryFromJSONTyped(json, false);
}

export function IncidentFormListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'assigned_to': json['assigned_to'],
        'deadline': (new Date(json['deadline'])),
        'completed_by': !exists(json, 'completed_by') ? undefined : json['completed_by'],
        'completed_date': !exists(json, 'completed_date') ? undefined : (new Date(json['completed_date'])),
        'is_optional': json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function IncidentFormListEntryToJSON(value?: IncidentFormListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'assigned_to': value.assigned_to,
        'deadline': (value.deadline.toISOString()),
        'completed_by': value.completed_by,
        'completed_date': value.completed_date === undefined ? undefined : (value.completed_date.toISOString()),
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

