/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealStage } from './DealStage';
import {
    DealStageFromJSON,
    DealStageFromJSONTyped,
    DealStageToJSON,
} from './DealStage';
import type { DealTemperature } from './DealTemperature';
import {
    DealTemperatureFromJSON,
    DealTemperatureFromJSONTyped,
    DealTemperatureToJSON,
} from './DealTemperature';

/**
 * 
 * @export
 * @interface CloseConnectionProspect
 */
export interface CloseConnectionProspect {
    /**
     * 
     * @type {DealStage}
     * @memberof CloseConnectionProspect
     */
    deal_stage: DealStage | null;
    /**
     * 
     * @type {DealTemperature}
     * @memberof CloseConnectionProspect
     */
    deal_temperature: DealTemperature | null;
}

/**
 * Check if a given object implements the CloseConnectionProspect interface.
 */
export function instanceOfCloseConnectionProspect(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deal_stage" in value;
    isInstance = isInstance && "deal_temperature" in value;

    return isInstance;
}

export function CloseConnectionProspectFromJSON(json: any): CloseConnectionProspect {
    return CloseConnectionProspectFromJSONTyped(json, false);
}

export function CloseConnectionProspectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloseConnectionProspect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deal_stage': DealStageFromJSON(json['deal_stage']),
        'deal_temperature': DealTemperatureFromJSON(json['deal_temperature']),
    };
}

export function CloseConnectionProspectToJSON(value?: CloseConnectionProspect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal_stage': DealStageToJSON(value.deal_stage),
        'deal_temperature': DealTemperatureToJSON(value.deal_temperature),
    };
}

