/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingSpecialCareHigh = {
    ComatoseCompletelyDependent: 'comatose_completely_dependent',
    Septicemia: 'septicemia',
    DiabetesInsulinInjectionsInsulinOrderChange: 'diabetes_insulin_injections_insulin_order_change',
    Quadriplegia: 'quadriplegia',
    ChronicObstructivePulmonaryDiseaseShortnessOfBreath: 'chronic_obstructive_pulmonary_disease_shortness_of_breath',
    FeverWithConditions: 'fever_with_conditions',
    NutritionIv7days: 'nutrition_iv_7days',
    RespiratoryTherapy7days: 'respiratory_therapy_7days'
} as const;
export type MedicareNursingSpecialCareHigh = typeof MedicareNursingSpecialCareHigh[keyof typeof MedicareNursingSpecialCareHigh];


export function MedicareNursingSpecialCareHighFromJSON(json: any): MedicareNursingSpecialCareHigh {
    return MedicareNursingSpecialCareHighFromJSONTyped(json, false);
}

export function MedicareNursingSpecialCareHighFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingSpecialCareHigh {
    return json as MedicareNursingSpecialCareHigh;
}

export function MedicareNursingSpecialCareHighToJSON(value?: MedicareNursingSpecialCareHigh | null): any {
    return value as any;
}

