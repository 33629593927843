/* tslint:disable */
/* eslint-disable */
/**
 * residents
 * residents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentVitalListEntry } from './ResidentVitalListEntry';
import {
    ResidentVitalListEntryFromJSON,
    ResidentVitalListEntryFromJSONTyped,
    ResidentVitalListEntryToJSON,
} from './ResidentVitalListEntry';

/**
 * 
 * @export
 * @interface ResidentVitalsResponse
 */
export interface ResidentVitalsResponse {
    /**
     * 
     * @type {Array<ResidentVitalListEntry>}
     * @memberof ResidentVitalsResponse
     */
    vitals: Array<ResidentVitalListEntry>;
    /**
     * 
     * @type {number}
     * @memberof ResidentVitalsResponse
     */
    count: number;
}

/**
 * Check if a given object implements the ResidentVitalsResponse interface.
 */
export function instanceOfResidentVitalsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vitals" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function ResidentVitalsResponseFromJSON(json: any): ResidentVitalsResponse {
    return ResidentVitalsResponseFromJSONTyped(json, false);
}

export function ResidentVitalsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentVitalsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vitals': ((json['vitals'] as Array<any>).map(ResidentVitalListEntryFromJSON)),
        'count': json['count'],
    };
}

export function ResidentVitalsResponseToJSON(value?: ResidentVitalsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vitals': ((value.vitals as Array<any>).map(ResidentVitalListEntryToJSON)),
        'count': value.count,
    };
}

