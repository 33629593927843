/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentAction
 */
export interface IncidentAction {
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    incident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    incident_protocol_action_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    completed_by_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    cancelled_by_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    created_by_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    deadline: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    completed_date?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    cancellation_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    completion_note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentAction
     */
    is_optional: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncidentAction
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the IncidentAction interface.
 */
export function instanceOfIncidentAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_id" in value;
    isInstance = isInstance && "deadline" in value;
    isInstance = isInstance && "is_optional" in value;

    return isInstance;
}

export function IncidentActionFromJSON(json: any): IncidentAction {
    return IncidentActionFromJSONTyped(json, false);
}

export function IncidentActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_id': json['incident_id'],
        'incident_protocol_action_id': !exists(json, 'incident_protocol_action_id') ? undefined : json['incident_protocol_action_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'completed_by_id': !exists(json, 'completed_by_id') ? undefined : json['completed_by_id'],
        'cancelled_by_id': !exists(json, 'cancelled_by_id') ? undefined : json['cancelled_by_id'],
        'created_by_id': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'deadline': json['deadline'],
        'completed_date': !exists(json, 'completed_date') ? undefined : json['completed_date'],
        'cancellation_reason': !exists(json, 'cancellation_reason') ? undefined : json['cancellation_reason'],
        'completion_note': !exists(json, 'completion_note') ? undefined : json['completion_note'],
        'is_optional': json['is_optional'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function IncidentActionToJSON(value?: IncidentAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_id': value.incident_id,
        'incident_protocol_action_id': value.incident_protocol_action_id,
        'description': value.description,
        'completed_by_id': value.completed_by_id,
        'cancelled_by_id': value.cancelled_by_id,
        'created_by_id': value.created_by_id,
        'deadline': value.deadline,
        'completed_date': value.completed_date,
        'cancellation_reason': value.cancellation_reason,
        'completion_note': value.completion_note,
        'is_optional': value.is_optional,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

