/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Tax } from './Tax';
import {
    TaxFromJSON,
    TaxFromJSONTyped,
    TaxToJSON,
} from './Tax';

/**
 * 
 * @export
 * @interface CreateTax201Response
 */
export interface CreateTax201Response {
    /**
     * 
     * @type {Tax}
     * @memberof CreateTax201Response
     */
    data?: Tax;
}

/**
 * Check if a given object implements the CreateTax201Response interface.
 */
export function instanceOfCreateTax201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateTax201ResponseFromJSON(json: any): CreateTax201Response {
    return CreateTax201ResponseFromJSONTyped(json, false);
}

export function CreateTax201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTax201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : TaxFromJSON(json['data']),
    };
}

export function CreateTax201ResponseToJSON(value?: CreateTax201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TaxToJSON(value.data),
    };
}

