import React from 'react';
import ScaleIcon from '@mui/icons-material/Scale';
import { Tooltip } from '@mui/material';

import { MedicationTaskInstanceModel } from '@/models/MedicationTaskInstanceModel';
import { MedicationTaskModel } from '@/models/MedicationTaskModel';

export const SlidingScaleDoseIndicator = ({
  model
}: {
  model: MedicationTaskInstanceModel | MedicationTaskModel;
}) => {
  let hasSlidingScale = false;
  if (model instanceof MedicationTaskInstanceModel) {
    const medTaskInstanceSchedule =
      model.medication_task.medication_task_instances.find(
        (item) => item.id === model.id
      )?.mts;
    if (
      medTaskInstanceSchedule &&
      medTaskInstanceSchedule.dose_ranges?.length
    ) {
      hasSlidingScale = true;
    }
  } else {
    hasSlidingScale = model.medication_task_schedules.some(
      (schedule) =>
        schedule.dose_ranges !== undefined && schedule.dose_ranges.length > 0
    );
  }

  if (hasSlidingScale) {
    return (
      <Tooltip
        title="This medication is administered with a sliding scale. Please see Total Dosage column for details"
        placement="top">
        <ScaleIcon color="primary" />
      </Tooltip>
    );
  }
  return null;
};
