/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenerateBillResponse } from './GenerateBillResponse';
import {
    GenerateBillResponseFromJSON,
    GenerateBillResponseFromJSONTyped,
    GenerateBillResponseToJSON,
} from './GenerateBillResponse';

/**
 * 
 * @export
 * @interface GenerateBills200Response
 */
export interface GenerateBills200Response {
    /**
     * 
     * @type {GenerateBillResponse}
     * @memberof GenerateBills200Response
     */
    data?: GenerateBillResponse;
}

/**
 * Check if a given object implements the GenerateBills200Response interface.
 */
export function instanceOfGenerateBills200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenerateBills200ResponseFromJSON(json: any): GenerateBills200Response {
    return GenerateBills200ResponseFromJSONTyped(json, false);
}

export function GenerateBills200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateBills200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : GenerateBillResponseFromJSON(json['data']),
    };
}

export function GenerateBills200ResponseToJSON(value?: GenerateBills200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GenerateBillResponseToJSON(value.data),
    };
}

