/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DepositEmailStatus = {
    Success: 'Success',
    Failed: 'Failed',
    NotReadyYet: 'NotReadyYet'
} as const;
export type DepositEmailStatus = typeof DepositEmailStatus[keyof typeof DepositEmailStatus];


export function DepositEmailStatusFromJSON(json: any): DepositEmailStatus {
    return DepositEmailStatusFromJSONTyped(json, false);
}

export function DepositEmailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositEmailStatus {
    return json as DepositEmailStatus;
}

export function DepositEmailStatusToJSON(value?: DepositEmailStatus | null): any {
    return value as any;
}

