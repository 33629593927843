/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SkippedResident
 */
export interface SkippedResident {
    /**
     * 
     * @type {string}
     * @memberof SkippedResident
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof SkippedResident
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof SkippedResident
     */
    latest_bill_info?: string;
}

/**
 * Check if a given object implements the SkippedResident interface.
 */
export function instanceOfSkippedResident(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "resident_name" in value;

    return isInstance;
}

export function SkippedResidentFromJSON(json: any): SkippedResident {
    return SkippedResidentFromJSONTyped(json, false);
}

export function SkippedResidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkippedResident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'resident_name': json['resident_name'],
        'latest_bill_info': !exists(json, 'latest_bill_info') ? undefined : json['latest_bill_info'],
    };
}

export function SkippedResidentToJSON(value?: SkippedResident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'resident_name': value.resident_name,
        'latest_bill_info': value.latest_bill_info,
    };
}

