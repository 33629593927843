/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentInjuryArea } from './IncidentInjuryArea';
import {
    IncidentInjuryAreaFromJSON,
    IncidentInjuryAreaFromJSONTyped,
    IncidentInjuryAreaToJSON,
} from './IncidentInjuryArea';
import type { IncidentInjuryType } from './IncidentInjuryType';
import {
    IncidentInjuryTypeFromJSON,
    IncidentInjuryTypeFromJSONTyped,
    IncidentInjuryTypeToJSON,
} from './IncidentInjuryType';
import type { IncidentOrientation } from './IncidentOrientation';
import {
    IncidentOrientationFromJSON,
    IncidentOrientationFromJSONTyped,
    IncidentOrientationToJSON,
} from './IncidentOrientation';

/**
 * 
 * @export
 * @interface IncidentCreateObject
 */
export interface IncidentCreateObject {
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    resident_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    incident_location_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    pain_level_id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    consciousness_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentCreateObject
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    notes_on_env?: string;
    /**
     * 
     * @type {Array<IncidentInjuryArea>}
     * @memberof IncidentCreateObject
     */
    injury_area?: Array<IncidentInjuryArea>;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    other_injury_area?: string;
    /**
     * 
     * @type {Array<IncidentInjuryType>}
     * @memberof IncidentCreateObject
     */
    injury_type?: Array<IncidentInjuryType>;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    other_injury_type?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentCreateObject
     */
    additional_notes?: string;
    /**
     * 
     * @type {Array<IncidentOrientation>}
     * @memberof IncidentCreateObject
     */
    orientation?: Array<IncidentOrientation>;
}

/**
 * Check if a given object implements the IncidentCreateObject interface.
 */
export function instanceOfIncidentCreateObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "incident_protocol_id" in value;
    isInstance = isInstance && "incident_location_id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function IncidentCreateObjectFromJSON(json: any): IncidentCreateObject {
    return IncidentCreateObjectFromJSONTyped(json, false);
}

export function IncidentCreateObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCreateObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resident_id': json['resident_id'],
        'incident_protocol_id': json['incident_protocol_id'],
        'incident_location_id': json['incident_location_id'],
        'pain_level_id': !exists(json, 'pain_level_id') ? undefined : json['pain_level_id'],
        'consciousness_id': !exists(json, 'consciousness_id') ? undefined : json['consciousness_id'],
        'date': (new Date(json['date'])),
        'description': json['description'],
        'notes_on_env': !exists(json, 'notes_on_env') ? undefined : json['notes_on_env'],
        'injury_area': !exists(json, 'injury_area') ? undefined : ((json['injury_area'] as Array<any>).map(IncidentInjuryAreaFromJSON)),
        'other_injury_area': !exists(json, 'other_injury_area') ? undefined : json['other_injury_area'],
        'injury_type': !exists(json, 'injury_type') ? undefined : ((json['injury_type'] as Array<any>).map(IncidentInjuryTypeFromJSON)),
        'other_injury_type': !exists(json, 'other_injury_type') ? undefined : json['other_injury_type'],
        'additional_notes': !exists(json, 'additional_notes') ? undefined : json['additional_notes'],
        'orientation': !exists(json, 'orientation') ? undefined : ((json['orientation'] as Array<any>).map(IncidentOrientationFromJSON)),
    };
}

export function IncidentCreateObjectToJSON(value?: IncidentCreateObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resident_id': value.resident_id,
        'incident_protocol_id': value.incident_protocol_id,
        'incident_location_id': value.incident_location_id,
        'pain_level_id': value.pain_level_id,
        'consciousness_id': value.consciousness_id,
        'date': (value.date.toISOString()),
        'description': value.description,
        'notes_on_env': value.notes_on_env,
        'injury_area': value.injury_area === undefined ? undefined : ((value.injury_area as Array<any>).map(IncidentInjuryAreaToJSON)),
        'other_injury_area': value.other_injury_area,
        'injury_type': value.injury_type === undefined ? undefined : ((value.injury_type as Array<any>).map(IncidentInjuryTypeToJSON)),
        'other_injury_type': value.other_injury_type,
        'additional_notes': value.additional_notes,
        'orientation': value.orientation === undefined ? undefined : ((value.orientation as Array<any>).map(IncidentOrientationToJSON)),
    };
}

