import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { crmCampaignsClient } from '@/adapters/schemaClients';
import {
  AddRemoveAudienceMembersBulkOperationRequest,
  CreateAudience201Response,
  CreateAudienceRequest,
  FindAllAudienceMembersRequest,
  FindAllAudiences200Response,
  FindAllCrmContactsCloseConnectionsRequest,
  FindAllCrmContactsProspectsRequest,
  FindAllCrmContactsReferralSourcesRequest,
  FindAllCrmContactsResidentsRequest,
  FindAudienceByIdRequest,
  RemoveAudienceMemberRequest,
  UpdateAudienceByIdRequest
} from '@/types/crm-campaigns';

import {
  AudienceMemberListEntryModel,
  AudienceMembersModelsPage
} from '../models/AudienceMemberListEntryModel';
import {
  CrmProspectListEntryModel,
  CrmProspectModelsPage
} from '../models/AudienceProspectListEntryModel';
import {
  CrmReferralSourceListEntryModel,
  CrmReferralSourceModelsPage
} from '../models/AudienceReferralSourceListEntryModel';
import {
  CrmResidentListEntryModel,
  CrmResidentModelsPage
} from '../models/AudienceResidentListEntryModel';
import {
  CrmCloseConnectionListEntryModel,
  CrmCloseConnectionsModelsPage
} from '../models/CrmCloseConnectionListEntryModel';

const AUDIENCES_FIND_ALL_QUERY_KEY = 'audienceFindAll';
const AUDIENCES_FIND_ONE_QUERY_KEY = 'audienceFindOne';
const AUDIENCES_FIND_ALL_MEMBERS_QUERY_KEY = 'audienceMembersFindAll';
const AUDIENCES_FIND_ALL_CRM_CONTACT_PROSPECTS_QUERY_KEY =
  'audienceCrmContactProspectsFindAll';
const AUDIENCES_FIND_ALL_CRM_CONTACT_RESIDENTS_QUERY_KEY =
  'audienceCrmContactResidentsFindAll';
const AUDIENCES_FIND_ALL_CRM_CONTACT_REFERRAL_SOURCES_QUERY_KEY =
  'audienceCrmContactReferralSourcesFindAll';
const AUDIENCES_FIND_ALL_PROSPECT_FAMILY_AND_FRIENDS_QUERY_KEY =
  'audienceProspectFamilyAndFriendsFindAll';

export const FIND_ALL_AUDIENCE_PROSPECTS_DEFAULT_LIMIT = 200;
export const FIND_ALL_AUDIENCE_RESIDENTS_DEFAULT_LIMIT = 200;
export const FIND_ALL_AUDIENCE_MEMBERS_DEFAULT_LIMIT = 200;
export const FIND_ALL_AUDIENCE_REFERRAL_SOURCES_DEFAULT_LIMIT = 200;
export const FIND_ALL_AUDIENCE_PROSPECT_FAMILY_AND_FRIENDS_DEFAULT_LIMIT = 200;

export const invalidateAudiencesQueries = () => {
  queryClient.invalidateQueries([AUDIENCES_FIND_ONE_QUERY_KEY]);
  queryClient.invalidateQueries([AUDIENCES_FIND_ALL_QUERY_KEY]);
  queryClient.invalidateQueries([AUDIENCES_FIND_ALL_MEMBERS_QUERY_KEY]);
  queryClient.invalidateQueries([
    AUDIENCES_FIND_ALL_CRM_CONTACT_RESIDENTS_QUERY_KEY
  ]);
  queryClient.invalidateQueries([
    AUDIENCES_FIND_ALL_CRM_CONTACT_PROSPECTS_QUERY_KEY
  ]);
  queryClient.invalidateQueries([
    AUDIENCES_FIND_ALL_CRM_CONTACT_REFERRAL_SOURCES_QUERY_KEY
  ]);
  queryClient.invalidateQueries([
    AUDIENCES_FIND_ALL_PROSPECT_FAMILY_AND_FRIENDS_QUERY_KEY
  ]);
};

export const useAudienceQuery = () => {
  return {
    invalidateAudiencesQueries,

    findAll: (
      facilityId?: string,
      options: UseQueryOptions<
        FindAllAudiences200Response['data'],
        HTTPError
      > = {}
    ) =>
      useQuery<FindAllAudiences200Response['data'], HTTPError>(
        [AUDIENCES_FIND_ALL_QUERY_KEY, { facilityId }],
        async () => {
          const response = await crmCampaignsClient.findAllAudiences({
            facilityId
          });
          return response.data!;
        },
        {
          ...options
        }
      ),
    findOne: (
      audienceId: FindAudienceByIdRequest,
      options: UseQueryOptions<
        CreateAudience201Response['data'],
        HTTPError
      > = {}
    ) =>
      useQuery<CreateAudience201Response['data'], HTTPError>(
        [AUDIENCES_FIND_ONE_QUERY_KEY, { audienceId }],
        async () => {
          const response = await crmCampaignsClient.findAudienceById(
            audienceId
          );
          return response.data!;
        },
        {
          enabled: !!audienceId,
          ...options
        }
      ),
    findAllMembers: (
      params: FindAllAudienceMembersRequest,
      options: UseQueryOptions<AudienceMembersModelsPage, HTTPError> = {}
    ) => {
      return useQuery<AudienceMembersModelsPage, HTTPError>(
        [AUDIENCES_FIND_ALL_MEMBERS_QUERY_KEY, params],
        async () => {
          const response = await crmCampaignsClient.findAllAudienceMembers(
            params
          );
          const members =
            response.data?.members.map(
              (member) => new AudienceMemberListEntryModel(member)
            ) || [];
          const total_count = response.data?.total_count || 0;
          return { members, total_count };
        },
        {
          enabled: !!params.audienceId,
          ...options
        }
      );
    },

    findAllCrmContactsProspects: (
      params: FindAllCrmContactsProspectsRequest,
      options: UseQueryOptions<CrmProspectModelsPage, HTTPError> = {}
    ) => {
      return useQuery<CrmProspectModelsPage, HTTPError>(
        [AUDIENCES_FIND_ALL_CRM_CONTACT_PROSPECTS_QUERY_KEY, params],
        async () => {
          const response = await crmCampaignsClient.findAllCrmContactsProspects(
            params
          );
          const prospects =
            response.data?.prospects.map(
              (prospect) => new CrmProspectListEntryModel(prospect)
            ) || [];

          const total_count = response.data?.total_count || 0;
          return { prospects, total_count };
        },
        {
          enabled: !!params.audienceId,
          ...options
        }
      );
    },

    findAllCrmContactsResidents: (
      params: FindAllCrmContactsResidentsRequest,
      options: UseQueryOptions<CrmResidentModelsPage, HTTPError> = {}
    ) => {
      return useQuery<CrmResidentModelsPage, HTTPError>(
        [AUDIENCES_FIND_ALL_CRM_CONTACT_RESIDENTS_QUERY_KEY, params],
        async () => {
          const response = await crmCampaignsClient.findAllCrmContactsResidents(
            params
          );
          const residents =
            response.data?.residents.map(
              (resident) => new CrmResidentListEntryModel(resident)
            ) || [];
          const total_count = response.data?.total_count || 0;
          return { residents, total_count };
        },
        {
          enabled: !!params.audienceId,
          ...options
        }
      );
    },

    findAllCrmContactsReferralSources: (
      params: FindAllCrmContactsReferralSourcesRequest,
      options: UseQueryOptions<CrmReferralSourceModelsPage, HTTPError> = {}
    ) => {
      return useQuery<CrmReferralSourceModelsPage, HTTPError>(
        [AUDIENCES_FIND_ALL_CRM_CONTACT_REFERRAL_SOURCES_QUERY_KEY, params],
        async () => {
          const response =
            await crmCampaignsClient.findAllCrmContactsReferralSources(params);
          const referral_sources =
            response.data?.referral_sources.map(
              (referralSource) =>
                new CrmReferralSourceListEntryModel(referralSource)
            ) || [];
          const total_count = response.data?.total_count || 0;
          return { referral_sources, total_count };
        },
        {
          enabled: !!params.audienceId,
          ...options
        }
      );
    },
    findAllProspectFamilyAndFriends: (
      params: FindAllCrmContactsCloseConnectionsRequest,
      options: UseQueryOptions<CrmCloseConnectionsModelsPage, HTTPError> = {}
    ) => {
      return useQuery<CrmCloseConnectionsModelsPage, HTTPError>(
        [AUDIENCES_FIND_ALL_PROSPECT_FAMILY_AND_FRIENDS_QUERY_KEY, params],
        async () => {
          const response =
            await crmCampaignsClient.findAllCrmContactsCloseConnections(params);
          const close_connections =
            response.data?.close_connections.map(
              (close_connection) =>
                new CrmCloseConnectionListEntryModel(close_connection)
            ) || [];
          const total_count = response.data?.total_count || 0;
          return { close_connections, total_count };
        },
        {
          enabled: !!params.audienceId,
          ...options
        }
      );
    },
    mutations: () => ({
      create: useMutation(
        async (payload: CreateAudienceRequest) => {
          const response = await crmCampaignsClient.createAudience(payload);
          return response.data!;
        },
        {
          onSuccess: invalidateAudiencesQueries
        }
      ),

      update: useMutation(
        async (audience: UpdateAudienceByIdRequest) => {
          return await crmCampaignsClient.updateAudienceById({
            audienceId: audience.audienceId,
            audience: audience.audience
          });
        },
        {
          onSuccess: invalidateAudiencesQueries
        }
      ),

      delete: useMutation<void, HTTPError, string>(
        async (audienceId: string) => {
          await crmCampaignsClient.deleteAudienceById({ audienceId });
        },
        {
          onSuccess: invalidateAudiencesQueries
        }
      ),

      removeAudienceMember: useMutation(
        async (params: RemoveAudienceMemberRequest) => {
          await crmCampaignsClient.removeAudienceMember(params);
        },
        {
          onSuccess: invalidateAudiencesQueries
        }
      ),

      bulkAddRemoveAudienceMembers: useMutation(
        async (params: AddRemoveAudienceMembersBulkOperationRequest) => {
          await crmCampaignsClient.addRemoveAudienceMembersBulk(params);
        },
        {
          onSuccess: invalidateAudiencesQueries
        }
      )
    })
  };
};
