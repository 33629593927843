/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCompletionAnswer } from './AssessmentCompletionAnswer';
import {
    AssessmentCompletionAnswerFromJSON,
    AssessmentCompletionAnswerFromJSONTyped,
    AssessmentCompletionAnswerToJSON,
} from './AssessmentCompletionAnswer';

/**
 * 
 * @export
 * @interface PutResidentAssessmentAnswer200Response
 */
export interface PutResidentAssessmentAnswer200Response {
    /**
     * 
     * @type {AssessmentCompletionAnswer}
     * @memberof PutResidentAssessmentAnswer200Response
     */
    data?: AssessmentCompletionAnswer;
}

/**
 * Check if a given object implements the PutResidentAssessmentAnswer200Response interface.
 */
export function instanceOfPutResidentAssessmentAnswer200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PutResidentAssessmentAnswer200ResponseFromJSON(json: any): PutResidentAssessmentAnswer200Response {
    return PutResidentAssessmentAnswer200ResponseFromJSONTyped(json, false);
}

export function PutResidentAssessmentAnswer200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutResidentAssessmentAnswer200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : AssessmentCompletionAnswerFromJSON(json['data']),
    };
}

export function PutResidentAssessmentAnswer200ResponseToJSON(value?: PutResidentAssessmentAnswer200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AssessmentCompletionAnswerToJSON(value.data),
    };
}

