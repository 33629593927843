/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PdpmEstimatorComponentReferenceMeta
 */
export interface PdpmEstimatorComponentReferenceMeta {
    /**
     * 
     * @type {string}
     * @memberof PdpmEstimatorComponentReferenceMeta
     */
    component: string;
    /**
     * 
     * @type {string}
     * @memberof PdpmEstimatorComponentReferenceMeta
     */
    case_mix_group: string | null;
    /**
     * 
     * @type {number}
     * @memberof PdpmEstimatorComponentReferenceMeta
     */
    case_mix_index: number | null;
    /**
     * 
     * @type {number}
     * @memberof PdpmEstimatorComponentReferenceMeta
     */
    case_mix_daily_rate: number;
}

/**
 * Check if a given object implements the PdpmEstimatorComponentReferenceMeta interface.
 */
export function instanceOfPdpmEstimatorComponentReferenceMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "case_mix_group" in value;
    isInstance = isInstance && "case_mix_index" in value;
    isInstance = isInstance && "case_mix_daily_rate" in value;

    return isInstance;
}

export function PdpmEstimatorComponentReferenceMetaFromJSON(json: any): PdpmEstimatorComponentReferenceMeta {
    return PdpmEstimatorComponentReferenceMetaFromJSONTyped(json, false);
}

export function PdpmEstimatorComponentReferenceMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdpmEstimatorComponentReferenceMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': json['component'],
        'case_mix_group': json['case_mix_group'],
        'case_mix_index': json['case_mix_index'],
        'case_mix_daily_rate': json['case_mix_daily_rate'],
    };
}

export function PdpmEstimatorComponentReferenceMetaToJSON(value?: PdpmEstimatorComponentReferenceMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': value.component,
        'case_mix_group': value.case_mix_group,
        'case_mix_index': value.case_mix_index,
        'case_mix_daily_rate': value.case_mix_daily_rate,
    };
}

