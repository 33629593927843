/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepositStatus } from './DepositStatus';
import {
    DepositStatusFromJSON,
    DepositStatusFromJSONTyped,
    DepositStatusToJSON,
} from './DepositStatus';

/**
 * 
 * @export
 * @interface Deposit
 */
export interface Deposit {
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    resident_id: string;
    /**
     * 
     * @type {number}
     * @memberof Deposit
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    due_date: string;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    gl_code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Deposit
     */
    emailed?: boolean;
    /**
     * 
     * @type {DepositStatus}
     * @memberof Deposit
     */
    status?: DepositStatus;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    recorded_by_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    bill_owing_date: string;
    /**
     * 
     * @type {boolean}
     * @memberof Deposit
     */
    is_refundable: boolean;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    resident_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Deposit
     */
    createdAt?: string;
}

/**
 * Check if a given object implements the Deposit interface.
 */
export function instanceOfDeposit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "due_date" in value;
    isInstance = isInstance && "bill_owing_date" in value;
    isInstance = isInstance && "is_refundable" in value;

    return isInstance;
}

export function DepositFromJSON(json: any): Deposit {
    return DepositFromJSONTyped(json, false);
}

export function DepositFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deposit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resident_id': json['resident_id'],
        'amount': json['amount'],
        'name': json['name'],
        'due_date': json['due_date'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'emailed': !exists(json, 'emailed') ? undefined : json['emailed'],
        'status': !exists(json, 'status') ? undefined : DepositStatusFromJSON(json['status']),
        'recorded_by_user_id': !exists(json, 'recorded_by_user_id') ? undefined : json['recorded_by_user_id'],
        'bill_owing_date': json['bill_owing_date'],
        'is_refundable': json['is_refundable'],
        'resident_name': !exists(json, 'resident_name') ? undefined : json['resident_name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function DepositToJSON(value?: Deposit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'amount': value.amount,
        'name': value.name,
        'due_date': value.due_date,
        'gl_code': value.gl_code,
        'emailed': value.emailed,
        'status': DepositStatusToJSON(value.status),
        'recorded_by_user_id': value.recorded_by_user_id,
        'bill_owing_date': value.bill_owing_date,
        'is_refundable': value.is_refundable,
        'resident_name': value.resident_name,
        'createdAt': value.createdAt,
    };
}

