import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import postAccessUrl from '@/api/postAccessUrl';

export const AvatarS3PhotoKey: React.FC<
  Omit<AvatarProps, 'src'> & { s3PhotoKey?: string | null }
> = ({ s3PhotoKey, ...props }) => {
  const { data = [] } = useQuery(
    ['avatarS3getAccessUrl', { s3Url: s3PhotoKey }],
    () => postAccessUrl([s3PhotoKey!]),
    {
      enabled: !!s3PhotoKey
    }
  );

  return <Avatar src={data[0]?.url} {...props} />;
};
