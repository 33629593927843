/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentFormComment
 */
export interface IncidentFormComment {
    /**
     * 
     * @type {string}
     * @memberof IncidentFormComment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormComment
     */
    incident_form_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormComment
     */
    created_by_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentFormComment
     */
    comment: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFormComment
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFormComment
     */
    updated_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentFormComment
     */
    deleted_at?: Date;
}

/**
 * Check if a given object implements the IncidentFormComment interface.
 */
export function instanceOfIncidentFormComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "incident_form_id" in value;
    isInstance = isInstance && "created_by_id" in value;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function IncidentFormCommentFromJSON(json: any): IncidentFormComment {
    return IncidentFormCommentFromJSONTyped(json, false);
}

export function IncidentFormCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentFormComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'incident_form_id': json['incident_form_id'],
        'created_by_id': json['created_by_id'],
        'comment': json['comment'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
    };
}

export function IncidentFormCommentToJSON(value?: IncidentFormComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'incident_form_id': value.incident_form_id,
        'created_by_id': value.created_by_id,
        'comment': value.comment,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toISOString()),
    };
}

