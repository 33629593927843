/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicareNursingExtensiveServices = {
    TracheostomyCare14days: 'tracheostomy_care_14days',
    VentilatorRespirator14days: 'ventilator_respirator_14days',
    QuarantinedInfectiousDisease14days: 'quarantined_infectious_disease_14days'
} as const;
export type MedicareNursingExtensiveServices = typeof MedicareNursingExtensiveServices[keyof typeof MedicareNursingExtensiveServices];


export function MedicareNursingExtensiveServicesFromJSON(json: any): MedicareNursingExtensiveServices {
    return MedicareNursingExtensiveServicesFromJSONTyped(json, false);
}

export function MedicareNursingExtensiveServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareNursingExtensiveServices {
    return json as MedicareNursingExtensiveServices;
}

export function MedicareNursingExtensiveServicesToJSON(value?: MedicareNursingExtensiveServices | null): any {
    return value as any;
}

