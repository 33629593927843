import dayjs from 'dayjs';

import { SlidingScaleDoseRange } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { OVERDUE_TASKS_COPY } from '@/constants';
import {
  MedicationFollowupInstancePayload,
  TaskInstanceStatus
} from '@/hooks/useTaskInstancesQuery';
import { UserPayload } from '@/hooks/useUserQuery';

export class MARTaskInstanceModel {
  cancellation_reason: string | null;
  cancelled_by: UserPayload;
  completed: false;
  completed_by_user_id: string | null;
  createdAt: string;
  date: string;
  date_completed: string | null;
  day_of_month: number;
  documentation_s3_key: string | null;
  execution_end_time: string | null;
  execution_start_time: string | null;
  execution_window_end_time: string;
  execution_window_start_time: string;
  id: string;
  is_cancelled: boolean;
  is_deleted: boolean;
  is_modified: boolean;
  medication_task_id: string;
  medication_task_schedule_id: string;
  number_of_dose_units: number | null;
  physicians_name: string | null;
  reason: string | null;
  shift_id: string | null;
  signed_for: string | null;
  status: TaskInstanceStatus;
  updatedAt: string | null;
  user: UserPayload | null;
  user_full_name: string | null;
  user_initials: string | null;
  medication_task_followups?: MedicationFollowupInstancePayload[] | null;
  dose_range?: SlidingScaleDoseRange | null;

  constructor(payload: MARTaskInstanceModel) {
    Object.assign(this, payload);
  }

  public getPRNResult = (): string => {
    return this.medication_task_followups?.[0]?.result ?? 'N/A';
  };

  public getPRNResultRecordedBy = (): string => {
    const recordedByUser =
      this.medication_task_followups?.[0]?.completed_by_user;
    return recordedByUser
      ? `${recordedByUser.first_name} ${recordedByUser.last_name}`
      : 'N/A';
  };

  public getPrnCompletedTime = (): string =>
    dayjs(this.date_completed).format('h:mm a');

  public isException = (): boolean =>
    this.status === 'cancelled' ||
    this.status === 'overdue' ||
    this.is_modified;

  public getExceptionType = (): string => {
    if (this.status === 'overdue') {
      return OVERDUE_TASKS_COPY;
    }

    if (this.status === 'cancelled') {
      return 'cancelled';
    }

    if (this.is_modified === true) {
      return 'rescheduled';
    }

    return '';
  };

  public getExceptionReason = (): string | null => {
    if (this.status === 'cancelled') {
      return this.cancellation_reason || '—';
    }
    return '—';
  };

  public getExceptionUpdatedTime = (): string => {
    if (this.status === 'cancelled' || this.is_modified) {
      return dayjs(this.updatedAt).format('h:mm a');
    }

    return '—';
  };

  public getExceptionDate = (): string => dayjs(this.date).format('MM/DD/YYYY');

  public isFuture = (_MARDate: Date): boolean => {
    // For MARDate, the year and month should only matter as selected from the
    // date picker and the day of month is set to the first day. Since we're
    // doing date comparisons based on year/month/date, we should set the
    // smaller units of time to 0 to not interfere with edge cases
    const MARDate = dayjs(_MARDate).date(1).startOf('day');
    const today = dayjs().startOf('day');

    if (MARDate.year() < today.year() || MARDate.month() < today.month()) {
      return false;
    }

    // This is a special case reserved for calendar days in the schedule that
    // are over the selected month's number of calendar days. For example,
    // February most likely has 28 days but the UI will always show 31 days.
    // Days 29-31 aren't part of February. Search for "isOverDaysInMonth" in
    // MARSchedule.tsx
    if (this.day_of_month > today.daysInMonth()) {
      return false;
    }

    const todayAdjustedWithDayOfMonth = today.date(this.day_of_month);
    return todayAdjustedWithDayOfMonth.isAfter(today);
  };

  public getStaffName = (): string => this.user_full_name || '—';
}
