import React from 'react';
import { useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { Close } from '@mui/icons-material';
import { CircularProgress, Dialog, IconButton } from '@mui/material';
import dayjs from 'dayjs';

import { useSnackbar } from '@/hooks/useSnackbar';
import {
  CreateUserClockPayload,
  UserClockAction,
  useTimeClock
} from '@/hooks/useTimeClock';

import { ClockInDialogConfirm } from './ClockInDialogConfirm';
import { ClockInDialogPhoto } from './ClockInDialogPhoto';
import { ClockInDialogPin } from './ClockInDialogPin';
import { useMediaStream } from './useMediaStream';

interface ClockInProps extends InstanceProps<MediaStream> {
  action: UserClockAction;
}

const ClockInDialog: React.FC<ClockInProps> = ({
  onResolve,
  isOpen,
  action
}) => {
  const [hasPinError, setHasPinError] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const [dataUrl, setDataUrl] = React.useState<string | null>(null);
  const [step, setStep] = React.useState(0);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const mediaStream = useMediaStream();
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const formMethods = useForm<Pick<CreateUserClockPayload, 'pin'>>({
    mode: 'all',
    defaultValues: {
      pin: ''
    }
  });
  // This is used to show the time the user clocked in/out and to get the ISO
  // date string for the request payload timestamp. It should never
  // update after the initial render
  const [date] = React.useState(dayjs());

  const handleClose = () => {
    onResolve(mediaStream.stream);
  };

  React.useEffect(() => {
    // Don't check if its falsy because the hook may not have yet marked as such
    if (mediaStream.isStreamSupported === false) {
      setStep(2);
    }
  }, [mediaStream]);

  const handleTakePhoto = () => {
    // Inspo from https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos#capturing_a_frame_from_the_stream
    setStep(1);
    const context = canvasRef.current!.getContext('2d');
    const width = videoRef.current!.videoWidth;
    const height = videoRef.current!.videoHeight;
    canvasRef.current!.width = width;
    canvasRef.current!.height = height;
    context?.drawImage(videoRef.current!, 0, 0, width, height);
    const data = canvasRef.current!.toDataURL('image/png');
    setDataUrl(data);
  };

  const handleRetakePhoto = () => {
    setStep(0);
    setDataUrl(null);
  };

  const handleConfirmPhoto = () => {
    setStep(2);
  };

  const {
    invalidateClockStatus,
    invalidateShiftBlockPayableTotalsQuery,
    invalidateShiftBlockSummariesQuery,
    invalidateShiftBlockQuery,
    useCreateUserClockMutation
  } = useTimeClock();
  const createUserClockMutation = useCreateUserClockMutation({
    onSuccess: async () => {
      showSnackbar({
        severity: 'success',
        message: `Successfully clocked ${action} at ${date.format('h:mma')}`
      });
      handleClose();
      invalidateClockStatus();
      // Invalidating these are necessary for the Admin end user but is nice
      // to have in place for testing
      invalidateShiftBlockPayableTotalsQuery();
      invalidateShiftBlockQuery();
      invalidateShiftBlockSummariesQuery();
    },
    onError: async (e) => {
      const text = await e.response.text();
      setHasPinError(text.includes('pin'));
      showSnackbar({
        severity: 'error',
        message: `Error clocking ${action}. Please try again`
      });
    }
  });

  const handleConfirmClockIn = formMethods.handleSubmit(async ({ pin }) => {
    createUserClockMutation.mutate({
      dataUrl: dataUrl,
      date,
      pin,
      isClockIn: action === 'in'
    });
  });

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      keepMounted={false}
      PaperProps={{
        sx: {
          flexDirection: 'row',
          justifyContent: 'center',
          maxHeight: 'unset',
          maxWidth: 'unset',
          width: 614,
          height: 564,
          p: 4,
          boxSizing: 'border-box',
          borderRadius: '16px',
          boxShadow: 'box-shadow: 0px 5px 10px rgba(117, 136, 153, 0.1)'
        }
      }}>
      <IconButton
        size="medium"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 25,
          right: 22
        }}>
        <Close htmlColor="#9AAEBB" />
      </IconButton>

      {mediaStream.isLoading && <CircularProgress />}

      {!mediaStream.isLoading && (
        <>
          {/* The canvas tag is only meant to fetch a frame from the <video> src. 
          It should never be displayed in the DOM */}
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          {step === 0 && (
            <ClockInDialogPhoto
              videoRef={videoRef}
              handleTakePhoto={handleTakePhoto}
              action={action}
              mediaStream={mediaStream}
              date={date}
            />
          )}
          {step === 1 && (
            <ClockInDialogConfirm
              dataUrl={dataUrl}
              handleRetakePhoto={handleRetakePhoto}
              handleConfirmPhoto={handleConfirmPhoto}
              action={action}
              date={date}
            />
          )}
          {step === 2 && (
            <ClockInDialogPin
              formMethods={formMethods}
              handleRetakePhoto={handleRetakePhoto}
              handleConfirmClockIn={handleConfirmClockIn}
              createUserClockMutation={createUserClockMutation}
              hasPinError={hasPinError}
              action={action}
              mediaStream={mediaStream}
              date={date}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export const showClockInDialog = create<ClockInProps, MediaStream>(
  ClockInDialog
);
