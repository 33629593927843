import React, { useEffect, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Add } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import {
  BLOOD_PRESSURE_VITAL_ID,
  ORDERED_VITALS_BY_ID,
  VITAL_ID_TO_UNIT_MAP
} from '@/constants';
import { useVitalTypesQuery } from '@/hooks/useVitalsQuery';

import { ControlledSelectPrimitive } from '../ControlledSelectPrimitive';
import { ControlledTextField } from '../ControlledTextField';
import {
  BloodPressureType,
  SlidingScaleDoseRange
} from '../FrequencyTimeForm/FrequencyTimeForm';
interface GapInRange {
  from: string;
  to: string;
}
interface MedicationSlidingScaleDoseProps {
  formMethods: UseFormReturn;
  fieldPath: string;
  removeSlidingScaleDose: VoidFunction;
  disabled: boolean;
}

export default function MedicationSlidingScaleDose(
  props: MedicationSlidingScaleDoseProps
) {
  const {
    formMethods,
    fieldPath,
    removeSlidingScaleDose,
    disabled = false
  } = props;
  const [showGapsInRangesAlert, setShowGapsInRangesAlert] = useState(false);
  const [currentGaps, setCurrentGaps] = useState<GapInRange[]>([]);
  const { data: vitals } = useVitalTypesQuery().findAll({
    select: (vitals) =>
      vitals
        .filter((vital) => ORDERED_VITALS_BY_ID.includes(vital.id))
        .sort(
          (a, b) =>
            ORDERED_VITALS_BY_ID.indexOf(a.id) -
            ORDERED_VITALS_BY_ID.indexOf(b.id)
        )
  });

  const { fields, append, remove, update } = useFieldArray({
    control: formMethods.control,
    name: fieldPath
  });

  const [selectedVitalTypeId, setSelectedVitalTypeId] = useState<string>();
  const [selectedBloodPressureMeasure, setSelectedBloodPressureMeasure] =
    useState<BloodPressureType>();

  const handleVitalTypeChange = (value: string) => {
    setSelectedVitalTypeId(value);
  };

  const handleBloodPressureMeasureChange = (value: BloodPressureType) => {
    setSelectedBloodPressureMeasure(value);
  };

  const updateArrayFields = () => {
    // @ts-expect-error #HACK: Force fields re-render. If this didn't
    // exist, edits to these fields will be lost when updating
    update();
  };

  // Set the vital type ID in all the fields if the selected vital type ID changes
  useEffect(() => {
    if (selectedVitalTypeId) {
      fields.forEach((item, index) => {
        formMethods.setValue(
          `${fieldPath}.${index}.vital_type_id`,
          selectedVitalTypeId
        );
        if (selectedVitalTypeId !== BLOOD_PRESSURE_VITAL_ID) {
          formMethods.setValue(
            `${fieldPath}.${index}.blood_pressure_type`,
            undefined
          );
        }
      });
      updateArrayFields();
    }
  }, [selectedVitalTypeId]);

  // Set the blood pressure measure type in all the fields if the vital type selected is blood pressure
  useEffect(() => {
    if (selectedBloodPressureMeasure) {
      fields.forEach((item, index) => {
        formMethods.setValue(
          `${fieldPath}.${index}.blood_pressure_type`,
          selectedBloodPressureMeasure
        );
      });
      updateArrayFields();
    }
  }, [selectedBloodPressureMeasure]);

  // Set the default vital type ID if any range exists as well as the blood pressure measure type
  useEffect(() => {
    if (fields.length) {
      const defaultVitalField = (fields as SlidingScaleDoseRange[]).find(
        (field) => field.vital_type_id
      );
      if (defaultVitalField?.vital_type_id) {
        const matchingVital = vitals?.find(
          (vital) => vital.id === defaultVitalField.vital_type_id
        );
        if (matchingVital) setSelectedVitalTypeId(matchingVital.id);
        if (matchingVital?.id === BLOOD_PRESSURE_VITAL_ID) {
          setSelectedBloodPressureMeasure(
            defaultVitalField?.blood_pressure_type || undefined
          );
        }
      }
    }
  }, []);

  function doRangesOverlap(ranges: any) {
    for (let i = 0; i < ranges.length; i++) {
      for (let j = i + 1; j < ranges.length; j++) {
        const range1 = ranges[i];
        const range2 = ranges[j];
        if (
          isNaN(parseFloat(range1.to)) ||
          isNaN(parseFloat(range1.from)) ||
          isNaN(parseFloat(range2.to)) ||
          isNaN(parseFloat(range2.from))
        ) {
          continue;
        }
        if (
          parseFloat(range1.from) <= parseFloat(range2.to) &&
          parseFloat(range1.to) >= parseFloat(range2.from)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const findGapsInRanges = (ranges: any) => {
    const sortedRanges = [...ranges].sort(
      (a, b) => parseFloat(a.from) - parseFloat(b.from)
    );
    const gaps = [];
    for (let i = 1; i < sortedRanges.length; i++) {
      const currentRange = sortedRanges[i];
      const previousRange = sortedRanges[i - 1];
      const currentTo = parseFloat(currentRange.from);
      const previousTo = parseFloat(previousRange.to);
      if (currentTo > previousTo + 0.0001) {
        gaps.push({
          from: (previousTo + 0.0001).toFixed(4),
          to: (currentTo - 0.0001).toFixed(4)
        });
      }
    }
    return gaps;
  };

  const getValidateRules = ({
    fromFieldPath,
    toFieldPath
  }: {
    fromFieldPath?: string;
    toFieldPath?: string;
  }) => ({
    isNotANumber: (value: string) => {
      if (isNaN(parseFloat(value))) {
        return 'Enter a valid number';
      }
      return true;
    },
    rangeWithSameValues: (value: string) => {
      const numericFromValue = fromFieldPath
        ? parseFloat(formMethods.getValues(fromFieldPath))
        : parseFloat(value);
      if (isNaN(numericFromValue)) return true;
      const numericToValue = toFieldPath
        ? parseFloat(formMethods.getValues(toFieldPath))
        : parseFloat(value);
      if (isNaN(numericToValue)) return true;
      if (numericFromValue === numericToValue) {
        return 'To and From have the same value';
      }
      return true;
    },
    fromGreaterThanTo: (value: string) => {
      const numericFromValue = fromFieldPath
        ? parseFloat(formMethods.getValues(fromFieldPath))
        : parseFloat(value);
      if (isNaN(numericFromValue)) return true;
      const numericToValue = toFieldPath
        ? parseFloat(formMethods.getValues(toFieldPath))
        : parseFloat(value);
      if (isNaN(numericToValue)) return true;
      if (numericFromValue > numericToValue) {
        return 'From value greater than To value';
      }
      return true;
    },
    overlapsWithRange: () => {
      if (doRangesOverlap(fields)) {
        return 'Overlapping ranges';
      }
      return true;
    },
    gapsInRanges: () => {
      const gaps = findGapsInRanges(fields);
      if (gaps.length) {
        setShowGapsInRangesAlert(true);
        setCurrentGaps(gaps);
        return true;
      }
      setShowGapsInRangesAlert(false);
      setCurrentGaps([]);
      return true;
    }
  });

  useEffect(() => {
    const gaps = findGapsInRanges(fields);
    if (gaps.length) {
      setShowGapsInRangesAlert(true);
      setCurrentGaps(gaps);
    }
  }, []);

  return (
    <>
      <Stack m={1} gap={2}>
        {showGapsInRangesAlert && (
          <Grid xs={12}>
            <Alert
              severity="warning"
              sx={{ height: '100%', alignItems: 'center' }}>
              <Stack>
                <Box fontSize="16px" fontWeight={500}>
                  Be Aware!
                </Box>
                <Box>
                  The ranges entered have gap(s) between them. Users will be
                  blocked from recording doses if the respective vital is
                  between:{' '}
                  <Typography
                    data-testid="gaps-in-ranges-text"
                    variant="inherit"
                    component="span"
                    fontWeight={500}>
                    {currentGaps
                      .map((gap) => `${gap.from} - ${gap.to}`)
                      .join(', ')}
                  </Typography>
                </Box>
              </Stack>
            </Alert>
          </Grid>
        )}
        {fields.map((range, index) => (
          <React.Fragment key={range.id}>
            {index === 0 && (
              <>
                <Stack direction="row" gap={1}>
                  <ControlledSelectPrimitive
                    label="Vital Type"
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.vital_type_id`}
                    rules={{ required: true }}
                    options={vitals}
                    optionLabelKey="name"
                    optionIdKey="id"
                    hideNA
                    onChange={(value) => handleVitalTypeChange(value as string)}
                    disabled={disabled}
                  />
                  {!disabled && (
                    <IconButton onClick={removeSlidingScaleDose} size="small">
                      <DeleteIcon htmlColor="#9AAEBB" />
                    </IconButton>
                  )}
                </Stack>
                {selectedVitalTypeId === BLOOD_PRESSURE_VITAL_ID && (
                  <ControlledSelectPrimitive
                    label="Blood Pressure Measure Type"
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.blood_pressure_type`}
                    rules={{ required: true }}
                    options={Object.values(BloodPressureType) as string[]}
                    hideNA
                    onChange={(value) =>
                      handleBloodPressureMeasureChange(
                        value as BloodPressureType
                      )
                    }
                    disabled={disabled}
                  />
                )}
              </>
            )}
            <Stack direction="row" gap={1}>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <ControlledTextField
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.from`}
                    label={`From ${
                      selectedVitalTypeId
                        ? `(${
                            VITAL_ID_TO_UNIT_MAP[selectedVitalTypeId as string]
                          })`
                        : ''
                    }`}
                    type="number"
                    maxDecimalPlaces={4}
                    InputLabelProps={{ shrink: true }}
                    onChange={updateArrayFields}
                    rules={{
                      required: true,
                      validate: getValidateRules({
                        toFieldPath: `${fieldPath}.${index}.to`
                      })
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={4}>
                  <ControlledTextField
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.to`}
                    label={`To ${
                      selectedVitalTypeId
                        ? `(${
                            VITAL_ID_TO_UNIT_MAP[selectedVitalTypeId as string]
                          })`
                        : ''
                    }`}
                    type="number"
                    maxDecimalPlaces={4}
                    InputLabelProps={{ shrink: true }}
                    onChange={updateArrayFields}
                    rules={{
                      required: true,
                      validate: getValidateRules({
                        fromFieldPath: `${fieldPath}.${index}.from`
                      })
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={4}>
                  <ControlledTextField
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.number_of_dose_units`}
                    label="Doses"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    onChange={updateArrayFields}
                    rules={{
                      required: true,
                      validate: {
                        isNotANumber: (value) => {
                          if (isNaN(parseFloat(value))) {
                            return 'Enter a valid number';
                          }
                          return true;
                        }
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={12}>
                  <ControlledTextField
                    control={formMethods.control}
                    name={`${fieldPath}.${index}.comment`}
                    label="Comment"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    onChange={updateArrayFields}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              {fields.length > 1 && !disabled && (
                <IconButton
                  data-testid="remove-range-button"
                  onClick={() => remove(index)}
                  size="small">
                  <Close htmlColor="#9AAEBB" />
                </IconButton>
              )}
            </Stack>
          </React.Fragment>
        ))}
        {!disabled && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<Add />}
            onClick={() => {
              append({
                vital_type_id: selectedVitalTypeId,
                blood_pressure_type: selectedBloodPressureMeasure,
                comment: ''
              });
            }}
            sx={{ width: 'max-content' }}>
            Add Range
          </Button>
        )}
      </Stack>
    </>
  );
}
