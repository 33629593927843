/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualUpdateBillItemRequest
 */
export interface ManualUpdateBillItemRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ManualUpdateBillItemRequest
     */
    discount_ids: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManualUpdateBillItemRequest
     */
    tax_ids: Array<string>;
}

/**
 * Check if a given object implements the ManualUpdateBillItemRequest interface.
 */
export function instanceOfManualUpdateBillItemRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "discount_ids" in value;
    isInstance = isInstance && "tax_ids" in value;

    return isInstance;
}

export function ManualUpdateBillItemRequestFromJSON(json: any): ManualUpdateBillItemRequest {
    return ManualUpdateBillItemRequestFromJSONTyped(json, false);
}

export function ManualUpdateBillItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualUpdateBillItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discount_ids': json['discount_ids'],
        'tax_ids': json['tax_ids'],
    };
}

export function ManualUpdateBillItemRequestToJSON(value?: ManualUpdateBillItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discount_ids': value.discount_ids,
        'tax_ids': value.tax_ids,
    };
}

