import React, { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useLocation } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import theme from '@/theme';

import { SearchBar } from '../ui/Searchbar/SearchBar';

import ResidentsTabs from './ResidentsTabs';

type ResidentGlobalSearchDialogProps = InstanceProps<unknown>;

const ResidentGlobalSearchDialog: React.FC<ResidentGlobalSearchDialogProps> = ({
  onResolve,
  isOpen
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const isCRM = location.pathname.startsWith('/crm');
  const isMobileViewport = useIsMobileViewport();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onResolve}
      fullScreen={isMobileViewport}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.25)' }
        }
      }}>
      <DialogTitle alignItems="flex-start !important">
        <Stack alignItems="center" width={1} gap={1}>
          <Avatar
            sx={{
              width: '60px',
              height: '60px',
              backgroundColor: theme.palette.primary.main
            }}>
            <PersonSearchIcon fontSize="large" />
          </Avatar>
          <Typography color="inherit" fontWeight={500}>
            {isCRM ? 'Search Prospective Residents' : 'Search Residents'}
          </Typography>
        </Stack>
        <IconButton
          onClick={onResolve}
          sx={{ position: 'absolute', top: 12, right: 12 }}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: { xs: '100%', md: 480 } }}>
        <SearchBar
          autoFocusDependencies={[currentTab]}
          autoFocus={true}
          sx={{ width: 1 }}
          searchQuery={searchQuery}
          popoverBreakpoint="xs"
          setSearchQuery={(searchValue) => setSearchQuery(searchValue)}
        />
        <Stack width={1} height={350}>
          <ResidentsTabs
            onResolve={onResolve}
            handleTabChange={handleTabChange}
            currentTab={currentTab}
            searchQuery={searchQuery}
            isCRM={isCRM}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const showResidentGlobalSearchDialog =
  create<ResidentGlobalSearchDialogProps>(ResidentGlobalSearchDialog);
