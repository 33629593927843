/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillTotals
 */
export interface BillTotals {
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    totalOutstanding: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    pendingTotalOutstanding: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    originalTotal: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    rentTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    baseCareTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    carePackagesTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    otherRecurringTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BillTotals
     */
    oneTimeTotal?: number;
}

/**
 * Check if a given object implements the BillTotals interface.
 */
export function instanceOfBillTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalOutstanding" in value;
    isInstance = isInstance && "pendingTotalOutstanding" in value;
    isInstance = isInstance && "originalTotal" in value;

    return isInstance;
}

export function BillTotalsFromJSON(json: any): BillTotals {
    return BillTotalsFromJSONTyped(json, false);
}

export function BillTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalOutstanding': json['totalOutstanding'],
        'pendingTotalOutstanding': json['pendingTotalOutstanding'],
        'originalTotal': json['originalTotal'],
        'rentTotal': !exists(json, 'rentTotal') ? undefined : json['rentTotal'],
        'baseCareTotal': !exists(json, 'baseCareTotal') ? undefined : json['baseCareTotal'],
        'carePackagesTotal': !exists(json, 'carePackagesTotal') ? undefined : json['carePackagesTotal'],
        'otherRecurringTotal': !exists(json, 'otherRecurringTotal') ? undefined : json['otherRecurringTotal'],
        'oneTimeTotal': !exists(json, 'oneTimeTotal') ? undefined : json['oneTimeTotal'],
    };
}

export function BillTotalsToJSON(value?: BillTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalOutstanding': value.totalOutstanding,
        'pendingTotalOutstanding': value.pendingTotalOutstanding,
        'originalTotal': value.originalTotal,
        'rentTotal': value.rentTotal,
        'baseCareTotal': value.baseCareTotal,
        'carePackagesTotal': value.carePackagesTotal,
        'otherRecurringTotal': value.otherRecurringTotal,
        'oneTimeTotal': value.oneTimeTotal,
    };
}

