import React from 'react';
import { Stack } from '@mui/material';

import GridToolbar from '@/components/GridToolbar';
import { GridToolbarFilterButton } from '@/components/GridToolbarFilterButton';
import { GridToolbarQuickFilter } from '@/components/GridToolbarQuickFilter';
import { PrintButton } from '@/components/PrintButton';

interface MedicationInventoryHistoryToolbarProps {
  isLoading: boolean;
  handlePrintMostRecent: () => Promise<void>;
}

const MedicationInventoryHistoryToolbar: React.FC<
  MedicationInventoryHistoryToolbarProps
> = ({ isLoading, handlePrintMostRecent }) => {
  return (
    <GridToolbar
      sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
      <GridToolbarQuickFilter disabled={isLoading} />
      <Stack direction="row" gap={2}>
        <GridToolbarFilterButton />
        <PrintButton
          ButtonProps={{
            onClick: () => {
              handlePrintMostRecent();
            }
          }}>
          Print
        </PrintButton>
      </Stack>
    </GridToolbar>
  );
};

export default MedicationInventoryHistoryToolbar;
