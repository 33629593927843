import React from 'react';
import { BackHand } from '@mui/icons-material';
import { Chip, styled, Tooltip } from '@mui/material';

import { MARMedicationModel } from '@/components/MAR/models/MARMedicationModel';
import { MedicationTaskInstanceModel } from '@/models/MedicationTaskInstanceModel';
import { MedicationTaskModel } from '@/models/MedicationTaskModel';

import { AcetaminophenLimitIndicator } from './AcetaminophenLimitIndicator';
import { SlidingScaleDoseIndicator } from './SlidingScaleDoseIndicator';
import TaskExtensionIndicator from './TaskExtensionIndicator';
interface MedicationIndicatorProps {
  model: MedicationTaskInstanceModel | MedicationTaskModel | MARMedicationModel;
  showTooltip?: boolean;
  abbreviated?: boolean;
}

export const MedicationIndicatorChip = styled(Chip, {
  shouldForwardProp: (prop) => !['type'].includes(prop as string)
})<{
  type?: 'controlled-substance' | 'new';
}>`
  color: #fcfeff;
  background-color: ${({ type, theme }) =>
    type === 'controlled-substance'
      ? '#364955'
      : type === 'new'
      ? theme.palette.success.light
      : 'none'};
`;

export const MedicationIndicators: React.FC<MedicationIndicatorProps> = ({
  model,
  showTooltip = false,
  abbreviated = false
}) => {
  const medicationWarning =
    model instanceof MedicationTaskModel ? model.getMedicationWarning() : null;

  return (
    <>
      {model.is_new && (
        <Tooltip
          title={
            showTooltip &&
            model instanceof MedicationTaskModel &&
            model.getNewTooltipTitle()
          }>
          <MedicationIndicatorChip type="new" size="small" label="New" />
        </Tooltip>
      )}
      {model.isControlledSubstance() && (
        <Tooltip title={showTooltip ? 'Controlled Substance' : ''}>
          <MedicationIndicatorChip
            type="controlled-substance"
            size="small"
            label={abbreviated ? 'CS' : 'Controlled Substance'}
          />
        </Tooltip>
      )}
      {model instanceof MedicationTaskInstanceModel &&
        model.status !== 'completed' &&
        model.includesIngredient('acetaminophen') && (
          <AcetaminophenLimitIndicator model={model} />
        )}
      {model instanceof MedicationTaskModel &&
        model.isActive &&
        model.isPrn &&
        model.acetaminophen_24_hours !== undefined && (
          <AcetaminophenLimitIndicator model={model} />
        )}
      {(model instanceof MedicationTaskInstanceModel ||
        model instanceof MedicationTaskModel) && (
        <SlidingScaleDoseIndicator model={model} />
      )}
      {(model instanceof MedicationTaskInstanceModel ||
        model instanceof MedicationTaskModel) && (
        <TaskExtensionIndicator model={model} />
      )}
      {medicationWarning && (
        <>
          {
            <Tooltip
              PopperProps={{
                className: 'white-space-pre-wrap'
              }}
              title={medicationWarning.text}>
              <BackHand color={medicationWarning.color} />
            </Tooltip>
          }
        </>
      )}
    </>
  );
  [];
};
