/* tslint:disable */
/* eslint-disable */
/**
 * crm
 * crm
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentPreArrivalListEntry } from './ResidentPreArrivalListEntry';
import {
    ResidentPreArrivalListEntryFromJSON,
    ResidentPreArrivalListEntryFromJSONTyped,
    ResidentPreArrivalListEntryToJSON,
} from './ResidentPreArrivalListEntry';

/**
 * 
 * @export
 * @interface ResidentPreArrivalFindAllResponse
 */
export interface ResidentPreArrivalFindAllResponse {
    /**
     * 
     * @type {number}
     * @memberof ResidentPreArrivalFindAllResponse
     */
    count: number;
    /**
     * 
     * @type {Array<ResidentPreArrivalListEntry>}
     * @memberof ResidentPreArrivalFindAllResponse
     */
    rows: Array<ResidentPreArrivalListEntry>;
}

/**
 * Check if a given object implements the ResidentPreArrivalFindAllResponse interface.
 */
export function instanceOfResidentPreArrivalFindAllResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "rows" in value;

    return isInstance;
}

export function ResidentPreArrivalFindAllResponseFromJSON(json: any): ResidentPreArrivalFindAllResponse {
    return ResidentPreArrivalFindAllResponseFromJSONTyped(json, false);
}

export function ResidentPreArrivalFindAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentPreArrivalFindAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'rows': ((json['rows'] as Array<any>).map(ResidentPreArrivalListEntryFromJSON)),
    };
}

export function ResidentPreArrivalFindAllResponseToJSON(value?: ResidentPreArrivalFindAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'rows': ((value.rows as Array<any>).map(ResidentPreArrivalListEntryToJSON)),
    };
}

