/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AudienceType = {
    Static: 'static',
    Dynamic: 'dynamic'
} as const;
export type AudienceType = typeof AudienceType[keyof typeof AudienceType];


export function AudienceTypeFromJSON(json: any): AudienceType {
    return AudienceTypeFromJSONTyped(json, false);
}

export function AudienceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudienceType {
    return json as AudienceType;
}

export function AudienceTypeToJSON(value?: AudienceType | null): any {
    return value as any;
}

