import React from 'react';

interface RequiredFormFieldLabelProps {
  label: string;
}

const RequiredFormFieldLabel: React.FC<RequiredFormFieldLabelProps> = ({
  label
}) => {
  return (
    <span>
      {label}
      <span style={{ color: '#1DB8F2' }}>*</span>
    </span>
  );
};

export default RequiredFormFieldLabel;
