/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CampaignStep } from './CampaignStep';
import {
    CampaignStepFromJSON,
    CampaignStepFromJSONTyped,
    CampaignStepToJSON,
} from './CampaignStep';

/**
 * 
 * @export
 * @interface CreateCampaignStep201Response
 */
export interface CreateCampaignStep201Response {
    /**
     * 
     * @type {CampaignStep}
     * @memberof CreateCampaignStep201Response
     */
    data?: CampaignStep;
}

/**
 * Check if a given object implements the CreateCampaignStep201Response interface.
 */
export function instanceOfCreateCampaignStep201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCampaignStep201ResponseFromJSON(json: any): CreateCampaignStep201Response {
    return CreateCampaignStep201ResponseFromJSONTyped(json, false);
}

export function CreateCampaignStep201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCampaignStep201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CampaignStepFromJSON(json['data']),
    };
}

export function CreateCampaignStep201ResponseToJSON(value?: CreateCampaignStep201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CampaignStepToJSON(value.data),
    };
}

