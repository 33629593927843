/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * 
 * @export
 * @interface Statement
 */
export interface Statement {
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    resident_id: string;
    /**
     * 
     * @type {Month}
     * @memberof Statement
     */
    month: Month;
    /**
     * 
     * @type {number}
     * @memberof Statement
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof Statement
     */
    starting_balance: number;
    /**
     * 
     * @type {number}
     * @memberof Statement
     */
    ending_balance: number;
    /**
     * 
     * @type {boolean}
     * @memberof Statement
     */
    emailed: boolean;
}

/**
 * Check if a given object implements the Statement interface.
 */
export function instanceOfStatement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "starting_balance" in value;
    isInstance = isInstance && "ending_balance" in value;
    isInstance = isInstance && "emailed" in value;

    return isInstance;
}

export function StatementFromJSON(json: any): Statement {
    return StatementFromJSONTyped(json, false);
}

export function StatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Statement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'resident_id': json['resident_id'],
        'month': MonthFromJSON(json['month']),
        'year': json['year'],
        'starting_balance': json['starting_balance'],
        'ending_balance': json['ending_balance'],
        'emailed': json['emailed'],
    };
}

export function StatementToJSON(value?: Statement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'resident_id': value.resident_id,
        'month': MonthToJSON(value.month),
        'year': value.year,
        'starting_balance': value.starting_balance,
        'ending_balance': value.ending_balance,
        'emailed': value.emailed,
    };
}

