/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NgRepositoryEntry } from './NgRepositoryEntry';
import {
    NgRepositoryEntryFromJSON,
    NgRepositoryEntryFromJSONTyped,
    NgRepositoryEntryToJSON,
} from './NgRepositoryEntry';

/**
 * 
 * @export
 * @interface FindAllNgRepositoryEntries200Response
 */
export interface FindAllNgRepositoryEntries200Response {
    /**
     * 
     * @type {Array<NgRepositoryEntry>}
     * @memberof FindAllNgRepositoryEntries200Response
     */
    data?: Array<NgRepositoryEntry>;
}

/**
 * Check if a given object implements the FindAllNgRepositoryEntries200Response interface.
 */
export function instanceOfFindAllNgRepositoryEntries200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllNgRepositoryEntries200ResponseFromJSON(json: any): FindAllNgRepositoryEntries200Response {
    return FindAllNgRepositoryEntries200ResponseFromJSONTyped(json, false);
}

export function FindAllNgRepositoryEntries200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllNgRepositoryEntries200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(NgRepositoryEntryFromJSON)),
    };
}

export function FindAllNgRepositoryEntries200ResponseToJSON(value?: FindAllNgRepositoryEntries200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(NgRepositoryEntryToJSON)),
    };
}

