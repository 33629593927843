/* tslint:disable */
/* eslint-disable */
/**
 * users
 * users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Facility } from './Facility';
import {
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
} from './Facility';

/**
 * 
 * @export
 * @interface UserFacilityAccess
 */
export interface UserFacilityAccess {
    /**
     * 
     * @type {string}
     * @memberof UserFacilityAccess
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserFacilityAccess
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof UserFacilityAccess
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof UserFacilityAccess
     */
    granted_by_user_id?: string;
    /**
     * 
     * @type {Facility}
     * @memberof UserFacilityAccess
     */
    facility: Facility;
    /**
     * 
     * @type {string}
     * @memberof UserFacilityAccess
     */
    granted_by_user_name?: string;
}

/**
 * Check if a given object implements the UserFacilityAccess interface.
 */
export function instanceOfUserFacilityAccess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "facility" in value;

    return isInstance;
}

export function UserFacilityAccessFromJSON(json: any): UserFacilityAccess {
    return UserFacilityAccessFromJSONTyped(json, false);
}

export function UserFacilityAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFacilityAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'user_id': json['user_id'],
        'granted_by_user_id': !exists(json, 'granted_by_user_id') ? undefined : json['granted_by_user_id'],
        'facility': FacilityFromJSON(json['facility']),
        'granted_by_user_name': !exists(json, 'granted_by_user_name') ? undefined : json['granted_by_user_name'],
    };
}

export function UserFacilityAccessToJSON(value?: UserFacilityAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'user_id': value.user_id,
        'granted_by_user_id': value.granted_by_user_id,
        'facility': FacilityToJSON(value.facility),
        'granted_by_user_name': value.granted_by_user_name,
    };
}

