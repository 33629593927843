/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionOrderFindOneResponse } from './PrescriptionOrderFindOneResponse';
import {
    PrescriptionOrderFindOneResponseFromJSON,
    PrescriptionOrderFindOneResponseFromJSONTyped,
    PrescriptionOrderFindOneResponseToJSON,
} from './PrescriptionOrderFindOneResponse';

/**
 * 
 * @export
 * @interface FindOnePrescriptionOrder200Response
 */
export interface FindOnePrescriptionOrder200Response {
    /**
     * 
     * @type {PrescriptionOrderFindOneResponse}
     * @memberof FindOnePrescriptionOrder200Response
     */
    data?: PrescriptionOrderFindOneResponse;
}

/**
 * Check if a given object implements the FindOnePrescriptionOrder200Response interface.
 */
export function instanceOfFindOnePrescriptionOrder200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOnePrescriptionOrder200ResponseFromJSON(json: any): FindOnePrescriptionOrder200Response {
    return FindOnePrescriptionOrder200ResponseFromJSONTyped(json, false);
}

export function FindOnePrescriptionOrder200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOnePrescriptionOrder200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : PrescriptionOrderFindOneResponseFromJSON(json['data']),
    };
}

export function FindOnePrescriptionOrder200ResponseToJSON(value?: FindOnePrescriptionOrder200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PrescriptionOrderFindOneResponseToJSON(value.data),
    };
}

