/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CountInformationalCPE200Response,
  FindAllInformationalCPE200Response,
  FindAllInstanceHistory200Response,
  RecordUnscheduledCare201Response,
  RecordUnscheduledCare400Response,
  RecordUnscheduledCareRequest,
  UpdateCompletionTimeRequest,
} from '../models/index';
import {
    CountInformationalCPE200ResponseFromJSON,
    CountInformationalCPE200ResponseToJSON,
    FindAllInformationalCPE200ResponseFromJSON,
    FindAllInformationalCPE200ResponseToJSON,
    FindAllInstanceHistory200ResponseFromJSON,
    FindAllInstanceHistory200ResponseToJSON,
    RecordUnscheduledCare201ResponseFromJSON,
    RecordUnscheduledCare201ResponseToJSON,
    RecordUnscheduledCare400ResponseFromJSON,
    RecordUnscheduledCare400ResponseToJSON,
    RecordUnscheduledCareRequestFromJSON,
    RecordUnscheduledCareRequestToJSON,
    UpdateCompletionTimeRequestFromJSON,
    UpdateCompletionTimeRequestToJSON,
} from '../models/index';

export interface CountInformationalCPERequest {
    id: string;
}

export interface DeleteCarePlanTaskInstanceRequest {
    id: string;
}

export interface DeleteUnscheduledCareTaskInstanceRequest {
    entryId: string;
    taskInstanceId: string;
}

export interface FindAllInformationalCPERequest {
    id: string;
}

export interface FindAllInstanceHistoryRequest {
    id: string;
}

export interface MarkInstanceIncompleteRequest {
    id: string;
}

export interface RecordUnscheduledCareOperationRequest {
    recordUnscheduledCareRequest: RecordUnscheduledCareRequest;
}

export interface UpdateInstanceCompletionTimeRequest {
    id: string;
    updateCompletionTimeRequest: UpdateCompletionTimeRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Count All Informational Care Plan information for a resident
     */
    async countInformationalCPERaw(requestParameters: CountInformationalCPERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountInformationalCPE200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling countInformationalCPE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{id}/care-plan-entries/informational/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountInformationalCPE200ResponseFromJSON(jsonValue));
    }

    /**
     * Count All Informational Care Plan information for a resident
     */
    async countInformationalCPE(requestParameters: CountInformationalCPERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountInformationalCPE200Response> {
        const response = await this.countInformationalCPERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete care plan task instance
     */
    async deleteCarePlanTaskInstanceRaw(requestParameters: DeleteCarePlanTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCarePlanTaskInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-plan-task-instances/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete care plan task instance
     */
    async deleteCarePlanTaskInstance(requestParameters: DeleteCarePlanTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarePlanTaskInstanceRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a task instance from an unscheduled care entry; if entry has no task instances, the entry is deleted as well
     */
    async deleteUnscheduledCareTaskInstanceRaw(requestParameters: DeleteUnscheduledCareTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entryId === null || requestParameters.entryId === undefined) {
            throw new runtime.RequiredError('entryId','Required parameter requestParameters.entryId was null or undefined when calling deleteUnscheduledCareTaskInstance.');
        }

        if (requestParameters.taskInstanceId === null || requestParameters.taskInstanceId === undefined) {
            throw new runtime.RequiredError('taskInstanceId','Required parameter requestParameters.taskInstanceId was null or undefined when calling deleteUnscheduledCareTaskInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/unscheduled-care-entries/{entry_id}/task-instances/{task_instance_id}`.replace(`{${"entry_id"}}`, encodeURIComponent(String(requestParameters.entryId))).replace(`{${"task_instance_id"}}`, encodeURIComponent(String(requestParameters.taskInstanceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a task instance from an unscheduled care entry; if entry has no task instances, the entry is deleted as well
     */
    async deleteUnscheduledCareTaskInstance(requestParameters: DeleteUnscheduledCareTaskInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUnscheduledCareTaskInstanceRaw(requestParameters, initOverrides);
    }

    /**
     * Find All Informational Care Plan information for a resident
     */
    async findAllInformationalCPERaw(requestParameters: FindAllInformationalCPERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllInformationalCPE200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllInformationalCPE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/residents/{id}/care-plan-entries/informational`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllInformationalCPE200ResponseFromJSON(jsonValue));
    }

    /**
     * Find All Informational Care Plan information for a resident
     */
    async findAllInformationalCPE(requestParameters: FindAllInformationalCPERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllInformationalCPE200Response> {
        const response = await this.findAllInformationalCPERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medication Task Instance History
     */
    async findAllInstanceHistoryRaw(requestParameters: FindAllInstanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllInstanceHistory200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findAllInstanceHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-plan-task-instances/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllInstanceHistory200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medication Task Instance History
     */
    async findAllInstanceHistory(requestParameters: FindAllInstanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllInstanceHistory200Response> {
        const response = await this.findAllInstanceHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark Care Plan Task Instance Incomplete
     */
    async markInstanceIncompleteRaw(requestParameters: MarkInstanceIncompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markInstanceIncomplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/care-plan-task-instances/{id}/mark-incomplete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark Care Plan Task Instance Incomplete
     */
    async markInstanceIncomplete(requestParameters: MarkInstanceIncompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInstanceIncompleteRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a new CPE with PRN schedule and precreates a completed task instance
     */
    async recordUnscheduledCareRaw(requestParameters: RecordUnscheduledCareOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordUnscheduledCare201Response>> {
        if (requestParameters.recordUnscheduledCareRequest === null || requestParameters.recordUnscheduledCareRequest === undefined) {
            throw new runtime.RequiredError('recordUnscheduledCareRequest','Required parameter requestParameters.recordUnscheduledCareRequest was null or undefined when calling recordUnscheduledCare.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/unscheduled-care-entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordUnscheduledCareRequestToJSON(requestParameters.recordUnscheduledCareRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordUnscheduledCare201ResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new CPE with PRN schedule and precreates a completed task instance
     */
    async recordUnscheduledCare(requestParameters: RecordUnscheduledCareOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordUnscheduledCare201Response> {
        const response = await this.recordUnscheduledCareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Care Plan Task Instance Completion Time
     */
    async updateInstanceCompletionTimeRaw(requestParameters: UpdateInstanceCompletionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInstanceCompletionTime.');
        }

        if (requestParameters.updateCompletionTimeRequest === null || requestParameters.updateCompletionTimeRequest === undefined) {
            throw new runtime.RequiredError('updateCompletionTimeRequest','Required parameter requestParameters.updateCompletionTimeRequest was null or undefined when calling updateInstanceCompletionTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/care-plan-task-instances/{id}/completion-time`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompletionTimeRequestToJSON(requestParameters.updateCompletionTimeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Care Plan Task Instance Completion Time
     */
    async updateInstanceCompletionTime(requestParameters: UpdateInstanceCompletionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInstanceCompletionTimeRaw(requestParameters, initOverrides);
    }

}
