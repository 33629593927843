/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountType } from './DiscountType';
import {
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
} from './DiscountType';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    gl_code?: string;
    /**
     * 
     * @type {DiscountType}
     * @memberof Discount
     */
    type: DiscountType;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    original_discount_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    date_modified?: string;
    /**
     * 
     * @type {User}
     * @memberof Discount
     */
    updated_by?: User;
}

/**
 * Check if a given object implements the Discount interface.
 */
export function instanceOfDiscount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DiscountFromJSON(json: any): Discount {
    return DiscountFromJSONTyped(json, false);
}

export function DiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'value': json['value'],
        'gl_code': !exists(json, 'gl_code') ? undefined : json['gl_code'],
        'type': DiscountTypeFromJSON(json['type']),
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'original_discount_id': !exists(json, 'original_discount_id') ? undefined : json['original_discount_id'],
        'date_modified': !exists(json, 'date_modified') ? undefined : json['date_modified'],
        'updated_by': !exists(json, 'updated_by') ? undefined : UserFromJSON(json['updated_by']),
    };
}

export function DiscountToJSON(value?: Discount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'value': value.value,
        'gl_code': value.gl_code,
        'type': DiscountTypeToJSON(value.type),
        'category_id': value.category_id,
        'original_discount_id': value.original_discount_id,
        'date_modified': value.date_modified,
        'updated_by': UserToJSON(value.updated_by),
    };
}

