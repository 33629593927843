/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilityBillingInfo } from './FacilityBillingInfo';
import {
    FacilityBillingInfoFromJSON,
    FacilityBillingInfoFromJSONTyped,
    FacilityBillingInfoToJSON,
} from './FacilityBillingInfo';

/**
 * 
 * @export
 * @interface FindOneFacilityBillingInfo200Response
 */
export interface FindOneFacilityBillingInfo200Response {
    /**
     * 
     * @type {FacilityBillingInfo}
     * @memberof FindOneFacilityBillingInfo200Response
     */
    data?: FacilityBillingInfo;
}

/**
 * Check if a given object implements the FindOneFacilityBillingInfo200Response interface.
 */
export function instanceOfFindOneFacilityBillingInfo200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOneFacilityBillingInfo200ResponseFromJSON(json: any): FindOneFacilityBillingInfo200Response {
    return FindOneFacilityBillingInfo200ResponseFromJSONTyped(json, false);
}

export function FindOneFacilityBillingInfo200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneFacilityBillingInfo200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : FacilityBillingInfoFromJSON(json['data']),
    };
}

export function FindOneFacilityBillingInfo200ResponseToJSON(value?: FindOneFacilityBillingInfo200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': FacilityBillingInfoToJSON(value.data),
    };
}

