/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MedicarePrimaryDiagnosis = {
    JointReplacementOrSpinalSurgery: 'joint_replacement_or_spinal_surgery',
    OrthopedicSurgery: 'orthopedic_surgery',
    NonOrthopedicSurgery: 'non_orthopedic_surgery',
    AcuteInfections: 'acute_infections',
    CardiovascularCoagulations: 'cardiovascular_coagulations',
    Pulmonary: 'pulmonary',
    NonSurgicalOrthopedic: 'non_surgical_orthopedic',
    AcuteNeurologic: 'acute_neurologic',
    Cancer: 'cancer',
    MedicalManagement: 'medical_management',
    Null: 'null'
} as const;
export type MedicarePrimaryDiagnosis = typeof MedicarePrimaryDiagnosis[keyof typeof MedicarePrimaryDiagnosis];


export function MedicarePrimaryDiagnosisFromJSON(json: any): MedicarePrimaryDiagnosis {
    return MedicarePrimaryDiagnosisFromJSONTyped(json, false);
}

export function MedicarePrimaryDiagnosisFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicarePrimaryDiagnosis {
    return json as MedicarePrimaryDiagnosis;
}

export function MedicarePrimaryDiagnosisToJSON(value?: MedicarePrimaryDiagnosis | null): any {
    return value as any;
}

