/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCompletionAnswerOption } from './AssessmentCompletionAnswerOption';
import {
    AssessmentCompletionAnswerOptionFromJSON,
    AssessmentCompletionAnswerOptionFromJSONTyped,
    AssessmentCompletionAnswerOptionToJSON,
} from './AssessmentCompletionAnswerOption';

/**
 * 
 * @export
 * @interface AssessmentCompletionAnswer
 */
export interface AssessmentCompletionAnswer {
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswer
     */
    question_id: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswer
     */
    answer_text?: string;
    /**
     * 
     * @type {Array<AssessmentCompletionAnswerOption>}
     * @memberof AssessmentCompletionAnswer
     */
    selected_options?: Array<AssessmentCompletionAnswerOption>;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionAnswer
     */
    care_points_sum?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionAnswer
     */
    care_minutes_per_month?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletionAnswer
     */
    has_care_packages?: boolean;
}

/**
 * Check if a given object implements the AssessmentCompletionAnswer interface.
 */
export function instanceOfAssessmentCompletionAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "question_id" in value;

    return isInstance;
}

export function AssessmentCompletionAnswerFromJSON(json: any): AssessmentCompletionAnswer {
    return AssessmentCompletionAnswerFromJSONTyped(json, false);
}

export function AssessmentCompletionAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletionAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'question_id': json['question_id'],
        'answer_text': !exists(json, 'answer_text') ? undefined : json['answer_text'],
        'selected_options': !exists(json, 'selected_options') ? undefined : ((json['selected_options'] as Array<any>).map(AssessmentCompletionAnswerOptionFromJSON)),
        'care_points_sum': !exists(json, 'care_points_sum') ? undefined : json['care_points_sum'],
        'care_minutes_per_month': !exists(json, 'care_minutes_per_month') ? undefined : json['care_minutes_per_month'],
        'has_care_packages': !exists(json, 'has_care_packages') ? undefined : json['has_care_packages'],
    };
}

export function AssessmentCompletionAnswerToJSON(value?: AssessmentCompletionAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question_id': value.question_id,
        'answer_text': value.answer_text,
        'selected_options': value.selected_options === undefined ? undefined : ((value.selected_options as Array<any>).map(AssessmentCompletionAnswerOptionToJSON)),
        'care_points_sum': value.care_points_sum,
        'care_minutes_per_month': value.care_minutes_per_month,
        'has_care_packages': value.has_care_packages,
    };
}

