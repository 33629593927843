import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import {
  transformToPostIncidentBody,
  useMutateIncident
} from '@/hooks/useCreateIncident';
import { AddIncident } from '@/hooks/useCreateIncident';
import { useIncidentsQuery } from '@/hooks/useIncidentsQuery';
import { useSnackbar } from '@/hooks/useSnackbar';

interface AddIncidentSubmitButtonProps {
  formMethods: UseFormReturn<AddIncident, any>;
  closeModal: (value?: any) => void;
}

const AddIncidentSubmitButton: React.FC<AddIncidentSubmitButtonProps> = ({
  formMethods,
  closeModal
}) => {
  const { getValues } = formMethods;
  const mutateIncident = useMutateIncident();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const incidents = useIncidentsQuery();

  const handleIncidentSubmit = async () => {
    const payload = transformToPostIncidentBody(getValues());
    await mutateIncident.create
      .mutateAsync(payload)
      .then(() => {
        incidents.invalidate();
        closeModal();
        showSnackbar({
          message: 'Incident added successfully',
          severity: 'success'
        });
        navigate(-1);
      })
      .catch((e) => {
        console.error(e);
        closeModal();
        showSnackbar({
          message: 'Error adding the incident, Please try again',
          severity: 'error'
        });
      });
  };
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      loading={mutateIncident.create.isLoading}
      onClick={handleIncidentSubmit}
      id="incident-submit-button">
      Sign and Confirm
    </LoadingButton>
  );
};

export default AddIncidentSubmitButton;
