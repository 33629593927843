/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RelationType = {
    User: 'USER',
    ProspectResident: 'PROSPECT_RESIDENT',
    Referral: 'REFERRAL',
    ResidentCloseConnection: 'RESIDENT_CLOSE_CONNECTION',
    Resident: 'RESIDENT'
} as const;
export type RelationType = typeof RelationType[keyof typeof RelationType];


export function RelationTypeFromJSON(json: any): RelationType {
    return RelationTypeFromJSONTyped(json, false);
}

export function RelationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationType {
    return json as RelationType;
}

export function RelationTypeToJSON(value?: RelationType | null): any {
    return value as any;
}

