import React, { useEffect, useState } from 'react';
import { Alert, Box, Link } from '@mui/material';

interface ErrorBoundaryFallbackProps {
  error: Error;
  resetError: () => void;
}

export const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const { resetError } = props;
  const [currentLocation] = useState(window.location.href);

  useEffect(() => {
    const locationChange = () => {
      if (window.location.href !== currentLocation) resetError();
    };
    window.addEventListener('popstate', locationChange);
    return () => {
      window.removeEventListener('popstate', locationChange);
    };
  }, []);

  return (
    <Box>
      <Alert severity="error" sx={{ fontSize: '1.25rem' }}>
        Encountered an error. Our team has been notified and will investigate
        shortly. Try <Link href={location.href}>reloading the page</Link>.
        <br />
        If the problem persists, email{' '}
        <Link href="mailto:support@exacare.com">support@exacare.com</Link> for
        immediate assistance.
      </Alert>
    </Box>
  );
};
