/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CareLevel } from './CareLevel';
import {
    CareLevelFromJSON,
    CareLevelFromJSONTyped,
    CareLevelToJSON,
} from './CareLevel';

/**
 * 
 * @export
 * @interface CarePlanPrices
 */
export interface CarePlanPrices {
    /**
     * 
     * @type {CareLevel}
     * @memberof CarePlanPrices
     */
    care_level?: CareLevel;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    base_care_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    care_packages_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    care_discount?: number;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    actual_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CarePlanPrices
     */
    total_price?: number;
}

/**
 * Check if a given object implements the CarePlanPrices interface.
 */
export function instanceOfCarePlanPrices(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CarePlanPricesFromJSON(json: any): CarePlanPrices {
    return CarePlanPricesFromJSONTyped(json, false);
}

export function CarePlanPricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlanPrices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'care_level': !exists(json, 'care_level') ? undefined : CareLevelFromJSON(json['care_level']),
        'base_care_price': !exists(json, 'base_care_price') ? undefined : json['base_care_price'],
        'care_packages_price': !exists(json, 'care_packages_price') ? undefined : json['care_packages_price'],
        'care_discount': !exists(json, 'care_discount') ? undefined : json['care_discount'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'actual_price': !exists(json, 'actual_price') ? undefined : json['actual_price'],
        'total_price': !exists(json, 'total_price') ? undefined : json['total_price'],
    };
}

export function CarePlanPricesToJSON(value?: CarePlanPrices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'care_level': CareLevelToJSON(value.care_level),
        'base_care_price': value.base_care_price,
        'care_packages_price': value.care_packages_price,
        'care_discount': value.care_discount,
        'tax': value.tax,
        'actual_price': value.actual_price,
        'total_price': value.total_price,
    };
}

