/* tslint:disable */
/* eslint-disable */
/**
 * medications
 * medications
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationStockSignOff } from './MedicationStockSignOff';
import {
    MedicationStockSignOffFromJSON,
    MedicationStockSignOffFromJSONTyped,
    MedicationStockSignOffToJSON,
} from './MedicationStockSignOff';

/**
 * 
 * @export
 * @interface FindAllMedicationStockSignsOffsResponse
 */
export interface FindAllMedicationStockSignsOffsResponse {
    /**
     * 
     * @type {Array<MedicationStockSignOff>}
     * @memberof FindAllMedicationStockSignsOffsResponse
     */
    rows?: Array<MedicationStockSignOff>;
    /**
     * 
     * @type {number}
     * @memberof FindAllMedicationStockSignsOffsResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the FindAllMedicationStockSignsOffsResponse interface.
 */
export function instanceOfFindAllMedicationStockSignsOffsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllMedicationStockSignsOffsResponseFromJSON(json: any): FindAllMedicationStockSignsOffsResponse {
    return FindAllMedicationStockSignsOffsResponseFromJSONTyped(json, false);
}

export function FindAllMedicationStockSignsOffsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllMedicationStockSignsOffsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rows': !exists(json, 'rows') ? undefined : ((json['rows'] as Array<any>).map(MedicationStockSignOffFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function FindAllMedicationStockSignsOffsResponseToJSON(value?: FindAllMedicationStockSignsOffsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rows': value.rows === undefined ? undefined : ((value.rows as Array<any>).map(MedicationStockSignOffToJSON)),
        'count': value.count,
    };
}

