import React from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';

import { ControlledRadioGroup } from '@/components/ControlledRadioGroup';
import { ControlledTextField } from '@/components/ControlledTextField';
import { useSelectedFacilityId } from '@/hooks/useFacilitiesQuery';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useReferrals } from '@/hooks/useReferrals';
import { USE_REFERRALS_CREATE_MUTATION_KEY } from '@/hooks/useReferrals';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ReferralPayload } from '@/models/ReferralModel';

import { useCrmReferralSourceTypesQuery } from '../../hooks/useCrmReferralSourceTypesQuery';
import { USE_CRM_REFERRAL_SOURCE_TYPES_QUERY_KEY } from '../../hooks/useCrmReferralSourceTypesQuery';

import { AddProspectResidentFormData } from './AddProspectResidentForm';

type LeadInfoOriginReferralName =
  | 'crm_lead_info.origin_referral_source_id'
  | 'crm_lead_info.origin_heard_referral_source_id';

interface SelectReferralSourceQuickCreate {
  formMethods: UseFormReturn<AddProspectResidentFormData>;
  name: LeadInfoOriginReferralName;
  referralSourceCompanyId?: string;
}

export const ADD_NEW_OPTION = 'ADD_NEW_OPTION';

/**
 *
 * @param referralSourceCompanyId - when passed in, this field only shows
 * referral sources that belong to the company. It IS NOT required if there are
 * no referral sources. The user the option to add one. Adding at least one
 * referral source will make this component required.
 * @returns JSX.Element
 */
export const SelectReferralSourceQuickCreate: React.FC<
  SelectReferralSourceQuickCreate
> = ({ formMethods, name, referralSourceCompanyId }) => {
  const selectedFacilityId = useSelectedFacilityId();
  const { data: referralSources = [] } = useReferrals().findAll({
    facilityId: referralSourceCompanyId ? null : selectedFacilityId!,
    referralSourceCompanyId
  });
  const required = React.useMemo(() => {
    if (referralSourceCompanyId && referralSources.length === 0) {
      return false;
    }
    return true;
  }, [referralSourceCompanyId, referralSources]);

  const handleOnChange = async (
    onFieldChange: (...event: any[]) => void,
    value: string
  ) => {
    if (value === ADD_NEW_OPTION) {
      const { newReferralSourceId } = await showCreateQuickReferralDialog({
        referralSourceCompanyId
      });
      formMethods.setValue(name, newReferralSourceId, { shouldValidate: true });
      return;
    }

    onFieldChange(value);
  };

  return (
    <Controller
      name={name}
      control={formMethods.control}
      rules={{ required }}
      render={({
        field: { onChange: onFieldChange, ...field },
        fieldState
      }) => (
        <FormControl error={!!fieldState.error} fullWidth required={required}>
          <InputLabel>Referral Source</InputLabel>
          <Select
            label="Referral Source"
            onChange={(e) => {
              handleOnChange(
                onFieldChange,
                (e as SelectChangeEvent).target.value as string
              );
            }}
            {...field}>
            <MenuItem
              sx={{ py: '12px', borderBottom: '1px solid #E7EEF4' }}
              value={ADD_NEW_OPTION}>
              + Add New
            </MenuItem>
            {!required && (
              <MenuItem value="" sx={{ fontStyle: 'italic' }}>
                Not Applicable
              </MenuItem>
            )}
            {referralSources.map((referralSource) => (
              <MenuItem key={referralSource.id} value={referralSource.id}>
                {referralSource.renderDetailsCell()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

type ReferralPayloadFormData = {
  newSourceTypeName: string;
} & ReferralPayload;

type CreateQuickReferralSourceDialogProps = {
  referralSourceCompanyId?: string | null;
} & InstanceProps<{
  newReferralSourceId: string;
}>;

const CreateQuickReferralSourceDialog: React.FC<
  CreateQuickReferralSourceDialogProps
> = ({ onResolve, onReject, isOpen, referralSourceCompanyId = '' }) => {
  const selectedFacilityId = useSelectedFacilityId();
  const formMethods = useForm<ReferralPayloadFormData>({
    mode: 'all',
    defaultValues: {
      first_name: '',
      last_name: '',
      referral_source_type_id: '',
      referral_source_company_id: referralSourceCompanyId,
      newSourceTypeName: ''
    }
  });
  const areReferralsMutating = useIsMutating({
    mutationKey: USE_REFERRALS_CREATE_MUTATION_KEY
  });
  const areReferralSourceTypesMutating = useIsMutating({
    mutationKey: USE_CRM_REFERRAL_SOURCE_TYPES_QUERY_KEY
  });
  const { data: sourceTypes = [] } =
    useCrmReferralSourceTypesQuery().findAll(selectedFacilityId);
  const createSourceType = useCrmReferralSourceTypesQuery().mutations().create;
  const createReferralSource = useReferrals().mutations.create;
  const id = formMethods.watch('referral_source_type_id');
  const { showSnackbar } = useSnackbar();
  const isMobileViewport = useIsMobileViewport();

  const handleSubmit = formMethods.handleSubmit(async (data) => {
    let referralSourceTypeId = data.referral_source_type_id;

    try {
      if (data.referral_source_type_id === ADD_NEW_OPTION) {
        const { id: newReferralSourceTypeId } =
          await createSourceType.mutateAsync({
            name: data.newSourceTypeName,
            facility_id: selectedFacilityId!
          });
        referralSourceTypeId = newReferralSourceTypeId;
      }

      const { id: newReferralSourceId } =
        await createReferralSource.mutateAsync({
          ...data,
          facility_id: selectedFacilityId!,
          referral_source_type_id: referralSourceTypeId,
          referral_source_company_id: referralSourceCompanyId || null
        });

      return onResolve({ newReferralSourceId });
    } catch (e) {
      showSnackbar({
        message: 'Error creating referral source, please try again',
        severity: 'error'
      });
    }
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => onReject()}
      fullScreen={isMobileViewport}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit
      }}>
      <DialogTitle>
        Add Referral Source
        <IconButton onClick={() => onReject()}>
          <Cancel />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="first_name"
              label="First Name"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledTextField
              control={formMethods.control}
              name="last_name"
              label="Last Name"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              control={formMethods.control}
              name="referral_source_type_id"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl required error={!!fieldState.error} fullWidth>
                  <InputLabel>Source Type</InputLabel>
                  <Select label="Source Type" {...field}>
                    <MenuItem value={ADD_NEW_OPTION}>+ Add New</MenuItem>
                    {sourceTypes.map((sourceType) => (
                      <MenuItem key={sourceType.id} value={sourceType.id}>
                        {sourceType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {
            // @ts-ignore
            id === ADD_NEW_OPTION && (
              <Grid xs={12}>
                <ControlledTextField
                  control={formMethods.control}
                  name="newSourceTypeName"
                  label="New Source Type"
                  placeholder="Hospital Case Manager, Church, etc"
                  rules={{ required: true }}
                />
              </Grid>
            )
          }
        </Grid>
        <Grid xs={12}>
          <ControlledRadioGroup
            name="paid"
            control={formMethods.control}
            rules={{ required: true }}
            fields={[
              {
                label: 'Paid',
                value: true
              },
              {
                label: 'Unpaid',
                value: false
              }
            ]}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onReject()}
          size="medium">
          Cancel
        </Button>
        <LoadingButton
          loading={!!areReferralsMutating || !!areReferralSourceTypesMutating}
          variant="contained"
          color="primary"
          type="submit"
          size="medium">
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const showCreateQuickReferralDialog = create(CreateQuickReferralSourceDialog);
