/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CampaignStepType } from './CampaignStepType';
import {
    CampaignStepTypeFromJSON,
    CampaignStepTypeFromJSONTyped,
    CampaignStepTypeToJSON,
} from './CampaignStepType';

/**
 * 
 * @export
 * @interface CampaignStepListEntry
 */
export interface CampaignStepListEntry {
    /**
     * 
     * @type {string}
     * @memberof CampaignStepListEntry
     */
    id: string;
    /**
     * 
     * @type {CampaignStepType}
     * @memberof CampaignStepListEntry
     */
    type: CampaignStepType;
    /**
     * 
     * @type {number}
     * @memberof CampaignStepListEntry
     */
    days_interval: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignStepListEntry
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignStepListEntry
     */
    sent_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStepListEntry
     */
    reply_count?: number;
    /**
     * Only for email steps, for validations on frontend
     * @type {string}
     * @memberof CampaignStepListEntry
     */
    email_subject?: string;
    /**
     * Only for email steps, for validations on frontend
     * @type {boolean}
     * @memberof CampaignStepListEntry
     */
    email_send_in_thread?: boolean;
    /**
     * Only for task steps, for a more descriptive display
     * @type {string}
     * @memberof CampaignStepListEntry
     */
    task_type?: string;
}

/**
 * Check if a given object implements the CampaignStepListEntry interface.
 */
export function instanceOfCampaignStepListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "days_interval" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function CampaignStepListEntryFromJSON(json: any): CampaignStepListEntry {
    return CampaignStepListEntryFromJSONTyped(json, false);
}

export function CampaignStepListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStepListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': CampaignStepTypeFromJSON(json['type']),
        'days_interval': json['days_interval'],
        'title': json['title'],
        'sent_count': !exists(json, 'sent_count') ? undefined : json['sent_count'],
        'reply_count': !exists(json, 'reply_count') ? undefined : json['reply_count'],
        'email_subject': !exists(json, 'email_subject') ? undefined : json['email_subject'],
        'email_send_in_thread': !exists(json, 'email_send_in_thread') ? undefined : json['email_send_in_thread'],
        'task_type': !exists(json, 'task_type') ? undefined : json['task_type'],
    };
}

export function CampaignStepListEntryToJSON(value?: CampaignStepListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CampaignStepTypeToJSON(value.type),
        'days_interval': value.days_interval,
        'title': value.title,
        'sent_count': value.sent_count,
        'reply_count': value.reply_count,
        'email_subject': value.email_subject,
        'email_send_in_thread': value.email_send_in_thread,
        'task_type': value.task_type,
    };
}

