import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  styled
} from '@mui/material';
import dayjs from 'dayjs';

import ControlledPinInput from '@/components/ui/ControlledPinInput/ControlledPinInput';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useFacilitiesQuery } from '@/hooks/useFacilitiesQuery';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { useFindOneRoomQuery } from '@/hooks/useRoomsQuery';
import { useUsersFindOneQuery } from '@/hooks/useUserQuery';

import AddIncidentSignButton from './buttons/AddIncidentSignButton';
import AddIncidentSubmitButton from './buttons/AddIncidentSubmitButton';
import { SectionHeader, SectionSeparator } from './AddIncidentDetailsStep';

const StyledConfirmationDialog = styled(Dialog)({
  minWidth: '920px',
  margin: '0 auto',
  '@media (max-width: 960px)': {
    minWidth: 'unset',
    width: '100%'
  },
  'div[role="dialog"]': {
    minWidth: '920px',
    '@media (max-width: 960px)': {
      minWidth: 'unset',
      width: '90%'
    }
  },
  'div.MuiDialogContent-root': {
    paddingTop: '12px!important',
    gap: '14px' // 14px gap + 10px margin bottom on text = 24px
  }
});

const StyledBackButton = styled(Button)({
  color: '#667A86',
  borderColor: '#667A86'
});

interface AddIncidentConfirmationDialogProps extends InstanceProps<any> {
  residentId: number;
  incidentTypeId: number;
  incidentType: string;
  occurenceDate: string;
  formMethods: UseFormReturn<any, any>;
  status?: string;
  prevUserId?: string | null;
}

const AddIncidentConfirmationDialog: React.FC<
  AddIncidentConfirmationDialogProps
> = ({
  close,
  isOpen,
  residentId,
  incidentTypeId,
  incidentType,
  occurenceDate,
  formMethods,
  status,
  prevUserId
}) => {
  const { data: resident } = useFindOneResidentQuery({
    residentId: `${residentId}`
  });
  const facility = useFacilitiesQuery().findOne(resident?.facility_id).data;
  const currentUser = useCurrentUser().data!;
  const today: string = dayjs(new Date()).format('MM/DD/YYYY');
  const { setValue } = formMethods;

  const { data: prevUser, isFetching } = useUsersFindOneQuery(prevUserId!, {
    enabled: typeof prevUserId !== 'undefined'
  });

  const { data: room } = useFindOneRoomQuery(resident?.room_id);

  const wasIncidentPreviouslySigned =
    !isFetching &&
    typeof prevUserId !== 'undefined' &&
    typeof prevUser !== 'undefined';

  return (
    <StyledConfirmationDialog
      onClose={close}
      aria-labelledby="incident-confirmation-dialog"
      open={isOpen}>
      <DialogTitle p="24px!important">Sign and Confirm Incident</DialogTitle>
      <DialogContent>
        <Stack>
          <Grid container>
            <Grid item xs={12}>
              <SectionHeader>
                <LanguageIcon />
                Resident and Incident Information
              </SectionHeader>
            </Grid>
            <Grid item xs={6}>
              <SectionSeparator>
                Resident: <span>{resident?.getFullName()}</span>
              </SectionSeparator>
              <SectionSeparator>
                Birth Date:
                <span className="no-upscope">
                  {' '}
                  {dayjs(resident?.dob).format('MM/DD/YY')}
                </span>
              </SectionSeparator>
              <SectionSeparator>
                Incident ID Number:
                <span> {incidentTypeId}</span>
              </SectionSeparator>
              <SectionSeparator>
                Incident Type:
                <span> {incidentType}</span>
              </SectionSeparator>
            </Grid>
            <Grid item xs={6}>
              <SectionSeparator>
                Community Name:
                <span> {facility?.name}</span>
              </SectionSeparator>
              <SectionSeparator>
                Room Number:
                <span> {room?.number}</span>
              </SectionSeparator>
              <SectionSeparator>
                Incident Date:
                <span> {dayjs(occurenceDate).format('MM/DD/YYYY')}</span>
              </SectionSeparator>
            </Grid>
          </Grid>
        </Stack>
        {wasIncidentPreviouslySigned && (
          <Stack spacing={2}>
            <Grid container>
              <Grid item xs={12}>
                <SectionHeader>
                  <CheckCircleOutlineIcon />
                  Already Signed
                </SectionHeader>
              </Grid>
              <Grid item xs={12} md={4}>
                <SectionSeparator>
                  Name: <span>{prevUser.getFullName()}</span>
                </SectionSeparator>
              </Grid>
              <Grid item xs={12} md={4}>
                <SectionSeparator>
                  Position: <span>{prevUser.getPosition()}</span>
                </SectionSeparator>
              </Grid>
              <Grid item xs={12} md={4}>
                <SectionSeparator>
                  Signing Date:{' '}
                  <span>{dayjs(occurenceDate).format('MM/DD/YYYY')}</span>
                </SectionSeparator>
              </Grid>
            </Grid>
          </Stack>
        )}
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={12}>
              <SectionHeader>
                <CheckCircleOutlineIcon />
                Your Confirmation and Signature
              </SectionHeader>
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionSeparator>
                Your Name:
                <span> {currentUser?.getFullName()}</span>
              </SectionSeparator>
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionSeparator>
                Signing Date:
                <span> {today}</span>
              </SectionSeparator>
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionSeparator>
                Your Position:
                <span> {currentUser?.getPosition()}</span>
              </SectionSeparator>
            </Grid>
            <Grid item xs={12} justifyContent="start">
              <SectionSeparator mb="18px" mt="18px">
                I have read all of the above information and confirm it to be
                factual
              </SectionSeparator>
              <Stack maxWidth="60%">
                <ControlledPinInput
                  handleInput={(values) => setValue('pin', values)}
                />
              </Stack>
              <SectionSeparator mt={2}>
                E-signature using your PIN
              </SectionSeparator>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          justifyContent="end"
          alignContent="end"
          direction="row"
          mt="16px"
          spacing={2}>
          <StyledBackButton variant="outlined" onClick={close}>
            {status === '0' ? 'Back' : 'Back to Review and Edit'}
          </StyledBackButton>
          {status === '0' ? (
            <AddIncidentSignButton
              formMethods={formMethods}
              closeModal={close}
              incidentId={incidentTypeId}
            />
          ) : (
            <AddIncidentSubmitButton
              formMethods={formMethods}
              closeModal={close}
            />
          )}
        </Stack>
      </DialogContent>
    </StyledConfirmationDialog>
  );
};

export default AddIncidentConfirmationDialog;

export const showAddIncidentConfirmationDialog =
  create<AddIncidentConfirmationDialogProps>(AddIncidentConfirmationDialog);
