/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CriteriaQualifier } from './CriteriaQualifier';
import {
    CriteriaQualifierFromJSON,
    CriteriaQualifierFromJSONTyped,
    CriteriaQualifierToJSON,
} from './CriteriaQualifier';

/**
 * 
 * @export
 * @interface Criteria
 */
export interface Criteria {
    /**
     * 
     * @type {string}
     * @memberof Criteria
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Criteria
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Criteria
     */
    category_id: string;
    /**
     * 
     * @type {string}
     * @memberof Criteria
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Criteria
     */
    description?: string;
    /**
     * 
     * @type {CriteriaQualifier}
     * @memberof Criteria
     */
    qualifier: CriteriaQualifier;
}

/**
 * Check if a given object implements the Criteria interface.
 */
export function instanceOfCriteria(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "category_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "qualifier" in value;

    return isInstance;
}

export function CriteriaFromJSON(json: any): Criteria {
    return CriteriaFromJSONTyped(json, false);
}

export function CriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Criteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'category_id': json['category_id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'qualifier': CriteriaQualifierFromJSON(json['qualifier']),
    };
}

export function CriteriaToJSON(value?: Criteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'category_id': value.category_id,
        'name': value.name,
        'description': value.description,
        'qualifier': CriteriaQualifierToJSON(value.qualifier),
    };
}

