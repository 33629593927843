/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentLinks
 */
export interface PaymentLinks {
    /**
     * 
     * @type {string}
     * @memberof PaymentLinks
     */
    card: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLinks
     */
    ach: string;
}

/**
 * Check if a given object implements the PaymentLinks interface.
 */
export function instanceOfPaymentLinks(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "card" in value;
    isInstance = isInstance && "ach" in value;

    return isInstance;
}

export function PaymentLinksFromJSON(json: any): PaymentLinks {
    return PaymentLinksFromJSONTyped(json, false);
}

export function PaymentLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': json['card'],
        'ach': json['ach'],
    };
}

export function PaymentLinksToJSON(value?: PaymentLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': value.card,
        'ach': value.ach,
    };
}

