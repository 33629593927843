/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatientContact
 */
export interface PccPatientContact {
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    gender?: PccPatientContactGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    cell_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    home_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    office_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    other_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    relationship?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientContact
     */
    pcc_pending_contact_id?: string;
}


/**
 * @export
 */
export const PccPatientContactGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Unknown: 'Unknown'
} as const;
export type PccPatientContactGenderEnum = typeof PccPatientContactGenderEnum[keyof typeof PccPatientContactGenderEnum];


/**
 * Check if a given object implements the PccPatientContact interface.
 */
export function instanceOfPccPatientContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;

    return isInstance;
}

export function PccPatientContactFromJSON(json: any): PccPatientContact {
    return PccPatientContactFromJSONTyped(json, false);
}

export function PccPatientContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'postal_code': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'cell_phone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'home_phone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'office_phone': !exists(json, 'office_phone') ? undefined : json['office_phone'],
        'other_phone': !exists(json, 'other_phone') ? undefined : json['other_phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'pcc_pending_contact_id': !exists(json, 'pcc_pending_contact_id') ? undefined : json['pcc_pending_contact_id'],
    };
}

export function PccPatientContactToJSON(value?: PccPatientContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'gender': value.gender,
        'country': value.country,
        'city': value.city,
        'state': value.state,
        'postal_code': value.postal_code,
        'address': value.address,
        'email': value.email,
        'cell_phone': value.cell_phone,
        'home_phone': value.home_phone,
        'office_phone': value.office_phone,
        'other_phone': value.other_phone,
        'fax': value.fax,
        'dob': value.dob,
        'relationship': value.relationship,
        'pcc_pending_contact_id': value.pcc_pending_contact_id,
    };
}

