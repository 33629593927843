/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PdpmEstimatorField = {
    SevereImpairment: 'severe_impairment',
    HasMemoryProblems: 'has_memory_problems',
    HasSwallowingDisorder: 'has_swallowing_disorder',
    HasMechanicallyAlteredDiet: 'has_mechanically_altered_diet',
    IsDepressed: 'is_depressed',
    PrimaryDiagnosis: 'primary_diagnosis',
    SkillsForDailyDecisionMaking: 'skills_for_daily_decision_making',
    MakesSelfUnderstood: 'makes_self_understood',
    Eating: 'eating',
    OralHygiene: 'oral_hygiene',
    Toileting: 'toileting',
    SitToLying: 'sit_to_lying',
    LyingToSit: 'lying_to_sit',
    SitToStand: 'sit_to_stand',
    BedToChair: 'bed_to_chair',
    Toilet: 'toilet',
    Walking50Feet: 'walking_50_feet',
    Walking150Feet: 'walking_150_feet',
    Comorbidities: 'comorbidities',
    ConditionsServices: 'conditions_services',
    ExtensiveServices: 'extensive_services',
    SpecialCareHigh: 'special_care_high',
    SpecialCareLow: 'special_care_low',
    ClinicallyComplex: 'clinically_complex',
    BehavioralSymptoms: 'behavioral_symptoms',
    ServicesRequired: 'services_required'
} as const;
export type PdpmEstimatorField = typeof PdpmEstimatorField[keyof typeof PdpmEstimatorField];


export function PdpmEstimatorFieldFromJSON(json: any): PdpmEstimatorField {
    return PdpmEstimatorFieldFromJSONTyped(json, false);
}

export function PdpmEstimatorFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdpmEstimatorField {
    return json as PdpmEstimatorField;
}

export function PdpmEstimatorFieldToJSON(value?: PdpmEstimatorField | null): any {
    return value as any;
}

