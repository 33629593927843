/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostIncidentFollowup } from './PostIncidentFollowup';
import {
    PostIncidentFollowupFromJSON,
    PostIncidentFollowupFromJSONTyped,
    PostIncidentFollowupToJSON,
} from './PostIncidentFollowup';

/**
 * 
 * @export
 * @interface IncidentProtocolFollowup
 */
export interface IncidentProtocolFollowup {
    /**
     * 
     * @type {PostIncidentFollowup}
     * @memberof IncidentProtocolFollowup
     */
    type?: PostIncidentFollowup;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocolFollowup
     */
    value?: string;
}

/**
 * Check if a given object implements the IncidentProtocolFollowup interface.
 */
export function instanceOfIncidentProtocolFollowup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IncidentProtocolFollowupFromJSON(json: any): IncidentProtocolFollowup {
    return IncidentProtocolFollowupFromJSONTyped(json, false);
}

export function IncidentProtocolFollowupFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocolFollowup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : PostIncidentFollowupFromJSON(json['type']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function IncidentProtocolFollowupToJSON(value?: IncidentProtocolFollowup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': PostIncidentFollowupToJSON(value.type),
        'value': value.value,
    };
}

