/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolListEntry } from './IncidentProtocolListEntry';
import {
    IncidentProtocolListEntryFromJSON,
    IncidentProtocolListEntryFromJSONTyped,
    IncidentProtocolListEntryToJSON,
} from './IncidentProtocolListEntry';

/**
 * 
 * @export
 * @interface FindAllProtocols200Response
 */
export interface FindAllProtocols200Response {
    /**
     * 
     * @type {Array<IncidentProtocolListEntry>}
     * @memberof FindAllProtocols200Response
     */
    data?: Array<IncidentProtocolListEntry>;
}

/**
 * Check if a given object implements the FindAllProtocols200Response interface.
 */
export function instanceOfFindAllProtocols200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllProtocols200ResponseFromJSON(json: any): FindAllProtocols200Response {
    return FindAllProtocols200ResponseFromJSONTyped(json, false);
}

export function FindAllProtocols200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllProtocols200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(IncidentProtocolListEntryFromJSON)),
    };
}

export function FindAllProtocols200ResponseToJSON(value?: FindAllProtocols200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(IncidentProtocolListEntryToJSON)),
    };
}

