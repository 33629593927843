/* tslint:disable */
/* eslint-disable */
/**
 * care-plan
 * care-plan
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountInformationalCPE200ResponseData } from './CountInformationalCPE200ResponseData';
import {
    CountInformationalCPE200ResponseDataFromJSON,
    CountInformationalCPE200ResponseDataFromJSONTyped,
    CountInformationalCPE200ResponseDataToJSON,
} from './CountInformationalCPE200ResponseData';

/**
 * 
 * @export
 * @interface CountInformationalCPE200Response
 */
export interface CountInformationalCPE200Response {
    /**
     * 
     * @type {CountInformationalCPE200ResponseData}
     * @memberof CountInformationalCPE200Response
     */
    data?: CountInformationalCPE200ResponseData;
}

/**
 * Check if a given object implements the CountInformationalCPE200Response interface.
 */
export function instanceOfCountInformationalCPE200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CountInformationalCPE200ResponseFromJSON(json: any): CountInformationalCPE200Response {
    return CountInformationalCPE200ResponseFromJSONTyped(json, false);
}

export function CountInformationalCPE200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountInformationalCPE200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CountInformationalCPE200ResponseDataFromJSON(json['data']),
    };
}

export function CountInformationalCPE200ResponseToJSON(value?: CountInformationalCPE200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CountInformationalCPE200ResponseDataToJSON(value.data),
    };
}

