import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import MedicationSlidingScaleDose from '../MedicationDrawer/MedicationSlidingScaleDose';
import { NumberOfDoseUnits } from '../MedicationDrawer/NumberOfDoseUnits';

interface ScheduleDoseFormProps {
  slidingScaleDoseActive: boolean;
  frequencyPath: string;
  formMethods: UseFormReturn;
  handleOnChange?: VoidFunction;
  disabled?: boolean;
}

export default function ScheduleDoseForm(props: ScheduleDoseFormProps) {
  const {
    slidingScaleDoseActive,
    frequencyPath,
    formMethods,
    handleOnChange,
    disabled = false
  } = props;
  const addSlidingScaleDose = () => {
    formMethods.setValue(`${frequencyPath}.dose_ranges`, [
      {
        comment: ''
      }
    ]);
    handleOnChange?.();
  };

  const removeSlidingScaleDose = () => {
    formMethods.setValue(`${frequencyPath}.dose_ranges`, undefined);
    handleOnChange?.();
  };

  return (
    <>
      {!slidingScaleDoseActive ? (
        <>
          <NumberOfDoseUnits
            formMethods={formMethods}
            name={`${frequencyPath}.number_of_dose_units`}
            onChange={() => handleOnChange?.()}
            disableEdit={disabled}
          />
          {FeatureFlagService.isEnabled(ExacareFeature.SLIDING_SCALE_DOSE) &&
            !disabled && (
              <Box m={2}>
                <Button
                  size="small"
                  startIcon={<Add />}
                  onClick={addSlidingScaleDose}
                  sx={{ width: 'max-content' }}>
                  Add Sliding Scale Dose
                </Button>
              </Box>
            )}
        </>
      ) : (
        <MedicationSlidingScaleDose
          fieldPath={`${frequencyPath}.dose_ranges`}
          formMethods={formMethods}
          removeSlidingScaleDose={removeSlidingScaleDose}
          disabled={disabled}
        />
      )}
    </>
  );
}
