/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DepositStatus = {
    Unpaid: 'Unpaid',
    Processing: 'Processing',
    PartiallyPaid: 'Partially Paid',
    Paid: 'Paid',
    Overpaid: 'Overpaid',
    Refunded: 'Refunded',
    Cancelled: 'Cancelled',
    Closed: 'Closed'
} as const;
export type DepositStatus = typeof DepositStatus[keyof typeof DepositStatus];


export function DepositStatusFromJSON(json: any): DepositStatus {
    return DepositStatusFromJSONTyped(json, false);
}

export function DepositStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositStatus {
    return json as DepositStatus;
}

export function DepositStatusToJSON(value?: DepositStatus | null): any {
    return value as any;
}

