import React from 'react';
import { Alert, Fade } from '@mui/material';

interface DirectionsRecommendationsProps {
  directions: string;
}

const DirectionsRecommendations: React.FC<DirectionsRecommendationsProps> = ({
  directions
}) => {
  return (
    <Fade in={true}>
      <Alert severity="info" sx={{ height: '100%', alignItems: 'center' }}>
        {`We are unable to parse the schedule for this medication from the
        pharmacy. Please manually create it below. The ordering physician's
        directions for the resident are:`}{' '}
        <br />
        <strong>{directions}</strong>
      </Alert>
    </Fade>
  );
};

export default DirectionsRecommendations;
