import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

interface ErrorPageProps {
  title: string;
  message: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, message }) => {
  return (
    <Stack sx={{ background: '#FCFEFF', height: '100%', borderRadius: '16px' }}>
      <Box p={3}>
        <Typography color="#0A1E28" fontSize="1.375rem">
          {title}
        </Typography>
      </Box>
      <Divider />
      <Typography color="#364955" fontSize="1.125rem" p={3}>
        {message}
      </Typography>
    </Stack>
  );
};

export default ErrorPage;
