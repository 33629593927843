/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadPccPatientDocumentsRequest
 */
export interface UploadPccPatientDocumentsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UploadPccPatientDocumentsRequest
     */
    push_summary_report: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadPccPatientDocumentsRequest
     */
    push_screener_results: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadPccPatientDocumentsRequest
     */
    push_clinic_package: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadPccPatientDocumentsRequest
     */
    push_full_clinic_package?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadPccPatientDocumentsRequest
     */
    clinic_package_document_ids?: Array<string>;
}

/**
 * Check if a given object implements the UploadPccPatientDocumentsRequest interface.
 */
export function instanceOfUploadPccPatientDocumentsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "push_summary_report" in value;
    isInstance = isInstance && "push_screener_results" in value;
    isInstance = isInstance && "push_clinic_package" in value;

    return isInstance;
}

export function UploadPccPatientDocumentsRequestFromJSON(json: any): UploadPccPatientDocumentsRequest {
    return UploadPccPatientDocumentsRequestFromJSONTyped(json, false);
}

export function UploadPccPatientDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadPccPatientDocumentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'push_summary_report': json['push_summary_report'],
        'push_screener_results': json['push_screener_results'],
        'push_clinic_package': json['push_clinic_package'],
        'push_full_clinic_package': !exists(json, 'push_full_clinic_package') ? undefined : json['push_full_clinic_package'],
        'clinic_package_document_ids': !exists(json, 'clinic_package_document_ids') ? undefined : json['clinic_package_document_ids'],
    };
}

export function UploadPccPatientDocumentsRequestToJSON(value?: UploadPccPatientDocumentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'push_summary_report': value.push_summary_report,
        'push_screener_results': value.push_screener_results,
        'push_clinic_package': value.push_clinic_package,
        'push_full_clinic_package': value.push_full_clinic_package,
        'clinic_package_document_ids': value.clinic_package_document_ids,
    };
}

