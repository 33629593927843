import React from 'react';

export interface UseMediaStreamReturn {
  stream?: MediaStream;
  isStreamSupported?: boolean;
  isLoading: boolean;
}

// We SHOULDN'T manage multiple instances of a stream. Therefore this top level
// component maintains a single reference to it in the useMediaStream hook below
let stream: MediaStream;

export function useMediaStream(): UseMediaStreamReturn {
  if (stream instanceof MediaStream) {
    return {
      stream,
      isStreamSupported: true,
      isLoading: false
    };
  }

  const [returnValue, setReturnValue] = React.useState<UseMediaStreamReturn>({
    isLoading: true
  });

  async function getUserMedia() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        mediaStreamConstraints
      );
      setReturnValue({
        stream,
        isStreamSupported: true,
        isLoading: false
      });
    } catch (err) {
      setReturnValue({
        isStreamSupported: false,
        isLoading: false
      });
    }
  }

  React.useEffect(() => {
    getUserMedia();
  }, []);

  return returnValue;
}

const mediaStreamConstraints: MediaStreamConstraints = {
  video: {
    width: 500,
    height: 500,
    facingMode: 'user'
  },
  audio: false
};
