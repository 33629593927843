/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncidentProtocol
 */
export interface IncidentProtocol {
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocol
     */
    publish_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    published_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocol
     */
    unpublish_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    created_by?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProtocol
     */
    last_modified_by?: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocol
     */
    date_created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocol
     */
    date_updated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentProtocol
     */
    date_deleted?: Date;
}

/**
 * Check if a given object implements the IncidentProtocol interface.
 */
export function instanceOfIncidentProtocol(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IncidentProtocolFromJSON(json: any): IncidentProtocol {
    return IncidentProtocolFromJSONTyped(json, false);
}

export function IncidentProtocolFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentProtocol {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'publish_date': !exists(json, 'publish_date') ? undefined : (new Date(json['publish_date'])),
        'published_by': !exists(json, 'published_by') ? undefined : json['published_by'],
        'unpublish_date': !exists(json, 'unpublish_date') ? undefined : (new Date(json['unpublish_date'])),
        'created_by': !exists(json, 'created_by') ? undefined : json['created_by'],
        'last_modified_by': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'date_created': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'date_updated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'date_deleted': !exists(json, 'date_deleted') ? undefined : (new Date(json['date_deleted'])),
    };
}

export function IncidentProtocolToJSON(value?: IncidentProtocol | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date.toISOString()),
        'published_by': value.published_by,
        'unpublish_date': value.unpublish_date === undefined ? undefined : (value.unpublish_date.toISOString()),
        'created_by': value.created_by,
        'last_modified_by': value.last_modified_by,
        'date_created': value.date_created === undefined ? undefined : (value.date_created.toISOString()),
        'date_updated': value.date_updated === undefined ? undefined : (value.date_updated.toISOString()),
        'date_deleted': value.date_deleted === undefined ? undefined : (value.date_deleted.toISOString()),
    };
}

