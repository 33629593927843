import dayjs from 'dayjs';
import { RRule } from 'rrule';

import { SlidingScaleDoseRange } from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import { PatternedRecurrenceEhr } from '@/models/PatternedRecurrenceEhr';

import { MARTaskInstanceModel } from './MARTaskInstanceModel';

export class MARScheduleModel {
  createdAt: string;
  created_by_user_id: string;
  execution_window_end_time: string;
  execution_window_start_time: string;
  generated_until: string;
  has_all_been_generated: boolean;
  id: string;
  instance_generation_end_date: string | null;
  instance_generation_start_date: string;
  is_discontinued: boolean;
  medication_task_id: string;
  instances: MARTaskInstanceModel[];
  number_of_dose_units: number | null;
  rrule: string | null;
  patterned_recurrence: PatternedRecurrenceEhr | null;
  schedule_is_active_end_date: string | null;
  schedule_is_active_start_date: string | null;
  updatedAt: string | null;
  dose_ranges?: SlidingScaleDoseRange[];

  paddedMedicationTaskInstances: Array<{
    time: string;
    padded_instances: MARTaskInstanceModel[];
  }>;

  constructor(payload: MARScheduleModel) {
    Object.assign(this, payload);
    this.paddedMedicationTaskInstances = this.padMedicationTaskInstances();
    this.instances = payload.instances.map(
      (instance) => new MARTaskInstanceModel(instance)
    );
  }

  private padMedicationTaskInstances = () => {
    // To handle patterned_recurrence, we need to consider that there can be several instances
    // for 1 single schedule. We will group the instances by execution_window_start_time, so we can display
    // one single row in the MAR for each time during the month

    // Please note that for:
    // Patterned recurrence: we will group by instance.execution_window_start_time
    // RRule: we will group by schedule.execution_window_start_time

    const paddedInstancesObjects = [];

    const startTimeToInstancesMap = this.instances.reduce((acc, curr) => {
      const time = this.patterned_recurrence
        ? curr.execution_window_start_time
        : this.execution_window_start_time;
      if (acc.has(time)) {
        acc.set(time, [...acc.get(time)!, curr]);
      } else {
        acc.set(time, [curr]);
      }
      return acc;
    }, new Map<string, MARTaskInstanceModel[]>());

    for (const [key, value] of startTimeToInstancesMap) {
      const instancesFromStartTime = value;
      // instances may not have 31 items in it, 1 for each
      // calendar day. Fill empty days with an object that only has the day of
      // month so the MARSchedule component has simplified iteration logic
      const padded = Array(31)
        .fill(null)
        .map(
          (_, index) =>
            new MARTaskInstanceModel({
              day_of_month: index + 1
            } as MARTaskInstanceModel)
        ) as MARTaskInstanceModel[];
      instancesFromStartTime.forEach((instance) => {
        padded[instance.day_of_month - 1] = new MARTaskInstanceModel(instance);
      });
      paddedInstancesObjects.push({
        time: key,
        padded_instances: padded
      });
    }
    return paddedInstancesObjects;
  };

  public isDateInapplicable = (
    _MARDate: Date,
    dayOfMonth: number,
    isPaddedInstance: boolean
  ) => {
    const MARDate = dayjs(_MARDate).date(dayOfMonth).startOf('day');
    const instanceGenerationStartDate = dayjs(
      this.instance_generation_start_date
    ).startOf('day');
    if (MARDate.isBefore(instanceGenerationStartDate)) {
      return true;
    }
    // Check if instance is a day before today AND that its NOT a padded
    // instance. A padded instance doesn't have a createdAt timestamp
    const isBeforeTodayAndHasNoInstance =
      MARDate.isBefore(dayjs().startOf('day')) && isPaddedInstance;

    // Check if the instance is a day after the instance generation end date. We
    // should use the rrule's until property because that is the day within
    // the timezone
    let isAfterUntilDate = false;
    if (this.rrule) {
      const rruleUntil = RRule.fromString(this.rrule).options.until;
      if (rruleUntil) {
        isAfterUntilDate = MARDate.isAfter(dayjs(rruleUntil).startOf('day'));
      }
    } else if (
      this.patterned_recurrence &&
      this.patterned_recurrence.range.endDate
    ) {
      isAfterUntilDate = MARDate.isAfter(
        dayjs(this.patterned_recurrence.range.endDate)
      );
    }
    return isBeforeTodayAndHasNoInstance || isAfterUntilDate;
  };
}
