/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MultiPaymentEntry } from './MultiPaymentEntry';
import {
    MultiPaymentEntryFromJSON,
    MultiPaymentEntryFromJSONTyped,
    MultiPaymentEntryToJSON,
} from './MultiPaymentEntry';

/**
 * 
 * @export
 * @interface UpdateResidentBillingInfoRequest
 */
export interface UpdateResidentBillingInfoRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResidentBillingInfoRequest
     */
    auto_bill_enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateResidentBillingInfoRequest
     */
    auto_bill_day_of_month: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResidentBillingInfoRequest
     */
    multi_payment_enabled: boolean;
    /**
     * 
     * @type {Array<MultiPaymentEntry>}
     * @memberof UpdateResidentBillingInfoRequest
     */
    multi_payment_entries: Array<MultiPaymentEntry>;
}

/**
 * Check if a given object implements the UpdateResidentBillingInfoRequest interface.
 */
export function instanceOfUpdateResidentBillingInfoRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "auto_bill_enabled" in value;
    isInstance = isInstance && "auto_bill_day_of_month" in value;
    isInstance = isInstance && "multi_payment_enabled" in value;
    isInstance = isInstance && "multi_payment_entries" in value;

    return isInstance;
}

export function UpdateResidentBillingInfoRequestFromJSON(json: any): UpdateResidentBillingInfoRequest {
    return UpdateResidentBillingInfoRequestFromJSONTyped(json, false);
}

export function UpdateResidentBillingInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResidentBillingInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auto_bill_enabled': json['auto_bill_enabled'],
        'auto_bill_day_of_month': json['auto_bill_day_of_month'],
        'multi_payment_enabled': json['multi_payment_enabled'],
        'multi_payment_entries': ((json['multi_payment_entries'] as Array<any>).map(MultiPaymentEntryFromJSON)),
    };
}

export function UpdateResidentBillingInfoRequestToJSON(value?: UpdateResidentBillingInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_bill_enabled': value.auto_bill_enabled,
        'auto_bill_day_of_month': value.auto_bill_day_of_month,
        'multi_payment_enabled': value.multi_payment_enabled,
        'multi_payment_entries': ((value.multi_payment_entries as Array<any>).map(MultiPaymentEntryToJSON)),
    };
}

