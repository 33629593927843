import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  EVENING_END_TIME,
  EVENING_START_TIME,
  generateExactTimeOptions,
  MORNING_END_TIME,
  MORNING_START_TIME,
  NIGHT_END_TIME,
  NIGHT_START_TIME
} from '@/components/FrequencyTimeForm/options';
import { getExactExecutionTime } from '@/utils/getExactExecutionTime';

dayjs.extend(localizedFormat);

const exactTimeOptions = generateExactTimeOptions();

interface GetExecutionWindowTimeValueParams {
  date_completed?: string | null;
  format?: string | null;
  execution_window_start_time?: string | null;
  execution_window_end_time?: string | null;
}

export const getExecutionWindowTimeValue = ({
  date_completed,
  format,
  execution_window_start_time,
  execution_window_end_time
}: GetExecutionWindowTimeValueParams): {
  display: string;
  value: number;
} => {
  if (
    date_completed &&
    !execution_window_start_time &&
    !execution_window_end_time
  ) {
    return getExactExecutionTime({ time: date_completed, format: format });
  }
  if (
    execution_window_start_time === MORNING_START_TIME.label &&
    execution_window_end_time === MORNING_END_TIME.label
  ) {
    return {
      display: 'Morning',
      value: MORNING_START_TIME.unixTime
    };
  }
  if (
    execution_window_start_time === AFTERNOON_START_TIME.label &&
    execution_window_end_time === AFTERNOON_END_TIME.label
  ) {
    return {
      display: 'Afternoon',
      value: AFTERNOON_START_TIME.unixTime
    };
  }
  if (
    execution_window_start_time === EVENING_START_TIME.label &&
    execution_window_end_time == EVENING_END_TIME.label
  ) {
    return {
      display: 'Evening',
      value: EVENING_START_TIME.unixTime
    };
  }
  if (
    execution_window_start_time === NIGHT_START_TIME.label &&
    execution_window_end_time === NIGHT_END_TIME.label
  ) {
    return {
      display: 'Night',
      value: NIGHT_START_TIME.unixTime
    };
  }
  if (execution_window_start_time === execution_window_end_time) {
    return {
      display: `${execution_window_start_time || '—'}`,
      value:
        exactTimeOptions.find((v) => v.value === execution_window_start_time)
          ?.unixTime ?? 0
    };
  }

  return {
    display: `${execution_window_start_time} - ${execution_window_end_time}`,
    value:
      exactTimeOptions.find((v) => v.value === execution_window_start_time)
        ?.unixTime ?? 0
  };
};
