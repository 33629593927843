/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloseConnectionFlag } from './CloseConnectionFlag';
import {
    CloseConnectionFlagFromJSON,
    CloseConnectionFlagFromJSONTyped,
    CloseConnectionFlagToJSON,
} from './CloseConnectionFlag';
import type { CloseConnectionProspect } from './CloseConnectionProspect';
import {
    CloseConnectionProspectFromJSON,
    CloseConnectionProspectFromJSONTyped,
    CloseConnectionProspectToJSON,
} from './CloseConnectionProspect';
import type { CloseConnectionRelation } from './CloseConnectionRelation';
import {
    CloseConnectionRelationFromJSON,
    CloseConnectionRelationFromJSONTyped,
    CloseConnectionRelationToJSON,
} from './CloseConnectionRelation';
import type { RelationType } from './RelationType';
import {
    RelationTypeFromJSON,
    RelationTypeFromJSONTyped,
    RelationTypeToJSON,
} from './RelationType';

/**
 * 
 * @export
 * @interface CrmCloseConnectionListEntry
 */
export interface CrmCloseConnectionListEntry {
    /**
     * 
     * @type {string}
     * @memberof CrmCloseConnectionListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CrmCloseConnectionListEntry
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CrmCloseConnectionListEntry
     */
    last_name: string;
    /**
     * 
     * @type {CloseConnectionRelation}
     * @memberof CrmCloseConnectionListEntry
     */
    relation: CloseConnectionRelation;
    /**
     * 
     * @type {Array<CloseConnectionFlag>}
     * @memberof CrmCloseConnectionListEntry
     */
    flags: Array<CloseConnectionFlag>;
    /**
     * 
     * @type {RelationType}
     * @memberof CrmCloseConnectionListEntry
     */
    relation_type: RelationType;
    /**
     * 
     * @type {string}
     * @memberof CrmCloseConnectionListEntry
     */
    relation_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CrmCloseConnectionListEntry
     */
    is_added_to_audience?: boolean;
    /**
     * 
     * @type {CloseConnectionProspect}
     * @memberof CrmCloseConnectionListEntry
     */
    prospect?: CloseConnectionProspect;
}

/**
 * Check if a given object implements the CrmCloseConnectionListEntry interface.
 */
export function instanceOfCrmCloseConnectionListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "relation" in value;
    isInstance = isInstance && "flags" in value;
    isInstance = isInstance && "relation_type" in value;
    isInstance = isInstance && "relation_id" in value;

    return isInstance;
}

export function CrmCloseConnectionListEntryFromJSON(json: any): CrmCloseConnectionListEntry {
    return CrmCloseConnectionListEntryFromJSONTyped(json, false);
}

export function CrmCloseConnectionListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmCloseConnectionListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'relation': CloseConnectionRelationFromJSON(json['relation']),
        'flags': ((json['flags'] as Array<any>).map(CloseConnectionFlagFromJSON)),
        'relation_type': RelationTypeFromJSON(json['relation_type']),
        'relation_id': json['relation_id'],
        'is_added_to_audience': !exists(json, 'is_added_to_audience') ? undefined : json['is_added_to_audience'],
        'prospect': !exists(json, 'prospect') ? undefined : CloseConnectionProspectFromJSON(json['prospect']),
    };
}

export function CrmCloseConnectionListEntryToJSON(value?: CrmCloseConnectionListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'relation': CloseConnectionRelationToJSON(value.relation),
        'flags': ((value.flags as Array<any>).map(CloseConnectionFlagToJSON)),
        'relation_type': RelationTypeToJSON(value.relation_type),
        'relation_id': value.relation_id,
        'is_added_to_audience': value.is_added_to_audience,
        'prospect': CloseConnectionProspectToJSON(value.prospect),
    };
}

