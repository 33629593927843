/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Question
     */
    options?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    required?: boolean;
}

/**
 * Check if a given object implements the Question interface.
 */
export function instanceOfQuestion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'options': value.options,
        'required': value.required,
    };
}

