import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ProtectedRoute } from '../ProtectedRoute';

const AddStaffFacesheet = loadable(() => import("@/pages/AdminHub/AddStaff/AddStaffFacesheet")); // prettier-ignore
const AddStaffPage = loadable(() => import("@/pages/AdminHub/AddStaff")); // prettier-ignore
const AddStaffRecords = loadable(() => import("@/pages/AdminHub/AddStaff/AddStaffRecords")); // prettier-ignore
const AddStaffRegistrationLink = loadable(() => import("@/pages/AdminHub/AddStaff/AddStaffRegistrationLink")); // prettier-ignore

export enum AddStaffRoutes {
  AddStaff = '/:domain/admin-hub/add-staff',
  AddStaffFacesheet = '/:domain/admin-hub/add-staff/facesheet',
  AddStaffRecords = '/:domain/admin-hub/add-staff/records',
  AddStaffRegistrationLink = '/:domain/admin-hub/add-staff/registration-link'
}

export const getAddStaffRoutes = (): RouteObject[] => {
  return [
    {
      path: AddStaffRoutes.AddStaff,
      element: <ProtectedRoute component={AddStaffPage} role="L1" />,
      children: [
        {
          index: true,
          path: AddStaffRoutes.AddStaffFacesheet,
          element: <AddStaffFacesheet />
        },
        {
          path: AddStaffRoutes.AddStaffRecords,
          element: <AddStaffRecords />
        },
        {
          path: AddStaffRoutes.AddStaffRegistrationLink,
          element: <AddStaffRegistrationLink />
        }
      ]
    }
  ];
};
