import { Frequency, RRule } from 'rrule';

import { RecurrencePatternTypeEhr } from '@/models/PatternedRecurrenceEhr';
export interface FrequencyOption {
  label: string;
  value:
    | Frequency
    | 'custom'
    | 'one-time-task'
    | 'prn'
    | 'informational'
    | 'twice-a-day'
    | 'three-times-a-day'
    | 'four-times-a-day'
    | 'five-times-a-day'
    | 'hourly-or-minutely';
}

export const hourlyOrMinutelyFrequencyOption: FrequencyOption = {
  label: 'Every Few Hours',
  value: 'hourly-or-minutely'
};

export const twiceADayFrequencyOption: FrequencyOption = {
  label: 'Twice a day',
  value: 'twice-a-day'
};

export const threeTimesADayFrequencyOption: FrequencyOption = {
  label: 'Three times a day',
  value: 'three-times-a-day'
};

export const fourTimesADayFrequencyOption: FrequencyOption = {
  label: 'Four times a day',
  value: 'four-times-a-day'
};

export const fiveTimesADayFrequencyOption: FrequencyOption = {
  label: 'Five times a day',
  value: 'five-times-a-day'
};

export const customFrequencyOption: FrequencyOption = {
  label: 'Custom',
  value: 'custom'
};

export const oneTimeTaskFrequencyOption: FrequencyOption = {
  label: 'One Time Task',
  value: 'one-time-task'
};

export const dailyFrequencyOption: FrequencyOption = {
  label: 'Once a day/Every x days',
  value: RRule.DAILY
};

export const weeklyFrequencyOption: FrequencyOption = {
  label: 'Weekly',
  value: RRule.WEEKLY
};

export const monthlyFrequencyOption: FrequencyOption = {
  label: 'Monthly',
  value: RRule.MONTHLY
};

export const prnFrequencyOption: FrequencyOption = {
  label: 'PRN',
  value: 'prn'
};

export const informationalFrequencyOption: FrequencyOption = {
  label: 'Informational only/No schedule',
  value: 'informational'
};

export const carePlanFrequencyOptions: FrequencyOption[] = [
  hourlyOrMinutelyFrequencyOption,
  dailyFrequencyOption,
  twiceADayFrequencyOption,
  threeTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  fiveTimesADayFrequencyOption,
  weeklyFrequencyOption,
  monthlyFrequencyOption,
  customFrequencyOption,
  oneTimeTaskFrequencyOption,
  prnFrequencyOption,
  informationalFrequencyOption
];

export const medicationFrequencyOptions: FrequencyOption[] = [
  hourlyOrMinutelyFrequencyOption,
  dailyFrequencyOption,
  twiceADayFrequencyOption,
  threeTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  fiveTimesADayFrequencyOption,
  weeklyFrequencyOption,
  monthlyFrequencyOption,
  customFrequencyOption,
  oneTimeTaskFrequencyOption,
  prnFrequencyOption
];

export interface IntervalOption {
  label: string;
  value: number;
}

export const dailyIntervalOptions: IntervalOption[] = [
  {
    label: 'Daily',
    value: 1
  },
  {
    label: 'Every 2 days',
    value: 2
  },
  {
    label: 'Every 3 days',
    value: 3
  },
  {
    label: 'Every 4 days',
    value: 4
  },
  {
    label: 'Every 5 days',
    value: 5
  },
  {
    label: 'Every 6 days',
    value: 6
  }
];

export const weeklyIntervalOptions: IntervalOption[] = [
  {
    label: 'Week',
    value: 1
  },
  {
    label: '2 weeks',
    value: 2
  },
  {
    label: '3 weeks',
    value: 3
  }
];

export const monthlyIntervalOptions: IntervalOption[] = [
  { label: '1st day', value: 1 },
  { label: '2nd day', value: 2 },
  { label: '3rd day', value: 3 },
  { label: '4th day', value: 4 },
  { label: '5th day', value: 5 },
  { label: '6th day', value: 6 },
  { label: '7th day', value: 7 },
  { label: '8th day', value: 8 },
  { label: '9th day', value: 9 },
  { label: '10th day', value: 10 },
  { label: '11th day', value: 11 },
  { label: '12th day', value: 12 },
  { label: '13th day', value: 13 },
  { label: '14th day', value: 14 },
  { label: '15th day', value: 15 },
  { label: '16th day', value: 16 },
  { label: '17th day', value: 17 },
  { label: '18th day', value: 18 },
  { label: '19th day', value: 19 },
  { label: '20th day', value: 20 },
  { label: '21st day', value: 21 },
  { label: '22nd day', value: 22 },
  { label: '23rd day', value: 23 },
  { label: '24th day', value: 24 },
  { label: '25th day', value: 25 },
  { label: '26th day', value: 26 },
  { label: '27th day', value: 27 },
  { label: '28th day', value: 28 },
  { label: '29th day', value: 29 },
  { label: '30th day', value: 30 },
  { label: '31st day', value: 31 }
];
export interface WeeklyDayOption {
  label:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
    // Everyday should never be displayed and is only used to add twice a day
    // and three times a day schedules into this bucket
    | 'Everyday';
  value: number; // Don't use RRule.Weekday or else an error will throw when
  // creating an RRule with {byweekday:RRuleWeekday}
}

export const weeklyDayOptions: WeeklyDayOption[] = [
  {
    label: 'Sunday',
    value: 6
  },
  {
    label: 'Monday',
    value: 0
  },
  {
    label: 'Tuesday',
    value: 1
  },
  {
    label: 'Wednesday',
    value: 2
  },
  {
    label: 'Thursday',
    value: 3
  },
  {
    label: 'Friday',
    value: 4
  },
  {
    label: 'Saturday',
    value: 5
  }
];

export interface TypeOption {
  label: string;
  value: 'exactTime' | 'period' | 'partOfDay';
}

export const exactTimeTypeOption: TypeOption = {
  label: 'Exact Time',
  value: 'exactTime'
};

export const periodTypeOption: TypeOption = {
  label: 'Period',
  value: 'period'
};

export const partOfDayTypeOption: TypeOption = {
  label: 'Part of Day',
  value: 'partOfDay'
};

export const typeOptions: TypeOption[] = [
  exactTimeTypeOption,
  periodTypeOption,
  partOfDayTypeOption
];

export interface PartOfDayOption {
  label: string;
  value: 'morning' | 'afternoon' | 'evening' | 'night';
  date?: Date;
}

export const generateStaticDate = ({
  date = 1,
  hour,
  minute
}: {
  date?: number;
  hour: number;
  minute: number;
}) => {
  const staticDate = new Date();
  // Always set to same year, month, seconds, milliseconds because when
  // persisting unix times so that only the hours and minutes are different.
  // This makes sorting much easier. The date field is optionally included
  // because NIGHT_END_TIME is specially treated as next day
  staticDate.setFullYear(2020);
  staticDate.setMonth(0);
  staticDate.setDate(date);
  staticDate.setHours(hour);
  staticDate.setMinutes(minute);
  staticDate.setSeconds(0);
  staticDate.setMilliseconds(0);
  return staticDate;
};

export const morningPartOfDayOption: PartOfDayOption = {
  label: 'Morning',
  value: 'morning'
};

export const afternoonPartOfDayOption: PartOfDayOption = {
  label: 'Afternoon',
  value: 'afternoon'
};

export const eveningPartOfDayOption: PartOfDayOption = {
  label: 'Evening',
  value: 'evening'
};

export const nightPartOfDayOption: PartOfDayOption = {
  label: 'Night',
  value: 'night'
};

export const partOfDayOptions: PartOfDayOption[] = [
  morningPartOfDayOption,
  afternoonPartOfDayOption,
  eveningPartOfDayOption,
  nightPartOfDayOption
];

export interface DurationOption {
  label: string;
  value: number;
}

export const medicationDurationOptions: DurationOption[] = [
  { label: 'N/A', value: 0 },
  { label: '5 mins', value: 5 },
  { label: '10 mins', value: 10 },
  { label: '15 mins', value: 15 },
  { label: '20 mins', value: 20 },
  { label: '25 mins', value: 25 },
  { label: '30 mins', value: 30 },
  { label: '45 mins', value: 45 },
  { label: '60 mins', value: 60 },
  { label: '75 mins', value: 75 },
  { label: '90 mins', value: 90 },
  { label: '105 mins', value: 105 },
  { label: '120 mins', value: 120 }
];

export const carePlanDurationOptions: DurationOption[] = [
  { label: 'N/A', value: 0 },
  { label: '5 mins', value: 5 },
  { label: '10 mins', value: 10 },
  { label: '15 mins', value: 15 },
  { label: '20 mins', value: 20 },
  { label: '25 mins', value: 25 },
  { label: '30 mins', value: 30 },
  { label: '45 mins', value: 45 },
  { label: '60 mins', value: 60 },
  { label: '75 mins', value: 75 },
  { label: '90 mins', value: 90 },
  { label: '105 mins', value: 105 },
  { label: '120 mins', value: 120 }
];

export interface ExactTimeOption {
  label: string;
  value: string;
  unixTime: number;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
});

export const generateExactTimeOptions = (): ExactTimeOption[] => {
  const items = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute++) {
      items.push([hour, minute]);
    }
  }

  return items.map((time) => {
    const [hour, minute] = time;
    const date = generateStaticDate({ hour, minute });
    // Don't use dateFormatter.format because on Windows Chrome the space
    // is a non-breaking space. Instead, grab each part individually and inject
    // into string template so it will always have a breaking space
    const parts = dateFormatter.formatToParts(date);
    const partsKeyValues = parts.reduce((accum, { type, value }) => {
      accum[type] = value;
      return accum;
    }, {} as Record<keyof Intl.DateTimeFormatPartTypesRegistry, string>);
    const formattedTime = `${partsKeyValues['hour']}:${partsKeyValues['minute']} ${partsKeyValues['dayPeriod']}`;

    return {
      label: formattedTime,
      value: formattedTime,
      unixTime: date.getTime()
    };
  });
};

// Night 11:59 PM - 5:59 AM
// Morning 6:00 AM - 11:59 AM
// Afternoon 12:00 PM - 3:59 PM
// Evening 4:00 PM - 11:59 PM

// NIGHT_START_TIME and NIGHT_END_TIME are used for FILTER_TIME_NIGHT,
// while NIGHT_START and NIGHT_END are used for an additional check to
// to filter times between 12 AM - 5:59 AM in TasksDataGridColumns
export const NIGHT_START_TIME: ExactTimeOption = {
  label: '11:59 PM',
  value: '11:59 PM',
  unixTime: generateStaticDate({ hour: 23, minute: 59 }).getTime()
};

export const NIGHT_END_TIME: ExactTimeOption = {
  label: '5:59 AM',
  value: '5:59 AM',
  unixTime: generateStaticDate({ date: 2, hour: 5, minute: 59 }).getTime()
};

export const NIGHT_START: ExactTimeOption = {
  label: '12:00 AM',
  value: '12:00 AM',
  unixTime: generateStaticDate({ hour: 0, minute: 0 }).getTime()
};

export const NIGHT_END: ExactTimeOption = {
  label: '5:59 AM',
  value: '5:59 AM',
  unixTime: generateStaticDate({ hour: 5, minute: 59 }).getTime()
};

export const MORNING_START_TIME: ExactTimeOption = {
  label: '6:00 AM',
  value: '6:00 AM',
  unixTime: generateStaticDate({ hour: 6, minute: 0 }).getTime()
};

export const MORNING_END_TIME: ExactTimeOption = {
  label: '11:59 AM',
  value: '11:59 AM',
  unixTime: generateStaticDate({ hour: 11, minute: 59 }).getTime()
};

export const AFTERNOON_START_TIME: ExactTimeOption = {
  label: '12:00 PM',
  value: '12:00 PM',
  unixTime: generateStaticDate({ hour: 12, minute: 0 }).getTime()
};

export const AFTERNOON_END_TIME: ExactTimeOption = {
  label: '3:59 PM',
  value: '3:59 PM',
  unixTime: generateStaticDate({ hour: 15, minute: 59 }).getTime()
};

export const EVENING_START_TIME: ExactTimeOption = {
  label: '4:00 PM',
  value: '4:00 PM',
  unixTime: generateStaticDate({ hour: 16, minute: 0 }).getTime()
};

export const EVENING_END_TIME: ExactTimeOption = {
  label: '11:59 PM',
  value: '11:59 PM',
  unixTime: generateStaticDate({ hour: 23, minute: 59 }).getTime()
};

export const fiveTimesADayOptions: ExactTimeOption[] = [
  MORNING_START_TIME,
  {
    label: '10:00 AM',
    value: '10:00 AM',
    unixTime: generateStaticDate({ hour: 10, minute: 0 }).getTime()
  },
  {
    label: '2:00 PM',
    value: '2:00 PM',
    unixTime: generateStaticDate({ hour: 14, minute: 0 }).getTime()
  },
  {
    label: '6:00 PM',
    value: '6:00 PM',
    unixTime: generateStaticDate({ hour: 18, minute: 0 }).getTime()
  },
  {
    label: '10:00 PM',
    value: '10:00 PM',
    unixTime: generateStaticDate({ hour: 22, minute: 0 }).getTime()
  }
];

export const everyHourlyOptions = Array.from({ length: 6 }, (v, i) => ({
  label: i === 0 ? `${i + 1} hour` : `${i + 1} hours`,
  value: i + 1,
  type: RecurrencePatternTypeEhr.Hourly
}));

export const everyMinutelyOptions = [
  {
    label: '15 min',
    value: 15,
    type: RecurrencePatternTypeEhr.Minutely
  },
  {
    label: '30 min',
    value: 30,
    type: RecurrencePatternTypeEhr.Minutely
  }
];

// Specific options to handle minutely or hourly patterned recurrences for EHR tasks
export const everyHourlyOrMinutelyOptions = [
  ...everyMinutelyOptions,
  ...everyHourlyOptions
];
