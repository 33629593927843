/* tslint:disable */
/* eslint-disable */
/**
 * pharmacy-integration
 * pharmacy-integration
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrescriptionOrderFindOneResponse
 */
export interface PrescriptionOrderFindOneResponse {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    fax_id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    resident_name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    pharmacy_name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    date_sent: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionOrderFindOneResponse
     */
    s3_key: string;
}

/**
 * Check if a given object implements the PrescriptionOrderFindOneResponse interface.
 */
export function instanceOfPrescriptionOrderFindOneResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fax_id" in value;
    isInstance = isInstance && "resident_name" in value;
    isInstance = isInstance && "pharmacy_name" in value;
    isInstance = isInstance && "date_sent" in value;
    isInstance = isInstance && "s3_key" in value;

    return isInstance;
}

export function PrescriptionOrderFindOneResponseFromJSON(json: any): PrescriptionOrderFindOneResponse {
    return PrescriptionOrderFindOneResponseFromJSONTyped(json, false);
}

export function PrescriptionOrderFindOneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionOrderFindOneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fax_id': json['fax_id'],
        'resident_name': json['resident_name'],
        'pharmacy_name': json['pharmacy_name'],
        'date_sent': json['date_sent'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        's3_key': json['s3_key'],
    };
}

export function PrescriptionOrderFindOneResponseToJSON(value?: PrescriptionOrderFindOneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fax_id': value.fax_id,
        'resident_name': value.resident_name,
        'pharmacy_name': value.pharmacy_name,
        'date_sent': value.date_sent,
        'notes': value.notes,
        's3_key': value.s3_key,
    };
}

