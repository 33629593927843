import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCRMReferrals } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import { ReferralModel, ReferralPayload } from '@/models/ReferralModel';
import { invalidateCallsQueries } from '@/pages/CRM/hooks/useCrmCallsQuery';
import { invalidateConversationQueries } from '@/pages/CRM/hooks/useCrmConversationsQuery';
import { EVENT_LOG_FIND_ALL_QUERY_KEY } from '@/pages/CRM/hooks/useCrmEventLogQuery';
import { invalidatePhoneBookQuery } from '@/pages/CRM/hooks/useCrmPhoneBookQuery';
import { CRM_TASK_INSTANCES_COUNT_QUERY_KEY } from '@/pages/CRM/hooks/useCrmTaskInstancesQuery';
import { invalidateEmailAddressBookQuery } from '@/pages/CRM/hooks/useEmailAddressBookQuery';
import { invalidateReferralSourceCompanies } from '@/pages/CRM/hooks/useReferralSourceCompaniesQuery';

import {
  invalidateProspectResidentsQueries,
  PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
} from './useProspectResidents';

export const USE_REFERRALS_CREATE_MUTATION_KEY = ['useReferralsCreateMutation'];
const USE_REFERRALS_FIND_ALL_QUERY_KEY = ['useReferralsFindAll'];
const USE_REFERRALS_FIND_ONE_QUERY_KEY = ['useReferralsFindOne'];
const USE_REFERRALS_DASHBOARD_QUERY_KEY = ['useReferralsDashboard'];
const USE_REFERRALS_DELETE_MUTATION_KEY = ['useReferralsDeleteMutation'];

interface UseReferralsFindAllParams {
  facilityId?: string | null;
  referralSourceCompanyId?: string | null;
  options?: UseQueryOptions<ReferralModel[]>;
}

export const invalidateReferralSources = () => {
  queryClient.invalidateQueries(USE_REFERRALS_FIND_ALL_QUERY_KEY);
  queryClient.invalidateQueries(USE_REFERRALS_FIND_ONE_QUERY_KEY);
  queryClient.invalidateQueries(USE_REFERRALS_DASHBOARD_QUERY_KEY);
};

export const useReferrals = () => {
  return {
    dashboard: (
      companyId: string,
      options: UseQueryOptions<ReferralModel[]> = {}
    ) =>
      useQuery<ReferralModel[]>(
        [...USE_REFERRALS_DASHBOARD_QUERY_KEY, companyId],
        async () => {
          const payloads = await fetchCRMReferrals.get<ReferralPayload[]>(
            '/referral-sources/dashboard',
            {
              searchParams: {
                company_id: companyId
              }
            }
          );
          return payloads.map((payload) => new ReferralModel(payload));
        },
        {
          ...options,
          enabled: !!companyId
        }
      ),

    findAll: ({
      facilityId,
      referralSourceCompanyId,
      options
    }: UseReferralsFindAllParams) =>
      useQuery<ReferralModel[]>(
        [
          ...USE_REFERRALS_FIND_ALL_QUERY_KEY,
          {
            facilityId,
            referralSourceCompanyId
          }
        ],
        async () => {
          const payloads = await fetchCRMReferrals.get<ReferralPayload[]>(
            '/referral-sources',
            {
              searchParams: {
                facility_id: facilityId,
                referral_source_company_id: referralSourceCompanyId
              }
            }
          );
          return payloads.map((payload) => new ReferralModel(payload));
        },
        {
          ...options
        }
      ),
    findOne: (id: string, options: UseQueryOptions<ReferralModel> = {}) =>
      useQuery<ReferralModel>(
        [...USE_REFERRALS_FIND_ONE_QUERY_KEY, id],
        async () => {
          const payload = await fetchCRMReferrals.get<ReferralPayload>(
            `/referral-sources/${id}`
          );
          return new ReferralModel(payload);
        },
        {
          enabled: !!id,
          ...options
        }
      ),
    mutations: {
      create: useMutation(
        async (payload: Partial<ReferralPayload>) => {
          const data = await fetchCRMReferrals.post<ReferralPayload>(
            '/referral-sources',
            {
              ...payload,
              lifecycle_step: 'Opportunity'
            }
          );
          return new ReferralModel(data);
        },
        {
          onSuccess: () => {
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
            invalidateEmailAddressBookQuery();
          },
          mutationKey: USE_REFERRALS_CREATE_MUTATION_KEY
        }
      ),
      update: useMutation(
        async (payload: Partial<ReferralPayload>) => {
          const data = await fetchCRMReferrals.put<ReferralPayload>(
            `/referral-sources/${payload.id}`,
            payload
          );
          return new ReferralModel(data);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([EVENT_LOG_FIND_ALL_QUERY_KEY]);
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
            invalidateEmailAddressBookQuery();
          }
        }
      ),
      delete: useMutation(
        async (id: string) =>
          fetchCRMReferrals.delete(`/referral-sources/${id}`),
        {
          onSuccess: () => {
            invalidateReferralSources();
            invalidateReferralSourceCompanies();
            invalidateCallsQueries();
            invalidateConversationQueries();
            invalidatePhoneBookQuery();
            invalidateEmailAddressBookQuery();
            invalidateProspectResidentsQueries();
            queryClient.invalidateQueries([CRM_TASK_INSTANCES_COUNT_QUERY_KEY]);
            queryClient.invalidateQueries([
              PROSPECT_RESIDENTS_FIND_ONE_QUERY_KEY
            ]);
          },
          mutationKey: [USE_REFERRALS_DELETE_MUTATION_KEY]
        }
      )
    }
  };
};
