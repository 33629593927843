/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserName } from './UserName';
import {
    UserNameFromJSON,
    UserNameFromJSONTyped,
    UserNameToJSON,
} from './UserName';

/**
 * 
 * @export
 * @interface TaskTemplateListEntry
 */
export interface TaskTemplateListEntry {
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateListEntry
     */
    facility_id: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateListEntry
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateListEntry
     */
    task_type_id: string | null;
    /**
     * 
     * @type {UserName}
     * @memberof TaskTemplateListEntry
     */
    created_by: UserName;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateListEntry
     */
    date_created: string;
}

/**
 * Check if a given object implements the TaskTemplateListEntry interface.
 */
export function instanceOfTaskTemplateListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "task_type_id" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "date_created" in value;

    return isInstance;
}

export function TaskTemplateListEntryFromJSON(json: any): TaskTemplateListEntry {
    return TaskTemplateListEntryFromJSONTyped(json, false);
}

export function TaskTemplateListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskTemplateListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'facility_id': json['facility_id'],
        'title': json['title'],
        'task_type_id': json['task_type_id'],
        'created_by': UserNameFromJSON(json['created_by']),
        'date_created': json['date_created'],
    };
}

export function TaskTemplateListEntryToJSON(value?: TaskTemplateListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'title': value.title,
        'task_type_id': value.task_type_id,
        'created_by': UserNameToJSON(value.created_by),
        'date_created': value.date_created,
    };
}

