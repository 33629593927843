import { useQueries } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { fetchMedications } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom,
  useFacilitiesQuery
} from '@/hooks/useFacilitiesQuery';
import {
  MedicationStockItemPayload,
  MedicationStockModel
} from '@/models/MedicationStockModel';

const QUERY_KEY = 'useMedicationStockQuery';

export const useMedicationStockQuery = () => {
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom);
  let facilitiesToFetch: string[];
  const { data: facilities = [] } = useFacilitiesQuery().findAll();
  if (selectedFacilityId === ALL_FACILITIES) {
    facilitiesToFetch = facilities.map((v) => v.id);
  } else {
    facilitiesToFetch = selectedFacilityId ? [selectedFacilityId] : [];
  }
  const queries = useQueries({
    queries: facilitiesToFetch.map((facility) => ({
      queryKey: [QUERY_KEY, facility],
      queryFn: () => {
        return fetchMedications.get<MedicationStockItemPayload[]>(
          `/medications/stock`,
          { searchParams: { facilityIds: facility } }
        );
      },
      enabled: !!selectedFacilityId,
      placeholderData: [] as MedicationStockItemPayload[]
    }))
  });
  const medicationStock: MedicationStockModel[] = [];
  queries
    .map((v) => v.data)
    .forEach((v) => {
      if (v) {
        medicationStock.push(...v.map((v) => new MedicationStockModel(v)));
      }
    });
  return {
    medicationStock,
    queries: queries
  };
};

export const invalidateMedicationStockQuery = () =>
  queryClient.invalidateQueries([QUERY_KEY]);
