/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResidentAssessment } from './ResidentAssessment';
import {
    ResidentAssessmentFromJSON,
    ResidentAssessmentFromJSONTyped,
    ResidentAssessmentToJSON,
} from './ResidentAssessment';

/**
 * 
 * @export
 * @interface CreateResidentAssessment201Response
 */
export interface CreateResidentAssessment201Response {
    /**
     * 
     * @type {ResidentAssessment}
     * @memberof CreateResidentAssessment201Response
     */
    data?: ResidentAssessment;
}

/**
 * Check if a given object implements the CreateResidentAssessment201Response interface.
 */
export function instanceOfCreateResidentAssessment201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateResidentAssessment201ResponseFromJSON(json: any): CreateResidentAssessment201Response {
    return CreateResidentAssessment201ResponseFromJSONTyped(json, false);
}

export function CreateResidentAssessment201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateResidentAssessment201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ResidentAssessmentFromJSON(json['data']),
    };
}

export function CreateResidentAssessment201ResponseToJSON(value?: CreateResidentAssessment201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ResidentAssessmentToJSON(value.data),
    };
}

