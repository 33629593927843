/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmCloseConnectionListEntry } from './CrmCloseConnectionListEntry';
import {
    CrmCloseConnectionListEntryFromJSON,
    CrmCloseConnectionListEntryFromJSONTyped,
    CrmCloseConnectionListEntryToJSON,
} from './CrmCloseConnectionListEntry';

/**
 * 
 * @export
 * @interface CrmCloseConnectionsPage
 */
export interface CrmCloseConnectionsPage {
    /**
     * 
     * @type {Array<CrmCloseConnectionListEntry>}
     * @memberof CrmCloseConnectionsPage
     */
    close_connections: Array<CrmCloseConnectionListEntry>;
    /**
     * 
     * @type {number}
     * @memberof CrmCloseConnectionsPage
     */
    total_count: number;
}

/**
 * Check if a given object implements the CrmCloseConnectionsPage interface.
 */
export function instanceOfCrmCloseConnectionsPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "close_connections" in value;
    isInstance = isInstance && "total_count" in value;

    return isInstance;
}

export function CrmCloseConnectionsPageFromJSON(json: any): CrmCloseConnectionsPage {
    return CrmCloseConnectionsPageFromJSONTyped(json, false);
}

export function CrmCloseConnectionsPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrmCloseConnectionsPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'close_connections': ((json['close_connections'] as Array<any>).map(CrmCloseConnectionListEntryFromJSON)),
        'total_count': json['total_count'],
    };
}

export function CrmCloseConnectionsPageToJSON(value?: CrmCloseConnectionsPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'close_connections': ((value.close_connections as Array<any>).map(CrmCloseConnectionListEntryToJSON)),
        'total_count': value.total_count,
    };
}

