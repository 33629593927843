/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillCredit } from './BillCredit';
import {
    BillCreditFromJSON,
    BillCreditFromJSONTyped,
    BillCreditToJSON,
} from './BillCredit';
import type { BillDeposit } from './BillDeposit';
import {
    BillDepositFromJSON,
    BillDepositFromJSONTyped,
    BillDepositToJSON,
} from './BillDeposit';
import type { BillPaymentRecord } from './BillPaymentRecord';
import {
    BillPaymentRecordFromJSON,
    BillPaymentRecordFromJSONTyped,
    BillPaymentRecordToJSON,
} from './BillPaymentRecord';
import type { BillStatus } from './BillStatus';
import {
    BillStatusFromJSON,
    BillStatusFromJSONTyped,
    BillStatusToJSON,
} from './BillStatus';
import type { BillTotals } from './BillTotals';
import {
    BillTotalsFromJSON,
    BillTotalsFromJSONTyped,
    BillTotalsToJSON,
} from './BillTotals';
import type { GroupedBillItems } from './GroupedBillItems';
import {
    GroupedBillItemsFromJSON,
    GroupedBillItemsFromJSONTyped,
    GroupedBillItemsToJSON,
} from './GroupedBillItems';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';
import type { NextPreviousBill } from './NextPreviousBill';
import {
    NextPreviousBillFromJSON,
    NextPreviousBillFromJSONTyped,
    NextPreviousBillToJSON,
} from './NextPreviousBill';
import type { OneTimeBillItem } from './OneTimeBillItem';
import {
    OneTimeBillItemFromJSON,
    OneTimeBillItemFromJSONTyped,
    OneTimeBillItemToJSON,
} from './OneTimeBillItem';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Bill
 */
export interface Bill {
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    resident_id: string;
    /**
     * 
     * @type {Month}
     * @memberof Bill
     */
    month: Month;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    year: number;
    /**
     * 
     * @type {BillStatus}
     * @memberof Bill
     */
    status: BillStatus;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    billed_from: Date;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    billed_to: Date;
    /**
     * 
     * @type {User}
     * @memberof Bill
     */
    created_by: User;
    /**
     * 
     * @type {BillTotals}
     * @memberof Bill
     */
    bill_totals: BillTotals;
    /**
     * 
     * @type {GroupedBillItems}
     * @memberof Bill
     */
    bill_items?: GroupedBillItems;
    /**
     * 
     * @type {Array<OneTimeBillItem>}
     * @memberof Bill
     */
    one_time_bill_items?: Array<OneTimeBillItem>;
    /**
     * 
     * @type {Array<BillPaymentRecord>}
     * @memberof Bill
     */
    bill_payment_records: Array<BillPaymentRecord>;
    /**
     * 
     * @type {Array<BillCredit>}
     * @memberof Bill
     */
    bill_credits: Array<BillCredit>;
    /**
     * 
     * @type {Array<BillCredit>}
     * @memberof Bill
     */
    created_credits: Array<BillCredit>;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    is_one_time: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    emailed: boolean;
    /**
     * 
     * @type {NextPreviousBill}
     * @memberof Bill
     */
    next_bill?: NextPreviousBill;
    /**
     * 
     * @type {NextPreviousBill}
     * @memberof Bill
     */
    previous_bill?: NextPreviousBill;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    is_move_out_bill: boolean;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    can_be_reopened?: boolean;
    /**
     * 
     * @type {Array<BillDeposit>}
     * @memberof Bill
     */
    bill_deposits: Array<BillDeposit>;
}

/**
 * Check if a given object implements the Bill interface.
 */
export function instanceOfBill(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resident_id" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "billed_from" in value;
    isInstance = isInstance && "billed_to" in value;
    isInstance = isInstance && "created_by" in value;
    isInstance = isInstance && "bill_totals" in value;
    isInstance = isInstance && "bill_payment_records" in value;
    isInstance = isInstance && "bill_credits" in value;
    isInstance = isInstance && "created_credits" in value;
    isInstance = isInstance && "is_one_time" in value;
    isInstance = isInstance && "emailed" in value;
    isInstance = isInstance && "is_move_out_bill" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "bill_deposits" in value;

    return isInstance;
}

export function BillFromJSON(json: any): Bill {
    return BillFromJSONTyped(json, false);
}

export function BillFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resident_id': json['resident_id'],
        'month': MonthFromJSON(json['month']),
        'year': json['year'],
        'status': BillStatusFromJSON(json['status']),
        'billed_from': (new Date(json['billed_from'])),
        'billed_to': (new Date(json['billed_to'])),
        'created_by': UserFromJSON(json['created_by']),
        'bill_totals': BillTotalsFromJSON(json['bill_totals']),
        'bill_items': !exists(json, 'bill_items') ? undefined : GroupedBillItemsFromJSON(json['bill_items']),
        'one_time_bill_items': !exists(json, 'one_time_bill_items') ? undefined : ((json['one_time_bill_items'] as Array<any>).map(OneTimeBillItemFromJSON)),
        'bill_payment_records': ((json['bill_payment_records'] as Array<any>).map(BillPaymentRecordFromJSON)),
        'bill_credits': ((json['bill_credits'] as Array<any>).map(BillCreditFromJSON)),
        'created_credits': ((json['created_credits'] as Array<any>).map(BillCreditFromJSON)),
        'is_one_time': json['is_one_time'],
        'emailed': json['emailed'],
        'next_bill': !exists(json, 'next_bill') ? undefined : NextPreviousBillFromJSON(json['next_bill']),
        'previous_bill': !exists(json, 'previous_bill') ? undefined : NextPreviousBillFromJSON(json['previous_bill']),
        'is_move_out_bill': json['is_move_out_bill'],
        'name': json['name'],
        'can_be_reopened': !exists(json, 'can_be_reopened') ? undefined : json['can_be_reopened'],
        'bill_deposits': ((json['bill_deposits'] as Array<any>).map(BillDepositFromJSON)),
    };
}

export function BillToJSON(value?: Bill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resident_id': value.resident_id,
        'month': MonthToJSON(value.month),
        'year': value.year,
        'status': BillStatusToJSON(value.status),
        'billed_from': (value.billed_from.toISOString().substring(0,10)),
        'billed_to': (value.billed_to.toISOString().substring(0,10)),
        'created_by': UserToJSON(value.created_by),
        'bill_totals': BillTotalsToJSON(value.bill_totals),
        'bill_items': GroupedBillItemsToJSON(value.bill_items),
        'one_time_bill_items': value.one_time_bill_items === undefined ? undefined : ((value.one_time_bill_items as Array<any>).map(OneTimeBillItemToJSON)),
        'bill_payment_records': ((value.bill_payment_records as Array<any>).map(BillPaymentRecordToJSON)),
        'bill_credits': ((value.bill_credits as Array<any>).map(BillCreditToJSON)),
        'created_credits': ((value.created_credits as Array<any>).map(BillCreditToJSON)),
        'is_one_time': value.is_one_time,
        'emailed': value.emailed,
        'next_bill': NextPreviousBillToJSON(value.next_bill),
        'previous_bill': NextPreviousBillToJSON(value.previous_bill),
        'is_move_out_bill': value.is_move_out_bill,
        'name': value.name,
        'can_be_reopened': value.can_be_reopened,
        'bill_deposits': ((value.bill_deposits as Array<any>).map(BillDepositToJSON)),
    };
}

