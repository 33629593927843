/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';

/**
 * 
 * @export
 * @interface CriteriaCategory
 */
export interface CriteriaCategory {
    /**
     * 
     * @type {string}
     * @memberof CriteriaCategory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CriteriaCategory
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof CriteriaCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CriteriaCategory
     */
    is_informational: boolean;
    /**
     * 
     * @type {Array<Criteria>}
     * @memberof CriteriaCategory
     */
    criteria?: Array<Criteria>;
}

/**
 * Check if a given object implements the CriteriaCategory interface.
 */
export function instanceOfCriteriaCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "is_informational" in value;

    return isInstance;
}

export function CriteriaCategoryFromJSON(json: any): CriteriaCategory {
    return CriteriaCategoryFromJSONTyped(json, false);
}

export function CriteriaCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'facility_id': json['facility_id'],
        'name': json['name'],
        'is_informational': json['is_informational'],
        'criteria': !exists(json, 'criteria') ? undefined : ((json['criteria'] as Array<any>).map(CriteriaFromJSON)),
    };
}

export function CriteriaCategoryToJSON(value?: CriteriaCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facility_id': value.facility_id,
        'name': value.name,
        'is_informational': value.is_informational,
        'criteria': value.criteria === undefined ? undefined : ((value.criteria as Array<any>).map(CriteriaToJSON)),
    };
}

