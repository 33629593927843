/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Totals
 */
export interface Totals {
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    tax: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    total: number;
}

/**
 * Check if a given object implements the Totals interface.
 */
export function instanceOfTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "tax" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function TotalsFromJSON(json: any): Totals {
    return TotalsFromJSONTyped(json, false);
}

export function TotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Totals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': json['price'],
        'tax': json['tax'],
        'discount': json['discount'],
        'total': json['total'],
    };
}

export function TotalsToJSON(value?: Totals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'tax': value.tax,
        'discount': value.discount,
        'total': value.total,
    };
}

