import dayjs from 'dayjs';
import { RRule } from 'rrule';

import {
  FrequencyTimeFormData,
  ScheduleFormData
} from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  AFTERNOON_END_TIME,
  AFTERNOON_START_TIME,
  afternoonPartOfDayOption,
  dailyFrequencyOption,
  dailyIntervalOptions,
  EVENING_END_TIME,
  EVENING_START_TIME,
  eveningPartOfDayOption,
  exactTimeTypeOption,
  monthlyFrequencyOption,
  monthlyIntervalOptions,
  MORNING_END_TIME,
  MORNING_START_TIME,
  morningPartOfDayOption,
  NIGHT_END_TIME,
  NIGHT_START_TIME,
  nightPartOfDayOption,
  oneTimeTaskFrequencyOption,
  partOfDayTypeOption,
  periodTypeOption,
  prnFrequencyOption,
  weeklyDayOptions,
  weeklyFrequencyOption,
  weeklyIntervalOptions
} from '@/components/FrequencyTimeForm/options';
import { ResidentCarePlanSchedulePayload } from '@/hooks/useResidentCarePlanQuery';
import { MedicationTaskSchedulePayload } from '@/hooks/useResidentMedications';
import { PatternedRecurrenceEhr } from '@/models/PatternedRecurrenceEhr';
import { CarePlanEntryTemplateSchedule } from '@/types/assessmentsv2';

export function transformSchedulePayloadToFrequencyTimeFormData(
  schedule:
    | ResidentCarePlanSchedulePayload
    | MedicationTaskSchedulePayload
    | CarePlanEntryTemplateSchedule,
  scheduleForm?: ScheduleFormData
) {
  const frequency = {
    id: schedule.id
  } as Partial<FrequencyTimeFormData>;

  // @ts-expect-error number_of_dose_units doesn't exist on CarePlanEntryTemplateSchedule
  frequency.number_of_dose_units = schedule.number_of_dose_units as number;

  if (schedule.rrule) {
    const rruleOptions = RRule.parseString(schedule.rrule);
    const dtstart = dayjs(rruleOptions.dtstart);
    const offset = dtstart.utcOffset();
    frequency.dtstart = dtstart.subtract(offset, 'minutes').toDate();

    if (rruleOptions.until) {
      const until = dayjs(rruleOptions.until);
      const offset = until.utcOffset();
      frequency.until = until.subtract(offset, 'minutes').toDate();
    }

    if (rruleOptions.count === 0 && rruleOptions.interval === 0) {
      frequency.freq = prnFrequencyOption.value;
    } else if (rruleOptions.count === 1) {
      frequency.freq = oneTimeTaskFrequencyOption.value;
    } else if (rruleOptions.freq === RRule.DAILY) {
      frequency.freq = dailyFrequencyOption.value;
      frequency.interval = dailyIntervalOptions.find(
        (interval) => interval.value === rruleOptions.interval
      )?.value;
    } else if (rruleOptions.freq === RRule.WEEKLY) {
      frequency.freq = weeklyFrequencyOption.value;
      frequency.interval = weeklyIntervalOptions.find(
        (interval) => interval.value === rruleOptions.interval
      )?.value;
      frequency.byweekday = weeklyDayOptions.find(
        // @ts-ignore
        (day) => day.value === rruleOptions.byweekday[0].weekday
      )?.value;
    } else if (rruleOptions.freq === RRule.MONTHLY) {
      frequency.freq = monthlyFrequencyOption.value;
      frequency.bymonthday = monthlyIntervalOptions.find(
        (interval) => interval.value === rruleOptions.bymonthday
      )?.value;
    }
    // Custom schedules have rrules with byweekday options, therefore we'll put
    // that specific schedule into the correct day record property of schedules
    // array
    if (scheduleForm && rruleOptions.byweekday) {
      // @ts-ignore
      const byweekday = rruleOptions.byweekday[0].weekday;

      const weeklyDayOption = weeklyDayOptions.find(
        (day) => day.value === byweekday
      );

      scheduleForm[weeklyDayOption!.label]?.push(
        frequency as FrequencyTimeFormData
      );
    }

    if (
      scheduleForm &&
      !rruleOptions.byweekday &&
      rruleOptions.interval === 1
    ) {
      scheduleForm['Everyday'].push(frequency as FrequencyTimeFormData);
    }
  }

  const startTime = schedule.execution_window_start_time;
  const endTime = schedule.execution_window_end_time;

  if (startTime === endTime) {
    frequency.type = exactTimeTypeOption.value;
    frequency.execution_window_start_time = startTime || undefined;
    frequency.execution_window_end_time = endTime || undefined;
  } else if (
    startTime === MORNING_START_TIME.label &&
    endTime === MORNING_END_TIME.label
  ) {
    frequency.type = partOfDayTypeOption.value;
    frequency.partOfDay = morningPartOfDayOption.value;
    frequency.execution_window_start_time = MORNING_START_TIME.value;
    frequency.execution_window_end_time = MORNING_END_TIME.value;
  } else if (
    startTime === AFTERNOON_START_TIME.label &&
    endTime === AFTERNOON_END_TIME.label
  ) {
    frequency.type = partOfDayTypeOption.value;
    frequency.partOfDay = afternoonPartOfDayOption.value;
    frequency.execution_window_start_time = AFTERNOON_START_TIME.value;
    frequency.execution_window_end_time = AFTERNOON_END_TIME.value;
  } else if (
    startTime === EVENING_START_TIME.label &&
    endTime == EVENING_END_TIME.label
  ) {
    frequency.type = partOfDayTypeOption.value;
    frequency.partOfDay = eveningPartOfDayOption.value;
    frequency.execution_window_start_time = EVENING_START_TIME.value;
    frequency.execution_window_end_time = EVENING_END_TIME.value;
  } else if (
    startTime === NIGHT_START_TIME.label &&
    endTime === NIGHT_END_TIME.label
  ) {
    frequency.type = partOfDayTypeOption.value;
    frequency.partOfDay = nightPartOfDayOption.value;
    frequency.execution_window_start_time = NIGHT_START_TIME.value;
    frequency.execution_window_end_time = NIGHT_END_TIME.value;
  } else {
    frequency.type = periodTypeOption.value;
    frequency.execution_window_start_time = startTime || undefined;
    frequency.execution_window_end_time = endTime || undefined;
  }

  // For a medication task schedule, we need to check for dose_ranges
  if ((schedule as MedicationTaskSchedulePayload).dose_ranges?.length) {
    frequency.dose_ranges = (
      schedule as MedicationTaskSchedulePayload
    ).dose_ranges;
  }

  // @ts-expect-error patterned_recurrence doesn't exist on CarePlanEntryTemplateSchedule
  if (schedule.patterned_recurrence) {
    const patternedRecurrence: PatternedRecurrenceEhr = (
      schedule as
        | ResidentCarePlanSchedulePayload
        | MedicationTaskSchedulePayload
    ).patterned_recurrence!;
    frequency.freq = 'hourly-or-minutely';
    frequency.interval = patternedRecurrence.pattern?.interval;
    frequency.dtstart = dayjs(patternedRecurrence.range.startDate).toDate();
    if (patternedRecurrence.range.endDate) {
      frequency.until = dayjs(patternedRecurrence.range.endDate).toDate();
    }
    frequency.execution_window_start_time = patternedRecurrence.range.startTime;
    frequency.execution_window_end_time = patternedRecurrence.range.endTime;
  }

  return frequency as FrequencyTimeFormData;
}
