/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverrideResidentAnswerPointsRequest
 */
export interface OverrideResidentAnswerPointsRequest {
    /**
     * 
     * @type {string}
     * @memberof OverrideResidentAnswerPointsRequest
     */
    answer_option_id: string;
    /**
     * 
     * @type {number}
     * @memberof OverrideResidentAnswerPointsRequest
     */
    care_points_sum: number | null;
}

/**
 * Check if a given object implements the OverrideResidentAnswerPointsRequest interface.
 */
export function instanceOfOverrideResidentAnswerPointsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "answer_option_id" in value;
    isInstance = isInstance && "care_points_sum" in value;

    return isInstance;
}

export function OverrideResidentAnswerPointsRequestFromJSON(json: any): OverrideResidentAnswerPointsRequest {
    return OverrideResidentAnswerPointsRequestFromJSONTyped(json, false);
}

export function OverrideResidentAnswerPointsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverrideResidentAnswerPointsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer_option_id': json['answer_option_id'],
        'care_points_sum': json['care_points_sum'],
    };
}

export function OverrideResidentAnswerPointsRequestToJSON(value?: OverrideResidentAnswerPointsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer_option_id': value.answer_option_id,
        'care_points_sum': value.care_points_sum,
    };
}

