import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from '../Accordion';
import { FormHeader } from '../FormHeader';

import {
  getCommonSlidingScaleDoseForSchedules,
  parseScheduleForAllSameDosage
} from './CustomFrequency';
import { getScheduleFieldNamesMap } from './CustomFrequencyDayForm';
import { ScheduleFormData } from './FrequencyTimeForm';
import {
  fiveTimesADayFrequencyOption,
  fourTimesADayFrequencyOption,
  threeTimesADayFrequencyOption,
  twiceADayFrequencyOption
} from './options';
import ScheduleDoseForm from './ScheduleDoseForm';
import { TypeAndTimeInput } from './TypeAndTimeInput';

interface MultipleTimesADayProps {
  formMethods: UseFormReturn<any>;
  frequencyPath: string;
  schedulePath: string;
  isMedicationsDrawer: boolean;
  disabled?: boolean;
}

export const MultipleTimesADay: React.FC<MultipleTimesADayProps> = ({
  formMethods,
  frequencyPath,
  schedulePath,
  isMedicationsDrawer = false,
  disabled = false
}) => {
  const fieldArrayName = `${schedulePath}.Everyday`;

  const [scheduleHasSameDoseUnits, setScheduleHasSameDoseUnits] =
    React.useState(false);

  const [numberOfDoseUnits, dose, frequency, slidingScaleDoseRange] =
    formMethods.watch([
      `${frequencyPath}.number_of_dose_units`,
      'dose',
      `${frequencyPath}.freq`,
      `${frequencyPath}.dose_ranges`
    ]);

  const { fields, replace, update } = useFieldArray({
    control: formMethods.control,
    name: fieldArrayName,
    keyName: '_id'
  });

  // Initialize the drawer with same number of dose units at the parent
  // frequency if all schedules have the same number of dose units or
  // if they all have the same sliding scale dose ranges
  React.useEffect(() => {
    const scheduleFormData = formMethods.getValues(
      schedulePath
    ) as ScheduleFormData;
    const commonSlidingScale =
      getCommonSlidingScaleDoseForSchedules(scheduleFormData);

    const {
      scheduleHasSameDoseUnits,
      numberOfDoseUnits: defaultNumberOfDoses
    } = parseScheduleForAllSameDosage(scheduleFormData, dose);

    if (!commonSlidingScale) {
      setScheduleHasSameDoseUnits(scheduleHasSameDoseUnits);
      formMethods.setValue(
        `${frequencyPath}.number_of_dose_units`,
        defaultNumberOfDoses
      );
    } else {
      setScheduleHasSameDoseUnits(true);
      formMethods.setValue(`${frequencyPath}.dose_ranges`, commonSlidingScale);
    }
  }, [setScheduleHasSameDoseUnits, dose]);

  // Handles the logic for setting the individual FrequencyTimeFormData to the
  // "master" freq.number_of_dose_units and freq.dose_ranges
  React.useEffect(() => {
    if (scheduleHasSameDoseUnits) {
      const fieldsWithSameDosage = fields.map((item) => ({
        ...item,
        ...(numberOfDoseUnits && {
          number_of_dose_units: numberOfDoseUnits
        }),
        ...(slidingScaleDoseRange && {
          dose_ranges: slidingScaleDoseRange
        })
      }));
      replace(fieldsWithSameDosage);
    }
  }, [scheduleHasSameDoseUnits, numberOfDoseUnits, slidingScaleDoseRange]);

  const updateArrayFields = () => {
    // @ts-expect-error #HACK: Force fields re-render. If this didn't
    // exist, edits to these fields will be lost when updating
    // number_of_dose_units because its copying the old fields
    // item
    update();
  };

  return (
    <>
      {isMedicationsDrawer && (
        <>
          <Grid xs={12} justifyContent="flex-start">
            <FormGroup
              onKeyDown={(e) =>
                e.key === 'Enter' && setScheduleHasSameDoseUnits((old) => !old)
              }
              onChange={() => setScheduleHasSameDoseUnits((old) => !old)}>
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                checked={scheduleHasSameDoseUnits}
                control={<Checkbox />}
                label="Same number of dosage units for schedule"
                disabled={disabled}
              />
            </FormGroup>
          </Grid>
        </>
      )}
      {scheduleHasSameDoseUnits && isMedicationsDrawer && (
        <ScheduleDoseForm
          formMethods={formMethods}
          frequencyPath={frequencyPath}
          slidingScaleDoseActive={slidingScaleDoseRange !== undefined}
          disabled={disabled}
        />
      )}
      <Grid xs={12}>
        <StyledAccordionWrapper
          frequency={frequency}
          isMedicationsDrawer={isMedicationsDrawer}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between">
            {fields.map((field, index) => {
              const fieldNames = getScheduleFieldNamesMap(
                fieldArrayName,
                index
              );
              const fieldSlidingScaleDoseRange = (field as any).dose_ranges
                ? true
                : false;
              return (
                <>
                  <TypeAndTimeInput
                    fieldNames={fieldNames}
                    formMethods={formMethods}
                    handleOnChange={updateArrayFields}
                    disabled={disabled}
                  />
                  {!scheduleHasSameDoseUnits && isMedicationsDrawer && (
                    <ScheduleDoseForm
                      formMethods={formMethods}
                      frequencyPath={`${fieldArrayName}.${index}`}
                      slidingScaleDoseActive={fieldSlidingScaleDoseRange}
                      handleOnChange={updateArrayFields}
                      disabled={disabled}
                    />
                  )}
                  {index !== fields.length - 1 && (
                    <Grid xs={12}>
                      <Divider sx={{ borderBottomWidth: '1.5px' }} />
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </StyledAccordionWrapper>
      </Grid>
    </>
  );
};

type StyledAccordionWrapperProps = React.PropsWithChildren<{
  frequency: any;
  isMedicationsDrawer?: boolean;
}>;

const StyledAccordionWrapper = ({
  children,
  frequency,
  isMedicationsDrawer = false
}: StyledAccordionWrapperProps) => {
  // We just need to find frequency option from the value so we can display
  // the value in the accordion header
  const frequencyOption = React.useMemo(() => {
    return [
      twiceADayFrequencyOption,
      threeTimesADayFrequencyOption,
      fourTimesADayFrequencyOption,
      fiveTimesADayFrequencyOption
    ].find((opt) => opt.value === frequency)!;
  }, [frequency]);

  if (
    isMedicationsDrawer &&
    FeatureFlagService.isEnabled(ExacareFeature.MEDICATION_TASK_REGIMENS)
  ) {
    return <>{children}</>;
  }

  return (
    <StyledAccordion expanded>
      <StyledAccordionSummary
        sx={{
          height: 62,
          '& .MuiAccordionSummary-expandIconWrapper': {
            order: -1
          }
        }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <FormHeader
            text={frequencyOption.label}
            sx={{ paddingLeft: '12px' }}
          />
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
