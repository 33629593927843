import React, { forwardRef } from 'react';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { MedicationIndicatorChip } from '@/components/MedicationIndicators/MedicationIndicators';
import { DEA_SCHEDULE_CODES } from '@/constants';
import { useFacilitiesQuery } from '@/hooks/useFacilitiesQuery';
import { MedicationStockItemPayload } from '@/models/MedicationStockModel';
import {
  MedicationStockSignOffSignedBy,
  MedicationType
} from '@/types/medications';

export interface MedicationInventorySignOffPrintViewDetails {
  signed_by: MedicationStockSignOffSignedBy;
  type: MedicationType;
  date: string;
}

interface MedicationInventoryPrintViewProps {
  details: MedicationInventorySignOffPrintViewDetails;
  data: MedicationStockItemPayload[];
  facilityId: string;
}

const MedicationInventoryPrintView = forwardRef<
  HTMLDivElement,
  MedicationInventoryPrintViewProps
>(({ details, data, facilityId }, ref) => {
  const facility = useFacilitiesQuery().findOne(facilityId);
  const facilityName = facility?.data?.name;

  return (
    <Box ref={ref} sx={{ padding: 3 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}>
        <Typography variant="h4" gutterBottom>
          Medication Stock
        </Typography>
        <Box flexGrow={1} />
        <Stack direction="column" alignItems="flex-start">
          <Typography>Community</Typography>
          <Typography variant="h6">{facilityName}</Typography>
        </Stack>
      </Stack>
      <Box sx={{ marginBottom: 2 }}>
        <Typography>Sign-Off Details</Typography>
        <Stack direction="row" alignItems="center" gap={5}>
          <Typography variant="inherit">
            By:{' '}
            {`${details?.signed_by.first_name} ${details?.signed_by.last_name}` ||
              'N/A'}
          </Typography>
          <Typography variant="inherit">
            Type:{' '}
            {details?.type === 'ALL'
              ? 'All Meds'
              : 'Controlled Substance' || 'N/A'}
          </Typography>
          <Typography variant="inherit">
            At: {details?.date || 'N/A'}
          </Typography>
        </Stack>
      </Box>
      <TableContainer
        sx={{ borderRadius: 2, border: '1px solid #ddd', overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Medication</TableCell>
              <TableCell>Resident</TableCell>
              <TableCell>Doctor</TableCell>
              <TableCell>Quantity Remaining</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Stack direction="column">
                    <Stack direction="row" gap={1}>
                      {item.medication.DrugNameDesc ?? 'N/A'}
                      {DEA_SCHEDULE_CODES.includes(
                        item.medication.FederalDEAClassCode!
                      ) && (
                        <MedicationIndicatorChip
                          type="controlled-substance"
                          size="small"
                          label="CS"
                        />
                      )}
                    </Stack>
                    <Typography variant="body2" color="textSecondary">
                      {item.medication.DispensableDrugDesc ?? 'N/A'}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  {item.resident.firstName ?? 'N/A'} {item.resident.lastName}
                </TableCell>
                <TableCell>{item.doctor.name ?? 'N/A'}</TableCell>
                <TableCell>{item.stock.remainingStockUnits ?? 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

MedicationInventoryPrintView.displayName = 'MedicationInventoryPrintView';

export default MedicationInventoryPrintView;
