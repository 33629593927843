import React from 'react';
import { Stack, Typography } from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { UserClockAction } from '@/hooks/useTimeClock';

interface ClockInDialogHeadersProps {
  action: UserClockAction;
}

export const ClockInDialogHeaders: React.FC<ClockInDialogHeadersProps> = ({
  action
}) => {
  const currentUser = useCurrentUser().data!;

  return (
    <Stack direction="column" alignItems="center" spacing={1}>
      <Typography variant="h5" color="secondary" fontSize="1.375rem">
        {action === 'in' ? 'Welcome back' : 'See you soon'},{' '}
        {currentUser.getFullName()}
      </Typography>
      <Typography color="#667A86" textAlign="center" fontSize="1rem">
        To confirm, point the camera at your face and take photo. Clock {action}{' '}
        time will be based on when you initiated this process.
      </Typography>
    </Stack>
  );
};
