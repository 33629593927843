import React, { useContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Cancel, Language } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  IconButton,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { useFindAllCareCategoriesQuery } from '@/hooks/useCareCategoriesQuery';
import { useCarePlanAssistanceLevelsQuery } from '@/hooks/useCarePlanAssistanceLevelsQuery';
import useResidentCarePlanQuery, {
  toCarePlanPayload
} from '@/hooks/useResidentCarePlanQuery';
import { useResidentInformationalQuery } from '@/hooks/useResidentInformationalQuery';
import { useFindOneResidentQuery } from '@/hooks/useResidentQuery';
import { useFindAllResponsiblePartiesQuery } from '@/hooks/useResponsiblePartiesQuery';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  ObjectWithTaskExtension,
  TaskExtensionModel
} from '@/models/TaskExtensionModel';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';
import { AddResidentCarePlan } from '@/stores/residentCarePlanAtom';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { ControlledSelect } from '../ControlledSelect';
import { ControlledTextField } from '../ControlledTextField';
import { FormHeader } from '../FormHeader';
import { FrequencyTimeForm } from '../FrequencyTimeForm/FrequencyTimeForm';
import {
  carePlanDurationOptions,
  carePlanFrequencyOptions
} from '../FrequencyTimeForm/options';
import ResponsiblePartySelectable from '../ResponsiblePartySelectable';
import { isStaffResponsibilityV2 } from '../ResponsiblePartySelectable/options';

import CarePlanExtras from './CarePlanExtras';

export interface NumberOfAssistantOption {
  value: number;
  label: string;
}

export const numberOfAssisantsOptions: NumberOfAssistantOption[] = [
  {
    value: 0,
    label: 'No assistants'
  },
  {
    value: 1,
    label: '1 assistant'
  },
  {
    value: 2,
    label: '2 assistants'
  },
  {
    value: 3,
    label: '3 assistants'
  },
  {
    value: 4,
    label: '4 assistants'
  },
  {
    value: 5,
    label: '5 assistants'
  },
  {
    value: 6,
    label: '6 assistants'
  }
];

interface CarePlanDrawerProps {
  type?: 'Add' | 'Edit' | 'Readonly';
  carePlan?: AddResidentCarePlan;
  onClose: VoidFunction;
}

export const CarePlanDrawer: React.FC<CarePlanDrawerProps> = ({
  type,
  carePlan,
  onClose
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const residentId = useParams().resident_id!;
  const resident = useFindOneResidentQuery({ residentId, timezone: true })
    .data!;
  const { showSnackbar } = useSnackbar();
  const {
    mutations: { post, put }
  } = useResidentCarePlanQuery(residentId);
  const formMethods = useForm<AddResidentCarePlan>({
    mode: 'all',
    defaultValues: {
      ...carePlan,
      task_extension: TaskExtensionModel.toTaskExtensionForm(
        carePlan?.task_extension
      )
    }
  });

  const responsibleParty = formMethods.watch('responsible_party');
  const { data: careLevelOptions } =
    useCarePlanAssistanceLevelsQuery().findAll();
  const { data: careCategoryOptions } = useFindAllCareCategoriesQuery();
  const { data: responsibleParties } = useFindAllResponsiblePartiesQuery();
  const invalidateInformationalQueries =
    useResidentInformationalQuery().invalidate;
  const { refetch: refetchInformationalQueries } =
    useResidentInformationalQuery().count({
      id: residentId
    });

  const handleSubmitCarePlan = formMethods.handleSubmit(async (values) => {
    const payload = toCarePlanPayload(
      values,
      resident.getTzid(),
      responsibleParties || []
    );

    (type === 'Add' ? post : put).mutate(payload, {
      onSuccess: () => {
        showSnackbar({
          message: `${type === 'Add' ? 'Added' : 'Edited'} "${
            values.title
          }" care plan`,
          severity: 'success'
        });
        invalidateInformationalQueries();
        refetchInformationalQueries();
        onClose();
      },
      onError: () => {
        showSnackbar({
          message: `Error ${type === 'Add' ? 'adding' : 'editing'} care plan`,
          severity: 'error'
        });
      }
    });
  });

  useEffect(() => {
    if (
      !responsibleParties ||
      responsibleParties.length === 0 ||
      type === 'Edit'
    )
      return;
    const defaultParty = responsibleParties?.find(
      (rp) => rp.default_for_care_plan === true
    );
    if (defaultParty) {
      formMethods.setValue('responsible_party', defaultParty.id);
    }
  }, [responsibleParties]);

  const frequency = formMethods.watch('frequency.freq');

  return (
    <>
      <Stack component="header">
        {`${type} Care`}
        <IconButton onClick={onClose}>
          <Cancel />
        </IconButton>
      </Stack>

      <Stack className="drawer-paper-content" gap={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <FormHeader Icon={Language} text="Information" />
          </Grid>
          <Grid xs={12} md={6}>
            <ControlledTextField
              control={formMethods.control}
              name="title"
              label="Care/Service"
              placeholder="Shower"
              disabled={type === 'Readonly'}
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ControlledSelect
              control={formMethods.control}
              name="careCategory"
              label="Category"
              disabled={type === 'Readonly'}
              options={careCategoryOptions ?? []}
              optionIdKey="id"
              optionLabelKey="title"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledSelect
              control={formMethods.control}
              disabled={type === 'Readonly'}
              name="careLevel"
              label="Level of assistance"
              options={careLevelOptions ?? []}
              optionIdKey="id"
              optionLabelKey="title"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledSelect
              disabled={type === 'Readonly'}
              control={formMethods.control}
              name="numAssistants"
              label="Number of assistants"
              options={numberOfAssisantsOptions}
              optionIdKey="value"
              optionLabelKey="label"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={12}>
            <ResponsiblePartySelectable
              formMethods={formMethods}
              name="responsible_party"
              disabled={isResidentArchived}
              responsibleParties={responsibleParties}
              isEditableParty={type !== 'Readonly' && !isResidentArchived}
            />
          </Grid>
          <Grid xs={12}>
            <ControlledTextField
              disabled={type === 'Readonly'}
              control={formMethods.control}
              name="instructions"
              label="Care Description"
              multiline
              placeholder="Assist with undressing. Use roller to transport resident to the bath. Towel dry and help get dressed"
              rules={{ required: true }}
            />
          </Grid>
          <Grid xs={12}>
            <ControlledTextField
              disabled={type === 'Readonly'}
              control={formMethods.control}
              name="preferences"
              placeholder="Resident prefers 90 degree shower temperature"
              label="Resident Preferences"
              multiline
              rules={{ required: false }}
            />
          </Grid>
        </Grid>
        {isStaffResponsibilityV2(responsibleParty, responsibleParties) && (
          <>
            <FrequencyTimeForm
              residentId={residentId}
              formMethods={formMethods}
              frequencyOptions={carePlanFrequencyOptions}
              durationInput={
                <ControlledSelect
                  control={formMethods.control}
                  label="Duration"
                  disabled={isResidentArchived}
                  name="duration"
                  options={carePlanDurationOptions}
                  optionIdKey="value"
                  optionLabelKey="label"
                  rules={{ required: true }}
                />
              }
            />
          </>
        )}
        {FeatureFlagService.isEnabled(ExacareFeature.EHR_TASK_EXTENSIONS) &&
          frequency !== 'prn' &&
          frequency !== 'informational' && (
            <CarePlanExtras
              disabled={type === 'Readonly'}
              formMethods={
                formMethods as unknown as UseFormReturn<ObjectWithTaskExtension>
              }
              isResidentArchived={isResidentArchived}
            />
          )}
      </Stack>

      <Stack component="footer">
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        {type !== 'Readonly' && (
          <LoadingButton
            data-testid="submit-care-plan"
            variant="contained"
            color="primary"
            loading={put.isLoading || post.isLoading}
            onClick={handleSubmitCarePlan}>
            {type === 'Add' ? 'Add Care Plan' : 'Save Changes'}
          </LoadingButton>
        )}
      </Stack>
    </>
  );
};
