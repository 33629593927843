/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BidirectionalActionType = {
    Accept: 'Accept',
    Decline: 'Decline',
    RequestInformation: 'RequestInformation'
} as const;
export type BidirectionalActionType = typeof BidirectionalActionType[keyof typeof BidirectionalActionType];


export function BidirectionalActionTypeFromJSON(json: any): BidirectionalActionType {
    return BidirectionalActionTypeFromJSONTyped(json, false);
}

export function BidirectionalActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionType {
    return json as BidirectionalActionType;
}

export function BidirectionalActionTypeToJSON(value?: BidirectionalActionType | null): any {
    return value as any;
}

