/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarePlanCategory } from './CarePlanCategory';
import {
    CarePlanCategoryFromJSON,
    CarePlanCategoryFromJSONTyped,
    CarePlanCategoryToJSON,
} from './CarePlanCategory';

/**
 * 
 * @export
 * @interface CarePlan
 */
export interface CarePlan {
    /**
     * 
     * @type {Array<CarePlanCategory>}
     * @memberof CarePlan
     */
    categories: Array<CarePlanCategory>;
}

/**
 * Check if a given object implements the CarePlan interface.
 */
export function instanceOfCarePlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function CarePlanFromJSON(json: any): CarePlan {
    return CarePlanFromJSONTyped(json, false);
}

export function CarePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(CarePlanCategoryFromJSON)),
    };
}

export function CarePlanToJSON(value?: CarePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(CarePlanCategoryToJSON)),
    };
}

