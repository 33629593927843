import React, { useState } from 'react';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  DateRange as DateRangeIcon
} from '@mui/icons-material';
import {
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import useForkRef from '@mui/utils/useForkRef';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
  DateRangePicker as MuiDateRangePicker,
  DateRangePickerProps as MuiDateRangePickerProps
} from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';

export interface DateRangePickerProps {
  isLoading: boolean;
  dateRange: [dateFrom: Date | null, dateTo: Date | null];
  maxDateRangeInDays?: number;
  setDateRange:
    | React.Dispatch<React.SetStateAction<[dateFrom?: null, dateTo?: null]>>
    | React.Dispatch<React.SetStateAction<[dateFrom: Date, dateTo: Date]>>;
  disableArrows?: boolean;
  clearable?: boolean;
  sx?: SxProps<Theme>;
  variant?: 'desktop' | 'mobile';
}

const DAYS_TO_INCREMENT_DECREMENT = 7;

interface ClearableDateRangeProps {
  clearable: boolean;
  clearDateRange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface DateRangeFieldProps extends SingleInputDateRangeFieldProps<Date> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
}

type DateRangeFieldComponent = ((
  props: DateRangeFieldProps &
    React.RefAttributes<HTMLDivElement> &
    ClearableDateRangeProps
) => React.ReactElement) & {
  fieldType?: string;
  displayName?: string;
};

const DateRangeField = React.forwardRef(
  (
    props: DateRangeFieldProps & ClearableDateRangeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {
      setOpen,
      label,
      id,
      InputProps: { ref: containerRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
      clearable,
      clearDateRange,
      sx = {}
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <Stack
        id={id}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => setOpen?.((prev) => !prev)}
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          border: '1px solid #364955',
          borderRadius: '1000px',
          height: 42,
          p: '0 16px',
          '&:hover': {
            cursor: 'pointer'
          },
          ...sx
        }}>
        <DateRangeIcon fontSize="small" htmlColor="#364955" />
        <Typography color="#364955" fontSize="0.875rem">
          {label}
        </Typography>
        {clearable && (
          <IconButton onClick={clearDateRange} size="small">
            <Close fontSize="small" />
          </IconButton>
        )}
      </Stack>
    );
  }
) as DateRangeFieldComponent;
DateRangeField.displayName = 'DateRangeField';
DateRangeField.fieldType = 'single-input';

const DateRangePickerWithCustomField = React.forwardRef(
  (
    props: Omit<MuiDateRangePickerProps<Date>, 'open' | 'onOpen' | 'onClose'> &
      ClearableDateRangeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [open, setOpen] = React.useState(false);
    const { clearable, clearDateRange } = props;
    return (
      <MuiDateRangePicker
        slots={{ field: DateRangeField, ...props.slots }}
        slotProps={{ field: { setOpen, clearable, clearDateRange } as any }}
        ref={ref}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    );
  }
);
DateRangePickerWithCustomField.displayName = 'DateRangePickerWithCustomField';

export const DateRangePicker = (props: DateRangePickerProps) => {
  const {
    sx,
    isLoading,
    dateRange,
    maxDateRangeInDays,
    setDateRange,
    variant = 'desktop',
    disableArrows = false,
    clearable = false
  } = props;
  const [originalDateRange] = useState<DateRange<Date>>(dateRange);
  const [datePickerValue, setDatePickerValue] =
    useState<DateRange<Date>>(dateRange);
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  const handleDecrementDateRange = () => {
    const decrementedDateFrom = dayjs(datePickerValue[0])
      .subtract(DAYS_TO_INCREMENT_DECREMENT, 'days')
      .toDate();
    const decrementedDateTo = dayjs(datePickerValue[1])
      .subtract(DAYS_TO_INCREMENT_DECREMENT, 'days')
      .toDate();
    setDatePickerValue([decrementedDateFrom, decrementedDateTo] as any);
    setDateRange([decrementedDateFrom, decrementedDateTo] as any);
  };

  const handleIncrementDateRange = () => {
    const incrementedDateFrom = dayjs(datePickerValue[0])
      .add(DAYS_TO_INCREMENT_DECREMENT, 'days')
      .toDate();
    const incrementedDateTo = dayjs(datePickerValue[1])
      .add(DAYS_TO_INCREMENT_DECREMENT, 'days')
      .toDate();
    setDatePickerValue([incrementedDateFrom, incrementedDateTo] as any);
    setDateRange([incrementedDateFrom, incrementedDateTo] as any);
  };

  const clearDateRange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setDatePickerValue([null, null]);
    setDateRange([null, null] as any);
  };

  const dateRangeDisplay = React.useMemo(() => {
    if (!datePickerValue[0] || !datePickerValue[1]) {
      return 'Select date range';
    }
    const formattedDateFrom = dayjs(datePickerValue[0]).format(
      isMediaQueryDownLg ? 'M/D/YY' : 'D MMM YYYY'
    );
    const formattedDateTo = dayjs(datePickerValue[1]).format(
      isMediaQueryDownLg ? 'M/D/YY' : 'D MMM YYYY'
    );
    return `${formattedDateFrom} \u2013 ${formattedDateTo}`;
  }, [datePickerValue, isMediaQueryDownLg]);

  return (
    <Stack direction="row" alignItems="center">
      {!disableArrows && (
        <IconButton
          onClick={handleDecrementDateRange}
          size="small"
          data-testid="date-range-picker-previous">
          <ArrowBackIos fontSize="small" />
        </IconButton>
      )}
      <DateRangePickerWithCustomField
        clearable={clearable}
        clearDateRange={clearDateRange}
        label={dateRangeDisplay}
        value={datePickerValue}
        closeOnSelect={true}
        disabled={isLoading}
        sx={{
          ...(variant === 'mobile' && mobileStyles),
          ...sx
        }}
        shouldDisableDate={(day, position) => {
          if (!maxDateRangeInDays) {
            return false;
          }
          if (position === 'start') {
            // Allow to select all days as start of the range.
            return false;
          }
          // Allow to select only {maxDateRangeInDays} days in the future as end.
          const dayToValidate = dayjs(day as Date);
          const startDate = dayjs(datePickerValue[0]);

          return (
            dayjs(dayToValidate).isAfter(startDate, 'days') &&
            dayjs(dayToValidate).diff(startDate, 'days') > maxDateRangeInDays
          );
        }}
        onChange={(value) => {
          const [dateFrom, dateTo] = value;
          if (dateFrom instanceof Date && dateTo instanceof Date) {
            setDatePickerValue([dateFrom, dateTo]);
          } else if (dateFrom instanceof Date) {
            setDatePickerValue([dateFrom, datePickerValue[1]]);
          } else if (dateTo instanceof Date) {
            setDatePickerValue([datePickerValue[0], dateTo]);
          }
        }}
        onAccept={(value) => {
          const [dateFrom, dateTo] = value;
          if (dateFrom instanceof Date && dateTo instanceof Date) {
            setDateRange([dateFrom, dateTo] as any);
            const dayFrom = dayjs(dateFrom);
            const dayTo = dayjs(dateTo);
            // These edge cases should never happen, but just in case.
            if (dayFrom.isAfter(dayjs(dayTo), 'day')) {
              // Incorrect data selected. Reset the values
              setDatePickerValue(originalDateRange);
              setDateRange([originalDateRange[0], originalDateRange[1]] as any);
              return;
            }
            if (dayTo.diff(dayFrom, 'days') > maxDateRangeInDays!) {
              // Incorrect data selected. Reset the values
              setDatePickerValue(originalDateRange);
              setDateRange([originalDateRange[0], originalDateRange[1]] as any);
              return;
            }
          }
        }}
      />
      {!disableArrows && (
        <IconButton
          onClick={handleIncrementDateRange}
          size="small"
          data-testid="date-range-picker-next">
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};

const mobileStyles = {
  border: 'none',
  cursor: 'pointer',
  padding: '0 8px',
  '& svg': {
    color: '#667A86',
    width: '24px',
    height: '24px'
  },
  '& .MuiTypography-root': {
    display: 'none'
  }
};
