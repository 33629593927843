import React from 'react';
import { Button, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { UserClockAction } from '@/hooks/useTimeClock';

import { ClockInDialogCaptureFrame } from './ClockInDialogCaptureFrame';
import { ClockInDialogHeaders } from './ClockInDialogHeaders';
import { UseMediaStreamReturn } from './useMediaStream';

interface ClockInDialogPhotoProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  handleTakePhoto: VoidFunction;
  action: UserClockAction;
  mediaStream: UseMediaStreamReturn;
  date: dayjs.Dayjs;
}

export const ClockInDialogPhoto: React.FC<ClockInDialogPhotoProps> = ({
  handleTakePhoto,
  videoRef,
  action,
  mediaStream,
  date
}) => {
  React.useEffect(() => {
    if (mediaStream.stream && videoRef.current) {
      // We'll always see this exception in the console but it is safe to ignore
      // Uncaught (in promise) DOMException: The play() request was interrupted by a new load request. https://goo.gl/LdLk22
      videoRef.current!.srcObject = mediaStream.stream!;
      videoRef.current!.play();
    }
  }, [videoRef.current, mediaStream]);

  return (
    <Stack
      id="clock-in-dialog-photo"
      direction="column"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
      height="100%">
      <ClockInDialogHeaders action={action} />
      <ClockInDialogCaptureFrame action={action} date={date}>
        <video
          ref={videoRef}
          style={{
            backgroundColor: 'black',
            width: '100%',
            height: 'inherit',
            position: 'absolute',
            bottom: 0
          }}
        />
      </ClockInDialogCaptureFrame>
      <Button color="primary" variant="contained" onClick={handleTakePhoto}>
        Take a Photo
      </Button>
    </Stack>
  );
};
