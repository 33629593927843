/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentProtocolFollowup } from './IncidentProtocolFollowup';
import {
    IncidentProtocolFollowupFromJSON,
    IncidentProtocolFollowupFromJSONTyped,
    IncidentProtocolFollowupToJSON,
} from './IncidentProtocolFollowup';
import type { IncidentProtocolParty } from './IncidentProtocolParty';
import {
    IncidentProtocolPartyFromJSON,
    IncidentProtocolPartyFromJSONTyped,
    IncidentProtocolPartyToJSON,
} from './IncidentProtocolParty';

/**
 * 
 * @export
 * @interface CreateIncidentProtocolActionRequest
 */
export interface CreateIncidentProtocolActionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    incident_protocol_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    incident_setting_action_type_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    deadline?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIncidentProtocolActionRequest
     */
    is_optional?: boolean;
    /**
     * 
     * @type {Array<IncidentProtocolParty>}
     * @memberof CreateIncidentProtocolActionRequest
     */
    responsible_parties?: Array<IncidentProtocolParty>;
    /**
     * 
     * @type {Array<IncidentProtocolFollowup>}
     * @memberof CreateIncidentProtocolActionRequest
     */
    followups?: Array<IncidentProtocolFollowup>;
    /**
     * 
     * @type {any}
     * @memberof CreateIncidentProtocolActionRequest
     */
    complete_followups?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentProtocolActionRequest
     */
    incomplete_followup_time?: string;
}

/**
 * Check if a given object implements the CreateIncidentProtocolActionRequest interface.
 */
export function instanceOfCreateIncidentProtocolActionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "incident_protocol_id" in value;

    return isInstance;
}

export function CreateIncidentProtocolActionRequestFromJSON(json: any): CreateIncidentProtocolActionRequest {
    return CreateIncidentProtocolActionRequestFromJSONTyped(json, false);
}

export function CreateIncidentProtocolActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIncidentProtocolActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident_protocol_id': json['incident_protocol_id'],
        'incident_setting_action_type_id': !exists(json, 'incident_setting_action_type_id') ? undefined : json['incident_setting_action_type_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        'is_optional': !exists(json, 'is_optional') ? undefined : json['is_optional'],
        'responsible_parties': !exists(json, 'responsible_parties') ? undefined : ((json['responsible_parties'] as Array<any>).map(IncidentProtocolPartyFromJSON)),
        'followups': !exists(json, 'followups') ? undefined : ((json['followups'] as Array<any>).map(IncidentProtocolFollowupFromJSON)),
        'complete_followups': !exists(json, 'complete_followups') ? undefined : json['complete_followups'],
        'incomplete_followup_time': !exists(json, 'incomplete_followup_time') ? undefined : json['incomplete_followup_time'],
    };
}

export function CreateIncidentProtocolActionRequestToJSON(value?: CreateIncidentProtocolActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident_protocol_id': value.incident_protocol_id,
        'incident_setting_action_type_id': value.incident_setting_action_type_id,
        'name': value.name,
        'description': value.description,
        'deadline': value.deadline,
        'is_optional': value.is_optional,
        'responsible_parties': value.responsible_parties === undefined ? undefined : ((value.responsible_parties as Array<any>).map(IncidentProtocolPartyToJSON)),
        'followups': value.followups === undefined ? undefined : ((value.followups as Array<any>).map(IncidentProtocolFollowupToJSON)),
        'complete_followups': value.complete_followups,
        'incomplete_followup_time': value.incomplete_followup_time,
    };
}

