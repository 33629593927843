/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncLeadSOffenderRecordsByIdRequest
 */
export interface SyncLeadSOffenderRecordsByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncLeadSOffenderRecordsByIdRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncLeadSOffenderRecordsByIdRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncLeadSOffenderRecordsByIdRequest
     */
    dob?: string;
}

/**
 * Check if a given object implements the SyncLeadSOffenderRecordsByIdRequest interface.
 */
export function instanceOfSyncLeadSOffenderRecordsByIdRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SyncLeadSOffenderRecordsByIdRequestFromJSON(json: any): SyncLeadSOffenderRecordsByIdRequest {
    return SyncLeadSOffenderRecordsByIdRequestFromJSONTyped(json, false);
}

export function SyncLeadSOffenderRecordsByIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncLeadSOffenderRecordsByIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
    };
}

export function SyncLeadSOffenderRecordsByIdRequestToJSON(value?: SyncLeadSOffenderRecordsByIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'dob': value.dob,
    };
}

