/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentCompletionComment } from './AssessmentCompletionComment';
import {
    AssessmentCompletionCommentFromJSON,
    AssessmentCompletionCommentFromJSONTyped,
    AssessmentCompletionCommentToJSON,
} from './AssessmentCompletionComment';

/**
 * 
 * @export
 * @interface FindAllResidentAssessmentComments200Response
 */
export interface FindAllResidentAssessmentComments200Response {
    /**
     * 
     * @type {Array<AssessmentCompletionComment>}
     * @memberof FindAllResidentAssessmentComments200Response
     */
    data?: Array<AssessmentCompletionComment>;
}

/**
 * Check if a given object implements the FindAllResidentAssessmentComments200Response interface.
 */
export function instanceOfFindAllResidentAssessmentComments200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllResidentAssessmentComments200ResponseFromJSON(json: any): FindAllResidentAssessmentComments200Response {
    return FindAllResidentAssessmentComments200ResponseFromJSONTyped(json, false);
}

export function FindAllResidentAssessmentComments200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllResidentAssessmentComments200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AssessmentCompletionCommentFromJSON)),
    };
}

export function FindAllResidentAssessmentComments200ResponseToJSON(value?: FindAllResidentAssessmentComments200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AssessmentCompletionCommentToJSON)),
    };
}

