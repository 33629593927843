/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompleteResidentAssessmentRequest
 */
export interface CompleteResidentAssessmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteResidentAssessmentRequest
     */
    next_assessment_date?: string;
}

/**
 * Check if a given object implements the CompleteResidentAssessmentRequest interface.
 */
export function instanceOfCompleteResidentAssessmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompleteResidentAssessmentRequestFromJSON(json: any): CompleteResidentAssessmentRequest {
    return CompleteResidentAssessmentRequestFromJSONTyped(json, false);
}

export function CompleteResidentAssessmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteResidentAssessmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_assessment_date': !exists(json, 'next_assessment_date') ? undefined : json['next_assessment_date'],
    };
}

export function CompleteResidentAssessmentRequestToJSON(value?: CompleteResidentAssessmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_assessment_date': value.next_assessment_date,
    };
}

