/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    exp_month: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    exp_year: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    holder_name: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    last4: string;
}

/**
 * Check if a given object implements the Card interface.
 */
export function instanceOfCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "exp_month" in value;
    isInstance = isInstance && "exp_year" in value;
    isInstance = isInstance && "holder_name" in value;
    isInstance = isInstance && "last4" in value;

    return isInstance;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': json['brand'],
        'exp_month': json['exp_month'],
        'exp_year': json['exp_year'],
        'holder_name': json['holder_name'],
        'last4': json['last4'],
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': value.brand,
        'exp_month': value.exp_month,
        'exp_year': value.exp_year,
        'holder_name': value.holder_name,
        'last4': value.last4,
    };
}

