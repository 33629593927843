// TODO: https://linear.app/exacarelt/issue/ENG-1751/move-files-from-stores-into-somewhere-more-appropriate
import { S3File } from '@/api/postS3Files';
import {
  FrequencyTimeFormData,
  generateScheduleFormDataDefaultValues,
  ScheduleFormData
} from '@/components/FrequencyTimeForm/FrequencyTimeForm';
import {
  DurationOption,
  medicationDurationOptions
} from '@/components/FrequencyTimeForm/options';
import { ORDERED_VITALS_BY_ID } from '@/constants';
import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';
import { TaskExtensionPayload } from '@/models/TaskExtensionModel';
import { GenericSqlRowOption } from '@/stores/residentFacesheetsAtom';

interface MedicationSqlRow {
  id: string;
  drug_name: string;
}

export interface MedicationDoseSqlRow {
  id: string;
  strength: string;
}

export interface MedicationRegimenForm {
  frequency: FrequencyTimeFormData | null;
  schedule: ScheduleFormData | null;
}

export interface ResidentMedicationForm {
  id?: string;
  medication: MedicationSqlRow | null;
  medicationRoute: GenericSqlRowOption | null;
  medicationForm: GenericSqlRowOption | null;
  medicationGeneric: GenericSqlRowOption | null;
  dose: MedicationDoseSqlRow | null;
  DispensableDrug: MedicationsPayload | null;
  DispensableDrugID: string | null;
  medication_id: string | null;
  diagnosis: string | null;
  duration: DurationOption | null;
  frequency: FrequencyTimeFormData | null;
  schedule: ScheduleFormData | null;
  regimens: MedicationRegimenForm[] | null;
  doctor_id: string | null;
  prescription_type: string | null;
  s3_prescription: S3File | null;
  directions: string | null;
  equivalent_to: string | null;
  responsible_party: string | null;
  followupIntervalInMinutes?: number;
  followupInstructions?: string;
  pharm_prescription_order_id?: string | null;
  responsible_party_id?: string | null;
  responsible_party_instance?: ResponsibleParty | null;
  vitals: {
    [key: string]: {
      enabled: boolean;
      min: string;
      max: string;
      minSystolicBloodPressure: string;
      minDiastolicBloodPressure: string;
      maxSystolicBloodPressure: string;
      maxDiastolicBloodPressure: string;
    };
  };
  vitalsEnabled?: boolean;
  extensionEnabled?: boolean;
  task_extension?: TaskExtensionPayload;
  amount_in_stock?: number;
  is_editable: boolean;
  medication_task_schedules?: Array<Record<string, any>>;
  non_community_dose?: number | null;
  prn_min_interval_minutes: number | null;
  prn_max_daily_number_of_dose_units: number | null;
  prescription_interval: string | null;
  may_have_max_daily_dosage: boolean | null;
}

export const medicationVitalsDefaultFormValues: ResidentMedicationForm['vitals'] =
  Object.freeze(
    ORDERED_VITALS_BY_ID.reduce((accum, id) => {
      accum[id] = {
        enabled: false,
        min: '',
        max: '',
        minSystolicBloodPressure: '',
        minDiastolicBloodPressure: '',
        maxSystolicBloodPressure: '',
        maxDiastolicBloodPressure: ''
      };
      return accum;
    }, {} as ResidentMedicationForm['vitals'])
  );

export const getMedicationDefaultFormValues = (
  defaultResponsibleParty?: ResponsibleParty
) =>
  Object.freeze<ResidentMedicationForm>({
    medication: null,
    medicationRoute: null,
    medicationForm: null,
    medicationGeneric: null,
    medication_id: null,
    dose: null,
    DispensableDrug: null,
    DispensableDrugID: null,
    diagnosis: '',
    duration: medicationDurationOptions[0], // N/A (default)
    frequency: null, // Relevant when VITE_FEATURE_FLAG_MEDICATION_TASK_REGIMENS is OFF
    schedule: generateScheduleFormDataDefaultValues(), // Relevant when VITE_FEATURE_FLAG_MEDICATION_TASK_REGIMENS is OFF
    regimens: [
      // Relevant when VITE_FEATURE_FLAG_MEDICATION_TASK_REGIMENS is ON
      {
        frequency: {
          dtstart: new Date()
        } as FrequencyTimeFormData,
        schedule: generateScheduleFormDataDefaultValues()
      }
    ],
    doctor_id: null,
    prescription_type: '',
    s3_prescription: null,
    directions: '',
    equivalent_to: '',
    responsible_party: null,
    vitals: medicationVitalsDefaultFormValues,
    is_editable: true,
    non_community_dose: null,
    responsible_party_id: defaultResponsibleParty?.id,
    followupIntervalInMinutes: 60,
    prn_min_interval_minutes: null,
    prn_max_daily_number_of_dose_units: null,
    prescription_interval: null,
    may_have_max_daily_dosage: null
  });
