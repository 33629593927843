import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { Box, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

type ControlledDatePickerProps<TFields extends FieldValues> =
  React.PropsWithChildren<
    UseControllerProps<TFields> & {
      tooltip?: string;
      label: string;
      onChange?: () => void;
      // TODO: Figure out how to use DatePickerProps<TInputDate, TDate>
      disableFuture?: boolean;
      disablePast?: boolean;
      disabled?: boolean;
      minDate?: any;
      maxDate?: any;
      format?: string;
    }
  >;

export function ControlledDatePicker<TFields extends FieldValues>({
  label,
  name,
  control,
  rules = {},
  onChange,
  disableFuture = false,
  disablePast = false,
  disabled = false,
  tooltip = '',
  minDate,
  maxDate,
  format
}: ControlledDatePickerProps<TFields>) {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const {
    field: { ref, onBlur: onFieldBlur, onChange: onFieldChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate: {
        isValidDate: (value: any) =>
          !(value instanceof Date) ||
          dayjs(value).isValid() ||
          'Must be a valid date',
        ...(rules.validate || {})
      }
    }
  });

  const renderLabel = () => {
    if (rules.required && label) {
      return (
        <span>
          {label}
          <span style={{ color: '#DD3730' }}>*</span>
        </span>
      );
    } else if (label) {
      return label;
    } else {
      return '';
    }
  };

  return (
    <Tooltip
      open={!!tooltip && openTooltip}
      arrow
      title={tooltip}
      placement="top-start">
      <Box>
        <DatePicker
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          format={format ? format : 'MM/dd/yyyy'}
          label={renderLabel()}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onOpen={() => setOpenTooltip(true)}
          onClose={() => setOpenTooltip(false)}
          onChange={(date: Date | null) => {
            onFieldChange(date);
            onChange?.();
          }}
          value={value ? dayjs(value).toDate() : null}
          inputRef={ref}
          slotProps={{
            desktopPaper: { className: 'date-picker-paper' },
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
              InputProps: {
                autoComplete: 'off',
                onFocus: () => {
                  setOpenTooltip(true);
                },
                onBlur: () => {
                  setOpenTooltip(false);
                  onFieldBlur();
                },
                id: `mui-component-textfield-${name}`
              }
            }
          }}
        />
      </Box>
    </Tooltip>
  );
}
