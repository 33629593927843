/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DailyDecisionMakingSkillsOption = {
    Na: 'na',
    ModifiedIndependence: 'modified_independence',
    ModeratelyImpaired: 'moderately_impaired'
} as const;
export type DailyDecisionMakingSkillsOption = typeof DailyDecisionMakingSkillsOption[keyof typeof DailyDecisionMakingSkillsOption];


export function DailyDecisionMakingSkillsOptionFromJSON(json: any): DailyDecisionMakingSkillsOption {
    return DailyDecisionMakingSkillsOptionFromJSONTyped(json, false);
}

export function DailyDecisionMakingSkillsOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyDecisionMakingSkillsOption {
    return json as DailyDecisionMakingSkillsOption;
}

export function DailyDecisionMakingSkillsOptionToJSON(value?: DailyDecisionMakingSkillsOption | null): any {
    return value as any;
}

