import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Check, Delete, InsertDriveFile } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import prettyBytes from 'pretty-bytes';

import { UploadCircularProgress } from '@/components/CircularProgress';
import { ControlledTextField } from '@/components/ControlledTextField';
import { Dropzone } from '@/components/Dropzone';
import { FormHeader } from '@/components/FormHeader';
import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { usePrescriptionFiles } from './usePrescriptionFiles';

interface PrescriptionFilesProps {
  formMethods: UseFormReturn<ResidentMedicationForm>;
  isResidentArchived: boolean;
  disableEdit?: boolean;
}

const sigCodeDescriptions = [
  'TAKE ONCE PER DAY',
  'TAKE 1 CAPSULE BY MOUTH DAILY',
  'TAKE 1 TABLET BY MOUTH DAILY',
  'TAKE TWICE PER DAY',
  'TAKE 1 CAPSULE BY MOUTH TWICE DAILY',
  'TAKE 1 TABLET BY MOUTH TWICE DAILY',
  'TAKE THREE TIMES PER DAY',
  'TAKE FOUR TIMES PER DAY',
  'TAKE FIVE TIMES PER DAY',
  'TAKE 1 CAPSULE BY MOUTH THREE TIMES DAILY',
  'TAKE 1 TABLET BY MOUTH THREE TIMES DAILY',
  'TAKE MEDICATION AS NEEDED',
  'TAKE MEDICATION ON AN EMPTY STOMACH',
  'TAKE 1 TABLET BY MOUTH ONCE PER WEEK'
];

export const PrescriptionFiles: React.FC<PrescriptionFilesProps> = ({
  formMethods,
  isResidentArchived,
  disableEdit = false
}) => {
  const {
    progress,
    s3Prescription,
    handleOnFileUpload,
    handleOnFileRemove,
    handleDownloadPrescription
  } = usePrescriptionFiles(formMethods);

  return (
    <>
      {!isResidentArchived && (
        <Grid xs={12}>
          <Dropzone
            multiple={false}
            onDrop={handleOnFileUpload}
            maxFiles={1}
            disabled={disableEdit}
            accept={{ 'image/*': [], 'application/pdf': [] }}
            inputProps={{ capture: 'environment' }}
          />
        </Grid>
      )}
      {progress.file && (
        <ListItem>
          <ListItemIcon>
            <UploadCircularProgress value={progress.loaded} />
          </ListItemIcon>
          <ListItemText
            primary={progress.file.name}
            secondary={progress.file.type}
          />
        </ListItem>
      )}
      {s3Prescription && !isResidentArchived && (
        <ListItem
          secondaryAction={
            <IconButton
              edge="end"
              disabled={disableEdit}
              aria-label="delete"
              onClick={handleOnFileRemove}>
              <Delete />
            </IconButton>
          }>
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFile />
            </Avatar>
          </ListItemAvatar>
          <Link
            href="#"
            target="_blank"
            rel="noreferrer"
            underline="hover"
            onClick={(e) => {
              if (disableEdit) return;
              e.preventDefault();
              handleDownloadPrescription();
            }}>
            <ListItemText
              primary={s3Prescription?.name}
              secondary={
                s3Prescription.size > 0 && prettyBytes(s3Prescription.size)
              }
            />
          </Link>
        </ListItem>
      )}
      <Grid xs={12}>
        <FormHeader Icon={Check} text="Instruction" m="16px 0 8px" />
      </Grid>
      <Grid xs={12}>
        <ControlledTextField
          disabled={isResidentArchived || disableEdit}
          control={formMethods.control}
          name="diagnosis"
          label="Diagnosis"
          placeholder="Emphysema"
        />
      </Grid>
      <Grid xs={12}>
        <Autocomplete
          disablePortal
          id="instructions-autocomplete"
          options={sigCodeDescriptions}
          disabled={isResidentArchived || disableEdit}
          freeSolo={true}
          value={formMethods.getValues('directions')}
          onInputChange={(event, value) => {
            formMethods.setValue('directions', value, { shouldDirty: true });
          }}
          renderInput={(params) => (
            <TextField
              multiline
              minRows={3}
              {...params}
              label="Directions for use"
              placeholder="Select or type directions for use"
            />
          )}
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: '8px'
            }
          }}
        />
      </Grid>
    </>
  );
};
