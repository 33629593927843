import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { atomWithStorage } from 'jotai/utils';

import { useCurrentRoute } from '@/hooks/useCurrentRoute';
import { RoutePath } from '@/routes/RoutePath';

export const tasksPageViewAtom = atomWithStorage(
  'tasksPageView',
  RoutePath.TasksByResident
);

export interface TasksLocationState {
  refetchOnMount: boolean | 'always';
}

export const TasksToggleView = () => {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();

  const handleOnChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    path?: RoutePath
  ) => {
    if (path) {
      navigate(path, {
        replace: true,
        state: { refetchOnMount: false } as TasksLocationState
      });
    }
  };

  return (
    <ToggleButtonGroup
      value={currentRoute}
      exclusive
      onChange={handleOnChange}
      aria-label="resident tasks view">
      <ToggleButton value={RoutePath.TasksChronological}>Chrono</ToggleButton>
      <ToggleButton value={RoutePath.TasksByResident}>By Res</ToggleButton>
    </ToggleButtonGroup>
  );
};
