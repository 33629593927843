/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRMStatus } from './CRMStatus';
import {
    CRMStatusFromJSON,
    CRMStatusFromJSONTyped,
    CRMStatusToJSON,
} from './CRMStatus';

/**
 * 
 * @export
 * @interface Resident
 */
export interface Resident {
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    avatar_url?: string;
    /**
     * 
     * @type {CRMStatus}
     * @memberof Resident
     */
    crm_status?: CRMStatus;
}

/**
 * Check if a given object implements the Resident interface.
 */
export function instanceOfResident(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;

    return isInstance;
}

export function ResidentFromJSON(json: any): Resident {
    return ResidentFromJSONTyped(json, false);
}

export function ResidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'crm_status': !exists(json, 'crm_status') ? undefined : CRMStatusFromJSON(json['crm_status']),
    };
}

export function ResidentToJSON(value?: Resident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'avatar_url': value.avatar_url,
        'crm_status': CRMStatusToJSON(value.crm_status),
    };
}

