/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssessmentQuestion } from './AssessmentQuestion';
import {
    AssessmentQuestionFromJSON,
    AssessmentQuestionFromJSONTyped,
    AssessmentQuestionToJSON,
} from './AssessmentQuestion';

/**
 * 
 * @export
 * @interface CreateAssessmentQuestion201Response
 */
export interface CreateAssessmentQuestion201Response {
    /**
     * 
     * @type {AssessmentQuestion}
     * @memberof CreateAssessmentQuestion201Response
     */
    data?: AssessmentQuestion;
}

/**
 * Check if a given object implements the CreateAssessmentQuestion201Response interface.
 */
export function instanceOfCreateAssessmentQuestion201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAssessmentQuestion201ResponseFromJSON(json: any): CreateAssessmentQuestion201Response {
    return CreateAssessmentQuestion201ResponseFromJSONTyped(json, false);
}

export function CreateAssessmentQuestion201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssessmentQuestion201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : AssessmentQuestionFromJSON(json['data']),
    };
}

export function CreateAssessmentQuestion201ResponseToJSON(value?: CreateAssessmentQuestion201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AssessmentQuestionToJSON(value.data),
    };
}

