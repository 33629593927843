import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const CRMOutboundCenterAudiencesPage = loadable(() => import("@/pages/CRM/OutboundCenterPage/Audiences/Audiences")); // prettier-ignore
const CRMOutboundCenterPage = loadable(() => import("@/pages/CRM/OutboundCenterPage/OutboundCenterPage")); // prettier-ignore
const CRMOutboundCenterCampaigns = loadable(() => import("@/pages/CRM/OutboundCenterPage/Campaigns/Campaigns")); // prettier-ignore
const CRMOutboundCenterTemplatesEmail = loadable(() => import("@/pages/CRM/OutboundCenterPage/Templates/Emails/Emails")); // prettier-ignore
const CRMOutboundCenterTemplatesTexts = loadable(() => import("@/pages/CRM/OutboundCenterPage/Templates/Texts/TextTemplates")); // prettier-ignore
const CRMOutboundCenterTemplatesTasks = loadable(() => import("@/pages/CRM/OutboundCenterPage/Templates/Tasks/TaskTemplates")); // prettier-ignore

export enum CRMOutboundCenterRoutes {
  CRMOutboundCenter = '/crm/outbound-center',
  CRMOutboundCenterCampaigns = '/crm/outbound-center/campaigns',
  CRMOutboundCenterTemplates = '/crm/outbound-center/templates',
  CRMOutboundCenterTemplatesEmails = '/crm/outbound-center/templates/emails',
  CRMOutboundCenterTemplatesTexts = '/crm/outbound-center/templates/texts',
  CRMOutboundCenterTemplatesTasks = '/crm/outbound-center/templates/tasks',
  CRMOutboundCenterAudiences = '/crm/outbound-center/audiences'
}

export const getCrmOutboundCenterRoutes = (): RouteObject[] => {
  return [
    {
      path: CRMOutboundCenterRoutes.CRMOutboundCenter,
      element: <CRMOutboundCenterPage />,
      children: [
        {
          path: CRMOutboundCenterRoutes.CRMOutboundCenterCampaigns,
          element: <CRMOutboundCenterCampaigns />
        },
        {
          path: CRMOutboundCenterRoutes.CRMOutboundCenterTemplatesEmails,
          element: <CRMOutboundCenterTemplatesEmail />
        },
        {
          path: CRMOutboundCenterRoutes.CRMOutboundCenterTemplatesTexts,
          element: <CRMOutboundCenterTemplatesTexts />
        },
        {
          path: CRMOutboundCenterRoutes.CRMOutboundCenterTemplatesTasks,
          element: <CRMOutboundCenterTemplatesTasks />
        },
        {
          path: CRMOutboundCenterRoutes.CRMOutboundCenterAudiences,
          element: <CRMOutboundCenterAudiencesPage />
        }
      ]
    }
  ];
};
