/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentCompletionAnswerOption
 */
export interface AssessmentCompletionAnswerOption {
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswerOption
     */
    answer_option_id: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswerOption
     */
    answer_option_text?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswerOption
     */
    care_plan_entry_template_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletionAnswerOption
     */
    ignore_care_plan_entry_template?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentCompletionAnswerOption
     */
    care_package_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionAnswerOption
     */
    care_points?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionAnswerOption
     */
    care_minutes_per_month?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionAnswerOption
     */
    overridden_care_points?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentCompletionAnswerOption
     */
    custom_template_modified_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentCompletionAnswerOption
     */
    is_ng_cpe_template_modified?: boolean;
}

/**
 * Check if a given object implements the AssessmentCompletionAnswerOption interface.
 */
export function instanceOfAssessmentCompletionAnswerOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "answer_option_id" in value;

    return isInstance;
}

export function AssessmentCompletionAnswerOptionFromJSON(json: any): AssessmentCompletionAnswerOption {
    return AssessmentCompletionAnswerOptionFromJSONTyped(json, false);
}

export function AssessmentCompletionAnswerOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletionAnswerOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer_option_id': json['answer_option_id'],
        'answer_option_text': !exists(json, 'answer_option_text') ? undefined : json['answer_option_text'],
        'care_plan_entry_template_id': !exists(json, 'care_plan_entry_template_id') ? undefined : json['care_plan_entry_template_id'],
        'ignore_care_plan_entry_template': !exists(json, 'ignore_care_plan_entry_template') ? undefined : json['ignore_care_plan_entry_template'],
        'care_package_ids': !exists(json, 'care_package_ids') ? undefined : json['care_package_ids'],
        'care_points': !exists(json, 'care_points') ? undefined : json['care_points'],
        'care_minutes_per_month': !exists(json, 'care_minutes_per_month') ? undefined : json['care_minutes_per_month'],
        'overridden_care_points': !exists(json, 'overridden_care_points') ? undefined : json['overridden_care_points'],
        'custom_template_modified_date': !exists(json, 'custom_template_modified_date') ? undefined : json['custom_template_modified_date'],
        'is_ng_cpe_template_modified': !exists(json, 'is_ng_cpe_template_modified') ? undefined : json['is_ng_cpe_template_modified'],
    };
}

export function AssessmentCompletionAnswerOptionToJSON(value?: AssessmentCompletionAnswerOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer_option_id': value.answer_option_id,
        'answer_option_text': value.answer_option_text,
        'care_plan_entry_template_id': value.care_plan_entry_template_id,
        'ignore_care_plan_entry_template': value.ignore_care_plan_entry_template,
        'care_package_ids': value.care_package_ids,
        'care_points': value.care_points,
        'care_minutes_per_month': value.care_minutes_per_month,
        'overridden_care_points': value.overridden_care_points,
        'custom_template_modified_date': value.custom_template_modified_date,
        'is_ng_cpe_template_modified': value.is_ng_cpe_template_modified,
    };
}

