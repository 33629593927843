/* tslint:disable */
/* eslint-disable */
/**
 * crm-campaigns
 * crm-campaigns
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrmCloseConnectionsPage } from './CrmCloseConnectionsPage';
import {
    CrmCloseConnectionsPageFromJSON,
    CrmCloseConnectionsPageFromJSONTyped,
    CrmCloseConnectionsPageToJSON,
} from './CrmCloseConnectionsPage';

/**
 * 
 * @export
 * @interface FindAllCrmContactsCloseConnections200Response
 */
export interface FindAllCrmContactsCloseConnections200Response {
    /**
     * 
     * @type {CrmCloseConnectionsPage}
     * @memberof FindAllCrmContactsCloseConnections200Response
     */
    data?: CrmCloseConnectionsPage;
}

/**
 * Check if a given object implements the FindAllCrmContactsCloseConnections200Response interface.
 */
export function instanceOfFindAllCrmContactsCloseConnections200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindAllCrmContactsCloseConnections200ResponseFromJSON(json: any): FindAllCrmContactsCloseConnections200Response {
    return FindAllCrmContactsCloseConnections200ResponseFromJSONTyped(json, false);
}

export function FindAllCrmContactsCloseConnections200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAllCrmContactsCloseConnections200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CrmCloseConnectionsPageFromJSON(json['data']),
    };
}

export function FindAllCrmContactsCloseConnections200ResponseToJSON(value?: FindAllCrmContactsCloseConnections200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CrmCloseConnectionsPageToJSON(value.data),
    };
}

