import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Alert, AlertTitle, Fade, Unstable_Grid2 as Grid } from '@mui/material';

import { ResidentMedicationForm } from '@/stores/residentMedicationsAtom';

import { SelectCustomDrugQuickCreate } from './SelectCustomDrugQuickCreate';

interface CustomMedicationDetailsProps {
  formMethods: UseFormReturn<ResidentMedicationForm>;
  onChange: (newValue?: string) => void;
  disabled: boolean;
  residentId?: string;
}

export const CustomMedicationDetails: React.FC<
  CustomMedicationDetailsProps
> = ({ formMethods, onChange, disabled, residentId }) => {
  return (
    <>
      <Grid xs={12}>
        <SelectCustomDrugQuickCreate
          formMethods={formMethods}
          onChange={onChange}
          disabled={disabled}
          residentId={residentId}
        />
      </Grid>
      <Grid xs={12}>
        <Fade in={true}>
          <Alert severity="warning">
            <AlertTitle>Be Aware!</AlertTitle>
            If you enter a custom drug that is not recognized by our system,
            ExaCare&apos;s excessive acetaminophen consumption warnings and
            negative drug interaction warnings may not work as expected.
          </Alert>
        </Fade>
      </Grid>
    </>
  );
};
