import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  styled
} from '@mui/material';

export const StyledAccordion = styled(Accordion)({
  margin: '8px 0 !important',
  boxShadow: 'unset',
  '&::before': {
    display: 'none'
  }
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  height: 44,
  padding: 0,
  minHeight: '44px !important',
  color: '#364955',
  fontWeight: 500
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0 0 8px 0',
  '& .MuiFormControlLabel-label': {
    fontSize: '1rem'
  }
});

export const StyledDivider = styled(Divider)({
  color: '#F2F2F2',
  margin: '0 -16px'
});
