import { UserPayload } from '@/hooks/useUserQuery';
import {
  ProspectResidentModel,
  ProspectResidentPayload
} from '@/models/ProspectResidentModel';
import { ReferralModel, ReferralPayload } from '@/models/ReferralModel';
import { ResidentCloseConnectionPayload } from '@/models/ResidentCloseConnectionModel';
import { UserModel } from '@/models/UserModel';

import {
  CrmRelationType,
  CrmTaskAttachment,
  CrmTaskPriority,
  CrmTaskReminderPayload,
  CrmTaskType,
  PatternedRecurrence
} from './CrmTaskModel';
import { getPatterendRecurrenceText } from './CrmTaskModel';

export type CrmTaskInstanceStatus =
  | 'Upcoming'
  | 'Overdue'
  | 'Completed'
  | 'Cancelled';

export class CrmTaskInstancePayload {
  id: string;
  task_id: string;
  scheduled_date: string;
  is_completed: boolean;
  completed_by_user_id: null | string;
  is_cancelled: boolean;
  cancelled_by_user_id: null | string;
  length_mins: number | null;
  completed_time: string | null;
  occurred_at: string | null;
  occurred_on: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  name: string;
  description: string | null;
  task_type: CrmTaskType | null;
  relation_type: CrmRelationType;
  status: CrmTaskInstanceStatus;
  patterned_recurrence: PatternedRecurrence;
  reminders: CrmTaskReminderPayload[] | null;
  assignee: UserPayload;
  related_to:
    | ReferralPayload
    | ProspectResidentPayload
    | UserPayload
    | ResidentCloseConnectionPayload;
  priority: CrmTaskPriority | null;
  attachments: CrmTaskAttachment[];
  creator: UserPayload;

  get patternedRecurrenceText(): string {
    return getPatterendRecurrenceText(this.patterned_recurrence);
  }

  constructor(payload: CrmTaskInstancePayload) {
    Object.assign(this, payload);
  }
}

export class CrmTaskInstanceModel extends CrmTaskInstancePayload {
  declare assignee: UserModel;
  declare creator: UserModel;
  declare related_to:
    | ReferralModel
    | ProspectResidentModel
    | UserModel
    | ResidentCloseConnectionPayload;

  constructor(payload: CrmTaskInstancePayload) {
    super(payload);
    this.assignee = new UserModel(payload.assignee);
    this.creator = new UserModel(payload.creator);

    if (!this.related_to) {
      return;
    }

    switch (this.relation_type) {
      case 'REFERRAL':
        this.related_to = new ReferralModel(
          payload.related_to as ReferralPayload
        );
        break;
      case 'PROSPECT_RESIDENT':
        this.related_to = new ProspectResidentModel(
          payload.related_to as ProspectResidentPayload
        );
        break;
      case 'USER':
        this.related_to = new UserModel(payload.related_to as UserPayload);
        break;
      case 'RESIDENT_CLOSE_CONNECTION':
        this.related_to = new ResidentCloseConnectionPayload(
          payload.related_to as ResidentCloseConnectionPayload
        );
        break;
      default:
        throw new Error('Unsupported relation type: ' + this.relation_type);
    }
  }

  public get relationEmail(): string | undefined {
    if (
      (
        ['REFERRAL', 'RESIDENT_CLOSE_CONNECTION', 'USER'] as CrmRelationType[]
      ).some((type) => type === this.relation_type)
    ) {
      return (
        this.related_to as
          | ReferralPayload
          | UserPayload
          | ResidentCloseConnectionPayload
      ).email;
    }

    if (this.relation_type === 'PROSPECT_RESIDENT') {
      return (this.related_to as ProspectResidentPayload).resident.email;
    }
  }
}
