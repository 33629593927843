import React from 'react';
import { Alert, AlertProps, Fade } from '@mui/material';

import { MedicationsPayload } from '@/hooks/useMedicationsQuery';
import { CustomMedicationPayload } from '@/pages/CRM/models/CustomMedicationModel';
import {
  calculateCustomMedicationTotalDosage,
  calculateFdbTotalDosage
} from '@/utils/calculateTotalDosage';

type TotalDosageProps = {
  dispensable: MedicationsPayload | null;
  numberOfDoseUnits?: number | null;
  customMedication?: CustomMedicationPayload | null;
  body?: string;
  alertTitle?: React.ReactNode;
} & AlertProps;

export const TotalDosage: React.FC<TotalDosageProps> = ({
  dispensable,
  numberOfDoseUnits,
  customMedication,
  body = 'Total Dosage:',
  alertTitle = null,
  ...props
}) => {
  let totalDosage;
  if (customMedication) {
    totalDosage = calculateCustomMedicationTotalDosage(
      customMedication,
      numberOfDoseUnits
    );
  } else {
    totalDosage = calculateFdbTotalDosage(dispensable, numberOfDoseUnits);
  }

  return (
    <Fade in={true}>
      <Alert severity="info" {...props}>
        {alertTitle}
        {body} {totalDosage}
      </Alert>
    </Fade>
  );
};
