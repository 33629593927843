import React from 'react';
import { Container as ModalContainer } from 'react-modal-promise';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions
} from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';

import { queryClient } from '@/adapters/query';

import './lib/loadGoogleMaps';
import './lib/loadUpscope';

import { ErrorBoundaryFallback } from './components/ErrorBoundaryFallback';
import Layout from './components/Layout';
import { useCachedPin } from './components/TaskModals/useCachedPin';
import { RouteConfig } from './routes';
import theme from './theme';

import './styles.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_PREMIUM_LICENSE_KEY);
registerLicense(import.meta.env.VITE_SYNCFUSION_API_KEY);

interface Auth0ProviderWithRedirectCallbackProps extends Auth0ProviderOptions {
  children: React.ReactNode;
}

const Auth0ProviderWithRedirectCallback: React.FC<
  Auth0ProviderWithRedirectCallbackProps
> = ({ children, ...props }) => {
  const { clearCachedPin } = useCachedPin();
  const navigate = useNavigate();
  const onRedirectCallback: (appState: AppState | undefined) => void = (
    appState
  ) => {
    clearCachedPin();
    navigate((appState && appState.returnTo) || window.location.pathname, {
      state: {
        isFirstLogin: true
      }
    });
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sentry.ErrorBoundary
          showDialog
          fallback={({ error, resetError }) => (
            <ErrorBoundaryFallback error={error} resetError={resetError} />
          )}>
          <BrowserRouter>
            <Auth0ProviderWithRedirectCallback
              domain={import.meta.env.VITE_AUTH0_DOMAIN}
              clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
              redirectUri={location.origin}
              audience={import.meta.env.VITE_AUTH0_AUDIENCE}>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
                  <Layout>
                    <RouteConfig />
                  </Layout>
                  <ModalContainer />
                </SnackbarProvider>
                <ReactQueryDevtools
                  toggleButtonProps={{
                    style: {
                      // Position to right of intercom icon
                      left: 75,
                      bottom: 10
                    }
                  }}
                />
              </QueryClientProvider>
            </Auth0ProviderWithRedirectCallback>
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
