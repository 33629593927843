/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bill } from './Bill';
import {
    BillFromJSON,
    BillFromJSONTyped,
    BillToJSON,
} from './Bill';

/**
 * 
 * @export
 * @interface FindOneBill200Response
 */
export interface FindOneBill200Response {
    /**
     * 
     * @type {Bill}
     * @memberof FindOneBill200Response
     */
    data?: Bill;
}

/**
 * Check if a given object implements the FindOneBill200Response interface.
 */
export function instanceOfFindOneBill200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindOneBill200ResponseFromJSON(json: any): FindOneBill200Response {
    return FindOneBill200ResponseFromJSONTyped(json, false);
}

export function FindOneBill200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneBill200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : BillFromJSON(json['data']),
    };
}

export function FindOneBill200ResponseToJSON(value?: FindOneBill200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': BillToJSON(value.data),
    };
}

