import { ResponsibleParty } from '@/hooks/useResponsiblePartiesQuery';

interface PartyOption {
  label: string;
  value: string;
}

export enum PartyCategory {
  staff = 'Community Staff Responsibility',
  nonStaff = 'Non-Staff Responsibility'
}

export interface GroupedPartyOptions {
  group: string;
  subItems: Array<PartyOption>;
}

const caregiver: PartyOption = {
  label: 'Caregiver',
  value: 'Caregiver'
};

const medicationTech = {
  label: 'Medication Tech',
  value: 'Medication Tech'
};

const nurse = {
  label: 'Nurse',
  value: 'Nurse'
};

const staffCategory = {
  group: PartyCategory.staff,
  subItems: [nurse, medicationTech, caregiver]
};

// A medication task with no responsible party is considered a staff responsibility
/**
 * @deprecated Use isStaffResponsibilityV2 instead
 */
export const isStaffResponsibility = (value: string | null): boolean =>
  !value || staffCategory.subItems.some((item) => item.value === value);

/**
 * @return null if responsibleParties is null or undefined
 */
export const isStaffResponsibilityV2 = (
  responsibleParty: string | null,
  responsibleParties: ResponsibleParty[] | undefined
): boolean | null => {
  if (!responsibleParty) {
    // No responsible party selected. Treat as staff responsibility by default
    return true;
  }
  if (!responsibleParties) {
    return null;
  }

  if (isNaN(parseInt(responsibleParty))) {
    // Deprecated responsible party
    return isStaffResponsibility(responsibleParty);
  } else {
    // New responsible party
    const selectedParty = responsibleParties.find(
      (party) => party.id === responsibleParty
    );
    return selectedParty ? !selectedParty.is_informational : null;
  }
};
