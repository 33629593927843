/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PccPatientDataUploadErrorCategory = {
    Contact: 'Contact',
    CarePeriod: 'Care Period',
    Allergy: 'Allergy',
    Condition: 'Condition',
    Document: 'Document'
} as const;
export type PccPatientDataUploadErrorCategory = typeof PccPatientDataUploadErrorCategory[keyof typeof PccPatientDataUploadErrorCategory];


export function PccPatientDataUploadErrorCategoryFromJSON(json: any): PccPatientDataUploadErrorCategory {
    return PccPatientDataUploadErrorCategoryFromJSONTyped(json, false);
}

export function PccPatientDataUploadErrorCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientDataUploadErrorCategory {
    return json as PccPatientDataUploadErrorCategory;
}

export function PccPatientDataUploadErrorCategoryToJSON(value?: PccPatientDataUploadErrorCategory | null): any {
    return value as any;
}

