/* tslint:disable */
/* eslint-disable */
/**
 * billingv2
 * billingv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OneTimeBillItem } from './OneTimeBillItem';
import {
    OneTimeBillItemFromJSON,
    OneTimeBillItemFromJSONTyped,
    OneTimeBillItemToJSON,
} from './OneTimeBillItem';

/**
 * 
 * @export
 * @interface CreateOneTimeBillItem201Response
 */
export interface CreateOneTimeBillItem201Response {
    /**
     * 
     * @type {OneTimeBillItem}
     * @memberof CreateOneTimeBillItem201Response
     */
    data?: OneTimeBillItem;
}

/**
 * Check if a given object implements the CreateOneTimeBillItem201Response interface.
 */
export function instanceOfCreateOneTimeBillItem201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOneTimeBillItem201ResponseFromJSON(json: any): CreateOneTimeBillItem201Response {
    return CreateOneTimeBillItem201ResponseFromJSONTyped(json, false);
}

export function CreateOneTimeBillItem201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOneTimeBillItem201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : OneTimeBillItemFromJSON(json['data']),
    };
}

export function CreateOneTimeBillItem201ResponseToJSON(value?: CreateOneTimeBillItem201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': OneTimeBillItemToJSON(value.data),
    };
}

