import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { Cancel } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

interface ConfirmDuplicateMedicationDialogProps extends InstanceProps<unknown> {
  residentName: string;
  drugName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDuplicateMedicationDialog: React.FC<
  ConfirmDuplicateMedicationDialogProps
> = ({
  isOpen,
  onResolve,
  onReject,
  residentName,
  drugName,
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        Warning! Potential duplicate med entry.
        <IconButton onClick={onReject}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '16px !important', paddingBottom: 0 }}>
        <DialogContentText>
          <Typography component="span" color="#0A1E28" fontSize="14px">
            <span style={{ fontWeight: 500 }}>
              Looks like {drugName} is already on {residentName}&#39;s
              medication list as an active medication.
            </span>{' '}
            Adding this might create a duplicate. What would you like to do?
          </Typography>
        </DialogContentText>
        <DialogActions sx={{ paddingTop: '8px' }}>
          <Stack direction="row" spacing={0.8}>
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              onClick={onReject}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={() => {
                onConfirm();
                onResolve();
              }}>
              Keep this New Entry
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                onCancel();
                onResolve();
              }}>
              Remove this New Entry
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export const showDuplicateMedicationDialog =
  create<ConfirmDuplicateMedicationDialogProps>(
    ConfirmDuplicateMedicationDialog
  );
