import React from 'react';
import { Print } from '@mui/icons-material';
import { Box, Button, ButtonProps, Tooltip, TooltipProps } from '@mui/material';

type PrintButtonProps = React.PropsWithChildren<{
  TooltipProps?: TooltipProps;
  ButtonProps?: ButtonProps;
}>;

export const PrintButton: React.FC<PrintButtonProps> = ({
  ButtonProps = {},
  TooltipProps = {},
  children
}) => {
  return (
    <Tooltip
      {...TooltipProps}
      title={TooltipProps.title ?? 'Print PDF'}
      enterDelay={1000}
      arrow>
      <Button
        {...ButtonProps}
        variant="contained"
        color="secondary"
        startIcon={<Print />}
        sx={{
          px: { xs: '11px', lg: '22px' },
          '& .MuiButton-startIcon': {
            margin: { xs: '0', lg: '0 8px 0 -4px' }
          }
        }}>
        <Box
          component="span"
          sx={{ display: { xs: 'none', lg: 'inline-block' } }}>
          {children}
        </Box>
      </Button>
    </Tooltip>
  );
};
