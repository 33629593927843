/* tslint:disable */
/* eslint-disable */
/**
 * billingv2-api
 * billingv2-api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiPaymentEntry
 */
export interface MultiPaymentEntry {
    /**
     * 
     * @type {string}
     * @memberof MultiPaymentEntry
     */
    payment_method_id: string;
    /**
     * 
     * @type {number}
     * @memberof MultiPaymentEntry
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof MultiPaymentEntry
     */
    charge_day_of_month: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiPaymentEntry
     */
    charge_remaining: boolean;
}

/**
 * Check if a given object implements the MultiPaymentEntry interface.
 */
export function instanceOfMultiPaymentEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payment_method_id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "charge_day_of_month" in value;
    isInstance = isInstance && "charge_remaining" in value;

    return isInstance;
}

export function MultiPaymentEntryFromJSON(json: any): MultiPaymentEntry {
    return MultiPaymentEntryFromJSONTyped(json, false);
}

export function MultiPaymentEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiPaymentEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment_method_id': json['payment_method_id'],
        'amount': json['amount'],
        'charge_day_of_month': json['charge_day_of_month'],
        'charge_remaining': json['charge_remaining'],
    };
}

export function MultiPaymentEntryToJSON(value?: MultiPaymentEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_method_id': value.payment_method_id,
        'amount': value.amount,
        'charge_day_of_month': value.charge_day_of_month,
        'charge_remaining': value.charge_remaining,
    };
}

