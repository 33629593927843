/* tslint:disable */
/* eslint-disable */
/**
 * incidentsv2
 * incidentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncidentCreateObject } from './IncidentCreateObject';
import {
    IncidentCreateObjectFromJSON,
    IncidentCreateObjectFromJSONTyped,
    IncidentCreateObjectToJSON,
} from './IncidentCreateObject';

/**
 * 
 * @export
 * @interface IncidentCreateRequest
 */
export interface IncidentCreateRequest {
    /**
     * 
     * @type {IncidentCreateObject}
     * @memberof IncidentCreateRequest
     */
    incident: IncidentCreateObject;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentCreateRequest
     */
    documents: Array<string>;
}

/**
 * Check if a given object implements the IncidentCreateRequest interface.
 */
export function instanceOfIncidentCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "incident" in value;
    isInstance = isInstance && "documents" in value;

    return isInstance;
}

export function IncidentCreateRequestFromJSON(json: any): IncidentCreateRequest {
    return IncidentCreateRequestFromJSONTyped(json, false);
}

export function IncidentCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incident': IncidentCreateObjectFromJSON(json['incident']),
        'documents': json['documents'],
    };
}

export function IncidentCreateRequestToJSON(value?: IncidentCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incident': IncidentCreateObjectToJSON(value.incident),
        'documents': value.documents,
    };
}

