import React from 'react';
import { Chip } from '@mui/material';

import { OVERDUE_TASKS_COPY } from '@/constants';

interface StatusChipProps {
  status: string;
  handleClick: VoidFunction;
}

const StatusChip: React.FC<StatusChipProps> = ({ status, handleClick }) => {
  switch (status) {
    case 'Cancelled':
      return <Chip color="default" label="Cancelled" />;
    case 'Open':
      return <Chip color="default" label="Open" />;
    case 'Closed':
      return <Chip color="success" label="Closed" />;
    case 'Completed':
      return <Chip color="success" label="Completed" />;
    case 'Overdue':
      return <Chip color="warning" label={OVERDUE_TASKS_COPY} />;
    default:
      return <Chip color="default" label="Upcoming" onClick={handleClick} />;
  }
};

export default StatusChip;
