/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PullHospitalMessages200ResponseData } from './PullHospitalMessages200ResponseData';
import {
    PullHospitalMessages200ResponseDataFromJSON,
    PullHospitalMessages200ResponseDataFromJSONTyped,
    PullHospitalMessages200ResponseDataToJSON,
} from './PullHospitalMessages200ResponseData';

/**
 * 
 * @export
 * @interface PullHospitalMessages200Response
 */
export interface PullHospitalMessages200Response {
    /**
     * 
     * @type {PullHospitalMessages200ResponseData}
     * @memberof PullHospitalMessages200Response
     */
    data?: PullHospitalMessages200ResponseData;
}

/**
 * Check if a given object implements the PullHospitalMessages200Response interface.
 */
export function instanceOfPullHospitalMessages200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PullHospitalMessages200ResponseFromJSON(json: any): PullHospitalMessages200Response {
    return PullHospitalMessages200ResponseFromJSONTyped(json, false);
}

export function PullHospitalMessages200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PullHospitalMessages200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : PullHospitalMessages200ResponseDataFromJSON(json['data']),
    };
}

export function PullHospitalMessages200ResponseToJSON(value?: PullHospitalMessages200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PullHospitalMessages200ResponseDataToJSON(value.data),
    };
}

