/* tslint:disable */
/* eslint-disable */
/**
 * assessmentsv2
 * assessmentsv2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentCompletionDiff
 */
export interface AssessmentCompletionDiff {
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionDiff
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentCompletionDiff
     */
    care_minutes?: number;
}

/**
 * Check if a given object implements the AssessmentCompletionDiff interface.
 */
export function instanceOfAssessmentCompletionDiff(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssessmentCompletionDiffFromJSON(json: any): AssessmentCompletionDiff {
    return AssessmentCompletionDiffFromJSONTyped(json, false);
}

export function AssessmentCompletionDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentCompletionDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'points': !exists(json, 'points') ? undefined : json['points'],
        'care_minutes': !exists(json, 'care_minutes') ? undefined : json['care_minutes'],
    };
}

export function AssessmentCompletionDiffToJSON(value?: AssessmentCompletionDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'points': value.points,
        'care_minutes': value.care_minutes,
    };
}

