import React, { useContext } from 'react';
import { MoreVertOutlined } from '@mui/icons-material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { DateRangePicker } from '@/components/DateRangePicker';
import GridToolbar from '@/components/GridToolbar';
import { GridToolbarQuickFilter } from '@/components/GridToolbarQuickFilter';
import { ResidentStatusContext } from '@/pages/ArchivedResidents/providers/ResidentStatusProvider';

import AddIncidentButton from './buttons/AddIncidentButton';

interface IncidentDataGridToolbarProps {
  isLoading: boolean;
  residentId?: string;
  dateRange: [dateFrom: Date, dateTo: Date];
  setDateRange: React.Dispatch<
    React.SetStateAction<[dateFrom: Date, dateTo: Date]>
  >;
  handlePrint: VoidFunction;
  includeAllOpen: boolean;
  onChangeIncludeAllOpen: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
}

const IncidentDataGridToolbar: React.FC<IncidentDataGridToolbarProps> = ({
  isLoading,
  dateRange,
  setDateRange,
  residentId,
  handlePrint,
  includeAllOpen,
  onChangeIncludeAllOpen
}) => {
  const { isResidentArchived } = useContext(ResidentStatusContext);
  const adminPageStyles = !residentId
    ? { borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }
    : {};

  return (
    <GridToolbar sx={adminPageStyles}>
      <Stack>
        <GridToolbarQuickFilter />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Tooltip title="Include all open incidents regardless of the date range selected.">
          <FormGroup sx={{ justifyContent: 'center' }}>
            <FormControlLabel
              onChange={onChangeIncludeAllOpen}
              labelPlacement="start"
              label={
                <Stack direction="row" alignItems="center" gap="6px">
                  <LibraryAddIcon htmlColor="#364955" />
                  <Typography
                    color="#364955"
                    fontWeight={500}
                    fontSize="0.9375rem">
                    Include All Open
                  </Typography>
                </Stack>
              }
              control={<Switch checked={includeAllOpen} />}
            />
          </FormGroup>
        </Tooltip>
        <DateRangePicker
          isLoading={isLoading}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        {!isResidentArchived && <AddIncidentButton residentId={residentId} />}
        <PopupState variant="popover">
          {(popup) => (
            <>
              <IconButton
                {...bindTrigger(popup)}
                color="secondary"
                style={{ backgroundColor: '#E7EEF4' }}>
                <MoreVertOutlined />
              </IconButton>
              <Menu {...bindMenu(popup)}>
                <MenuItem id="download-resident-incident" onClick={handlePrint}>
                  Print
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Stack>
    </GridToolbar>
  );
};

export default IncidentDataGridToolbar;
